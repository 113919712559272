import React from "react"

import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { Chip, Typography } from "@synapse-analytics/synapse-ui"

import { DatetimeHistogram } from "./DatetimeHistogram"
import { ParentsBreadcrumbs } from "./components/ParentsBreadcrumbs"
import { StatisticsRow } from "./components/StatisticsRow"

import styles from "../DataMonitor.module.scss"

type AggregatesType = {
  birth_date: string
  count: number
}

type Props = {
  name: string
  min?: number
  max?: number
  span?: number
  num_of_examples: number
  num_missing?: number
  unique?: number
  frequency?: string
  aggregates: AggregatesType[]
  parents: string
}

export function DatetimeFeatureCard(props: Readonly<Props>): React.ReactElement {
  const { name, min, max, span, num_of_examples, num_missing, unique, frequency, aggregates, parents } = props

  const missingPercentage = React.useMemo(() => {
    if (num_missing) return (num_missing / num_of_examples) * 100
    else return undefined
  }, [num_of_examples, num_missing])

  return (
    <Paper className={styles.valueContainer}>
      <Grid container>
        {/* Name and Type */}
        <Grid item xs={2}>
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" wrap="wrap">
            <Grid container item spacing={1} xs={12}>
              {/* Parents */}
              {parents && <ParentsBreadcrumbs parents={parents} />}
            </Grid>
            <Grid container item xs={11} spacing={1}>
              <Grid item xs={11} className={styles.featName}>
                <Typography variant="h2-bold" noWrap>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12} className={styles.chipAlignment}>
                <Chip>Datetime</Chip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Stats */}
        <Grid container item xs={6} className={styles.dataSection} justifyContent="space-between">
          <Grid container item xs={6} spacing={2} zeroMinWidth direction="column">
            <StatisticsRow name="Min" value={min} />
            <StatisticsRow name="Max" value={max} />
            <StatisticsRow name="Span" value={span} />
            <StatisticsRow name="Frequency" value={frequency} />
          </Grid>
          <Grid container item xs={6} spacing={2} zeroMinWidth direction="column">
            <StatisticsRow name="Distinct" value={unique} precision={0} />
            <StatisticsRow
              name="Distinct (%)"
              value={unique !== undefined ? ((unique / num_of_examples) * 100).toFixed(1) + "%" : "0.0%"}
              precision={1}
            />
            <StatisticsRow name="Num Missing" value={num_missing} precision={0} />
            <StatisticsRow
              name="Num Missing (%)"
              value={missingPercentage !== undefined ? missingPercentage.toFixed(1) + "%" : "0.0%"}
              precision={1}
            />
          </Grid>
        </Grid>

        {/* Histogram  */}
        <Grid item xs={4} alignItems="flex-end">
          <DatetimeHistogram name={name} data={aggregates ? aggregates : []} graphHeight={200} />
        </Grid>
      </Grid>
    </Paper>
  )
}
