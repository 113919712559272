import React, { useEffect, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { SelectWithSearch } from "../../../../../../components/UI/SelectWithSearch"
import { KonanAPI } from "../../../../../../services/KonanAPI"
import { Model } from "../../../../../../types/generated/api/Model"

type Props = {
  open: boolean
  onClose: () => void
  projectUUID: string
  trainingDataUUID: string
  trainingName: string
}

export function AssignTrainingDataToModelDialog(props: Readonly<Props>): React.ReactElement {
  const { open, onClose, projectUUID, trainingDataUUID, trainingName } = props

  const queryClient = useQueryClient()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  const [selectedModel, setSelectedModel] = useState<string>("")
  const [replaceTrainingDataWarning, setReplaceTrainingDataWarning] = useState<boolean>(false)

  const DEFAULT_TRAINING_DATA_NAME = "data.csv"

  // fetch models in the project
  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError>(
    ["models", projectUUID],
    () => KonanAPI.fetchModels(projectUUID),
    {
      enabled: !!projectUUID,
    },
  )

  const { data: modelData } = useQuery<AxiosResponse<Model>, AxiosError>(
    ["model", selectedModel],
    () => KonanAPI.fetchModel(selectedModel),
    { enabled: !!selectedModel },
  )

  const AssignedModelMutation = useMutation<AxiosResponse>(() =>
    KonanAPI.AssignTrainingDataToModel(selectedModel, trainingDataUUID),
  )

  async function AssignOrReplaceModelToTrainingData(): Promise<void> {
    try {
      await AssignedModelMutation.mutateAsync()
      // Invalidate react-query queries
      await queryClient.invalidateQueries("models")
      await queryClient.invalidateQueries(["training-data", projectUUID])
      onClose()
      NotificationUtils.toast(
        `Successfully assigned <${trainingName || DEFAULT_TRAINING_DATA_NAME}> to ${
          modelData?.data?.name ? `<${modelData?.data?.name}>` : "training data"
        } `,
        {
          snackBarVariant: "positive",
        },
      )

      window.scrollTo(0, 0)
    } catch (e) {
      onClose()
      NotificationUtils.toast(
        `an error occurred while assigning <${trainingName || DEFAULT_TRAINING_DATA_NAME}> to ${
          modelData?.data?.name ? `<${modelData?.data?.name}>` : "training data"
        }`,
        {
          snackBarVariant: "negative",
        },
      )
    }
  }

  async function assignModel(type: string): Promise<void> {
    if (type === "Replace") {
      AssignOrReplaceModelToTrainingData()
    } else if (type === "Assign") {
      if (modelData?.data && modelData?.data?.training_data && modelData?.data?.training_data !== null) {
        setReplaceTrainingDataWarning(true)
      } else {
        setReplaceTrainingDataWarning(false)
        AssignOrReplaceModelToTrainingData()
      }
    }
  }

  useEffect(() => {
    if (open) {
      setReplaceTrainingDataWarning(false)
    }
  }, [open])

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      onClose={(_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && AssignedModelMutation.isLoading) {
          return undefined
        } else {
          onClose()
        }
      }}
    >
      <DialogTitle className="dialog-header-base">
        <Typography variant="h2-bold">{replaceTrainingDataWarning ? "Replace Dataset" : "Assign Dataset"}</Typography>
      </DialogTitle>
      <DialogContent className="dialog-content-base">
        <Typography variant="h3-bold" gutterBottom>
          {replaceTrainingDataWarning
            ? `${modelData?.data?.name ?? "This Model"} already has an assigned dataset.`
            : "Assign to an existing Model"}
        </Typography>

        {!replaceTrainingDataWarning && (
          <Grid item xs={12}>
            <SelectWithSearch
              options={
                models?.data?.map((model) => {
                  return { label: model.name, value: model.uuid }
                }) ?? []
              }
              label="Models"
              tooltipLabel="Select a Model to assign to this dataset"
              isDropDownDisabled={models?.data?.length === 0}
              searchInputPlaceHolder="Search models"
              onSelectMenuItem={(item: { label: string; value: string }) => setSelectedModel(item?.value)}
              placeHolder={models?.data?.length === 0 ? "No models in this project" : "choose a model"}
            />
          </Grid>
        )}

        {replaceTrainingDataWarning ? (
          <Typography variant="p">Are you sure you want to replace it?</Typography>
        ) : (
          <Typography variant="span" style={{ marginTop: "4px" }}>
            Models with attached dataset will be replaced
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="dialog-actions-base">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => assignModel(replaceTrainingDataWarning ? "Replace" : "Assign")}
          disabled={!selectedModel || AssignedModelMutation.isLoading}
          variant="primary"
        >
          {replaceTrainingDataWarning && !AssignedModelMutation.isLoading ? (
            "Replace"
          ) : (replaceTrainingDataWarning && AssignedModelMutation.isLoading) ||
            (!replaceTrainingDataWarning && AssignedModelMutation.isLoading) ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            "Assign"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
