import React, { Dispatch, Fragment, SetStateAction, useState } from "react"

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, CardContent, CardHeader, Grid, IconButton } from "@mui/material"
import { Button, InputText, Menu, MenuItem, Select, Skeleton, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { FieldArray, useFormikContext } from "formik"
import { v4 as uuidv4 } from "uuid"

import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { getTheme } from "../../hooks/UseTheme"
import { Operators } from "../../types/custom/projects"
import { Rule } from "../../types/custom/rules"
import { WorkflowSchemaFeature } from "../../types/generated/api/WorkflowSchemaFeature"
import { Condition } from "../Ruleset/components/Condition"
import { TagCardFormikValues, TagGroupConditions } from "./inteface"

import styles from "..//Ruleset/components/RuleCard.module.scss"

/**
 * Helper UI component to render data items in tag-list card
 * @param {string} value
 * @return {React.ReactElement}
 */
export function DataBlock(props: Readonly<{ value: string; isLoading?: boolean }>): React.ReactElement {
  const { value, isLoading } = props

  return (
    <div className={styles.cardField}>
      <Typography variant="h3-bold">
        {isLoading ? <Skeleton variant="rectangular" width={100} height={20} /> : value}
      </Typography>
    </div>
  )
}

type Props = {
  editMode?: boolean
  createMode?: boolean
  duplicateMode?: boolean
  index: number
  andOr: ["and", "or"]
  handleDelete?: (name: string, stateFn: Dispatch<SetStateAction<boolean>>) => void
  handleRemoveRule?: (index: number) => boolean
}

/**
 * Rule card component
 * @return {React.ReactElement}
 */
export function TagCard(props: Readonly<Props>): React.ReactElement {
  const {
    editMode = false,
    createMode = false,
    handleDelete,
    handleRemoveRule,
    index,
    andOr,
    duplicateMode = false,
  } = props

  const { values, setFieldValue, handleChange, handleBlur, isSubmitting, errors, touched } =
    useFormikContext<TagCardFormikValues>()

  const theme = getTheme()

  const [openRuleDeletionDialog, setOpenRuleDeletionDialog] = useState<boolean>(false)

  const uniqueId = uuidv4()

  const emptyCondition = {
    id: uniqueId,
    feature: "",
    andOr: "and",
    operator: Operators["="],
    type: "string",
    value: "",
    secondValue: "",
    valueOrFeature: "Value",
    schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
  }

  // anchor for settings menu for tag card
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  // anchor for add rule menu (lvl1)
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null)
  // anchor for add rule menu (lvl2)
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null)
  // anchor for add rule menu (lvl3)
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null)
  const openMenu1 = Boolean(anchorEl1)
  const openMenu2 = Boolean(anchorEl2)
  const openMenu3 = Boolean(anchorEl3)

  const openRuleCardMenu = Boolean(anchor)

  const handleRemoveCondition = (
    levelRemove: (idx: number) => void,
    conditionIdx: number,
    conditionLevel: "levelThreeConditions" | "levelTwoConditions",
  ): void => {
    // current level the condition being removed from
    const currentLevelLength = values?.cards[index]?.[conditionLevel]?.length

    // the migrated level/group -> ex: if removing condition from levelTwo then the migrated level is levelOneConditions
    const levelToBeMigratedTo = conditionLevel === "levelThreeConditions" ? "levelTwoConditions" : "levelOneConditions"

    // the condition index that will be migrated to the prev level
    const conditionIndexToBeMigrated = conditionIdx === 0 ? 1 : 0

    if (currentLevelLength <= 2) {
      // cache the migrated condition in a temp variable
      const tempCondition = values?.cards[index]?.[conditionLevel][conditionIndexToBeMigrated]

      // remove the whole level
      setFieldValue(`cards[${index}].${conditionLevel}`, [])

      // push the migrated condition to the prev/migrated level
      setFieldValue(`cards[${index}].${levelToBeMigratedTo}`, [
        ...(values?.cards[index]?.[levelToBeMigratedTo] ?? []),
        tempCondition,
      ])

      // edge case, removing levelTwoConditions while levelThreeConditions still has data
      if (conditionLevel === "levelTwoConditions" && values?.cards[index]?.levelThreeConditions?.length > 0) {
        // assign levelTwoConditions data to be equal to levelThreeConditions
        setFieldValue(`cards[${index}].levelTwoConditions`, [...(values?.cards[index]?.levelThreeConditions ?? [])])

        // and remove levelThreeConditions data
        setFieldValue(`cards[${index}].levelThreeConditions`, [])
      }
    } else {
      levelRemove(conditionIdx)
    }
  }

  return (
    <Card className={styles.card}>
      {openRuleDeletionDialog && (
        <BaseSimpleDialog
          open={openRuleDeletionDialog}
          name={values?.cards[index]?.name}
          onClose={() => setOpenRuleDeletionDialog(false)}
          onAccept={() => {
            if (handleDelete) {
              handleDelete(values?.cards[index]?.name, setOpenRuleDeletionDialog)
            }
          }}
          mode={"rule-deletion"}
          isLoading={isSubmitting}
        />
      )}

      <CardHeader
        className={styles.cardHeader}
        title={
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={6}>
              {editMode || createMode ? (
                <InputText
                  hideDescription
                  id={`cards[${index}].name`}
                  placeholder="Name"
                  value={values.cards[index]?.name}
                  handleChange={handleChange}
                  error={
                    touched?.cards &&
                    errors?.cards &&
                    touched?.cards[index]?.name &&
                    Boolean((errors?.cards[index] as TagGroupConditions)?.name) &&
                    (errors?.cards[index] as TagGroupConditions)?.name
                  }
                  handleBlur={handleBlur}
                  disabled={isSubmitting}
                  description="Manually configured condition that returns a decision when matched."
                  fullWidth
                />
              ) : (
                <Typography variant="h3-bold" style={{ textTransform: "inherit" }}>
                  {values.cards[index]?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
        }
        action={
          handleDelete &&
          handleRemoveRule && (
            <Fragment>
              <IconButton
                aria-label="settings"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
              >
                <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
              </IconButton>
              <Menu
                key="basic-menu"
                anchorEl={anchor}
                open={openRuleCardMenu}
                onClose={() => setAnchor(null)}
                menuMaxContent
              >
                <Tooltip width={"100%"} title={1 === values?.cards?.length && "Taglist should have at least one tag"}>
                  <MenuItem
                    onClick={() => {
                      const removeIndicator = handleRemoveRule?.(index)
                      if (removeIndicator) {
                        setOpenRuleDeletionDialog(true)
                      }
                      setAnchor(null)
                    }}
                    disabled={values?.cards?.length === 1 || isSubmitting}
                  >
                    <Typography variant="a" color="negative" variantColor={2}>
                      Remove Tag
                    </Typography>
                  </MenuItem>
                </Tooltip>
              </Menu>
            </Fragment>
          )
        }
      />

      <CardContent className={styles.cardContent}>
        <FieldArray name={`cards[${index}].levelOneConditions`}>
          {/* Level 1 group */}
          {({ push: level1push, remove: level1remove }) => (
            <Grid container justifyContent="flex-end" alignItems="center" className={styles.groupBackground}>
              <Grid container item spacing={1}>
                {values?.cards[index]?.levelOneConditions?.map((item: Rule, idx: number) => (
                  <Grid item xs={12} key={item.id}>
                    <Condition
                      key={item.id}
                      duplicateMode={duplicateMode}
                      conditionLevel={"levelOneConditions"}
                      id={`cards[${index}].levelOneConditions[${idx}]`}
                      index={idx}
                      editMode={editMode}
                      createMode={createMode}
                      ruleIndex={index}
                      removeCondition={() => {
                        level1remove(idx)
                      }}
                      groupSwitch={values.cards[index].group1Switch}
                      lastSwitchSetter={(data) => setFieldValue(`cards[${index}].group1Switch`, data)}
                    />
                  </Grid>
                ))}

                {/* and/or */}
                {values.cards[index]?.levelTwoConditions && values.cards[index]?.levelTwoConditions.length > 0 && (
                  <Grid item xs={3} md={2} lg={1}>
                    {editMode && values.cards[index]?.levelOneConditions.length === 1 ? (
                      <Select
                        hideDescription
                        type="text"
                        value={values.cards[index].group1Switch}
                        handleChange={(e) => setFieldValue(`cards[${index}].group1Switch`, e.target.value as string)}
                        fullWidth
                        disabled={values.cards[index]?.levelOneConditions.length > 1}
                        options={andOr}
                        menuProps={{ menuMaxContent: true }}
                      />
                    ) : (
                      <DataBlock
                        value={
                          editMode
                            ? values.cards[index].group1Switch
                            : values.cards[index]?.levelOneConditions &&
                                values.cards[index]?.levelOneConditions.length > 0
                              ? values.cards[index]?.levelOneConditions[0].andOr
                              : ""
                        }
                      />
                    )}
                  </Grid>
                )}

                {/* Level 2 group */}
                {values.cards[index]?.levelTwoConditions && values.cards[index]?.levelTwoConditions.length > 0 && (
                  <Grid item xs>
                    <FieldArray name={`cards[${index}].levelTwoConditions`}>
                      {({ push: level2push, remove: level2remove }) => (
                        <Grid
                          container
                          item
                          justifyContent="flex-end"
                          alignItems="flex-start"
                          className={styles.groupBackground}
                        >
                          <Grid container item spacing={1}>
                            {values.cards[index]?.levelTwoConditions?.map((item: Rule, idx: number) => (
                              <Grid item xs={12} key={item.id}>
                                <Condition
                                  key={item.id}
                                  duplicateMode={duplicateMode}
                                  ruleIndex={index}
                                  conditionLevel={"levelTwoConditions"}
                                  id={`cards[${index}].levelTwoConditions[${idx}]`}
                                  index={idx}
                                  editMode={editMode}
                                  createMode={createMode}
                                  removeCondition={() => {
                                    handleRemoveCondition(level2remove, idx, "levelTwoConditions")
                                  }}
                                  groupSwitch={values.cards[index].group2Switch}
                                  lastSwitchSetter={(data) => setFieldValue(`cards[${index}].group2Switch`, data)}
                                />
                              </Grid>
                            ))}

                            {/* and/or */}
                            {values.cards[index]?.levelThreeConditions &&
                              values.cards[index]?.levelThreeConditions.length > 0 && (
                                <Grid item xs={3} md={2} lg={1}>
                                  {editMode && values.cards[index]?.levelTwoConditions.length === 1 ? (
                                    <Select
                                      hideDescription
                                      type="text"
                                      fullWidth
                                      value={values.cards[index].group2Switch}
                                      handleChange={(e) =>
                                        setFieldValue(`cards[${index}].group2Switch`, e.target.value as string)
                                      }
                                      disabled={values.cards[index]?.levelTwoConditions.length > 1}
                                      options={andOr}
                                      menuProps={{ menuMaxContent: true }}
                                    />
                                  ) : (
                                    <DataBlock
                                      value={
                                        editMode
                                          ? values.cards[index].group2Switch
                                          : values.cards[index]?.levelTwoConditions &&
                                              values.cards[index]?.levelTwoConditions.length > 0
                                            ? values.cards[index]?.levelTwoConditions[0].andOr
                                            : ""
                                      }
                                    />
                                  )}
                                </Grid>
                              )}

                            {/* Level 3 group */}
                            {values.cards[index]?.levelThreeConditions &&
                              values.cards[index]?.levelThreeConditions.length > 0 && (
                                <Grid item xs>
                                  <FieldArray name={`cards[${index}].levelThreeConditions`}>
                                    {({ push: level3push, remove: level3remove }) => (
                                      <Grid container item className={styles.groupBackground}>
                                        <Grid container item justifyContent="flex-end" alignItems="center" spacing={1}>
                                          {values.cards[index]?.levelThreeConditions?.map((item: Rule, idx: number) => (
                                            <Grid item xs={12} key={item.id}>
                                              <Condition
                                                key={item.id}
                                                duplicateMode={duplicateMode}
                                                ruleIndex={index}
                                                conditionLevel={"levelThreeConditions"}
                                                id={`cards[${index}].levelThreeConditions[${idx}]`}
                                                index={idx}
                                                editMode={editMode}
                                                createMode={createMode}
                                                removeCondition={() => {
                                                  handleRemoveCondition(level3remove, idx, "levelThreeConditions")
                                                }}
                                              />
                                            </Grid>
                                          ))}
                                        </Grid>

                                        {/* level 3 menu button  */}
                                        {(editMode || createMode) &&
                                          values.cards[index]?.levelThreeConditions &&
                                          values.cards[index]?.levelThreeConditions.length > 0 && (
                                            <Grid container>
                                              <Grid item lg={12} mt={1}>
                                                <Button
                                                  size="regular"
                                                  onClick={(event) => setAnchorEl3(event.currentTarget)}
                                                  disabled={isSubmitting}
                                                >
                                                  + Add Rule
                                                </Button>
                                                <Menu
                                                  key="basic-menu3"
                                                  anchorEl={anchorEl3}
                                                  open={openMenu3}
                                                  onClose={() => setAnchorEl3(null)}
                                                  menuMaxContent
                                                >
                                                  <MenuItem
                                                    onClick={() => {
                                                      level3push(emptyCondition)
                                                      setAnchorEl3(null)
                                                    }}
                                                  >
                                                    <Grid container display={"flex"} gap={1} height={"20px"}>
                                                      <Grid item>
                                                        <AddOutlinedIcon
                                                          fontSize="small"
                                                          htmlColor={theme.palette.grayscale.text[1]}
                                                        />
                                                      </Grid>

                                                      <Grid item>
                                                        <Typography variant="a">Add Rule</Typography>
                                                      </Grid>
                                                    </Grid>
                                                  </MenuItem>
                                                </Menu>
                                              </Grid>
                                            </Grid>
                                          )}
                                      </Grid>
                                    )}
                                  </FieldArray>
                                </Grid>
                              )}

                            {/* level 2 menu button  */}
                            {(editMode || createMode) &&
                              values.cards[index]?.levelTwoConditions &&
                              values.cards[index]?.levelTwoConditions.length > 0 && (
                                <Grid container>
                                  <Grid item lg={12} mt={1} ml={1}>
                                    <Button
                                      size="regular"
                                      onClick={(event) => setAnchorEl2(event.currentTarget)}
                                      disabled={isSubmitting}
                                    >
                                      + Add Rule
                                    </Button>
                                    <Menu
                                      key="basic-menu2"
                                      anchorEl={anchorEl2}
                                      open={openMenu2}
                                      onClose={() => setAnchorEl2(null)}
                                      menuMaxContent
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          level2push({
                                            ...emptyCondition,
                                            andOr: values.cards[index].group2Switch,
                                          })
                                          setAnchorEl2(null)
                                        }}
                                      >
                                        <Grid container display={"flex"} gap={1} height={"20px"}>
                                          <Grid item>
                                            <AddOutlinedIcon
                                              fontSize="small"
                                              htmlColor={theme.palette.grayscale.text[1]}
                                            />
                                          </Grid>

                                          <Grid item>
                                            <Typography variant="a">Add Rule</Typography>
                                          </Grid>
                                        </Grid>
                                      </MenuItem>
                                      {values.cards[index]?.levelThreeConditions &&
                                        values.cards[index]?.levelThreeConditions.length === 0 && (
                                          <MenuItem
                                            onClick={() => {
                                              values.cards[index]?.levelThreeConditions.push(
                                                emptyCondition,
                                                emptyCondition,
                                              )
                                              setAnchorEl2(null)
                                            }}
                                          >
                                            <Grid container display={"flex"} gap={1} height={"20px"}>
                                              <Grid item>
                                                <AddBoxOutlinedIcon
                                                  fontSize="small"
                                                  htmlColor={theme.palette.grayscale.text[1]}
                                                />
                                              </Grid>
                                              <Grid item>
                                                <Typography variant="a">Add Group</Typography>
                                              </Grid>
                                            </Grid>
                                          </MenuItem>
                                        )}
                                    </Menu>
                                  </Grid>
                                </Grid>
                              )}
                          </Grid>
                        </Grid>
                      )}
                    </FieldArray>
                  </Grid>
                )}

                {/* level 1 menu button  */}
                {(editMode || createMode) && (
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item lg={12} mt={1} ml={1}>
                      <Button
                        size="regular"
                        onClick={(event) => setAnchorEl1(event.currentTarget)}
                        disabled={isSubmitting}
                      >
                        + Add Rule
                      </Button>
                      <Menu
                        key="basic-menu1"
                        anchorEl={anchorEl1}
                        open={openMenu1}
                        onClose={() => setAnchorEl1(null)}
                        menuMaxContent
                      >
                        <MenuItem
                          onClick={() => {
                            level1push({ ...emptyCondition, andOr: values.cards[index].group1Switch })
                            setAnchorEl1(null)
                          }}
                        >
                          <Grid container display={"flex"} gap={1} height={"20px"}>
                            <Grid item>
                              <AddOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                            </Grid>
                            <Grid item>
                              <Typography variant="a">Add Rule</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                        {values.cards[index]?.levelTwoConditions &&
                          values.cards[index]?.levelTwoConditions.length === 0 && (
                            <MenuItem
                              onClick={() => {
                                values.cards[index]?.levelTwoConditions.push(emptyCondition, emptyCondition)
                                setAnchorEl1(null)
                              }}
                            >
                              <Grid container display={"flex"} gap={1} height={"20px"}>
                                <Grid item>
                                  <AddBoxOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                                </Grid>
                                <Grid item>
                                  <Typography variant="a">Add Group</Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          )}
                      </Menu>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </FieldArray>
      </CardContent>
    </Card>
  )
}
