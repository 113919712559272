import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"

import SearchIcon from "@mui/icons-material/Search"
import { Box, Grid } from "@mui/material"
import { InputText, Menu, MenuItem, RadioButton, Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../hooks/UseTheme"
import { CustomArrowAdornment } from "../CustomArrowAdornment"

import styles from "./SelectWithSearch.module.scss"

/**
 * Type definitions for menu options in the GroupedSelectWithSearch component.
 */
export interface MenuOptions {
  label: string
  value: string
  element?: React.ReactElement
  shouldHideLabel?: boolean
  isDisabled?: boolean
  subtitle?: string
}

export interface Options {
  [key: string]: Array<MenuOptions>
}

interface GroupedSelectWithSearchProps {
  actionButton?: React.ReactElement
  onSearchQueryChange?: (query: string) => void
  onSelectMenuItem: (item: { label: string; value: string }) => void
  options: Options
  placeHolder?: string
  label?: string
  isDropDownDisabled?: boolean
  tooltipLabel?: string
  fullWidth?: boolean
  searchInputPlaceHolder?: string
  initialValue?: string
  isOptionsLoading?: boolean
  hideDescription?: boolean
  currentValue?: string | undefined | null
}

/**
 * Custom Menu Variant, designed for menus with long items that include a search input to facilitate selection.
 * This component provides a dropdown menu enhanced with search functionality to filter items dynamically.
 *
 * @param {React.ReactElement} [actionButton] Optional action button to display in the menu.
 * @param {function} onSearchQueryChange Optional handler for search input changes.
 * @param {function} onSelectMenuItem Handler for when a menu item is selected.
 * @param {Array<MenuOptions>} options List of options to be displayed in the menu.
 * @param {string} [placeHolder] Placeholder text for the search input.
 * @param {string} [label] Label for the dropdown menu.
 * @param {boolean} [isDropDownDisabled] Flag to disable the dropdown functionality.
 * @param {string} [tooltipLabel] Tooltip text for the label.
 * @param {boolean} [fullWidth] Flag to set the menu to full width, defaults to true.
 * @param {string} [searchInputPlaceHolder] Placeholder text for the search field.
 * @param {string} [initialValue] Initial value for the search input.
 * @param {boolean} [isOptionsLoading] Flag to indicate if options are currently loading.
 * @returns {React.ReactElement} The rendered GroupedSelectWithSearch component as a React element.
 */
export function GroupedSelectWithSearch(props: Readonly<GroupedSelectWithSearchProps>): React.ReactElement {
  const {
    options,
    onSelectMenuItem,
    onSearchQueryChange,
    placeHolder,
    label,
    isDropDownDisabled,
    searchInputPlaceHolder,
    fullWidth = true,
    initialValue,
    isOptionsLoading,
    hideDescription = true,
    currentValue = undefined,
  } = props

  const theme = getTheme()

  const [value, setValue] = useState<{ name: string; element?: React.ReactElement }>({ name: initialValue ?? "" })

  const selectedValue = value?.name
    ? value?.name
    : initialValue && initialValue?.length > 0
      ? initialValue
      : (placeHolder ?? "")

  const inputRef = useRef<HTMLInputElement>(null)

  const searchInputRef = useRef<HTMLInputElement>(null)

  const [searchValue, setSearchValue] = useState<string>("")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(anchorEl)

  const adjustedOptions = useMemo<Options>(() => {
    return Object.keys(options).reduce((acc, key) => {
      const filteredOptions = options[key].filter((option) => {
        return option?.label.toLowerCase().includes(searchValue.toLowerCase())
      })

      if (filteredOptions.length > 0) {
        acc[key] = filteredOptions
      }

      return acc
    }, {} as Options)
  }, [options, searchValue])

  const handleChange = (option: { label: string; value: string; element?: React.ReactElement }): void => {
    // needed in case we have an option its job to de-select the current selected option and reset to default
    if (option?.value === "none" && initialValue) {
      setValue({ element: option?.element ?? undefined, name: initialValue })
    } else {
      const { label } = option

      setValue({ element: option?.element ?? undefined, name: label })
    }

    setAnchorEl(null)
    setSearchValue("")
  }

  const handleAdornmentClick = (): void => {
    if (inputRef?.current) {
      if (isMenuOpen) {
        setAnchorEl(null)
      } else {
        setAnchorEl(inputRef.current.parentElement)
        inputRef?.current.focus()
      }
    }
  }

  const handleQueryChange = (query: string): void => {
    setSearchValue(query)

    // calling outside handler if it passed
    onSearchQueryChange?.(query)
  }

  // setting focus on the search input ref on menu open
  useEffect(() => {
    if (anchorEl && searchInputRef?.current) {
      if (searchInputRef.current.offsetParent !== null) {
        searchInputRef.current.focus()
      }
    }
  }, [anchorEl])

  return (
    <Fragment>
      <InputText
        value={selectedValue}
        width={240}
        isSelect
        placeholder={placeHolder ?? ""}
        label={label ?? ""}
        endAdornment={<CustomArrowAdornment isMenuOpen={isMenuOpen} handleAdornmentClick={handleAdornmentClick} />}
        handleInputClick={handleAdornmentClick}
        anchoringRef={inputRef}
        disabled={isDropDownDisabled}
        hideDescription={hideDescription}
        fullWidth={fullWidth}
        menuProps={{ menuMaxContent: true }}
      >
        {value?.element && value.element}
      </InputText>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setSearchValue("")
          setAnchorEl(null)
        }}
        menuMaxContent
      >
        <Grid>
          <InputText
            id="search-bar"
            width={100}
            fullWidth
            startAdornment={
              <SearchIcon fontSize="small" style={{ marginTop: "2px", color: theme.palette.grayscale.text[2] }} />
            }
            placeholder={searchInputPlaceHolder ?? "Search.."}
            handleChange={(e) => handleQueryChange(e?.target?.value as string)}
            variant="filled"
            hideDescription
            customRef={searchInputRef}
          />
        </Grid>

        <div className={styles.optionsContainer}>
          {Object.keys(adjustedOptions).length !== 0 ? (
            Object.keys(adjustedOptions).map((key) => (
              <Fragment key={key}>
                <Typography
                  variant="label"
                  variantColor={2}
                  style={{
                    margin: "8px 0",
                  }}
                >
                  {key}
                </Typography>
                {adjustedOptions[key].map((option, idx) => (
                  <MenuItem
                    // [TODO]: fix key problem
                    key={`${key}-${idx}-${isOptionsLoading}`}
                    onClick={() => {
                      handleChange(option)
                      onSelectMenuItem(option)
                    }}
                    isSelected={option?.label === value?.name && currentValue === option?.value}
                    disabled={option?.isDisabled}
                    className={adjustedOptions[key]?.length > 4 ? styles.menuItem : undefined}
                  >
                    {option?.element ? (
                      <div className={styles.element}>
                        {!option?.shouldHideLabel && <div className={styles.optionLabel}>{option?.label}</div>}
                        {option?.element}
                      </div>
                    ) : (
                      <Box display="flex">
                        <RadioButton checked={option?.label === value?.name && currentValue === option?.value} />
                        <Box flexGrow={1}>
                          <Typography variant="h3-bold" className={styles.groupedOptionLabel}>
                            {option?.label}
                          </Typography>
                          <Typography variant="label" variantColor={2}>
                            {option?.subtitle}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </MenuItem>
                ))}
              </Fragment>
            ))
          ) : (
            <Grid item height="100%" mt={1} container justifyContent="center" alignItems="center">
              <Typography variantColor={2} variant="h3-bold">
                No results found
              </Typography>
            </Grid>
          )}
        </div>

        {isOptionsLoading && (
          <Grid item height="100%" mt={1} container justifyContent="center" alignItems="center">
            <Skeleton width="100%" height="30px" variant="rectangular" />
          </Grid>
        )}
      </Menu>
    </Fragment>
  )
}
