import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useDropzone } from "react-dropzone"
import { useIsMutating, useMutation } from "react-query"

import CloseIcon from "@mui/icons-material/Close"
import { CardActionArea, Grid, IconButton, useMediaQuery, useTheme as useMuiTheme } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { queryClient } from "../../../../.."
import { LinearProgressWithLabel } from "../../../../../components/LinearProgressWithLabel"
import { getTheme } from "../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../services/KonanAPI"
import { TrainingDataResponse } from "../../../../../types/generated/api/TrainingDataResponse"
import { dropZoneCSVExtensionValidator } from "../../../../../utils/genericHelpers"

import styles from "../TrainingData.module.scss"

type Props = {
  projectUUID: string
  open: boolean
  onDialogClose: () => void
  onUploadSuccess?: (name: string, uuid: string) => void
  onUploadAbort?: (name: string) => void
  onUploadError?: (name: string) => void
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

// TODO:: refactor all dropzone components into 1
export function UploadCSVDialog(props: Readonly<Props>): React.ReactElement {
  const { projectUUID, open, setOpen, onDialogClose, onUploadSuccess, onUploadError, onUploadAbort } = props

  const MuiTheme = useMuiTheme()
  const theme = getTheme()

  const [allAcceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)

  const abortControllerRef = useRef<AbortController | null>(null)

  const fullScreen = useMediaQuery(MuiTheme.breakpoints.down("md"))

  const baseStyle: React.CSSProperties = useMemo(() => {
    return {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: theme.palette.grayscale.border,
      borderStyle: "dashed",
      backgroundColor: theme.palette.grayscale.background[2],
      color: theme.palette.grayscale.text[2],
      outline: "none",
      transition: "border .24s ease-in-out",
    }
  }, [theme.palette.grayscale.background, theme.palette.grayscale.border, theme.palette.grayscale.text])

  const activeStyle = useMemo(() => {
    return {
      borderColor: theme.palette.blue.border,
    }
  }, [theme.palette.blue.border])

  const acceptStyle = useMemo(() => {
    return {
      borderColor: theme.palette.green.border,
    }
  }, [theme.palette.green.border])

  const rejectStyle = useMemo(() => {
    return {
      borderColor: theme.palette.red.border,
    }
  }, [theme.palette.red.border])

  // https://github.com/TanStack/query/discussions/1551
  // Followed the instructions here by F0rro on jan 23 and cleaned up a bit to match our architecture
  const {
    isLoading: uploadingCSV,
    mutateAsync: uploadCSVAsyncMutation,
    reset,
  } = useMutation<TrainingDataResponse, AxiosError, File>(
    (file: File) => {
      abortControllerRef.current = new AbortController()
      return KonanAPI.uploadTrainingData(projectUUID, file, setUploadProgress, abortControllerRef.current.signal)
    },
    {
      mutationKey: "uploadCSV",
    },
  )

  // check if there's a mutation running or not
  const isMutating = useIsMutating({
    mutationKey: "uploadCSV",
    exact: true,
  })

  // Dropzone stuff
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      disabled: uploadingCSV,
      multiple: false,
      accept: {
        "text/*": [".csv"],
      },
      validator: dropZoneCSVExtensionValidator,
    })

  // Need to style dropzone, https://react-dropzone.js.org/#!/Styling%20Dropzone
  const dropzoneStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [baseStyle, isDragActive, activeStyle, isDragAccept, acceptStyle, isDragReject, rejectStyle],
  )

  const handleUpload = async (): Promise<void> => {
    try {
      const trainingResponse = await uploadCSVAsyncMutation(acceptedFiles[0])
      // Invalidate training data to trigger a refresh
      // When tested, this line takes time to execute. Might want to move this
      // lower down the logic
      // Close dialog after CSV has been uploaded
      await queryClient.invalidateQueries(["training-data", projectUUID])

      if (onUploadSuccess) onUploadSuccess(acceptedFiles[0]?.name, trainingResponse?.uuid)

      setTimeout(() => {
        onDialogClose()
        handleCloseAndAbort()
        setAcceptedFiles([])
      }, 500)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.message === "canceled") {
        onUploadAbort && onUploadAbort(acceptedFiles[0]?.name)
      } else {
        onUploadError && onUploadError(acceptedFiles[0]?.name)
      }
      handleCloseAndAbort()
    }
  }

  const handleCloseAndAbort = useCallback(() => {
    abortControllerRef.current?.abort()
    reset()
    setUploadProgress(0)
    onDialogClose()
    setAcceptedFiles([])
  }, [onDialogClose, reset])

  useEffect(() => {
    if (!uploadingCSV && allAcceptedFiles && allAcceptedFiles.length > 0) {
      handleUpload()
      reset()
      setUploadProgress(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAcceptedFiles, uploadingCSV])

  useEffect(() => {
    reset()
    setAcceptedFiles([...acceptedFiles])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={(_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && isMutating) {
          setOpen && setOpen(true)
        } else {
          onDialogClose()
        }
      }}
      fullScreen={fullScreen}
      maxWidth="sm"
      aria-labelledby="upload-csv-dialog-title"
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2-bold">Upload Dataset</Typography>
        <IconButton className="close-icon-button" onClick={() => handleCloseAndAbort()} size={"small"}>
          <CloseIcon style={{ color: theme.palette.gray.background[1] }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        <Typography variant="h3-bold" gutterBottom>
          Upload training data
        </Typography>
        <Typography variant="label" gutterBottom>
          Training Data
        </Typography>

        {allAcceptedFiles && allAcceptedFiles.length > 0 && !(fileRejections && fileRejections.length > 0) ? (
          <CardActionArea className={acceptedFiles && acceptedFiles.length > 0 ? "selected-dropzone" : "dropzone"}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="p" className={styles.labels} noWrap>
                  {isMutating ? "Uploading file..." : "Data uploaded"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="span" className={styles.labels} noWrap>
                  {allAcceptedFiles[0].name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <LinearProgressWithLabel value={uploadProgress} isUploading={isMutating ? true : false} />
              </Grid>
            </Grid>
          </CardActionArea>
        ) : (
          <div {...getRootProps({ style: dropzoneStyle })}>
            <input {...getInputProps()} />
            <p>Drop or upload CSV file</p>
          </div>
        )}

        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          <Grid item>
            <Typography variant="span" display="block" gutterBottom variantColor={2} style={{ marginTop: "4px" }}>
              {"Must have .csv extension and be under 2GB"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
