import { useEffect, useState } from "react"

import { Editor, loader } from "@monaco-editor/react"

import { getTheme } from "../hooks/UseTheme"
import { KonanCopyToClipboard } from "./KonanCopyToClipboard"

import styles from "./MonacoTextEditor.module.scss"

type Props = {
  value: string | undefined
  language: string
  height?: string
  width?: string
  readOnly?: boolean
  onChange?: (text: string | undefined) => void
  loading?: React.ReactNode
  automaticLayout?: boolean
  lineNumbers?: "on" | "off"
  renderLineHighLight?: "line" | "all" | "none" | "gutter"
  enableCopy?: boolean
  wordWrap?: "on" | "off"
}

export const MonacoTextEditor = (props: Props): React.ReactElement => {
  const {
    value,
    language,
    height,
    width = "100%",
    automaticLayout = false,
    readOnly = false,
    onChange,
    loading,
    lineNumbers = "on",
    wordWrap,
    enableCopy = false,
    renderLineHighLight = "line",
  } = props

  const theme = getTheme()

  loader.config({
    paths: {
      vs: "https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.36.1/min/vs",
    },
  })

  const [newHeight, setNewHeight] = useState<number | string>(
    height ?? ((value?.split("\n").length as number) + 1) * 23,
  )

  // automatically layout the height of the code editor based on the input value
  useEffect(() => {
    if (automaticLayout) {
      const length = (value?.split("\n").length as number) + 0.5
      setNewHeight(`${length * 23}px`)
    }
  }, [automaticLayout, value])

  return (
    <div className={styles.MonacoTextEditor} style={{ width, borderRadius: "8px" }}>
      {enableCopy && value && (
        <div className={styles.copyBtnContainer}>
          <KonanCopyToClipboard text={value} rounded />
        </div>
      )}
      <Editor
        height={automaticLayout ? newHeight : height}
        width={width}
        language={language}
        value={value}
        theme={theme.mode === "dark" ? "vs-dark" : "vs-light"}
        options={{
          minimap: { enabled: false },
          readOnly: readOnly,
          scrollBeyondLastLine: true,
          autoIndent: "advanced",
          formatOnType: true,
          formatOnPaste: true,
          fontFamily: '"Inter", sans-serif',
          fontSize: 16,
          lineNumbers: lineNumbers,
          padding: { top: 8 },
          matchBrackets: "near",
          detectIndentation: true,
          smoothScrolling: true,
          wordWrap: wordWrap,
          wrappingStrategy: "advanced",
          renderLineHighlight: renderLineHighLight,
        }}
        onChange={(e) => onChange?.(e)}
        loading={loading}
      />
    </div>
  )
}
