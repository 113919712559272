import React, { Dispatch, Fragment, SetStateAction, useContext, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  SvgIcon,
} from "@mui/material"
import {
  Button,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import { FormikProvider, useFormik } from "formik"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { KonanAvatar } from "../../../components/Avatar"
import { KonanEmptyState } from "../../../components/KonanEmptyState"
import { VersionTag } from "../../../components/VersionTag"
import { BaseSimpleDialog } from "../../../components/dialogs/BaseSimpleDialog"
import { VersioningDialog } from "../../../components/dialogs/VersioningDialog"
import { getTheme } from "../../../hooks/UseTheme"
import { KonanAPI } from "../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../store/CurrentProjectAndModelContext"
import { RootState } from "../../../store/ReduxStore"
import { CreateWorkflowRequest, CustomRule, Operators } from "../../../types/custom/projects"
import {
  CreateRulesetRequest,
  CustomRuleRequest,
  MapValueTypeToFeatureType,
  Rule,
  UpdateRulesetRequest,
  VersionChangeRequest,
} from "../../../types/custom/rules"
import { SchemaFeature } from "../../../types/custom/workflows"
import { RuleSetGroupRetrieve } from "../../../types/generated/api/RuleSetGroupRetrieve"
import { RuleSetVersionRetrieve } from "../../../types/generated/api/RuleSetVersionRetrieve"
import { WorkflowCreateRequest } from "../../../types/generated/api/WorkflowCreateRequest"
import { WorkflowSchemaFeatureRequest } from "../../../types/generated/api/WorkflowSchemaFeatureRequest"
import { levelSchema } from "../../../utils/conditionHelpers"
import { getValidMaxMinorVersion } from "../../../utils/deploymentDetailsHelpers"
import {
  compareTwoRulesList,
  convertComplexConditionsToNestedForm,
  convertComplexFormToCondition,
  reorderList,
} from "../../../utils/rulesetHelpers"
import { RuleGroupConditions } from "../interface"
import { RuleCard } from "./RuleCard"

import styles from "../Ruleset.module.scss"

// Yup validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("Ruleset name is required"),
  cards: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Rule name is required"),
      levelOneConditions: Yup.array().of(levelSchema),
      levelTwoConditions: Yup.array().of(levelSchema),
      levelThreeConditions: Yup.array().of(levelSchema),
      label: Yup.string().required("Return label is required"),
      label_id: Yup.string().required("Choose an item from the dropdown to select a label"),
    }),
  ),
})

type Props = {
  uuid: string
  createdBy: string
  createdAt?: string
  closeCreateCard?: () => void
  isDuplicate?: boolean
  duplicateRuleset?: (rulesetUUID: string) => void
  disableDuplicateButton?: boolean
}

/**
 * Ruleset card component
 * @param {string} uuid
 * @param {string} createdBy email of creator
 * @param {string} createdAt  date of creation
 * @param {boolean} isDuplicate indicate if the ruleset is a duplicate
 * @param {function} closeCreateCard close the create card
 * @param {function} duplicateRuleset duplicate a ruleset
 * @return {React.ReactElement}
 */
export function RulesetCard(props: Readonly<Props>): React.ReactElement {
  const { uuid, createdBy, createdAt, closeCreateCard, duplicateRuleset, isDuplicate, disableDuplicateButton } = props
  const { id: projectId } = useParams<{ id: string }>()

  const queryClient = useQueryClient()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const theme = getTheme()

  const workflowsList = useSelector((state: RootState) => state?.workflow?.workflows)
  const schemaFeatures = useSelector((state: RootState) => state?.project?.schemaFeatures)
  const computedSchemaFeatures = useSelector((state: RootState) => state?.project?.computedFeatures)

  const isCreateMode = uuid === "new" || !!isDuplicate

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchor)

  //Group Switches are used to link between levels (level 1 and level 2) and (level 2 and level 3)
  const [group1Switch, setGroup1Switch] = useState("and")
  const [group2Switch, setGroup2Switch] = useState("and")

  // saving a snap shot of the cards on edit click so if we cancel after some edit we get this snap shot back
  const [cardsSnapShot, setCardsSnapShot] = useState<Array<RuleGroupConditions>>([])

  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)

  // fetch single ruleset
  const { isLoading: isRulesetLoading, data: ruleset } = useQuery<AxiosResponse<RuleSetGroupRetrieve>, AxiosError>(
    ["ruleset", currentProject, uuid],
    () => KonanAPI.fetchRuleset(currentProject.uuid as string, uuid),
    {
      onSuccess: async ({ data }) => {
        const activeVersion = data?.versions?.find(
          (version: RuleSetVersionRetrieve) => version?.is_active_version === true,
        )

        await formik.setValues({
          uuid: data.uuid,
          name: `${data.name}${isDuplicate ? " - Copy" : ""}`,
          cards: activeVersion?.rules?.map((rule) => {
            const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)

            return {
              name: rule?.name,
              levelOneConditions: convertedRule && convertedRule.length > 0 ? convertedRule[0] : [emptyCondition],
              levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
              levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
              label: rule?.return_label.name,
              label_id: rule?.return_label.uuid,
              condition_list_files: [...rule.condition_list_files],
            }
          }),
        })
      },
      enabled: !!currentProject && uuid !== "new",
    },
  )

  // create workflow mutation
  const createWorkflowMutation = useMutation<AxiosResponse, AxiosError, CreateWorkflowRequest>(
    KonanAPI.createWorkflow,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["workflows", currentProject?.uuid])
        await queryClient.invalidateQueries(["project", currentProject?.uuid])
      },
    },
  )

  // ruleset deletion Mutation
  const deleteRulesetMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    { project_uuid: string; resource_uuid: string }
  >((props) => KonanAPI.deleteWorkflowResource({ ...props, resource_type: "rulesets" }), {
    onSuccess: async () => {
      NotificationUtils.toast("Ruleset deleted successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries("rulesets")
      await queryClient.invalidateQueries("ruleset")
    },
    onError: ({ response }) => {
      NotificationUtils.toast(response?.data?.details ?? "Ruleset deletion failed", { snackBarVariant: "negative" })
    },
  })

  // create ruleset
  const createRulesetMutation = useMutation<AxiosResponse, AxiosError, CreateRulesetRequest>(KonanAPI.createRuleset, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("rulesets")
      await queryClient.invalidateQueries("ruleset")
      NotificationUtils.toast("Ruleset successfully created!", {
        snackBarVariant: "positive",
      })
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(error: any) {
      if (error?.response?.data?.name) {
        NotificationUtils.toast(error?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.condition) {
        NotificationUtils.toast(error?.response?.data?.condition[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.details) {
        NotificationUtils.toast(error?.response?.data?.details, {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while creating your ruleset.", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // update ruleset
  const updateRulesetMutation = useMutation<AxiosResponse, AxiosError, UpdateRulesetRequest>(KonanAPI.updateRuleset, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("rulesets")
      await queryClient.invalidateQueries(["ruleset", currentProject, uuid])
      await queryClient.invalidateQueries("ruleset")
      setIsEditMode(false)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(error: any) {
      if (error?.response?.data?.name) {
        NotificationUtils.toast(error?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.condition) {
        NotificationUtils.toast(error?.response?.data?.condition[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.details) {
        NotificationUtils.toast(error?.response?.data?.details, {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while updating your ruleset.", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // change ruleset version
  const rulesetVersionChangeMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("rulesets")
        await queryClient.invalidateQueries(["ruleset", uuid])
        await queryClient.invalidateQueries("ruleset")
      },
    },
  )

  // Memoize active version to render it in the card
  const activeVersion = useMemo(() => {
    if (ruleset) {
      for (const version of ruleset.data.versions) {
        if (version.is_active_version) {
          return version
        }
      }
    }
  }, [ruleset])

  const emptyCondition: Rule = {
    id: uuidv4(),
    feature: "",
    andOr: "and",
    operator: Operators["="],
    valueOrFeature: "Value",
    type: "string",
    value: "",
    secondValue: "",
    secondFeature: "",
    schemaFeatureType: WorkflowSchemaFeatureRequest.type.TEXT,
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      cards: [
        {
          id: `new-${uuidv4()}`,
          name: "",
          levelOneConditions: [emptyCondition] as Rule[],
          levelTwoConditions: [] as Rule[],
          levelThreeConditions: [] as Rule[],
          label: "",
          label_id: "",
          condition_list_files: [],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async ({ name, cards }) => {
      const finalRulesFormat: CustomRuleRequest[] = []
      const featuresArray: Array<SchemaFeature> = []
      const trimmedName = name?.trim()

      for (const ruleCard of cards) {
        const trimmedName = ruleCard?.name?.trim()

        try {
          const multiValuesOperators = [
            Operators["in"],
            Operators["not in"],
            Operators["contains"],
            Operators["not contains"],
          ]

          if (ruleCard.levelOneConditions && ruleCard.levelOneConditions.length > 0) {
            ruleCard.levelOneConditions.forEach((item: Rule) => {
              if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
                featuresArray.push({
                  name: item?.feature,
                  is_required: true,
                  source: "workflow",
                  type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
                })
              }

              item.andOr = group1Switch
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          if (ruleCard.levelTwoConditions && ruleCard.levelTwoConditions.length > 0) {
            ruleCard.levelTwoConditions.forEach((item: Rule) => {
              if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
                featuresArray.push({
                  name: item?.feature,
                  is_required: true,
                  source: "workflow",
                  type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
                })
              }
              item.andOr = group2Switch
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          if (ruleCard.levelThreeConditions && ruleCard.levelThreeConditions.length > 1) {
            const ruleLevelThree = ruleCard.levelThreeConditions[1]
            const switchValue = ruleLevelThree.andOr

            ruleCard.levelThreeConditions.forEach((item: Rule) => {
              if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
                featuresArray.push({
                  name: item?.feature,
                  is_required: true,
                  source: "workflow",
                  type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
                })
              }
              item.andOr = switchValue
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          const newRule = {
            name: trimmedName,
            condition: convertComplexFormToCondition(
              ruleCard.levelOneConditions,
              ruleCard.levelTwoConditions,
              ruleCard.levelThreeConditions,
            ),
            return_label: ruleCard?.label_id,
          }

          finalRulesFormat.push(newRule)
        } catch (e) {
          console.warn(e)
        }
      }
      if (workflowsList?.length === 0 && featuresArray?.length > 0) {
        await createWorkflowMutation.mutateAsync({
          name: "Untitled-1",
          state: WorkflowCreateRequest.state.DISABLED,
          projectUUID: currentProject?.uuid as string,
          schema: [...featuresArray],
        })
      }

      if (isCreateMode) {
        // Create a new ruleset
        await createRulesetMutation.mutateAsync({
          projectUUID: currentProject?.uuid as string,
          name: trimmedName,
          rules: [...finalRulesFormat],
        })
        closeCreateCard?.()
      } else {
        if (activeVersion?.rules && compareTwoRulesList(finalRulesFormat, activeVersion?.rules)) {
          await updateRulesetMutation.mutateAsync({
            projectUUID: currentProject.uuid,
            rulesetUUID: ruleset?.data.uuid as string,
            name: trimmedName === ruleset?.data?.name ? undefined : trimmedName,
          })
        } else {
          await updateRulesetMutation.mutateAsync({
            projectUUID: currentProject.uuid,
            rulesetUUID: ruleset?.data.uuid as string,
            name: trimmedName === ruleset?.data?.name ? undefined : trimmedName,
            rules: [...(finalRulesFormat ?? [])],
          })
        }

        setIsEditMode(false)
        NotificationUtils.toast("Ruleset successfully updated!", {
          snackBarVariant: "positive",
        })

        await queryClient.invalidateQueries("rulesets")
        await queryClient.invalidateQueries("ruleset")
      }
    },
  })

  const changeRulesetVersion = async (version: string): Promise<void> => {
    try {
      await rulesetVersionChangeMutation.mutateAsync({
        projectUUID: currentProject.uuid,
        resourceUUID: uuid,
        resource: "rulesets",
        version,
      })

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
      // Invalidate react-query queries
      await queryClient.invalidateQueries("ruleset")
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  const deleteRule = async (name: string, closeDialogState: Dispatch<SetStateAction<boolean>>): Promise<void> => {
    const clonedArray = activeVersion?.rules

    try {
      const finalFormattedRules = clonedArray?.map((rule: CustomRule) => {
        return { ...rule, return_label: rule?.return_label?.uuid }
      })
      await updateRulesetMutation.mutateAsync({
        projectUUID: currentProject.uuid,
        uuid: ruleset?.data?.uuid ?? "",
        name: undefined,
        rules: [...(finalFormattedRules?.filter((rule: CustomRule) => rule.name !== name) ?? [])],
      })

      // Invalidate react-query queries
      await queryClient.invalidateQueries("ruleset")
      await queryClient.invalidateQueries(["rulesets", currentProject])
      await queryClient.invalidateQueries(["ruleset", currentProject, uuid])
      closeDialogState(false)

      NotificationUtils.toast("Rule successfully deleted!", {
        snackBarVariant: "positive",
      })
    } catch (e) {
      console.warn(e)
    }
  }

  const handleCancelRuleset = (): void => {
    formik.setFieldValue("cards", cardsSnapShot)

    setIsEditMode(false)
    closeCreateCard?.()
    formik.setFieldValue("name", ruleset?.data.name)
    formik.setErrors({})
  }

  const handleRemoveRule = (ruleIndex: number): boolean => {
    if (isCreateMode || isEditMode) {
      formik.setFieldValue(
        "cards",
        formik.values.cards.filter((_, index) => index !== ruleIndex),
      )
      formik.setFieldError(`cards[${ruleIndex}]`, "")
      return false
    } else {
      return true
    }
  }

  // helper function for the versioning dialog
  // returns data of the version selected
  const GetRulesetContainer = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

    const Version = useMemo((): RuleSetVersionRetrieve | undefined => {
      if (ruleset && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(ruleset.data.versions, selectedVersion)
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of ruleset.data.versions) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, ruleset])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeVersion?.version

    const versionFormik = useFormik({
      initialValues: {
        name: formik.values.name,
        cards: Version?.rules?.map((rule) => {
          const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)

          return {
            name: rule?.name,
            levelOneConditions: convertedRule && convertedRule.length > 0 ? convertedRule[0] : [emptyCondition],
            levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
            levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
            label: rule?.return_label.name,
            label_id: rule?.return_label.uuid,
            condition_list_files: [...rule.condition_list_files],
          }
        }),
      },
      onSubmit: () => {
        return
      },
    })

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {ruleset?.data.name}
            </Typography>

            <VersionTag
              version={Version?.version ?? selectedVersion}
              variant={Version ? (activeVersion?.version === selectedVersion ? "positive" : "default") : "positive"}
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => changeRulesetVersion(selectedVersion as string)}
              disabled={rulesetVersionChangeMutation.isLoading}
            >
              {rulesetVersionChangeMutation.isLoading ? <CircularProgress size={12} /> : "Restore"}
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0}>
          <FormikProvider value={versionFormik}>
            {Version?.rules && 0 < Version?.rules?.length ? (
              Version?.rules?.map((rule, index: number) => (
                <Grid
                  container
                  item
                  key={rule?.name}
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  pt={1.5}
                >
                  {/*  Timeline section  */}
                  <Grid item xs={1.75} md={1} lg={0.75} xl={0.5}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item>
                        <Avatar sx={{ width: 32, height: 32 }} className={styles.avatar}>
                          {index + 1}
                        </Avatar>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={10.25} md={11} lg={11.25} xl={11.5}>
                    <RuleCard
                      duplicateMode={isDuplicate}
                      index={index}
                      andOr={["and", "or"]}
                      group1Switch={group1Switch}
                      group2Switch={group2Switch}
                      setGroup1Switch={setGroup1Switch}
                      setGroup2Switch={setGroup2Switch}
                      key={rule.name}
                    />
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid
                container
                height={"100%"}
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                pt={1.5}
              >
                <KonanEmptyState title="No rules in this version" subTitle="Checkout another version" />
              </Grid>
            )}
          </FormikProvider>
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      {isVersionDialogOpen && (
        <VersioningDialog
          isOpen={isVersionDialogOpen}
          onClose={() => setIsVersionDialogOpen(false)}
          versions={ruleset?.data.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          activeVersion={activeVersion as RuleSetVersionRetrieve}
        >
          <GetRulesetContainer />
        </VersioningDialog>
      )}

      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          open={isDeletionDialogOpen}
          name={formik.values.name}
          onClose={() => setIsDeletionDialogOpen(false)}
          onAccept={() => {
            deleteRulesetMutation.mutateAsync({
              project_uuid: projectId as string,
              resource_uuid: uuid,
            })
          }}
          mode={"ruleset-deletion"}
          isLoading={deleteRulesetMutation.isLoading}
        />
      )}

      <Card className="card-box-shadow">
        <CardHeader
          className={styles.padding}
          title={
            <Grid container direction="column" item xs={12} spacing={1}>
              <Grid item container xs={12} justifyContent="space-between">
                <Grid item container xs={6}>
                  {isRulesetLoading ? (
                    <Skeleton width={200} />
                  ) : !isEditMode && formik.values.name && !isCreateMode ? (
                    <Typography variant="h3-bold" style={{ textTransform: "inherit" }}>
                      {formik.values.name}
                    </Typography>
                  ) : !isEditMode && ruleset?.data.name && !isCreateMode ? (
                    <Typography variant="h3-bold" style={{ textTransform: "inherit" }}>
                      {ruleset?.data.name}
                    </Typography>
                  ) : (
                    <InputText
                      hideDescription
                      id="name"
                      placeholder="Name"
                      value={formik.values.name}
                      handleChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
                      handleBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  )}
                  {!isCreateMode && !isEditMode && (activeVersion?.version || selectedVersion) && (
                    <Grid item mt={isCreateMode || isEditMode ? 0 : -1} ml={1}>
                      <VersionTag
                        version={selectedVersion ?? activeVersion?.version}
                        variant={
                          selectedVersion
                            ? selectedVersion === activeVersion?.version
                              ? "positive"
                              : "default"
                            : "positive"
                        }
                      />
                    </Grid>
                  )}
                </Grid>

                {!isCreateMode && !isEditMode && (
                  <Grid container item xs justifyContent={"flex-end"}>
                    <IconButton
                      aria-label="settings"
                      size="small"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
                    >
                      <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
                    </IconButton>

                    <Menu
                      key="basic-menu"
                      anchorEl={anchor}
                      open={isMenuOpen}
                      onClose={() => setAnchor(null)}
                      menuMaxContent
                    >
                      <MenuItem
                        onClick={() => {
                          setIsDeletionDialogOpen(true)
                          setAnchor(null)
                        }}
                      >
                        <Typography variant="a" color="negative" variantColor={2}>
                          Remove Ruleset
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}
              </Grid>
              {/* Avatar section */}
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <Grid container item spacing={1} xs={6} alignItems="flex-start" className={styles.avatarContainer}>
                  <Grid item sx={{ marginTop: "2px" }}>
                    {/* SvgIcon is used to fix square avatars on safari */}
                    <SvgIcon className={styles.modelAvatar}>
                      <KonanAvatar size={24} name={createdBy} />
                    </SvgIcon>
                  </Grid>
                  <Grid container item xs={10}>
                    <Grid item>
                      <Typography
                        variant="label"
                        noWrap
                        style={{ width: "fit-content", marginTop: isCreateMode ? "5px" : "0px" }}
                      >
                        {createdBy}
                      </Typography>

                      {createdAt && (
                        <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                          <Tooltip title={format(new Date(createdAt), "dd/MM/yyyy, p")} placement="right">
                            <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                              {moment(new Date(createdAt)).fromNow()}
                            </Typography>
                          </Tooltip>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  alignSelf={"flex-end"}
                  sx={{ justifyContent: { xs: "flex-start", md: "flex-end" } }}
                >
                  {!isCreateMode && !isEditMode && (
                    <Fragment>
                      <Grid item pr={1}>
                        <Button
                          size="small"
                          variant="secondary"
                          disabled={isRulesetLoading || disableDuplicateButton}
                          onClick={() => {
                            duplicateRuleset?.(uuid)
                          }}
                        >
                          Duplicate
                        </Button>
                      </Grid>

                      <Grid item pr={1}>
                        <Button
                          size="small"
                          variant="secondary"
                          disabled={isRulesetLoading}
                          onClick={() => {
                            setSelectedVersion(activeVersion?.version)
                            setIsVersionDialogOpen(true)
                          }}
                        >
                          History
                        </Button>
                      </Grid>
                    </Fragment>
                  )}

                  {!isRulesetLoading && !isEditMode && !isCreateMode ? (
                    <Grid item alignSelf={"flex-end"}>
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          setIsEditMode(!isEditMode)
                          // to deep copy array of nested objects
                          setCardsSnapShot(JSON.parse(JSON.stringify(formik.values.cards)))
                        }}
                      >
                        Edit
                      </Button>
                    </Grid>
                  ) : (
                    !isRulesetLoading &&
                    (isEditMode || isCreateMode) && (
                      <Grid item gap={1} display={"flex"} alignSelf={"flex-end"}>
                        <Grid item>
                          <Button
                            disabled={isRulesetLoading}
                            size="small"
                            onClick={handleCancelRuleset}
                            variant={"secondary"}
                          >
                            Cancel
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            variant="primary"
                            size="small"
                            disabled={formik.isSubmitting || isRulesetLoading}
                            onClick={formik.submitForm}
                          >
                            {formik.isSubmitting ? <CircularProgress color="inherit" size={16} /> : "Save"}
                          </Button>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>

              <Grid item container>
                <Typography variant="p" display="block" style={{ textTransform: "inherit" }}>
                  Define conditions with its final labels. To be effective, you integrate it into your workflow.
                </Typography>
              </Grid>
            </Grid>
          }
        />

        <CardContent className={styles.cardContent}>
          {isRulesetLoading ? (
            <Grid item xs={12}>
              <Card className={styles.cardHeader}>
                <CardHeader className={styles.padding} title={<Skeleton width={200} />} />
                <CardContent className={styles.cardContent}>
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Fragment>
              <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                <FormikProvider value={formik}>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    formik.values.cards.map((rule: any, index) => (
                      <Grid container item key={index} justifyContent="flex-start" alignItems="center" spacing={2}>
                        {/*  Timeline section  */}
                        <Grid item xs={1.75} md={1} lg={0.75} xl={0.5}>
                          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                            {index !== 0 && isEditMode && (
                              <Grid item>
                                <Button
                                  variant="primary"
                                  size="small"
                                  onClick={() => {
                                    const adjustedList = reorderList(
                                      formik.values.cards,
                                      rule.name,
                                      index !== 0 ? "up" : "down",
                                    )
                                    formik.setFieldValue("cards", [...adjustedList])
                                  }}
                                  disabled={updateRulesetMutation.isLoading}
                                >
                                  {index !== 0 ? (
                                    <ArrowUpwardIcon fontSize="inherit" />
                                  ) : (
                                    <ArrowDownwardIcon fontSize="inherit" />
                                  )}
                                </Button>
                              </Grid>
                            )}

                            <Grid item>
                              <Avatar sx={{ width: 32, height: 32 }} className={styles.avatar}>
                                {index + 1}
                              </Avatar>
                            </Grid>

                            {index !== formik.values.cards.length - 1 && isEditMode && (
                              <Grid item>
                                <Button
                                  variant="primary"
                                  size="small"
                                  onClick={() => {
                                    const adjustedList = reorderList(formik.values.cards, rule.name, "down")
                                    formik.setFieldValue("cards", [...adjustedList])
                                  }}
                                  disabled={updateRulesetMutation.isLoading}
                                >
                                  <ArrowDownwardIcon fontSize="inherit" />
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item xs={10.25} md={11} lg={11.25} xl={11.5}>
                          <RuleCard
                            createMode={isCreateMode || rule?.id?.includes("new")}
                            editMode={isEditMode}
                            duplicateMode={isDuplicate}
                            andOr={["and", "or"]}
                            key={`${rule.id}_${schemaFeatures.length}_${computedSchemaFeatures.length}`}
                            index={index}
                            handleRemoveRule={(index: number) => handleRemoveRule(index)}
                            group1Switch={group1Switch}
                            group2Switch={group2Switch}
                            setGroup1Switch={setGroup1Switch}
                            setGroup2Switch={setGroup2Switch}
                            handleDelete={(name: string, stateFn: Dispatch<SetStateAction<boolean>>) =>
                              deleteRule(name, stateFn)
                            }
                          />
                        </Grid>
                      </Grid>
                    ))
                  }
                </FormikProvider>
              </Grid>

              <Grid container justifyContent="flex-start" alignItems="center" mt={2}>
                {(isEditMode || isCreateMode) && (
                  <Grid item>
                    <Button
                      size="regular"
                      disabled={
                        formik?.values?.cards?.length >= Number(window.__RUNTIME_CONFIG__.KONAN_MAX_RULE_NUMBER) ||
                        formik.isSubmitting
                      }
                      tooltip={
                        formik?.values?.cards?.length >= Number(window.__RUNTIME_CONFIG__.KONAN_MAX_RULE_NUMBER)
                          ? "You have reached the maximum number of rules"
                          : ""
                      }
                      onClick={() => {
                        formik.setFieldValue("cards", [
                          ...formik.values.cards,
                          {
                            id: `new-${uuidv4()}`,
                            name: "",
                            levelOneConditions: [emptyCondition],
                            levelTwoConditions: [],
                            levelThreeConditions: [],
                            label: "",
                            label_id: "",
                            condition_list_files: [],
                          },
                        ])
                      }}
                    >
                      + Add Rule
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Fragment>
          )}
        </CardContent>
      </Card>
    </Fragment>
  )
}
