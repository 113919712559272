import React, { Fragment, useState } from "react"

import { useMutation } from "react-query"
import { useSelector } from "react-redux"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from "../.."
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { SidAPI } from "../../services/SidAPI"
import { RootState } from "../../store/ReduxStore"
import { PatchedGroupRequest } from "../../types/custom/Permissions"
import { GroupRetrieve } from "../../types/generated/authentication/GroupRetrieve"
import { KonanResource } from "../../types/generated/authentication/KonanResource"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { MenuOption } from "../Members"
import { RoleCard } from "../Roles/RoleCard"
import { GroupCreationDialog } from "./GroupCreationDialog"
import { GroupContainerInterfaceProps } from "./Interfaces"

/**
 * Group container holding all the group logic
 * @param {GroupRetrieve} group
 * @param {boolean} immutable (optional)
 * @param {string[]} users (optional)
 * @returns {React.ReactElement}
 */
export function GroupContainer(props: Readonly<GroupContainerInterfaceProps>): React.ReactElement {
  const { group, immutable = true, users } = props

  const permissions = Auth.getPermissions()
  const organizationUUID = Auth.getOrganizationUID() as string

  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false)
  const [isViewMode, setIsViewMode] = useState<boolean>(false)

  const deleteGroupMutation = useMutation<AxiosResponse, AxiosError, string>(SidAPI.deleteGroup, {
    onSuccess: async () => {
      NotificationUtils.toast("Group deleted successfully", {
        snackBarVariant: "positive",
      })

      await queryClient.invalidateQueries("groups")
      await queryClient.invalidateQueries("change-history")
    },
    onError: () =>
      NotificationUtils.toast("Group deletion failed", {
        snackBarVariant: "negative",
      }),
  })

  const editGroupsMutation = useMutation<AxiosResponse<GroupRetrieve>, AxiosError, PatchedGroupRequest>(
    SidAPI.updateGroup,
    {
      onSuccess: () => {
        NotificationUtils.toast("Group updated successfully", {
          snackBarVariant: "positive",
        })

        queryClient.invalidateQueries(["groups", organizationUUID])
        queryClient.invalidateQueries("change-history")
      },
      onError: () =>
        NotificationUtils.toast("Group update failed", {
          snackBarVariant: "negative",
        }),
    },
  )

  const updateGroupHandler = async (name: string, konan_resources: KonanResource[]): Promise<void> => {
    await editGroupsMutation.mutateAsync({
      uid: group.uid,
      name: name,

      konan_resources: konan_resources,
    })

    setIsDeletionDialogOpen(false)
  }

  const MenuOptions = (): MenuOption[] => {
    const options: MenuOption[] = []

    isPermitted("Update groups", permissions.synapse_id, flattenedSidPermissions) &&
      options.push({
        title: "Edit",
        variant: "neutral",
        onClick: () => {
          setIsEditDialogOpen(true)
        },
      })

    isPermitted("Delete groups", permissions.synapse_id, flattenedSidPermissions) &&
      options.push({
        title: "Delete",
        variant: "negative",
        onClick: () => {
          setIsDeletionDialogOpen(true)
        },
      })

    return options
  }

  return (
    <Fragment>
      {/* Dialogs */}
      {isEditDialogOpen && (
        <GroupCreationDialog
          isOpen={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false)
            setTimeout(() => {
              setIsViewMode(false)
            }, 300)
          }}
          group={group}
          onAccept={updateGroupHandler}
          isViewMode={isViewMode}
        />
      )}
      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          open={isDeletionDialogOpen}
          isLoading={deleteGroupMutation.isLoading}
          name={group.name}
          onAccept={() => {
            deleteGroupMutation.mutateAsync(group.uid)
            setIsDeletionDialogOpen(false)
          }}
          onClose={() => setIsDeletionDialogOpen(false)}
          mode={"group-deletion"}
        />
      )}

      <RoleCard
        name={group.name}
        onTitleClick={
          !["All Projects"].includes(group.name)
            ? () => {
                setIsViewMode(true)
                setIsEditDialogOpen(true)
              }
            : undefined
        }
        date={group.created_at}
        immutable={immutable}
        users={users}
        MenuOptions={MenuOptions()}
      />
    </Fragment>
  )
}
