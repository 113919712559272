import React, { Fragment, useContext, useEffect, useMemo, useState } from "react"

import { useSelector } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import ApiOutlinedIcon from "@mui/icons-material/ApiOutlined"
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined"
import BatchPredictionOutlinedIcon from "@mui/icons-material/BatchPredictionOutlined"
import BoltIcon from "@mui/icons-material/Bolt"
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined"
import CodeIcon from "@mui/icons-material/Code"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined"
import DescriptionIcon from "@mui/icons-material/Description"
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined"
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined"
import MediationOutlinedIcon from "@mui/icons-material/MediationOutlined"
import ModelTrainingIcon from "@mui/icons-material/ModelTraining"
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined"
import ScoreOutlinedIcon from "@mui/icons-material/ScoreOutlined"
import SettingsIcon from "@mui/icons-material/Settings"
import SubjectIcon from "@mui/icons-material/Subject"
import TagIcon from "@mui/icons-material/Tag"
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined"
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined"
import TimelineIcon from "@mui/icons-material/Timeline"
import {
  Accordion,
  AccordionSummary,
  Divider,
  Drawer,
  Grid,
  ListItemButton,
  SvgIcon,
  Switch,
  useTheme as useMUITheme,
  useMediaQuery,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Hidden from "@mui/material/Hidden"
import List from "@mui/material/List"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Skeleton from "@mui/material/Skeleton"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import { Menu, MenuItem, Tooltip as SUIToolTip, Tag, Typography } from "@synapse-analytics/synapse-ui"
import queryString from "query-string"

import { KonanAvatar } from "../../../components/Avatar"
import { ProjectType } from "../../../components/ProjectType"
import { CustomTooltip } from "../../../components/UI/CustomTooltip"
import { BaseSimpleDialog } from "../../../components/dialogs/BaseSimpleDialog"
import { KonanLogo } from "../../../components/icons/KonanLogo"
import { useTheme } from "../../../hooks/UseTheme"
import { useThrottle } from "../../../hooks/useThrottle"
import { SidAPI } from "../../../services/SidAPI"
import { CurrentProjectAndModelContext } from "../../../store/CurrentProjectAndModelContext"
import { RootState } from "../../../store/ReduxStore"
import { isPermitted } from "../../../utils/PermissionsHelpers"
import { Auth } from "../../../utils/auth"
import { getHostnames, getInitialLetters, getPosition } from "../../../utils/genericHelpers"

import styles from "../Dashboard.module.scss"
import "./DashboardDrawer.scss"

type Props = {
  orgName: string | undefined
  mobileOpen: boolean
  handleDrawerToggle: () => void
}

const avatarSize = 24

export function DashboardDrawer(props: Readonly<Props>): React.ReactElement {
  const { mobileOpen, handleDrawerToggle, orgName } = props
  const [expanded, setExpanded] = useState(true)

  const [closeWarningDialogInfo, setCloseWarningDialogInfo] = useState<{
    isOpen: boolean
    actionType?: { name: "switch" | "navigate"; path?: string }
  }>({
    isOpen: false,
  })

  const [theme, setTheme] = useTheme()

  const MUITheme = useMUITheme()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const permissions = Auth.getPermissions()

  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)
  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const queryParams = useMemo(() => queryString?.parse(location.search), [location.search])

  const { currentProject, currentModel } = useContext(CurrentProjectAndModelContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState<boolean>(
    localStorage.getItem("is_drawer_collapsed") === "true",
  )

  const isSmDown = useMediaQuery(MUITheme.breakpoints.down("sm"))

  const isDrawerCollapsedOnBigScreen = isDrawerCollapsed && !isSmDown

  // Default width for the main drawer
  const drawerWidth = isDrawerCollapsedOnBigScreen ? 65 : 215

  const open = Boolean(anchorEl)

  // handler to toggle open state of logout menu, (on/off)
  // setting anchor null here to close in case it was opened already
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const firstName = useMemo(() => {
    return Auth.getFirstName()
  }, [])
  const lastName = useMemo(() => {
    return Auth.getLastName()
  }, [])
  const userEmail = useMemo(() => {
    return Auth.getEmail()
  }, [])
  const toggleDrawer =
    () =>
    (event: React.KeyboardEvent<HTMLElement>): void => {
      if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return
      }

      handleDrawerToggle()
    }

  // navigate to "/projects" if pathname includes "projects/" and not followed by anything other than "/"
  useEffect(() => {
    if (location.pathname.includes("/projects/") && !location.pathname.match(/^\/projects\/[^/]+/)) {
      navigate("/projects")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleListItemClick = (path: string): void => {
    if (searchParams.get("EnableClose") === "false") {
      setCloseWarningDialogInfo({
        isOpen: true,
        actionType: { name: "navigate", path },
      })
    } else {
      navigate(path)
    }
  }

  const handleAcceptCloseDialog = (): void => {
    switch (closeWarningDialogInfo?.actionType?.name) {
      case "navigate":
        navigate(closeWarningDialogInfo?.actionType?.path as string, {
          replace: true,
        })
        break

      case "switch":
        setTheme(theme === "dark" ? "light" : "dark")
        break

      default:
        break
    }

    setCloseWarningDialogInfo({ isOpen: false })
  }

  const handleSwitchChange = (): void => {
    if (searchParams.get("EnableClose") === "false") {
      setCloseWarningDialogInfo({
        isOpen: true,
        actionType: { name: "switch" },
      })
    } else {
      setTheme(theme === "dark" ? "light" : "dark")
    }
  }

  const handleMainDrawerToggle = (): void => {
    setIsDrawerCollapsed(!isDrawerCollapsed)
    localStorage.setItem("is_drawer_collapsed", String(!isDrawerCollapsed))
  }

  const throttledToggle = useThrottle(handleMainDrawerToggle, 500)

  const BottomDrawerList = (
    <Fragment>
      <Hidden smDown>
        <Divider className={styles.divider} />
      </Hidden>

      <Hidden smDown>
        <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Expand" : ""} placement="right">
          <ListItemButton
            sx={{
              width: drawerWidth,
              transition: "225ms ease-in-out",
            }}
            onClick={throttledToggle}
            key="Expand"
            dense
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                transition: "225ms ease-in-out",
              }}
            >
              {isDrawerCollapsedOnBigScreen ? (
                <KeyboardDoubleArrowRightOutlinedIcon fontSize="small" />
              ) : (
                <KeyboardDoubleArrowLeftIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
              primary={
                <Typography variant="a" variantColor={2}>
                  Collapse
                </Typography>
              }
            />
          </ListItemButton>
        </CustomTooltip>
      </Hidden>

      <Grid container item xs={12} style={{ display: "flex", padding: "4px 16px 4px 8px" }}>
        <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Switch Theme" : ""} placement="right">
          <Grid item>
            <Switch checked={theme === "dark"} onChange={handleSwitchChange} name="Theme_switch" color="primary" />
          </Grid>
        </CustomTooltip>

        {!isDrawerCollapsedOnBigScreen && (
          <Grid item alignSelf={"center"}>
            <Typography variant="a" variantColor={2}>
              {theme === "dark" ? "Dark" : "Light"} Theme
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} className={styles.paddingContainer}>
        <a
          href={`https://${getHostnames().docs}/`}
          rel="noopener noreferrer"
          target="_blank"
          onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
          className={styles.documentationItem}
        >
          <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Documentation" : ""} placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
              }}
              className="desc-icon"
            >
              <DescriptionIcon fontSize="small" />
            </ListItemIcon>
          </CustomTooltip>

          <ListItemText
            sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
            primary={
              <Typography variant="a" variantColor={2}>
                Documentation
              </Typography>
            }
          />
          <ListItemIcon className="desc-icon">
            <OpenInNewIcon fontSize="small" />
          </ListItemIcon>
        </a>
      </Grid>

      <ListItemButton
        key="Account"
        onClick={handleMenu}
        classes={{
          selected: styles.selectedList,
        }}
        dense
        sx={{
          width: drawerWidth,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
          }}
        >
          <SvgIcon className={styles.avatar}>
            <KonanAvatar size={avatarSize} name={userEmail ?? "User"} />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText
          sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
          primary={
            <Typography variant="a" variantColor={2}>
              {firstName} {lastName}
            </Typography>
          }
        />
      </ListItemButton>

      <Menu
        className={styles.collapsedLogoutMenu}
        maxWidth={100}
        id="menu-appbar"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={async () => {
            await SidAPI.logOut({
              all_devices: false,
              refresh: localStorage.getItem("refresh_token") as string,
            }).catch(console.error)
            Auth.logOut(true, navigate)
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )

  const ModelRoutes = (): React.ReactElement => {
    return (
      <Fragment>
        <Divider className={styles.divider} />
        <List className={styles.list}>
          <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "API Requests" : ""} placement="right">
            <ListItemButton
              sx={{
                transition: "225ms ease-in-out",
                width: drawerWidth,
              }}
              id="api-requests-button"
              key="API-Requests"
              dense
              selected={location.pathname.includes("requests") && location.pathname.includes("models")}
              onClick={() =>
                handleListItemClick(
                  location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/models/requests"),
                )
              }
              classes={{
                selected: styles.selectedList,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                  justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                  transition: "225ms ease-in-out",
                }}
              >
                <ApiOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                primary={
                  <Typography
                    color={
                      location.pathname.includes("requests") && location.pathname.includes("models")
                        ? "important"
                        : "neutral"
                    }
                    variant="a"
                    variantColor={2}
                  >
                    API Requests
                  </Typography>
                }
              />
            </ListItemButton>
          </CustomTooltip>

          {currentProject?.type !== "generic" && (
            <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Classification Metrics" : ""} placement="right">
              <ListItemButton
                selected={location.pathname.includes("classification")}
                onClick={() =>
                  handleListItemClick(
                    location.pathname
                      .substring(0, getPosition(location.pathname, "/", 3))
                      .concat("/models/classification"),
                  )
                }
                sx={{
                  transition: "225ms ease-in-out",
                  width: drawerWidth,
                }}
                key="Classification-Metrics"
                dense
                classes={{
                  selected: styles.selectedList,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                    justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                    transition: "225ms ease-in-out",
                  }}
                >
                  <BubbleChartOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                  primary={
                    <Typography
                      variant="a"
                      variantColor={2}
                      color={location.pathname.includes("classification") ? "important" : "neutral"}
                    >
                      Classification Metrics
                    </Typography>
                  }
                />
              </ListItemButton>
            </CustomTooltip>
          )}

          <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Data Drift" : ""} placement="right">
            <ListItemButton
              selected={location.pathname.includes("drift")}
              onClick={() =>
                handleListItemClick(
                  location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/models/drift"),
                )
              }
              sx={{
                transition: "225ms ease-in-out",
                width: drawerWidth,
              }}
              key="Data-Drift"
              dense
              classes={{
                selected: styles.selectedList,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                  justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                  transition: "225ms ease-in-out",
                }}
              >
                <TimelineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                primary={
                  <Typography
                    variant="a"
                    variantColor={2}
                    color={location.pathname.includes("drift") ? "important" : "neutral"}
                  >
                    Data Drift
                  </Typography>
                }
              />
            </ListItemButton>
          </CustomTooltip>

          <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Model Logs" : ""} placement="right">
            <ListItemButton
              selected={location.pathname.includes("/models/logs")}
              onClick={() =>
                handleListItemClick(
                  location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/models/logs"),
                )
              }
              sx={{
                transition: "225ms ease-in-out",
                width: drawerWidth,
              }}
              key="Model-Logs"
              dense
              classes={{
                selected: styles.selectedList,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                  justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                  transition: "225ms ease-in-out",
                }}
              >
                <TerminalOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                primary={
                  <Typography
                    variant="a"
                    variantColor={2}
                    color={location.pathname.includes("/models/logs") ? "important" : "neutral"}
                  >
                    Model Logs
                  </Typography>
                }
              />
            </ListItemButton>
          </CustomTooltip>
        </List>
      </Fragment>
    )
  }

  const drawer = (
    <Box width="auto" onClick={handleDrawerToggle} onKeyDown={toggleDrawer}>
      <div className={styles.newOrganizationContainer}>
        {orgName ? (
          isDrawerCollapsedOnBigScreen ? (
            /* TODO: add default grey variant to it later  */
            <SUIToolTip placement="right" title={orgName}>
              <Tag className={styles.grayTag} size="small">
                {getInitialLetters(orgName)}
              </Tag>
            </SUIToolTip>
          ) : (
            <Typography variant="label" noWrap>
              {orgName}
            </Typography>
          )
        ) : (
          <Skeleton animation="wave" width={isDrawerCollapsedOnBigScreen ? 50 : 150} />
        )}
      </div>

      <Grid container className={styles.logo}>
        <Button
          onClick={() => handleListItemClick("/projects?page=model")}
          fullWidth
          className={isDrawerCollapsedOnBigScreen ? styles.collapesdLogoButton : styles.logoButton}
        >
          <KonanLogo
            logoWidth={isDrawerCollapsedOnBigScreen ? 130 : 180}
            isDrawerCollapsed={isDrawerCollapsedOnBigScreen}
            isDrawerView={!isSmDown}
          />
        </Button>
      </Grid>

      <Divider className={styles.bottomDivider} />
      <List className={styles.list}>
        <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Models" : ""} placement="right">
          <ListItemButton
            sx={{
              justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "flex-start",
              width: drawerWidth,
            }}
            key="Projects"
            onClick={() => handleListItemClick("/projects?page=model")}
            dense
            selected={location.pathname.includes("projects") && queryParams?.page === "model"}
            classes={{
              selected: styles.selectedList,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                transition: "225ms ease-in-out",
              }}
            >
              <BatchPredictionOutlinedIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText
              sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
              primary={
                <Typography
                  variant="a"
                  variantColor={2}
                  color={
                    location.pathname.includes("projects") && queryParams?.page === "model" ? "important" : "neutral"
                  }
                >
                  Models
                </Typography>
              }
            />
          </ListItemButton>
        </CustomTooltip>

        <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Workflows" : ""} placement="right">
          <ListItemButton
            sx={{
              width: drawerWidth,
            }}
            key="Projects"
            onClick={() => handleListItemClick("/projects?page=workflow")}
            dense
            selected={location.pathname.includes("projects") && queryParams?.page === "workflow"}
            classes={{
              selected: styles.selectedList,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                transition: "225ms ease-in-out",
              }}
            >
              <MediationOutlinedIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText
              sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
              primary={
                <Typography
                  variant="a"
                  variantColor={2}
                  color={
                    location.pathname.includes("projects") && queryParams?.page === "workflow" ? "important" : "neutral"
                  }
                >
                  Workflows
                </Typography>
              }
            />
          </ListItemButton>
        </CustomTooltip>

        <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "KCR" : ""} placement="right">
          <ListItemButton
            sx={{
              width: drawerWidth,
            }}
            key="registry"
            onClick={() => handleListItemClick("/kcr")}
            dense
            selected={location.pathname.includes("kcr")}
            classes={{
              selected: styles.selectedList,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                transition: "225ms ease-in-out",
              }}
            >
              <DnsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
              primary={
                <Typography
                  variant="a"
                  variantColor={2}
                  color={location.pathname.includes("kcr") ? "important" : "neutral"}
                >
                  KCR
                </Typography>
              }
            />
          </ListItemButton>
        </CustomTooltip>

        {(isPermitted("List roles", permissions.synapse_id, flattenedSidPermissions) ||
          isPermitted("List users", permissions.synapse_id, flattenedSidPermissions) ||
          isPermitted("List groups", permissions.synapse_id, flattenedSidPermissions)) && (
          <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Settings" : ""} placement="right">
            <ListItemButton
              sx={{
                transition: "225ms ease-in-out",
                width: drawerWidth,
              }}
              key="Settings"
              id="settings"
              onClick={() => handleListItemClick("/settings")}
              dense
              selected={location.pathname.includes("settings")}
              classes={{
                selected: styles.selectedList,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                  justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                  transition: "225ms ease-in-out",
                }}
              >
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                primary={
                  <Typography
                    variant="a"
                    variantColor={2}
                    color={location.pathname.includes("settings") ? "important" : "neutral"}
                  >
                    Settings
                  </Typography>
                }
              />
            </ListItemButton>
          </CustomTooltip>
        )}
      </List>

      <Divider className={styles.divider} />

      {location.pathname.includes("/projects/") &&
        location.pathname.match(/^\/projects\/[^/]+/) &&
        !location.pathname.includes("new") && (
          <Fragment>
            {!isDrawerCollapsedOnBigScreen && (
              <div className={styles.organizationContainer}>
                <Typography variant="h3-bold" noWrap>
                  {currentProject?.category ? (
                    currentProject?.category.charAt(0).toUpperCase() + currentProject?.category.slice(1)
                  ) : (
                    <Skeleton animation="wave" width={150} />
                  )}
                </Typography>
              </div>
            )}

            {currentProject?.category === "model" && !isDrawerCollapsedOnBigScreen && (
              <ProjectType
                type={currentProject?.type}
                margin={MUITheme.spacing(0, 0, 0, 2)}
                size="regular"
                skeletonWidth={100}
              />
            )}

            <List className={styles.list}>
              <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Live Decisions" : ""} placement="right">
                <ListItemButton
                  sx={{
                    transition: "225ms ease-in-out",
                    width: drawerWidth,
                  }}
                  key="Decisions"
                  onClick={() =>
                    handleListItemClick(
                      location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/decisions"),
                    )
                  }
                  dense
                  selected={location.pathname.includes("decisions")}
                  classes={{
                    selected: styles.selectedList,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                      justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                      transition: "225ms ease-in-out",
                    }}
                  >
                    <BoltIcon fontSize="small" />
                  </ListItemIcon>

                  <ListItemText
                    sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                    primary={
                      <Typography
                        variant="a"
                        variantColor={2}
                        color={location.pathname.includes("decisions") ? "important" : "neutral"}
                      >
                        Live Decisions
                      </Typography>
                    }
                  />
                </ListItemButton>
              </CustomTooltip>

              <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Data Files" : ""} placement="right">
                <ListItemButton
                  sx={{
                    transition: "225ms ease-in-out",
                    width: drawerWidth,
                  }}
                  key="Data-Files"
                  onClick={() =>
                    handleListItemClick(
                      location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/data-files"),
                    )
                  }
                  dense
                  selected={location.pathname.includes("data-files")}
                  classes={{
                    selected: styles.selectedList,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                      justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                      transition: "225ms ease-in-out",
                    }}
                  >
                    <FolderOutlinedIcon fontSize="small" />
                  </ListItemIcon>

                  <ListItemText
                    sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                    primary={
                      <Typography
                        variant="a"
                        variantColor={2}
                        color={location.pathname.includes("data-files") ? "important" : "neutral"}
                      >
                        Data Files
                      </Typography>
                    }
                  />
                </ListItemButton>
              </CustomTooltip>

              {currentProject?.category === "model" && (
                <Fragment>
                  <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Overview" : ""} placement="right">
                    <ListItemButton
                      sx={{
                        transition: "225ms ease-in-out",
                        width: drawerWidth,
                      }}
                      key="Overview"
                      onClick={() =>
                        handleListItemClick(
                          location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/overview"),
                        )
                      }
                      dense
                      selected={location.pathname.includes("overview")}
                      classes={{
                        selected: styles.selectedList,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                          justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                          transition: "225ms ease-in-out",
                        }}
                      >
                        <DashboardOutlinedIcon fontSize="small" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                        primary={
                          <Typography
                            variant="a"
                            variantColor={2}
                            color={location.pathname.includes("overview") ? "important" : "neutral"}
                          >
                            Overview
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </CustomTooltip>

                  <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Live Monitoring" : ""} placement="right">
                    <ListItemButton
                      sx={{
                        transition: "225ms ease-in-out",
                        width: drawerWidth,
                      }}
                      key="live-monitoring"
                      onClick={() =>
                        handleListItemClick(
                          location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/requests"),
                        )
                      }
                      dense
                      selected={location.pathname.includes("requests") && !location.pathname.includes("models")}
                      classes={{
                        selected: styles.selectedList,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                          justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                          transition: "225ms ease-in-out",
                        }}
                      >
                        <OnlinePredictionIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                        primary={
                          <Typography
                            variant="a"
                            variantColor={2}
                            color={
                              location.pathname.includes("requests") && !location.pathname.includes("models")
                                ? "important"
                                : "neutral"
                            }
                          >
                            Live Monitoring
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </CustomTooltip>

                  <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Models" : ""} placement="right">
                    <ListItemButton
                      sx={{
                        transition: "225ms ease-in-out",
                        width: drawerWidth,
                      }}
                      key="decision-engines"
                      onClick={() =>
                        handleListItemClick(
                          location.pathname
                            .substring(0, getPosition(location.pathname, "/", 3))
                            .concat("/decision-engines"),
                        )
                      }
                      dense
                      selected={location.pathname.includes("decision-engines")}
                      classes={{
                        selected: styles.selectedList,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                          justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                          transition: "225ms ease-in-out",
                        }}
                      >
                        <BatchPredictionOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                        primary={
                          <Typography
                            variant="a"
                            variantColor={2}
                            color={location.pathname.includes("decision-engines") ? "important" : "neutral"}
                          >
                            Models
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </CustomTooltip>
                </Fragment>
              )}

              {currentProject?.category === "workflow" && (
                <Accordion
                  expanded={expanded}
                  elevation={0}
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    margin: 0,
                    "&.Mui-expanded": {
                      margin: 0,
                    },
                    "& .MuiAccordionSummary-root": {
                      minHeight: "48px",
                      padding: "0px 10px 0px 0px",
                      "&.Mui-expanded": {
                        minHeight: "48px",
                      },
                    },
                    "& .MuiAccordionSummary-content": {
                      margin: 0,
                      "&.Mui-expanded": {
                        margin: 0,
                      },
                    },
                    "& .MuiIconButton-root": {
                      padding: "0px",
                      marginRight: "16px",
                    },
                  }}
                >
                  {!isDrawerCollapsedOnBigScreen && (
                    <AccordionSummary
                      onClick={() => {
                        // needed to avoid re-setting params when already current route in workflow
                        if (!location.pathname.includes("workflows")) {
                          navigate({
                            pathname: location.pathname
                              .substring(0, getPosition(location.pathname, "/", 3))
                              .concat("/workflows"),
                          })
                        }
                      }}
                      expandIcon={
                        <ExpandMoreIcon
                          onClick={(event) => {
                            // Prevents the accordion toggle from also triggering navigation
                            event.stopPropagation()
                            setExpanded(!expanded)
                          }}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <ListItemButton
                        component="div"
                        dense
                        selected={location.pathname.includes("workflows")}
                        classes={{
                          selected: styles.workflowSelectedList,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 0,
                            justifyContent: "inherit",
                          }}
                        >
                          <MediationOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="a"
                              variantColor={2}
                              color={location.pathname.includes("workflows") ? "important" : "neutral"}
                            >
                              Workflows
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </AccordionSummary>
                  )}

                  {isDrawerCollapsedOnBigScreen && (
                    <CustomTooltip title={"Workflows"} placement="right">
                      <ListItemButton
                        sx={{
                          justifyContent: "center",
                          width: drawerWidth,
                        }}
                        onClick={() => {
                          // needed to avoid re-setting params when already current route in workflow
                          if (!location.pathname.includes("workflows")) {
                            navigate({
                              pathname: location.pathname
                                .substring(0, getPosition(location.pathname, "/", 3))
                                .concat("/workflows"),
                            })
                          }
                        }}
                        selected={location.pathname.includes("workflows")}
                        classes={{
                          selected: styles.selectedList,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: "auto",
                            justifyContent: "center",
                          }}
                        >
                          <MediationOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                      </ListItemButton>
                    </CustomTooltip>
                  )}

                  <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Rulesets" : ""} placement="right">
                    <ListItemButton
                      sx={{
                        transition: "225ms ease-in-out",
                        width: drawerWidth,
                      }}
                      key="Rulesets"
                      onClick={() =>
                        handleListItemClick(
                          location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/Rulesets"),
                        )
                      }
                      dense
                      selected={location.pathname.includes("Rulesets")}
                      classes={{
                        selected: styles.selectedList,
                      }}
                    >
                      <ListItemIcon
                        className={!isDrawerCollapsedOnBigScreen ? styles.indentIcon : ""}
                        sx={{
                          minWidth: 0,
                          mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                          justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                          transition: "225ms ease-in-out",
                        }}
                      >
                        <RuleFolderOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                        primary={
                          <Typography
                            variant="a"
                            variantColor={2}
                            color={location.pathname.includes("Rulesets") ? "important" : "neutral"}
                          >
                            Rulesets
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </CustomTooltip>

                  {isPermitted("List taglists", permissions.konan, flattenedKonanPermissions) && (
                    <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Taglists" : ""} placement="right">
                      <ListItemButton
                        sx={{
                          transition: "225ms ease-in-out",
                          width: drawerWidth,
                        }}
                        key="Taglists"
                        onClick={() =>
                          handleListItemClick(
                            location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/Taglists"),
                          )
                        }
                        dense
                        selected={location.pathname.includes("Taglists")}
                        classes={{
                          selected: styles.selectedList,
                        }}
                      >
                        <ListItemIcon
                          className={!isDrawerCollapsedOnBigScreen ? styles.indentIcon : ""}
                          sx={{
                            minWidth: 0,
                            mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                            justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                            transition: "225ms ease-in-out",
                          }}
                        >
                          <TagIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                          primary={
                            <Typography
                              variant="a"
                              variantColor={2}
                              color={location.pathname.includes("Taglists") ? "important" : "neutral"}
                            >
                              Taglists
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </CustomTooltip>
                  )}

                  <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Scorecards" : ""} placement="right">
                    <ListItemButton
                      sx={{
                        transition: "225ms ease-in-out",
                        width: drawerWidth,
                      }}
                      key="Scorecards"
                      onClick={() =>
                        handleListItemClick(
                          location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/Scorecards"),
                        )
                      }
                      dense
                      selected={location.pathname.includes("Scorecards")}
                      classes={{
                        selected: styles.selectedList,
                      }}
                    >
                      <ListItemIcon
                        className={!isDrawerCollapsedOnBigScreen ? styles.indentIcon : ""}
                        sx={{
                          minWidth: 0,
                          mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                          justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                          transition: "225ms ease-in-out",
                        }}
                      >
                        <ScoreOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                        primary={
                          <Typography
                            variant="a"
                            variantColor={2}
                            color={location.pathname.includes("Scorecards") ? "important" : "neutral"}
                          >
                            Scorecards
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </CustomTooltip>

                  <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Programs" : ""} placement="right">
                    <ListItemButton
                      sx={{
                        transition: "225ms ease-in-out",
                        width: drawerWidth,
                      }}
                      key="Programs"
                      onClick={() =>
                        handleListItemClick(
                          location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/Programs"),
                        )
                      }
                      dense
                      selected={location.pathname.includes("Programs")}
                      classes={{
                        selected: styles.selectedList,
                      }}
                    >
                      <ListItemIcon
                        className={!isDrawerCollapsedOnBigScreen ? styles.indentIcon : ""}
                        sx={{
                          minWidth: 0,
                          mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                          justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                          transition: "225ms ease-in-out",
                        }}
                      >
                        <SubjectIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                        primary={
                          <Typography
                            variant="a"
                            variantColor={2}
                            color={location.pathname.includes("Programs") ? "important" : "neutral"}
                          >
                            Programs
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </CustomTooltip>

                  <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Scripts" : ""} placement="right">
                    <ListItemButton
                      sx={{
                        transition: "225ms ease-in-out",
                        width: drawerWidth,
                      }}
                      key="Scripts"
                      onClick={() =>
                        handleListItemClick(
                          location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/Scripts"),
                        )
                      }
                      dense
                      selected={location.pathname.includes("Scripts")}
                      classes={{
                        selected: styles.selectedList,
                      }}
                    >
                      <ListItemIcon
                        className={!isDrawerCollapsedOnBigScreen ? styles.indentIcon : ""}
                        sx={{
                          minWidth: 0,
                          mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                          justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                          transition: "225ms ease-in-out",
                        }}
                      >
                        <CodeIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                        primary={
                          <Typography
                            variant="a"
                            variantColor={2}
                            color={location.pathname.includes("Scripts") ? "important" : "neutral"}
                          >
                            Scripts
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </CustomTooltip>
                  <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Simulation Reports" : ""} placement="right">
                    <ListItemButton
                      sx={{
                        transition: "225ms ease-in-out",
                        width: drawerWidth,
                      }}
                      key="SimulationReports"
                      onClick={() =>
                        handleListItemClick(
                          location.pathname
                            .substring(0, getPosition(location.pathname, "/", 3))
                            .concat("/SimulationReports"),
                        )
                      }
                      dense
                      selected={location.pathname.includes("SimulationReports")}
                      classes={{
                        selected: styles.selectedList,
                      }}
                    >
                      <ListItemIcon
                        className={!isDrawerCollapsedOnBigScreen ? styles.indentIcon : ""}
                        sx={{
                          minWidth: 0,
                          mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                          justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                          transition: "225ms ease-in-out",
                        }}
                      >
                        <TextSnippetOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                        primary={
                          <Typography
                            variant="a"
                            variantColor={2}
                            color={location.pathname.includes("SimulationReports") ? "important" : "neutral"}
                          >
                            Simulation Reports
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </CustomTooltip>
                </Accordion>
              )}

              {currentProject?.category === "model" && (
                <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Model Training" : ""} placement="right">
                  <ListItemButton
                    sx={{
                      transition: "225ms ease-in-out",
                      width: drawerWidth,
                    }}
                    key="Model-training"
                    onClick={() =>
                      handleListItemClick(
                        location.pathname
                          .substring(0, getPosition(location.pathname, "/", 3))
                          .concat("/model-training"),
                      )
                    }
                    dense
                    selected={location.pathname.includes("model-training")}
                    classes={{
                      selected: styles.selectedList,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                        justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                        transition: "225ms ease-in-out",
                      }}
                    >
                      <ModelTrainingIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                      primary={
                        <Typography
                          variant="a"
                          variantColor={2}
                          color={location.pathname.includes("model-training") ? "important" : "neutral"}
                        >
                          Model Training
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </CustomTooltip>
              )}

              <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "Integration" : ""} placement="right">
                <ListItemButton
                  sx={{
                    transition: "225ms ease-in-out",
                    width: drawerWidth,
                  }}
                  key="Model-integration"
                  onClick={() =>
                    handleListItemClick(
                      location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/integration"),
                    )
                  }
                  dense
                  id="integration"
                  selected={location.pathname.includes("integration")}
                  classes={{
                    selected: styles.selectedList,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                      justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                      transition: "225ms ease-in-out",
                    }}
                  >
                    <IntegrationInstructionsOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                    primary={
                      <Typography
                        variant="a"
                        variantColor={2}
                        color={location.pathname.includes("integration") ? "important" : "neutral"}
                      >
                        Integration
                      </Typography>
                    }
                  />
                </ListItemButton>
              </CustomTooltip>

              {currentProject && !(currentProject.category === "model" && currentProject?.type === "generic") && (
                <CustomTooltip title={isDrawerCollapsedOnBigScreen ? "EKYC" : ""} placement="right">
                  <ListItemButton
                    sx={{
                      transition: "225ms ease-in-out",
                      width: drawerWidth,
                    }}
                    key="kyc"
                    onClick={() =>
                      handleListItemClick(
                        location.pathname.substring(0, getPosition(location.pathname, "/", 3)).concat("/ekyc"),
                      )
                    }
                    dense
                    selected={location.pathname.includes("ekyc")}
                    classes={{
                      selected: styles.selectedList,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isDrawerCollapsedOnBigScreen ? "auto" : 0,
                        justifyContent: isDrawerCollapsedOnBigScreen ? "center" : "inherit",
                        transition: "225ms ease-in-out",
                      }}
                    >
                      <AssignmentIndOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ opacity: isDrawerCollapsedOnBigScreen ? 0 : 1 }}
                      primary={
                        <Typography
                          variant="a"
                          variantColor={2}
                          color={location.pathname.includes("ekyc") ? "important" : "neutral"}
                        >
                          EKYC
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </CustomTooltip>
              )}
            </List>
            {currentModel && <ModelRoutes />}
            <Hidden smUp>
              <Divider className={styles.divider} />
            </Hidden>
          </Fragment>
        )}

      <List className={isSmDown ? styles.list : styles.bottomListItems}>{BottomDrawerList}</List>
    </Box>
  )

  return (
    <Fragment>
      {closeWarningDialogInfo?.isOpen && (
        <BaseSimpleDialog
          onClose={() => setCloseWarningDialogInfo({ isOpen: false })}
          open
          mode="close-while-edit"
          isLoading={false}
          name=""
          onAccept={handleAcceptCloseDialog}
        />
      )}

      <nav
        className={isDrawerCollapsedOnBigScreen ? styles.collapsedNav : !isSmDown ? styles.nav : undefined}
        aria-label="mailbox folders"
      >
        <Hidden smUp implementation="js">
          <SwipeableDrawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            classes={{
              paper: styles.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="js">
          <Drawer
            classes={{
              paper: isDrawerCollapsedOnBigScreen ? styles.collapsedDrawerPaper : styles.drawerPaper,
            }}
            variant="permanent"
            open={!isDrawerCollapsedOnBigScreen}
            onClose={handleMainDrawerToggle}
          >
            <div className={styles.scrollableDrawer}>{drawer}</div>
          </Drawer>
        </Hidden>
      </nav>
    </Fragment>
  )
}
