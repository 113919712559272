import React from "react"

import { useLocation, useNavigate } from "react-router-dom"

import { Box, Grid, Skeleton } from "@mui/material"
import { ResponsiveHeatMap } from "@nivo/heatmap"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { ConfusionMatrixInput, MetricsState } from "../../types/custom/projects"
import { truncateString } from "../../utils/genericHelpers"
import { GraphHeader } from "../GraphHeader"
import { InfoContainer } from "../InfoContainer"

type Props = {
  title: string
  data: ConfusionMatrixInput[]
  isLoading: boolean
  graphHeight: number
  emptyState?: MetricsState | undefined
  range?: number
}

export function ConfusionMatrix(props: Props): React.ReactElement {
  const { title, data, isLoading, graphHeight, emptyState, range } = props

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const theme = getTheme()

  return (
    <div className={"charts-paper"}>
      <GraphHeader
        title={title}
        tooltipText={
          <React.Fragment>
            A confusion matrix provides a <strong>summary</strong> of the number of <strong>correct</strong> and{" "}
            <strong>incorrect</strong> predictions, broken down by each label.
            <br />
            <br />
            <em>Only works for predictions with feedback.</em>
          </React.Fragment>
        }
        range={range}
      />

      <Box style={{ height: graphHeight, padding: "16px 16px 0px 16px" }}>
        {(!data || data.length < 1) && !isLoading && emptyState ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            {emptyState?.title ? (
              <InfoContainer
                title={emptyState?.title}
                subtitle={emptyState?.description}
                action={
                  emptyState?.action ? (
                    <Button
                      variant="primary"
                      onClick={() => navigate(`${pathname.substring(0, pathname.indexOf("/models"))}/integration`)}
                    >
                      {emptyState?.action}
                    </Button>
                  ) : undefined
                }
              />
            ) : (
              <Typography variant="p" variantColor={2}>
                No predictions or feedback
              </Typography>
            )}
          </Grid>
        ) : isLoading || !data ? (
          // Loading skeletons
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className={"full-height"}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="75%" />
            <Skeleton animation="wave" width="60%" height={30} />
            <Skeleton animation="wave" width="30%" />
          </Grid>
        ) : (
          <ResponsiveHeatMap
            data={data}
            theme={NivoTheme(theme)}
            margin={{ top: 24, right: 36, bottom: 48, left: 84 }}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendOffset: 46,
              format: (v) => truncateString(v, 8),
            }}
            axisRight={{
              tickSize: 0,
              legendPosition: "middle",
              legendOffset: 20,
              legend: "Actual Values",
              //disable ticks
              format: () => "",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -72,
              format: (v) => truncateString(v, 12),
            }}
            axisBottom={{
              tickSize: 0,
              legendPosition: "middle",
              legendOffset: 20,
              legend: "Predicted Values",
              //disable ticks
              format: () => "",
            }}
            colors={{
              type: "diverging",
              scheme: "blues",
              divergeAt: 0,
            }}
            emptyColor={theme.palette.gray.background[1]}
            borderRadius={2}
            inactiveOpacity={1}
            borderColor={{ theme: "background" }}
            labelTextColor={{ theme: "labels.text.fill" }}
            tooltip={({ cell: { color, id, value } }) => (
              <div className={"chart-tooltip"}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Grid item>
                    <span key="chip" style={{ display: "block", width: "12px", height: "12px", background: color }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="label" variantColor={2}>
                      {id.replace(".", " - ")} : {value}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          />
        )}
      </Box>
    </div>
  )
}
