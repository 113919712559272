import React from "react"

import { Box } from "@mui/material"
import { ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../../../hooks/UseTheme"
import { NivoTheme } from "../../../../../themes/nivoTheme"
import { truncateString } from "../../../../../utils/genericHelpers"

type Props = {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  graphHeight: number
}

export function DatetimeHistogram(props: Readonly<Props>): React.ReactElement {
  const { name, data, graphHeight } = props

  const theme = getTheme()

  const sortedData = React.useMemo(() => {
    const newArr = Array.from(data)
    if (newArr.length > 9) {
      // get the sum of other values
      const sortedArray = newArr.sort((a, b) => b.count - a.count)
      const otherCount = sortedArray.slice(9).reduce((acc, obj) => {
        return acc + obj.count
      }, 0)
      return [
        { [name]: "Other (" + (newArr.length - 9) + ")", count: otherCount },
        ...sortedArray.slice(0, 9).sort((a, b) => a.count - b.count),
      ]
    } else {
      return newArr.sort((a, b) => a.count - b.count).slice(0, 9)
    }
  }, [data, name])

  return (
    <Box style={{ height: graphHeight }}>
      <ResponsiveBar
        data={sortedData}
        theme={NivoTheme(theme)}
        indexBy={name}
        keys={["count"]}
        colors={["#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b"]}
        colorBy="indexValue"
        borderWidth={1}
        margin={{ top: 20, right: 10, bottom: 25, left: 100 }}
        padding={sortedData.length < 3 ? 0.9 : 0}
        layout="horizontal"
        valueScale={{ type: "linear" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          format: (v) => truncateString(v as string, 12),
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
        }}
        enableLabel={true}
        enableGridY={false}
        enableGridX={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        tooltip={(slice) => {
          return (
            <div className={"chart-tooltip"}>
              <Typography variant="h3-bold" variantColor={2}>
                {slice.data[name]}
              </Typography>
              <Typography variant="p" variantColor={2}>
                {`Count: ${slice.data.count.toFixed(0)}`}
              </Typography>
            </div>
          )
        }}
      />
    </Box>
  )
}
