import React from "react"

import Skeleton from "@mui/material/Skeleton"
import { Tag } from "@synapse-analytics/synapse-ui"

type Props = {
  modelState: string | undefined
  size?: "small" | "regular"
  skeletonWidth?: number
  margin?: number | string
}

export function ModelType(props: Props): React.ReactElement {
  const { modelState, size = "regular", margin, skeletonWidth = 50 } = props

  const animation: "wave" | "pulse" = "pulse"

  return (
    <React.Fragment>
      {modelState ? (
        <Tag
          size={size}
          // TODO:: refactor to use enums
          variant={
            modelState === "challenger"
              ? "orange"
              : modelState === "live"
                ? "positive"
                : modelState === "pending-action"
                  ? "warning"
                  : "default"
          }
          style={{ margin: margin }}
        >
          {modelState === "live"
            ? "Live"
            : modelState === "disabled"
              ? "Idle"
              : modelState === "pending-action"
                ? "Pending Action"
                : "Challenger"}
        </Tag>
      ) : (
        <Skeleton width={skeletonWidth} animation={animation} style={{ display: "inline-block", margin: margin }} />
      )}
    </React.Fragment>
  )
}
