import React, { ChangeEvent, useEffect } from "react"

import ReactGA from "react-ga4"
import { useMutation, useQuery } from "react-query"

import * as Yup from "yup"
import { CircularProgress, Dialog, DialogActions, DialogContent, Grid, useMediaQuery, useTheme } from "@mui/material"
import {
  Button,
  Checkbox,
  InputChangeEvent,
  InputChips,
  NotificationUtils,
  Snackbar,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import moment from "moment"

import { queryClient } from "../.."
import { KonanAvatarComponent } from "../../components/KonanAvatar"
import { SelectWithSearch } from "../../components/UI/SelectWithSearch"
import { SidAPI } from "../../services/SidAPI"
import { EditUserRequest } from "../../types/custom/Permissions"
import { GroupRetrieve } from "../../types/generated/authentication/GroupRetrieve"
import { OrganizationInvitationBulkRequest } from "../../types/generated/authentication/OrganizationInvitationBulkRequest"
import { OrganizationInvitationBulkResponse } from "../../types/generated/authentication/OrganizationInvitationBulkResponse"
import { OrganizationUser } from "../../types/generated/authentication/OrganizationUser"
import { Role } from "../../types/generated/authentication/Role"
import { Auth } from "../../utils/auth"
import { getRoleDisplayName } from "../../utils/rolesHelpers"
import { MemberInviteDialogProps } from "./Interfaces"

import styles from "./Members.module.scss"

//Adapted from https://stackoverflow.com/questions/61891269/yup-validation-how-to-get-the-value
const validationSchema = Yup.object({
  email: Yup.array()
    .required("You must invite at least 1 member")
    .of(Yup.string().email((el) => el.value + " is an invalid email."))
    .test({
      test: (arr) => (arr as string[]).length > 0,
    }),
  role: Yup.string().required("You must choose a role"),
  groups: Yup.array()
    .of(Yup.string().required())
    .required("You must choose at least 1 group")
    .test({
      test: (arr) => (arr as string[]).length > 0,
    }),
})

/**
 * Component Dialog for inviting new users(s), only admin can invite users.
 * @param {boolean} isOpen indicates dialog open or not
 * @param {void} onClose callback fn to handle closing dialog
 * @param {OrganizationUser} member callback fn to handle closing dialog
 * @returns {React.ReactElement}
 */
export function MemberInviteDialog(props: Readonly<MemberInviteDialogProps>): React.ReactElement {
  const { isOpen, onClose, member } = props

  const OrganizationUID = Auth.getOrganizationUID() as string

  const theme = useTheme()

  // Fetching organization roles
  const { isLoading: isRolesLoading, data: roles } = useQuery<AxiosResponse<Role[]>, AxiosError>(
    ["roles", OrganizationUID],
    () => SidAPI.fetchRoles(OrganizationUID),
    {
      refetchOnMount: false,
      enabled: !!OrganizationUID,
    },
  )

  // Fetching organization groups
  const { isLoading: isGroupsLoading, data: groups } = useQuery<AxiosResponse<GroupRetrieve[]>, AxiosError>(
    ["groups", OrganizationUID],
    () => SidAPI.fetchGroups(OrganizationUID),
    {
      enabled: !!OrganizationUID,
      refetchOnMount: false,
    },
  )

  // sending user invitations
  const inviteUserMutation = useMutation<
    AxiosResponse<OrganizationInvitationBulkResponse>,
    AxiosError,
    {
      organizationUUID: string
    } & OrganizationInvitationBulkRequest
  >(SidAPI.inviteUsers, {
    onSuccess: async (response) => {
      response.data.fail.map((item) =>
        NotificationUtils.toast(
          item?.detail?.sender ??
            item?.detail?.email[0].replace("User", `User [${item.email}]`) ??
            `An error occurred when attempting to send a invitation to this email [${item.email}].`,
          {
            snackBarVariant: "negative",
          },
        ),
      )
      await queryClient.invalidateQueries("Organization")
      await queryClient.invalidateQueries("OrganizationInvitation")
    },
  })

  // edit user
  const editUserMutation = useMutation<AxiosResponse, AxiosError, EditUserRequest>(SidAPI.editUser, {
    onSuccess: async () => {
      NotificationUtils.toast("Member updated successfully", {
        snackBarVariant: "positive",
      })

      await queryClient.invalidateQueries("Organization")
      await queryClient.invalidateQueries("OrganizationInvitation")

      await queryClient.invalidateQueries(["roles", OrganizationUID])
      await queryClient.invalidateQueries(["groups", OrganizationUID])

      await queryClient.invalidateQueries(["change-history", OrganizationUID])
    },
  })

  const closeDialog = (): void => {
    // Call parent handler
    onClose()

    // To prevent flickering of Snackbars when closing the dialog
    setTimeout(() => {
      inviteUserMutation.reset()
      formik.resetForm()
    }, 300)
  }

  // init formik
  const formik = useFormik({
    initialValues: {
      email: member?.email ? [member?.email] : [],
      role: member?.role.uid ?? "",
      groups: member?.groups.map((group) => group.uid) ?? [],
      lastInput: "",
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (member) {
        await editUserMutation.mutateAsync({
          userId: member.uid,
          role: values.role,
          groups: values.groups,
        })
      } else {
        // invite new users
        try {
          //if the user forgot to press enter and the last email entered is valid
          const res = await inviteUserMutation.mutateAsync({
            organizationUUID: OrganizationUID,
            role: values.role,
            groups: values.groups,
            emails:
              //if the user forgot to press enter and the last email entered is valid
              !!values.lastInput && Yup.string().email().isValidSync(values.lastInput)
                ? [...values.email, values.lastInput]
                : values.email,
            expiry_date: moment().add(7, "days").toISOString(),
          })

          ReactGA.event({
            category: "Users",
            action: "Invited users to organization",
          })

          if (res.data.fail.length === 0) {
            NotificationUtils.toast(`Invitation successfully sent!`, { snackBarVariant: "positive" })
            resetForm({})
            closeDialog()
          }
          // TODO:: figure out error type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.response?.data?.detail) {
            NotificationUtils.toast(e.response.data.detail, { snackBarVariant: "negative" })
          } else {
            NotificationUtils.toast("An error occurred when trying to send invitations to your users.", {
              snackBarVariant: "negative",
            })
          }
        }
      }

      resetForm({})
      closeDialog()
    },
  })

  useEffect(() => {
    const resources: string[] = []

    if (member) {
      groups?.data.forEach((group) => {
        group.users.some((user: OrganizationUser) => user.email === member.email) && resources.push(group.uid)
      })

      formik.setFieldValue("groups", resources)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups?.data, member, member?.email])

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && formik.isSubmitting) {
          return undefined
        } else {
          formik.resetForm()
          closeDialog()
        }
      }}
      fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
      maxWidth="sm"
      PaperProps={{
        style: {
          position: "static",
        },
      }}
    >
      <DialogContent className="dialog-content-base">
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Typography variant="h2-bold">
              {member ? "Edit" : "Invite"} Member{!member && "s"}
            </Typography>
          </Grid>

          {/* Showing error snackbar */}
          {inviteUserMutation.data !== undefined && inviteUserMutation.data?.data?.fail?.length > 0 && (
            <Grid item xs={12} className={styles.newUserContainer}>
              {inviteUserMutation.data.data.fail.map((item) => (
                <Snackbar
                  variant="negative"
                  fullWidth
                  description={
                    item?.detail?.sender ||
                    item?.detail?.email ||
                    "An error occurred when attempting to send a invitation to this email."
                  }
                />
              ))}
            </Grid>
          )}

          <Grid item xs={12}>
            {member ? (
              <KonanAvatarComponent createdBy={member.email} title={member.email} createdAt={member.created_at} />
            ) : (
              <InputChips
                value={formik.values.lastInput}
                handleChange={(e: InputChangeEvent) => {
                  formik.setFieldValue("lastInput", e.target.value)
                  formik.validateForm()
                }}
                handleBlur={() => {
                  if (!!formik.values.lastInput && Yup.string().email().isValidSync(formik.values.lastInput)) {
                    formik.setFieldValue("email", [...formik.values.email, formik.values.lastInput])
                    formik.setFieldValue("lastInput", "")
                  }
                  formik.validateForm()
                }}
                setEnteredChipsValue={(emails) => {
                  formik.setFieldValue("email", emails)
                  formik.validateForm()
                }}
                enteredChipsValue={formik.values.email}
                email
                fullWidth
                label="Emails"
                placeholder={"John@doe.com"}
                description={(formik.errors.email as string) ?? "Press enter to add the email to the list"}
              />
            )}
          </Grid>

          <Grid container style={{ maxHeight: "400px" }}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h3-bold" gutterBottom>
                  Choose Role
                </Typography>
              </Grid>

              <Grid container item xs={12}>
                <SelectWithSearch
                  options={[
                    { label: "Select Role", value: "", isDisabled: true },
                    ...(roles?.data
                      .filter((role) => role.is_owner !== true)
                      .sort((a, b) => (a.name === "USER" ? -1 : b.name === "USER" ? 1 : 0))
                      .map((role) => {
                        return {
                          label: getRoleDisplayName(role?.name),
                          value: role?.uid,
                        }
                      }) ?? []),
                  ]}
                  initialValue={member?.role_label ? getRoleDisplayName(member.role_label) : "Select Role"}
                  placeHolder="Select Role"
                  searchInputPlaceHolder="Search roles"
                  isOptionsLoading={isRolesLoading}
                  fullWidth
                  onSelectMenuItem={(item: { label: string; value: string }) => {
                    formik.setFieldValue("role", item.value)
                  }}
                  hideDescription
                />
              </Grid>
            </Grid>

            <Grid item xs={12} mt={1.5}>
              <Grid item xs={12}>
                <Typography variant="h3-bold" gutterBottom>
                  Choose Group
                </Typography>
              </Grid>

              <Grid
                container
                item
                xs={12}
                spacing={1}
                className="scrollbar"
                style={{ flex: "1 1 auto", overflowY: "auto", maxHeight: "350px" }}
              >
                {/* Add loading state */}
                {!isGroupsLoading &&
                  [...(groups?.data ?? [])]
                    .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                    .map((group) => (
                      <Grid item xs={12} key={group.uid}>
                        <Checkbox
                          label={group.name}
                          name={group.name}
                          value={group.uid}
                          checked={formik.values.groups.includes(group.uid)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            // since the onChange can toggle between checked and unChecked and the method is custom
                            // not just setting a normal field in formik so we had to make that check
                            if (e.target?.checked) {
                              formik.setFieldValue("groups", [...formik.values.groups, group.uid])
                            } else {
                              formik.setFieldValue(
                                "groups",
                                formik.values.groups.filter((item: string) => item !== group.uid),
                              )
                            }
                          }}
                        />
                      </Grid>
                    ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Button onClick={closeDialog} variant="secondary" disabled={formik.isSubmitting}>
          Cancel
        </Button>
        <Button onClick={formik.submitForm} id="send-invite" variant="primary" disabled={!formik.isValid}>
          {formik.isSubmitting ? <CircularProgress size={20} color="inherit" /> : member ? "Edit" : "Invite"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
