import React from "react"

import CloseIcon from "@mui/icons-material/Close"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { ProjectSchemaTable } from "../../components/tables/ProjectSchemaTable"
import { getTheme } from "../../hooks/UseTheme"

interface Props {
  isOpen: boolean
  onClose: () => void
}

/**
 * Model Configuration dialog
 * @param {boolean} isOpen
 * @param {function} onClose
 * @returns
 */
export function ConfigurationDialog(props: Readonly<Props>): React.ReactElement {
  const { isOpen, onClose } = props

  const theme = getTheme()
  const MuiTheme = useMuiTheme()

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      fullWidth
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      onClose={onClose}
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2-bold">Project Configuration</Typography>

        <IconButton onClick={onClose} size="small" className="close-icon-button">
          <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        <Grid item marginBottom={2}>
          <ProjectSchemaTable />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
