import React, { useCallback, useEffect } from "react"

import { Handle, Node, Position, useReactFlow, useStoreApi } from "reactflow"

import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined"
import CloseIcon from "@mui/icons-material/Close"
import { Card, Grid, IconButton } from "@mui/material"
import { InputChangeEvent, InputText, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { FormikProps, useFormik } from "formik"

import { ValueBlock } from "../../../components/ValueBlock"
import { getTheme } from "../../../hooks/UseTheme"
import { SchemaFeature, UpdateGraphParams } from "../../../types/custom/workflows"
import { handleDeletingNodeInBetween } from "../../../utils/workflowHelpers"

import styles from "./CustomCards.module.scss"

// props for calculator node
interface CalculatorNodeType {
  id?: string
  menuView?: boolean
  data?: {
    updateGraph: (data: UpdateGraphParams) => void
    equation: string
    readMode: boolean
    computed_feature_name: string
    schema: Array<SchemaFeature>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>
  }
}

/**
 * calculator node component
 * @param {string} id calculator node id
 * @param {boolean} menuView indicator prop wether the view is menu or full canvas
 * @param data all important data for calculator nodes (condition and form values)
 * @return {React.ReactElement}
 */
export function CalculatorNode(props: Readonly<CalculatorNodeType>): React.ReactElement {
  const { menuView, data, id } = props

  const readMode = data?.readMode ?? null
  const computed_feature_name = data?.computed_feature_name ?? null
  const equation = data?.equation ?? null
  const nodeId = id ?? null
  const updateGraph = props.data?.updateGraph

  // instance of our state management that share our nodes and edges across all component
  const store = useStoreApi()
  const { setNodes, getNodes } = store.getState()

  const theme = getTheme()

  // this is reactflow helper function to delete passed nodes as well as all the connected edges from the passed nodes
  const { getEdges } = useReactFlow()

  // formik initial values
  const formik = useFormik({
    initialValues: {
      computed_feature_name: computed_feature_name ?? "",
      equation: equation?.slice(1, -1) ?? "",
      computedNameError: "",
    },
    onSubmit: async ({ computed_feature_name, computedNameError }) => {
      setNodes(
        getNodes().map((node) => {
          const newNode = { ...node }
          if (node.id === nodeId) {
            newNode.data = {
              ...newNode.data,
              computedNameError,
              computed_feature_name,
              // IMPORTANT: NEEDED FOR PARSING EQUATION WITH BE
              equation: `(${equation})`,
            }
          }
          return newNode
        }),
      )
    },
  })

  /**
   * Our onNodesDelete callback is called with one argument - deleted -
   * that is an array of every node that was just deleted.
   * If you select an individual node and press the delete key, deleted will contain just that node,
   * but if you make a selection all the nodes in that selection will be in deleted.
   */
  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      const newNodesAndEdges = handleDeletingNodeInBetween(deleted, getEdges(), getNodes(), nodeId as string)

      if (updateGraph) {
        updateGraph({ dataAfterDeletion: { nodes: newNodesAndEdges?.nodes, edges: newNodesAndEdges?.edges } })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getEdges, getNodes, nodeId],
  )

  // when removing a calculator node we call the deleteDescendants function first to get
  // a final list of updated nodes then updated our nodes state with the returned array
  const handleRemoveNode = (): void => {
    const deleted = getNodes().filter((node) => node.id === nodeId)

    onNodesDelete(deleted)

    NotificationUtils.toast(`Calculator node removed!`, {
      snackBarVariant: "positive",
    })
  }

  // this effect listens to any change happens to any of 2 formik values in calculator node
  // and update the node state with the changed values
  // if create/edit mode is true (creating or updating workflow)
  useEffect(() => {
    if (!readMode) {
      const { computed_feature_name, computedNameError, equation } = formik.values

      setNodes(
        getNodes().map((node) => {
          const newNode = { ...node }
          if (node.id === nodeId) {
            newNode.data = {
              ...newNode.data,
              computedNameError,
              computed_feature_name,
              equation: equation,
            }
          }
          return newNode
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.equation, formik.values.computed_feature_name, readMode])

  const handleComputedFeatureChange = (e: InputChangeEvent<Element>): void => {
    formik.handleChange(e)
    if (props?.data?.formik?.values?.features?.length > 0) {
      if (props?.data?.formik?.values?.features?.find((feat: SchemaFeature) => feat?.name === e?.target?.value)) {
        formik.setFieldValue(
          "computedNameError",
          `Computed feature name (${e?.target?.value}) can't be the same as feature in schema`,
        )
      } else {
        formik.setFieldValue("computedNameError", "")
      }
    }
  }

  const handleComputedFeatureBlur = (): void => {
    // check if this node has already an assigned computed feat name
    const computedFeatureIndex = props?.data?.formik?.values?.computedFeatures?.findIndex(
      (feat: { uuid: string; value: string }) => feat?.uuid === nodeId,
    )
    // check if it's a new computed feat name
    if (computedFeatureIndex === -1 && formik.values.computed_feature_name) {
      props?.data?.formik.setFieldValue("computedFeatures", [
        ...(props?.data?.formik?.values?.computedFeatures ?? []),
        { uuid: nodeId, value: formik.values.computed_feature_name },
      ])
    } else if (computedFeatureIndex !== -1 && formik.values.computed_feature_name) {
      props?.data?.formik.setFieldValue(
        `computedFeatures[${computedFeatureIndex}].value`,
        formik.values.computed_feature_name,
      )
    }
  }

  return (
    <Card className={`${menuView ? styles.card : styles.calculatorCard} disableKeyboardA11y nodrag`}>
      {!menuView && <Handle style={{ opacity: 0 }} type="target" position={Position.Top} id="b" isConnectable={true} />}
      <Grid container flexWrap="nowrap" gap={0.5}>
        <Grid item xs={1.2}>
          <Grid item className={styles.calculatorCardType}>
            <CalculateOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          item
          xs={10.6}
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs={11} paddingLeft={menuView ? 1.5 : 0}>
              <Typography variant="label" variantColor={2}>
                CALCULATOR
              </Typography>
            </Grid>
            {/* close Icon */}
            {!menuView && !readMode && (
              <Grid item marginRight={"-5px"} justifySelf={"flex-end"}>
                <IconButton style={{ marginTop: "-15px" }} size="small" onClick={() => handleRemoveNode()}>
                  <CloseIcon fontSize="small" className={styles.remove} />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Grid
            flexWrap={"nowrap"}
            item
            xs={12}
            container
            alignItems="flex-end"
            width="100%"
            paddingTop={menuView ? "0px" : readMode ? "5px" : "-4px"}
            height={!readMode ? "40px" : "30px"}
          >
            {/* computed_feature_name */}
            <Grid
              item
              xs={4}
              padding={menuView ? "0px 4px" : "0px"}
              margin={readMode ? "5px 3px 0px 0px" : menuView ? "0px 0px 0px 10px" : "-2px 3px 0px"}
            >
              {!readMode ? (
                <InputText
                  id="computed_feature_name"
                  placeholder="feature"
                  value={formik.values.computed_feature_name}
                  handleChange={(e) => handleComputedFeatureChange(e)}
                  handleBlur={(e) => {
                    formik.handleBlur(e)
                    handleComputedFeatureBlur()
                  }}
                  disabled={formik.isSubmitting || menuView}
                  fullWidth
                  hideDescription
                />
              ) : (
                <ValueBlock size={12} value={computed_feature_name ?? ""} />
              )}
            </Grid>

            {/* = */}
            <Grid item xs="auto" margin={readMode ? "-15px 0px 0px" : "-2px 3px 0px 0px"}>
              <Grid item xs="auto" marginRight={0.3} marginLeft={0.3} marginTop={2.5}>
                <Typography variant="a">{"="}</Typography>
              </Grid>
            </Grid>

            {/* Equation */}
            <Grid
              item
              xs={menuView || !readMode ? true : 7.5}
              justifySelf="flex-end"
              margin={readMode ? "5px 0px 0px 3px" : "-2px 0px 0px 2px"}
            >
              {!readMode ? (
                <InputText
                  id="equation"
                  placeholder="Equation"
                  value={formik.values.equation}
                  handleChange={formik.handleChange}
                  disabled={formik.isSubmitting || menuView}
                  fullWidth
                  hideDescription
                />
              ) : (
                <div className={styles.valueFieldMargin}>
                  <ValueBlock size={12} value={equation?.slice(1, -1) ?? ""} />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!menuView && (
        <Handle style={{ opacity: 0 }} type="source" position={Position.Bottom} id="a" isConnectable={true} />
      )}
    </Card>
  )
}
