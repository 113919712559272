import React, { ReactElement } from "react"

import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format, formatDistance } from "date-fns"

import { GraphHeader } from "./GraphHeader"

import styles from "./KonanTextualCard.module.scss"

type Props = {
  header: string
  cardHeight: number
  isLoading: boolean
  isFeedback?: boolean
  emptyState?: string
  tooltipText?: string | ReactElement
  value?: Date | undefined | string | number
  variant?: "neutral" | "positive" | "negative" | "important" | "warning"
}

/**
 * Generic text card
 * @param {string} header card header text
 * @param {number} cardHeight
 * @param {boolean} isLoading
 * @param {boolean} isFeedback (optional) feedback card mode
 * @param {string} emptyState (optional)
 * @param {string} tooltipText (optional)
 * @param {Date | undefined | string | number} value (optional)
 * @param {"neutral" | "positive" | "negative" | "important" | "warning"} variant (optional)
 * @return {React.ReactElement}
 */
export function KonanTextualCard(props: Props): React.ReactElement {
  const {
    value,
    cardHeight,
    isLoading,
    header,
    emptyState = "No data to display",
    isFeedback,
    tooltipText,
    variant = "neutral",
  } = props

  return (
    <div className={"charts-paper"}>
      <GraphHeader title={header} tooltipText={tooltipText} />

      <Box style={{ height: cardHeight, padding: "4px 16px 8px 16px" }}>
        {(value === null || value === undefined) && !isLoading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            className={"full-height"}
          >
            <Typography variant="p">{emptyState}</Typography>
          </Grid>
        ) : isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className={"full-height"}
          >
            <Skeleton animation="wave" width="50%" height="30px" />
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            style={{ height: cardHeight }}
          >
            <Grid container direction="row" justifyContent="center" alignItems="center" marginTop={1}>
              {isFeedback ? (
                <Typography variant="label" noWrap>
                  <Tooltip
                    title={
                      <Typography variant="h3-regular">{value && format(value as number, "dd/MM/yyyy, p")} </Typography>
                    }
                    placement="top"
                  >
                    <Typography variant="h1-bold">
                      {value &&
                        formatDistance(value as number, new Date(), {
                          addSuffix: true,
                        })}
                    </Typography>
                  </Tooltip>
                </Typography>
              ) : (
                <Grid item style={{ width: "100%" }}>
                  <Typography
                    variant="h1-bold"
                    style={{ height: "100%", textAlign: "center" }}
                    className={styles.labelSize}
                    noWrap
                    color={variant}
                    variantColor={2}
                  >
                    {value && `${value}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  )
}
