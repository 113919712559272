import React from "react"

import { Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

/**
 * Recharts graph custom labels components
 * GraphLabelText -> label
 *                   text   smallText
 *
 * GraphLabelText (bottomLabel variant) -> text smallText
 *                                         label
 * GraphLabelText (noLabel variant) -> text
 *                                         smallText
 * @return {React.ReactElement}
 */

interface GraphLabelProps {
  label: {
    name: string
    value: number
    percent: number
  }
  bottomLabel?: boolean
  noLabel?: boolean
}

export function GraphLabelText(props: GraphLabelProps): React.ReactElement {
  const { label, bottomLabel, noLabel } = props

  return (
    <Grid container direction={bottomLabel ? "column-reverse" : "column"}>
      <Grid container item xs={12}>
        <Typography variant="span" noWrap>
          {label.name}
        </Typography>
      </Grid>
      <Grid container item xs={12} wrap={noLabel ? "wrap" : "nowrap"} display={"row"}>
        <Grid item mr={0.5} xs={noLabel ? 12 : "auto"}>
          <Typography variant="h1-bold">{label.value}</Typography>
        </Grid>
        <Grid item alignSelf={"center"} xs={noLabel ? 12 : "auto"}>
          <Typography variant="span">{label.percent}%</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
