import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { FeatureData } from "../../types/custom/workflows"

/**
 * this slice used to store and update workflow schemas features (project schema mirror)
 * and computed features in all workflows to use them in different workflow component
 */
export const projectSlice = createSlice({
  name: "project",
  initialState: {
    computedFeatures: [] as FeatureData[],
    schemaFeatures: [] as FeatureData[],
  },
  reducers: {
    setComputedFeatures(state, action: PayloadAction<Array<FeatureData>>) {
      state.computedFeatures = action.payload
    },
    setSchemaFeatures(state, action: PayloadAction<Array<FeatureData>>) {
      state.schemaFeatures = action.payload
    },
  },
})

export const { setComputedFeatures, setSchemaFeatures } = projectSlice.actions
