import { Card, CardContent, CardHeader, Skeleton } from "@mui/material"

import styles from "./Scorecard.module.scss"

/**
 * Renders a loader for the scorecard component.
 *
 * @returns A React element representing the scorecard loader.
 */
export function ScoreCardLoader(): React.ReactElement {
  return (
    <Card className="card-box-shadow">
      <CardHeader className={styles.loaderCardHeader} title={<Skeleton width={200} />} />
      <CardContent className={styles.loaderCardContent}>
        <Skeleton height={30} />
        <Skeleton height={30} />
      </CardContent>
    </Card>
  )
}
