import { useCallback, useMemo } from "react"

import { GroupedOptions, GroupedSelectWithSearch } from "../../../components/UI/SelectWithSearch"
import { RequirementsDataFile, RequirementsListProps } from "../Interfaces"

export function RequirementsList(props: RequirementsListProps): React.ReactElement {
  const { requirements, setRequirementsFile, requirementsFile, isLoading } = props

  const formatDate = useCallback((date: Date): string => {
    // Format the date
    return date.toLocaleDateString("en-GB", {
      weekday: "long",
      day: "numeric",
      month: "short",
      year: "numeric",
    })
  }, [])

  const getDateString = useCallback(
    (date: string): string => {
      // if the date is not today or yesterday, show the full date
      const now = new Date()
      const today = formatDate(now)
      const yesterday = formatDate(new Date(now.setDate(now.getDate() - 1)))
      if (date === today) {
        return "Today"
      } else if (date === yesterday) {
        return "Yesterday"
      }
      return date
    },
    [formatDate],
  )

  // group requirements by day
  const groupedRequirements = useMemo(() => {
    return requirements
      .map((requirement) => {
        const date = new Date(requirement.createdAt as string)
        return {
          ...requirement,
          // if the date is not today or yesterday, show the full date
          date: getDateString(formatDate(date)),
          time: date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true, // Ensures 12-hour format with AM/PM
          }),
        }
      })
      .reduce(
        (acc, requirement) => {
          if (!acc[requirement.date]) {
            acc[requirement.date] = []
          }
          acc[requirement.date].push(requirement)
          return acc
        },
        {} as Record<
          string,
          {
            label?: string
            value?: string | null | undefined
            uuid?: string | undefined
            time?: string
            date?: string
            index?: number | undefined | null
          }[]
        >,
      )
  }, [requirements, formatDate, getDateString])

  // create map of <uuid, requirement>
  const requirementsMap = useMemo(() => {
    return requirements.reduce(
      (acc, requirement) => {
        acc[`${requirement.index}`] = requirement
        return acc
      },
      {} as Record<string, RequirementsDataFile>,
    )
  }, [requirements])

  const options = useMemo<GroupedOptions>(
    () =>
      Object.keys(groupedRequirements).reduce((acc, key) => {
        acc[key] = groupedRequirements[key].map((requirement) => {
          return {
            label: requirement.label as string,
            value: `${requirement.index}`,
            subtitle: requirement.time,
          }
        })
        return acc
      }, {} as GroupedOptions),
    [groupedRequirements],
  )

  return (
    <GroupedSelectWithSearch
      isOptionsLoading={isLoading}
      options={options}
      initialValue={requirementsFile.label}
      onSelectMenuItem={(item: { label: string; value: string }) => setRequirementsFile(requirementsMap[item.value])}
      searchInputPlaceHolder="Search"
      hideDescription
      fullWidth={false}
      currentValue={`${requirementsFile.index}`}
    />
  )
}
