import React from "react"

import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { BarDatum, ResponsiveBar } from "@nivo/bar"
import { TooltipWrapper } from "@nivo/tooltip"
import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { SummaryByDay } from "../../types/generated/api/SummaryByDay"
import { fillMissingRequestSummaryDates } from "../../utils/deploymentDetailsHelpers"

import styles from "./RequestsDailyBarChart.module.scss"

type Props = {
  data: SummaryByDay[]
  isLoading: boolean
  graphHeight: number
}

export function RequestsDailyBarChart(props: Props): React.ReactElement {
  const { data, isLoading, graphHeight } = props

  const theme = getTheme()

  const adjustedData: SummaryByDay[] = React.useMemo(() => {
    if (data && data.length > 0) {
      const newData = Array.from(data)
      const startDate = moment().subtract(27, "days")
      const endDate = moment()
      //map creation date to each model
      const finalData = fillMissingRequestSummaryDates(startDate, endDate, newData)
      return finalData
    }
    return []
  }, [data])

  return (
    <Grid className={styles.paper}>
      {isLoading || !data ? (
        <Skeleton animation="wave" height={60} />
      ) : (
        <Box style={{ height: graphHeight, padding: 0, margin: 0 }}>
          <ResponsiveBar
            data={adjustedData as BarDatum[]}
            theme={NivoTheme(theme)}
            indexBy="timestamp"
            keys={["alternateSuccessful", "alternateFailed", "empty"]}
            colors={[
              theme.palette.green.background[1],
              theme.palette.red.background[1],
              theme.palette.grayscale.border,
            ]}
            colorBy="id"
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            borderWidth={0}
            borderRadius={2}
            padding={0.4}
            layout="vertical"
            valueScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisLeft={null}
            enableLabel={false}
            enableGridY={false}
            animate={true}
            tooltip={(slice) => {
              const lastFiveBars = 28 - slice?.index <= 5
              return (
                <TooltipWrapper anchor={lastFiveBars ? "left" : "top"} position={[-10, 0]}>
                  <Grid sx={{ pointerEvents: "none" }}>
                    {/** IMPORTANT: TooltipWrapper will no longer work after nivo v0.83.1
                     * and nivo doesn't provide an out of the box solution for tooltip position
                     * we need to look for an alternative to handle dynamic tooltip position
                     * probably, will need to make a custom component with the needed functionality
                     */}
                    <Grid className={"chart-tooltip"}>
                      <Grid container direction={"row"} alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography noWrap variant="label" display="inline" variantColor={2}>
                            {slice.data.timestamp}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container xs={12} direction={"row"} alignItems={"flex-start"} wrap="nowrap">
                        <span key="chip" className={`${styles.block} ${styles.successful}`} />
                        <Typography
                          style={{ textAlign: "start", paddingTop: "2px" }}
                          noWrap
                          variant="label"
                          variantColor={2}
                        >
                          Successful: {slice.data.successful ?? 0}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12} direction={"row"} alignItems={"flex-start"}>
                        <Grid item>
                          <span
                            style={{ display: "block" }}
                            key="chip"
                            className={`${styles.block} ${styles.failed}`}
                          />
                        </Grid>
                        <Typography
                          style={{ textAlign: "start", paddingTop: "2px" }}
                          noWrap
                          variant="label"
                          variantColor={2}
                        >
                          Failed: {slice.data.failed ?? 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </TooltipWrapper>
              )
            }}
          />
        </Box>
      )}
    </Grid>
  )
}
