import React, { Fragment, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  SvgIcon,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import {
  Button,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import { FieldArray, FormikProvider, useFormik } from "formik"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { KonanAvatar } from "../../../components/Avatar"
import { KonanEmptyState } from "../../../components/KonanEmptyState"
import { VersionTag } from "../../../components/VersionTag"
import { BaseSimpleDialog } from "../../../components/dialogs/BaseSimpleDialog"
import { VersioningDialog } from "../../../components/dialogs/VersioningDialog"
import { getTheme } from "../../../hooks/UseTheme"
import { KonanAPI } from "../../../services/KonanAPI"
import { CreateProgramRequest, UpdateProgramRequest, baseErrorType } from "../../../types/custom/projects"
import { VersionChangeRequest } from "../../../types/custom/rules"
import { Deployment } from "../../../types/generated/api/Deployment"
import { PaginatedScoreCardSetListList } from "../../../types/generated/api/PaginatedScoreCardSetListList"
import { Program } from "../../../types/generated/api/Program"
import { ProgramItemRequest } from "../../../types/generated/api/ProgramItemRequest"
import { ProgramRetrieve } from "../../../types/generated/api/ProgramRetrieve"
import { Auth } from "../../../utils/auth"
import { getValidMaxMinorVersion } from "../../../utils/deploymentDetailsHelpers"
import { DataBlock } from "../../Ruleset/components/RuleCard"
import { LiveModelCard } from "./ProjectCard"
import { ScoreCardSetBlock } from "./ScoreCardSetBlock"

import styles from "../Programs.module.scss"

// type for URL params
type ParamsType = {
  id: string
}

interface ItemType {
  item_type: "project" | "scorecardset"
  scorecard_set?: { name: string; uuid: string | undefined }
  project?: { name: string; uuid: string | undefined }
  weight: string
}

type Props = {
  isLoading?: boolean
  program?: Program
  handleCancel?: () => void
  isNewProgram?: boolean
  key?: string
  projectsData?: Array<Deployment>
  scorecardSetData?: PaginatedScoreCardSetListList
  isScorecardSetsLoading?: boolean
  isProjectsLoading?: boolean
  disableDuplicateButton?: boolean
  duplicateProgram?: (program: Program) => void
}

// Yup validation schema
const validationSchemaScorecard = Yup.object({
  name: Yup.string().required("Program name is required"),
  items: Yup.array().of(
    Yup.object().shape({
      item_type: Yup.string().required("Item Type is required"),
      scorecard_set: Yup.object({
        name: Yup.string(),
        uuid: Yup.string(),
      }).when(["item_type"], {
        is: (item_type: string) => item_type === "scorecardset",
        then: () =>
          Yup.object({
            name: Yup.string().required("Scorecard name is required"),
            uuid: Yup.string().required("Scorecard uuid is required"),
          }),
      }),
      project: Yup.object({
        name: Yup.string(),
        uuid: Yup.string(),
      }).when(["item_type"], {
        is: (item_type: string) => item_type === "project",
        then: () =>
          Yup.object({
            name: Yup.string().required("Project name is required"),
            uuid: Yup.string().required("Project uuid is required"),
          }),
      }),
      weight: Yup.number().required("Weight is required"),
    }),
  ),
})

/**
 * Single Program component
 * @param {boolean} isLoading indicator for Program data loading or not
 * @param {function} handleCancel handler for opting out of Program creation
 * @param {Program} program program item
 * @param {Program} allPrograms program item
 * @param {boolean} isNewProgram enable/disable new program mode
 * @param {string} key unique key for the program
 * @param {Array<Deployment>} projectsData list of projects
 * @param {PaginatedScoreCardSetListList} scorecardSetData list of scorecard sets
 * @param {boolean} isScorecardSetsLoading indicator for scorecard sets loading or not
 * @param {boolean} isProjectsLoading indicator for projects loading or not
 * @param {boolean} disableDuplicateButton indicator for duplicate button
 * @param {function} duplicateProgram handler for duplicating a program
 * @returns {React.ReactElement}
 */
export function ProgramCard(props: Readonly<Props>): React.ReactElement {
  const {
    program,
    handleCancel,
    isLoading,
    isNewProgram = false,
    key,
    projectsData,
    scorecardSetData,
    isScorecardSetsLoading,
    isProjectsLoading,
    disableDuplicateButton,
    duplicateProgram,
  } = props

  const { id: projectId } = useParams<ParamsType>()

  const MuiTheme = useMuiTheme()
  const theme = getTheme()

  const smallActionButtons = useMediaQuery("(min-width:725px)")

  const userEmail = Auth.getEmail()

  const queryClient = useQueryClient()

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const [isEditMode, setIsEditMode] = useState<boolean>(isNewProgram ?? false)
  const [isVersioningDialogOpen, setIsVersioningDialogOpen] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  const [anchor2, setAnchor2] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchor2)

  const fullScreen = useMediaQuery(MuiTheme.breakpoints.down("md"))

  // fetch single program
  const { data: versionedProgram, isLoading: isProgramLoading } = useQuery<AxiosResponse<ProgramRetrieve>, AxiosError>(
    ["program", projectId, program?.uuid],
    () => KonanAPI.fetchProgram(projectId as string, program?.uuid as string),
    {
      onSuccess: () => {
        // to overwrite items data in formik
        formik.setFieldValue("items", program?.items)
      },
      enabled: !!projectId && !!program?.uuid,
    },
  )

  const createProgram = useMutation<AxiosResponse<Program>, AxiosError<baseErrorType>, CreateProgramRequest>(
    KonanAPI.createProgram,
    {
      onError: (data) => {
        // TODO:: check error types with backend
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          NotificationUtils.toast(data.response?.data.detail[0] ?? "An error occurred. please try again later.", {
            snackBarVariant: "negative",
          })
        } catch (e) {
          NotificationUtils.toast("An error occurred. please try again later.", {
            snackBarVariant: "negative",
          })
        }
      },
      onSuccess: async () => {
        isNewProgram && handleCancel?.()

        await queryClient.invalidateQueries("programs")
        await queryClient.invalidateQueries("program")
      },
    },
  )

  const updateProgram = useMutation<AxiosResponse, AxiosError<baseErrorType>, UpdateProgramRequest>(
    KonanAPI.updateProgram,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("programs")
        await queryClient.invalidateQueries("program")
      },
      onError: (data) => {
        // TODO:: check error types with backend
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          NotificationUtils.toast(data.response?.data.detail[0] ?? "An error occurred. please try again later.", {
            snackBarVariant: "negative",
          })
        } catch (e) {
          NotificationUtils.toast("An error occurred. please try again later.", {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  // ruleset deletion Mutation
  const deleteProgramMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    { project_uuid: string; resource_uuid: string }
  >((props) => KonanAPI.deleteWorkflowResource({ ...props, resource_type: "programs" }), {
    onSuccess: async () => {
      NotificationUtils.toast("Program deleted successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries("programs")
      await queryClient.invalidateQueries("program")
    },
    onError: ({ response }) => {
      NotificationUtils.toast(response?.data?.details ?? "Program deletion failed", { snackBarVariant: "negative" })
    },
  })

  const changeActiveProgramVersion = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("programs")
        await queryClient.invalidateQueries("program")
      },
    },
  )

  const formik = useFormik({
    validationSchema: validationSchemaScorecard,
    initialValues: {
      name: program?.name ?? "",
      items: program?.items ?? [],
    },
    onSubmit: async (values) => {
      let TotalWeightSum = 0
      const trimmedProgramName = values.name.trim()

      for (const item of values.items) TotalWeightSum += parseFloat(item.weight)

      if (TotalWeightSum < 1) {
        NotificationUtils.toast("Sum of all weights mustn't be less than 1!", {
          snackBarVariant: "negative",
        })
        return
      }

      if (isNewProgram) {
        await createProgram.mutateAsync({
          name: trimmedProgramName,
          items: values.items.map((item) => {
            return {
              ...item,
              ...(item.item_type === "project"
                ? { project: item.project?.uuid }
                : { scorecard_set: item.scorecard_set?.uuid }),
            }
          }) as ProgramItemRequest[],
          projectUUID: projectId as string,
        })
      } else {
        await updateProgram.mutateAsync({
          name: trimmedProgramName !== program?.name ? trimmedProgramName : undefined,
          items: values.items.map((item) => {
            return {
              ...item,
              ...(item.item_type === "project"
                ? { project: item.project?.uuid }
                : { scorecard_set: item.scorecard_set?.uuid }),
            }
          }) as ProgramItemRequest[],
          projectUUID: projectId as string,
          programUUID: program?.uuid as string,
        })
      }

      setIsEditMode(false)
    },
  })

  const scorecardsets = useMemo(() => {
    const formikScoreSets = formik.values.items.map((item) => item.scorecard_set?.uuid)

    return scorecardSetData?.results?.map((scoreCardSet) => {
      return { uuid: scoreCardSet.uuid, name: scoreCardSet.name, hidden: formikScoreSets.includes(scoreCardSet.uuid) }
    })
  }, [scorecardSetData, formik.values.items])

  const projects = useMemo(() => {
    const formikProjects = formik.values.items.map((item) => item.project?.uuid)

    return projectsData
      ?.filter((project) => project.category === "model")
      .map((project) => {
        return { uuid: project.uuid, name: project.name, hidden: formikProjects.includes(project.uuid) }
      })
  }, [projectsData, formik.values.items])

  // Memoize active version to render it in the versioning card
  const activeVersion = useMemo(() => {
    if (versionedProgram) {
      for (const version of versionedProgram.data.versions) {
        if (version.is_active_version) {
          return version
        }
      }
    }
  }, [versionedProgram])

  const changeProgramVersion = async (version: string): Promise<void> => {
    try {
      await changeActiveProgramVersion.mutateAsync({
        projectUUID: projectId as string,
        resourceUUID: program?.uuid as string,
        version,
        resource: "programs",
      })

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  // resetting values when cancelling editing
  const handleCancelEdit = (): void => {
    formik.resetForm()
    formik.setFieldValue("name", program?.name)
    formik.setFieldValue("items", program?.items)

    setIsEditMode(false)
  }

  // helper function for the versioning dialog
  // returns data of the version selected
  const GetProgramContainer = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

    const Version = useMemo((): Program | undefined => {
      if (versionedProgram && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(versionedProgram.data.versions as Program[], selectedVersion)
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of versionedProgram.data.versions) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, versionedProgram])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeVersion?.version

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {program?.name}
            </Typography>

            <VersionTag
              version={Version?.version ?? selectedVersion}
              variant={Version ? (activeVersion?.version === selectedVersion ? "positive" : "default") : "positive"}
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => changeProgramVersion(selectedVersion as string)}
              disabled={changeActiveProgramVersion.isLoading}
            >
              {changeActiveProgramVersion.isLoading ? <CircularProgress size={12} /> : "Restore"}
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0}>
          {isProgramLoading || Version === undefined ? (
            <Grid container mt={2}>
              <Grid item xs={12} textAlign={"center"}>
                <CircularProgress size={24} style={{ color: theme.palette.blue.text[2] }} />
              </Grid>
              <Grid item xs={12} style={{ textAlignLast: "center" }}>
                <Typography variant="h3-regular">Fetching Programs...</Typography>
              </Grid>
            </Grid>
          ) : Version?.items?.length > 0 ? (
            Version?.items?.map((item, index) => (
              <Grid container item key={index} justifyContent="flex-start" alignItems="center" spacing={2} pt={1.5}>
                <Grid item xs={12}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={10}>
                      {item.item_type === "project" ? (
                        <LiveModelCard isVersioningDialog project={item.project} projects={projects} isEdit={false} />
                      ) : (
                        <ScoreCardSetBlock
                          scorecardset={item.scorecard_set}
                          isEdit={false}
                          ScorecardSets={scorecardsets}
                        />
                      )}
                    </Grid>
                    <Grid item xs={2} alignSelf={"center"}>
                      {isEditMode ? (
                        <InputText
                          id={`items[${index}].weight`}
                          placeholder="Weight"
                          value={`${item.weight}`}
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          hideDescription
                          disabled={formik.isSubmitting}
                          fullWidth
                          key={key}
                          type="number"
                        />
                      ) : (
                        <DataBlock value={`${parseFloat(item.weight)}%`} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid
              container
              height={"100%"}
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              pt={1.5}
            >
              <KonanEmptyState title="No programs in this version" subTitle="Checkout another version" />
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      {isVersioningDialogOpen && (
        <VersioningDialog
          isOpen={isVersioningDialogOpen}
          onClose={() => setIsVersioningDialogOpen(false)}
          versions={versionedProgram?.data.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          height={fullScreen ? "100%" : "400px"}
          activeVersion={activeVersion as Program}
          maxWidth="md"
        >
          <GetProgramContainer />
        </VersioningDialog>
      )}

      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          open={isDeletionDialogOpen}
          name={program?.name ?? ""}
          onClose={() => setIsDeletionDialogOpen(false)}
          onAccept={() => {
            deleteProgramMutation.mutateAsync({
              project_uuid: projectId as string,
              resource_uuid: program?.uuid as string,
            })
          }}
          mode={"program-deletion"}
          isLoading={deleteProgramMutation.isLoading}
        />
      )}

      {isLoading ? (
        <Grid item xs={12}>
          <Card className="card-box-shadow">
            <CardHeader className={styles.scorecardSetHeader} title={<Skeleton width={200} />} />
            <CardContent className={styles.cardContent}>
              <Skeleton height={30} />
              <Skeleton height={30} />
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <Card className="card-box-shadow">
          <CardHeader
            className={styles.scorecardSetHeader}
            title={
              <Grid container direction="column" item xs={12} spacing={1}>
                {/* Program Name */}
                <Grid item container xs={12} justifyContent="space-between">
                  <Grid item xs={6} md={3}>
                    {!isEditMode ? (
                      <Typography noWrap variant="h3-bold" style={{ textTransform: "inherit", width: "100%" }}>
                        {program?.name}
                      </Typography>
                    ) : (
                      <InputText
                        id="name"
                        hideDescription
                        placeholder="Name"
                        value={formik.values.name}
                        handleChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
                        handleBlur={formik.handleBlur}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    )}
                  </Grid>

                  {!isEditMode && (
                    <Grid container item xs justifyContent={"flex-end"}>
                      <IconButton
                        aria-label="settings"
                        size="small"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor2(event.currentTarget)}
                      >
                        <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
                      </IconButton>

                      <Menu
                        key="basic-menu"
                        anchorEl={anchor2}
                        open={isMenuOpen}
                        onClose={() => setAnchor2(null)}
                        menuMaxContent
                      >
                        <MenuItem
                          onClick={() => {
                            setIsDeletionDialogOpen(true)
                            setAnchor2(null)
                          }}
                        >
                          <Typography variant="a" color="negative" variantColor={2}>
                            Remove Program
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  )}
                </Grid>

                {/* Avatar section */}
                <Grid item container xs={12} justifyContent="space-between">
                  <Grid
                    sx={{ flexDirection: { xs: "column", md: "row" } }}
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                  >
                    <Grid container item spacing={1} md={4} alignItems="flex-start" flexWrap={"nowrap"}>
                      <Grid item sx={{ marginTop: "2px" }}>
                        {/* SvgIcon is used to fix square avatars on safari */}
                        <SvgIcon className={styles.modelAvatar}>
                          <KonanAvatar size={24} name={program?.created_by ?? (userEmail as string)} />
                        </SvgIcon>
                      </Grid>
                      <Grid container item xs={10}>
                        <Grid item mt={program?.created_at ? 0 : 0.5}>
                          <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                            {program?.created_by ?? userEmail}
                          </Typography>

                          {program?.created_at && (
                            <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                              <Tooltip title={format(new Date(program?.created_at), "dd/MM/yyyy, p")} placement="right">
                                <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                                  {moment(new Date(program?.created_at)).fromNow()}
                                </Typography>
                              </Tooltip>
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {isEditMode ? (
                      <Grid item style={{ justifyContent: smallActionButtons ? "flex-end" : "flex-start" }}>
                        <Box display={"flex"}>
                          <Box mr={1} alignSelf="flex-start">
                            <Button
                              size="small"
                              variant={"secondary"}
                              onClick={() => (isNewProgram ? handleCancel?.() : handleCancelEdit())}
                            >
                              Cancel
                            </Button>
                          </Box>

                          <Box alignSelf="flex-end">
                            <Button
                              variant="primary"
                              disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                formik.values.items.some((item) => item.weight === undefined) ||
                                formik.values.items.length === 0
                              }
                              size="small"
                              onClick={formik.submitForm}
                            >
                              {formik.isSubmitting ? <CircularProgress color="inherit" size={16} /> : "Save"}
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item style={{ justifyContent: smallActionButtons ? "flex-end" : "flex-start" }}>
                        <Box display={"flex"}>
                          <Box mr={1} alignSelf="flex-start">
                            <Button
                              size="small"
                              variant="secondary"
                              onClick={() => {
                                duplicateProgram?.(program as Program)
                              }}
                              disabled={disableDuplicateButton}
                            >
                              Duplicate
                            </Button>
                          </Box>

                          <Box mr={1} alignSelf="flex-start">
                            <Button
                              size="small"
                              variant="secondary"
                              onClick={() => {
                                setSelectedVersion(program?.version)
                                setIsVersioningDialogOpen(true)
                              }}
                            >
                              History
                            </Button>
                          </Box>

                          <Box alignSelf="flex-start">
                            <Button size="small" variant="secondary" onClick={() => setIsEditMode(!isEditMode)}>
                              Edit
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item container width="100%">
                  <Typography variant="p" display="block" style={{ textTransform: "inherit" }}>
                    Combine multiple scorecards and AI models with weights. To be effective, you integrate it into your
                    workflow
                  </Typography>
                </Grid>
              </Grid>
            }
          />

          <CardContent className={styles.cardContent}>
            <Grid container spacing={1}>
              <FormikProvider value={formik}>
                <FieldArray
                  name={"items"}
                  render={(arrayHelpers) =>
                    arrayHelpers.form.values.items.map((item: ItemType, index: number) => {
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={2}
                          justifyContent={"space-between"}
                          key={(item.item_type === "project" ? item.project?.uuid : item.scorecard_set?.uuid) ?? index}
                        >
                          <Grid item xs={8} md={9.5}>
                            {item.item_type === "project" ? (
                              <LiveModelCard
                                project={item.project}
                                isProjectsLoading={isProjectsLoading}
                                projects={projects}
                                isEdit={isEditMode}
                                handleRemove={() => {
                                  arrayHelpers.remove(index)
                                }}
                                onValueChange={(item: { name: string; uuid: string }) =>
                                  formik.setFieldValue(`items[${index}].project`, item)
                                }
                              />
                            ) : (
                              <ScoreCardSetBlock
                                scorecardset={item.scorecard_set}
                                isEdit={isEditMode}
                                handleRemove={() => {
                                  arrayHelpers.remove(index)
                                }}
                                onValueChange={(item: { name: string; uuid: string }) =>
                                  formik.setFieldValue(`items[${index}].scorecard_set`, item)
                                }
                                isScorecardSetsLoading={isScorecardSetsLoading}
                                ScorecardSets={scorecardsets}
                              />
                            )}
                          </Grid>
                          <Grid item xs={4} md={2.3} alignSelf={"center"}>
                            {isEditMode ? (
                              <InputText
                                key={formik.values.items[index].scorecard_set?.uuid}
                                id={`items[${index}].weight`}
                                placeholder="Weight"
                                value={item.weight ? `${parseFloat(item.weight)}` : undefined}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                hideDescription
                                disabled={formik.isSubmitting}
                                type="number"
                                fullWidth
                              />
                            ) : (
                              <DataBlock value={`${parseFloat(item.weight)}%`} />
                            )}
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                />
              </FormikProvider>

              {/* Add new block section */}
              {isEditMode && (
                <Grid item mt={1}>
                  <Button size="regular" onClick={(event) => setAnchor(event?.currentTarget)}>
                    + Add Block
                  </Button>
                  <Menu
                    key="basic-menu"
                    anchorEl={anchor}
                    open={Boolean(anchor)}
                    onClose={() => setAnchor(null)}
                    menuMaxContent
                  >
                    <Tooltip
                      title={
                        !isProjectsLoading &&
                        0 === projectsData?.length &&
                        "You need Models before you can add them to a program"
                      }
                      placement="top"
                    >
                      <MenuItem
                        onClick={() => {
                          formik.setFieldValue("items", [
                            ...formik.values.items,
                            {
                              item_type: "project",
                              project: { uuid: `default-${uuidv4()}`, name: "" },
                              weight: undefined,
                            },
                          ])
                          setAnchor(null)
                        }}
                        disabled={!isProjectsLoading && projectsData?.length === 0}
                      >
                        <LiveModelCard menuView project={{ uuid: "default", name: "" }} />
                      </MenuItem>
                    </Tooltip>
                    <Tooltip
                      title={
                        !isScorecardSetsLoading &&
                        0 === scorecardSetData?.count &&
                        "You need Scorecardsets before you can add them to a program"
                      }
                      placement="top"
                    >
                      <MenuItem
                        onClick={() => {
                          formik.setFieldValue("items", [
                            ...formik.values.items,
                            {
                              item_type: "scorecardset",
                              scorecard_set: { uuid: `default-${uuidv4()}`, name: "" },
                              weight: undefined,
                            },
                          ])
                          setAnchor(null)
                        }}
                        disabled={!isScorecardSetsLoading && scorecardSetData?.count === 0}
                      >
                        <ScoreCardSetBlock menuView scorecardset={{ uuid: "default", name: "" }} />
                      </MenuItem>
                    </Tooltip>
                  </Menu>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Fragment>
  )
}
