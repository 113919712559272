import React, { useEffect } from "react"

import { Background, ConnectionLineType, Controls, Edge, Node, OnEdgesChange, ReactFlow, useReactFlow } from "reactflow"

import { edgeTypes, nodeTypes } from "../workflow-fixtures"

type Props = {
  isWorkflowLoading: boolean
  edges: Edge[]
  nodes: Node[]
  isCreateMode?: boolean
  isEditMode?: boolean
  isFetching?: boolean
  onEdgesChange: OnEdgesChange
}

export function WorkflowCanvas(props: Readonly<Props>): React.ReactElement {
  const { edges, isFetching, isWorkflowLoading, nodes, isCreateMode, isEditMode, onEdgesChange } = props

  const { fitView, zoomIn, zoomOut } = useReactFlow()

  // this effect responsible for adjusting canvas position(zoom, pan) when a workflow
  // canvas mounted/re-loaded
  useEffect(() => {
    // check if it's readMode then adjusting the position
    if (!isCreateMode && !isEditMode) {
      // this is to make sure fitView will be called after nodes are loaded
      setTimeout(() => {
        fitView({ duration: 500 })
      }, 100)
    }
  }, [nodes.length, isWorkflowLoading, nodes, isFetching, isCreateMode, isEditMode, fitView])

  /* Reactflow canvas */
  return (
    <ReactFlow
      edgeTypes={edgeTypes}
      snapToGrid={true}
      nodes={isWorkflowLoading ? [] : nodes}
      nodeTypes={nodeTypes}
      fitView={isCreateMode}
      nodesDraggable={true}
      onEdgesChange={onEdgesChange}
      className="transition"
      edges={edges}
      connectionLineType={ConnectionLineType.Bezier}
      deleteKeyCode={[]}
    >
      <Background />
      <Controls
        onZoomIn={() => zoomIn({ duration: 400 })}
        onZoomOut={() => zoomOut({ duration: 400 })}
        onFitView={() => fitView({ duration: 500 })}
        position="top-right"
        showFitView={true}
        showInteractive={false}
      />
    </ReactFlow>
  )
}
