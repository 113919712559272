import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import Grid from "@mui/material/Grid"
import { DateRangePicker } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { EvaluationMetricsCard } from "../../components/EvaluationMetricsCard"
import { KonanPageHeader } from "../../components/KonanPageHeader"
import { SelectWithSearch } from "../../components/UI/SelectWithSearch"
import { RequestsLineGraph } from "../../components/graphs/RequestsLineGraph"
import { ModelComparisonTable } from "../../components/tables/ModelComparisonTable"
import { RequestLogTable } from "../../components/tables/RequestLogTable"
import { useDateQuery } from "../../hooks/useDateQuery"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { ModelTimeRange, baseErrorType } from "../../types/custom/projects"
import { EvaluateResult } from "../../types/generated/api/EvaluateResult"
import { Model } from "../../types/generated/api/Model"
import { PaginatedListModelStateSwitchList } from "../../types/generated/api/PaginatedListModelStateSwitchList"
import { RequestSummaryDailyOutput } from "../../types/generated/api/RequestSummaryDailyOutput"
import {
  convertActivityDataToMarkers,
  convertDatatoTotalRequestsLineGraph,
  fillMissingRequestSummaryDates,
} from "../../utils/deploymentDetailsHelpers"
import { getModelByUUID } from "../../utils/modelDetailsHelpers"

type ParamsType = {
  id: string
}

/**
 * Component that monitors requests/predictions on any model as well as evaluation metrics
 * contains requests log table with the (date, request body, response) to each request
 * and compare with option to compare between 2 models
 * @returns {React.ReactElement}
 */
export function ModelApiMonitor(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  const [compareModel, setCompareModel] = useState("")

  const { currentModel } = useContext(CurrentProjectAndModelContext)

  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError>(["models", projectId], () =>
    KonanAPI.fetchModels(projectId as string),
  )

  const {
    mutate,
    data: evaluationData,
    isLoading,
    isError,
    error,
  } = useMutation<EvaluateResult, AxiosError<baseErrorType>, ModelTimeRange>(KonanAPI.evaluateModel)

  const { isLoading: isLoadingDailyRequestSummary, data: dailyRequestSummaryResponse } = useQuery<
    RequestSummaryDailyOutput,
    AxiosError<baseErrorType>
  >(
    ["model-daily-request-summary", currentModel, startDate, endDate],
    () =>
      KonanAPI.fetchDailyModelRequestSummary({
        start_date: startDate?.toISOString() as string,
        end_date: endDate?.toISOString() as string,
        model_uuid: currentModel,
      }),
    { enabled: startDate != null && endDate != null },
  )

  const { isLoading: isActivityLoading, data: activityResponse } = useQuery<
    PaginatedListModelStateSwitchList,
    AxiosError<baseErrorType>
  >(["modelHistory", projectId], () => KonanAPI.getProjectHistory(projectId as string), {
    enabled: projectId !== "",
  })

  const handleChange = (item: string): void => {
    if (item === "none") {
      setCompareModel("")
    } else {
      setCompareModel(item)
    }
  }

  const memoizedGetModelByUUID = useCallback(
    (currentModel: string) => getModelByUUID(currentModel, models?.data),
    [models],
  )

  //add model name to activity response
  const activityData = useMemo(() => {
    if (activityResponse?.results && activityResponse?.results.length > 0) {
      return convertActivityDataToMarkers(memoizedGetModelByUUID, activityResponse.results)
    }
    return []
  }, [activityResponse?.results, memoizedGetModelByUUID])

  const adjustedData = useMemo(() => {
    if (startDate && endDate) {
      // If there's new data, fill in the missing dates and convert it to Serie[] type
      return convertDatatoTotalRequestsLineGraph(
        fillMissingRequestSummaryDates(startDate, endDate, dailyRequestSummaryResponse?.data ?? [], activityData),
      )
    }
    return []
  }, [startDate, endDate, dailyRequestSummaryResponse, activityData])

  const isCurrentModelDisabled = useMemo(() => {
    return memoizedGetModelByUUID(currentModel)?.state === "disabled"
  }, [currentModel, memoizedGetModelByUUID])

  // Use evaluation endpoint whenever datetime range changes
  useEffect(() => {
    if (startDate && endDate) {
      mutate({
        start_date: startDate?.toISOString(),
        end_date: endDate?.toISOString(),
        model_uuid: currentModel,
      })
    }
  }, [startDate, endDate, currentModel, mutate])

  return (
    <Grid container spacing={2}>
      <KonanPageHeader
        title="API Requests"
        actions={[
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            disableFuture
          />,
        ]}
      />

      {/* Requests Line Graph */}
      <Grid item xs={12} md={!isCurrentModelDisabled ? 8 : 12} lg={!isCurrentModelDisabled ? 9 : 12}>
        <RequestsLineGraph
          title="Number of Requests per Day"
          data={adjustedData}
          graphHeight={400}
          isLoading={isLoadingDailyRequestSummary || isActivityLoading}
        />
      </Grid>

      {/* Evaluation Metrics card */}
      {!isCurrentModelDisabled && (
        <Grid item xs={12} md={4} lg={3}>
          <EvaluationMetricsCard
            metrics={evaluationData?.metrics}
            cardHeight={400}
            isLoading={isLoading}
            isError={isError || !!evaluationData?.error}
            error={
              error?.response?.data.detail
                ? error?.response?.data.detail
                : error?.response?.data.error === "Not Found"
                  ? "Evaluation endpoint not found. Please make sure you implemented the evaluation endpoint in your model."
                  : evaluationData?.error || "An unknown error occurred"
            }
          />
        </Grid>
      )}

      {/* hide menu if there is only one model */}
      {models?.data && models?.data.length > 1 && (
        <Grid container item xs={12} md={4} lg={3}>
          <SelectWithSearch
            options={[
              { label: "Select none", value: "none" },
              ...(models?.data
                ?.filter((model) => model.uuid !== currentModel)
                ?.map((model) => {
                  return { label: model.name, value: model.uuid }
                }) ?? []),
            ]}
            initialValue="Select Model"
            placeHolder="Select Model"
            label="Compare With"
            searchInputPlaceHolder="Search models"
            fullWidth
            hideDescription
            onSelectMenuItem={(item: { label: string; value: string }) => handleChange(item?.value)}
          />
        </Grid>
      )}

      {/* Requests log table */}
      {compareModel === "" ? (
        <Grid item xs={12}>
          <RequestLogTable
            startDate={startDate?.toISOString() as string}
            endDate={endDate?.toISOString() as string}
            projectUUID={projectId as string}
            modelUUID={currentModel}
          />
        </Grid>
      ) : (
        /* Model comparison log table */
        <Grid item xs={12}>
          <ModelComparisonTable
            startDate={startDate?.toISOString() as string}
            endDate={endDate?.toISOString() as string}
            projectUUID={projectId as string}
            modelAUUID={currentModel}
            modelBUUID={compareModel}
            models={models?.data}
          />
        </Grid>
      )}
    </Grid>
  )
}
