/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RetrainingJob = {
  /**
   * Should be in ISO format (YYYY-MM-DDTHH:mm:ss)
   */
  predictions_start_time?: string
  /**
   * Should be in ISO format (YYYY-MM-DDTHH:mm:ss)
   */
  predictions_end_time?: string
  readonly created_by: string
  readonly model: string
  readonly training_data: string | null
  readonly metrics: Record<string, any> | null
  readonly test_percentage: number | null
  readonly train_percentage: number | null
  readonly eval_percentage: number | null
  readonly resulting_model: string | null
  /**
   * Carries the model state desired (at RetrainingJob creation) for the resulting model.
   *
   * * `live` - live
   * * `challenger` - challenger
   * * `disabled` - disabled
   * * `pending-action` - pending-action
   */
  readonly resulting_model_state: RetrainingJob.resulting_model_state
  resulting_model_name?: string
  readonly uuid: string
  readonly created_at: string
  /**
   * Represents the time the retraining script was started
   */
  readonly started_at: string | null
  /**
   * Represents the time the retraining job finished
   */
  readonly ended_at: string | null
  /**
   * Represents the time the retraining job was cancelled
   */
  readonly cancelled_at: string | null
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   * * `TIMEOUT` - Timeout
   */
  readonly status: RetrainingJob.status
  /**
   * Serving predictions used for retraining
   */
  readonly predictions: string | null
  readonly duration: string | null
  /**
   * * `custom` - Custom
   * * `prebuilt` - Prebuilt
   * * `automl` - AutoML
   */
  resulting_model_type?: RetrainingJob.resulting_model_type
}
export namespace RetrainingJob {
  /**
   * Carries the model state desired (at RetrainingJob creation) for the resulting model.
   *
   * * `live` - live
   * * `challenger` - challenger
   * * `disabled` - disabled
   * * `pending-action` - pending-action
   */
  export enum resulting_model_state {
    LIVE = "live",
    CHALLENGER = "challenger",
    DISABLED = "disabled",
    PENDING_ACTION = "pending-action",
  }
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   * * `TIMEOUT` - Timeout
   */
  export enum status {
    PENDING = "PENDING",
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED",
    TIMEOUT = "TIMEOUT",
  }
  /**
   * * `custom` - Custom
   * * `prebuilt` - Prebuilt
   * * `automl` - AutoML
   */
  export enum resulting_model_type {
    CUSTOM = "custom",
    PREBUILT = "prebuilt",
    AUTOML = "automl",
  }
}
