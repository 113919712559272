import React, { Fragment, useMemo, useState } from "react"

import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import { Grid, Skeleton } from "@mui/material"
import { RadioButton, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState } from "../../../components/KonanEmptyState"
import { KonanPagination } from "../../../components/tables/KonanPagination"
import { getTheme } from "../../../hooks/UseTheme"
import { KonanAPI } from "../../../services/KonanAPI"
import { DataFile } from "../../../types/generated/api/DataFile"
import { ExistingDatasetViewProps } from "../Interfaces"

import styles from "../Simulations.module.scss"

/**
 * Simulation existing dataset selection screen
 * @param {WorkflowVersionsRetrieve} workflow
 * @param {(uuid: string, columns: string[]) => void} setDataFile
 * @param {string} dataFile (optional)
 * @return {React.ReactElement}
 */
export function ExistingDatasetView(props: Readonly<ExistingDatasetViewProps>): React.ReactElement {
  const { workflow, dataFile, setDataFile, formik } = props

  const { id: projectID } = useParams<{ id: string }>()

  const theme = getTheme()

  const pageSize = 6
  const [page, setPage] = useState<number>(0)

  // Load data files
  const { isLoading: isDataFilesLoading, data: allDataFiles } = useQuery<AxiosResponse<Array<DataFile>>, AxiosError>(
    ["predictions-data-files", projectID],
    () => KonanAPI.fetchDataFiles(projectID as string, "PREDICTIONS"),
  )

  // check dataset validity (has columns to match the required features of the workflow)
  // and then sorts them so that valid dataSets goes first in the list
  const sortedDataSets = useMemo(() => {
    return allDataFiles?.data
      .map((item) => {
        return {
          name: item.name,
          ID: item.uuid,
          valid: workflow?.schema?.features
            ?.filter((feat) => feat?.is_required)
            ?.every((el) => {
              return item.columns?.includes(el?.name)
            }),
        }
      })
      .sort((a, b) => Number(b.valid) - Number(a.valid))
  }, [allDataFiles?.data, workflow?.schema?.features])

  return (
    <Grid container>
      {!isDataFilesLoading && sortedDataSets && sortedDataSets?.length < 1 ? (
        // TODO:: refactor margin to be calculated dynamically
        <Grid item xs={12} mt={15}>
          <KonanEmptyState title="No existing datasets" />
        </Grid>
      ) : (
        <Fragment>
          {/** dataset table */}
          <Grid item xs={12} mb={1}>
            <Typography variant="label" variantColor={2}>
              Existing Dataset
            </Typography>
          </Grid>

          <Grid container item xs={12} className={styles.existingDataSetTable} padding={0}>
            {isDataFilesLoading
              ? [1, 2, 3, 4].map((_item, index) => (
                  <Grid container item xs={12} className={styles.existingDataSetRow} key={index}>
                    <Grid item xs={6}>
                      <Skeleton animation="wave" width="75%" height="20%" />
                    </Grid>

                    <Grid item xs={6}>
                      <Skeleton animation="wave" width="75%" height="20%" />
                    </Grid>
                  </Grid>
                ))
              : sortedDataSets &&
                sortedDataSets?.length >= 1 && (
                  <Fragment>
                    {sortedDataSets.slice(page * pageSize, (page + 1) * pageSize).map((item) => (
                      <Grid container item xs={12} className={styles.existingDataSetRow} key={item.ID}>
                        <Grid item xs={6}>
                          <RadioButton
                            value={item.ID}
                            checked={item.ID === dataFile}
                            onChange={(e) => {
                              setDataFile(
                                e.target.value,
                                allDataFiles?.data.find((dataSet) => dataSet.uuid === e.target.value)?.columns ?? [""],
                              )

                              formik.setFieldValue("shouldProceed", item.valid)
                            }}
                            label={item.name}
                            id={item.ID}
                          />
                        </Grid>

                        <Grid container item xs={6}>
                          <Grid item mr={1} pt={0.5}>
                            {item.valid ? (
                              <CheckIcon fontSize="small" style={{ color: theme.palette.green.text[2] }} />
                            ) : (
                              <ClearIcon fontSize="small" style={{ color: theme.palette.red.text[2] }} />
                            )}
                          </Grid>
                          <Grid item alignSelf={"center"}>
                            <Typography variant="p" variantColor={2}>
                              {item.valid ? "Valid features" : "Invalid features"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}

                    <KonanPagination
                      count={sortedDataSets.length}
                      page={page}
                      onPageChange={setPage}
                      rowsPerPage={pageSize}
                      hideRowCount={true}
                    />
                  </Fragment>
                )}
          </Grid>
        </Fragment>
      )}
    </Grid>
  )
}
