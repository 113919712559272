// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Auth } from "./auth"

/**
 * Function that un-nests a nested json and return a single level json
 */
export function PermissionsFlattener(obj: object): string[] {
  const result: object = {}

  function helper(obj: object, parents?: string[]): void {
    Object.entries(obj).forEach(([key, value]) => {
      // if not children take the key and label and add them to the results array
      result[value.label] = { key: key, children: [], parents: [...(parents ?? [])], default: value.default ?? false }

      for (const parent of parents ?? []) {
        result[parent].children.push(value.label)
      }

      if (value?.children) {
        helper(value.children, [...(parents ?? []), value.label])
      }
    })
  }

  helper(obj)

  return result
}

// permission to check on + user permissions
export const isPermitted = (permission: string, userPermissions: number[], flattenedPermissions: object): boolean => {
  const role = Auth.getUserRole()
  const isOwner = Auth.getIsOwnerRole()

  // reassigning flattenedPermissions to be able to override it with default permissions when needed
  let permissions = { ...flattenedPermissions }

  // OWNER role has all permissions
  if (isOwner) return true

  // if no permission passed or if the permissions object is empty for any reason make sure the user isnt permitted
  if (permission === undefined || Object.keys(permissions).length === 0) return false

  // default roles (USER) role, should be validated against default permissions
  // since the jwt doesn't return a permissions array for them
  if (role === "USER") {
    permissions = Object.entries(flattenedPermissions)
      // filtering by default permissions
      .filter(([_, value]) => value.default)
      // re-zipping the array into an object to be able to perform the permission check on it
      // eslint-disable-next-line no-sequences
      .reduce((obj, prop) => ((obj[prop[0]] = prop[1]), obj), {})
  }

  // return a boolean if the user is permitted or not
  return (
    userPermissions?.includes(parseInt(permissions[permission]?.key)) ||
    permissions[permission]?.children.some((child) => userPermissions?.includes(parseInt(permissions[child]?.key)))
  )
}
