import React, { useMemo } from "react"

import { useQuery } from "react-query"

import { Box, Grid } from "@mui/material"
import { InputText, Snackbar, Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { FormikProps } from "formik"

import { KonanAPI } from "../../../services/KonanAPI"
import { KonanRegistryImagesSeriliazer } from "../../../types/generated/api/KonanRegistryImagesSeriliazer"
import { Auth } from "../../../utils/auth"

import Styles from "../Projects.module.scss"

type Props = {
  formik: FormikProps<{
    name: string
    image_url: string
    registry: string
    exposed_port: number
    docker_username: string
    docker_password: string
  }>
  errorMessage?: string
}

/**
 * Model creation form
 * @param  {FormikProps} formik form control object
 * @param  {string} errorMessage creation error messages
 * @return  {React.ReactElement}
 */
export function ModelCreationForm(props: Props): React.ReactElement {
  const { formik, errorMessage } = props

  // Fetching container images
  const orgId = Auth.getOrganizationUID() as string

  const fetchRegistryImages = useQuery<Array<KonanRegistryImagesSeriliazer>, AxiosError>(
    ["registry-images", orgId],
    () => KonanAPI.fetchRegistryImages(),
  )

  // Memoizing the image list to prevent unnecessary re-renders
  const imagesList = useMemo(() => {
    const images: string[] = []

    fetchRegistryImages?.data?.forEach((item) => images.push(item.image))

    return images ?? undefined
  }, [fetchRegistryImages?.data])

  return (
    <Grid container item direction="column" className={Styles.imageContainer}>
      {errorMessage && (
        <Grid item paddingBottom={2}>
          <Snackbar variant="negative" fullWidth description={errorMessage} />
        </Grid>
      )}

      <Typography variant="h3-bold" gutterBottom>
        Model
      </Typography>
      <InputText
        label="Name"
        id="name"
        placeholder="Initial Model"
        value={formik.values.name}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
        fullWidth
        required
        hideDescription
      />

      <Grid item paddingTop={2.5}>
        <Typography variant="h3-bold" gutterBottom>
          Container Image
        </Typography>

        <Typography variant="label">Choose method</Typography>

        <Tabs
          value={formik.values.registry}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any) => {
            formik.setFieldValue("registry", e.target.value)
          }}
        >
          <Tab
            label="KCR"
            value="KCR"
            selected={"KCR" === formik.values.registry ? true : false}
            // To reset container image shifting from one mode to another
            onClick={() => formik.setFieldValue("image_url", "")}
          />
          <Tab
            label="Custom"
            value="Custom"
            selected={"Custom" === formik.values.registry ? true : false}
            // To reset container image shifting from one mode to another
            onClick={() => formik.setFieldValue("image_url", "")}
          />
        </Tabs>
      </Grid>

      <Grid item className={Styles.modelCreationContainer}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            {formik.values.registry === "KCR" ? (
              <InputText
                id="image_url"
                label="Container Image URL"
                placeholder="Select a container image from your organization's KCR"
                value={formik.values.image_url}
                // add key prop when using Autocomplete to identify which items in the list are changed, updated, or deleted
                key={imagesList ? imagesList[0] : ""}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                error={formik.touched.image_url && Boolean(formik.errors.image_url) && formik.errors.image_url}
                disabled={formik.isSubmitting}
                // TODO:: fix this
                // description={
                //   <React.Fragment>
                //     Instructions can be found{" "}
                //     <LinkRouter color="textPrimary" to="/kcr">
                //       here
                //     </LinkRouter>{" "}
                //     on how to push your container images to KCR
                //   </React.Fragment>
                // }
                fullWidth
                required
                options={imagesList}
                hideDescription
              />
            ) : (
              <InputText
                id="image_url"
                label="Container Image URL"
                placeholder="Enter Container Image URL"
                value={formik.values.image_url}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                error={formik.touched.image_url && Boolean(formik.errors.image_url) && formik.errors.image_url}
                description={
                  "URL where your container image is located. (e.g. registry.gitlab.com/<my-group>/<my-image>)"
                }
                fullWidth
                required
              />
            )}
            <Box mb={2} />

            <InputText
              id="exposed_port"
              label="Port"
              value={formik.values.exposed_port.toString()}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.touched.exposed_port && Boolean(formik.errors.exposed_port) && formik.errors.exposed_port}
              fullWidth
              required
              hideDescription
            />
            <Box mb={2} />

            {formik.values.registry !== "KCR" && (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <InputText
                    id="docker_username"
                    placeholder="Enter Registry Username"
                    label="Registry Username (optional)"
                    value={formik.values.docker_username}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={
                      formik.touched.docker_username &&
                      Boolean(formik.errors.docker_username) &&
                      formik.errors.docker_username
                    }
                    description={"Required if container registry needs authentication"}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputText
                    id="docker_password"
                    label="Registry Password (optional)"
                    placeholder="Enter Registry Password"
                    type="password"
                    value={formik.values.docker_password}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={
                      formik.touched.docker_password &&
                      Boolean(formik.errors.docker_password) &&
                      formik.errors.docker_password
                    }
                    description={"Required if container registry needs authentication"}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
