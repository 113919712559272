import React, { useContext, useState } from "react"

import { useQuery } from "react-query"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Grid, Pagination } from "@mui/material"
import { Button } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState } from "../../components/KonanEmptyState"
import { KonanPageHeader } from "../../components/KonanPageHeader"
import { DecisionLoadingCard } from "../../components/cards/DecisionLoadingCard"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { PaginatedRuleSetListList } from "../../types/generated/api/PaginatedRuleSetListList"
import { Auth } from "../../utils/auth"
import { RulesetCard } from "./components/RulesetCard"

/**
 * Ruleset module component
 * home to ruleset page
 * @return {React.ReactElement}
 */
export function Ruleset(): React.ReactElement {
  // Pagination
  const pageSize = 3
  const [page, setPage] = useState<number>(0)

  const [duplicatedId, setDuplicatedId] = useState<string | null>(null)

  const [showRulesetCard, setShowRulesetCard] = useState<boolean>(false)

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const currentUserEmail = Auth.getEmail()

  const duplicateRuleset = (uuid: string): void => {
    setDuplicatedId(uuid)
    // scroll to top
    window.scrollTo(0, 0)
  }

  // fetch rulesets
  const { isLoading: isRulesetsLoading, data: rulesets } = useQuery<
    AxiosResponse<PaginatedRuleSetListList>,
    AxiosError
  >(
    ["rulesets", currentProject, page, pageSize],
    () => KonanAPI.fetchRulesets(currentProject.uuid as string, page + 1, pageSize),
    {
      enabled: !!currentProject,
    },
  )

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} textAlign={"right"}>
        <KonanPageHeader
          title="Rulesets"
          actions={[
            <Button
              variant="primary"
              startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
              onClick={() => setShowRulesetCard(true)}
              disabled={showRulesetCard || isRulesetsLoading || !!duplicatedId}
              size="regular"
              key={"create-ruleset-button"}
            >
              Create Ruleset
            </Button>,
          ]}
        />
      </Grid>

      {/* Ruleset empty state */}
      {currentProject != null && !isRulesetsLoading && !rulesets?.data.count && !showRulesetCard && (
        <Grid item xs={12} className={"empty-container"}>
          <KonanEmptyState
            title="No rulesets"
            subTitle="Define conditions with its final labels. To be effective, you integrate it into your workflow"
            buttonText="Create Ruleset"
            setAction={setShowRulesetCard}
          />
        </Grid>
      )}

      {!isRulesetsLoading && (!!duplicatedId || showRulesetCard) && (
        <Grid item xs={12} key={!!duplicatedId ? `duplicate-${duplicatedId}` : "new"}>
          <RulesetCard
            uuid={duplicatedId ?? "new"}
            isDuplicate={!!duplicatedId}
            createdBy={currentUserEmail ?? ""}
            closeCreateCard={!!duplicatedId ? () => setDuplicatedId(null) : () => setShowRulesetCard(false)}
          />
        </Grid>
      )}

      {/* Loading state */}
      {isRulesetsLoading
        ? [1, 2].map((item: number) => <DecisionLoadingCard key={item} />)
        : rulesets?.data?.results?.map((ruleset) => (
            <Grid item xs={12} key={ruleset.uuid}>
              <RulesetCard
                duplicateRuleset={duplicateRuleset}
                uuid={ruleset.uuid}
                createdBy={ruleset.created_by}
                createdAt={ruleset.created_at}
                disableDuplicateButton={!!duplicatedId || showRulesetCard}
              />
            </Grid>
          ))}

      {/* Pagination Section */}
      {!isRulesetsLoading && rulesets?.data.results && rulesets?.data.results.length > 0 && (
        <Grid container item justifyContent="flex-start">
          <Pagination
            count={Math.ceil((rulesets.data.count as number) / pageSize)}
            page={page + 1}
            onChange={(_, value: number) => {
              setPage(value - 1)
              window.scrollTo(0, 0)
            }}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  )
}
