import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { CustomTooltip } from "../UI/CustomTooltip"

export type CustomTableHeadersType = {
  title: string
  tooltipText: NonNullable<React.ReactNode>
}

export function CustomTableHeaders(props: Readonly<CustomTableHeadersType>): React.ReactElement {
  const { title, tooltipText } = props
  const theme = getTheme()

  return (
    <Grid item direction="row" justifyContent="center" alignItems="flex-end" spacing={1}>
      <CustomTooltip title={tooltipText} placement="top" style={{ cursor: "text" }}>
        <Typography variant="a">
          {title}{" "}
          <InfoOutlinedIcon
            sx={{
              color: theme.palette.grayscale.text[1],
              fontSize: "15px",
              verticalAlign: "middle",
            }}
          />
        </Typography>
      </CustomTooltip>
    </Grid>
  )
}

export function CustomTableHeadersWithInternalTooltip(props: Readonly<CustomTableHeadersType>): React.ReactElement {
  const { title, tooltipText } = props

  return (
    <Grid item direction="row" justifyContent="center" alignItems="flex-end" spacing={1} key={title}>
      <CustomTooltip title={tooltipText} placement="top">
        <Typography variant="a" tooltipVerticalAlign="middle" tooltipIconSize={16} style={{ width: "max-content" }}>
          {title}
        </Typography>
      </CustomTooltip>
    </Grid>
  )
}
