import { ReactElement, useState } from "react"

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, Grid, IconButton, Skeleton } from "@mui/material"
import { Avatar, Menu, MenuItem, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { KonanAvatarComponent } from "../../components/KonanAvatar"
import { InfoBlock } from "../../components/containers/InfoBlock"
import { getTheme } from "../../hooks/UseTheme"
import { MemberCardProps } from "./Interfaces"

import styles from "./Members.module.scss"

export function MemberCard(props: Readonly<MemberCardProps>): ReactElement {
  const { name, email, date, role, pending, MenuOptions } = props

  const theme = getTheme()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Card
      className="card-box-shadow"
      style={{ padding: "12px", backgroundColor: theme.palette.grayscale.background[3] }}
    >
      <Grid container direction="column">
        <Grid container item xs={12} justifyContent={"space-between"} wrap="nowrap" minHeight={"22px"}>
          <Grid item xs>
            <Typography variant="span" noWrap variantColor={2}>
              {pending ? "Invited" : "Added"} {moment(new Date(date)).format("MMM DD YYYY")}
            </Typography>
          </Grid>

          {MenuOptions && MenuOptions.length > 0 && (
            <Grid item>
              <IconButton
                size="small"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                className={styles.headerButton}
                id={`${email}-menu-options-btn`}
              >
                <MoreHorizIcon fontSize="small" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                id={`${email}-menu-options`}
                menuMaxContent
              >
                {MenuOptions.map((option) => (
                  <MenuItem
                    onClick={() => {
                      option.onClick()
                      setAnchorEl(null)
                    }}
                    key={option.title}
                  >
                    <Typography variant="label" color={option.variant ?? "neutral"} variantColor={2}>
                      {option.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}
        </Grid>

        <Grid container item xs={12}>
          <KonanAvatarComponent createdBy={name} title={name} avatarName={email} description={email} />
        </Grid>

        <Grid container item xs={12} mt={1} display={"flex"} wrap="nowrap">
          <InfoBlock text={role === "OWNER" ? "Admin" : role === "USER" ? "Viewer" : role} />
        </Grid>
      </Grid>
    </Card>
  )
}

export const MemberCardLoader = (): ReactElement => {
  const theme = getTheme()

  return (
    <Card
      className="card-box-shadow"
      style={{ padding: "12px", backgroundColor: theme.palette.grayscale.background[3] }}
    >
      <Grid container direction="column" spacing={0.75}>
        <Grid container item xs={12} justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="span" variantColor={2}>
              <Skeleton animation="wave" width="100px" height="20%" />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Avatar src="1" isLoading />
        </Grid>

        <Grid container item spacing={1}>
          <Grid item alignSelf={"flex-end"}>
            <Skeleton animation="wave" width="50px" height="20%" />
          </Grid>
          <Grid item alignSelf={"flex-end"}>
            <Skeleton animation="wave" width="100px" height="20%" />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
