import React, { Dispatch, SetStateAction, useEffect } from "react"

import { darkTheme, lightTheme } from "@synapse-analytics/synapse-ui"

export const useTheme = (): ["light" | "dark", Dispatch<SetStateAction<"light" | "dark">>] => {
  const [theme, setTheme] = React.useState<"light" | "dark">(
    (localStorage.getItem("theme") as "light" | "dark") ?? "light",
  )

  // change the app theme so that the css variables change accordingly
  const html = document.documentElement
  html?.setAttribute("app-theme", theme)

  // Setting the theme in localStorage then reload for SUI to take effect
  useEffect(() => {
    const localStorageTheme = localStorage.getItem("theme")

    if (localStorageTheme !== theme) {
      localStorage.setItem("theme", theme)

      window.location.reload()
    }
  }, [theme])

  return [theme, setTheme]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTheme = (): any => {
  const theme = localStorage.getItem("theme")

  return theme === "dark" ? darkTheme : lightTheme
}
