import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"

import { useLocation, useNavigate } from "react-router-dom"

import queryString from "query-string"

/**
 * This custom hook gathers all the logic of redirecting to the current page tab at a given page
 */
export function useHandlePageQueryParam(
  pageValues: string[],
  defaultPageValue: string,
): [string, Dispatch<SetStateAction<string>>] {
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = useMemo(() => queryString.parse(location.search), [location.search])

  const [pageValue, setPageValue] = useState<string>(queryParams?.page ? `${queryParams?.page}` : defaultPageValue)

  /**
   * Effect to make sure tab value will be the default tab value if we have
   * an invalid value in the query-param
   */
  useEffect(() => {
    if (queryParams?.page && !pageValues?.includes(`${queryParams?.page}`)) {
      setPageValue(defaultPageValue)
    } else if (queryParams?.page && pageValues?.includes(`${queryParams?.page}`)) {
      setPageValue(`${queryParams?.page}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname, queryParams?.page])

  // change the queryparam to match the current tab whenever the tabvalue changes
  useEffect(() => {
    const mergedParams = { ...queryParams, page: pageValue }
    const searchString = queryString.stringify(mergedParams)

    navigate(
      {
        pathname: location.pathname,
        search: searchString,
      },
      {
        replace: true,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValue])

  return [pageValue, setPageValue]
}
