import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react"

import { useInfiniteQuery, useMutation } from "react-query"
import { useParams } from "react-router-dom"

import CheckIcon from "@mui/icons-material/Check"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CloseIcon from "@mui/icons-material/Close"
import HistoryIcon from "@mui/icons-material/History"
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled"
import { Box, Card, CircularProgress, Grid, IconButton, Link, Popover } from "@mui/material"
import {
  Button,
  InputText,
  NotificationUtils,
  Select,
  Snackbar,
  Tag,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import _ from "lodash"
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table"
import moment from "moment"

import { queryClient } from "../.."
import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { ExportJobCreateRequest } from "../../types/generated/api/ExportJobCreateRequest"
import { ListPredictionsOutput } from "../../types/generated/api/ListPredictionsOutput"
import { PaginatedListPredictionsList } from "../../types/generated/api/PaginatedListPredictionsList"
import { PaginatedPredictionReviewList } from "../../types/generated/api/PaginatedPredictionReviewList"
import { PredictionReview } from "../../types/generated/api/PredictionReview"
import { PredictionReviewRequest } from "../../types/generated/api/PredictionReviewRequest"
import { extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import { keyValueDeepJsonify } from "../../utils/modelDetailsHelpers"
import { KonanAvatarComponent } from "../KonanAvatar"
import { KonanEmptyState } from "../KonanEmptyState"
import { KonanJsonView } from "../KonanJsonView"
import { ExportDataDialog } from "../dialogs/ExportDataDialog"
import { BaseTableContainer } from "./BaseTableContainer"
import { TAG_VARIANTS } from "./CONSTANTS"
import { CustomTableHeaders, CustomTableHeadersWithInternalTooltip } from "./CustomTableHeader"

import styles from "./ProjectDecisions.module.scss"

let tagVariants = [...TAG_VARIANTS]

const tagVariantHash = {}

export function FeedbackPopper(
  props: Readonly<{
    id: string
    isOpen: boolean
    onClose: () => void
    anchorEl: HTMLButtonElement | null
    feedback: Record<string, unknown>
  }>,
): React.ReactElement {
  const { isOpen, onClose, anchorEl, id, feedback } = props

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={{ minWidth: "200px" }}
    >
      <KonanJsonView src={feedback} />
    </Popover>
  )
}

export function ReviewPopper(
  props: Readonly<{
    id: string
    isOpen: boolean
    onClose: () => void
    anchorEl: HTMLButtonElement | null
    predictionId: string
  }>,
): React.ReactElement {
  const { isOpen, onClose, anchorEl, id, predictionId } = props

  // fetch Review history
  const {
    isLoading,
    data: history,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AxiosResponse<PaginatedPredictionReviewList>, AxiosError>(
    ["review-history", predictionId],
    ({ pageParam = 1 }) =>
      KonanAPI.fetchPredictionReviewHistory({
        prediction_uuid: predictionId,
        page: pageParam,
        pageSize: 5,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },
      enabled: !!predictionId,
      refetchOnMount: true,
    },
  )

  // extracting the results after infinite-Query
  const adjustedHistory = useMemo(() => {
    const result = history?.pages.reduce((accumulator, page) => {
      return accumulator.concat(page.data.results ?? [])
    }, [] as Array<PredictionReview>)

    return result
  }, [history?.pages])

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          id: "review-history-scrollable-target",
          className: "verticalScroller",
          style: {
            borderRadius: "8px",
            boxShadow: "var(--box-shadow)",
            minWidth: 250,
            maxWidth: 400,
            maxHeight: 200,
            height: "auto",
            backgroundColor: "var(--grayscale-background-3)",
            border: "1px solid var(--grayscale-border)",
          },
        },
      }}
    >
      <Grid container p={2}>
        {isLoading ? (
          <Grid container item xs={12} display={"flex"} justifyContent={"center"} alignContent={"center"}>
            <CircularProgress size={20} style={{ color: "var(--blue-text-2)" }} />
          </Grid>
        ) : adjustedHistory?.length === 0 ? (
          <Grid item mb={2.5}>
            <KonanEmptyState title={"Review history unavailable"} subTitle="Mark as reviewd to see review history" />
          </Grid>
        ) : (
          <Grid container item xs={12} gap={1.5}>
            {adjustedHistory &&
              adjustedHistory?.length > 0 &&
              adjustedHistory.map((reviewLog: PredictionReview) => (
                <Grid container display={"flex"} justifyContent={"space-between"} key={reviewLog.uuid}>
                  <Grid item xs={9}>
                    <KonanAvatarComponent
                      createdBy={reviewLog.created_by}
                      createdAt={reviewLog.created_at}
                      avatarName={reviewLog.created_by}
                      description={reviewLog.created_by}
                    />
                  </Grid>

                  <Grid item xs={3} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                    <Tag variant={reviewLog.status === PredictionReview.status.REVOKED ? "default" : "positive"}>
                      {reviewLog.status?.toLocaleLowerCase()}
                    </Tag>
                  </Grid>
                </Grid>
              ))}

            {hasNextPage && (
              <Grid item display={"flex"}>
                <Button
                  variant="ghost"
                  onClick={() => {
                    !isFetchingNextPage && fetchNextPage()
                  }}
                >
                  <Grid container item xs={12} display={"flex"}>
                    Load More
                    {isFetchingNextPage && (
                      <Grid item ml={1} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <CircularProgress size={14} color="primary" />
                      </Grid>
                    )}
                  </Grid>
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Popover>
  )
}

type Props = {
  data: PaginatedListPredictionsList | undefined
  isFetching: boolean
  isLoading: boolean
  pagination: MRT_PaginationState
  setPagination?: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  filterObject: object
  setFilterObject: React.Dispatch<React.SetStateAction<object>>
  reviewLabel: "REVIEWED" | "REVOKED" | undefined
  setReviewLabel: React.Dispatch<React.SetStateAction<"REVIEWED" | "REVOKED" | undefined>>
}

export function TableLoadingState(): React.ReactElement {
  return (
    <Card className="card-box-shadow" style={{ height: "300px", display: "flex" }}>
      <Grid container className={styles.emptyStateRoot}>
        <Grid item xs={12} className={styles.emptyStateItem}>
          <CircularProgress size={20} color="primary" />
        </Grid>
        <Grid item xs={12} className={styles.emptyStateItem}>
          <Typography variant={"p"}>Fetching your data...</Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

const ReviewButton = (props: { row: object }): React.ReactElement => {
  const { row } = props
  const [isHoveringOverIcon, setIsHoveringOverIcon] = useState(false)

  const createPredictionReviewMutation = useMutation<
    AxiosResponse<PredictionReview>,
    AxiosError,
    PredictionReviewRequest & { prediction_uuid: string }
  >(KonanAPI.createPredictionReview, {
    onSuccess: async () => {
      NotificationUtils.toast("Review sent successfully", { snackBarVariant: "positive" })

      queryClient.invalidateQueries("project-predictions")

      // revert the hovering state to default after requst is finsihed
      setIsHoveringOverIcon(false)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (error: any) => {
      NotificationUtils.toast(error?.response?.data?.details ?? "Review failed", { snackBarVariant: "negative" })

      // revert the hovering state to default after requst is finsihed
      setIsHoveringOverIcon(false)
    },
  })

  return (
    <Fragment>
      {[null, undefined].includes(row.original.backend_review) ||
      row.original.backend_review?.status === PredictionReview.status.REVOKED ? (
        <Button
          variant="secondary"
          onClick={() =>
            createPredictionReviewMutation.mutateAsync({
              prediction_uuid: row.original.uuid,
              status: PredictionReview.status.REVIEWED,
            })
          }
          disabled={createPredictionReviewMutation.isLoading}
        >
          Mark as Reviewed
        </Button>
      ) : (
        <Grid
          container
          direction={"row"}
          display={"flex"}
          className={styles.reviewedArea}
          wrap="nowrap"
          gap={0.5}
          onMouseEnter={() => setIsHoveringOverIcon(true)}
          onMouseLeave={() => setIsHoveringOverIcon(false)}
          onClick={() =>
            !createPredictionReviewMutation.isLoading &&
            createPredictionReviewMutation.mutateAsync({
              prediction_uuid: row.original.uuid,
              status: PredictionReview.status.REVOKED,
            })
          }
        >
          <Grid item display={"flex"} alignItems={"center"}>
            {createPredictionReviewMutation.isLoading ? (
              <CircularProgress size={14} style={{ color: "var(--blue-text-2)", marginRight: "10px" }} />
            ) : (
              <IconButton disabled={createPredictionReviewMutation.isLoading} style={{ padding: "2px" }}>
                {isHoveringOverIcon ? (
                  <ReplayCircleFilledIcon
                    fontSize="small"
                    className="neutral-table-icon"
                    style={{ fontSize: "1.25rem" }}
                  />
                ) : (
                  <CheckCircleIcon fontSize="small" className="good-table-icon" style={{ fontSize: "1.25rem" }} />
                )}
              </IconButton>
            )}
          </Grid>

          <Grid item display={"flex"} alignItems={"center"}>
            {/* Truncating programmatically since i cant size table columns automatically  */}
            {row.original.backend_review.created_by}
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}

export function ProjectDecisionsTable(props: Readonly<Props>): React.ReactElement {
  const {
    data,
    isFetching,
    isLoading,
    pagination,
    setPagination,
    filterObject,
    setFilterObject,
    reviewLabel,
    setReviewLabel,
  } = props
  const { id: projectId } = useParams<{ id: string }>()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const theme = getTheme()

  const [isExportDialogOpen, setIsExportDialogOpen] = useState<boolean>(false)

  const clearFilter = useCallback(
    (item: string, type: string): void => {
      const tempObject = filterObject
      // eslint-disable-next-line
      // @ts-ignore
      delete tempObject[`${type}__${item.split(".").join("__")}`]

      setFilterObject({
        ...tempObject,
      })
      setPagination?.({
        pageIndex: 0,
        pageSize: 10,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterObject],
  )

  const handleFilterChange = useCallback(
    (value: string | number, title: string, type: string): void => {
      const tempObject = filterObject

      if (!value) {
        clearFilter(title, type)
        return
      }

      // eslint-disable-next-line
      // @ts-ignore
      tempObject[`${type}__${title.split(".").join("__")}`] = value

      setFilterObject({
        ...tempObject,
      })
      setPagination?.({
        pageIndex: 0,
        pageSize: 10,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterObject],
  )

  const debouncedFilter = useMemo(() => {
    return _.debounce(handleFilterChange, 1000)
  }, [handleFilterChange])

  // When the component unmounts, the search request could be still in progress.
  //  In this case, an error will occur when the characters state is set.
  useEffect(() => {
    return () => {
      debouncedFilter.cancel()
    }
  }, [debouncedFilter])

  const assignTagVariantToReasonValue = (reason: string): void => {
    // refilling color array if we run out of variants
    // TODO:: should find a dynamic way to fill tag color later
    if (tagVariants.length === 0) tagVariants = [...TAG_VARIANTS]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tagVariantHash[reason] = tagVariants[0]

    tagVariants.splice(0, 1)
  }

  const decisionReason = (rowData: ListPredictionsOutput): { text: string; tooltip: string; type: string } => {
    const tooltip = rowData.workflow_output?.description
    let text = ""
    let type = currentProject?.category === "workflow" ? "__" : "AI Engine"

    if (rowData.workflow_output) {
      // getting last node in the satisfied_flow_path
      // to show it's data in the reason column since it's the last node the prediction ran into
      let lastNode = rowData.workflow_output?.satisfied_flow_path?.slice(-1)[0]
      if (lastNode?.type !== "project") {
        if (lastNode?.type === "loop-start") {
          const lastIteration = lastNode.iterations[Object.keys(lastNode.iterations).length]
          lastNode = lastIteration[lastIteration.length - 1]
        }

        // Adding __ as a fallback for when there isn't any nodes in the satisfied_flow_path to parse the data from
        text = lastNode ? `${lastNode?.type?.charAt(0).toUpperCase() + lastNode?.type?.slice(1)}` : "__"
      }

      type = lastNode?.type
      if (lastNode?.name) type += `: ${lastNode?.name}`
    }

    return { text: text, type: type, tooltip: tooltip }
  }

  // get table columns from response
  const tableColumns = useMemo((): { title: string; field: string; type: string }[] => {
    const columnSet = new Set()

    // loop over the list to collect all possible columns
    if (data?.results?.outputs && data.results.outputs.length > 0) {
      for (let i = 0; i < data.results.outputs.length; i++) {
        if (data?.results.outputs[i]?.features_json) {
          for (const item in keyValueDeepJsonify(data?.results.outputs[i]?.features_json)) {
            const adjustedItem = {
              item: item,
              type: "features",
            }
            const stringifiedItem = JSON.stringify(adjustedItem)
            !columnSet.has(stringifiedItem) && columnSet.add(stringifiedItem)
          }

          // adding calculated features to table columns
          for (const item in keyValueDeepJsonify(
            data?.results.outputs[i]?.workflow_output?.calculated_features,
            data.results.workflow_response_filters,
          )) {
            const adjustedItem = {
              item: item,
              type: "calculated_features",
            }
            const stringifiedItem = JSON.stringify(adjustedItem)
            !columnSet.has(stringifiedItem) && columnSet.add(stringifiedItem)
          }

          // adding calculated features to table columns
          for (const item in keyValueDeepJsonify(
            data?.results.outputs[i]?.be_output?.output,
            data.results.workflow_response_filters,
          )) {
            const adjustedItem = {
              item: item,
              type: "Model output",
            }
            const stringifiedItem = JSON.stringify(adjustedItem)
            !columnSet.has(stringifiedItem) && columnSet.add(stringifiedItem)
          }
        }
      }
    }

    return data?.results && data?.results?.outputs.length > 0
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Array.from(columnSet.keys()).map((column: any) => {
          const item = JSON.parse(column)
          return {
            title: item.item,
            field: item.item,
            type: item.type,
            align: "center",
          }
        })
      : []
  }, [data?.results])

  const adjustedData = useMemo((): ListPredictionsOutput[] => {
    return data?.results && data?.results.outputs.length > 0
      ? data?.results.outputs.map((item: ListPredictionsOutput) => {
          // TODO:: refactor to avoid any overlap between preset object elements names and schema/cfn names
          return {
            ...item,
            ...item.features_json,
            ...item.workflow_output?.calculated_features,
            ...item.be_output?.output,
            backend_review: item.review,
          }
        })
      : []
  }, [data])

  // this function handles both (background color and text color) in decision column,
  // inside classification metrics screen
  function getDecisionColumn(
    rowData: ListPredictionsOutput,
  ): { backgroundColor: string; text: string; color: "neutral" | "negative" | "positive" } | undefined {
    const theme = getTheme()
    const result: { backgroundColor: string; text: string; color: "neutral" | "negative" | "positive" } = {
      backgroundColor: "__",
      text: "__",
      color: "neutral",
    }

    if (rowData.feedback === null) {
      result.backgroundColor = ""
      result.color = "neutral"
    } else {
      if (rowData.feedback && rowData.joint_output) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (rowData.feedback === rowData.joint_output) {
          result.backgroundColor = theme.palette.green.background[2]
          result.color = "positive"
        } else {
          result.backgroundColor = theme.palette.red.background[2]
          result.color = "negative"
        }
      } else if (rowData.feedback && rowData.be_output?.target_label) {
        if (rowData.feedback === rowData.be_output?.target_label) {
          result.backgroundColor = theme.palette.green.background[2]
          result.color = "positive"
        } else {
          result.backgroundColor = theme.palette.red.background[2]
          result.color = "negative"
        }
      }
    }

    if (![null, undefined].includes(rowData.joint_output)) {
      result.text = rowData.joint_output
    } else if (![null, undefined].includes(rowData.be_output?.target_label)) {
      if (rowData.be_output?.target_label === "uncovered") {
        result.text = "Uncovered"
      } else if (rowData.be_output?.target_label === "invalid") {
        result.text = "Invalid"
      } else {
        result.text = rowData.be_output?.target_label
      }
    } else {
      result.text = "__"
    }

    return result
  }

  const adjustedColumns = useMemo((): MRT_ColumnDef<ListPredictionsOutput>[] => {
    return [
      ...(currentProject?.category === "workflow"
        ? [
            {
              header: "Reviewed",
              accessorKey: "Reviewed",
              Filter: () => (
                <Select
                  id="reviewed-filter"
                  placeholder={"All"}
                  value={reviewLabel}
                  optionsWithValues={[
                    { label: "All", value: undefined },
                    { label: "Reviewed", value: "REVIEWED" },
                    { label: "Revoked", value: "REVOKED" },
                  ]}
                  handleChange={(e) => {
                    setReviewLabel(e.target.value as "REVIEWED" | "REVOKED" | undefined)
                  }}
                  fullWidth
                />
              ),
              Cell: ({ row }) => {
                const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

                return (
                  <Grid container direction={"row"} display={"flex"} gap={1} wrap="nowrap">
                    <Grid container item display={"flex"}>
                      <ReviewButton row={row} />
                    </Grid>

                    <Grid item display={"flex"} alignItems={"center"}>
                      <Button
                        variant="ghost"
                        onClick={(e) => {
                          setAnchorEl(e.target)
                        }}
                      >
                        <HistoryIcon fontSize="small" />
                      </Button>

                      {Boolean(anchorEl) && (
                        <ReviewPopper
                          id={row.original.uuid}
                          isOpen={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={() => {
                            setAnchorEl(null)
                          }}
                          predictionId={row.original.uuid}
                        />
                      )}
                    </Grid>
                  </Grid>
                )
              },
            },
          ]
        : []),
      {
        header: "Request date (UTC)",
        accessorKey: "created_at",
        Cell: ({ row }) => {
          return (
            <Typography variant={"p"}>
              {moment(row.original?.created_at).utc().format("MMM DD YYYY, H:mm:ss")}
            </Typography>
          )
        },
      },
      {
        header: "Decision",
        Header: (
          <CustomTableHeaders
            title="Decision"
            tooltipText={
              <React.Fragment>
                Final decision taken combining both credit rules and the model.
                <br />
                Rules override the models decision.
                <br />
                Will be colored:
                <ul>
                  <li>
                    <strong style={{ color: theme.palette.green.text[2] }}>GREEN</strong> for correct decisions.
                  </li>
                  <li>
                    <strong style={{ color: theme.palette.red.text[2] }}>RED</strong> for incorrect decisions.
                  </li>
                  <li>
                    <strong>UNCOLORED</strong> for decisions pending feedback.
                  </li>
                </ul>
              </React.Fragment>
            }
          />
        ),
        accessorKey: "joint_output",
        muiTableBodyCellProps: ({ row }) => {
          return { sx: { background: getDecisionColumn(row.original)?.backgroundColor } }
        },
        Cell: ({ row }) => {
          const decision = getDecisionColumn(row.original)
          return (
            <Typography variant={"p"} color={decision?.color} variantColor={decision?.color === "neutral" ? 1 : 2}>
              {decision?.text}
            </Typography>
          )
        },
      },
      {
        header: "Reason",
        Header: <CustomTableHeaders title="Reason" tooltipText="Indication to the reason behind this decision." />,
        accessorKey: "reason_row",
        Cell: ({ row }) => {
          const reason = decisionReason(row.original)
          // checking if reason is not in the tag variant hash to assign a tag to it
          // else skip and render the tag directly
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !tagVariantHash[reason.text] && assignTagVariantToReasonValue(reason.text)
          return (
            <Grid container wrap="nowrap" display={"flex"}>
              {reason.type === "__" ? (
                <Typography variant={"p"}>{reason.type}</Typography>
              ) : (
                <Tag
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  variant={tagVariantHash[reason.text] ?? "cyan"}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Tooltip title={reason.tooltip}>{reason.type ?? "Reason not specified"}</Tooltip>
                </Tag>
              )}
            </Grid>
          )
        },
      },
      {
        header: "Feedback",
        Header: (
          <CustomTableHeaders
            title="Feedback"
            tooltipText="Feedback provided by the user through the feedback endpoint. Indicates the actual ground truth label."
          />
        ),
        accessorKey: "feedback_row",
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

          return ["object"].includes(typeof row?.original?.feedback) && !!row?.original?.feedback ? (
            <Fragment>
              {Boolean(anchorEl) && (
                <FeedbackPopper
                  id={row.original.uuid}
                  isOpen={Boolean(anchorEl)}
                  onClose={function (): void {
                    setAnchorEl(null)
                  }}
                  anchorEl={anchorEl}
                  feedback={row.original.feedback}
                />
              )}

              {/* TODO:: replace with SUI anchor component in the revamp
                  leaving the styles inline to remember to remove them when revamping */}
              <Link
                onClick={(e) => {
                  setAnchorEl(e.target)
                }}
                style={{
                  fontFamily: '"inter",sans-serif',
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              >
                View feedback
              </Link>
            </Fragment>
          ) : (
            <Typography variant={"p"} variantColor={1}>
              {(row?.original?.feedback ?? "__") as string}
            </Typography>
          )
        },
      },
      ...tableColumns
        // TODO:: Refactor later
        .toSorted((a, _b) => (a.title === "KONAN_TAGS" ? -1 : 1))
        .map((item): MRT_ColumnDef<ListPredictionsOutput> => {
          const parsedTitle = item.title.split(".")
          const type = item.type

          return {
            ...item,
            accessorKey: `${item.type}-${item.title}`,
            header: item.title,
            Header: (
              <CustomTableHeadersWithInternalTooltip
                title={parsedTitle[parsedTitle.length - 1]}
                tooltipText={
                  <Fragment>
                    Source: {item.type}
                    <br />
                    Name: {item.title}
                  </Fragment>
                }
              />
            ),
            Filter: () => {
              const [value, setValue] = useState<string | number | undefined>(
                filterObject[`${item.type}__${item.title}`] ?? undefined,
              )

              return (
                <InputText
                  id={`${type}-${item.title}`}
                  key={`${type}-${item.title}`}
                  hideDescription
                  placeholder={"Filter"}
                  fullWidth
                  value={value}
                  handleChange={(e) => {
                    setValue(e.target.value)
                    debouncedFilter(
                      item.title === "KONAN_TAGS" || e.target.value.includes(",")
                        ? (e.target.value.split(",").map((item) => (typeof item === "string" ? item.trim() : item)) ??
                            e.target.value)
                        : e.target.value,
                      item.title,
                      type,
                    )
                  }}
                  endAdornment={
                    !["", null, undefined].includes(value) ? (
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {
                          setValue("")
                          clearFilter(item.title, type)
                          document.getElementById(`${type}-${item.title}`).value = ""
                        }}
                      />
                    ) : undefined
                  }
                />
              )
            },
            Cell: ({ row }) => {
              const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

              const value = _.get(row.original, item.title)

              return Boolean(value) && (typeof value === "object" || (Array.isArray(value) && value.length > 0)) ? (
                <Fragment>
                  {Boolean(anchorEl) && (
                    <FeedbackPopper
                      id={row.original.uuid}
                      isOpen={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                      anchorEl={anchorEl}
                      feedback={value}
                    />
                  )}

                  {/* TODO:: replace with SUI anchor component in the revamp
                    leaving the styles inline to remember to remove them when revamping */}
                  <Link
                    onClick={(e) => setAnchorEl(e.target)}
                    style={{
                      fontFamily: '"inter",sans-serif',
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                    }}
                  >
                    View
                  </Link>
                </Fragment>
              ) : typeof value === "boolean" ? (
                value ? (
                  <CheckIcon style={{ fontSize: "1.1rem" }} />
                ) : (
                  <CloseIcon style={{ fontSize: "1.1rem" }} />
                )
              ) : (
                <Typography variant={"p"} variantColor={1}>
                  {Array.isArray(value) && value.length === 0 ? "__" : ((value ?? "__") as string)}
                </Typography>
              )
            },
          }
        }),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns])

  return (
    <Fragment>
      {isExportDialogOpen && (
        <ExportDataDialog
          onClose={() => setIsExportDialogOpen(false)}
          jobType={ExportJobCreateRequest.type.DEPLOYMENT_PREDICTIONS}
        />
      )}

      {!isLoading ? (
        <BaseTableContainer
          id="project-decisions-table"
          title="Project Decisions"
          tooltip="All the decisions that the classification model took, along with each associated actual label."
          columns={adjustedColumns}
          data={adjustedData}
          isLoading={isFetching}
          source="live-decisions"
          manualPagination
          rowCount={data?.count}
          setPagination={setPagination}
          pagination={pagination}
          hiddenColumns={data?.results?.workflow_response_filters ?? []}
          clearAllFiltersFn={Object.keys(filterObject).length > 0 ? () => setFilterObject({}) : undefined}
          onExportClick={() => setIsExportDialogOpen(true)}
          detailPanel={(row) => (
            <Box p={2} position={"sticky"} left={0}>
              {Boolean(row.original?.workflow_output?.satisfied_flow_path) || Boolean(row.original?.feedback) ? (
                <Grid container spacing={2}>
                  {Boolean(row.original?.workflow_output?.satisfied_flow_path) && (
                    <Grid item xs={Boolean(row.original?.feedback) ? 6 : 12}>
                      <Typography variant="p" gutterBottom>
                        Workflow Path
                      </Typography>
                      <KonanJsonView src={row.original?.workflow_output?.satisfied_flow_path} />
                    </Grid>
                  )}
                  {Boolean(row.original?.feedback) && (
                    <Grid item xs={6}>
                      <Typography variant="p" gutterBottom>
                        Feedback
                      </Typography>
                      <KonanJsonView src={row.original?.feedback} />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Snackbar
                  variant={"warning"}
                  fullWidth
                  action="send feedback"
                  onAction={() => window.open(`/projects/${projectId}/integration/rest-api`, "_blank")}
                >
                  No feedback to show
                </Snackbar>
              )}
            </Box>
          )}
        />
      ) : (
        <TableLoadingState />
      )}
    </Fragment>
  )
}
