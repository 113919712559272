import React, { Fragment, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useDispatch, useSelector } from "react-redux"

import AddIcon from "@mui/icons-material/Add"
import { Divider, Grid } from "@mui/material"
import { Button, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from ".."
import { KonanEmptyState } from "../components/KonanEmptyState"
import { SideBar } from "../components/UI/SideBar"
import { GroupContainer, GroupCreationDialog, setHistory as setGroupsHistory, setgroups } from "../features/Groups"
import {
  Container,
  MemberCardLoader,
  MemberInviteDialog,
  PendingMemberContainer,
  setMembers,
  setPendingMembers,
} from "../features/Members"
import {
  RoleCardLoader,
  RoleContainer,
  RoleCreationDialog,
  setRoles,
  setHistory as setRolesHistory,
} from "../features/Roles"
import { LogItem } from "../features/Roles/LogItem"
import { SubHeader } from "../layouts/SubHeader"
import { SidAPI } from "../services/SidAPI"
import { RootState } from "../store/ReduxStore"
import { CreateGroupRequestProps, CreateRoleRequestProps } from "../types/custom/Permissions"
import { GroupRetrieve } from "../types/generated/authentication/GroupRetrieve"
import { KonanResource } from "../types/generated/authentication/KonanResource"
import { Organization } from "../types/generated/authentication/Organization"
import { OrganizationInvitation } from "../types/generated/authentication/OrganizationInvitation"
import { Role } from "../types/generated/authentication/Role"
import { RolePermission } from "../types/generated/authentication/RolePermission"
import { TypeEnum } from "../types/generated/authentication/TypeEnum"
import { UserManagementLog } from "../types/generated/authentication/UserManagementLog"
import { isPermitted } from "../utils/PermissionsHelpers"
import { Auth } from "../utils/auth"

interface SideBarSettingsReturn {
  title: string
  body?: React.ReactElement
  actions?: React.ReactElement[]
}

function GetSideBarSettings(mode: "pending-members" | "group-history" | "role-history" | null): SideBarSettingsReturn {
  // TODO:: Lookup a way to reduce this overhead
  // Idea:: make a separate function for each and call when needed
  const pendingInvites = useSelector((state: RootState) => state.members.pendingMembers)
  const rolesHistory = useSelector((state: RootState) => state.roles.history)
  const groupsHistory = useSelector((state: RootState) => state.groups.history)

  switch (mode) {
    case "pending-members":
      return {
        title: "Pending Members",
        body: (
          <Grid container item xs={12} spacing={1.5}>
            {(pendingInvites ?? []).length > 0 ? (
              pendingInvites.map((user) => (
                <Grid item xs={12} key={user.email}>
                  <PendingMemberContainer member={user} />
                </Grid>
              ))
            ) : (
              <Grid container item xs={12} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                <KonanEmptyState title="No Invites available." />
              </Grid>
            )}
          </Grid>
        ),
      }

    case "group-history":
      return {
        title: "Group history",
        body: (
          <Grid container item xs={12} spacing={1.5}>
            {(groupsHistory ?? []).length > 0 ? (
              groupsHistory.map((log) => (
                <Grid item xs={12} key={log.uid}>
                  <LogItem log={log} />
                </Grid>
              ))
            ) : (
              <Grid container item xs={12} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                <KonanEmptyState title="No history available." />
              </Grid>
            )}
          </Grid>
        ),
      }

    case "role-history":
      return {
        title: "Role history",
        body: (
          <Grid container item xs={12} spacing={1.5}>
            {(rolesHistory ?? []).length > 0 ? (
              rolesHistory.map((log) => (
                <Grid item xs={12} key={log.uid}>
                  <LogItem log={log} />
                </Grid>
              ))
            ) : (
              <Grid container item xs={12} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                <KonanEmptyState title="No history available." />
              </Grid>
            )}
          </Grid>
        ),
      }

    default:
      return {
        title: "Something went wrong",
      }
  }
}

/**
 * Wrapper component for Users/Team module
 * handles data fetching for `fetch-organization` endpoint
 * contains all related dialogs and Users table
 * @returns {React.ReactElement}
 */
export function Settings(): React.ReactElement {
  const dispatch = useDispatch()

  // extracting organization UID as well as the current user role
  const organizationUUID = Auth.getOrganizationUID() as string
  const permissions = Auth.getPermissions()

  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)

  const [openInviteDialog, setOpenInviteDialog] = useState<boolean>(false)

  const [isRoleDialogOpen, setIsRoleDialogOpen] = useState<boolean>(false)
  const [isGroupDialogOpen, setIsGroupDialogOpen] = useState<boolean>(false)

  const [isSideBarOpen, setIsSideBarOpen] = useState(false)
  const [drawerMode, setDrawerMode] = useState<"role-history" | "group-history" | "pending-members" | null>(null)

  const sideBarSettings = GetSideBarSettings(drawerMode)

  // Fetching organization roles
  const { isLoading: isRolesLoading, data: roles } = useQuery<AxiosResponse<Role[]>, AxiosError>(
    ["roles", organizationUUID],
    () => SidAPI.fetchRoles(organizationUUID),
    {
      onSuccess: (response) => {
        dispatch(setRoles(response.data))
      },
      enabled: !!organizationUUID && isPermitted("List roles", permissions.synapse_id, flattenedSidPermissions),
      refetchOnMount: false,
    },
  )

  // Fetching organization groups
  const { isLoading: isGroupsLoading, data: groups } = useQuery<AxiosResponse<GroupRetrieve[]>, AxiosError>(
    ["groups", organizationUUID],
    () => SidAPI.fetchGroups(organizationUUID),
    {
      onSuccess: (response) => {
        dispatch(setgroups(response.data))
      },
      enabled: !!organizationUUID && isPermitted("List groups", permissions.synapse_id, flattenedSidPermissions),
      refetchOnMount: false,
    },
  )

  // Fetching history
  const { isLoading: isHistoryLoading } = useQuery<AxiosResponse<UserManagementLog[]>, AxiosError>(
    ["change-history", organizationUUID],
    () => SidAPI.fetchUserManagementChangeHistory(organizationUUID),
    {
      onSuccess: (response) => {
        dispatch(setGroupsHistory(response.data.filter((log) => log.target.type === "group")))
        dispatch(setRolesHistory(response.data.filter((log) => log.target.type === "role")))
      },
      enabled:
        !!organizationUUID && isPermitted("List user management logs", permissions.synapse_id, flattenedSidPermissions),
      refetchOnMount: false,
    },
  )

  // TODO: Handle what happens when errors appear here
  const { isLoading: isOrgFetching, data: OrganizationResponse } = useQuery<AxiosResponse<Organization>, AxiosError>(
    ["Organization", organizationUUID],
    () => SidAPI.fetchOrganization(organizationUUID),
    {
      onSuccess: (response) => {
        dispatch(setMembers(response.data.users))
      },
      enabled: !!organizationUUID,
      refetchOnMount: false,
    },
  )

  // Fetching organization invitations
  const { isLoading: isPendingInvitesLoading } = useQuery<AxiosResponse<Array<OrganizationInvitation>>, AxiosError>(
    ["OrganizationInvitation", organizationUUID],
    () => SidAPI.fetchPendingInvites(organizationUUID),
    {
      onSuccess: (response) => {
        dispatch(setPendingMembers(response.data))
      },
      enabled: isPermitted("List user invitations", permissions.synapse_id, flattenedSidPermissions),
      refetchOnMount: false,
    },
  )

  const CreateRoleMutation = useMutation<
    AxiosResponse<Role>,
    AxiosError,
    CreateRoleRequestProps & { organization_uuid: string }
  >(SidAPI.createRole, {
    onSuccess: () => {
      NotificationUtils.toast("Role created successfully", {
        snackBarVariant: "positive",
      })

      queryClient.invalidateQueries("konan-permissions")
      queryClient.invalidateQueries("sid-permissions")
      queryClient.invalidateQueries(["roles", organizationUUID])
    },
    onError: () =>
      NotificationUtils.toast("couldn't create role", {
        snackBarVariant: "negative",
      }),
  })

  const createRoleHandler = async (name: string, description: string, permissions: RolePermission[]): Promise<void> => {
    await CreateRoleMutation.mutateAsync({
      organization_uuid: organizationUUID,
      name: name,
      description: description,
      permissions: permissions,
    })

    setIsRoleDialogOpen(false)
  }

  const CreateGroupMutation = useMutation<
    AxiosResponse<GroupRetrieve>,
    AxiosError,
    CreateGroupRequestProps & { organization_uuid: string }
  >(SidAPI.createGroup, {
    onSuccess: () => {
      NotificationUtils.toast("Group created successfully", {
        snackBarVariant: "positive",
      })

      queryClient.invalidateQueries(["groups", organizationUUID])
    },
    onError: () =>
      NotificationUtils.toast("Group creation failed", {
        snackBarVariant: "negative",
      }),
  })

  const createGroupHandler = async (name: string, resources: KonanResource[]): Promise<void> => {
    await CreateGroupMutation.mutateAsync({
      organization_uuid: organizationUUID,
      name: name,
      konan_resources: [
        ...resources.map((resource): KonanResource => {
          return { uid: resource.uid, type: TypeEnum.PROJECT }
        }),
      ],
    })
  }

  return (
    <React.Fragment>
      {/* Dialogs*/}
      {openInviteDialog && <MemberInviteDialog isOpen={openInviteDialog} onClose={() => setOpenInviteDialog(false)} />}

      {isRoleDialogOpen && (
        <RoleCreationDialog
          isOpen={isRoleDialogOpen}
          onClose={() => setIsRoleDialogOpen(false)}
          onAccept={createRoleHandler}
        />
      )}

      {isGroupDialogOpen && (
        <GroupCreationDialog
          isOpen={isGroupDialogOpen}
          onClose={() => setIsGroupDialogOpen(false)}
          onAccept={createGroupHandler}
        />
      )}

      <Grid container item xs={12} display={"block"} wrap="nowrap" py={3}>
        <Grid container item xs={12}>
          {isPermitted("List users", permissions.synapse_id, flattenedSidPermissions) && (
            <Grid container item xs={12} spacing={1.5} pb={3} px={3}>
              <SubHeader
                title="Members"
                actions={[
                  isPermitted("List user invitations", permissions.synapse_id, flattenedSidPermissions) ? (
                    <Button
                      id="sent-invites"
                      key="sent-invites"
                      variant="secondary"
                      size="regular"
                      onClick={() => {
                        setIsSideBarOpen(true)
                        setDrawerMode("pending-members")
                      }}
                      disabled={isPendingInvitesLoading}
                    >
                      Pending Members
                    </Button>
                  ) : undefined,
                  isPermitted("Invite users", permissions.synapse_id, flattenedSidPermissions) ? (
                    <Button
                      size="regular"
                      id="invite-members"
                      key="invite-members"
                      variant="primary"
                      onClick={() => setOpenInviteDialog(true)}
                      startIcon={<AddIcon fontSize="small" />}
                    >
                      Invite Members
                    </Button>
                  ) : undefined,
                ]}
              />

              {isOrgFetching && (
                <Grid container item spacing={1.5}>
                  {[1, 2, 3].map((item: number) => {
                    return (
                      <Grid item xs={12} md={6} lg={3} key={`member-loader-${item}`}>
                        <MemberCardLoader />
                      </Grid>
                    )
                  })}
                </Grid>
              )}

              {!isOrgFetching && (
                <Grid container item spacing={1.5}>
                  {[...(OrganizationResponse?.data.users ?? [])]
                    .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                    .map((user) => (
                      <Grid item xs={12} md={6} lg={3} key={user.email}>
                        <Container member={user} isLastMember={OrganizationResponse?.data.users.length === 1} />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Grid>
          )}

          {isPermitted("List roles", permissions.synapse_id, flattenedSidPermissions) && (
            <Fragment>
              {isPermitted("List users", permissions.synapse_id, flattenedSidPermissions) && (
                <Grid container item xs={12}>
                  <Divider
                    orientation="horizontal"
                    style={{ backgroundColor: "var(--grayscale-border)", width: "100%" }}
                  />
                </Grid>
              )}

              <Grid container item xs={12} spacing={1.5} p={3}>
                <SubHeader
                  title="Roles & Permission"
                  actions={[
                    isPermitted("List user management logs", permissions.synapse_id, flattenedSidPermissions) ? (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsSideBarOpen(true)
                          setDrawerMode("role-history")
                        }}
                        disabled={isHistoryLoading}
                        size="regular"
                      >
                        History
                      </Button>
                    ) : undefined,
                    isPermitted("Create roles", permissions.synapse_id, flattenedSidPermissions) ? (
                      <Button
                        key="sent-invites"
                        id="sent-invites"
                        variant="primary"
                        onClick={() => setIsRoleDialogOpen(true)}
                        startIcon={<AddIcon fontSize="small" />}
                        size="regular"
                      >
                        New Role
                      </Button>
                    ) : undefined,
                  ]}
                />

                {isRolesLoading &&
                  [1, 2, 3].map((item: number) => {
                    return (
                      <Grid item xs={12} md={6} lg={3} key={`role-loader-${item}`}>
                        <RoleCardLoader />
                      </Grid>
                    )
                  })}

                {!isRolesLoading &&
                  (roles?.data ?? []).length > 0 &&
                  [...(roles?.data ?? [])]
                    ?.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                    ?.sort((a, _) => (a.is_owner ? -1 : 0))
                    .map((role) => (
                      <Grid item xs={12} md={6} lg={3} key={role.name}>
                        <RoleContainer role={role} users={role.users.map((user) => user.email)} />
                      </Grid>
                    ))}
              </Grid>
            </Fragment>
          )}

          {isPermitted("List groups", permissions.synapse_id, flattenedSidPermissions) && (
            <Fragment>
              {(isPermitted("List users", permissions.synapse_id, flattenedSidPermissions) ||
                isPermitted("List roles", permissions.synapse_id, flattenedSidPermissions)) && (
                <Grid container item xs={12}>
                  <Divider
                    orientation="horizontal"
                    style={{ backgroundColor: "var(--grayscale-border)", width: "100%" }}
                  />
                </Grid>
              )}

              <Grid container item xs={12} spacing={1.5} p={3} pb={0}>
                <SubHeader
                  title="Groups & Access"
                  actions={[
                    isPermitted("List user management logs", permissions.synapse_id, flattenedSidPermissions) ? (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsSideBarOpen(true)
                          setDrawerMode("group-history")
                        }}
                        disabled={isHistoryLoading}
                        size="regular"
                      >
                        History
                      </Button>
                    ) : undefined,
                    isPermitted("Create groups", permissions.synapse_id, flattenedSidPermissions) ? (
                      <Button
                        key="sent-invites"
                        id="sent-invites"
                        variant="primary"
                        onClick={() => setIsGroupDialogOpen(true)}
                        startIcon={<AddIcon fontSize="small" />}
                        size="regular"
                      >
                        New Group
                      </Button>
                    ) : undefined,
                  ]}
                />

                {isGroupsLoading &&
                  [1, 2, 3].map((item: number) => {
                    return (
                      <Grid item xs={12} md={6} lg={3} key={`group-loader-${item}`}>
                        <RoleCardLoader />
                      </Grid>
                    )
                  })}

                {!isGroupsLoading &&
                  (groups?.data ?? []).length > 0 &&
                  [...(groups?.data ?? [])]
                    ?.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                    .map((group) => (
                      <Grid item xs={12} md={6} lg={3} key={group.name}>
                        <GroupContainer
                          group={group}
                          immutable={group.name === "All Projects"}
                          users={group.users.map((user) => user.email)}
                        />
                      </Grid>
                    ))}
              </Grid>
            </Fragment>
          )}
        </Grid>

        <SideBar
          isOpen={isSideBarOpen}
          title={sideBarSettings.title}
          onClose={() => setIsSideBarOpen(false)}
          actions={sideBarSettings.actions}
        >
          {sideBarSettings.body}
        </SideBar>
      </Grid>
    </React.Fragment>
  )
}
