import React from "react"

import { Card, Grid } from "@mui/material"
import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import styles from "../../TrainingData.module.scss"

export function ProcessingFailedLoader(): React.ReactElement {
  return (
    <Card className={"card-box-shadow"}>
      <Grid container direction="column" className={styles.failedProcessingCard}>
        <Grid container item xs={12}>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={3} sx={{ marginTop: "4px" }}>
              <Typography variant="p" noWrap>
                <Skeleton variant="rectangular" height={15} width={"80%"} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={6} className={styles.trainingDataLoaderName}>
              <Typography variant="p" noWrap>
                <Skeleton variant="rectangular" height={15} width={"60%"} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center" className={styles.trainingDataAvatarContainer}>
            <Grid item sx={{ marginTop: "10px" }}>
              <Skeleton variant="circular" width={32} height={32} />
            </Grid>
            <Grid direction="column" container item style={{ marginTop: "8px" }}>
              <Grid item xs={6} sx={{ marginBottom: "6px", marginTop: "10px" }}>
                <Typography variant="p" noWrap>
                  <Skeleton variant="rectangular" height={10} width={"80%"} />
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <Typography variant="span" noWrap>
                  <Skeleton variant="rectangular" height={10} width={"30%"} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
