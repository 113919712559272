import React, { Dispatch, Fragment, SetStateAction, useMemo, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton } from "@mui/material"
import {
  Button,
  InputChangeEvent,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FieldArray, useFormikContext } from "formik"
import { v4 as uuidv4 } from "uuid"

import { queryClient } from "../../.."
import { BaseSimpleDialog } from "../../../components/dialogs/BaseSimpleDialog"
import { Rule } from "../../../components/dialogs/DecisionNodeDialog"
import { getTheme } from "../../../hooks/UseTheme"
import { useDebounce } from "../../../hooks/useDebounce"
import { KonanAPI } from "../../../services/KonanAPI"
import { Operators } from "../../../types/custom/projects"
import { Label } from "../../../types/generated/api/Label"
import { PaginatedLabelList } from "../../../types/generated/api/PaginatedLabelList"
import { WorkflowSchemaFeature } from "../../../types/generated/api/WorkflowSchemaFeature"
import { RuleCardFormikValues, RuleGroupConditions } from "../interface"
import { Condition } from "./Condition"

import styles from "./RuleCard.module.scss"

interface DataBlockProps {
  value: string | React.ReactElement
  isLoading?: boolean
  textVariant?: "h3-bold" | "a" | "p"
  textColor?: string
  variantColor?: 1 | 2
  bgVariant?: "neutral" | "gray"
}

/**
 * Helper UI component to render data items in ruleset card
 * @param {string} value
 * @return {React.ReactElement}
 */
export function DataBlock(props: Readonly<DataBlockProps>): React.ReactElement {
  const {
    value,
    isLoading,
    textVariant = "h3-bold",
    bgVariant = "gray",
    variantColor = 1,
    textColor = "neutral",
  } = props

  return (
    <div className={bgVariant === "gray" ? styles.cardFieldLight : styles.cardFieldDark}>
      {typeof value === "string" ? (
        <Typography variant={textVariant} color={textColor} variantColor={variantColor}>
          {isLoading ? <Skeleton variant="rectangular" width={"100%"} height={20} /> : value}
        </Typography>
      ) : (
        value
      )}
    </div>
  )
}

type Props = {
  editMode?: boolean
  createMode?: boolean
  index: number
  group1Switch: string
  group2Switch: string
  setGroup1Switch: Dispatch<SetStateAction<string>>
  setGroup2Switch: Dispatch<SetStateAction<string>>
  andOr: ["and", "or"]
  handleDelete?: (name: string, stateFn: Dispatch<SetStateAction<boolean>>) => void
  handleRemoveRule?: (index: number) => boolean
  duplicateMode?: boolean
}

/**
 * Rule card component
 * @return {React.ReactElement}
 */
export function RuleCard(props: Readonly<Props>): React.ReactElement {
  const {
    editMode = false,
    createMode = false,
    handleDelete,
    handleRemoveRule,
    group1Switch,
    group2Switch,
    setGroup1Switch,
    setGroup2Switch,
    index,
    andOr,
    duplicateMode = false,
  } = props
  const { id: projectId } = useParams<{ id: string }>()

  const { values, setFieldValue, handleChange, handleBlur, isSubmitting, errors, touched } =
    useFormikContext<RuleCardFormikValues>()

  const theme = getTheme()

  const [openRuleDeletionDialog, setOpenRuleDeletionDialog] = useState<boolean>(false)
  const uniqueId = uuidv4()

  const emptyCondition: Rule = {
    id: uniqueId,
    feature: "",
    andOr: "and",
    operator: Operators["="],
    valueOrFeature: "Value",
    type: "string",
    value: "",
    secondValue: "",
    schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
  }

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null)
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null)
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null)
  const openMenu1 = Boolean(anchorEl1)
  const openMenu2 = Boolean(anchorEl2)
  const openMenu3 = Boolean(anchorEl3)

  const openRuleCardMenu = Boolean(anchor)

  const pageSize = 20
  const [page, setPage] = useState<number>(0)
  const [searchKey, setSearchKey] = useState<string | null>(null)

  const [labels, setLabels] = useState<Label[]>([])

  const CreateLabelMutation = useMutation<
    AxiosResponse<Label>,
    AxiosError,
    {
      project_uuid: string
      name: string
    }
  >(KonanAPI.CreateLabel, {
    onSuccess: async ({ data }) => {
      NotificationUtils.toast("Label created successfully", { snackBarVariant: "positive" })

      setFieldValue(`cards[${index}].label`, data.name)
      setFieldValue(`cards[${index}].label_id`, data.uuid)

      await queryClient.invalidateQueries("label-list")
    },
    onError: async () => {
      NotificationUtils.toast("Label creation failed", { snackBarVariant: "negative" })
    },
  })

  // fetch uploaded training data headers (columns)
  const { data: labelList, isLoading: isLabelListLoading } = useQuery<AxiosResponse<PaginatedLabelList>, AxiosError>(
    ["label-list", projectId, page, pageSize, searchKey],
    () =>
      KonanAPI.RetrieveLabels({
        project_uuid: projectId as string,
        page: page + 1,
        pageSize: pageSize,
        search: searchKey,
      }),
    {
      onSuccess: (response) => {
        // filtering out already existing labels in the list
        const tempLabels =
          response.data.results?.filter((item: Label) => !labels.find((label: Label) => label.uuid === item.uuid)) ?? []

        setLabels([...labels, ...tempLabels])

        response.data.results?.length === 1 &&
          !["", null, undefined].includes(searchKey) &&
          // setting name (automatically) ONLY if the user writes the full name correctly
          response.data.results[0].name === searchKey &&
          setFieldValue(`cards[${index}].label_id`, response.data.results[0].uuid)
      },
      enabled: editMode || createMode,
      refetchOnMount: true,
    },
  )

  const optionsWithValues = useMemo(() => {
    return labels.map((label) => {
      return { label: label.name, value: label.uuid }
    })
  }, [labels])

  const handleRemoveCondition = (
    levelRemove: (idx: number) => void,
    conditionIdx: number,
    conditionLevel: "levelThreeConditions" | "levelTwoConditions",
  ): void => {
    // current level the condition being removed from
    const currentLevelLength = values?.cards[index]?.[conditionLevel]?.length

    // the migrated level/group -> ex: if removing condition from levelTwo then the migrated level is levelOneConditions
    const levelToBeMigratedTo = conditionLevel === "levelThreeConditions" ? "levelTwoConditions" : "levelOneConditions"

    // the condition index that will be migarted to the prev level
    const conditionIndexToBeMigrated = conditionIdx === 0 ? 1 : 0

    if (currentLevelLength <= 2) {
      // cache the migrated condition in a temp variable
      const tempCondition = values?.cards[index]?.[conditionLevel][conditionIndexToBeMigrated]

      // remove the whole level
      setFieldValue(`cards[${index}].${conditionLevel}`, [])

      // push the migrated condition to the prev/migrated level
      setFieldValue(`cards[${index}].${levelToBeMigratedTo}`, [
        ...values?.cards[index]?.[levelToBeMigratedTo],
        tempCondition,
      ])

      // edge case, removing levelTwoConditions while levelThreeConditions still has data
      if (conditionLevel === "levelTwoConditions" && values?.cards[index]?.levelThreeConditions?.length > 0) {
        // assign levelTwoConditions data to be equal to levelThreeConditions
        setFieldValue(`cards[${index}].levelTwoConditions`, [...values?.cards[index]?.levelThreeConditions])

        // and remove levelThreeConditions data
        setFieldValue(`cards[${index}].levelThreeConditions`, [])
      }
    } else {
      levelRemove(conditionIdx)
    }
  }

  const debouncedOnChange = useDebounce((): void => {
    const label = values.cards[index].label

    setPage(0)
    setSearchKey(label === "" ? null : label)
  }, 400)

  return (
    <Card className={styles.card}>
      {openRuleDeletionDialog && (
        <BaseSimpleDialog
          open={openRuleDeletionDialog}
          name={values.cards[index]?.name}
          onClose={() => setOpenRuleDeletionDialog(false)}
          onAccept={() => {
            if (handleDelete) {
              handleDelete(values?.cards[index]?.name, setOpenRuleDeletionDialog)
            }
          }}
          mode={"rule-deletion"}
          isLoading={isSubmitting}
        />
      )}

      <CardHeader
        className={styles.cardHeader}
        title={
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={6}>
              {editMode || createMode ? (
                <InputText
                  id={`cards[${index}].name`}
                  hideDescription
                  placeholder="Name"
                  value={values.cards[index]?.name}
                  handleChange={handleChange}
                  error={
                    touched?.cards &&
                    errors?.cards &&
                    touched?.cards[index]?.name &&
                    Boolean((errors?.cards[index] as RuleGroupConditions)?.name) &&
                    (errors?.cards[index] as RuleGroupConditions)?.name
                  }
                  handleBlur={handleBlur}
                  disabled={isSubmitting}
                  description="Manually configured condition that returns a decision when matched."
                  fullWidth
                />
              ) : (
                <Typography variant="h3-bold" style={{ textTransform: "inherit" }}>
                  {values.cards[index]?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
        }
        action={
          handleDelete &&
          handleRemoveRule && (
            <Fragment>
              <IconButton
                aria-label="settings"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
              >
                <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
              </IconButton>
              <Menu
                key="basic-menu"
                anchorEl={anchor}
                open={openRuleCardMenu}
                onClose={() => setAnchor(null)}
                menuMaxContent
              >
                <Tooltip width={"100%"} title={1 === values?.cards?.length && "Ruleset should have at least one rule"}>
                  <MenuItem
                    onClick={() => {
                      const removeIndicator = handleRemoveRule?.(index)
                      if (removeIndicator) {
                        setOpenRuleDeletionDialog(true)
                      }
                      setAnchor(null)
                    }}
                    disabled={values?.cards?.length === 1 || isSubmitting}
                  >
                    <Typography variant="a" color="negative" variantColor={2}>
                      Remove Rule
                    </Typography>
                  </MenuItem>
                </Tooltip>
              </Menu>
            </Fragment>
          )
        }
      />

      <CardContent className={styles.cardContent}>
        <FieldArray name={`cards[${index}].levelOneConditions`}>
          {/* Level 1 group */}
          {({ push: level1push, remove: level1remove }) => (
            <Grid container justifyContent="flex-end" alignItems="center" className={styles.groupBackground}>
              <Grid container item spacing={1}>
                {values?.cards[index]?.levelOneConditions?.map((item: Rule, idx: number) => (
                  <Grid item xs={12}>
                    <Condition
                      key={item?.id}
                      conditionLevel={"levelOneConditions"}
                      id={`cards[${index}].levelOneConditions[${idx}]`}
                      index={idx}
                      editMode={editMode}
                      createMode={createMode}
                      duplicateMode={duplicateMode}
                      ruleIndex={index}
                      removeCondition={() => level1remove(idx)}
                      groupSwitch={group1Switch}
                      lastSwitchSetter={setGroup1Switch}
                    />
                  </Grid>
                ))}

                {/* and/or */}
                {values.cards[index]?.levelTwoConditions && values.cards[index]?.levelTwoConditions.length > 0 && (
                  <Grid item xs={3} md={2} lg={1}>
                    {editMode && values.cards[index]?.levelOneConditions.length === 1 ? (
                      <Select
                        type="text"
                        value={group1Switch}
                        handleChange={(e) => setGroup1Switch(e.target.value as string)}
                        fullWidth
                        disabled={values.cards[index]?.levelOneConditions.length > 1}
                        options={andOr}
                        hideDescription
                      />
                    ) : (
                      <DataBlock
                        value={
                          editMode
                            ? group1Switch
                            : values.cards[index]?.levelOneConditions &&
                                values.cards[index]?.levelOneConditions.length > 0
                              ? values.cards[index]?.levelOneConditions[0].andOr
                              : ""
                        }
                      />
                    )}
                  </Grid>
                )}

                {/* Level 2 group */}
                {values.cards[index]?.levelTwoConditions && values.cards[index]?.levelTwoConditions.length > 0 && (
                  <Grid item xs>
                    <FieldArray name={`cards[${index}].levelTwoConditions`}>
                      {({ push: level2push, remove: level2remove }) => (
                        <Grid
                          container
                          item
                          justifyContent="flex-end"
                          alignItems="flex-start"
                          className={styles.groupBackground}
                        >
                          <Grid container item spacing={1}>
                            {values.cards[index]?.levelTwoConditions?.map((item: Rule, idx: number) => (
                              <Grid item xs={12} key={item?.id}>
                                <Condition
                                  key={item?.id}
                                  ruleIndex={index}
                                  conditionLevel={"levelTwoConditions"}
                                  id={`cards[${index}].levelTwoConditions[${idx}]`}
                                  index={idx}
                                  editMode={editMode}
                                  createMode={createMode}
                                  duplicateMode={duplicateMode}
                                  removeCondition={() => handleRemoveCondition(level2remove, idx, "levelTwoConditions")}
                                  groupSwitch={group2Switch}
                                  lastSwitchSetter={setGroup2Switch}
                                />
                              </Grid>
                            ))}

                            {/* and/or */}
                            {values.cards[index]?.levelThreeConditions &&
                              values.cards[index]?.levelThreeConditions.length > 0 && (
                                <Grid item xs={3} md={2} lg={1}>
                                  {editMode && values.cards[index]?.levelTwoConditions.length === 1 ? (
                                    <Select
                                      type="text"
                                      value={group2Switch}
                                      handleChange={(e) => setGroup2Switch(e.target.value as string)}
                                      fullWidth
                                      disabled={values.cards[index]?.levelTwoConditions.length > 1}
                                      options={andOr}
                                      hideDescription
                                    />
                                  ) : (
                                    <DataBlock
                                      value={
                                        editMode
                                          ? group2Switch
                                          : values.cards[index]?.levelTwoConditions &&
                                              values.cards[index]?.levelTwoConditions.length > 0
                                            ? values.cards[index]?.levelTwoConditions[0].andOr
                                            : ""
                                      }
                                    />
                                  )}
                                </Grid>
                              )}

                            {/* Level 3 group */}
                            {values.cards[index]?.levelThreeConditions &&
                              values.cards[index]?.levelThreeConditions.length > 0 && (
                                <Grid item xs>
                                  <FieldArray name={`cards[${index}].levelThreeConditions`}>
                                    {({ push: level3push, remove: level3remove }) => (
                                      <Grid container item className={styles.groupBackground}>
                                        <Grid container item justifyContent="flex-end" alignItems="center" spacing={1}>
                                          {values.cards[index]?.levelThreeConditions?.map((item: Rule, idx: number) => (
                                            <Grid item xs={12} key={item?.id}>
                                              <Condition
                                                key={item?.id}
                                                ruleIndex={index}
                                                conditionLevel={"levelThreeConditions"}
                                                id={`cards[${index}].levelThreeConditions[${idx}]`}
                                                index={idx}
                                                editMode={editMode}
                                                createMode={createMode}
                                                duplicateMode={duplicateMode}
                                                removeCondition={() => {
                                                  handleRemoveCondition(level3remove, idx, "levelThreeConditions")
                                                }}
                                              />
                                            </Grid>
                                          ))}
                                        </Grid>

                                        {/* level 3 menu button  */}
                                        {(editMode || createMode) &&
                                          values.cards[index]?.levelThreeConditions &&
                                          values.cards[index]?.levelThreeConditions.length > 0 && (
                                            <Grid container>
                                              <Grid item lg={12} mt={1}>
                                                <Button
                                                  size="regular"
                                                  onClick={(event) => setAnchorEl3(event.currentTarget)}
                                                  disabled={isSubmitting}
                                                >
                                                  + Add Rule
                                                </Button>
                                                <Menu
                                                  key="basic-menu3"
                                                  anchorEl={anchorEl3}
                                                  open={openMenu3}
                                                  onClose={() => setAnchorEl3(null)}
                                                  menuMaxContent
                                                >
                                                  <MenuItem
                                                    onClick={() => {
                                                      level3push(emptyCondition)
                                                      setAnchorEl3(null)
                                                    }}
                                                  >
                                                    <Grid container display={"flex"} gap={1} height={"20px"}>
                                                      <Grid item>
                                                        <AddOutlinedIcon
                                                          fontSize="small"
                                                          htmlColor={theme.palette.grayscale.text[1]}
                                                        />
                                                      </Grid>
                                                      <Grid item>
                                                        <Typography variant="a">Add Rule</Typography>
                                                      </Grid>
                                                    </Grid>
                                                  </MenuItem>
                                                </Menu>
                                              </Grid>
                                            </Grid>
                                          )}
                                      </Grid>
                                    )}
                                  </FieldArray>
                                </Grid>
                              )}

                            {/* level 2 menu button  */}
                            {(editMode || createMode) &&
                              values.cards[index]?.levelTwoConditions &&
                              values.cards[index]?.levelTwoConditions.length > 0 && (
                                <Grid container>
                                  <Grid item lg={12} mt={1} ml={1}>
                                    <Button
                                      size="regular"
                                      onClick={(event) => setAnchorEl2(event.currentTarget)}
                                      disabled={isSubmitting}
                                    >
                                      + Add Rule
                                    </Button>
                                    <Menu
                                      key="basic-menu2"
                                      anchorEl={anchorEl2}
                                      open={openMenu2}
                                      onClose={() => setAnchorEl2(null)}
                                      menuMaxContent
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          level2push({ ...emptyCondition, andOr: group2Switch })
                                          setAnchorEl2(null)
                                        }}
                                      >
                                        <Grid container display={"flex"} gap={1} height={"20px"}>
                                          <Grid item>
                                            <AddOutlinedIcon
                                              fontSize="small"
                                              htmlColor={theme.palette.grayscale.text[1]}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="a">Add Rule</Typography>
                                          </Grid>
                                        </Grid>
                                      </MenuItem>
                                      {values.cards[index]?.levelThreeConditions &&
                                        values.cards[index]?.levelThreeConditions.length === 0 && (
                                          <MenuItem
                                            onClick={() => {
                                              values.cards[index]?.levelThreeConditions.push(
                                                emptyCondition as never,
                                                emptyCondition as never,
                                              )
                                              setAnchorEl2(null)
                                            }}
                                          >
                                            <Grid container display={"flex"} gap={1} height={"20px"}>
                                              <Grid item>
                                                <AddBoxOutlinedIcon
                                                  fontSize="small"
                                                  htmlColor={theme.palette.grayscale.text[1]}
                                                />
                                              </Grid>
                                              <Grid item>
                                                <Typography variant="a">Add Group</Typography>
                                              </Grid>
                                            </Grid>
                                          </MenuItem>
                                        )}
                                    </Menu>
                                  </Grid>
                                </Grid>
                              )}
                          </Grid>
                        </Grid>
                      )}
                    </FieldArray>
                  </Grid>
                )}

                {/* level 1 menu button  */}
                {(editMode || createMode) && (
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item lg={12} mt={1} ml={1}>
                      <Button
                        size="regular"
                        onClick={(event) => setAnchorEl1(event.currentTarget)}
                        disabled={isSubmitting}
                      >
                        + Add Rule
                      </Button>
                      <Menu
                        key="basic-menu1"
                        anchorEl={anchorEl1}
                        open={openMenu1}
                        onClose={() => setAnchorEl1(null)}
                        menuMaxContent
                      >
                        <MenuItem
                          onClick={() => {
                            level1push({ ...emptyCondition, andOr: group1Switch })
                            setAnchorEl1(null)
                          }}
                        >
                          <Grid container display={"flex"} gap={1} height={"20px"}>
                            <Grid item>
                              <AddOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                            </Grid>
                            <Grid item>
                              <Typography variant="a">Add Rule</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                        {values.cards[index]?.levelTwoConditions &&
                          values.cards[index]?.levelTwoConditions.length === 0 && (
                            <MenuItem
                              onClick={() => {
                                values.cards[index]?.levelTwoConditions.push(
                                  emptyCondition as never,
                                  emptyCondition as never,
                                )
                                setAnchorEl1(null)
                              }}
                            >
                              <Grid container display={"flex"} gap={1} height={"20px"}>
                                <Grid item>
                                  <AddBoxOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                                </Grid>
                                <Grid item>
                                  <Typography variant="a">Add Group</Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          )}
                      </Menu>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </FieldArray>

        <Grid container justifyContent="flex-start" alignItems="center" spacing={2} mt={2}>
          {/* Return */}
          <Grid item xs={3} lg={1} display="flex" justifyContent="flex-end">
            <Typography variant="p">return</Typography>
          </Grid>

          {/* Label */}
          <Grid item xs={9} lg={11}>
            {editMode || createMode ? (
              <InputText
                hideDescription
                id={`cards[${index}].label`}
                placeholder="Label"
                value={values.cards[index]?.label}
                handleChange={(e: InputChangeEvent) => {
                  if (e._reactName === "onClick") {
                    setFieldValue(`cards[${index}].label_id`, e.target.value)
                    setFieldValue(
                      `cards[${index}].label`,
                      optionsWithValues.filter((option) => e.target.value === option.value)[0].label ?? "",
                    )
                  } else {
                    handleChange(e)
                    setFieldValue(`cards[${index}].label_id`, "")
                  }
                  debouncedOnChange()
                }}
                error={
                  touched?.cards &&
                  errors?.cards &&
                  touched?.cards[index]?.label &&
                  Boolean((errors?.cards[index] as RuleGroupConditions)?.label_id) &&
                  (errors?.cards[index] as RuleGroupConditions)?.label_id
                }
                optionsWithValues={
                  optionsWithValues.length === 0
                    ? [{ label: "No entries found!", value: "default", disabled: true }]
                    : optionsWithValues
                }
                handleBlur={handleBlur}
                disabled={isSubmitting}
                noEntriesProps={{
                  handleAddNewValue: async () => {
                    await CreateLabelMutation.mutateAsync({
                      project_uuid: projectId as string,
                      name: values.cards[index].label,
                    }).then((res) => {
                      setFieldValue(`cards[${index}].label_id`, res.data.uuid)
                    })
                  },
                }}
                loaderComponent={
                  <Grid container item xs={12} px={2} py={0.5}>
                    <CircularProgress size={14} />
                  </Grid>
                }
                isLoadingOptions={isLabelListLoading}
                menuProps={{
                  handleScrollToEnd: () => {
                    labelList?.data.next && setPage(page + 1)
                  },
                  scrollThreshold: 10,
                }}
                fullWidth
                description="Labels are case sensitive and must be exactly the same as the model's output."
              />
            ) : (
              <DataBlock value={values.cards[index].label ?? values.cards[index]?.label} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
