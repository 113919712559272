import React, { Fragment } from "react"

import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined"
import { Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { LabelAuditCard } from "../../../components/cards/SimulationAuditCards/LabelAuditCard"

type Props = {
  strategy: "Combined" | "Parallel"
}

/**
 * WorkflowExample component renders a visual representation of a workflow
 * consisting of LabelAuditCard components and arrows indicating the flow.
 *
 * @param {"Combined" | "Parallel"} strategy - Determines the strategy used for the workflow, either "Combined" or "Parallel".
 *
 * @returns {React.ReactElement} The rendered WorkflowExample component.
 */
export function WorkflowExample(props: Props): React.ReactElement {
  const { strategy } = props

  return (
    <Grid container className="grid-container">
      <LabelAuditCard
        nodeType="loop-start"
        nodeBody={
          <Fragment>
            <Typography variant="p" gutterBottom style={{ marginTop: "4px" }}>
              feature_1 = [<span style={{ color: "var(--pink-text-2)" }}>1</span>, 2 ,3]
            </Typography>
            <Typography variant="p">
              feature_2 = [
              {strategy === "Parallel" ? (
                <Fragment>
                  <span style={{ color: "var(--pink-text-2)" }}>50</span>, 60, 70
                </Fragment>
              ) : (
                <span style={{ color: "var(--pink-text-2)" }}>50, 60, 70</span>
              )}
              ]
            </Typography>
          </Fragment>
        }
      />

      <Grid item display="flex" mt={"-2px"} ml={5} border="none" style={{ background: "transparent" }}>
        <ArrowDownwardOutlinedIcon sx={{ color: "var(--neutral-border-disabled)", zIndex: 100 }} />
      </Grid>
      <LabelAuditCard
        nodeType="calculator"
        nodeBody={
          <Fragment>
            <Typography variant="p" gutterBottom style={{ marginTop: "4px" }}>
              feature_1 + feature_2
            </Typography>

            <Typography variant="p" style={{ marginTop: "4px" }}>
              output = [
              {strategy === "Parallel" ? (
                <Fragment>
                  <span style={{ color: "var(--pink-text-2)" }}>51</span>
                </Fragment>
              ) : (
                <span style={{ color: "var(--pink-text-2)" }}>51, 61, 71</span>
              )}
              ]
            </Typography>
          </Fragment>
        }
      />
      <Grid item display="flex" mt={"-2px"} ml={5} border="none" style={{ background: "transparent" }}>
        <ArrowDownwardOutlinedIcon fontSize="medium" sx={{ color: "var(--neutral-border-disabled)", zIndex: 100 }} />
      </Grid>

      <LabelAuditCard nodeType="loop-end" />
    </Grid>
  )
}
