import React, { Fragment, useContext, useEffect, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import { AddOutlined } from "@mui/icons-material"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Skeleton,
  SvgIcon,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import {
  Button,
  InputChangeEvent,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  RadioButton,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import { useFormik } from "formik"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { KonanAvatar } from "../../components/Avatar"
import { VersionTag } from "../../components/VersionTag"
import { DecisionLoadingCard } from "../../components/cards/DecisionLoadingCard"
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { OptionsWithExplanationDialog } from "../../components/dialogs/OptionsWithExplanationDialog"
import { VersioningDialog } from "../../components/dialogs/VersioningDialog"
import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { CreateScorecardSetRequest, UpdateScorecardsetRequest } from "../../types/custom/projects"
import { VersionChangeRequest } from "../../types/custom/rules"
import { ScoreCardList } from "../../types/generated/api/ScoreCardList"
import { ScoreCardSetCreate } from "../../types/generated/api/ScoreCardSetCreate"
import { ScoreCardSetGroupRetrieve } from "../../types/generated/api/ScoreCardSetGroupRetrieve"
import { ScoreCardSetList } from "../../types/generated/api/ScoreCardSetList"
import { ScoreCardSetRetrieve } from "../../types/generated/api/ScoreCardSetRetrieve"
import { Auth } from "../../utils/auth"
import { extractScorecardErrorsMessages, getValidMaxMinorVersion } from "../../utils/deploymentDetailsHelpers"
import { searchListByName } from "../../utils/searchSortFilterHelpers"
import { DataBlock } from "../Ruleset/components/RuleCard"
import { ScorecardExample } from "../Scorecards/ScorecardExample"
import { ScoreCardBlock } from "./ScoreCardBlock"

import styles from "./ScorecardSet.module.scss"

type Props = {
  isCreate?: boolean
  isLoading: boolean
  allScorecards: ScoreCardList[]
  goToFirstPage: () => void
  handleCancel?: () => void
  scorecardset?: ScoreCardSetList
  disableDuplicateButton?: boolean
  duplicateScoreSet?: (scoreCardSetList: ScoreCardSetList) => void
  isDuplicate?: boolean
}

// Yup validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("Scorecardset name is required"),
  scorecards: Yup.array().of(
    Yup.object({
      name: Yup.string().required("Scorecard name is required"),
      uuid: Yup.string().required("Scorecard name is required"),
      weight: Yup.number().typeError("Numerical value is required"),
    }),
  ),
})

export function ScorecardLoadingComponent(): React.ReactElement {
  return (
    <Grid item md={12} container style={{ width: "100%" }}>
      <Card className={styles.loaderScorecard} style={{ width: "100%" }}>
        <CardHeader className={styles.loaderHeader} title={<Skeleton width={200} />} />
        <CardContent className={styles.loaderContent}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </CardContent>
      </Card>
    </Grid>
  )
}

/**
 * single scorecardset component
 * @param {boolean} isCreate current mode for scorecardset
 * @param {boolean} isLoading indicator for scorecardset data loading or not
 * @param {ScoreCardSetList} scorecardset retrieved scorecardset from backend
 * @param {ScoreCardList} allScorecards list contains all scorecardset from backend
 * @param {function} handleCancel handler for opting out of scorecardset creation
 * @param {function} goToFirstPage set current page to 0 after creating scorecardset
 * @returns {React.ReactElement}
 */
export function Scorecardset({
  isCreate = false,
  scorecardset,
  handleCancel,
  isLoading,
  goToFirstPage,
  allScorecards,
  disableDuplicateButton,
  duplicateScoreSet,
  isDuplicate = false,
}: Readonly<Props>): React.ReactElement {
  const { id: projectId } = useParams<{ id: string }>()

  // getting the current user
  const userEmail = Auth.getEmail()
  const MuiTheme = useMuiTheme()
  const theme = getTheme()

  // indicator for updateMode
  const { currentProject } = useContext(CurrentProjectAndModelContext)
  const queryClient = useQueryClient()

  const fullScreen = useMediaQuery(MuiTheme.breakpoints.down("md"))

  const [isCreateMode, setIsCreateMode] = useState<boolean>(isCreate)
  const isUpdateMode = scorecardset && isCreateMode && !isDuplicate

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openNewCardMenu = Boolean(anchorEl)

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  const [isExplanationDialogOpen, setIsExplanationDialogOpen] = useState<boolean>(false)

  const [anchor2, setAnchor2] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchor2)

  // state to handle scorecards list after using search param
  const [adjustedAllScorecards, setAdjustedAllScorecards] = useState<ScoreCardList[]>(allScorecards ?? [])

  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)

  const [calculationMethod, setCalculationMethod] = useState<
    | ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT
    | ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT
  >(scorecardset?.calculation_method ?? ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT)

  const { isLoading: isScorecardsetLoading, data: scorecardsetData } = useQuery<
    AxiosResponse<ScoreCardSetGroupRetrieve>,
    AxiosError
  >(
    ["scorecardset", scorecardset?.uuid],
    () => KonanAPI.fetchScorecardset(currentProject?.uuid, scorecardset?.uuid as string),
    {
      onSuccess: async ({ data }) => {
        const activeVersion = data?.versions?.find(
          (version: ScoreCardSetRetrieve) => version?.is_active_version === true,
        )

        await formik.setValues({
          uuid: data?.uuid,
          name: scorecardset?.name ?? data?.name,
          scorecards: activeVersion?.scorecards?.map((scorecard) => ({
            name: scorecard?.name,
            weight: scorecard?.weight,
            uuid: scorecard?.uuid,
          })),
        })
      },
      enabled: !!scorecardset?.uuid,
    },
  )

  const createScorecardSetsMutation = useMutation<
    AxiosResponse,
    AxiosError<CreateScorecardSetRequest>,
    CreateScorecardSetRequest
  >(KonanAPI.createScorecardSet, {
    mutationKey: "createScorecardset",
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
      await queryClient.invalidateQueries(["scorecardset", response?.data?.uuid])
      // closing the scorecardset view upon creation
      handleCancel?.()
      NotificationUtils.toast("Scorecardset successfully created!", {
        snackBarVariant: "positive",
      })
      setIsCreateMode(false)
      // Redirect to first page after new scorecardset gets created!
      goToFirstPage?.()
    },
    onError: async (response: AxiosError<CreateScorecardSetRequest>) => {
      const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "create")
      NotificationUtils.toast(errorMessage, {
        snackBarVariant: "negative",
      })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateScorecardsetMutation = useMutation<AxiosResponse, AxiosError<any>, UpdateScorecardsetRequest>(
    KonanAPI.updateScorecardset,
    {
      mutationKey: "updateScorecardset",
      onSuccess: async (response) => {
        await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
        await queryClient.invalidateQueries(["scorecardset", response?.data?.uuid])

        NotificationUtils.toast("Scorecardset successfully updated!", {
          snackBarVariant: "positive",
        })
        setIsCreateMode(false)
      },
      onError: async (response: AxiosError<UpdateScorecardsetRequest>) => {
        const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "update")
        NotificationUtils.toast(errorMessage, {
          snackBarVariant: "negative",
        })
      },
    },
  )

  // change scorecardset version
  const scorecardsetChangeMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
        await queryClient.invalidateQueries(["scorecardset", scorecardset?.uuid])
      },
    },
  )

  // scorecardset deletion Mutation
  const deleteScoreCardSetMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    { project_uuid: string; resource_uuid: string }
  >((props) => KonanAPI.deleteWorkflowResource({ ...props, resource_type: "scorecardsets" }), {
    onSuccess: async () => {
      NotificationUtils.toast("Scorecard deleted successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
      await queryClient.invalidateQueries(["scorecardset", scorecardset?.uuid])

      setIsDeletionDialogOpen(false)
    },
    onError: ({ response }) => {
      NotificationUtils.toast(response?.data?.details ?? "Scorecard deletion failed", { snackBarVariant: "negative" })
    },
  })

  // Memoize active version to render it in the card
  const activeScorecardsetVersion = useMemo(() => {
    if (scorecardsetData?.data && scorecardsetData?.data?.versions) {
      for (const version of scorecardsetData?.data?.versions ?? []) {
        if (version.is_active_version) {
          return version
        }
      }
    }
  }, [scorecardsetData])

  // form control
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      scorecards: [
        {
          name: "",
          weight: undefined,
          uuid: undefined,
        },
      ],
    },
    onSubmit: async ({ scorecards, name }) => {
      const trimmedName = name?.trim()

      // TODO:: refactor all these checks
      // Check if every Scorecard has a weight
      const allScorecardsHaveWeight = scorecards.every((scorecard) => !!scorecard.weight && scorecard.weight !== "")

      // check if some scorecards have weights
      const someScorecardsHaveWeight = scorecards.some((scorecard) => !!scorecard.weight && scorecard.weight !== "")

      // check if some scorecards have invalid weights
      const someScorecardsHaveInvalidWeight = scorecards.some(
        (scorecard) =>
          !!scorecard.weight && scorecard?.weight > Number(window.__RUNTIME_CONFIG__.SCORECARDSET_MAX_WEIGHT),
      )

      // Calculate the sum of all weights
      const totalWeight = scorecards.reduce((sum, scorecard) => sum + (parseFloat(scorecard?.weight ?? "0") || 0), 0)

      if (someScorecardsHaveWeight && !allScorecardsHaveWeight) {
        NotificationUtils.toast(
          <Fragment>
            Weight: Providing partial weights is not allowed. <br /> Either provide a weight value for all scorecards or
            none of them!
          </Fragment>,
          {
            snackBarVariant: "negative",
          },
        )
        return
      }

      if (someScorecardsHaveInvalidWeight) {
        NotificationUtils.toast(
          "Weight: Please ensure that there are no more than 3 digits before the decimal point!",
          {
            snackBarVariant: "negative",
          },
        )
        return
      }

      // Check if the sum of all weights is less than 1
      if (
        (allScorecardsHaveWeight &&
          Math.round(totalWeight) < Number(window.__RUNTIME_CONFIG__.SCORECARDSET_MIN_WEIGHT)) ||
        Math.round(totalWeight) > Number(window.__RUNTIME_CONFIG__.SCORECARDSET_MAX_WEIGHT)
      ) {
        // Display a message
        NotificationUtils.toast(
          `Sum of all weights must be between ${Number(window.__RUNTIME_CONFIG__.SCORECARDSET_MIN_WEIGHT)} and ${Number(window.__RUNTIME_CONFIG__.SCORECARDSET_MAX_WEIGHT)}!`,
          {
            snackBarVariant: "negative",
          },
        )
        return
      }

      // Create a new array of objects representing the Scorecards with their UUIDs and weights
      const scorecardsWithUuidsAndWeights = scorecards.map((scorecard) => {
        const uuid = scorecard.uuid ?? ""

        const scorecardSetObject: { uuid: string; weight?: string } = { uuid }

        // Return an object representing the current Scorecard with its UUID and weight
        if (scorecard.weight) {
          scorecardSetObject["weight"] = scorecard.weight
          return scorecardSetObject
        }
        return scorecardSetObject
      })

      if (isUpdateMode) {
        await updateScorecardsetMutation.mutateAsync({
          projectUUID: currentProject?.uuid,
          scorecardsetUUID: scorecardset?.uuid ?? "",
          name: trimmedName === scorecardset?.name ? undefined : trimmedName,
          calculation_method: calculationMethod,
          scorecards: [...scorecardsWithUuidsAndWeights],
        })
      } else {
        await createScorecardSetsMutation.mutateAsync({
          projectUUID: currentProject?.uuid,
          calculation_method: calculationMethod,
          name: trimmedName,
          scorecards: [...scorecardsWithUuidsAndWeights],
        })
      }
    },
  })

  const handleAddScorecard = (): void => {
    formik.setFieldError("name", undefined)

    formik.setFieldTouched("name", false)

    formik.setFieldValue("scorecards", [
      ...formik.values.scorecards,
      {
        name: "",
        weight: undefined,
        uuid: undefined,
      },
    ])

    setAnchorEl(null)
  }

  const handleRemoveScorecard = (cardIdx: number): void => {
    // Update the scorecards array by removing the object representing the Scorecard at the given index
    formik.setFieldValue(
      "scorecards",
      formik.values.scorecards.filter((_, index) => index !== cardIdx),
    )
  }

  // onChange handler in textInput for searching inside menu options for scorecard names
  const onSearchValueChange = (value: string): ScoreCardList[] | void => {
    if (allScorecards && value) {
      const list = searchListByName(allScorecards, value)
      setAdjustedAllScorecards(list)
    } else if (!value && allScorecards) {
      setAdjustedAllScorecards(allScorecards)
    }
  }

  const handleCancelButton = (): void => {
    if (isUpdateMode) {
      setIsCreateMode(false)
      const activeVersion = scorecardsetData?.data?.versions?.find(
        (version: ScoreCardSetRetrieve) => version?.is_active_version === true,
      )

      formik.setValues({
        uuid: scorecardsetData?.data?.uuid,
        name: scorecardsetData?.data?.name,
        scorecards: activeVersion?.scorecards?.map((scorecard) => ({
          name: scorecard?.name,
          weight: scorecard?.weight,
          uuid: scorecard?.uuid,
        })),
      })

      if (scorecardset?.calculation_method) {
        setCalculationMethod(scorecardset?.calculation_method)
      }
    } else {
      handleCancel?.()
    }
  }

  const changeActiveScorecardsetVersion = async (version: string): Promise<void> => {
    try {
      await scorecardsetChangeMutation.mutateAsync({
        projectUUID: currentProject.uuid,
        resourceUUID: scorecardset?.uuid as string,
        version: version.toString(),
        resource: "scorecardsets",
      })

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
      // Invalidate react-query queries
      await queryClient.invalidateQueries(["scorecardsets", currentProject?.uuid])
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  const GetScorecardsetContainer = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

    const Version: ScoreCardSetRetrieve | undefined = useMemo((): ScoreCardSetRetrieve | undefined => {
      if (scorecardsetData && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(scorecardsetData.data.versions, selectedVersion)
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of scorecardsetData.data.versions?.sort(
          (a, b) => Number(a?.created_at) - Number(b?.created_at),
        )) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, scorecardsetData])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeScorecardsetVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeScorecardsetVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeScorecardsetVersion?.version

    useEffect(() => {
      if (allScorecards) {
        setAdjustedAllScorecards(allScorecards)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allScorecards])

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {scorecardsetData?.data?.name}
            </Typography>

            <VersionTag
              version={isNaN(Number(Version?.version)) ? undefined : (Number(Version?.version) ?? selectedVersion)}
              variant={
                Version ? (activeScorecardsetVersion?.version === selectedVersion ? "positive" : "default") : "positive"
              }
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => changeActiveScorecardsetVersion(selectedVersion as string)}
              disabled={scorecardsetChangeMutation.isLoading}
              isLoading={scorecardsetChangeMutation.isLoading}
            >
              Restore
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0}>
          {Version?.scorecards &&
            Version?.scorecards?.length &&
            Version?.scorecards
              ?.toSorted((a, b) => Number(a?.created_at) - Number(b?.created_at))
              ?.map((scorecard) => (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  display={"flex"}
                  justifyContent="flex-start"
                  alignItems="center"
                  key={scorecard.uuid}
                  pt={1.5}
                >
                  <Grid item xs={8} md={9.5}>
                    <ScoreCardBlock scorecard={{ name: scorecard.name, uuid: scorecard.uuid }} />
                  </Grid>

                  <Grid item xs={4} md={2.5} alignSelf={"center"}>
                    <DataBlock value={`${parseInt(scorecard.weight)}%`} />
                  </Grid>
                </Grid>
              ))}
        </Grid>
      </Grid>
    )
  }

  const shouldDisableEditButton = Boolean(
    createScorecardSetsMutation.isLoading ||
      isScorecardsetLoading ||
      formik.isSubmitting ||
      updateScorecardsetMutation.isLoading,
  )

  const shouldDisableDuplicateButton = Boolean(
    createScorecardSetsMutation.isLoading ||
      isScorecardsetLoading ||
      formik.isSubmitting ||
      updateScorecardsetMutation.isLoading ||
      disableDuplicateButton,
  )

  return (
    <Fragment>
      {/* Versioning Dialog*/}
      {isVersionDialogOpen && (
        <VersioningDialog
          isOpen={isVersionDialogOpen}
          onClose={() => setIsVersionDialogOpen(false)}
          versions={scorecardsetData?.data.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          activeVersion={activeScorecardsetVersion as ScoreCardSetRetrieve}
          height={fullScreen ? "100%" : "400px"}
          maxWidth="md"
        >
          <GetScorecardsetContainer />
        </VersioningDialog>
      )}

      {isExplanationDialogOpen && (
        <OptionsWithExplanationDialog
          setSelectedOption={setCalculationMethod}
          selectedOption={calculationMethod}
          dialogTitle="Scorecard Block Explanation"
          options={[
            {
              value: "NORMALIZED_VALUE_BY_WEIGHT",
              label: "Divide by max value",
              description: "Description for Divide by max value",
              component: <ScorecardExample isEquationNormalized />,
            },
            {
              value: "VALUE_BY_WEIGHT",
              label: "Multiply by card weight",
              description: "Description for Multiply by card weight",
              component: <ScorecardExample />,
            },
          ]}
          open
          onClose={() => setIsExplanationDialogOpen(false)}
          shouldEnableSelectingMethods={Boolean(isCreateMode || isUpdateMode)}
        />
      )}

      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          open={isDeletionDialogOpen}
          name={scorecardset?.name ?? ""}
          onClose={() => setIsDeletionDialogOpen(false)}
          onAccept={() => {
            deleteScoreCardSetMutation.mutateAsync({
              project_uuid: projectId as string,
              resource_uuid: scorecardset?.uuid as string,
            })
          }}
          mode={"scorecardset-deletion"}
          isLoading={deleteScoreCardSetMutation.isLoading}
        />
      )}

      {/* Scorecardset Loader */}
      {isLoading ? (
        <DecisionLoadingCard />
      ) : (
        <Card className="card-box-shadow">
          <CardHeader
            className={styles.scorecardSetHeader}
            title={
              <Grid container direction="column" item xs={12} spacing={1}>
                <Grid item container xs={12} justifyContent="space-between">
                  <Grid item container xs={5}>
                    {scorecardset?.uuid && !isCreateMode ? (
                      <Grid item mr={1} xs={"auto"} sm={"auto"}>
                        <Typography noWrap variant="h3-bold" className={styles.cardTitle}>
                          {scorecardset?.name}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <InputText
                          hideDescription
                          id="name"
                          placeholder="Name"
                          value={formik.values.name}
                          handleChange={formik.handleChange}
                          error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
                          handleBlur={formik.handleBlur}
                          disabled={
                            formik.isSubmitting || Boolean(updateScorecardsetMutation.isLoading && isUpdateMode)
                          }
                          fullWidth
                        />
                      </Grid>
                    )}

                    {!isCreateMode && !isUpdateMode && (activeScorecardsetVersion?.version || selectedVersion) && (
                      <Grid item mt={isCreateMode || isUpdateMode ? 0 : -1}>
                        <VersionTag
                          version={selectedVersion ?? activeScorecardsetVersion?.version}
                          variant={
                            selectedVersion
                              ? selectedVersion === activeScorecardsetVersion?.version
                                ? "positive"
                                : "default"
                              : "positive"
                          }
                        />
                      </Grid>
                    )}
                  </Grid>

                  {!isCreateMode && !isUpdateMode && (
                    <Grid container item xs justifyContent={"flex-end"}>
                      <IconButton
                        aria-label="settings"
                        size="small"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor2(event.currentTarget)}
                      >
                        <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
                      </IconButton>

                      <Menu
                        key="basic-menu"
                        anchorEl={anchor2}
                        open={isMenuOpen}
                        onClose={() => setAnchor2(null)}
                        menuMaxContent
                      >
                        <MenuItem
                          onClick={() => {
                            setIsDeletionDialogOpen(true)
                            setAnchor2(null)
                          }}
                        >
                          <Typography variant="a" color="negative" variantColor={2}>
                            Remove Scorecardset
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  )}
                </Grid>

                {/* Avatar section */}
                <Grid
                  item
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                  container
                  xs={12}
                  justifyContent="space-between"
                >
                  <Grid
                    sx={{ flexDirection: { xs: "column", md: "row" } }}
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                  >
                    <Grid container item spacing={1} md={4} alignItems="flex-start" flexWrap={"nowrap"}>
                      <Grid item sx={{ marginTop: "2px" }}>
                        {/* SvgIcon is used to fix square avatars on safari */}
                        <SvgIcon className={styles.modelAvatar}>
                          <KonanAvatar
                            size={24}
                            name={scorecardset?.created_by ? scorecardset?.created_by : (userEmail ?? "user")}
                          />
                        </SvgIcon>
                      </Grid>
                      <Grid container item xs={10}>
                        <Grid item mt={scorecardset?.created_at ? 0 : 0.8}>
                          <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                            {scorecardset?.created_by ?? userEmail}
                          </Typography>

                          {scorecardset?.created_at && (
                            <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                              <Tooltip
                                title={format(new Date(scorecardset?.created_at), "dd/MM/yyyy, p")}
                                placement="right"
                              >
                                <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                                  {moment(new Date(scorecardset?.created_at)).fromNow()}
                                </Typography>
                              </Tooltip>
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Action Buttons*/}
                    <Grid item container xs={12} sm={6} sx={{ justifyContent: { xs: "flex-start", md: "flex-end" } }}>
                      {isCreateMode ? (
                        <Grid item display="flex" gap={1}>
                          <Button
                            size="small"
                            disabled={Boolean(isScorecardsetLoading || updateScorecardsetMutation.isLoading)}
                            onClick={handleCancelButton}
                            variant={"secondary"}
                          >
                            Cancel
                          </Button>

                          <Button
                            variant="primary"
                            disabled={Boolean(isScorecardsetLoading || updateScorecardsetMutation.isLoading)}
                            size="small"
                            onClick={formik.submitForm}
                          >
                            {formik.isSubmitting ||
                            createScorecardSetsMutation.isLoading ||
                            updateScorecardsetMutation.isLoading ? (
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                display="flex"
                                className={styles.loadingContainer}
                              >
                                <Grid item margin={"auto"}>
                                  <CircularProgress style={{ margin: "auto", verticalAlign: "top" }} size={14} />
                                </Grid>
                              </Grid>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </Grid>
                      ) : (
                        <Fragment>
                          <Box mr={1} alignSelf="flex-start" mt={-0.3} mb={0.5}>
                            <Button
                              size="small"
                              variant="secondary"
                              disabled={shouldDisableDuplicateButton}
                              onClick={() => duplicateScoreSet?.(scorecardset as ScoreCardSetList)}
                            >
                              Duplicate
                            </Button>
                          </Box>
                          <Box mr={1} alignSelf="flex-start" mt={-0.3} mb={0.5}>
                            <Button
                              size="small"
                              variant="secondary"
                              disabled={Boolean(
                                createScorecardSetsMutation.isLoading ||
                                  isScorecardsetLoading ||
                                  formik.isSubmitting ||
                                  updateScorecardsetMutation.isLoading,
                              )}
                              onClick={() => {
                                setSelectedVersion(activeScorecardsetVersion?.version)
                                setIsVersionDialogOpen(true)
                              }}
                            >
                              History
                            </Button>
                          </Box>
                          <Box alignSelf="flex-start" mt={-0.3} mb={0.5}>
                            <Button
                              variant="secondary"
                              disabled={shouldDisableEditButton}
                              size="small"
                              onClick={() => setIsCreateMode(true)}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Fragment>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container xs={12} display="flex" flexDirection={{ xs: "column", lg: "row" }}>
                  <Grid item xs>
                    <Typography variant="p" display="block" style={{ textTransform: "inherit" }}>
                      Assign weighted numerical scores to applicants based on their features. To be effective, you
                      integrate it into your workflow.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          />

          {/* Body */}
          <CardContent className={styles.cardContent}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2} mb={1}>
              <Grid item container display="flex" flexDirection="column">
                <Typography variant="label" tooltip="Calculation method applied for all scorecards">
                  Scorecard calculation
                </Typography>
                <Grid item container display="flex" justifyContent="flex-start">
                  {isCreateMode || isUpdateMode ? (
                    <Grid container item width={{ xs: "fit-content" }} spacing={1} flexWrap="nowrap">
                      <Grid item mr={0.5}>
                        <RadioButton
                          value="Value"
                          onChange={() =>
                            setCalculationMethod(ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT)
                          }
                          checked={
                            calculationMethod === ScoreCardSetCreate.calculation_method.NORMALIZED_VALUE_BY_WEIGHT
                          }
                          label="Divide by max value"
                          id={`normalized-value-by-weight-${uuidv4()}`}
                          disabled={formik?.isSubmitting}
                        />
                      </Grid>

                      <Grid item mr={0.5}>
                        <RadioButton
                          value="Feature"
                          onChange={() => setCalculationMethod(ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT)}
                          checked={calculationMethod === ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT}
                          label="Multiply by card weight"
                          id={`Value-by-weight-${uuidv4()}`}
                          disabled={formik?.isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item width="min-content" mt={0.5} mr={1.5}>
                      <DataBlock
                        textVariant="p"
                        value={
                          calculationMethod === ScoreCardSetCreate.calculation_method.VALUE_BY_WEIGHT
                            ? "Multiply by card weight"
                            : "Divide By Max Value"
                        }
                      />
                    </Grid>
                  )}
                  {/* TODO:: replace with SUI anchor component in the revamp
             leaving the styles inline to remember to remove them when revamping */}
                  <Grid
                    display="flex"
                    alignSelf={isCreateMode || isUpdateMode ? "flex-end" : "center"}
                    item
                    ml={1}
                    mt={isCreateMode || isUpdateMode ? "0px" : "4px"}
                    onClick={() => setIsExplanationDialogOpen(true)}
                  >
                    <Link className={"explainAnchor"} underline="none">
                      Explain
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              {formik.values.scorecards && formik.values.scorecards?.length > 0 ? (
                formik?.values?.scorecards.map((scorecard, index: number) => (
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    display={"flex"}
                    justifyContent="flex-start"
                    alignItems="center"
                    key={scorecard.uuid}
                  >
                    <Grid item xs={8} md={9.5}>
                      <ScoreCardBlock
                        scorecard={{ name: scorecard.name, uuid: scorecard.uuid }}
                        isEdit={isCreateMode || isUpdateMode}
                        isLoading={isScorecardsetLoading}
                        handleRemove={() => handleRemoveScorecard(index)}
                        ScoreCards={allScorecards}
                        onValueChange={(scorecard) => {
                          const formikScoreCards = formik.values.scorecards

                          formikScoreCards[index] = { ...formikScoreCards[index], ...scorecard }

                          formik.setFieldValue("scorecards", formikScoreCards)
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} md={2.5} alignSelf={"center"}>
                      {isCreateMode || isUpdateMode ? (
                        <InputText
                          id={`scorecards[${index}].weight`}
                          placeholder="Weight"
                          value={
                            formik.values.scorecards[index].weight
                              ? `${parseFloat(formik.values.scorecards[index].weight)}`
                              : undefined
                          }
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          hideDescription
                          disabled={formik.isSubmitting}
                          type="number"
                          fullWidth
                        />
                      ) : (
                        <DataBlock value={`${parseFloat(scorecard.weight)}`} isLoading={isScorecardsetLoading} />
                      )}
                    </Grid>
                  </Grid>
                ))
              ) : (
                <ScorecardLoadingComponent />
              )}
            </Grid>

            {(isCreateMode || isUpdateMode) && (
              <Grid container justifyContent="flex-start" alignItems="center" mt={2} mb={0.5}>
                <Grid item justifyContent="flex-start">
                  <Button
                    size="regular"
                    variant="ghost"
                    disabled={Boolean(
                      createScorecardSetsMutation.isLoading ||
                        updateScorecardsetMutation.isLoading ||
                        formik.isSubmitting,
                    )}
                    onClick={
                      allScorecards && allScorecards?.length === 0
                        ? handleAddScorecard
                        : (event) => setAnchorEl(event.currentTarget)
                    }
                  >
                    + Add Card
                  </Button>

                  <Menu
                    key="basic-menu1"
                    anchorEl={anchorEl}
                    open={openNewCardMenu}
                    onClose={() => {
                      setAnchorEl(null)
                      setAdjustedAllScorecards(allScorecards ?? [])
                    }}
                    menuMaxContent
                  >
                    <Grid item container justifyContent="center" paddingLeft={1} paddingRight={1}>
                      <Button
                        variant="secondary"
                        startIcon={<AddOutlined fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />}
                        onClick={handleAddScorecard}
                        fullWidth
                      >
                        New Scorecard
                      </Button>
                    </Grid>

                    {allScorecards && allScorecards?.length > 0 && (
                      <Grid>
                        <Grid mt={1} paddingLeft={1} paddingRight={1}>
                          <InputText
                            id="scorecards"
                            width={100}
                            fullWidth
                            placeholder={"Search existing scorecards"}
                            key={allScorecards[0]?.name}
                            hideDescription
                            handleChange={(e: InputChangeEvent) => onSearchValueChange(e.target.value as string)}
                          />
                        </Grid>

                        <Grid container direction="column" className={styles.scorecardsOptionsContainer}>
                          {adjustedAllScorecards?.map((scorecard) => {
                            return (
                              <MenuItem
                                key={scorecard?.name}
                                onClick={() => {
                                  formik.setFieldValue("scorecards", [
                                    ...formik.values.scorecards,
                                    {
                                      name: scorecard.name,
                                      weight: undefined,
                                      uuid: scorecard.uuid,
                                    },
                                  ])

                                  setAnchorEl(null)
                                }}
                              >
                                <Grid item justifyContent="space-between" container px={0.5}>
                                  <Typography
                                    style={{
                                      marginLeft: "-8px",
                                    }}
                                    disabled={
                                      Boolean(
                                        formik.values.scorecards?.find((item) => item.uuid === scorecard?.uuid),
                                      ) ||
                                      Boolean(formik?.values?.scorecards?.find((sc) => sc?.uuid === scorecard?.uuid))
                                    }
                                    variant="p"
                                  >
                                    {scorecard?.name}
                                  </Typography>

                                  {Boolean(formik.values.scorecards?.find((item) => item.uuid === scorecard?.uuid)) && (
                                    <CheckOutlinedIcon fontSize="small" style={{ color: "var(--green-text-2)" }} />
                                  )}
                                </Grid>
                              </MenuItem>
                            )
                          })}
                        </Grid>
                      </Grid>
                    )}
                  </Menu>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
    </Fragment>
  )
}
