import React from "react"

import { Card, CardContent, CardHeader, Grid, Skeleton } from "@mui/material"

import styles from "./DecisionLoadingCard.module.scss"

export function DecisionLoadingCard(): React.ReactElement {
  return (
    <Grid item xs={12}>
      <Card className="card-box-shadow">
        <CardHeader className={styles.header} title={<Skeleton width={200} />} />
        <CardContent className={styles.content}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </CardContent>
      </Card>
    </Grid>
  )
}
