import React from "react"

import { useLocation, useNavigate } from "react-router-dom"

import { Box, Grid, Hidden, Skeleton } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { MetricsState } from "../types/custom/projects"
import { customRound } from "../utils/genericHelpers"
import { GraphHeader } from "./GraphHeader"
import { InfoContainer } from "./InfoContainer"

import styles from "./AccuracyCard.module.scss"

type Props = {
  accuracy: number | null
  cardHeight: number
  isLoading: boolean
  emptyState: MetricsState | undefined
  range?: number
}

export function AccuracyCard(props: Props): React.ReactElement {
  const { accuracy, cardHeight, isLoading, emptyState, range } = props

  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <div className={"charts-paper"}>
      <GraphHeader
        title={"ACCURACY"}
        tooltipText={
          <React.Fragment>
            Number of <strong>correct predictions</strong> over the <strong>total number</strong> of predictions.
            <br />
            <br />
            <em>Only works for predictions with feedback.</em>
          </React.Fragment>
        }
        range={range}
      />

      <Box style={{ height: cardHeight, padding: "16px 16px 0px 16px" }}>
        {isLoading ? (
          // response is still loading
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={styles.loadingContainer}
          >
            <Skeleton animation="wave" width="75%" height="20%" />
            <Skeleton animation="wave" width="50%" height="10%" />
          </Grid>
        ) : accuracy || accuracy === 0 ? (
          // Accuracy is NOT falsey OR is exactly 0
          <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: cardHeight }}>
            {/* Desktop label and value */}
            <Hidden mdDown>
              <Grid item marginBottom={3}>
                <Typography variant="h1-bold" className={styles.accuracyValue}>
                  {customRound(accuracy * 100, 2)}%
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h1-regular" className={styles.accuracyLabel}>
                  Accurate
                </Typography>
              </Grid>
            </Hidden>
            {/* Mobile label and value */}
            <Hidden mdUp>
              <Grid item>
                <Typography variant="h1-bold" className={styles.accuracyValueMobile}>
                  {customRound(accuracy * 100, 2)}%
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h1-regular" className={styles.accuracyLabelMobile}>
                  Accurate
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        ) : (
          // Accuracy is falsey AND NOT 0
          // i.e.: Accuracy is null ==> emptyState
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            {emptyState?.title ? (
              <InfoContainer
                title={emptyState?.title}
                subtitle={emptyState?.description}
                action={
                  emptyState?.action ? (
                    <Button
                      variant="primary"
                      onClick={() => navigate(`${pathname.substring(0, pathname.indexOf("/models"))}/integration`)}
                      id="acc_card_empty_state_btn"
                    >
                      {emptyState?.action}
                    </Button>
                  ) : undefined
                }
              />
            ) : (
              <Typography variant="p">No predictions or feedback</Typography>
            )}
          </Grid>
        )}
      </Box>
    </div>
  )
}
