import React from "react"

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import BlockIcon from "@mui/icons-material/Block"
import CachedIcon from "@mui/icons-material/Cached"
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import CodeIcon from "@mui/icons-material/Code"
import FilterListIcon from "@mui/icons-material/FilterList"
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined"
import SubjectIcon from "@mui/icons-material/Subject"
import TagIcon from "@mui/icons-material/Tag"
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined"
import { Card, Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { NodeDataBasedOnType } from "../../../features/Workflows/components/SelectionNode"
import { getTheme } from "../../../hooks/UseTheme"

import styles from "../../../features/Workflows/components/CustomCards.module.scss"

interface Props {
  nodeType:
    | "scorecardset"
    | "ruleset"
    | "taglist"
    | "program"
    | "label"
    | "filter"
    | "calculator"
    | "loop-start"
    | "loop-end"
    | "project"
    | "script"
    | "AddBlockNode"

  nodeBody?: React.ReactElement
}

/**
 * Retrieves node data based on the node type. - used in (simulation sankey chart and loops explaination block)
 *
 * @param nodeType - The type of the node.
 * @param nodeBody - the react element to render (node body)
 * @returns {React.ReactElement} The node data including logo, title, and logo class name.
 */
export function LabelAuditCard(props: Readonly<Props>): React.ReactElement {
  const { nodeType, nodeBody } = props

  const theme = getTheme()

  const getNodesDataBasedOnNodeType = (
    nodeType:
      | "scorecardset"
      | "ruleset"
      | "taglist"
      | "program"
      | "label"
      | "filter"
      | "calculator"
      | "loop-start"
      | "loop-end"
      | "project"
      | "script"
      | "AddBlockNode",
  ): NodeDataBasedOnType => {
    switch (nodeType) {
      case "ruleset":
        return {
          nodeLogo: <RuleOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "RULESET",
          logoClassName: "RulesetCardType",
        }
      case "scorecardset":
        return {
          nodeLogo: <AssignmentOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "SCORECARDSET",
          logoClassName: "ScorecardsetCardType",
        }
      case "program":
        return {
          nodeLogo: <SubjectIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "PROGRAM",
          logoClassName: "ProgramCardType",
        }
      case "filter":
        return {
          nodeLogo: <FilterListIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "FILTER",
          logoClassName: "filterCardType",
        }
      case "calculator":
        return {
          nodeLogo: <CalculateOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "CALCULATOR",
          logoClassName: "calculatorCardType",
        }
      case "project":
        return {
          nodeLogo: <TokenOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "PROJECT",
          logoClassName: "ProjectCardType",
        }
      case "taglist":
        return {
          nodeLogo: <TagIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "TAGLIST",
          logoClassName: "TaglistCardType",
        }
      case "script":
        return {
          nodeLogo: <CodeIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "SCRIPT",
          logoClassName: "ScriptCardType",
        }
      case "loop-start":
        return {
          nodeLogo: <CachedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "LOOP START",
          logoClassName: "loopStartCardType",
        }
      case "loop-end":
        return {
          nodeLogo: <CheckOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "LOOP END",
          logoClassName: "loopEndCardType",
        }
      default:
        return {
          nodeLogo: <BlockIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "LABEL",
          logoClassName: "endCardType",
        }
    }
  }

  return (
    <Card className={styles.card} style={{ width: "240px", marginRight: "0px" }}>
      <Grid container>
        {/* Node Logo */}
        <Grid item xs={"auto"}>
          <Grid item className={styles[`${getNodesDataBasedOnNodeType(nodeType)?.logoClassName}`]}>
            {getNodesDataBasedOnNodeType(nodeType)?.nodeLogo}
          </Grid>
        </Grid>

        <Grid
          item
          xs
          flexDirection={"column"}
          display="flex"
          paddingLeft={1}
          justifyContent={nodeType === "loop-end" ? "center" : "space-between"}
          alignItems={"flex-start"}
          position={"relative"}
        >
          <Grid item container justifyContent="space-between">
            <Typography variant="label" variantColor={2}>
              {getNodesDataBasedOnNodeType(nodeType)?.nodeTitle}
            </Typography>
          </Grid>

          {/* Node Body*/}
          {nodeType !== "loop-end" && nodeBody}
        </Grid>
      </Grid>
    </Card>
  )
}
