import React, { Fragment } from "react"

import { useLocation, useNavigate } from "react-router-dom"

import { Box, Grid, Skeleton } from "@mui/material"
import { ResponsivePie } from "@nivo/pie"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { CoverageGraphInput, MetricsState } from "../../types/custom/projects"
import { customColorScheme, customRound } from "../../utils/genericHelpers"
import { GraphHeader } from "../GraphHeader"
import { InfoContainer } from "../InfoContainer"

type Props = {
  title: string
  data: CoverageGraphInput[]
  totalCoveragePercentage: number
  isLoading: boolean
  graphHeight: number
  emptyState: MetricsState | undefined
  range?: number
}

export function CoveragePieChart(props: Props): React.ReactElement {
  const { title, data, totalCoveragePercentage, isLoading, graphHeight, emptyState, range } = props

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const theme = getTheme()

  /* data inside the pie chart */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CenteredMetric = (data: any): React.ReactElement => {
    return (
      <Fragment>
        <text textAnchor="middle" dominantBaseline="central" fill={theme.palette.grayscale.text[1]}>
          <tspan
            x={data.centerX}
            y={data.centerY - 10}
            style={{
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            {customRound(totalCoveragePercentage * 100, 1) + "%"}
          </tspan>
          <tspan
            x={data.centerX}
            y={data.centerY + 16}
            style={{
              fontSize: "18px",
              fontWeight: 400,
            }}
          >
            Covered
          </tspan>
        </text>
      </Fragment>
    )
  }

  return (
    <div className={"charts-paper"}>
      <GraphHeader
        title={title}
        tooltipText={"Percentage of how many times the model was able to return a decision, per label."}
        range={range}
      />

      <Box style={{ height: graphHeight, padding: "16px 16px 0px 16px" }}>
        {(!data || data.length < 1) && !isLoading && emptyState ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            {emptyState?.title ? (
              <InfoContainer
                title={emptyState?.title}
                subtitle={emptyState?.description}
                action={
                  emptyState?.action ? (
                    <Button
                      variant="primary"
                      onClick={() => navigate(`${pathname.substring(0, pathname.indexOf("/models"))}/integration`)}
                    >
                      {emptyState?.action}
                    </Button>
                  ) : undefined
                }
              />
            ) : (
              <Typography variant="p">No predictions or feedback</Typography>
            )}
          </Grid>
        ) : isLoading || !data ? (
          // Loading skeletons
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className={"full-height"}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="75%" />
            <Skeleton animation="wave" width="60%" height={30} />
            <Skeleton animation="wave" width="30%" />
          </Grid>
        ) : (
          <ResponsivePie
            data={data}
            theme={NivoTheme(theme)}
            layers={["arcs", "arcLabels", "legends", CenteredMetric]}
            colors={customColorScheme()}
            margin={{ top: 36, right: 0, bottom: 36, left: 0 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#fff"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            valueFormat={(item: number) => `${item}%`}
            tooltip={({ datum: { id, value, data, color } }) => (
              <div className={"chart-tooltip"}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Grid item>
                    <span key="chip" style={{ display: "block", width: "12px", height: "12px", background: color }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h3-bold" display="block" variantColor={2}>
                      {id}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography variant="p" variantColor={2}>
                  Count: {data.count}
                </Typography>
                <Typography variant="p" variantColor={2}>
                  Percentage: {value + "%"}
                </Typography>
              </div>
            )}
          />
        )}
      </Box>
    </div>
  )
}
