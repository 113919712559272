import React, { Fragment, useRef, useState } from "react"

import { useSelector } from "react-redux"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Card, CardContent, Grid } from "@mui/material"
import { Button, InputChangeEvent, InputText } from "@synapse-analytics/synapse-ui"
import { useFormikContext } from "formik"

import { FeaturesMenu } from "../../components/FeaturesMenu"
import { InfoBlock } from "../../components/containers/InfoBlock"
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { useDebounce } from "../../hooks/useDebounce"
import { RootState } from "../../store/ReduxStore"
import { FeatureData, SchemaFeature } from "../../types/custom/workflows"
import { WorkflowSchemaFeature } from "../../types/generated/api/WorkflowSchemaFeature"
import { ArrowAdornment } from "../Scorecards/Condition"
import { Condition } from "./Condition"
import {
  AddToBuckets,
  createScoreTableBucketsFromResultsArray,
  getIndexRepresentationBasedOnCriteria,
  reverseIndexRepresentationBasedOnCriteria,
} from "./helpers"
import { ConfigCardProps, ScoreTableFormik } from "./interfaces"

import styles from "./ScoreTables.module.scss"

export function ConfigCard(props: Readonly<ConfigCardProps>): React.ReactElement {
  const { index, mode } = props

  const maxBucketsFeatureFlags = Number(window.__RUNTIME_CONFIG__.RULES_TABLES_MAXIMUM_BUCKETS)

  const { values, setFieldValue, handleChange, handleBlur, isSubmitting } = useFormikContext<ScoreTableFormik>()

  // variable contains all features (computed, schema)
  const schemaFeatures = useSelector((state: RootState) => state?.project?.schemaFeatures)
  const computedSchemaFeatures = useSelector((state: RootState) => state?.project?.computedFeatures)

  const workflowsList = useSelector((state: RootState) => state?.workflow?.workflows)

  const [filteredFeatures, setFilteredFeatures] = useState<Array<FeatureData>>(schemaFeatures ?? [])

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<null | boolean>(null)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // ref for LHS feature
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>()

  const debouncedOnChange = useDebounce((e: InputChangeEvent): void => {
    const filteredFeatures = [...schemaFeatures, ...computedSchemaFeatures].filter(
      (item: FeatureData | SchemaFeature) => item?.name?.toLowerCase()?.includes(values.features[index].toLowerCase()),
    )

    setFilteredFeatures(filteredFeatures)

    setFieldValue(`features.${index}`, e.target.value)

    const tempCriteria = values.criteria

    tempCriteria[index].forEach((condition) => {
      condition.feature = e.target.value as string
    })

    setFieldValue("criteria", tempCriteria)

    setAnchorEl(inputRef?.current)
    setTimeout(() => {
      inputRef?.current?.focus()
    }, 100)
  }, 400)

  const handleFeatureChange = (event: InputChangeEvent): void => {
    handleChange(event)
    setAnchorEl(null)
    debouncedOnChange(event)
  }

  const handleFeatureSelectionFromMenu = (selectedItem: FeatureData): void => {
    const filteredFeatures = [...schemaFeatures, ...computedSchemaFeatures].filter(
      (item: FeatureData | SchemaFeature) => item?.name?.toLowerCase()?.includes(selectedItem?.name.toLowerCase()),
    )

    setFilteredFeatures(filteredFeatures)

    setFieldValue(`features.${index}`, selectedItem?.name)

    const tempCriteria = values.criteria

    tempCriteria[index].forEach((condition) => {
      condition.feature = selectedItem?.name
    })

    setFieldValue("criteria", tempCriteria)
  }

  const handleAddNewCriteria = (): void => {
    const buckets = createScoreTableBucketsFromResultsArray(values.results, values.criteria)

    AddToBuckets(buckets, index)

    setFieldValue("results", buckets.flat(values.criteria.length))

    setFieldValue(`criteria.[${index}]`, [
      ...values.criteria[index],
      {
        feature: values.features[index],
        condition: `${values.features[index]} = `,
        parsedCondition: [values.features[index], "=", ""],
        type: "string",
        operator: "equal",
        value: "",
      },
    ])
  }

  const handleDeleteBucket = (): void => {
    setFieldValue("results", [])

    setFieldValue(
      "criteria",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      values.criteria.filter((_: any, i: number) => i !== index),
    )

    setFieldValue(
      "features",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      values.features.filter((_: any, i: number) => i !== index),
    )

    setIsDeletionDialogOpen(null)
  }

  // TODO:: refactor
  const handleConfigCardReordering = (direction: "up" | "down"): void => {
    const swappingIndex = direction === "up" ? index - 1 : index + 1
    const criteria = values.criteria

    const criteria_new = [...values.criteria]
    ;[criteria_new[index], criteria_new[swappingIndex]] = [criteria_new[swappingIndex], criteria_new[index]]

    const results_new = Array(values.results.length).fill(null)

    for (let i = 0; i < values.results.length; i++) {
      const J = []

      // Generating index representation based on criteria
      for (let j = 0; j < criteria.length; j++) {
        J.push(getIndexRepresentationBasedOnCriteria(i, criteria, j))
      }

      //  Swapping criterions
      ;[J[index], J[swappingIndex]] = [J[swappingIndex], J[index]]

      // Reversing index representation based on generated representation and new criteria

      let new_i: number[] | number = []
      for (let j = 0; j < J.length; j++) new_i.push(reverseIndexRepresentationBasedOnCriteria(J[j], criteria_new, j))

      new_i = new_i.reduce((acc, i) => acc + i, 0)

      results_new.splice(new_i, 1, values.results[i])
    }

    setFieldValue("results", results_new)

    setFieldValue("criteria", criteria_new)

    const features = values.features
    ;[features[index], features[swappingIndex]] = [features[swappingIndex], features[index]]
    setFieldValue("features", features)
  }

  return (
    <Fragment>
      {!!isDeletionDialogOpen && (
        <BaseSimpleDialog
          open={true}
          isLoading={false}
          name={""}
          onAccept={handleDeleteBucket}
          onClose={() => setIsDeletionDialogOpen(null)}
          mode={"delete-config"}
        />
      )}

      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <Grid container item xs={12} gap={1}>
            <Grid container item gap={1} display={"flex"} flexWrap={"nowrap"}>
              {mode !== "read" && (
                <Fragment>
                  <Button
                    variant="ghost"
                    size="regular"
                    disabled={index === 0}
                    onClick={() => handleConfigCardReordering("up")}
                  >
                    <KeyboardArrowUpIcon sx={{ fontSize: "22px" }} />
                  </Button>

                  <Button
                    variant="ghost"
                    size="regular"
                    disabled={index === values.criteria.length - 1}
                    onClick={() => handleConfigCardReordering("down")}
                  >
                    <KeyboardArrowDownIcon sx={{ fontSize: "22px" }} />
                  </Button>
                </Fragment>
              )}
              {mode === "read" ? (
                <InfoBlock text={values.features[index]} />
              ) : (
                <Fragment>
                  <InputText
                    id={`features.${index}`}
                    anchoringRef={inputRef}
                    variant="filled"
                    fullWidth
                    hideDescription
                    value={values.features[index]}
                    handleChange={handleFeatureChange}
                    handleBlur={handleBlur}
                    placeholder={"feature"}
                    endAdornment={
                      [...schemaFeatures, ...computedSchemaFeatures]?.length > 0 ? (
                        <ArrowAdornment
                          anchorEl={anchorEl}
                          onClick={() => setAnchorEl(!!anchorEl ? null : inputRef?.current)}
                        />
                      ) : undefined
                    }
                  />

                  {/* as far as i remember it was made to accommodate MUI, so maybe we don't need it -> 9/2/2023 */}
                  {/* TODO:: Refactor and discuss removing adding to schema from this Menu */}
                  <FeaturesMenu
                    filteredFeatures={filteredFeatures}
                    key={`${filteredFeatures?.length}`}
                    workflowsList={workflowsList}
                    anchorEl={anchorEl}
                    queryKey={values.features[index]}
                    handleFeatureTypeChange={() => {
                      return false
                    }}
                    onAddingNewFeature={() => {
                      return false
                    }}
                    onSelectingFeature={(item) => {
                      setFieldValue(`features.${index}`, item?.name)
                      handleFeatureSelectionFromMenu(item)
                      setAnchorEl(null)
                    }}
                    onMenuClose={() => setAnchorEl(null)}
                    initialFeatureType={() => WorkflowSchemaFeature.type.NUMBER}
                  />

                  <Button
                    variant="dangerous"
                    onClick={() => setIsDeletionDialogOpen(true)}
                    disabled={isSubmitting || values.criteria.length <= 2}
                    tooltip={values.criteria.length <= 2 ? "Must have at least 2 features per table" : undefined}
                  >
                    Delete
                  </Button>
                </Fragment>
              )}
            </Grid>

            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {values.criteria[index].map((_: any, idx: number) => (
              <Condition mode={mode} configIndex={index} conditionIndex={idx} />
            ))}
          </Grid>

          {mode !== "read" && (
            <Grid item xs={12} mt={1}>
              <Button
                variant="ghost"
                onClick={handleAddNewCriteria}
                disabled={values?.criteria?.[index].length >= maxBucketsFeatureFlags && maxBucketsFeatureFlags !== -1}
                tooltip={
                  values?.criteria?.[index].length >= maxBucketsFeatureFlags && maxBucketsFeatureFlags !== -1
                    ? `Maximum number of conditions (${maxBucketsFeatureFlags}) reached`
                    : undefined
                }
              >
                + Add condition
              </Button>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Fragment>
  )
}
