import React, { Fragment } from "react"

import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { BarDatum, ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { PredictionsSummary } from "../../types/custom/projects"
import { Model } from "../../types/generated/api/Model"
import { getModelByUUID } from "../../utils/modelDetailsHelpers"
import { orderModelsByStatus } from "../../utils/searchSortFilterHelpers"
import { GraphHeader } from "../GraphHeader"

type Props = {
  title: string
  data: PredictionsSummary[]
  models: Model[]
  isLoading: boolean
  graphHeight: number
  range?: number
}

export function RequestsBarChart(props: Props): React.ReactElement {
  const { title, data, models, isLoading, graphHeight, range } = props

  const theme = getTheme()

  const maxLabelLength = 24

  const sortedData: PredictionsSummary[] = React.useMemo(() => {
    if (data && data.length > 0 && models && models.length > 0) {
      const newData = Array.from(data)
      //map creation date to each model
      newData.forEach((item) => {
        item.created_at = getModelByUUID(item.uuid, models)?.created_at
      })
      return orderModelsByStatus(newData, "desc")
    }
    return []
  }, [data, models])

  return (
    <div className={"charts-paper"}>
      <GraphHeader
        title={title}
        tooltipText={
          <React.Fragment>
            The number of predictions that the models received.
            <br />
            Will be colored:
            <ul>
              <li>
                <strong style={{ color: theme.palette.blue.background[1] }}>BLUE</strong> for successful requests.
              </li>
              <li>
                <strong style={{ color: theme.palette.red.background[1] }}>RED</strong> for failed requests.
              </li>
            </ul>
          </React.Fragment>
        }
        range={range}
      />

      <Box style={{ height: graphHeight, padding: "4px 16px 8px 16px" }}>
        {(!data || data.length < 1) && !isLoading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            <Typography variant="p" variantColor={2}>
              No data to display
            </Typography>
          </Grid>
        ) : isLoading || !data ? (
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className={"full-height"}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="75%" />
            <Skeleton animation="wave" width="60%" height={30} />
            <Skeleton animation="wave" width="30%" />
          </Grid>
        ) : (
          <ResponsiveBar
            data={sortedData as BarDatum[]}
            theme={NivoTheme(theme)}
            indexBy="name"
            keys={["successful", "failed"]}
            colors={[theme.palette.blue.background[1], theme.palette.red.background[1]]}
            colorBy="id"
            margin={{ top: 30, right: 0, bottom: -20, left: 0 }}
            borderRadius={4}
            padding={0.75}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            enableGridY={false}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            animate={true}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -22,
                itemsSpacing: 30,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 15,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            legendLabel={(v) => v.id.toString().toUpperCase()}
            labelSkipWidth={2000} // labelSkipWidth = minimum bar size needed for a normal label
            layers={[
              "grid",
              "axes",
              "bars",
              "markers",
              "legends",
              "annotations",
              ({ bars }) => {
                return (
                  <g>
                    {bars.map(({ width, y, key, data }) => {
                      return (
                        width && (
                          <Fragment key={key}>
                            {/* Model name on bar */}
                            <text
                              //Calculate position of model name
                              transform={`translate(${0}, ${y - 9})`}
                              textAnchor="left"
                              dominantBaseline="central"
                              className="chart-text"
                            >
                              {`${
                                key.slice(key.indexOf(".") + 1).length > maxLabelLength
                                  ? key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength) + "..."
                                  : key.slice(key.indexOf(".") + 1)
                              }`}
                            </text>

                            {/* TODO:: remove the magic numbers and refactor to something more understandable */}
                            {!!data.value &&
                              (data.id === "successful"
                                ? //Left value on bar
                                  key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength).length * 10 +
                                    data?.value?.toString().length * 10 -
                                    25 <
                                    width && (
                                    <text
                                      transform={`translate(${
                                        //Calculate position left value
                                        width - data?.value?.toString().length * 10 - 5
                                      }, ${y - 9})`}
                                      textAnchor="right"
                                      dominantBaseline="central"
                                      className="chart-text"
                                    >
                                      {data.value ?? ""}
                                    </text>
                                  )
                                : //Right value on bar
                                  key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength).length * 10 +
                                    data?.value?.toString().length * 10 -
                                    10 <
                                    width &&
                                  data?.value?.toString().length * 10 + 15 < width && (
                                    <text
                                      transform={`translate(${
                                        //Calculate position of right
                                        bars[
                                          bars.findIndex((item) => item.key.includes(key.slice(key.indexOf(".") + 1)))
                                        ].width +
                                        width -
                                        data?.value?.toString().length * 10 -
                                        5
                                      }, ${y - 9})`}
                                      textAnchor="right"
                                      dominantBaseline="central"
                                      className="chart-text"
                                    >
                                      {data.value ?? ""}
                                    </text>
                                  ))}
                          </Fragment>
                        )
                      )
                    })}
                  </g>
                )
              },
            ]}
            tooltip={(slice) => {
              return (
                <div className={"chart-tooltip"}>
                  <Typography variant="h3-bold" variantColor={2}>
                    {slice.data.name}
                  </Typography>
                  <Typography variant="p" variantColor={2}>{`Successful: ${slice.data.successful ?? 0}`}</Typography>
                  <Typography variant="p" variantColor={2}>{`Failed: ${slice.data.failed ?? 0}`}</Typography>
                  <Typography variant="p" variantColor={2}>{`Total: ${slice.data.total}`}</Typography>
                </div>
              )
            }}
          />
        )}
      </Box>
    </div>
  )
}
