import { configureStore } from "@reduxjs/toolkit"

import { GroupSlice } from "../features/Groups"
import { MembersSlice } from "../features/Members"
import { PermissionsSlice, RoleSlice } from "../features/Roles"
import { WorkflowSlice } from "../features/Workflows"
import { ProjectSlice } from "../features/projects"

export const store = configureStore({
  reducer: {
    permissions: PermissionsSlice.reducer,
    members: MembersSlice.reducer,
    roles: RoleSlice.reducer,
    groups: GroupSlice.reducer,
    project: ProjectSlice.reducer,
    workflow: WorkflowSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
