import React, { Fragment } from "react"

import { Grid } from "@mui/material"
import { Avatar, Typography } from "@synapse-analytics/synapse-ui"

import { KonanAvatarComponent } from "../../components/KonanAvatar"
import { UserManagementLog } from "../../types/generated/authentication/UserManagementLog"

interface LogItemProps {
  log: UserManagementLog
}

/**
 * Change History log item
 * @param {UserManagementLog} log
 * @returns {React.ReactElement}
 */
export function LogItem(props: Readonly<LogItemProps>): React.ReactElement {
  const { log } = props

  const title = (): React.ReactElement => {
    return (
      <Typography variant="span">
        {/* First name */}
        <strong style={{ textTransform: "capitalize" }}>{log?.performed_by?.first_name?.toLowerCase()}</strong>{" "}
        {/* Action */}
        {log?.action} {/* name in case of assignee to a role */}
        {/* TODO:: should be replaced with name when BE implements it */}
        {log?.target?.type === "role" && log.action_for && (
          <Fragment>
            <strong>{log.action_for.label}</strong> to{" "}
          </Fragment>
        )}
        {log?.target?.type}{" "}
        <strong style={{ textTransform: "capitalize" }}>
          {log.target.type === "role" && log?.target?.label === "USER" ? "Viewer" : log?.target?.label?.toLowerCase()}
        </strong>
      </Typography>
    )
  }

  return (
    <Grid container item xs={12} justifyContent={"space-between"}>
      <KonanAvatarComponent
        createdAt={log.created_at}
        createdBy={log.performed_by.email}
        title={title()}
        noWrap={false}
      />
    </Grid>
  )
}

export function LogItemLoader(): React.ReactElement {
  return <Avatar src="1" isLoading={true} />
}
