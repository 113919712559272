import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"

import { useQuery } from "react-query"

import CloseIcon from "@mui/icons-material/Close"
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Button, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { FormikProps } from "formik"

import { DataMappingFlow } from "../features/projects/components/DataMappingFlow"
import { UploadTrainingDataFlow } from "../features/projects/components/UploadTrainingDataFlow"
import { getTheme } from "../hooks/UseTheme"
import { KonanAPI } from "../services/KonanAPI"
import { CustomFeatureRequest, baseErrorType } from "../types/custom/projects"
import { ExcludedFeatureRequest } from "../types/generated/api/ExcludedFeatureRequest"
import { TrainingDataSchemaResponse } from "../types/generated/api/TrainingDataSchemaResponse"

type Props = {
  open: boolean
  setDialog: Dispatch<SetStateAction<boolean>>
  projectId: string
  isBtnLoading: boolean
  errorMessage?: string
  handleTrainClick: (type: "pre-built" | "auto-ml") => Promise<void>
  setTrainingDataUUID: Dispatch<SetStateAction<string>>
  setAutoMlData: Dispatch<
    SetStateAction<{ mapping: Array<CustomFeatureRequest>; excludedColumns: Array<ExcludedFeatureRequest> }>
  >
  trainingDataUUID: string
  formik: FormikProps<{ target_column: string; name: string; targetColumnType: string }>
  automlErrors: AxiosError<baseErrorType> | null
  resetErrors: () => void
}

/**
 * Dialog Component that handles the uploading and data mapping mapping steps in Credit scoring projects
 * @param {boolean} open dialog open state
 * @param {Function} setDialog setter state for handling dialog open/close
 * @param {string} projectId current project id
 * @param {string | undefined} errorMessage error message string
 * @param {Function} handleTrainClick handler function to toggle between different flow based on CS model type
 * @param {Function} setTrainingDataUUID setter state for setting CS data
 * @param {Function} setAutoMlData setter state for setting AutoML specific data
 * @param {string} trainingDataUUID training data uuid
 * @param {FormikProps} formik form state handler
 * @param {AxiosError} automlErrors
 * @param {Function} resetErrors function to reset formik errors
 * @returns
 */
export function TrainingDataUploadDialog(props: Readonly<Props>): React.ReactElement {
  const {
    open,
    errorMessage,
    setDialog,
    projectId,
    isBtnLoading,
    handleTrainClick,
    setTrainingDataUUID,
    setAutoMlData,
    trainingDataUUID,
    formik,
    automlErrors,
    resetErrors,
  } = props
  const errorALertRef = useRef<HTMLElement>(null)

  // this state will be used later to toggle between pre-built and automl
  const [selectedCSType, _] = useState<"auto-ml" | "pre-built">("auto-ml")

  // TODO:: refactor to a stepper
  const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false)

  const theme = useTheme()
  const themeMode = getTheme()

  // fetch uploaded training data headers (columns)
  const { data: trainingSchema, isLoading: isTrainingSchemaLoading } = useQuery<TrainingDataSchemaResponse, AxiosError>(
    ["training-data-schema", trainingDataUUID],
    () => KonanAPI.fetchTrainingDataSchema(projectId, trainingDataUUID),
    {
      enabled: !!trainingDataUUID || open,
    },
  )

  // scrolling to the top of dialog where the snackbar error should be displayed using errorALertRef
  useEffect(() => {
    if (errorMessage) {
      errorALertRef?.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [errorMessage])

  return (
    <Dialog
      onClose={(_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && isBtnLoading) {
          return undefined
        } else {
          setTimeout(() => {
            setDialog(false)
            setNextButtonClicked(false)
            setTrainingDataUUID("")
            resetErrors()
            formik.resetForm()
          }, 100)
        }
      }}
      open={open}
      fullWidth
      fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
    >
      <DialogTitle className="dialog-header-base">
        <Grid item container justifyContent="space-between" xs={12}>
          <Grid item alignSelf={"center"}>
            <Typography variant="h2-bold">New Model</Typography>
          </Grid>

          <Grid item alignSelf={"center"}>
            <IconButton
              aria-label="close"
              onClick={() => {
                setTimeout(() => {
                  setNextButtonClicked(false)
                  setDialog(false)
                  setTrainingDataUUID("")
                  resetErrors()
                  formik.resetForm()
                }, 100)
              }}
              className="close-icon-button"
            >
              <CloseIcon style={{ color: themeMode.palette.gray.background[1] }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog-content-base">
        {errorMessage && <Snackbar variant="negative" fullWidth description={errorMessage} />}

        {nextButtonClicked ? (
          <DataMappingFlow
            onDataChange={(mapping: Array<CustomFeatureRequest>, excludedColumns: Array<ExcludedFeatureRequest>) => {
              setAutoMlData({ mapping, excludedColumns })
            }}
            createdProjectUUID={projectId}
            fileHeaders={trainingSchema?.columns}
            errors={automlErrors}
            formik={formik}
            isSchemaLoading={isTrainingSchemaLoading}
            trainingDataUUID={trainingDataUUID}
          />
        ) : (
          <UploadTrainingDataFlow
            currentProjectUUID={projectId}
            type={"dialog"}
            formik={formik}
            onDataChange={(datasetUUID: string) => {
              setTrainingDataUUID(datasetUUID)
            }}
            creditScoringType={selectedCSType}
          />
        )}
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Grid direction="column" container alignItems="flex-end" item xs={12}>
          <Grid item xs={12}>
            <Button
              variant="primary"
              onClick={() =>
                selectedCSType === "auto-ml" && !nextButtonClicked
                  ? setNextButtonClicked(true)
                  : selectedCSType === "auto-ml" && nextButtonClicked
                    ? handleTrainClick("auto-ml")
                    : handleTrainClick("pre-built")
              }
              disabled={
                selectedCSType === "auto-ml" && !nextButtonClicked
                  ? !formik.values.name || !trainingDataUUID
                  : selectedCSType === "auto-ml" && nextButtonClicked
                    ? !formik.values.target_column
                    : isBtnLoading
              }
            >
              {isBtnLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : selectedCSType === "auto-ml" && !nextButtonClicked ? (
                "next"
              ) : (
                selectedCSType === "auto-ml" && nextButtonClicked && "Train"
              )}
            </Button>
          </Grid>
          {(selectedCSType === "pre-built" || (selectedCSType === "auto-ml" && nextButtonClicked)) && (
            <Typography variant="span" color="neutral" variantColor={2} disabled style={{ marginTop: "5px" }}>
              Redirects to Training Jobs
            </Typography>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
