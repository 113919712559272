/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ScriptVersionRetrieve = {
  readonly file: string | null
  /**
   * Indicates whether this script is still being tested or is it ready to be used in workflows
   */
  is_draft?: boolean
  readonly created_at: string
  created_by?: string
  readonly version: string
  /**
   * Marks the active version of several instances of a Script
   */
  is_active_version?: boolean
  creds_keys: Array<string> | null
  readonly requirements_file: string | null
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   */
  status?: ScriptVersionRetrieve.status
  /**
   * logs that was output when building script package
   */
  logs?: string | null
}
export namespace ScriptVersionRetrieve {
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   */
  export enum status {
    PENDING = "PENDING",
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED",
  }
}
