import React, { useContext, useMemo, useState } from "react"

import InfiniteScroll from "react-infinite-scroller"
import { useQueries, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import Grid from "@mui/material/Grid"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState, KonanEmptyStateFilter, KonanEmptyStateSearch } from "../../components/KonanEmptyState"
import { KonanSubHeader } from "../../components/KonanSubHeader"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { baseErrorType } from "../../types/custom/projects"
import { Model } from "../../types/generated/api/Model"
import { ModelSettingsSet } from "../../types/generated/api/ModelSettingsSet"
import { getModelByUUID } from "../../utils/modelDetailsHelpers"
import { filterListByState, searchListByName, sortConfigList } from "../../utils/searchSortFilterHelpers"
import { ConfigurationCard } from "./Components/ConfigurationCard"
import { ConfigurationCardLoader } from "./Components/ConfigurationCardLoader"
import { ConfigurationFlow } from "./Components/ConfigurationFlow"

import styles from "./ModelConfigurations.module.scss"

type ParamsType = {
  id: string
}

/**
 * Model configuration screen component
 * @return {React.ReactElement}
 */
export function ModelConfigurations(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const pageSize = 6

  // SSF states
  const [searchValue, setSearchValue] = useState<string>("")
  const [sortValue, setSortValue] = useState<string>("Status")
  const [filterValue, setFilterValue] = useState<string[]>([])

  const [openNewConfigDialog, setOpenNewConfigDialog] = useState<boolean>(false)
  const [isConfigLoading, setIsConfigLoading] = useState<boolean>(false)

  const [modelsLoadCount, setModelsLoadCount] = useState<number>(0)

  const { isLoading: isModelsLoading, data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError<baseErrorType>>(
    ["overviewModels", projectId],
    () => KonanAPI.fetchModels(projectId as string),
    {
      onSuccess: (data) => {
        if (data.data.length > 0) {
          setModelsLoadCount(modelsLoadCount + 1)
          modelsLoadCount === 1 && setIsConfigLoading(true)
        }
      },
    },
  )

  // fetching the model configurations of each model in sequence of queries
  const modelsConfigurations = useQueries(
    models?.data
      ? (models?.data as Model[]).map((model: Model) => ({
          queryKey: ["model-configurations", model.uuid],
          queryFn: () => {
            return KonanAPI.fetchModelConfigurationsList(model.uuid)
          },
          onSettled: () => setIsConfigLoading(false),
          enabled: model.uuid !== null,
        }))
      : [],
  )

  // extracting the results from each query
  const configurations = useMemo(() => {
    const configs: ModelSettingsSet[] = []

    for (const config of modelsConfigurations) {
      config.data?.data?.results?.length &&
        config.data?.data?.results?.length > 0 &&
        configs.push(...config.data?.data?.results)
    }

    return configs
  }, [modelsConfigurations])

  // returns adjusted configurations based on search, sort, and filter values
  const adjustedConfigurations = useMemo((): ModelSettingsSet[] => {
    const configs = configurations.map((item) => {
      return {
        ...item,
        name: getModelByUUID(item.model, models?.data)?.name ?? "",
        is_active_str: item.is_active ? "Active" : "Deactivated",
      }
    })
    let adjustedConfigurations = configs.length > 0 ? sortConfigList(configs, "Status") : []

    if (searchValue && searchValue.length > 0) {
      adjustedConfigurations = adjustedConfigurations ? searchListByName(adjustedConfigurations, searchValue) : []
    }

    if (sortValue && sortValue.length > 0) {
      adjustedConfigurations = adjustedConfigurations ? sortConfigList(adjustedConfigurations, sortValue) : []
    }

    if (filterValue && filterValue.length > 0) {
      adjustedConfigurations = adjustedConfigurations
        ? filterListByState(adjustedConfigurations, filterValue, "configurations")
        : []
    }

    return adjustedConfigurations
  }, [configurations, filterValue, models?.data, searchValue, sortValue])

  return (
    <React.Fragment>
      {openNewConfigDialog && <ConfigurationFlow open onClose={() => setOpenNewConfigDialog(false)} />}

      <Grid container item xs={12}>
        <Grid container item mt={0.5} className={styles.overviewHeader}>
          <KonanSubHeader
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            sortValue={sortValue}
            setSortValue={setSortValue}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            filterOptions={["Active", "Deactivated"]}
            sortOptions={["Status", "Most Recent"]}
            containerType="Configuration"
            setAction={setOpenNewConfigDialog}
            title={"New Configuration"}
            isBtnDisabled={!isModelsLoading && models?.data.length === 0}
            disabledBtnTooltip={
              !isModelsLoading && models?.data.length === 0
                ? "Please create a model before trying to create a configuration"
                : ""
            }
          />
        </Grid>

        {isModelsLoading || isConfigLoading ? (
          <Grid container item xs={12} spacing={2}>
            {[1, 2].map((item: number) => (
              <Grid item xs={12} md={6} lg={4} key={item}>
                {/* Loading component */}
                <ConfigurationCardLoader />
              </Grid>
            ))}
          </Grid>
        ) : (
          <InfiniteScroll
            pageStart={currentPage}
            loadMore={() => setCurrentPage(currentPage + 1)}
            hasMore={adjustedConfigurations?.length >= currentPage * pageSize}
            className={styles.infiniteScroller}
          >
            <Grid container item xs={12} spacing={2}>
              {/* Config card */}
              {adjustedConfigurations?.map((item: ModelSettingsSet) => (
                <Grid item xs={12} md={6} lg={4} key={item?.uuid}>
                  <ConfigurationCard Configuration={item} />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
        {adjustedConfigurations?.length === 0 && (
          <Grid container xs={12} className={"empty-container"}>
            {!isModelsLoading && models?.data.length === 0 ? (
              <KonanEmptyState
                setAction={() => navigate(`/projects/${projectId}/decision-engines?page=Models`)}
                title="No models, yet."
                buttonText={currentProject?.type === "credit_scoring" ? "Train New Model" : "Deploy New Model"}
                subTitle={
                  currentProject?.type === "credit_scoring" ? "Train your first model" : "Deploy your first model"
                }
              />
            ) : !isModelsLoading &&
              (searchValue || (searchValue && filterValue)) &&
              adjustedConfigurations?.length === 0 ? (
              <KonanEmptyStateSearch title={searchValue} />
            ) : filterValue && !searchValue && adjustedConfigurations?.length === 0 && !isModelsLoading ? (
              <KonanEmptyStateFilter title={filterValue} />
            ) : (
              !isModelsLoading &&
              !isConfigLoading &&
              adjustedConfigurations.length === 0 && (
                <KonanEmptyState
                  setAction={() => setOpenNewConfigDialog(true)}
                  title="Classification settings have not been configured for the live model yet."
                  buttonText={"Configure your model"}
                  subTitle={
                    "This project will be treated as a generic project until the classification settings are configured."
                  }
                />
              )
            )}
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
}
