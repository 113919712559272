import React from "react"

import { RadioButton } from "@synapse-analytics/synapse-ui"
import { v4 as uuidv4 } from "uuid"

import { CustomTooltip } from "./UI/CustomTooltip"

type Props = {
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  checkedFeat: boolean
  checkedValue: boolean
  margin?: string
  isDisabled?: boolean
  shouldDisableFeature?: boolean
}

export function RadioButtonsCondition({
  handleOnChange,
  checkedFeat,
  checkedValue,
  isDisabled = false,
  shouldDisableFeature,
  margin,
}: Readonly<Props>): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: margin ?? "0px",
        minWidth: "70px",
      }}
    >
      <CustomTooltip title={shouldDisableFeature ? "Cannot select feature when data file uploaded!" : ""}>
        <RadioButton
          value="Feature"
          onChange={(e) => handleOnChange(e)}
          checked={checkedFeat}
          label={"Feature"}
          id={`Feature-${uuidv4()}`}
          disabled={isDisabled || shouldDisableFeature}
          style={{ marginBottom: "0.2rem" }}
        />
      </CustomTooltip>

      <RadioButton
        value="Value"
        onChange={(e) => handleOnChange(e)}
        checked={checkedValue}
        disabled={isDisabled}
        label={"Value"}
        id={`value-${uuidv4()}`}
      />
    </div>
  )
}
