import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { Auth } from "../utils/auth"
import { getHostnames } from "../utils/genericHelpers"

class AxiosApi {
  public axiosInstance: AxiosInstance
  public AUTH_URL = "http://localhost:8001"
  public SERVICE_URL = "http://localhost:8002"

  constructor(SERVICE_URL: string) {
    this.axiosInstance = axios.create({
      paramsSerializer: {
        indexes: null,
      },
    })

    // Remove the following 3 lines if you want to work with localhost
    const { auth } = getHostnames()
    this.AUTH_URL = `https://${auth}`
    this.SERVICE_URL = `https://${SERVICE_URL}`

    // To be used inside interceptors
    const axiosObj = this.axiosInstance
    const authURL = this.AUTH_URL

    // TODO: Add another interceptor to log issues
    // https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
    // Interceptor to add access token to requests
    axiosObj.interceptors.request.use(
      // TODO:: remove later
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (config: any) => {
        const token = Auth.getAccessToken()
        if (token && config.headers) {
          config.headers["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`
        }
        // config.headers['Content-Type'] = 'application/json';
        return config
      },
      (error) => {
        Promise.reject(error).catch(console.error)
      },
    )

    // Interceptor to handle 401 response (when access token expires)
    axiosObj.interceptors.response.use(
      (response) => {
        return response
      },
      function (error) {
        const originalRequest = error.config
        const refreshEndpoint = "/api/auth/token/refresh/"

        if (
          (error.response?.status === 400 || error.response?.status === 403) &&
          originalRequest.url === authURL + refreshEndpoint
        ) {
          // Don't call the logout endpoint here since that would cause an infinite loop due to the endpoint returning 401
          Auth.logOut(true)
          return Promise.reject(error)
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          const refreshToken = Auth.getRefreshToken()
          return axiosObj
            .post(authURL + refreshEndpoint, {
              refresh: refreshToken,
            })
            .then((res) => {
              if (res.status === 200) {
                Auth.login(res.data.access, refreshToken).catch(console.error)
                axiosObj.defaults.headers.common["Authorization"] = Auth.getAuthHeaderValue()
                return axiosObj(originalRequest)
              }
            })
        }
        return Promise.reject(error)
      },
    )
  }

  fetchResource = (endpoint: string, params = {}): Promise<AxiosResponse> => {
    const paramsObj = { params }
    return this.axiosInstance.get(this.SERVICE_URL + endpoint, paramsObj)
  }

  postResource = (endpoint: string, params = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this.axiosInstance.post(this.SERVICE_URL + endpoint, params, config)
  }

  deleteResource = (endpoint: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this.axiosInstance.delete(this.SERVICE_URL + endpoint, config)
  }

  patchResource = (endpoint: string, params = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this.axiosInstance.patch(this.SERVICE_URL + endpoint, params, config)
  }

  putResource = (endpoint: string, params = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this.axiosInstance.put(this.SERVICE_URL + endpoint, params, config)
  }
}

export { AxiosApi }
