import React, { Fragment, useMemo } from "react"

import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { InputText, Typography } from "@synapse-analytics/synapse-ui"
import { useFormikContext } from "formik"

import { Operators } from "../../types/custom/projects"
import { matchFile } from "../../utils/rulesetHelpers"
import { Condition, ScoreTableFormik, WeightTableProps } from "./interfaces"

import styles from "./ScoreTables.module.scss"

function* sequentialNumberGenerator(): Generator<number, void, unknown> {
  let i = 0
  while (true) yield i++
}

const valueParser = (value: string, type: string): string => {
  if (["number", "string"].includes(type)) {
    return value
  }

  return type
}

export function WeightTable(props: Readonly<WeightTableProps>): React.ReactElement {
  const { mode } = props

  const { values, handleChange } = useFormikContext<ScoreTableFormik>()

  const permutations = useMemo(() => {
    if (values.criteria.length < 2) return

    function generate(arrays: Condition[][]): Condition[][] {
      if (arrays.length === 0) {
        return [[]]
      }

      const [firstArray, ...restArrays] = arrays
      const restPermutations = generate(restArrays)
      const permutations: Condition[][] = []

      for (const item of firstArray) {
        for (const permutation of restPermutations) {
          permutations.push([item, ...permutation])
        }
      }

      return permutations
    }

    const allPermutations = generate(values.criteria).map((permutation) => {
      return {
        x: permutation[0],
        y: permutation.slice(1, permutation.length),
      }
    })

    return Object.groupBy(allPermutations, (perm) => `${perm.x.feature} ${perm.x.operator} ${perm.x.value}`)
  }, [values.criteria])

  /**
   * Retrieves the key of an operator based on its value.
   *
   * @param value - The value of the operator.
   * @returns The key of the operator.
   */
  const getOperatorKeyByValue = (value: string): string => {
    if (value === "is null") return value
    return Object.keys(Operators)[Object.values(Operators).indexOf(value as typeof Operators)]
  }

  // Initialize the generator outside the map function
  const generator = sequentialNumberGenerator()

  return (
    <Fragment>
      {values.criteria.length < 2 ? (
        <Grid container justifyContent={"center"} alignItems={"center"} height={180}>
          <Typography variant={"h3-bold"}>You must at least have 2 features to create a table</Typography>
        </Grid>
      ) : (
        <TableContainer component={Paper} className={styles.table}>
          <Table sx={{ minWidth: "100%" }} aria-label="Weight-table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={styles.tableCell}>
                  <Typography variant="h3-bold" align="center">
                    {Object.values(permutations)[0]?.[0].x.feature}
                  </Typography>
                </TableCell>

                {Object.values(permutations)[0]?.map((perm) => (
                  <TableCell align="center" className={styles.tableCell}>
                    <Grid container gap={2} justifyContent={"center"} display={"flex"} wrap="nowrap" px={1}>
                      {perm.y.map((condition) => (
                        <Grid item>
                          <Typography variant="label" noWrap>
                            {condition.feature}
                          </Typography>
                          <Typography variant="h3-bold" noWrap>
                            {matchFile(condition.value).isMatching
                              ? condition.operator +
                                " " +
                                values?.criteria_files?.find(
                                  (file: { uuid: string; name: string }) =>
                                    file.uuid === matchFile(condition.value).fileId,
                                )?.name
                              : getOperatorKeyByValue(condition.operator) +
                                " " +
                                (condition.operator === "between"
                                  ? `[${valueParser(condition.value, condition.type)}:${valueParser(condition.secondValue ?? "", condition.type) ?? ""}]`
                                  : !["is null", "not null"].includes(condition.operator)
                                    ? valueParser(condition.value, condition.type)
                                    : "")}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* Body */}
              {Object.values(permutations).map((row) => (
                <TableRow>
                  <TableCell align="center" className={styles.tableCell}>
                    <Typography variant="h3-bold" align="center" noWrap>
                      {matchFile(row?.[0].x.value).isMatching
                        ? getOperatorKeyByValue(row?.[0].x.operator) +
                          " " +
                          values?.criteria_files?.find(
                            (file: { uuid: string; name: string }) => file.uuid === matchFile(row?.[0].x.value).fileId,
                          )?.name
                        : getOperatorKeyByValue(row?.[0].x.operator) +
                          " " +
                          (row?.[0].x.operator === "between"
                            ? `[${valueParser(row?.[0].x.value, row?.[0].x.type)}:${valueParser(row?.[0].x.secondValue ?? "", row?.[0].x.type) ?? ""}]`
                            : !["is null", "not null"].includes(row?.[0].x.operator)
                              ? valueParser(row?.[0].x.value, row?.[0].x.type)
                              : "")}
                    </Typography>
                  </TableCell>

                  {row?.map((_) => {
                    const generatedIndex = generator.next().value
                    return (
                      <TableCell align="center" className={styles.tableCell}>
                        {mode !== "read" ? (
                          <InputText
                            fullWidth
                            type="number"
                            id={`results.${generatedIndex}`}
                            hideDescription
                            value={values.results[generatedIndex]}
                            handleChange={handleChange}
                            placeholder={"Weight"}
                          />
                        ) : (
                          <Typography variant="h3-bold" align="center">
                            {values.results[generatedIndex]}
                          </Typography>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  )
}
