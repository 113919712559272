import React, { Fragment, useCallback, useRef, useState } from "react"

import { useDropzone } from "react-dropzone"
import { useMutation } from "react-query"
import { useParams } from "react-router-dom"

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { Grid } from "@mui/material"
import { Button, InputText, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { useFormikContext } from "formik"

import { UploadFileBlock } from "../../components/UploadFileBlock"
import { KonanAPI } from "../../services/KonanAPI"
import { DataFile } from "../../types/generated/api/DataFile"
import { multiValuesOperators, operatorsBasedOnFeatureType } from "../../utils/conditionHelpers"
import { dropZoneCSVExtensionValidator } from "../../utils/genericHelpers"
import { matchFile } from "../../utils/rulesetHelpers"
import { DataBlock } from "../Ruleset/components/RuleCard"
import { RemoveFromBuckets, createScoreTableBucketsFromResultsArray } from "./helpers"
import { ConditionProps, InputWithReadModeProps, ScoreTableFormik } from "./interfaces"

/**
 * Renders an input component with read mode functionality.
 *
 * @param props - The component props.
 * @param id - The ID of the input.
 * @param value - The value of the input.
 * @param isReadMode - (Optional)Determines if the input is in read mode. Defaults to false.
 * @param isSelect - (Optional) Determines if the input is a select input. Defaults to false.
 * @param placeHolder - (Optional) The placeholder text for the input.
 * @param enableUpload - (Optional) Determines if file upload is enabled. Defaults to false.
 * @param handleChange - (Optional) The event handler for input change.
 * @param handleBlur - (Optional) The event handler for input blur.
 * @param isDisabled - (Optional) Determines if the input is disabled. Defaults to false.
 * @returns The rendered input component.
 */
function InputWithReadMode(props: InputWithReadModeProps): React.ReactElement {
  const {
    id,
    isReadMode = false,
    isSelect = false,
    type,
    value,
    placeHolder,
    enableUpload = false,
    handleChange,
    handleBlur,
    isDisabled = false,
    options,
    fileUploadHelpers,
  } = props
  const { id: projectId } = useParams<{ id: string }>()

  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const abortControllerRef = useRef<AbortController | null>(null)

  const {
    isLoading: isCsvUploading,
    mutateAsync: uploadCSVAsyncMutation,
    reset,
  } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      abortControllerRef.current = new AbortController()
      return KonanAPI.uploadDataFile({
        project_uuid: projectId as string,
        file: file,
        signal: abortControllerRef.current.signal,
        type: DataFile.type.CONDITION_LIST,
      })
    },
    {
      mutationKey: "uploadCSV",
      onSuccess: async (response) => {
        fileUploadHelpers?.handleUploadFileSuccessful({
          name: response?.name,
          uuid: response?.uuid,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (response: any) => {
        if (response?.response?.data?.details) {
          NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
            snackBarVariant: "negative",
          })
        } else if (response?.response?.data?.data_file) {
          NotificationUtils.toast(response?.response?.data?.data_file[0], {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  // handle close dropzone
  const handleCloseAndAbort = useCallback(() => {
    abortControllerRef.current?.abort()
    reset()
    setAcceptedFiles([])
  }, [reset])

  const { open: openDropZone, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted: async (files) => {
      setAcceptedFiles([...acceptedFiles, ...files])
      await uploadCSVAsyncMutation(files[0])
    },
    accept: {
      "text/*": [".csv"],
    },
    validator: dropZoneCSVExtensionValidator,
  })

  /**
   * indicator for displaying the file block that contains the file name used in condition
   */
  const shouldDisplayFileBlock = Boolean((matchFile(value).isMatching || isCsvUploading) && enableUpload && !isReadMode)

  return shouldDisplayFileBlock ? (
    <UploadFileBlock
      onDelete={() => {
        reset()
        fileUploadHelpers?.handleDeleteFile(matchFile(value).fileId as string)
      }}
      fileName={
        fileUploadHelpers?.criteria_files?.find(
          (file: { uuid: string; name: string }) => file.uuid === matchFile(value).fileId,
        )?.name ?? ""
      }
      isUploading={isCsvUploading}
      handleCancel={handleCloseAndAbort}
      editMode={!isReadMode}
      createMode={!isReadMode}
      isDisabled={isCsvUploading}
    />
  ) : isReadMode ? (
    <DataBlock
      value={
        matchFile(value).isMatching
          ? (fileUploadHelpers?.criteria_files?.find(
              (file: { uuid: string; name: string }) => file.uuid === matchFile(value).fileId,
            )?.name ?? "")
          : value
      }
    />
  ) : (
    <InputText
      id={id}
      type={type}
      hideDescription
      isSelect={isSelect}
      options={options}
      placeholder={placeHolder}
      value={value}
      handleChange={handleChange}
      handleBlur={handleBlur}
      disabled={isDisabled}
      endAdornment={
        enableUpload && !isSelect ? (
          <Fragment>
            {/* must add this input to make it work on safari */}
            <input {...getInputProps()} />
            <FileUploadOutlinedIcon
              onClick={openDropZone}
              style={{ color: "var(--grayscale-text-2)" }}
              fontSize="small"
            />
          </Fragment>
        ) : undefined
      }
      fullWidth
      menuProps={{ menuMaxContent: true }}
    />
  )
}

export function Condition(props: Readonly<ConditionProps>): React.ReactElement {
  const { mode, configIndex, conditionIndex } = props

  const { values, setFieldValue, handleChange, handleBlur, isSubmitting } = useFormikContext<ScoreTableFormik>()

  const conditionObject = values.criteria[configIndex][conditionIndex]
  const conditionObjectId = `criteria[${configIndex}][${conditionIndex}]`

  const types = ["string", "number", "true", "false", "null"]

  const handleDeleteCriteria = (): void => {
    const buckets = createScoreTableBucketsFromResultsArray(values.results, values.criteria)

    RemoveFromBuckets(buckets, configIndex, conditionIndex)

    setFieldValue("results", buckets.flat(values.criteria.length))

    const newRules = values.criteria[configIndex]?.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (_: any, idx: number) => idx !== conditionIndex,
    )

    setFieldValue(`criteria[${configIndex}]`, newRules)
  }

  return (
    <Grid container display={"flex"} gap={1}>
      <Grid
        item
        xs={
          multiValuesOperators.includes(conditionObject.operator)
            ? 4
            : !["is null", "not null"].includes(conditionObject.operator)
              ? 2
              : true
        }
      >
        <InputWithReadMode
          id={`${conditionObjectId}.operator`}
          isReadMode={mode === "read"}
          isSelect
          value={conditionObject.operator}
          handleChange={(e) => {
            ;["is null", "not null"].includes(e.target.value) && setFieldValue(`${conditionObjectId}.type`, "null")
            handleChange(e)
          }}
          handleBlur={handleBlur}
          isDisabled={isSubmitting}
          options={operatorsBasedOnFeatureType.UNDEFINED}
        />
      </Grid>

      {!multiValuesOperators.includes(conditionObject.operator) &&
        !["is null", "not null"].includes(conditionObject.operator) && (
          <Grid item xs={["string", "number"].includes(conditionObject.type) ? 2 : true}>
            <InputWithReadMode
              id={`${conditionObjectId}.type`}
              isReadMode={mode === "read"}
              isSelect
              value={conditionObject.type}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isDisabled={isSubmitting}
              options={types}
            />
          </Grid>
        )}

      {["string", "number"].includes(conditionObject.type) &&
        !["is null", "not null"].includes(conditionObject.operator) && (
          <Fragment>
            <Grid item xs>
              <InputWithReadMode
                id={`${conditionObjectId}.value`}
                isReadMode={mode === "read"}
                value={conditionObject.value}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isDisabled={isSubmitting}
                type={conditionObject.type === "number" ? "number" : undefined}
                enableUpload={multiValuesOperators.includes(conditionObject.operator)}
                fileUploadHelpers={{
                  criteria_files: values.criteria_files,
                  handleDeleteFile: (uuid: string) => {
                    setFieldValue(
                      `criteria_files`,
                      values.criteria_files.filter((file: { uuid: string; name: string }) => file.uuid !== uuid),
                    )

                    setFieldValue(`criteria[${configIndex}][${conditionIndex}].value`, ``)
                  },
                  handleUploadFileSuccessful: (file) => {
                    setFieldValue(`criteria_files`, [...values.criteria_files, file])

                    setFieldValue(`criteria[${configIndex}][${conditionIndex}].value`, `$file<${file.uuid}>`)
                  },
                }}
              />
            </Grid>

            {conditionObject.operator === "between" && (
              <Fragment>
                <Grid item>
                  <DataBlock value={"AND"} />
                </Grid>

                <Grid item xs>
                  <InputWithReadMode
                    id={`${conditionObjectId}.secondValue`}
                    isReadMode={mode === "read"}
                    type="number"
                    value={conditionObject.secondValue as string}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isDisabled={isSubmitting}
                  />
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}

      {mode !== "read" && (
        <Grid item>
          <Button
            variant="dangerous"
            size="regular"
            onClick={handleDeleteCriteria}
            disabled={isSubmitting || values.criteria[configIndex].length <= 1}
            tooltip={values.criteria[configIndex].length <= 1 ? "Must have at least 1 condition per bucket" : undefined}
          >
            <RemoveCircleOutlineIcon fontSize="small" />
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
