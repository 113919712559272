import React from "react"

import ApiOutlinedIcon from "@mui/icons-material/ApiOutlined"
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined"
import { Grid } from "@mui/material"
import { Tab, Tabs } from "@synapse-analytics/synapse-ui"

import { useHandlePageQueryParam } from "../../hooks/useHandlePageQueryParam"
import { ApiMonitor } from "./components/ApiMonitor"
import { DataMonitor } from "./components/DataMonitor/DataMonitor"

/**
 * Live Monitoring component
 * home to requests and data stats
 * @return {React.ReactElement}
 */
export function LiveMonitoring(): React.ReactElement {
  const [value, setTabValue] = useHandlePageQueryParam(["Requests", "Data-Statistics"], "Requests")
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} pt={2}>
        <Tabs value={value} className="base-tabs">
          <Tab
            icon={() => <ApiOutlinedIcon fontSize="small" />}
            label="Requests"
            value={"Requests"}
            selected={value === "Requests"}
            onClick={() => setTabValue("Requests")}
          />
          <Tab
            icon={() => <LeaderboardOutlinedIcon fontSize="small" />}
            label="Data Statistics"
            value="Data-Statistics"
            selected={value === "Data-Statistics"}
            onClick={() => setTabValue("Data-Statistics")}
          />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        {value === "Requests" ? <ApiMonitor /> : <DataMonitor />}
      </Grid>
    </Grid>
  )
}
