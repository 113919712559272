/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PredictionReview = {
  readonly uuid: string
  readonly created_by: string
  created_at?: string
  /**
   * * `REVIEWED` - Reviewed
   * * `REVOKED` - Revoked
   */
  status: PredictionReview.status
}
export namespace PredictionReview {
  /**
   * * `REVIEWED` - Reviewed
   * * `REVOKED` - Revoked
   */
  export enum status {
    REVIEWED = "REVIEWED",
    REVOKED = "REVOKED",
  }
}
