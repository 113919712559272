import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { CircularProgress, Grid } from "@mui/material"
import {
  Button,
  InputChangeEvent,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Select,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikErrors, FormikProps } from "formik"
import { v4 as uuidv4 } from "uuid"

import { queryClient } from "../../.."
import { DataBlock } from "../../../features/Ruleset/components/RuleCard"
import { ArrowAdornment } from "../../../features/Scorecards/Condition"
import { useDebounce } from "../../../hooks/useDebounce"
import { KonanAPI } from "../../../services/KonanAPI"
import { SchemaFeature } from "../../../types/custom/workflows"
import { Label } from "../../../types/generated/api/Label"
import { PaginatedLabelList } from "../../../types/generated/api/PaginatedLabelList"
import { WorkflowSchemaFeatureRequest } from "../../../types/generated/api/WorkflowSchemaFeatureRequest"
import { Rule } from "../DecisionNodeDialog"

import styles from "./Condition.module.scss"

type Props = {
  readMode: boolean
  ruleInReadMode: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflowFormik: FormikProps<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any
  index: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any
  disableRule: boolean
  mode: { create: boolean; edit: boolean }
  handleRemoveRule: () => void
}

export function Condition({
  readMode,
  ruleInReadMode,
  index,
  item,
  disableRule,
  handleRemoveRule,
  mode,
  formik,
  workflowFormik,
}: Readonly<Props>): React.ReactElement {
  const { id: projectId } = useParams<{ id: string }>()

  const [filteredFeatures, setFilteredFeatures] = useState<Array<{ uuid: string; name: string }>>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [type, setType] = useState<string>("TEXT")

  const errorFormikRules = formik?.errors?.ruleset?.rules
  const touchedFormikRules = formik?.touched?.ruleset?.rules

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>()

  const pageSize = 20
  const [page, setPage] = useState<number>(0)
  const [searchKey, setSearchKey] = useState<string | null>(null)

  const [labels, setLabels] = useState<Label[]>([])

  const CreateLabelMutation = useMutation<
    AxiosResponse<Label>,
    AxiosError,
    {
      project_uuid: string
      name: string
    }
  >(KonanAPI.CreateLabel, {
    onSuccess: async ({ data }) => {
      NotificationUtils.toast("Label created successfully", { snackBarVariant: "positive" })

      formik.setFieldValue(`ruleset.rules.${[index]}.label_id`, data.uuid)
      formik.setFieldValue(`ruleset.rules.${[index]}.returnValue`, data.name)

      await queryClient.invalidateQueries("label-list")
    },
    onError: async () => {
      NotificationUtils.toast("Label creation failed", { snackBarVariant: "negative" })
    },
  })

  // fetch uploaded training data headers (columns)
  const { data: labelList, isLoading: isLabelListLoading } = useQuery<AxiosResponse<PaginatedLabelList>, AxiosError>(
    ["label-list", projectId, page, pageSize, searchKey],
    () =>
      KonanAPI.RetrieveLabels({
        project_uuid: projectId as string,
        page: page + 1,
        pageSize: pageSize,
        search: searchKey,
      }),
    {
      onSuccess: (response) => {
        // filtering out already existing labels in the list
        const tempLabels =
          response.data.results?.filter((item: Label) => !labels.find((label: Label) => label.uuid === item.uuid)) ?? []

        setLabels([...labels, ...tempLabels])

        response.data.results?.length === 1 &&
          !["", null, undefined].includes(searchKey) &&
          // setting name (automatically) ONLY if the user writes the full name correctly
          response.data.results[0].name === searchKey &&
          formik.setFieldValue(`ruleset.rules.${[index]}.label_id`, response.data.results[0].uuid)
      },
      enabled: !ruleInReadMode,
      refetchOnMount: true,
    },
  )

  // fetch label if first mount
  const { isLoading: isSingleLabelLoading } = useQuery<AxiosResponse<Label>, AxiosError>(
    ["label", formik?.values.ruleset.rules[index]?.label_id],
    () =>
      KonanAPI.FetchLabel({
        project_uuid: projectId as string,
        label_uuid: formik?.values.ruleset.rules[index]?.label_id,
      }),
    {
      onSuccess: (response) => {
        formik.setFieldValue(`ruleset.rules.${[index]}.returnValue`, response.data.name)
      },
      enabled: ruleInReadMode,
      refetchOnMount: true,
    },
  )

  const optionsWithValues = useMemo(() => {
    return labels.map((label) => {
      return { label: label.name, value: label.uuid }
    })
  }, [labels])

  const adjustedFeatures = useMemo(() => {
    return [
      ...(workflowFormik?.values?.features
        ?.filter((item: SchemaFeature) => item.type === WorkflowSchemaFeatureRequest.type.NUMBER)
        .map((item: SchemaFeature) => {
          return { name: item.name, uuid: item.id }
        }) || []),
      ...(workflowFormik?.values?.computedFeatures?.map((item: { uuid: string; value: string }) => {
        return { name: item.value, uuid: item.uuid }
      }) || []),
    ]
  }, [workflowFormik?.values?.features, workflowFormik?.values?.computedFeatures])

  const debouncedOnChange = useDebounce((): void => {
    const label = formik?.values.ruleset.rules[index]?.returnValue ?? ""

    setPage(0)
    setSearchKey(label === "" ? null : label)
  }, 400)

  const debouncedFilterChange = useDebounce((): void => {
    setFilteredFeatures(
      adjustedFeatures?.filter((item: { uuid: string; name: string }) =>
        item.name.includes(formik.values.ruleset.rules[index]?.feature),
      ),
    )
    setAnchorEl(inputRef?.current)
    setTimeout(() => {
      inputRef?.current?.focus()
    }, 100)
  }, 400)

  useEffect(() => {
    if (adjustedFeatures?.length > 0) {
      setFilteredFeatures(adjustedFeatures)
    }
  }, [adjustedFeatures])

  useEffect(() => {
    if (adjustedFeatures && inputRef?.current && workflowFormik?.values?.features?.length > 0) {
      inputRef?.current?.focus()
    }
  }, [inputRef, adjustedFeatures, workflowFormik?.values?.features])

  return (
    <Grid container item className={styles.conditionContainer} spacing={1} key={item.id}>
      {/* Feature */}
      <Grid item xs={3} mt={readMode ? 1 : 0}>
        {ruleInReadMode ? (
          <DataBlock value={formik?.values.ruleset.rules[index]?.feature} />
        ) : (
          <Fragment>
            <div>
              <InputText
                anchoringRef={inputRef}
                id={`ruleset.rules[${index}].feature`}
                placeholder="Feature"
                key={formik?.values.ruleset.rules[index]?.id}
                value={formik?.values.ruleset.rules[index]?.feature}
                handleChange={(e: InputChangeEvent) => {
                  formik?.handleChange(e)
                  debouncedFilterChange()
                }}
                hideDescription
                endAdornment={
                  adjustedFeatures.length > 0 ? (
                    <ArrowAdornment
                      anchorEl={anchorEl}
                      onClick={() => setAnchorEl(!!anchorEl ? null : inputRef?.current)}
                    />
                  ) : undefined
                }
                // error={
                //   touchedFormikRules &&
                //   errorFormikRules &&
                //   touchedFormikRules[index]?.feature &&
                //   (errorFormikRules[index] as FormikErrors<Rule>)?.feature &&
                //   Boolean((errorFormikRules[index] as FormikErrors<Rule>)?.feature) &&
                //   (errorFormikRules[index] as FormikErrors<Rule>)?.feature
                // }
                handleBlur={formik?.handleBlur}
                disabled={formik?.isSubmitting}
                fullWidth
              />
            </div>
            {(workflowFormik?.values?.features?.filter(
              (item: SchemaFeature) => item.type === WorkflowSchemaFeatureRequest.type.NUMBER,
            )?.length > 0 ||
              workflowFormik?.values?.computedFeatures.length > 0) && (
              <Menu
                menuMaxContent
                anchorEl={anchorEl}
                key={workflowFormik?.values?.features?.length}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {filteredFeatures?.length > 0
                  ? filteredFeatures?.map((item: { uuid: string; name: string }) => (
                      <MenuItem
                        key={`${item.uuid}-${workflowFormik?.values?.features?.length}`}
                        value={item.name}
                        onClick={() => {
                          formik.setFieldValue(`ruleset.rules[${index}].feature`, item.name)
                          setAnchorEl(null)
                          // Resetting to rerender element to display correct data
                          // since key of input isnt the same as key of it's formik value
                          setTimeout(() => {
                            setAnchorEl(null)
                          }, 100)
                        }}
                      >
                        <Typography variant="a">{item.name}</Typography>
                      </MenuItem>
                    ))
                  : filteredFeatures?.length === 0 &&
                    formik?.values?.ruleset?.rules[index]?.feature && (
                      <MenuItem value={"new feature"}>
                        <Grid container item xs={12} justifyContent="space-between">
                          <Typography style={{ marginRight: "4px" }} variant="a">
                            Add this feature to schema
                          </Typography>
                          <Select
                            hideDescription
                            id={"type"}
                            type="text"
                            value={type}
                            handleChange={(e) => setType(e?.target?.value)}
                            fullWidth
                            options={["NUMBER", "TEXT", "BOOLEAN", "UNDEFINED"]}
                          />
                          <Button
                            style={{ marginTop: "5px" }}
                            onClick={() => {
                              const newFeat = {
                                name: formik?.values?.ruleset?.rules[index]?.feature?.replace(/\s/g, ""),
                                type,
                                is_required: true,
                                id: uuidv4(),
                                source: "workflow",
                                new: true,
                              }
                              workflowFormik?.setFieldValue("features", [
                                ...(workflowFormik?.values?.features ?? []),
                                newFeat,
                              ])

                              setAnchorEl(null)
                            }}
                            size="small"
                            variant="primary"
                          >
                            Add
                          </Button>
                        </Grid>
                      </MenuItem>
                    )}
              </Menu>
            )}
          </Fragment>
        )}
      </Grid>

      <Grid item mt={readMode ? 1 : mode.create ? 0.6 : 0} xs={2.5}>
        <DataBlock value={item.operator} />
      </Grid>

      {item.operator === "between" ? (
        <Grid item xs={3.5} container>
          <Grid item xs md={4} mt={readMode ? 1 : 0}>
            {ruleInReadMode ? (
              <DataBlock value={formik?.values.ruleset.rules[index]?.value} />
            ) : (
              <InputText
                key={formik?.values.ruleset.rules[index]?.id}
                handleChange={formik?.handleChange}
                value={formik?.values.ruleset.rules[index]?.value}
                id={`ruleset.rules[${index}].value`}
                hideDescription
                disabled={disableRule}
                error={
                  touchedFormikRules &&
                  errorFormikRules &&
                  touchedFormikRules[index]?.value &&
                  (errorFormikRules[index] as FormikErrors<Rule>)?.value &&
                  Boolean((errorFormikRules[index] as FormikErrors<Rule>)?.value) &&
                  (errorFormikRules[index] as FormikErrors<Rule>)?.value
                }
                placeholder={"Value"}
                type="number"
                fullWidth
              />
            )}
          </Grid>

          <Grid item xs={0.5} mt={readMode && 1} mx={0.7} className="and-block">
            <Typography variant="h3-bold" variantColor={1} color="neutral">
              And
            </Typography>
          </Grid>

          <Grid item xs md={4} mt={readMode ? 1 : 0}>
            {ruleInReadMode ? (
              <DataBlock value={formik?.values.ruleset.rules[index]?.secondValue ?? ""} />
            ) : (
              <InputText
                type="number"
                hideDescription
                handleChange={formik?.handleChange}
                key={formik?.values.ruleset.rules[index]?.id}
                value={formik?.values.ruleset.rules[index]?.secondValue}
                id={`ruleset.rules[${index}].secondValue`}
                disabled={disableRule}
                placeholder={"Value"}
                fullWidth
                error={
                  touchedFormikRules &&
                  errorFormikRules &&
                  touchedFormikRules[index]?.secondValue &&
                  (errorFormikRules[index] as FormikErrors<Rule>)?.secondValue &&
                  Boolean((errorFormikRules[index] as FormikErrors<Rule>)?.secondValue) &&
                  (errorFormikRules[index] as FormikErrors<Rule>)?.secondValue
                }
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={3.5} mt={readMode ? 1 : 0}>
          {ruleInReadMode ? (
            <DataBlock value={formik?.values.ruleset.rules[index]?.value} />
          ) : (
            <InputText
              type="number"
              handleChange={formik?.handleChange}
              hideDescription
              key={formik?.values.ruleset.rules[index]?.id}
              value={formik?.values.ruleset.rules[index]?.value}
              id={`ruleset.rules[${index}].value`}
              disabled={disableRule}
              placeholder={"Value"}
              fullWidth
              error={
                touchedFormikRules &&
                errorFormikRules &&
                touchedFormikRules[index]?.value &&
                (errorFormikRules[index] as FormikErrors<Rule>)?.value &&
                Boolean((errorFormikRules[index] as FormikErrors<Rule>)?.value) &&
                (errorFormikRules[index] as FormikErrors<Rule>)?.value
              }
            />
          )}
        </Grid>
      )}

      <Grid item display={"flex"} alignSelf={"self-end"}>
        <Typography variant="a">{"="}</Typography>
      </Grid>

      {/* Return label */}
      <Grid
        item
        // TODO:: fix when refactoring condition files
        md={item.operator?.toLowerCase() === "between" && (mode.create || mode.edit) ? 2.1 : 2.7}
        xs={2}
        mt={readMode ? 1 : 0}
      >
        {ruleInReadMode ? (
          <DataBlock value={formik?.values.ruleset.rules[index]?.returnValue} isLoading={isSingleLabelLoading} />
        ) : (
          <InputText
            fullWidth
            handleChange={(e) => {
              formik?.handleChange(e)
              debouncedOnChange()

              const target = e.target

              e._reactName === "onClick" && formik.setFieldValue(`ruleset.rules.${[index]}.label_id`, target.value)
            }}
            key={formik?.values.ruleset.rules[index]?.id}
            value={formik?.values.ruleset.rules[index]?.returnValue}
            id={`ruleset.rules[${index}].returnValue`}
            disabled={disableRule}
            placeholder={"Return label"}
            hideDescription
            optionsWithValues={
              optionsWithValues.length === 0
                ? [{ label: "No entries found!", value: "default", disabled: true }]
                : optionsWithValues
            }
            loaderComponent={
              <Grid container item xs={12} px={1} py={0.5}>
                <CircularProgress size={14} />
              </Grid>
            }
            loading={CreateLabelMutation.isLoading || isSingleLabelLoading}
            noEntriesProps={{
              isHidden: !!searchKey,
              handleAddNewValue: async () => {
                await CreateLabelMutation.mutateAsync({
                  project_uuid: projectId as string,
                  name: formik?.values.ruleset.rules[index]?.returnValue as string,
                }).then((res) => {
                  formik.setFieldValue(`ruleset.rules.${[index]}.returnValue`, res.data.uuid)
                  formik.setFieldValue(`ruleset.rules.${[index]}.label_id`, target.value)
                })
              },
            }}
            menuProps={{
              handleScrollToEnd: () => {
                labelList?.data.next && setPage(page + 1)
              },
              scrollThreshold: 10,
              menuMaxContent: true,
            }}
            isLoadingOptions={isLabelListLoading}
            error={
              touchedFormikRules &&
              errorFormikRules &&
              touchedFormikRules[index]?.returnValue &&
              (errorFormikRules[index] as FormikErrors<Rule>)?.label_id &&
              Boolean((errorFormikRules[index] as FormikErrors<Rule>)?.label_id) &&
              (errorFormikRules[index] as FormikErrors<Rule>)?.label_id
            }
          />
        )}
      </Grid>

      {/* Remove Rule Button*/}
      {item.operator?.toLowerCase() === "between" && (mode.create || mode.edit) && (
        <Grid item mt={mode.edit ? 2 : 1.5}>
          <Button variant="dangerous" disabled={disableRule} size="small" onClick={handleRemoveRule}>
            <RemoveCircleOutlineIcon fontSize="inherit" />
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
