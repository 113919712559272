import React, { ChangeEvent, Fragment, useEffect, useState } from "react"

import { useSelector } from "react-redux"

import * as Yup from "yup"
import { CircularProgress, Dialog, DialogActions, DialogContent, Grid, useMediaQuery, useTheme } from "@mui/material"
import { Button, Checkbox, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { useFormik } from "formik"

import { InfoBlock } from "../../components/containers/InfoBlock"
import { RootState } from "../../store/ReduxStore"
import { ProductEnum } from "../../types/generated/authentication/ProductEnum"
import { RolePermission } from "../../types/generated/authentication/RolePermission"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { getRoleDisplayName } from "../../utils/rolesHelpers"
import { RoleDialogProps } from "./Interfaces"

// should be refactored to return a masonry compatible api
const RenderPermissions = function RenderPermissions(
  permissions: object,
  checkPermissions: string[],
  setCheckedPermissions: (permission: string[]) => void,
  isAdmin = false,
  isReadMode = false,
): React.ReactElement {
  return (
    <Fragment>
      {Object.entries({
        ...permissions,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).map((permission: any) => {
        return (
          <Grid container item xs={12} key={permission[1].label} spacing={1}>
            <Grid item>
              <Typography variant={"a"} variantColor={2}>
                {permission[1].label}
              </Typography>
            </Grid>

            {/* TODO:: Open a ticket for handling parent permissions */}
            {/* <Grid item xs={12}>
              <Checkbox
                label={permission[1].label}
                name={permission[1].label}
                value={permission[0]}
                checked={checkPermissions.includes(permission[0])}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target?.checked === true) {
                    setCheckedPermissions([...checkPermissions, permission[0]])
                  } else {
                    setCheckedPermissions(checkPermissions.filter((perm) => perm !== permission[0]))
                  }
                }}
              />
            </Grid> */}

            {permission[1].children && (
              <Fragment>
                {Object.entries(permission[1].children)
                  .sort((a, b) => {
                    // if both have children or both dont => do nothing
                    // if a has child while b doesnt => b goes first & V.V.
                    return (a[1]?.children && b[1]?.children) || (!a[1]?.children && !b[1]?.children)
                      ? 0
                      : a[1]?.children
                        ? 1
                        : -1
                  })
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  .map((item: any) => (
                    <Fragment>
                      {!item[1].children ? (
                        <Grid item xs={12} key={item[1].label}>
                          <Checkbox
                            label={item[1].label}
                            name={item[1].label}
                            value={item[0]}
                            checked={isAdmin || checkPermissions.includes(item[0])}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target?.checked === true) {
                                setCheckedPermissions([...checkPermissions, item[0]])
                              } else {
                                setCheckedPermissions(checkPermissions.filter((perm) => perm !== item[0]))
                              }
                            }}
                            readOnly={isReadMode}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} mt={1}>
                          {RenderPermissions(
                            { [item[0]]: { ...item[1] } },
                            checkPermissions,
                            setCheckedPermissions,
                            isAdmin,
                            isReadMode,
                          )}
                        </Grid>
                      )}
                    </Fragment>
                  ))}
              </Fragment>
            )}
          </Grid>
        )
      })}
    </Fragment>
  )
}

// Yup validation schema object
const validationSchema = Yup.object({
  roleName: Yup.string().required("Role name is required"),
  description: Yup.string(),
})

/**
 * Role creation/edit dialog
 * @param {boolean} isOpen
 * @param {Function} onClose
 * @param {boolean} isEdit
 * @returns {React.ReactElement}
 */
export function RoleCreationDialog(props: Readonly<RoleDialogProps>): React.ReactElement {
  const { isOpen, onClose, role, onAccept, isViewMode } = props

  const permissions = Auth.getPermissions()

  const sidPermissions = useSelector((state: RootState) => state.permissions.sidPermissions)
  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)
  const konanPermissions = useSelector((state: RootState) => state.permissions.konanPermissions)
  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const MuiTheme = useTheme()

  const [isReadMode, setIsReadMode] = useState<boolean>(isViewMode ?? false)

  const formik = useFormik({
    initialValues: {
      roleName: role?.name ?? "",
      description: role?.description ?? "",
      konanCheckedPermissions: [],
      sidCheckedPermissions: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await onAccept?.(values.roleName, values.description, [
        ...values.konanCheckedPermissions.map((permission: string): RolePermission => {
          return { permission: parseInt(permission), product: ProductEnum.KONAN }
        }),
        ...values.sidCheckedPermissions.map((permission: string): RolePermission => {
          return { permission: parseInt(permission), product: ProductEnum.SYNAPSE_ID }
        }),
      ])

      resetForm({})
    },
  })

  // Set existing Permissions on mount
  useEffect(() => {
    if (role?.name !== "USER") {
      if (role?.permissions) {
        const tempKonanPermissions: string[] = []
        const tempSIDPermissions: string[] = []

        for (const permission of role.permissions) {
          if (permission.product === ProductEnum.KONAN) {
            tempKonanPermissions.push(permission.permission.toString())
          } else {
            tempSIDPermissions.push(permission.permission.toString())
          }
        }

        formik.setFieldValue("konanCheckedPermissions", [...tempKonanPermissions])
        formik.setFieldValue("sidCheckedPermissions", [...tempSIDPermissions])
      }
    } else {
      formik.setFieldValue(
        "sidCheckedPermissions",
        Object.values(flattenedSidPermissions)
          .filter((permission) => permission?.default)
          .map((permission) => permission?.key),
      )
      formik.setFieldValue(
        "konanCheckedPermissions",
        Object.values(flattenedKonanPermissions)
          .filter((permission) => permission?.default)
          .map((permission) => permission?.key),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldExpandRoleName = isReadMode && formik.values.description.length === 0

  const isAdmin = role?.name === "OWNER"

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose()
        formik.resetForm({})
      }}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("sm"))}
      maxWidth="sm"
      PaperProps={{
        style: {
          position: "static",
        },
      }}
    >
      <DialogContent className="dialog-content-base">
        <Grid container gap={3}>
          <Grid item xs={12}>
            <Typography variant={"h1-bold"}>{role ? (isReadMode ? "View" : "Edit") : "New"} Role</Typography>
          </Grid>

          <Grid container item xs={12} gap={1} wrap="nowrap">
            <Grid xs={shouldExpandRoleName ? 12 : 4}>
              {isReadMode ? (
                <InfoBlock text={getRoleDisplayName(formik.values.roleName)} title={`Role name`} />
              ) : (
                <InputText
                  label={`Role name`}
                  id="roleName"
                  placeholder={`Risk Officer`}
                  description="Must be unique"
                  value={getRoleDisplayName(formik.values.roleName)}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  fullWidth
                  required
                />
              )}
            </Grid>

            {!shouldExpandRoleName && (
              <Grid xs={8}>
                {isReadMode ? (
                  <InfoBlock text={formik.values.description} title={`Description`} />
                ) : (
                  <InputText
                    label={`Description`}
                    id="description"
                    placeholder={`Has all permissions`}
                    value={formik.values.description}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    fullWidth
                  />
                )}
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              {isReadMode ? (
                <Typography variant={"label"} variantColor={2}>
                  Permissions
                </Typography>
              ) : (
                <Typography variant={"h3-bold"}>Choose Permissions</Typography>
              )}
            </Grid>

            <Grid container className="scrollbar" style={{ flex: "1 1 auto", overflowY: "auto", maxHeight: "300px" }}>
              <Grid item xs={6} spacing={1} p={1}>
                {RenderPermissions(
                  sidPermissions?.["0"].children,
                  formik.values.sidCheckedPermissions,
                  (permissions) => {
                    !isReadMode && formik.setFieldValue("sidCheckedPermissions", permissions)
                  },
                  isAdmin,
                  isReadMode,
                )}
              </Grid>

              <Grid item xs={6} spacing={1} p={1}>
                {RenderPermissions(
                  konanPermissions,
                  formik.values.konanCheckedPermissions,
                  (permissions) => {
                    !isReadMode && formik.setFieldValue("konanCheckedPermissions", permissions)
                  },
                  isAdmin,
                  isReadMode,
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Button
          variant="secondary"
          onClick={() => {
            onClose()
            formik.resetForm({})
          }}
        >
          Cancel
        </Button>
        {isReadMode && isPermitted("Update roles", permissions.synapse_id, flattenedSidPermissions)
          ? !["OWNER", "USER"].includes(role?.name ?? "") && (
              <Button onClick={() => setIsReadMode(false)} variant="primary">
                Edit
              </Button>
            )
          : !isReadMode &&
            (isPermitted("Update roles", permissions.synapse_id, flattenedSidPermissions) ||
              isPermitted("Create roles", permissions.synapse_id, flattenedSidPermissions)) && (
              <Button
                onClick={formik.submitForm}
                disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                variant="primary"
              >
                {formik.isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
              </Button>
            )}
      </DialogActions>
    </Dialog>
  )
}
