import { Serie } from "@nivo/line"
import moment from "moment"

import { EkycStatsByTimeBarData } from "../types/custom/ekyc"
import {
  CategoricalType,
  CustomSwitchedModels,
  DatetimeType,
  DiscreteOrContinuousType,
  ModelAverageCpuUsage,
  Operators,
  SummaryByDayPredictions,
  TaskResult,
  TrainingAndRetraining,
  UnknownType,
  VersioningComponents,
} from "../types/custom/projects"
import { AutoMLTrainingJob } from "../types/generated/api/AutoMLTrainingJob"
import { DeploymentUsage } from "../types/generated/api/DeploymentUsage"
import { ListModelStateSwitch } from "../types/generated/api/ListModelStateSwitch"
import { Model } from "../types/generated/api/Model"
import { ModelListCreate } from "../types/generated/api/ModelListCreate"
import { RetrainingJob } from "../types/generated/api/RetrainingJob"
import { SummaryByDay } from "../types/generated/api/SummaryByDay"
import { TrainingJob } from "../types/generated/api/TrainingJob"
import { WorkflowVersionList } from "../types/generated/api/WorkflowVersionList"
import { TimeSeriesDataPoint } from "../types/generated/doxter/TimeSeriesDataPoint"
import { sortRetrainings } from "./searchSortFilterHelpers"

export function convertDatatoTotalRequestsLineGraph(logs: SummaryByDayPredictions[]): Serie[] {
  const arr2: Serie[] = [
    { id: "Failed", data: [] },
    { id: "Successful", data: [] },
  ]
  if (logs && logs.length > 0) {
    logs.forEach((elem) => {
      arr2[1].data.push({ x: moment(elem.timestamp).toDate(), y: elem.successful, modelsSwitched: elem.modelsSwitched })
      arr2[0].data.push({ x: moment(elem.timestamp).toDate(), y: elem.failed })
    })
    return arr2
  }
  return []
}

export function fillMissingRequestSummaryDates(
  startDate: moment.Moment,
  endDate: moment.Moment,
  currentDates: SummaryByDay[],
  activityData?: CustomSwitchedModels[],
): SummaryByDay[] {
  // Change RequestSummaryData array to an object, for quicker lookup later on.
  const dateObj: Record<
    string,
    { successful: number; failed: number; empty: number; alternateSuccessful: number; alternateFailed: number }
  > = {}
  for (const entry of currentDates) {
    dateObj[entry.timestamp] = {
      successful: entry.successful,
      failed: entry.failed,
      empty: 0,
      alternateFailed: entry.failed,
      alternateSuccessful: entry.successful,
    }
  }

  // Create a new RequestSummaryData array, while filling in the missing dates
  const filledDatesArray: Array<SummaryByDayPredictions> = []

  let tempStartDate = moment(startDate).format("YYYY-MM-DD")
  const tempEndDate = moment(endDate).format("YYYY-MM-DD")

  while (tempStartDate <= tempEndDate) {
    filledDatesArray.push({
      timestamp: tempStartDate,
      successful: dateObj[tempStartDate] ? dateObj[tempStartDate].successful : 0,
      failed: dateObj[tempStartDate] ? dateObj[tempStartDate].failed : 0,
      alternateFailed: 0,
      alternateSuccessful: 0,
      empty: 0,
    })
    tempStartDate = moment(tempStartDate).add(1, "days").format("YYYY-MM-DD")
  }
  const max = filledDatesArray.reduce((prev, current) =>
    prev.successful + prev.failed > current.successful + current.failed ? prev : current,
  )
  const maxTotal = max.successful + max.failed
  filledDatesArray.forEach((item) => {
    /**
     * alternate failed/successful respresnt the bar value
     * not the actual item value
     */
    item.alternateFailed = item.failed
    item.alternateSuccessful = item.successful
    if (item.successful === 0 && item.failed === 0 && maxTotal > 0) {
      item.empty = 0.05 * maxTotal
    }
    /**
     * if the gap between the biggest bar and single item bigger or equal to (item * 80)
     * then, to make sure that the item bar will be visible on the chart we adjust its value
     */
    if (item.successful && maxTotal >= item.successful * 80) {
      item.alternateSuccessful = Math.ceil(maxTotal / 80 + item.successful)
    }
    if (item.failed && maxTotal >= item.failed * 80) {
      item.alternateFailed = Math.ceil(maxTotal / 80 + item.failed)
    }
  })
  // return filledDatesArray

  //if there are no model switches occured + no predictions in the given time frame -> break
  let shouldBreak = true

  // //Add model state history to api monitoring line graph
  if (filledDatesArray && filledDatesArray.length > 0) {
    filledDatesArray.forEach((item) => {
      const temp = [] as CustomSwitchedModels[]
      activityData?.forEach((activity) => {
        if (moment(item.timestamp).format("YYYY-MM-DD") === moment(activity.created_at).format("YYYY-MM-DD")) {
          temp.push(activity)
          shouldBreak = false
        }
      })
      item.modelsSwitched = temp
    })
  }
  if (shouldBreak && !(currentDates && currentDates.length > 0)) {
    return []
  } else {
    return filledDatesArray
  }
}

const iterate = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nestedFeatures: any,
  result: (DiscreteOrContinuousType | CategoricalType | DatetimeType | UnknownType)[],
  num_of_examples: number,
  parent: string,
): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nestedFeatures.forEach((item: any) => {
    if (item.type === "multiple") {
      //nested array detected -> loop through it recursively
      iterate(item.features, result, num_of_examples, parent + "." + item.name)
    } else {
      //simple stat detected -> push it to the result array
      result.push({
        ...item.statistics,
        name: item.name,
        parent: parent,
        num_of_examples: num_of_examples,
        type: item.type,
      })
    }
  })
}

export function sortFeatures(
  featuresObject: TaskResult,
): (DiscreteOrContinuousType | CategoricalType | DatetimeType | UnknownType)[] {
  const result: (DiscreteOrContinuousType | CategoricalType | DatetimeType | UnknownType)[] = []
  if (featuresObject !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    featuresObject.statistics.forEach((item: any) => {
      if (item.type === "multiple") {
        //nested array detected -> loop through it recursively
        iterate(item.features, result, featuresObject.num_of_examples, item.name)
      } else {
        //simple stat detected -> push it to the result array
        result.push({
          ...item.statistics,
          name: item.name,
          parent: "",
          num_of_examples: featuresObject.num_of_examples,
          type: item.type,
        })
      }
    })
  }

  return result
}

export function getAverageCpuUsageForModel(model: ModelListCreate, modelUsage: DeploymentUsage): ModelAverageCpuUsage {
  let average_cpu = 0
  let max = 0
  if (modelUsage?.cpu_usage && modelUsage?.cpu_usage.length) {
    average_cpu =
      modelUsage?.cpu_usage?.reduce(
        (r: number, item: { timestamp: string; cpu_usage_percentage: number }) => r + item.cpu_usage_percentage,
        0,
      ) / modelUsage.cpu_usage.length
    max = modelUsage?.cpu_usage?.reduce((prev, current) =>
      prev.cpu_usage_percentage > current.cpu_usage_percentage ? prev : current,
    ).cpu_usage_percentage
  }

  return {
    average: average_cpu * 100,
    max: 0, //max on graph
    realMax: max * 100, //real max which is displayed on the tooltip
    total: 100,
    model: model.uuid,
    name: model.name,
    created_at: model.created_at,
    state: model.state,
  }
}

export function getAverageRamUsageForModel(model: ModelListCreate, modelUsage: DeploymentUsage): ModelAverageCpuUsage {
  let average_ram = 0
  let max = 0
  if (modelUsage?.ram_usage && modelUsage?.ram_usage.length) {
    average_ram =
      modelUsage?.ram_usage?.reduce(
        (r: number, item: { timestamp: string; memory_usage_megabytes: number }) => r + item.memory_usage_megabytes,
        0,
      ) / modelUsage.ram_usage.length
    max = modelUsage?.ram_usage?.reduce((prev, current) =>
      prev.memory_usage_megabytes > current.memory_usage_megabytes ? prev : current,
    ).memory_usage_megabytes
  }

  return {
    average: average_ram / 1024,
    max: 0, //max on graph
    realMax: max / 1024, //real max which is displayed on the tooltip
    total: 4,
    model: model.uuid,
    name: model.name,
    created_at: model.created_at,
    state: model.state,
  }
}

// helper function to genereate a unique name for retraining/training card
export const uniqueJobName = (id: string, timestamp: string, type: string): string => {
  const slicedId = id?.slice(0, 6)
  const slicedTime = moment(new Date(timestamp)).format("mm:ss")
  return `${type}-${slicedId}-${slicedTime}`
}

// check if this model has been trained/retrained before by Konan
export const modelTrainedByKonan = (
  name: string,
  trainings: TrainingJob[],
  trainingsAutoMl: AutoMLTrainingJob[],
  retrainings: RetrainingJob[],
): string => {
  const retrained = retrainings?.filter((retrain: RetrainingJob) => retrain?.resulting_model_name === name)
  const trained = trainings?.filter((train: TrainingJob) => train?.resulting_model_name === name)
  const trainedAutoMl = trainingsAutoMl?.filter((train: AutoMLTrainingJob) => train?.resulting_model_name === name)

  if (retrained?.length > 0) {
    return "Retrained by "
  } else if (trained?.length > 0 || trainedAutoMl?.length > 0) {
    return "Trained by "
  } else {
    return ""
  }
}

// function returns training/retraining jobs depending on the type
export const trainingJobList = (
  type: string,
  jobList: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] | undefined,
  training: boolean,
): (TrainingAndRetraining | TrainingJob | RetrainingJob)[] | undefined => {
  switch (type) {
    case "success":
      const successList = jobList
        ?.filter(
          (item: TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob) =>
            item?.status === "SUCCEEDED",
        )
        .map((item: TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob) => {
          return {
            training: training,
            ...item,
            training_job_name: uniqueJobName(item?.uuid, item?.created_at, training ? "training" : "retraining"),
          }
        })
      return successList
    case "failed":
      const failedList = jobList
        ?.filter(
          (item: TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob) =>
            item?.status === "FAILED" || item?.status === "CANCELLED" || item?.status === "TIMEOUT",
        )
        .map((item: TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob) => {
          return {
            training: training,
            ...item,
            training_job_name: uniqueJobName(item?.uuid, item?.created_at, training ? "training" : "retraining"),
          }
        })
      return failedList
    case "pending":
      const pendingList = jobList
        ?.filter(
          (item: TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob) =>
            item?.status === "PENDING" || item?.status === "RUNNING",
        )
        .map((item: TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob) => {
          return {
            training: training,
            ...item,
            training_job_name: uniqueJobName(item?.uuid, item?.created_at, training ? "training" : "retraining"),
          }
        })
      return pendingList
  }
}

//function to decide the all succeeded / failed /pending jobs
export const allJobsList = (
  trainingJobList: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] | undefined,
  retrainingJobList: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] | undefined,
): (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] => {
  let allList: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] = []

  if (trainingJobList && retrainingJobList) {
    allList = sortRetrainings([...trainingJobList, ...retrainingJobList], "Most Recent")
  } else if (retrainingJobList && !trainingJobList) {
    allList = [...retrainingJobList]
  } else if (trainingJobList && !retrainingJobList) {
    allList = [...trainingJobList]
  }

  return allList
}

// Function that converts activity data to markers
export const convertActivityDataToMarkers = (
  memoizedGetModelByUUID: (currentModel: string) => Model | null,
  activity: ListModelStateSwitch[],
): CustomSwitchedModels[] => {
  const res = []

  for (let i = 0; i < activity?.length; i++) {
    if (i % 2 === 0) {
      const liveSwitchRow = activity[i]
      res.push({
        ...liveSwitchRow,
        model_name: memoizedGetModelByUUID(liveSwitchRow?.model)?.name,
      })
    }
  }

  return res
}

// TODO: refactor it to enum later
export const mapResponseTypesToTabValues = (status: string): string => {
  switch (status?.toLowerCase()) {
    //inProgress values
    case "pending":
    case "running":
    case "processing":
      return "inProgress"
    // success values
    case "succeeded":
    case "success":
      return "success"
    // failed values
    case "error":
    case "failed":
    case "cancelled":
    case "timeout":
      return "failed"
    default:
      return "success"
  }
}

export const hasLiveModel = (models: ModelListCreate[]): boolean => {
  // if there is no live model, the user can't switch to challenger
  if (models.some((item) => item.state === "live")) {
    return true
  } else {
    return false
  }
}

// This function parses the condition string that we get from the backend
// for example, "age > 18" -> it should return [["age"], [">"], ["18"]]
// another example, "key != 'something'" -> it should return [["key"], ["!="], ["'something'"]]
// so it extracts the feature, operator, and value from the condition string
export const extractFeatureOperatorValue = (str: string): Array<string> | string => {
  //const pattern = /[\s]*([<Fragment>=!]+|>=|<=|!=|in|not in)[\s]*/
  //remove the double parentheses from the string then split with pattern
  let adjustedStr = str
  if (str?.startsWith("((") && str?.endsWith("))")) {
    adjustedStr = str?.slice(2, -2)
  }
  const match = adjustedStr?.split(" ")

  const adjustedMatch = []

  // Removing all empty spaces except in strings
  for (let i = 0; i <= match?.length - 1; i++) {
    if (match[i]?.includes("'")) {
      const temp = match?.slice(i).join(" ")

      adjustedMatch?.push(temp)
      break
    }

    if (match[i] !== "") adjustedMatch?.push(match[i])
  }

  if (adjustedMatch) {
    if (adjustedMatch?.length > 1 && adjustedMatch?.length <= 3) {
      return adjustedMatch
    } else if (
      adjustedMatch?.length > 3 &&
      adjustedMatch[1] === "not" &&
      ["in", "contains"].includes(adjustedMatch[2])
    ) {
      return [adjustedMatch[0], adjustedMatch?.slice(1, 3).join(" "), adjustedMatch?.slice(3).join(" ")]
    } else if (adjustedMatch?.length > 3 && adjustedMatch[1] === "between") {
      const formattedResponse = [
        ...adjustedMatch?.slice(0, 2),
        ...adjustedMatch?.slice(2).join("").replace(/[[\]]/g, "").split(","),
      ]
      return formattedResponse
    } else if (adjustedMatch?.length > 3) {
      //Edge case: if the value string contains > < ! = -> for example "name = 'Hell> Wo>ld!'"
      return [adjustedMatch[0], adjustedMatch[1], adjustedMatch?.slice(2).join(" ")]
    } else {
      return ""
    }
  } else {
    return ""
  }
}

//Function that detects the type of value in a condition
export const detectValueType = (input: string): string => {
  if (input === "true") {
    return "true"
  } else if (input === "false") {
    return "false"
  } else if (input === "null") {
    return "null"
  } else if (input === Operators.in) {
    return "in"
  } else if (input === Operators["not in"]) {
    return "not in"
  } else if (input === Operators["contains"]) {
    return "contains"
  } else if (input === Operators["not contains"]) {
    return "not contains"
  } else if (!isNaN(Number(input))) {
    return "number"
  } else {
    return "string"
  }
}

export function convertEKYCDataToBarGraph(data: Array<TimeSeriesDataPoint> | undefined): EkycStatsByTimeBarData[] {
  if (!data) return []

  const arrayWithMissingDates: Array<TimeSeriesDataPoint> = []
  for (let i = 0; i < data.length; i++) {
    if (!data[i + 1]) {
      arrayWithMissingDates.push(data[i])
      break
    }

    arrayWithMissingDates.push(data[i])

    // convert your array item (with index i and i+1) to a date (keep in mind, index i + 1 cant be > than array.length)
    const date1 = moment.utc(data[i].day)
    const date2 = moment.utc(data[i + 1].day)

    // calculate diffDays between the 2 dates, if diff is > 1, you have a missing date
    // create your missing date (use your date1 variable + 1Day)
    // add missingDate to arrayWithMissingDates[] array
    const dateDiff = moment(date2).diff(date1, "days")

    if (dateDiff > 1) {
      let prevDate = date1
      for (let j = 0; j < dateDiff - 1; j++) {
        prevDate = prevDate.add(1, "days")
        const datePlus1Day = prevDate.toISOString()
        arrayWithMissingDates.push({
          day: datePlus1Day,
          total: 0,
          verified: 0,
          unverified: 0,
          incomplete: 0,
          total_sessions: 0,
          total_users: 0,
        })
      }
    }
  }

  const arr: EkycStatsByTimeBarData[] = []
  arrayWithMissingDates.forEach((elem) => {
    arr.push({
      timestamp: moment(elem.day).format("MMM Do YYYY"),
      verified: elem.verified,
      total_users: elem.total_users,
      total_sessions: elem.total_sessions,
      unverified: elem.unverified,
      incomplete: elem.incomplete,
    })
  })

  return arr
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractScorecardErrorsMessages = (error: any, type: "create" | "update"): string => {
  if (error?.name) {
    return error?.name[0]
  } else if (error?.rules?.condition) {
    return `${error?.rules?.condition[0]}, Try removing spaces!`
  } else if (error?.scorecards && error?.scorecards[0]?.weight) {
    return error?.scorecards[0]?.weight
  } else if (error?.rules && error?.rules[0]?.return_value) {
    return `Rule weight: ${error?.rules[0]?.return_value}`
  } else {
    return `An error occurred while ${type} scorecard, please try again!`
  }
}

/**
 * Maps a list of features to key-value pairs where keys are feature names and values are corresponding values.
 * @param {Array<{ feat: string; value: string | number }>} list - An array of objects representing features and their values.
 * @returns {Record<string, string | number>} An object where keys are feature names and values are corresponding values.
 */
export const mapFeaturesListToKeyValuePair = (
  list: Array<{ feat: string; value: string }>,
): { [key: string]: string | number } => {
  return list
    ?.filter((item: { feat: string; value: string }) => item.feat !== "" && item.value !== "")
    ?.reduce((mappedValue: { [key: string]: string | number }, item) => {
      mappedValue[item?.feat] =
        isNaN(Number(item?.value)) || item?.value.includes(".") || item?.value.includes("e")
          ? item?.value?.trim()
          : Number(item?.value?.trim())
      return mappedValue
    }, {})
}

/**
 * This function takes an array of versions and a target version, then constructs a hashMap/object
 * that registers the major version to the highest valid version for restoration before the target version.
 *
 * @param versions - An array of versioning components, each containing a version string.
 * @param targetVersion - A version string used to split the array and find the highest minor version.
 * @returns A record object where the key is the major version number and the value is the corresponding highest minor version string.
 */
export const getValidMaxMinorVersion = (
  versions: Array<VersioningComponents>,
  targetVersion: string,
): Record<string, string> => {
  const majorTargetVersion = targetVersion.split(".")[0]
  const validVersionsHashMap: Record<string, string> = {}

  for (const version of versions) {
    // Split the version string into major and minor parts
    const [major, minor] = version.version.split(".")

    // Break the loop if the current version matches the target version
    if (version.version === targetVersion) {
      break
    }

    // Check if the major part of the current version matches the major target version
    if (major === majorTargetVersion) {
      // Update the validVersionsHashMap if the current version is higher than the stored version or if the major version is not yet in the hashmap
      if (!validVersionsHashMap.hasOwnProperty(major) || validVersionsHashMap[major].split(".")[1] < minor) {
        validVersionsHashMap[major] = version.version
      }
    }
  }

  // Find the target version object in the versions array
  const targetVersionData = versions.find((version) => version.version === targetVersion)

  // Find the stored version object corresponding to the valid maximum minor version for the major target version
  const storedVersionData = versions.find((version) => version.version === validVersionsHashMap[majorTargetVersion])

  // If no valid versions were found, and the the target version is deprecated return empty object, otherwise return the target version
  if (Object.keys(validVersionsHashMap).length === 0) {
    return (targetVersionData as WorkflowVersionList)?.is_deprecated ? {} : { [majorTargetVersion]: targetVersion }
  }

  // Return the validVersionsHashMap if the stored version is not deprecated, otherwise return an empty object
  return (storedVersionData as WorkflowVersionList)?.is_deprecated ? {} : validVersionsHashMap
}
