import React, { Fragment, useState } from "react"

import { useMutation } from "react-query"
import { useSelector } from "react-redux"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from "../.."
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { SidAPI } from "../../services/SidAPI"
import { RootState } from "../../store/ReduxStore"
import { CreateRoleRequestProps } from "../../types/custom/Permissions"
import { Role } from "../../types/generated/authentication/Role"
import { RolePermission } from "../../types/generated/authentication/RolePermission"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { getRoleDisplayName } from "../../utils/rolesHelpers"
import { MenuOption } from "../Members"
import { RoleContainerProps } from "./Interfaces"
import { RoleCard } from "./RoleCard"
import { RoleCreationDialog } from "./RoleCreationDialog"

/**
 * Role container holding all the role logic
 * @param {Role} role
 * @param {string} users (optional)
 * @returns {React.ReactElement}
 */
export function RoleContainer(props: Readonly<RoleContainerProps>): React.ReactElement {
  const { role, users } = props

  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)

  const [isRoleDialogOpen, setIsRoleDialogOpen] = useState<boolean>(false)
  const [isViewMode, setIsViewMode] = useState<boolean>(false)
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  const OrganizationUID = Auth.getOrganizationUID() as string
  const permissions = Auth.getPermissions()

  const deleteRoleMutation = useMutation<AxiosResponse, AxiosError, string>(SidAPI.deleteRole, {
    onSuccess: () => {
      NotificationUtils.toast("Role deleted successfully", {
        snackBarVariant: "positive",
      })

      queryClient.invalidateQueries("roles")
      queryClient.invalidateQueries("change-history")
    },
    onError: () =>
      NotificationUtils.toast("Couldn't delete role", {
        snackBarVariant: "negative",
      }),
  })

  const editRoleMutation = useMutation<AxiosResponse<Role>, AxiosError, CreateRoleRequestProps & { role_uuid: string }>(
    SidAPI.updateRole,
    {
      onSuccess: () => {
        NotificationUtils.toast("Role updated successfully", {
          snackBarVariant: "positive",
        })

        queryClient.invalidateQueries("konan-permissions")
        queryClient.invalidateQueries("sid-permissions")
        queryClient.invalidateQueries(["roles", OrganizationUID])
        queryClient.invalidateQueries("change-history")
      },
      onError: () =>
        NotificationUtils.toast("Role update failed", {
          snackBarVariant: "negative",
        }),
    },
  )

  const updateRoleHandler = async (name: string, description: string, permissions: RolePermission[]): Promise<void> => {
    await editRoleMutation.mutateAsync({
      role_uuid: role.uid,
      name: name,
      description: description,
      permissions: permissions,
    })

    setIsRoleDialogOpen(false)
  }

  const MenuOptions = (): MenuOption[] => {
    const options: MenuOption[] = []

    isPermitted("Update roles", permissions.synapse_id, flattenedSidPermissions) &&
      options.push({
        title: "Edit",
        variant: "neutral",
        onClick: () => {
          setIsRoleDialogOpen(true)
        },
      })

    isPermitted("Delete roles", permissions.synapse_id, flattenedSidPermissions) &&
      options.push({
        title: "Delete",
        variant: "negative",
        onClick: () => {
          setIsDeletionDialogOpen(true)
        },
      })

    return options
  }

  return (
    <Fragment>
      {isRoleDialogOpen && (
        <RoleCreationDialog
          isOpen={isRoleDialogOpen}
          onClose={() => {
            setIsRoleDialogOpen(false)
            setIsViewMode(false)
          }}
          role={role}
          onAccept={updateRoleHandler}
          isViewMode={isViewMode}
        />
      )}

      <BaseSimpleDialog
        open={isDeletionDialogOpen}
        isLoading={deleteRoleMutation.isLoading}
        name={role.name}
        onAccept={() => {
          deleteRoleMutation.mutateAsync(role.uid)
          setIsDeletionDialogOpen(false)
        }}
        onClose={() => setIsDeletionDialogOpen(false)}
        mode={"role-deletion"}
      />

      <RoleCard
        onTitleClick={() => {
          setIsViewMode(true)
          setIsRoleDialogOpen(true)
        }}
        name={getRoleDisplayName(role.name)}
        date={role.created_at}
        immutable={["OWNER", "USER"].includes(role.name)}
        users={users}
        MenuOptions={MenuOptions()}
      />
    </Fragment>
  )
}
