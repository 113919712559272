import React, { Fragment, useContext, useState } from "react"

import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import WarningIcon from "@mui/icons-material/Warning"
import { CircularProgress, Divider } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import { MRT_ColumnDef } from "material-react-table"

import { InfoContainer } from "../../components/InfoContainer"
import { KonanEmptyState } from "../../components/KonanEmptyState"
import { KonanPageHeader } from "../../components/KonanPageHeader"
import { BaseTable } from "../../components/tables/BaseTable"
import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { AdjustedDriftJob, AdjustedDriftSkewInfo, DriftResponse, ProjectLimits } from "../../types/custom/projects"
import { TrainingDataUpload } from "../../types/generated/api/TrainingDataUpload"
import { mapSeverityToFeatures } from "../../utils/modelDetailsHelpers"

import styles from "./DataDrift.module.scss"

const columns: MRT_ColumnDef<AdjustedDriftSkewInfo>[] = [
  {
    header: "Severity",
    accessorFn: (row) => (row.error ? "Warning" : "Good"),
    Cell: ({ row }) => {
      if (row.original.error) {
        return (
          <Typography variant="h3-regular">
            <WarningIcon className={"warning-table-icon"} /> Warning
          </Typography>
        )
      } else {
        return (
          <Typography variant="h3-regular">
            <CheckCircleIcon className={"good-table-icon"} /> Good
          </Typography>
        )
      }
    },
  },
  {
    header: "Name",
    accessorKey: "featureName",
  },
  {
    header: "Data Drift Value",
    accessorFn: (row) => {
      if (row.driftValue === null) return "-"
      const percentValue = row.driftValue * 100
      return (percentValue % 1 === 0 ? percentValue : percentValue.toFixed(1)) + "%"
    },
  },
  {
    header: "Data Drift Threshold",
    accessorFn: (row) => {
      if (row.driftThreshold === null) return "-"
      const percentValue = row.driftThreshold * 100
      return (percentValue % 1 === 0 ? percentValue : percentValue.toFixed(1)) + "%"
    },
  },
  {
    header: "Warning Description",
    accessorKey: "errorReason.shortDescription",
  },
]

type ParamsType = {
  id: string
}

const SeverityContainer = (props: { driftData: AdjustedDriftSkewInfo[]; status: string }): React.ReactElement => {
  const { driftData, status } = props

  const filterBySeverity = (features: AdjustedDriftSkewInfo[]): AdjustedDriftSkewInfo[] => {
    return features.filter((feature: AdjustedDriftSkewInfo) => feature.error)
  }

  const severityData = filterBySeverity(driftData)

  return (
    <Fragment>
      {severityData.length === 1 ? (
        <Typography variant="span">
          <strong>{severityData[0].featureName}</strong> <span className={"warning-text"}>requires attention!</span>
        </Typography>
      ) : severityData.length === 2 ? (
        <Typography variant="span">
          <strong>{severityData[0].featureName} </strong> and <strong>{severityData[1].featureName}</strong>{" "}
          <span className={"warning-text"}>require attention!</span>
        </Typography>
      ) : severityData.length === 3 ? (
        <Typography variant="span">
          <strong>{severityData[0].featureName}</strong>, <strong>{severityData[1].featureName}</strong> and{" "}
          <strong>1</strong> more feature <span className={"warning-text"}>require attention!</span>
        </Typography>
      ) : severityData.length > 3 ? (
        <Typography variant="span">
          <strong>{severityData[0].featureName}</strong>, <strong>{severityData[1].featureName}</strong> and{" "}
          <strong>{(severityData.length - 2).toString()}</strong> more features{" "}
          <span className={"warning-text"}>require attention!</span>
        </Typography>
      ) : status === "error" ? (
        <Typography variant="span">
          <span className={"error-text"}>An error occurred when running data drift check.</span>
        </Typography>
      ) : (
        <Typography variant="span">
          <span className={"good-text"}>No issues detected!</span>
        </Typography>
      )}
    </Fragment>
  )
}

export function DataDriftView(): React.ReactElement {
  const navigate = useNavigate()
  const { id: projectId } = useParams<ParamsType>()
  const theme = getTheme()

  const { currentModel } = useContext(CurrentProjectAndModelContext)

  const [page, setPage] = useState(1)
  const pageSize = 5

  const [expanded, setExpanded] = React.useState<string | false>("panel0")

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const {
    isFetching,
    isError,
    isLoading: isDriftDataLoading,
    data: driftResponse,
  } = useQuery<AxiosResponse<DriftResponse>, AxiosError>(
    ["driftJobs", currentModel, page, pageSize],
    () => KonanAPI.fetchDataDriftJobs(currentModel, page, pageSize),
    { keepPreviousData: true, enabled: !!currentModel },
  )

  const { data: trainingData, isLoading: isTrainingDataLoading } = useQuery<TrainingDataUpload[], AxiosError>(
    ["training-data", projectId],
    () => KonanAPI.fetchTrainingData(projectId as string),
  )

  const { data: projectLimits } = useQuery<AxiosResponse<ProjectLimits>, AxiosError>(["project-limits"], () =>
    KonanAPI.fetchProjectLimits(),
  )

  const driftData = React.useMemo(() => {
    if (driftResponse?.data?.results) {
      return mapSeverityToFeatures(driftResponse?.data?.results)
    }
    return []
  }, [driftResponse?.data?.results])

  return (
    <React.Fragment>
      <Grid container direction="row" spacing={2} marginBottom={1}>
        <KonanPageHeader title="Data Drift" />
      </Grid>
      <Grid container spacing={2}>
        {/* Info Section */}
        {isTrainingDataLoading || isDriftDataLoading || (isFetching && !isError) ? (
          <InfoContainer
            icon={<CircularProgress className={styles.circularProgress} />}
            title="Loading data drift checks..."
          />
        ) : isError ? (
          <InfoContainer
            icon={<ErrorIcon style={{ color: theme.palette.red.text[2] }} fontSize="large" />}
            title="An error occurred when loading data drift checks."
            subtitle="Please check your internet connection or try again later."
          />
        ) : (
          driftData?.length === 0 &&
          // If there's no valid training data attached to the project
          (trainingData && trainingData.some((e) => e.is_valid) ? (
            <KonanEmptyState
              title={"No data drift checks to display!"}
              subTitle={"Try sending more requests to trigger a data drift check."}
            />
          ) : (
            // If there's a valid training data file and no data drift jobs have been created yet
            <KonanEmptyState
              title={"No valid training data found!"}
              subTitle={"To activate data drift checks, you'll need to provide the project's training data."}
              buttonText="Upload Training Data"
              setAction={() => navigate(`/projects/${projectId}/model-training?page=Training-Data`)}
            />
          ))
        )}

        {driftResponse && driftResponse?.data.results.length > 0 && !isFetching && (
          <Grid item xs={12}>
            {driftData.map((item: AdjustedDriftJob, index: number) => (
              <Accordion
                key={index}
                expanded={expanded === "panel" + index}
                onChange={handleChange("panel" + index)}
                className={styles.accordionBase}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: theme.palette.grayscale.text[2] }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={6}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Grid item container spacing={1}>
                          {item.created_at && (
                            <Grid item>
                              <Typography variant="h3-regular">
                                Data drift check ran on{" "}
                                <strong>{format(new Date(item.created_at), "dd/MM/yyyy, p")}</strong> using{" "}
                                <strong>{projectLimits?.data.drift_threshold}</strong> requests
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <SeverityContainer driftData={item.result} status={item.status} />
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} justifyContent="flex-end" spacing={1} alignItems="center">
                      <Grid item>
                        <Typography variant="h3-regular">
                          <strong>Training Data</strong>: {item.reference_data}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem className={styles.divider} />
                      <Grid item>
                        {item.status === "error" ? (
                          <Typography variant="p">
                            <ErrorIcon className={styles.error} /> Error
                          </Typography>
                        ) : item.result?.filter((feature: AdjustedDriftSkewInfo) => feature.error).length > 0 ? (
                          <Typography variant="p">
                            <WarningIcon className={"warning-icon"} /> Warning
                          </Typography>
                        ) : (
                          <Typography variant="p">
                            <CheckCircleIcon className={styles.good} /> Good
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    {item.status !== "error" ? (
                      <Grid item xs={12}>
                        <BaseTable
                          enableOrdering={false}
                          disableToolbarActions
                          columns={columns}
                          data={item.result}
                          title={"Features Table"}
                        />
                      </Grid>
                    ) : (
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      <Typography variant="h3-bold">No data to display</Typography>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        )}

        {/* Pagination Section */}
        {driftResponse && driftResponse?.data.results.length > 0 && !isFetching && (
          <Grid container item justifyContent="flex-end" className="pagination">
            <Pagination
              shape="rounded"
              count={driftResponse?.data?.count ? Math.ceil(driftResponse.data.count / pageSize) : 0}
              page={page}
              onChange={(_, value) => {
                setPage(value)
                window.scrollTo(0, 0)
              }}
              siblingCount={2}
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
}
