import React from "react"

import { Outlet } from "react-router-dom"

import { Grid } from "@mui/material"

import { KonanLogo } from "../../components/icons/KonanLogo"

import styles from "./Entry.module.scss"

/**
 * Layout component for entry module components
 * wrapping the component in a grid and placing logo on the top of the screen
 * @return {<EntryLayout />}
 */
export function EntryLayout(): React.ReactElement {
  return (
    <Grid container direction="column" alignItems="center" wrap="nowrap">
      <Grid item className={styles.logo}>
        <KonanLogo logoWidth={window.innerWidth >= 400 ? 360 : 300} />
      </Grid>

      <Grid item xs={12}>
        {/* This element will render the entry component responding the current url
        for more info visit: https://reactrouter.com/en/main/components/outlet */}
        <Outlet />
      </Grid>
    </Grid>
  )
}
