import React, { useEffect } from "react"

import { Grid, Paper } from "@mui/material"
import { Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { JsonViewer } from "@textea/json-viewer"

import styles from "./KonanJsonView.module.scss"

type Props = {
  src?: string | Record<string, unknown> | null
}

export function KonanJsonView(props: Props): React.ReactElement {
  const { src } = props

  // State to hold the JSON object that will actually be passed to React Json View
  const [jsonObject, setJsonObject] = React.useState({})

  // Boolean to check whether input src is a valid json or not
  const [isValidJson, setIsValidJson] = React.useState(true)

  useEffect(() => {
    // Parse src if it's a string
    if (typeof src === "string") {
      try {
        setJsonObject(JSON.parse(src))
        setIsValidJson(true)
      } catch (e) {
        setIsValidJson(false)
      }
      // If src is an object
    } else if (typeof src === "object" && src !== null) {
      setJsonObject(src)
      setIsValidJson(true)
    } else {
      setIsValidJson(false)
    }
  }, [src])

  return (
    <React.Fragment>
      {isValidJson ? (
        <JsonViewer
          value={jsonObject}
          rootName={false}
          theme={"dark"}
          collapseStringsAfterLength={300}
          className={styles.container}
        />
      ) : !!src ? (
        // src can be a string, int, array or etc...
        <Grid component={Paper} className={styles.container}>
          <Typography variant="p" className={styles.srcText}>
            <>{src}</>
          </Typography>
        </Grid>
      ) : (
        <Snackbar variant="warning" fullWidth description="No data to display." />
      )}
    </React.Fragment>
  )
}
