import React, { useMemo, useState } from "react"

import { Grid, useMediaQuery, useTheme as useMuiTheme } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

import { TopDataFeaturesProps } from "../../features/Simulations"
import { getTheme } from "../../hooks/UseTheme"
import { SelectWithSearch } from "../UI/SelectWithSearch"
import { ChartsContainer } from "./ChartsContainer"
import { GraphLabelText } from "./GraphLabelText"

interface SingleFeatureProps {
  showAxis?: boolean
  features: Array<{ name: string; values: object }>
  size: number
  index: number
}

/**
 * Recharts single feature graph (bar chart)
 * @return {React.ReactElement}
 */
const SingleFeatureGraph = (props: SingleFeatureProps): React.ReactElement => {
  const { showAxis, features, size, index } = props
  const theme = getTheme()

  const [selectedFeature, setSelectedFeature] = useState<string>(features[index].name)

  const labels = useMemo(() => {
    return features.length > 0 ? features.map((item) => item.name) : []
  }, [features])

  // processing data to be able to present it on the graphs (key-value pairs with the label data)
  const processedData = useMemo(() => {
    const data: {
      key: string
      value: string | number
      labelData: {
        label: string
        percentage: string
      }
    }[] = []

    const adjustedFeatures = features.filter((item) => item.name === selectedFeature)

    let adjustedFeaturesTotal = 0

    adjustedFeatures.forEach((item): void => {
      Object.entries(item.values).forEach(([_, value]) => {
        adjustedFeaturesTotal += value
      })
    })

    adjustedFeatures.forEach((item): void => {
      Object.entries(item.values).forEach(([key, value], index) => {
        index < 5 &&
          data.push({
            key: key,
            value: value,
            labelData: {
              label: key,
              percentage: ((value / adjustedFeaturesTotal) * 100).toFixed(1),
            },
          })
      })
    })

    // sorting data in a descending order to view the charts correctly
    data.sort((a, b) => b.value - a.value)

    return data
  }, [features, selectedFeature])

  return (
    <Grid container item xs={size}>
      <Grid item xs={12} display={"flex"} justifyContent={"center"} pt={2} px={2}>
        <SelectWithSearch
          options={[
            { label: "Select Label", value: "", isDisabled: true },
            ...labels.map((label) => {
              return { label: label, value: label }
            }),
          ]}
          searchInputPlaceHolder="Search features"
          initialValue={selectedFeature}
          fullWidth
          onSelectMenuItem={(item: { label: string; value: string }) => {
            setSelectedFeature(item.value)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ResponsiveContainer>
          <BarChart
            data={processedData}
            margin={{
              top: 16,
              right: 16,
              left: 80,
              bottom: 16,
            }}
            layout="vertical"
          >
            <XAxis dataKey="value" type="number" hide={!showAxis} reversed />
            <YAxis type="category" dataKey="key" hide={!showAxis} />

            <Bar dataKey="value" fill={theme.palette.blue.background[1]} radius={4}>
              <LabelList
                dataKey="labelData"
                content={({ y, value }) => {
                  return (
                    <g>
                      <foreignObject
                        x={20}
                        y={y}
                        width={"100%"}
                        height={"100%"}
                        style={{
                          overflow: "visible",
                        }}
                      >
                        <GraphLabelText label={{ percent: value.percentage, value: value.label }} bottomLabel noLabel />
                      </foreignObject>
                    </g>
                  )
                }}
              />
            </Bar>

            <Tooltip
              cursor={{ fill: "transparent" }}
              content={({ payload }) =>
                payload?.length && (
                  <div className={"chart-tooltip"} style={{ width: "fit-content" }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography variant="h3-bold" display="block" variantColor={2}>
                          {payload?.[0].payload.key}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography variant="p" variantColor={2}>
                      Value: {payload?.[0].payload.value}
                    </Typography>
                  </div>
                )
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  )
}

interface BarChartProps extends Omit<BarProp, "dataKey"> {
  title: string
  tooltipText?: string
  graphHeight?: number
  range?: number
  showAxis?: boolean
  isLoading?: boolean
  data: TopDataFeaturesProps[]
}

/**
 * Recharts Top features graph container
 * @return {React.ReactElement}
 */
export function TopFeaturesGraph(props: Readonly<BarChartProps>): React.ReactElement {
  const { graphHeight = 400, title, range, tooltipText, showAxis, isLoading = false, data } = props

  const MuiTheme = useMuiTheme()
  const xlQuery = useMediaQuery(MuiTheme.breakpoints.up("xl"))
  const lgQuery = useMediaQuery(MuiTheme.breakpoints.up("lg"))

  const [selectedLabel, setSelectedLabel] = useState<string | null>(null)

  // labels to be used in drop down menu
  const labels = useMemo(() => {
    const labelsArray = data.map((item) => item.label)

    if ([null, undefined].includes(selectedLabel)) {
      setSelectedLabel(labelsArray[0])
    }

    return labelsArray
  }, [data, selectedLabel])

  // returning the correct features after the user chooses the label
  const features = useMemo(() => {
    if (data.length > 0) {
      for (const item of data) {
        if (item.label === selectedLabel) {
          return [...item.features]
        }
      }

      return [...data[0].features]
    }

    return []
  }, [data, selectedLabel])

  const GetGraphSize = (featuresLength: number): number => {
    if (featuresLength >= 3 && xlQuery) {
      return 3
    } else if (featuresLength >= 2 && lgQuery) {
      return 2
    } else {
      return 1
    }
  }

  return (
    <ChartsContainer
      title={title}
      tooltipText={tooltipText}
      range={range}
      isLoading={isLoading}
      graphHeight={graphHeight + 30}
      selectProps={
        !isLoading && labels.length > 0
          ? { options: labels, value: selectedLabel, setValue: setSelectedLabel }
          : undefined
      }
    >
      {labels.length > 0 && !selectedLabel ? (
        <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
          <Typography variant="h2-bold">Select a label to proceed.</Typography>
        </Grid>
      ) : data.length === 0 || features.length === 0 ? (
        <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
          <Typography variant="h2-bold">Top features are unavailable.</Typography>
        </Grid>
      ) : (
        <Grid container spacing={0}>
          {Array(GetGraphSize(features.length))
            .fill(0)
            .map((_, index) => (
              <SingleFeatureGraph
                showAxis={showAxis}
                features={features}
                size={GetGraphSize(features.length) === 1 ? 12 : GetGraphSize(features.length) === 2 ? 6 : 4}
                index={index}
              />
            ))}
        </Grid>
      )}
    </ChartsContainer>
  )
}
