import { Fragment } from "react"

import { Grid } from "@mui/material"
import { ResponsiveSankey } from "@nivo/sankey"

import { getTheme } from "../../hooks/UseTheme"
import { KonanEmptyState } from "../KonanEmptyState"
import { ValueBlock } from "../ValueBlock"
import { LabelAuditCard } from "../cards/SimulationAuditCards/LabelAuditCard"
import { ChartsContainer } from "./ChartsContainer"
import { GraphLabelText } from "./GraphLabelText"

interface SankeyChartProps {
  title: string
  tooltipText?: string
  graphHeight?: number
  range?: number
  isLoading?: boolean
  data: {
    nodes: {
      id: string
      uuid: string
      color?: string
      percentage: number
      nodeType:
        | "label"
        | "filter"
        | "script"
        | "scorecardset"
        | "ruleset"
        | "taglist"
        | "program"
        | "calculator"
        | "loop-start"
        | "loop-end"
        | "project"
      name: string
    }[]
    links: { source: string; target: string; value: number; percentage: number }[]
  }
}

export function SankeyChart(props: Readonly<SankeyChartProps>): React.ReactElement {
  const { graphHeight = 400, title, range, tooltipText, isLoading = false, data } = props

  const theme = getTheme()

  return (
    <ChartsContainer
      title={title}
      tooltipText={tooltipText}
      range={range}
      isLoading={isLoading}
      graphHeight={graphHeight}
    >
      {data.links.length > 0 && data.nodes.length > 0 ? (
        <ResponsiveSankey
          data={data}
          theme={{
            labels: {
              text: {
                fontSize: 14,
                fontWeight: 700,
                fontFamily: '"Inter", sans-serif',
              },
            },
          }}
          margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
          align="justify"
          colors={(node) => node?.color}
          nodeOpacity={1}
          nodeHoverOthersOpacity={0.35}
          nodeThickness={24}
          nodeSpacing={4}
          nodeBorderWidth={0}
          nodeBorderRadius={0}
          nodeTooltip={({ node }) => (
            <Grid container item xs>
              <LabelAuditCard
                nodeType={node.nodeType}
                nodeBody={
                  <Grid item xs={12} container alignItems="flex-start" justifyContent={"space-between"} mt={1}>
                    <ValueBlock value={node.name} size={12} />
                  </Grid>
                }
              />
            </Grid>
          )}
          linkOpacity={0.8}
          linkHoverOthersOpacity={0.25}
          linkContract={4}
          linkBlendMode={"normal"}
          linkTooltip={({ link }) => (
            <Fragment>
              <Grid item xs={12} mb={1}>
                <LabelAuditCard
                  nodeType={link.target.nodeType}
                  nodeBody={
                    <Grid item xs={12} container alignItems="flex-start" justifyContent={"space-between"} mt={1}>
                      <ValueBlock value={link.target.name} size={12} />
                    </Grid>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div className={"chart-tooltip"} style={{ width: "fit-content" }}>
                  <GraphLabelText
                    label={{
                      name: link.target.name,
                      value: link.target.value,
                      percent: link.target.percentage,
                    }}
                  />
                </div>
              </Grid>
            </Fragment>
          )}
          enableLinkGradient={true}
          labelPosition="inside"
          labelOrientation="vertical"
          labelPadding={-10}
          label={({ value, y0, y1, name }) => {
            // making sure the label is hidden if height of node is less than 100px
            // and formatting the numbers so that 1000 is displayed as 1,000 (for ease of readability)
            // (y1 - y0) / 6 - 12 --> magic numbers deciding if we should display the name based on it's length to avoid text overlapping on the chart
            // TODO:: should look into a pixel length ratio and make these numbers more dynamic,
            // however we will wait for the revamp since inter has different aspect ration that not-sans
            return y1 - y0 > 100 && name.length < (y1 - y0) / 6 - 12
              ? `${name} (${new Intl.NumberFormat("en-IN").format(value)})`
              : ""
          }}
          labelTextColor={theme.palette.grayscale.text[1]}
        />
      ) : (
        <KonanEmptyState title="Decision funnel is not available at the moment" />
      )}
    </ChartsContainer>
  )
}
