import React from "react"

import { CardActions, Grid } from "@mui/material"
import { Tag, Typography } from "@synapse-analytics/synapse-ui"

import { TemplateCardProps } from "../Interfaces"

import styles from "../Scripts.module.scss"

/**
 * Represents a card component for displaying template information.
 * The card includes a title and an optional short description, and can be selected.
 *
 * @param {string} name The name of the template.
 * @param {boolean} isSelected Indicates if the template is currently isSelected.
 * @param {string} description A brief description of the template, if available.
 * @param {function} onSelect Callback function to be called when the card is clicked.
 * @param {function} isInUse Indicator if this template has a script created from it or not.
 * @returns {React.ReactElement} that represents the template card.
 */
export function TemplateCard(props: Readonly<TemplateCardProps>): React.ReactElement {
  const { name, isSelected, description, onSelect, isInUse = true } = props

  const cardClassName = isInUse ? styles.disabledTemplate : isSelected ? styles.selectedTemplate : styles.templateCard

  return (
    <CardActions className={cardClassName} onClick={isInUse ? undefined : onSelect}>
      <Grid container display="flex" flexDirection="column">
        <Grid item container flexWrap="nowrap" xs={12} gap={1} alignItems="center" justifyContent="flex-start">
          <Typography variantColor={isInUse ? 2 : 1} style={{ width: "100% - 40px" }} variant="h3-bold">
            {name}
          </Typography>

          {isInUse && (
            <Tag variant="default" size="small">
              in use
            </Tag>
          )}
        </Grid>

        {description && (
          <Grid item>
            <Typography variantColor={2} variant="h3-regular">
              {description}
            </Typography>
          </Grid>
        )}
      </Grid>
    </CardActions>
  )
}
