import React, { Fragment, useContext, useEffect, useMemo, useState } from "react"

import InfiniteScroll from "react-infinite-scroller"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { Box, CircularProgress, Grid } from "@mui/material"
import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState, KonanEmptyStateSearch } from "../../../../components/KonanEmptyState"
import { KonanTabsHeader } from "../../../../components/KonanTabsHeader"
import { useHandleTrainingQueryParam } from "../../../../hooks/useHandleTrainingQueryParam"
import { KonanAPI } from "../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../store/CurrentProjectAndModelContext"
import { CreateRetrainingModelRequest, TrainingAndRetraining, baseErrorType } from "../../../../types/custom/projects"
import { AutoMLTrainingJob } from "../../../../types/generated/api/AutoMLTrainingJob"
import { Model } from "../../../../types/generated/api/Model"
import { PaginatedAutoMLTrainingJobList } from "../../../../types/generated/api/PaginatedAutoMLTrainingJobList"
import { PaginatedRetrainingJobList } from "../../../../types/generated/api/PaginatedRetrainingJobList"
import { PaginatedTrainingJobList } from "../../../../types/generated/api/PaginatedTrainingJobList"
import { RetrainingJob } from "../../../../types/generated/api/RetrainingJob"
import { TrainingJob } from "../../../../types/generated/api/TrainingJob"
import { allJobsList, mapResponseTypesToTabValues, trainingJobList } from "../../../../utils/deploymentDetailsHelpers"
import { getModelByUUID } from "../../../../utils/modelDetailsHelpers"
import { searchRetrainingsByName, sortRetrainings } from "../../../../utils/searchSortFilterHelpers"
import { PendingFailedTrainingCardLoader } from "./components/PendingFailedTrainingCardLoader"
import { PendingFailedTrainingJobCard } from "./components/PendingFailedTrainingJobCard"
import { RetrainingCreationDialog } from "./components/RetrainingCreationCard"
import { SuccessfulTrainingCardLoader } from "./components/SuccessfulTrainingCardLoader"
import { SuccessfulTrainingJobCard } from "./components/SuccessfulTrainingJobCard"

import styles from "./TrainingJobs.module.scss"

type ParamsType = {
  id: string
}

export function TrainingJobs(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()
  const [liveModel, setLiveModel] = useState<Model>()
  const [newTrainingJobOpen, setNewTrainingJobOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [sortValue, setSortValue] = useState<string>("Most Recent")
  const [sortValueFailed, setSortValueFailed] = useState<string>("Most Recent")
  const [searchValueFailed, setSearchValueFailed] = useState<string>("")
  const [sortValueInProgress, setSortValueInProgress] = useState<string>("Most Recent")
  const [searchValueInProgress, setSearchValueInProgress] = useState<string>("")
  const [tabValue, setTabValue] = useHandleTrainingQueryParam(["success", "failed", "inProgress"], "success")
  const [errorMessage, setErrorMessage] = useState<string>()
  const [refetchInterval, setRefetchInterval] = useState<number | false | undefined>(false)

  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 6

  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError<baseErrorType>>(["models", projectId], () =>
    KonanAPI.fetchModels(projectId as string),
  )

  const { currentProject } = useContext(CurrentProjectAndModelContext)
  const cleanType = currentProject ? currentProject?.type?.replace(/_+/g, " ") : ""

  // fetch automl training jobs
  const { isLoading: isTrainingAutoMlLoading, data: trainingAutoMlModels } = useQuery<
    PaginatedAutoMLTrainingJobList,
    AxiosError<baseErrorType>
  >(["training-jobs-automl", projectId], () => KonanAPI.fetchAutoMlTrainingJobs(projectId as string, 100, 1), {
    enabled: !!projectId,
    refetchInterval: refetchInterval,
    refetchIntervalInBackground: true,
    onError: () => {
      setRefetchInterval(false)
    },
    onSuccess: (data) => {
      if (
        (data?.results && data?.results[0]?.status === "PENDING") ||
        (data?.results && data?.results[0]?.status === "RUNNING")
      ) {
        return setRefetchInterval(3000)
      } else setRefetchInterval(false)
    },
  })

  // fetch re-training jobs
  const { isLoading: isRetrainigLoading, data: retrainingModels } = useQuery<
    PaginatedRetrainingJobList,
    AxiosError<baseErrorType>
  >(["retraining-jobs", liveModel?.uuid], () => KonanAPI.fetchRetrainingModels(projectId as string, 100, 1), {
    enabled: !!liveModel,
    refetchInterval: refetchInterval,
    refetchIntervalInBackground: true,
    onError: () => {
      setRefetchInterval(false)
    },
    onSuccess: (data) => {
      if (
        (data?.results && data?.results[0]?.status === "PENDING") ||
        (data?.results && data?.results[0]?.status === "RUNNING")
      ) {
        return setRefetchInterval(3000)
      } else setRefetchInterval(false)
    },
  })

  const { isLoading: isTrainingLoading, data: trainingModels } = useQuery<
    PaginatedTrainingJobList,
    AxiosError<baseErrorType>
  >(["training-jobs", projectId], () => KonanAPI.fetchTrainingModels(projectId as string, 100, 1), {
    enabled: !!projectId,
    refetchInterval: refetchInterval,
    refetchIntervalInBackground: true,
    onError: () => {
      setRefetchInterval(false)
    },
    onSuccess: (data) => {
      if (
        (data?.results && data?.results[0]?.status === "PENDING") ||
        (data?.results && data?.results[0]?.status === "RUNNING")
      ) {
        return setRefetchInterval(3000)
      } else setRefetchInterval(false)
    },
  })

  let allSucceededTrainingJobs: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] = []
  let allFailedTrainingJobs: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] = []
  let allPendingTrainingJobs: (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] = []

  // FILTERING ALL SUCCEEDED TRAINING + RETRAINING JOBS
  const succeededTrainingJobs = trainingJobList(
    "success",
    trainingModels?.results !== undefined && trainingAutoMlModels?.results !== undefined
      ? [...trainingModels?.results, ...trainingAutoMlModels?.results]
      : undefined,
    true,
  )

  const succeededRetrainingJobs = trainingJobList("success", retrainingModels?.results, false)
  allSucceededTrainingJobs = allJobsList(succeededTrainingJobs, succeededRetrainingJobs)
  // FILTERING ALL FAILED TRAINING + RETRAINING JOBS
  const failedTrainingJobs = trainingJobList(
    "failed",
    trainingModels?.results !== undefined && trainingAutoMlModels?.results !== undefined
      ? [...trainingModels?.results, ...trainingAutoMlModels?.results]
      : undefined,
    true,
  )

  const failedRetrainingJobs = trainingJobList("failed", retrainingModels?.results, false)
  allFailedTrainingJobs = allJobsList(failedTrainingJobs, failedRetrainingJobs)
  // FILTERING ALL SUCCEEDED TRAINING + RETRAINING JOBS
  const pendingTrainingJobs = trainingJobList(
    "pending",
    trainingModels?.results !== undefined && trainingAutoMlModels?.results !== undefined
      ? [...trainingModels?.results, ...trainingAutoMlModels?.results]
      : undefined,
    true,
  )

  const pendingRetrainingJobs = trainingJobList("pending", retrainingModels?.results, false)
  allPendingTrainingJobs = allJobsList(pendingTrainingJobs, pendingRetrainingJobs)

  type extendedTrainingJobs = (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob) & {
    current_model_state: string
  }
  const adjustedAllSuccessfulTrainingJobs = useMemo(
    (): extendedTrainingJobs[] => {
      let adjustedTrainings = allSucceededTrainingJobs || []
      if (searchValue && searchValue?.length > 0)
        adjustedTrainings = adjustedTrainings ? searchRetrainingsByName(adjustedTrainings, searchValue) : []

      if (sortValue && sortValue?.length > 0)
        adjustedTrainings = adjustedTrainings ? sortRetrainings(adjustedTrainings, sortValue) : []

      adjustedTrainings = adjustedTrainings.map((item) => ({
        ...item,
        current_model_state: getModelByUUID(item.resulting_model, models?.data)?.state,
      }))
      return adjustedTrainings
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allSucceededTrainingJobs,
      searchValue,
      sortValue,
      trainingModels?.results?.length,
      retrainingModels?.results?.length,
    ],
  )

  const adjustedAllPendingTrainingJobs = useMemo(
    (): (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] => {
      let adjustedTrainings = allPendingTrainingJobs || []
      if (searchValueInProgress && searchValueInProgress?.length > 0)
        adjustedTrainings = adjustedTrainings ? searchRetrainingsByName(adjustedTrainings, searchValueInProgress) : []

      if (sortValueInProgress && sortValueInProgress?.length > 0)
        adjustedTrainings = adjustedTrainings ? sortRetrainings(adjustedTrainings, sortValueInProgress) : []

      return adjustedTrainings
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allPendingTrainingJobs,
      searchValueInProgress,
      sortValueInProgress,
      trainingModels?.results?.length,
      retrainingModels?.results?.length,
      refetchInterval,
    ],
  )

  const adjustedAllFailedTrainingJobs = useMemo(
    (): (TrainingAndRetraining | TrainingJob | RetrainingJob | AutoMLTrainingJob)[] => {
      let adjustedTrainings = allFailedTrainingJobs || []
      if (searchValueFailed && searchValueFailed?.length > 0)
        adjustedTrainings = adjustedTrainings ? searchRetrainingsByName(adjustedTrainings, searchValueFailed) : []

      if (sortValueFailed && sortValueFailed?.length > 0)
        adjustedTrainings = adjustedTrainings ? sortRetrainings(adjustedTrainings, sortValueFailed) : []

      return adjustedTrainings
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allFailedTrainingJobs,
      searchValueFailed,
      sortValueFailed,
      trainingModels?.results?.length,
      retrainingModels?.results?.length,
    ],
  )

  useEffect(() => {
    if (models?.data && models?.data?.length > 0) {
      //Get live model UUID on mount
      setLiveModel(models?.data?.find((model) => model.state === "live"))
    }
  }, [models?.data])

  const isTrainingJobLoading = isTrainingLoading || isRetrainigLoading || isTrainingAutoMlLoading
  const queryClient = useQueryClient()
  const createRetrainingModelMutation = useMutation<
    AxiosResponse<RetrainingJob>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    CreateRetrainingModelRequest
  >(KonanAPI.createRetrainingModel)

  const handleRetrainingModelCreation = (
    predictions_start_time: string,
    predictions_end_time: string,
    append_training_data: boolean,
    resulting_model_name: string,
    resulting_model_state: string,
  ): void => {
    createRetrainingModelMutation
      .mutateAsync({
        predictions_start_time,
        predictions_end_time,
        append_training_data,
        resulting_model_name,
        resulting_model_state,
        project_uuid: projectId as string,
      })
      .then((data) => {
        setNewTrainingJobOpen(false)
        queryClient.invalidateQueries(["models", liveModel?.uuid])
        queryClient.invalidateQueries(["training-jobs", projectId])
        queryClient.invalidateQueries(["training-jobs-automl", projectId])
        queryClient.invalidateQueries(["retraining-jobs", liveModel?.uuid])
        setTimeout(() => {
          const lowerCaseType = data?.data?.status?.toLowerCase()
          setTabValue(mapResponseTypesToTabValues(lowerCaseType))
        }, 500)
        NotificationUtils.toast(`Retraining job is now being processed`, { snackBarVariant: "positive" })
      })
  }

  const loadMoreJobs = (): void => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [tabValue])

  useEffect(() => {
    if (createRetrainingModelMutation.error) {
      setErrorMessage(createRetrainingModelMutation.error?.response?.data.detail)
    }
  }, [createRetrainingModelMutation.error])
  const handleCreationDialogClose = (): void => {
    setNewTrainingJobOpen(false)
    setErrorMessage(undefined)
  }

  useEffect(() => {
    createRetrainingModelMutation.error?.response?.data?.resulting_model_name &&
      NotificationUtils.toast(createRetrainingModelMutation.error?.response?.data?.resulting_model_name[0], {
        snackBarVariant: "negative",
      })
  }, [createRetrainingModelMutation.error?.response?.data?.resulting_model_name])

  return (
    <Fragment>
      {newTrainingJobOpen && (
        <RetrainingCreationDialog
          onModelCreation={handleRetrainingModelCreation}
          retrainingError={errorMessage}
          onClose={handleCreationDialogClose}
          open={newTrainingJobOpen}
          isLoading={createRetrainingModelMutation.isLoading}
        />
      )}
      <Grid container item mt={3}>
        <KonanTabsHeader
          title="New Training job"
          setAction={setNewTrainingJobOpen}
          containerType={"Training Job"}
          setTabValue={setTabValue}
          value={tabValue}
          searchValue={
            tabValue === "success" ? searchValue : tabValue === "failed" ? searchValueFailed : searchValueInProgress
          }
          setSearchValue={
            tabValue === "success"
              ? setSearchValue
              : tabValue === "failed"
                ? setSearchValueFailed
                : setSearchValueInProgress
          }
          sortValue={tabValue === "success" ? sortValue : tabValue === "failed" ? sortValueFailed : sortValueInProgress}
          setSortValue={
            tabValue === "success" ? setSortValue : tabValue === "failed" ? setSortValueFailed : setSortValueInProgress
          }
          sortOptions={["Most Recent", "Job Name"]}
        />
      </Grid>

      <Grid item xs={12} className={styles.cardContainer}>
        <InfiniteScroll
          pageStart={currentPage}
          loadMore={loadMoreJobs}
          hasMore={
            (tabValue === "success" && adjustedAllSuccessfulTrainingJobs && adjustedAllSuccessfulTrainingJobs.length) >=
              currentPage * pageSize ||
            (tabValue === "failed" && adjustedAllFailedTrainingJobs && adjustedAllFailedTrainingJobs.length) >=
              currentPage * pageSize ||
            (tabValue === "inProgress" && adjustedAllPendingTrainingJobs && adjustedAllPendingTrainingJobs.length) >=
              currentPage * pageSize
          }
        >
          <Grid container spacing={2}>
            {isTrainingJobLoading &&
              tabValue === "success" &&
              [1, 2].map((item: number) => (
                <Grid item xs={12} md={6} lg={4} key={item}>
                  <SuccessfulTrainingCardLoader />
                </Grid>
              ))}
            {isTrainingJobLoading &&
              tabValue === "inProgress" &&
              [1, 2].map((item: number) => (
                <Grid item xs={12} md={6} lg={4} key={item}>
                  <PendingFailedTrainingCardLoader cardType="pending" />
                </Grid>
              ))}
            {isTrainingJobLoading &&
              tabValue === "failed" &&
              [1, 2].map((item: number) => (
                <Grid item xs={12} md={6} lg={4} key={item}>
                  <PendingFailedTrainingCardLoader cardType="failed" />
                </Grid>
              ))}

            {tabValue === "success" &&
              !isTrainingLoading &&
              !isRetrainigLoading &&
              !isTrainingAutoMlLoading &&
              adjustedAllSuccessfulTrainingJobs &&
              adjustedAllSuccessfulTrainingJobs?.length > 0 &&
              (adjustedAllSuccessfulTrainingJobs as Array<TrainingAndRetraining>)
                ?.slice(0, currentPage * pageSize)
                .map((item: TrainingAndRetraining, index: number) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      key={item.uuid}
                      style={{
                        paddingTop: "0px !important",
                        marginBottom: index === adjustedAllSuccessfulTrainingJobs?.length - 1 ? 16 : 0,
                      }}
                    >
                      <SuccessfulTrainingJobCard
                        isLoading={isTrainingJobLoading}
                        uuid={item.uuid}
                        status={item.status}
                        startedAt={item?.started_at as string}
                        createdAt={item?.created_at}
                        createdModelName={item.resulting_model_name as string}
                        parentModel={item?.model as string}
                        userName={item.created_by}
                        duration={item.duration as string}
                        liveModel={liveModel}
                        endedAt={item.ended_at as string}
                        trainingDataUUID={item.training_data as string}
                        resultingModel={item.resulting_model as string}
                        dataPoints={
                          item.metrics
                            ? `${item.metrics?.split.train * 100}% / ${item.metrics?.split.test * 100}%`
                            : undefined
                        }
                        createdModelStatus={item.resulting_model_state as string}
                        projectType={cleanType}
                        firstPredictionDate={item.predictions_start_time as string}
                        lastPredictionDate={item.predictions_end_time as string}
                        trainingOrRetraining={
                          item?.training
                            ? item.hasOwnProperty("threshold_metrics")
                              ? "training-automl"
                              : "training-prebuilt"
                            : "retraining"
                        }
                        evalMetrics={item.metrics ? item.metrics?.evaluation : undefined}
                        points={
                          item.metrics ? item.metrics?.split.train * 100 + item.metrics?.split.test * 100 : undefined
                        }
                      />
                    </Grid>
                  )
                })}
            {!isTrainingLoading &&
              !isRetrainigLoading &&
              !isTrainingAutoMlLoading &&
              tabValue === "success" &&
              adjustedAllSuccessfulTrainingJobs &&
              adjustedAllSuccessfulTrainingJobs?.length === 0 &&
              ((succeededTrainingJobs && succeededTrainingJobs?.length > 0) ||
                (succeededRetrainingJobs && succeededRetrainingJobs?.length > 0)) && (
                <Grid className={styles.emptySearch} direction="column" container xs={12} item>
                  <KonanEmptyStateSearch title={searchValue} />
                </Grid>
              )}
            {!isTrainingLoading &&
              !isRetrainigLoading &&
              !isTrainingAutoMlLoading &&
              tabValue === "success" &&
              (trainingModels || retrainingModels) &&
              allSucceededTrainingJobs?.length === 0 && (
                <Grid container item xs={12} className={"empty-container"}>
                  <KonanEmptyState
                    title="No successful training jobs to show"
                    buttonText={currentProject?.type === "credit_scoring" ? "" : "New Training job"}
                    subTitle="Can't find what you're looking for? Check the failed or in progress tabs"
                    setAction={setNewTrainingJobOpen}
                  />
                </Grid>
              )}
            {tabValue === "inProgress" &&
              !isTrainingLoading &&
              !isRetrainigLoading &&
              !isTrainingAutoMlLoading &&
              adjustedAllPendingTrainingJobs &&
              adjustedAllPendingTrainingJobs?.length > 0 &&
              (adjustedAllPendingTrainingJobs as Array<TrainingAndRetraining>)
                ?.slice(0, currentPage * pageSize)
                .map((item: TrainingAndRetraining) => (
                  <Grid item xs={12} md={6} lg={4} key={item.uuid} style={{ paddingTop: "0px !important" }}>
                    <PendingFailedTrainingJobCard
                      isLoading={isTrainingJobLoading}
                      uuid={item.uuid}
                      status={item.status}
                      startedAt={item.started_at as string}
                      createdAt={item.created_at}
                      createdModelName={item.resulting_model_name as string}
                      parentModel={item.model as string}
                      userName={item.created_by}
                      duration={item.duration as string}
                      predictions={item.predictions as string}
                      liveModel={liveModel}
                      cardType="pending"
                      endedAt={item.ended_at as string}
                      trainingDataUUID={item.training_data as string}
                      dataPoints={
                        item.metrics
                          ? `${item.metrics?.split.train * 100}% / ${item.metrics?.split.test * 100}%`
                          : undefined
                      }
                      createdModelStatus={item.resulting_model_state as string}
                      projectType={cleanType}
                      firstPredictionDate={item.predictions_start_time as string}
                      lastPredictionDate={item.predictions_end_time as string}
                      trainingOrRetraining={
                        item?.training
                          ? item.hasOwnProperty("threshold_metrics")
                            ? "training-automl"
                            : "training-prebuilt"
                          : "retraining"
                      }
                    />
                  </Grid>
                ))}
            {!isTrainingJobLoading &&
              tabValue === "inProgress" &&
              adjustedAllPendingTrainingJobs?.length === 0 &&
              ((pendingTrainingJobs && pendingTrainingJobs?.length > 0) ||
                (pendingRetrainingJobs && pendingRetrainingJobs?.length > 0)) && (
                <Grid container item xs={12} className={styles.emptySearch}>
                  <KonanEmptyStateSearch title={searchValueInProgress} />
                </Grid>
              )}
            {!isTrainingJobLoading &&
              tabValue === "inProgress" &&
              (trainingModels || retrainingModels) &&
              allPendingTrainingJobs?.length === 0 && (
                <Grid container xs={12} item className={"empty-container"}>
                  <KonanEmptyState
                    title="No training jobs in progress"
                    buttonText={currentProject?.type === "credit_scoring" ? "" : "New Training job"}
                    subTitle="Can't find what you're looking for? Check the success or failed tabs"
                    setAction={setNewTrainingJobOpen}
                  />
                </Grid>
              )}
            {tabValue === "failed" &&
              !isTrainingLoading &&
              !isRetrainigLoading &&
              !isTrainingAutoMlLoading &&
              (failedRetrainingJobs || failedTrainingJobs) &&
              adjustedAllFailedTrainingJobs &&
              adjustedAllFailedTrainingJobs?.length > 0 &&
              (adjustedAllFailedTrainingJobs as Array<TrainingAndRetraining>)
                ?.slice(0, currentPage * pageSize)
                .map((item: TrainingAndRetraining, index: number) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={item.uuid}
                    style={{
                      paddingTop: "0px !important",
                      marginBottom: index === adjustedAllFailedTrainingJobs?.length - 1 ? 16 : 0,
                    }}
                  >
                    <PendingFailedTrainingJobCard
                      isLoading={isTrainingJobLoading}
                      uuid={item.uuid}
                      status={item.status}
                      startedAt={item.started_at as string}
                      createdAt={item.created_at}
                      createdModelName={item.resulting_model_name as string}
                      parentModel={item.model as string}
                      userName={item.created_by}
                      duration={item.duration as string}
                      liveModel={liveModel}
                      predictions={item.predictions as string}
                      cardType="failed"
                      endedAt={item.ended_at as string}
                      trainingDataUUID={item.training_data as string}
                      dataPoints={
                        item.metrics
                          ? `${item.metrics?.split.train * 100}% / ${item.metrics?.split.test * 100}%`
                          : undefined
                      }
                      createdModelStatus={item.resulting_model_state as string}
                      projectType={cleanType}
                      firstPredictionDate={item.predictions_start_time as string}
                      lastPredictionDate={item.predictions_end_time as string}
                      trainingOrRetraining={
                        item?.training
                          ? item.hasOwnProperty("threshold_metrics")
                            ? "training-automl"
                            : "training-prebuilt"
                          : "retraining"
                      }
                    />
                  </Grid>
                ))}
            {!isTrainingLoading &&
              !isRetrainigLoading &&
              !isTrainingAutoMlLoading &&
              tabValue === "failed" &&
              adjustedAllFailedTrainingJobs?.length === 0 &&
              ((failedTrainingJobs && failedTrainingJobs?.length > 0) ||
                (failedRetrainingJobs && failedRetrainingJobs?.length > 0)) && (
                <Grid container item xs={12} className={styles.emptySearch}>
                  <KonanEmptyStateSearch title={searchValueFailed} />
                </Grid>
              )}
            {!isTrainingJobLoading &&
              tabValue === "failed" &&
              (trainingModels || retrainingModels) &&
              allFailedTrainingJobs?.length === 0 && (
                <Grid container xs={12} item className={"empty-container"}>
                  <KonanEmptyState
                    title="No failed training jobs to show"
                    buttonText={currentProject?.type === "credit_scoring" ? "" : "New Training job"}
                    subTitle="Can't find what you're looking for? Check the success or in progress tabs"
                    setAction={setNewTrainingJobOpen}
                  />
                </Grid>
              )}

            {(tabValue === "success" &&
              adjustedAllSuccessfulTrainingJobs &&
              adjustedAllSuccessfulTrainingJobs.length) >=
              currentPage * pageSize ||
            (tabValue === "failed" && adjustedAllFailedTrainingJobs && adjustedAllFailedTrainingJobs.length) >=
              currentPage * pageSize ||
            (tabValue === "inProgress" && adjustedAllPendingTrainingJobs && adjustedAllPendingTrainingJobs.length) >=
              currentPage * pageSize ? (
              <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                <Box mt={3} />
                <CircularProgress size={36} />
                <Box mt={1} />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </InfiniteScroll>
      </Grid>
    </Fragment>
  )
}
