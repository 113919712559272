/** Implementation of the rendered row that has the highlighter component.
 * The Highlighter searches for the search value in each row of the modified data using the index
 * and it is rendered as a list item in the react-virtualized list **/
import { useEffect, useRef } from "react"

import Highlighter from "react-highlight-words"

import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "./KonanLogViewer.module.scss"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const KonanRowRender = ({ index, setSize, windowWidth, searchValue, modifiedData }: any): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowRef = useRef<any>()
  useEffect(() => {
    if (rowRef?.current) {
      setSize(index, rowRef.current.getBoundingClientRect().height)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, index, setSize, modifiedData])

  return (
    <div className={styles.row} ref={rowRef}>
      <div style={{ flex: "0 0 auto", width: "35px " }}>
        <Typography variant="p" disabled variantColor={2} style={{ color: "gray" }}>
          {index + 1}
        </Typography>
      </div>

      <div style={{ padding: "0px 4px", textAlign: "start", textAlignLast: "start", lineHeight: "20px" }}>
        <Highlighter
          key={index}
          className={styles.field}
          highlightClassName="YourHighlightClass"
          searchWords={[searchValue]}
          autoEscape={true}
          textToHighlight={modifiedData[index as number]}
        />
      </div>
    </div>
  )
}
