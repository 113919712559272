import React from "react"

import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined"
import { Grid } from "@mui/material"
import { Button, ThemeProvider, Typography, darkTheme } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"

interface Props {
  name?: string
  onRefetch?: () => Promise<unknown>
  isFetching?: boolean
}

/**
 * General component to display error message when fetching given query, and refetch action
 * to re-try fetching the requested query
 * @param {string} name name of the failed requested query ex:projects, workflows, etc..
 * @param {function} onRefetch action for refetching
 * @param {boolean} isFetching indicator if query is being fetched
 * @returns {React.ReactElement}
 */
export function ErrorStateWithRefetch(props: Readonly<Props>): React.ReactElement {
  const { name, onRefetch, isFetching } = props

  const theme = getTheme()

  return (
    <Grid container gap={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <ThemeProvider theme={name === "script" ? darkTheme : theme}>
        <Typography variant="h3-bold">{`Something went wrong`}</Typography>

        <Typography
          variantColor={2}
          variant="p"
        >{`Reload to try again. If problem persists, contact your administrator.`}</Typography>
      </ThemeProvider>

      <Button
        startIcon={<ReplayOutlinedIcon fontSize="small" />}
        disabled={isFetching}
        variant="primary"
        onClick={async () => await onRefetch?.()}
      >
        Reload
      </Button>
    </Grid>
  )
}
