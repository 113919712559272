import React, { Fragment } from "react"

import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { BarDatum, ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { ModelAverageCpuUsage } from "../../types/custom/projects"
import { customRound } from "../../utils/genericHelpers"
import { orderModelsByStatus } from "../../utils/searchSortFilterHelpers"
import { GraphHeader } from "../GraphHeader"

type Props = {
  title?: string
  data?: ModelAverageCpuUsage[]
  graphHeight: number
  isLoading: boolean
  isRamUsage?: boolean
  range?: number
}

export function UsageBarChart(props: Props): React.ReactElement {
  const { title, data, graphHeight, isLoading, isRamUsage, range } = props

  const theme = getTheme()

  const maxLabelLength = 24

  const adjustedData: ModelAverageCpuUsage[] = React.useMemo(() => {
    if (data && data.length > 0) {
      let newData = Array.from(data)

      newData = newData.filter((item) => {
        return item.average > 0
      })

      return orderModelsByStatus(newData, "desc")
    }
    return []
  }, [data])

  return (
    <div className={"charts-paper"}>
      <GraphHeader
        title={title}
        tooltipText={
          isRamUsage
            ? "The average and maximum percentage of RAM used by the model from the total available memory"
            : "The average and maximum percentage of CPU used by the model from the total available cores"
        }
        range={range}
      />

      <Box style={{ height: graphHeight, padding: "4px 16px 8px 16px" }}>
        {(!data || data.length < 1) && !isLoading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            <Typography variant="p" variantColor={2}>
              No data to display
            </Typography>
          </Grid>
        ) : isLoading || !data ? (
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className={"full-height"}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="75%" />
            <Skeleton animation="wave" width="60%" height={30} />
            <Skeleton animation="wave" width="30%" />
          </Grid>
        ) : (
          <ResponsiveBar
            data={adjustedData as BarDatum[]}
            theme={NivoTheme(theme)}
            indexBy="name"
            keys={["average", "total", "max"]}
            colors={[
              theme.palette.blue.background[1],
              theme.palette.grayscale.border,
              theme.palette.blue.background[2],
            ]}
            colorBy="id"
            margin={{ top: 30, right: 0, bottom: -20, left: 0 }}
            padding={0.75}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            maxValue={isRamUsage ? 4 : 100}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            // valueFormat={(v) => v.toFixed(0)}
            enableLabel={false}
            enableGridY={false}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["brighter", 3]] }}
            animate={true}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -22,
                itemsSpacing: 5,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 15,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            legendLabel={(v) => v.id.toString().toUpperCase()}
            labelSkipWidth={2000} // labelSkipWidth = minimum bar size needed for a normal label
            layers={[
              "grid",
              "axes",
              "bars",
              "markers",
              "legends",
              "annotations",
              ({ bars }) => {
                return (
                  <g>
                    {bars.map(({ width, height, x, y, key, data }) => {
                      return width ? (
                        <Fragment key={key}>
                          {/* Model name on bar */}
                          {data.id === "total" ? (
                            <text
                              //Calculate position of model name
                              transform={`translate(${0}, ${y - 9})`}
                              textAnchor="left"
                              dominantBaseline="central"
                              fill={theme.palette.grayscale.text[2]}
                              style={{
                                fontFamily: '"Inter", sans-serif',
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: 12,
                                lineHeight: "16px",
                              }}
                            >
                              {`${
                                key.slice(key.indexOf(".") + 1)
                                  ? //truncate model name if it's too long
                                    key.slice(key.indexOf(".") + 1).length > maxLabelLength
                                    ? key.slice(key.indexOf(".") + 1).substring(0, maxLabelLength) + "..."
                                    : key.slice(key.indexOf(".") + 1)
                                  : ""
                              }`}
                            </text>
                          ) : (
                            ""
                          )}

                          {!data.value ? (
                            ""
                          ) : //Right text on bar
                          data.id === "total" ? (
                            <text
                              transform={`translate(${width - (isRamUsage ? 40 : 50)}, ${y - 9})`}
                              textAnchor="right"
                              dominantBaseline="central"
                              fill={theme.palette.grayscale.text[2]}
                              style={{
                                fontFamily: '"Inter", sans-serif',
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: 12,
                                lineHeight: "16px",
                              }}
                            >
                              {data.value ? (isRamUsage ? "4GB" : "1 Core") : ""}
                            </text>
                          ) : (
                            ""
                          )}
                          {/* add max marker */}
                          <rect
                            x={(Number(data.data.realMax) / Number(data.data.average)) * x}
                            y={y}
                            width="5"
                            height={data.id === "total" ? height : 0}
                            fill="#B4DCF0"
                          />
                        </Fragment>
                      ) : null
                    })}
                  </g>
                )
              },
            ]}
            tooltip={(slice) => {
              return (
                <div className={"chart-tooltip"}>
                  <Typography variant="h3-bold" variantColor={2}>
                    {slice.data.name}
                  </Typography>
                  <Typography variant="p" variantColor={2}>
                    {isRamUsage
                      ? `Average: ${slice.data.average ? customRound(Number(slice.data.average) * 1024, 2) : 0}MB`
                      : `Average: ${slice.data.average ? customRound(Number(slice.data.average), 2) : 0}%`}
                  </Typography>
                  <Typography variant="p" variantColor={2}>
                    {isRamUsage
                      ? `Max: ${slice.data.realMax ? customRound(Number(slice.data.realMax) * 1024, 2) : 0}MB`
                      : `Max: ${slice.data.realMax ? customRound(Number(slice.data.realMax), 2) : 0}%`}
                  </Typography>
                </div>
              )
            }}
          />
        )}
      </Box>
    </div>
  )
}
