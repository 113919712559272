import React, { useMemo, useState } from "react"

import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Grid } from "@mui/material"
import { Button } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState } from "../../../components/KonanEmptyState"
import { ErrorStateWithRefetch } from "../../../components/UI/ErrorStateWithRefetch"
import { SelectionLoaderCard } from "../../../components/cards/SelectionCard"
import { DebouncedInput } from "../../../components/form/DebouncedInput"
import { KonanAPI } from "../../../services/KonanAPI"
import { CustomScriptTemplate } from "../../../types/custom/workflows"
import { PaginatedScriptTemplateList } from "../../../types/generated/api/PaginatedScriptTemplateList"
import { extractPageFromBackEndPaginationLink } from "../../../utils/genericHelpers"
import { BuiltinCreationStepProps } from "../Interfaces"
import { TemplateCard } from "./TemplateCard"

import styles from "../Scripts.module.scss"

/**
 * Displays a step in the script creation process where a user can select a template from a list.
 * It includes search functionality to filter through templates and infinite scrolling to handle
 * a large number of templates. The component allows users to select a template and either
 * continue using the selected template or cancel the process.
 *
 * The function utilizes an infinite query to fetch and display ready-to-use script templates
 * from a backend service, handling pagination and search dynamically. It updates the selection
 * state based on user interaction and triggers appropriate actions through callbacks.
 *
 * @param {function} onTemplateSelect - Callback to handle the selection of a template.
 * @param {function} onClose - Callback to handle closing the component.
 * @param {string} selectedTemplate
 * @param {function} onUseTemplateClick - Callback to handle the action when the "Use Template" button is clicked.
 * @returns {React.ReactElement} The rendered component showing a list of templates and control buttons.
 */
export function BuiltinScriptCreationStep(props: Readonly<BuiltinCreationStepProps>): React.ReactElement {
  const { onTemplateSelect, selectedTemplate, onClose, onUseTemplateClick, scripts } = props
  const { id: projectId } = useParams<{ id: string }>()

  const [searchValue, setSearchValue] = useState<string | null>("")

  // fetch ready templates
  const {
    isLoading: isTemplatesLoading,
    isFetching: isTemplatesFetching,
    data: templates,
    isFetchingNextPage,
    isError,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<AxiosResponse<PaginatedScriptTemplateList>, AxiosError>(
    ["ready-templates", projectId, searchValue],
    ({ pageParam = 1 }) =>
      KonanAPI.fetchReadyTemplates({
        projectUUID: projectId as string,
        page: pageParam,
        page_size: 3,
        search: searchValue as string,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },

      enabled: !!projectId,
      refetchOnMount: true,
    },
  )

  // extracting the results after infinite-Query
  const adjustedTemplates = useMemo(() => {
    const allTemplates = templates?.pages.reduce((accumulator, page) => {
      return accumulator.concat(page.data.results ?? [])
    }, [] as CustomScriptTemplate[])

    let firstAvailableTemplateSelected = false

    allTemplates?.forEach((template) => {
      const isInUse = scripts?.some((script) => script?.template === template?.uuid)
      template.isInUse = isInUse

      if (!isInUse && !firstAvailableTemplateSelected && !selectedTemplate) {
        onTemplateSelect(template.uuid)
        firstAvailableTemplateSelected = true
      }
    })

    const orderedTemplates = allTemplates?.sort((a, b) => {
      // Convert true to 1, false or undefined to 0
      const aIsInUse = a.isInUse ? 1 : 0
      const bIsInUse = b.isInUse ? 1 : 0

      // Sort by ascending order of isInUse; false (0) comes before true (1)
      return aIsInUse - bIsInUse
    })

    return orderedTemplates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates?.pages, scripts, selectedTemplate])

  return (
    <Grid item container>
      {/* Search input */}
      <Grid item xs={12}>
        <DebouncedInput
          callback={(value: string | null) => {
            setSearchValue(value)
          }}
          fullWidth
          placeHolder="Search Templates"
          variant="filled"
          isSearch
        />
      </Grid>

      {/* Templates listing and empty states */}
      <Grid item xs={12} mt={2} className={styles.templatesContainer} id="templates-scrollable-target">
        {isTemplatesLoading ? (
          <Grid container item xs={12} gap={1.5} pb={2}>
            {[1, 2].map((item) => {
              return (
                <Grid item xs={12} key={item}>
                  <SelectionLoaderCard />
                </Grid>
              )
            })}
          </Grid>
        ) : (
          <InfiniteScroll
            dataLength={adjustedTemplates && adjustedTemplates.length > 0 ? adjustedTemplates.length : 0}
            next={() => !isTemplatesLoading && !isFetchingNextPage && fetchNextPage()}
            hasMore={hasNextPage ?? false}
            loader={
              <Grid container item xs={12} gap={1.5} pb={2}>
                {[1, 2].map((item) => {
                  return (
                    <Grid item xs={12} key={item}>
                      <SelectionLoaderCard />
                    </Grid>
                  )
                })}
              </Grid>
            }
            scrollableTarget={"templates-scrollable-target"}
          >
            <Grid container item xs={12} gap={1.5} pb={2}>
              {adjustedTemplates && adjustedTemplates?.length > 0 ? (
                adjustedTemplates.map((template: CustomScriptTemplate) => (
                  <Grid
                    item
                    xs={12}
                    key={template.uuid}
                    mr={
                      (templates?.pages[0]?.data?.count as number) &&
                      templates?.pages[0]?.data?.count &&
                      templates?.pages[0]?.data?.count > 3
                        ? 0.5
                        : 0
                    }
                  >
                    <TemplateCard
                      onSelect={() => onTemplateSelect(template.uuid)}
                      isSelected={selectedTemplate === template.uuid}
                      name={template.name}
                      description={template?.short_description}
                      isInUse={template?.isInUse}
                    />
                  </Grid>
                ))
              ) : (
                <Grid container alignItems="center" justifyContent="center" item minHeight="25dvh">
                  {isError ? (
                    <ErrorStateWithRefetch
                      onRefetch={() => refetch()}
                      isFetching={isTemplatesLoading || isTemplatesFetching}
                      name="ready templates"
                    />
                  ) : (
                    (searchValue || adjustedTemplates?.length === 0) && (
                      <KonanEmptyState
                        title={searchValue ? `No results for '${searchValue}'` : "No ready templates found, yet."}
                        subTitle={searchValue ? "Adjust the search value and try again" : undefined}
                      />
                    )
                  )}
                </Grid>
              )}
            </Grid>
          </InfiniteScroll>
        )}
      </Grid>

      {/* Action Buttons */}
      <Grid container item xs={12} gap={1.5} mt={2} pb={1}>
        <Grid item xs={12}>
          <Button
            variant="primary"
            onClick={onUseTemplateClick}
            id="main_btn"
            fullWidth
            disabled={!selectedTemplate}
            size="large"
          >
            Use Template
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button variant="ghost" onClick={onClose} autoFocus fullWidth size="large">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
