/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ScoreCardList = {
  uuid: string
  name: string
  readonly created_at: string
  created_by: string
  /**
   * * `LIST` - LIST
   * * `TABLE` - TABLE
   */
  format?: ScoreCardList.format
}
export namespace ScoreCardList {
  /**
   * * `LIST` - LIST
   * * `TABLE` - TABLE
   */
  export enum format {
    LIST = "LIST",
    TABLE = "TABLE",
  }
}
