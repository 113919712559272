import React, { Fragment, ReactElement, useCallback, useEffect, useRef, useState } from "react"

import { useIsMutating, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { Box, CircularProgress, Grid } from "@mui/material"
import { Button, InputChangeEvent, InputText, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { FormikProps } from "formik"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { queryClient } from "../../.."
import { SwitchIcon } from "../../../assets/SvgIcons"
import { KonanLogViewer } from "../../../components/KonanLogViewer"
import { MonacoTextEditor } from "../../../components/MonacoTextEditor"
import { AccordionHeader } from "../../../components/UI/AccordionHeader"
import { UploadButton } from "../../../components/UI/CustomUpload"
import { ErrorStateWithRefetch } from "../../../components/UI/ErrorStateWithRefetch"
import { KonanPagination } from "../../../components/tables/KonanPagination"
import { getTheme } from "../../../hooks/UseTheme"
import { KonanAPI } from "../../../services/KonanAPI"
import { readFileContentAsync } from "../../../utils/fileUploader"
import { KonanTimeHelper, safeParse, safeStringify } from "../../../utils/genericHelpers"
import { RequirementsDataFile, ScriptEditorProps } from "../Interfaces"
import { RequirementsList } from "./RequirementsList"

import styles from "../Scripts.module.scss"

/**
 * Script editor responsible for showing/editing editor and logs from running tests
 *
 * @param {boolean} isLoading
 * @param {ScriptGroupRetrieve} script
 * @param {boolean} isReadMode
 * @param {FormikProps} formik
 * @param {string[]} consoleLogs
 * @param {boolean} isFetchingFileError
 * @param {string[]} logs
 * @returns {React.ReactElement}
 */
export function CustomScriptEditor(props: Readonly<ScriptEditorProps>): React.ReactElement {
  const {
    isLoading,
    script,
    isReadMode,
    formik,
    consoleLogs,
    isFetchingFileError,
    logs,
    isRefetchingFile,
    isConsoleExpanded,
    isLogsExpanded,
    setIsConsoleExpanded,
    setIsLogsExpanded,
    isActionButtonDisabled,
    onActionButtonClick,
    isBuildingState = false,
  } = props

  const theme = getTheme()

  const CURRENT_PY_VERSION = "3.10"

  const isTestScriptMutationLoading = useIsMutating({ mutationKey: ["Test-script-mutation", script?.uuid] })
  const isCancellingScriptMutationLoading = useIsMutating({
    mutationKey: ["cancelBuildingScript", script?.uuid, script?.active_version?.version],
  })

  const onUploadFile = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    e.stopPropagation()
    const file = e.target.files?.[0]
    if (file) {
      try {
        const fileContent = await readFileContentAsync(file)
        formik.setFieldValue("snippet", fileContent)
      } catch (error) {
        NotificationUtils.toast("Failed to upload file", { snackBarVariant: "negative" })
      }
    }
    e.target.value = ""
  }

  const handleAccordionHeaderClick = (): void => {
    if (!isBuildingState) {
      onActionButtonClick()
      setIsConsoleExpanded()
      setIsLogsExpanded()
    } else {
      onActionButtonClick()
    }
  }

  return (
    <Grid container item xs={12} flexDirection={"column"}>
      <Grid item>
        {isLoading ? (
          <Grid
            container
            item
            xs={12}
            minHeight={"450px"}
            alignItems={"center"}
            justifyContent={"center"}
            style={{ borderRadius: "8px 8px 0px 0px" }}
          >
            <CircularProgress style={{ color: theme.palette.blue.text[2] }} size={36} />
          </Grid>
        ) : (
          <Fragment>
            <Grid>
              <AccordionHeader
                view={"code"}
                isBuildingState={isBuildingState}
                actionButtonProps={{
                  shouldShow: true,
                  isDisabled: isActionButtonDisabled,
                  onClick: handleAccordionHeaderClick,
                }}
                isLoading={isBuildingState ? !!isCancellingScriptMutationLoading : !!isTestScriptMutationLoading}
                /* TODO: periodically revisit current PY version used in scripts w/DevOps team */
                title={
                  <Box display="flex" gap={1} alignItems={"center"}>
                    {isBuildingState && (
                      <CircularProgress style={{ color: theme?.palette?.grayscale.text[2] }} size={15} />
                    )}
                    <Typography variantColor={2} variant="p">
                      {`Code - (Python) v${script?.language_version ?? CURRENT_PY_VERSION}`}
                    </Typography>
                    {!isReadMode && <UploadButton id="script-file" accept=".py" onChange={onUploadFile} />}
                  </Box>
                }
              />

              {(isLoading || isRefetchingFile) && (
                <Grid container item xs={12} className={styles.scriptDialogLoader}>
                  <CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />
                </Grid>
              )}

              <Grid
                style={{
                  opacity: 1,
                  transition: "opacity 0.5s ease-in",
                }}
              >
                {isFetchingFileError && !isRefetchingFile ? (
                  <Grid container item height={400}>
                    <ErrorStateWithRefetch
                      onRefetch={() =>
                        queryClient.fetchQuery(["getFileContent", script?.uuid, script?.active_version.version])
                      }
                      isFetching={isLoading}
                      name="script"
                    />
                  </Grid>
                ) : (
                  !isLoading &&
                  !isRefetchingFile && (
                    <MonacoTextEditor
                      key={formik.values.id}
                      value={formik.values.snippet}
                      height="310px"
                      language="python"
                      onChange={(text) => formik.setFieldValue("snippet", text)}
                      enableCopy
                      renderLineHighLight="none"
                      wordWrap="on"
                      width="100%"
                      loading={<CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />}
                      readOnly={isReadMode}
                    />
                  )
                )}
              </Grid>
            </Grid>

            <Grid item>
              <AccordionHeader
                title="Script output"
                isAccordionExpanding={isConsoleExpanded}
                actionButtonProps={{
                  onClick: () => setIsConsoleExpanded(),
                }}
              />

              {isConsoleExpanded && (
                <KonanLogViewer
                  enableSearch={false}
                  customBorderRadius="0px"
                  data={consoleLogs}
                  isLoading={!!isTestScriptMutationLoading}
                  minHeight="150px"
                />
              )}
            </Grid>

            <Grid item>
              <AccordionHeader
                title="Logs"
                isAccordionExpanding={isLogsExpanded}
                actionButtonProps={{
                  onClick: () => setIsLogsExpanded(),
                }}
              />

              {isLogsExpanded && (
                <KonanLogViewer
                  customBorderRadius="0px 0px 4px 4px"
                  data={logs?.length > 0 ? logs : []}
                  isLoading={!!isTestScriptMutationLoading}
                  minHeight="150px"
                  scriptNodeView
                />
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Grid>
  )
}

/**
 * Historical logs dialog responsible for showing logs of current script up to the last 7 days
 *
 * @param {string} uuid
 * @returns {React.ReactElement}
 */
export function CustomScriptLogs(props: Readonly<{ uuid: string }>): React.ReactElement {
  const { uuid } = props
  const { id: projectId } = useParams<{ id: string }>()

  const konanTime = new KonanTimeHelper()

  const [startDate, setStartDate] = useState(konanTime.adjustDate(moment().subtract(6, "days"), "start"))
  const [endDate, setEndDate] = useState(konanTime.adjustDate(moment(), "end"))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isLoading, data } = useQuery<any, AxiosError>(
    ["script-logs", projectId, startDate, endDate, uuid],
    () =>
      KonanAPI.fetchScriptLogs({
        start_date: startDate?.toISOString() as string,
        end_date: endDate?.toISOString() as string,
        project_uuid: projectId as string,
        script_uuid: uuid,
      }),
    { enabled: !!startDate && !!endDate, refetchInterval: 3000 },
  )

  return (
    <Grid item xs={12}>
      <AccordionHeader title="Logs" view={"code"} />

      <KonanLogViewer
        customBorderRadius="0px 0px 4px 4px"
        data={data?.logs ?? []}
        isLoading={isLoading}
        minHeight="328px"
        scriptNodeView
        enableDatePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        isOutSideRange={konanTime.lastWeekOnly}
      />
    </Grid>
  )
}

const MemoizedCustomPagination = React.memo(KonanPagination)

const TestFeatureItem = (props: {
  handleRemove: () => void
  handleFeatureChange: (e: InputChangeEvent<Element>) => void
  handleValueChange: (e: InputChangeEvent<Element>) => void
  feature: { feat: string; value: string | number | null }
  enableDeletion: boolean
}): ReactElement => {
  const { handleRemove, handleFeatureChange, handleValueChange, feature, enableDeletion } = props

  return (
    <Grid container item xs={12} style={{ borderBottom: "1px solid var(--grayscale-border)" }}>
      <Grid item xs={6} className={styles.testFeature}>
        <InputText
          value={feature.feat}
          handleChange={(e) => handleFeatureChange(e)}
          placeholder={"Feature"}
          hideDescription
          fullWidth
        />
      </Grid>

      <Grid item xs={6} className={styles.testValue}>
        <InputText
          value={safeStringify(feature.value)}
          handleChange={(e) => handleValueChange(e)}
          placeholder={"Value"}
          hideDescription
          fullWidth
        />

        <Button disabled={!enableDeletion} onClick={async () => handleRemove()}>
          <DeleteOutlineIcon
            fontSize="medium"
            style={{ fill: enableDeletion ? "var(--gray-background-1)" : "var(--grayscale-border)" }}
          />
        </Button>
      </Grid>
    </Grid>
  )
}

/**
 * Table where you can add/remove/edit test features used by script while testing
 *
 * @param {FormikProps} formik
 * @returns {React.ReactElement}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomScriptTestFeatures(props: Readonly<{ formik: FormikProps<any> }>): React.ReactElement {
  const { formik } = props
  const [page, setPage] = useState<number>(0)
  const [isJsonMode, setIsJsonMode] = useState<boolean>(false)
  const [jsonText, setJsonText] = useState<string>("")
  const [tableData, setTableData] = useState<{ feat: string; value: string | number | null; uuid: string }[]>([])
  const pageSize = 5

  const convertJsonToTable = useCallback((): void => {
    try {
      const testFeatures = JSON.parse(jsonText)
      setTableData(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.entries(testFeatures).map(([key, value]: [string, any]) => ({ feat: key, value, uuid: uuidv4() })),
      )
    } catch {
      throw new Error("Invalid JSON format")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonText])

  const convertTableDataToJson = useCallback((): void => {
    const testFeatures = tableData.reduce((acc, item) => ({ ...acc, [item.feat]: item.value }), {})
    setJsonText(JSON.stringify(testFeatures, null, 2))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  const convertJsonToTestFeatures = useCallback((): void => {
    try {
      const testFeatures = JSON.parse(jsonText)
      formik.setFieldValue("testFeatures", testFeatures)
    } catch {
      throw new Error("Invalid JSON format")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonText])

  const convertTableDataToTestFeatures = useCallback((): void => {
    const testFeatures = tableData.reduce((acc, item) => ({ ...acc, [item.feat]: item.value }), {})
    formik.setFieldValue("testFeatures", testFeatures)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  const convertTestFeaturesToTableData = useCallback((): void => {
    setTableData(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.entries(formik.values.testFeatures).map(([key, value]: [string, any]) => ({
        feat: key,
        value,
        uuid: uuidv4(),
      })),
    )
  }, [formik.values.testFeatures])

  const onUploadFile = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    e.stopPropagation()
    const file = e.target.files?.[0]
    if (file) {
      try {
        const fileContent = await readFileContentAsync(file)
        setJsonText(fileContent)
      } catch (error) {
        NotificationUtils.toast("Failed to upload file", { snackBarVariant: "negative" })
      }
    }
    e.target.value = ""
  }

  const handleRemove = (item: { uuid: string }): void => {
    setTableData((tableData) => [
      ...tableData.filter(
        (testFeature: { feat: string; value: string | number | null; uuid: string }) => testFeature.uuid !== item.uuid,
      ),
    ])
  }

  const handleSwitchMode = (): void => {
    // now we in json mode and we want to switch to test features
    try {
      if (isJsonMode) {
        convertJsonToTable()
      } else {
        convertTableDataToJson()
      }
      setIsJsonMode(!isJsonMode)
    } catch (e) {
      NotificationUtils.toast(e?.toString() ?? "Cannot switch mode", { snackBarVariant: "negative" })
    }
  }

  const handleAddTestFeature = (): void => {
    setTableData((tableData) => [{ feat: "", value: "", uuid: uuidv4() }, ...tableData])
    setPage(0)
  }

  // go back one page automatically if the last page has no more items to show
  useEffect(() => {
    tableData.length && tableData.length <= page * pageSize && setPage(page - 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData.length])

  // run on component unmounting
  // use refs to keep track of states in cleanup function
  const isJsonModeRef = useRef(isJsonMode)
  const convertJsonToTestFeaturesRef = useRef(convertJsonToTestFeatures)
  const convertTableDataToTestFeaturesRef = useRef(convertTableDataToTestFeatures)

  useEffect(() => {
    isJsonModeRef.current = isJsonMode
    convertJsonToTestFeaturesRef.current = convertJsonToTestFeatures
    convertTableDataToTestFeaturesRef.current = convertTableDataToTestFeatures
  }, [isJsonMode, convertJsonToTestFeatures, convertTableDataToTestFeatures])

  // initialize table data
  useEffect(() => {
    convertTestFeaturesToTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      try {
        if (isJsonModeRef.current) {
          convertJsonToTestFeaturesRef.current()
        } else {
          convertTableDataToTestFeaturesRef.current()
        }
      } catch (e) {
        NotificationUtils.toast(e?.toString() ?? "Invalid Json format", { snackBarVariant: "negative" })
      }
    }
  }, [])

  return (
    <Grid container pt={2}>
      <Grid item xs={12} px={3} pb={1} className={styles.testFeaturesActionsContainer}>
        {!isJsonMode && (
          <Button size="regular" variant="ghost" onClick={handleAddTestFeature}>
            + Add Test Feature
          </Button>
        )}

        {isJsonMode && (
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="h3-bold">JSON Editor</Typography>
            <UploadButton id="upload-json" accept=".json" onChange={onUploadFile} />
          </Box>
        )}
        <Button
          size="regular"
          startIcon={<SwitchIcon width="16" height="17" fill="var(--neutral-text-active)" />}
          variant="ghost"
          onClick={handleSwitchMode}
        >
          {isJsonMode ? "Switch to Table" : "Switch to JSON"}
        </Button>
      </Grid>
      {/* Test features table */}
      {!isJsonMode && (
        <Grid container item xs={12} mx={3}>
          <Grid container item xs={12} px={1} py={1} style={{ borderBottom: "1px solid var(--grayscale-border)" }}>
            <Grid item xs={6}>
              <Typography variant={"a"}>Feature</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"a"}>Value</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} flexGrow={1} height="285px">
            {tableData
              .slice(page * pageSize, page * pageSize + pageSize)
              ?.map((item: { feat: string; value: string | number | null; uuid: string }, index: number) => {
                return (
                  <TestFeatureItem
                    key={item.uuid}
                    idx={page * pageSize + index}
                    handleRemove={() => handleRemove(item)}
                    enableDeletion={tableData.length > 0}
                    handleFeatureChange={(e) => {
                      const newTableData = [...tableData]
                      newTableData[+page * +pageSize + index].feat = safeStringify(e.target.value)
                      setTableData(newTableData)
                    }}
                    handleValueChange={(e) => {
                      const newTableData = [...tableData]
                      newTableData[+page * +pageSize + index].value = safeParse(e.target.value)
                      setTableData(newTableData)
                    }}
                    feature={item}
                  />
                )
              })}
          </Grid>

          {!!tableData.length && (
            <Grid item xs={12}>
              <MemoizedCustomPagination
                stripped={true}
                count={tableData.length ?? 0}
                page={page}
                onPageChange={(index: number) => setPage(index)}
                rowsPerPage={pageSize}
                hideRowCount
                variant="simple"
                debounceTime={0}
              />
            </Grid>
          )}
        </Grid>
      )}
      {isJsonMode && (
        <MonacoTextEditor
          key={formik.values.id}
          value={jsonText}
          height="382px"
          language="json"
          onChange={(text) => setJsonText(text ?? "")}
          enableCopy
          renderLineHighLight="none"
          wordWrap="on"
          width="100%"
          loading={<CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />}
          readOnly={false}
        />
      )}
    </Grid>
  )
}

/**
 * Requirements packages editor responsible for showing/editing requirements packages used by script
 * while testing
 * @param {FormikProps} formik
 * @param {boolean} isReadMode
 * @param {boolean} isLoading
 * @param {RequirementsDataFile[]} options
 * @param {boolean} isLoadingOptions
 * @param {Function} setRequirementsFile
 * @param {RequirementsDataFile} requirementsFile
 * @returns {React.ReactElement}
 */
export function CustomRequirementsPackages(
  props: Readonly<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>
    isReadMode: boolean
    isLoading: boolean
    options: RequirementsDataFile[]
    isLoadingOptions: boolean
    setRequirementsFile: (file: RequirementsDataFile) => void
    requirementsFile: RequirementsDataFile
  }>,
): React.ReactElement {
  const { formik, isReadMode, isLoading, options, isLoadingOptions, requirementsFile, setRequirementsFile } = props

  const onUploadFile = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    e.stopPropagation()
    const file = e.target.files?.[0]
    if (file) {
      try {
        const fileContent = await readFileContentAsync(file)
        formik.setFieldValue("requirements", fileContent)
      } catch (error) {
        NotificationUtils.toast("Failed to upload file", { snackBarVariant: "negative" })
      }
    }
    formik.setFieldValue("requirementsFileName", file?.name)
    setRequirementsFile({ label: file?.name })
    e.target.value = ""
  }

  return (
    <Grid item xs={12}>
      <AccordionHeader
        view="code"
        /* TODO: periodically revisit current PY version used in scripts w/DevOps team */
        title={
          isReadMode ? (
            (formik.values.requirementsFileName ?? `requirements.txt`)
          ) : (
            <Box display="flex" justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
              <RequirementsList
                requirements={options}
                setRequirementsFile={setRequirementsFile}
                requirementsFile={requirementsFile}
                isLoading={isLoadingOptions}
              />
              <UploadButton id="requirements-file" accept=".txt" onChange={onUploadFile} />
            </Box>
          )
        }
      />
      {isLoading ? (
        <Grid container item xs={12} className={styles.scriptDialogLoader}>
          <CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />
        </Grid>
      ) : (
        <MonacoTextEditor
          key={formik.values.id}
          value={isLoading ? "" : formik.values.requirements}
          height={"397px"}
          language="plaintext"
          onChange={(text) => formik.setFieldValue("requirements", text)}
          enableCopy
          renderLineHighLight="none"
          wordWrap="on"
          // width="100%"
          loading={<CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />}
          readOnly={isReadMode}
        />
      )}
    </Grid>
  )
}
