import React, { Fragment } from "react"

import { Box, Grid, Skeleton } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { MetricsState } from "../../types/custom/projects"
import { GraphHeader } from "../GraphHeader"
import { InfoContainer } from "../InfoContainer"

type Props = {
  title: string
  tooltipText?: string
  isLoading: boolean
  emptyState?: MetricsState
  graphHeight: number
  range?: number
  children: React.ReactElement
  selectProps?: {
    options: string[]
    value: string
    setValue: (val: string) => void
  }
  headerMode?: "full" | "select" | "none"
}

/**
 * Graph container to encapsulate (loading, empty, rendering ,...ext)  states
 * @return {React.ReactElement}
 */
export function ChartsContainer(props: Readonly<Props>): React.ReactElement {
  const {
    graphHeight = 400,
    title,
    range,
    tooltipText,
    isLoading,
    emptyState,
    children,
    selectProps,
    headerMode = "full",
  } = props

  return (
    <div className={"charts-paper-darker"}>
      {/* hide header when the mode is "select" and the data is still loading */}
      {!(isLoading && headerMode === "select") && (
        <GraphHeader
          title={title}
          tooltipText={tooltipText}
          range={range}
          selectProps={selectProps}
          headerMode={headerMode}
        />
      )}

      <Box display={"flex"} style={{ height: graphHeight, padding: isLoading ? "0px 16px" : "0px" }}>
        {isLoading ? (
          // Loading skeletons
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className={"full-height"}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="75%" />
            <Skeleton animation="wave" width="60%" height={30} />
            <Skeleton animation="wave" width="30%" />
          </Grid>
        ) : emptyState ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            {emptyState?.title ? (
              <InfoContainer
                title={emptyState?.title}
                subtitle={emptyState?.description}
                action={emptyState?.action ? <Button variant="primary">{emptyState?.action}</Button> : undefined}
              />
            ) : (
              <Typography variant="p">No predictions or feedback</Typography>
            )}
          </Grid>
        ) : (
          <Fragment>{children}</Fragment>
        )}
      </Box>
    </div>
  )
}
