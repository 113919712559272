import { ConditionListFile } from "../generated/api/ConditionListFile"
import { RuleSetCreateRequest } from "../generated/api/RuleSetCreateRequest"
import { RulesetRuleCreateRequest } from "../generated/api/RulesetRuleCreateRequest"
import { ScoreCardRuleCreate } from "../generated/api/ScoreCardRuleCreate"
import { TagListCreateRequest } from "../generated/api/TagListCreateRequest"
import { WorkflowSchemaFeature } from "../generated/api/WorkflowSchemaFeature"
import { Operators } from "./projects"
import { FeatureData, SchemaFeature } from "./workflows"

/**
 * all related custom types for ruleset component.
 */

export type CreateRulesetRequest = {
  projectUUID: string
  name: string
  rulesUUID?: string[]
  rules?: Array<RulesetRuleCreateRequest>
  type?: RuleSetCreateRequest.type
}

export type CreateTaglistRequest = {
  projectUUID: string
  name: string
  rules?: Array<TagListCreateRequest>
}

export type UpdateTaglistRequest = {
  projectUUID: string
  taglistUUID: string
  name?: string
  rules?: Array<TagListCreateRequest>
}

export type UpdateRulesetRequest = {
  projectUUID: string
  rulesetUUID: string
  name?: string
  rules?: Array<CustomRuleRequest>
  type?: RuleSetCreateRequest.type
}

export interface CustomRule {
  name: string
  readonly created_at: string
  uuid?: string
  return_label: string
  condition: string
  feature_nesting_separator?: string
  readonly condition_list_files?: ConditionListFile | null
}

export type BaseScorecard = {
  name: string
  weight: undefined
  uuid: string
  conditionsArray: Array<ScorecardCondition>
}

export type CustomTag = Omit<CustomRule, "return_label">

export interface CustomRuleRequest extends Omit<CustomRule, "created_at" | "condition_list_files"> {
  readonly condition_list_files?: ConditionListFile
  readonly created_at?: string
}

export type CustomTagRequest = Omit<CustomRuleRequest, "return_label">

export interface BaseCondition {
  id?: number | string
  feature: string
  operator: string
  type?: string
  value: string
  valueOrFeature?: "Value" | "Feature"
  secondValue?: string
  secondFeature?: string
}

export interface ScorecardCondition extends BaseCondition {
  return_value: string
  condition_list_files?: ConditionListFile
  andOr: "and" | "or"
  rule: ScoreCardRuleCreate | undefined
  ruleName: string
}

export interface Rule extends BaseCondition {
  andOr: string
  schemaFeatureType: WorkflowSchemaFeature.type
}

export interface FilterCondition extends BaseCondition {
  andOr: "and" | "or"
}

export type ConditionFeatureChange = {
  filteredFeatures: Array<FeatureData | SchemaFeature>
  otherFilteredFeatures: Array<FeatureData | SchemaFeature>
  operator: Operators | string
  operators: Operators[] | string[]
  types: Array<string>
  type: string
  value: string
}

export enum MapValueTypeToFeatureType {
  "true" = WorkflowSchemaFeature.type.BOOLEAN,
  "false" = WorkflowSchemaFeature.type.BOOLEAN,
  "null" = WorkflowSchemaFeature.type.BOOLEAN,
  "string" = WorkflowSchemaFeature.type.TEXT,
  "number" = WorkflowSchemaFeature.type.NUMBER,
}

export type VersionChangeRequest = {
  projectUUID: string
  resourceUUID: string
  resource: "taglists" | "rulesets" | "scorecardsets" | "scorecards" | "workflows" | "programs" | "scripts"
  version: string
}

export type SingleTagCard = {
  id: string
  tagName: string
  levelOneConditions: Rule[]
  levelTwoConditions: Rule[]
  levelThreeConditions: Rule[]
  condition_list_files?: ConditionListFile | null
}

export type VersionHistoryScorecardRule = {
  id: string
  levelOneConditions: Rule[]
  levelTwoConditions: Rule[]
  levelThreeConditions: Rule[]
  condition_list_files: ConditionListFile[]
  ruleWeight: string
}

export type ScorecardRule = {
  id: string
  ruleName: string
  levelOneConditions: Rule[]
  levelTwoConditions: Rule[]
  return_value: string
  condition_list_files: ConditionListFile[]
  schemaFeatureType: WorkflowSchemaFeature.type.TEXT
  type: "simple" | "complex"
}

export type SingleRuleCard = {
  id: string
  ruleName: string
  levelOneConditions: Rule[]
  levelTwoConditions: Rule[]
  levelThreeConditions: Rule[]
  label: string
  condition_list_files: ConditionListFile | null
}

export interface ScoreCardFormikValues {
  uuid: string
  name: string
  type: string
  format: string
  rules: ScorecardRule[]
}

export interface RetrieveLabelsRequest {
  project_uuid: string
  search?: string | null
  page: number
  pageSize: number
}

export type NewFeatureData = {
  isRequired: boolean
  type: WorkflowSchemaFeature.type
  workflow: string
}

export type getNewRuleInfoResult = {
  commonFeature: { name: string; type: string | undefined }
  nonEmptyFeatures: Rule[]
}
