import React from "react"

import { Tag, Tooltip } from "@synapse-analytics/synapse-ui"

// props passed to VersionTag.tsx --> ruleset versioning, workflow versioning
type versionTagProps = {
  version: number | undefined | string
  variant: "positive" | "default" | "negative"
  tooltip?: string
}

/**
 * VersionTag component, contains (ruleset, workflow) version
 * @param {number | undefined} version
 * @param {"positive" | "default"} variant
 * @return {<VersionTag />}
 */

export function VersionTag(props: Readonly<versionTagProps>): React.ReactElement {
  const { version, variant = "default", tooltip } = props
  return tooltip ? (
    <Tooltip title={tooltip} placement="top">
      <Tag variant={variant} style={{ textTransform: "lowercase" }}>{`v${version}`}</Tag>
    </Tooltip>
  ) : (
    <Tag variant={variant} style={{ textTransform: "lowercase" }}>{`v${version}`}</Tag>
  )
}
