import { useEffect, useMemo, useRef } from "react"

import { DebouncedFunc, debounce } from "lodash"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any[]) => void>(callback: T, timer?: number): DebouncedFunc<T> => {
  const ref = useRef<T | null>(null)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = (...args: Parameters<T>): void => {
      ref.current?.(...args)
    }

    return debounce(func, timer ?? 1000)
  }, [timer])

  return debouncedCallback
}
