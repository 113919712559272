import React from "react"

import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { Grid, IconButton } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"

import styles from "./AccordionHeader.module.scss"

export interface AccordionHeaderButton {
  isDisabled?: boolean
  shouldShow?: boolean
  onClick?: () => void
}

type Props = {
  isAccordionExpanding?: boolean
  view?: "code" | "features"
  title: string | React.ReactElement
  isLoading?: boolean
  actionButtonProps?: AccordionHeaderButton
  isBuildingState?: boolean
}

/**
 * Header UI for the Accordions used in script dialog
 * @param {boolean} isAccordionExpanding indicator wether accordion is expanding or not
 * @param {boolean} isLoading indicator for the loading state for the action button
 * @param {string} view current view for the accordion ("code" | "features")
 * @param {AccordionHeaderButton} actionButtonProps header action button
 * @param {string | ReactElement} title title for the accordion header
 * @returns <ReactElement />
 */
export function AccordionHeader(props: Readonly<Props>): React.ReactElement {
  const { view, title, isAccordionExpanding, isLoading = false, actionButtonProps, isBuildingState = false } = props
  const theme = getTheme()

  return (
    <Grid className={view === "code" ? styles.codeHeader : styles.consoleHeader}>
      {typeof title === "string" ? (
        <Typography variantColor={2} variant="p">
          {title}
        </Typography>
      ) : (
        title
      )}

      {actionButtonProps?.shouldShow && view === "code" && (
        <Grid>
          {!isBuildingState ? (
            <Button
              variant="secondary"
              onClick={() => actionButtonProps?.onClick?.()}
              size="small"
              startIcon={<PlayArrowIcon style={{ fontSize: 16 }} />}
              disabled={actionButtonProps?.isDisabled}
              isLoading={isLoading}
            >
              Run Test
            </Button>
          ) : (
            <Button
              variant="dangerous"
              onClick={() => actionButtonProps?.onClick?.()}
              size="small"
              disabled={actionButtonProps?.isDisabled}
              isLoading={isLoading}
            >
              Abort
            </Button>
          )}
        </Grid>
      )}

      {view !== "code" && (
        <IconButton size="small" onClick={() => actionButtonProps?.onClick?.()}>
          {isAccordionExpanding ? (
            <ExpandLessIcon style={{ color: theme.palette.grayscale.text[2] }} />
          ) : (
            <ExpandMoreIcon style={{ color: theme.palette.grayscale.text[2] }} />
          )}
        </IconButton>
      )}
    </Grid>
  )
}
