import React from "react"

import CloseIcon from "@mui/icons-material/Close"
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined"
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"

import { InfoContainer } from "../../components/InfoContainer"
import { InfoBlock } from "../../components/containers/InfoBlock"
import { KeyValueBlock } from "../../components/dialogs/components/KeyValueBlock"
import { getTheme } from "../../hooks/UseTheme"
import { SchemaFeature } from "../../types/custom/workflows"
import { FeatureMappingFormikValues } from "../Workflows/components/SelectionNode"

import styles from "./Projects.module.scss"

interface ProjectFeatureMappingDialogProps {
  isOpen: boolean
  onClose: () => void
  name: string
  workflowSchema?: Array<SchemaFeature>
  workflowFormik?: FormikProps<FeatureMappingFormikValues>
  isLoading: boolean
  isWorkflowInReadMode: boolean | null
}

export function ProjectFeatureMappingDialog(props: Readonly<ProjectFeatureMappingDialogProps>): React.ReactElement {
  const { isOpen, onClose, name, workflowSchema, workflowFormik, isLoading, isWorkflowInReadMode } = props

  const theme = getTheme()
  const MuiTheme = useTheme()

  const mode = isWorkflowInReadMode ? "read" : "edit"

  return (
    <Dialog
      open={isOpen}
      maxWidth={"md"}
      fullWidth
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      onClose={onClose}
    >
      <DialogTitle className="dialog-header-base" display="flex" justifyContent="space-between">
        <Typography variant="h2-bold">Live model feature mapping</Typography>

        <IconButton
          onClick={() => {
            onClose()
          }}
          size="small"
          className={"close-icon-button"}
        >
          <CloseIcon style={{ color: theme.palette.grayscale.text[2] }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.featureMappingDialogContent}>
        <Grid container item xs={12} p={3} gap={1}>
          <Grid container item flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item xs height="fit-content" container justifyContent="flex-start">
              <Grid item className={styles.projectSideBar}>
                <TokenOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />
              </Grid>

              <Grid item xs container direction="column" gap={0.9}>
                <Grid item>
                  <Typography variant="label" variantColor={2}>
                    Live Model
                  </Typography>
                </Grid>

                <Grid justifySelf="flex-end" item xs mt={"5px"} container>
                  {isLoading ? (
                    <Grid item mt={1}>
                      <Skeleton width={60} height={24} />
                    </Grid>
                  ) : (
                    <Typography variant="h3-bold">{name}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Template info section (input features, output features, description, etc..) */}
          <Grid container item flexDirection="column">
            {isLoading ? (
              <Grid item xs={12} container minHeight={"80%"}>
                <InfoContainer icon={<CircularProgress />} title="Loading Template info..." />
              </Grid>
            ) : (
              <Grid item mt={1}>
                {/* Input Features */}
                <Typography variant="h3-bold" gutterBottom>
                  Model Input Features Mapping
                </Typography>
                <Typography variant="p" variantColor={2} gutterBottom>
                  Model features are automatically mapped to similar feature names in workflow schema. If there aren't
                  any in the workflow, they are added automatically.
                </Typography>

                {isLoading ? (
                  <InfoContainer icon={<CircularProgress />} title="Loading feature mappings.." />
                ) : workflowFormik?.values.liveModelSchema?.length === 0 ? (
                  <Grid item xs={12} display={"flex"} mt={1}>
                    <InfoBlock text={"No features found for this Project"} bordered alignText="center" />
                  </Grid>
                ) : (
                  <Grid mt={1}>
                    <KeyValueBlock
                      isViewMode={mode === "read"}
                      inputFeatures={workflowFormik?.values.liveModelSchema}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      formik={workflowFormik as FormikProps<any>}
                      valueListOptions={workflowSchema?.filter((feature) => feature?.name?.length > 0)}
                      type="Project"
                      enableSelfMappingUnmappedValues
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
