import React, { Fragment } from "react"

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { Card, CardContent, Grid } from "@mui/material"
import { Button, InputText, Menu, MenuItem, Select, Typography } from "@synapse-analytics/synapse-ui"
import { FieldArray, useFormikContext } from "formik"
import { v4 as uuidv4 } from "uuid"

import { getTheme } from "../../hooks/UseTheme"
import { Rule, ScoreCardFormikValues, ScorecardRule } from "../../types/custom/rules"
import { getFieldErrorIfTouched, getRuleWeightValue } from "../../utils/conditionHelpers"
import { DataBlock } from "../Ruleset/components/RuleCard"
import { EMPTY_SCORECARD_CONDITION } from "../ScoreCardSets/CONSTANTS"
import { Condition } from "./Condition"

import styles from "./RuleCard.module.scss"

type Props = {
  /** Indicates if the form is in create mode */
  createMode: boolean
  /** Indicates if the form is in edit mode */
  editMode?: boolean
  /** Index of the rule within the scorecard */
  ruleIndex: number
  /** Indicates if the scorecard is loading */
  isScorecardLoading: boolean
  /** Indicates if the form is in duplicate mode */
  isDuplicateMode?: boolean
}

/**
 * RuleCard component displays a rule within a scorecard.
 *
 * @param {boolean} createMode - Indicates if the form is in create mode
 * @param {boolean} [editMode] - Indicates if the form is in edit mode
 * @param {number} ruleIndex - Index of the rule within the scorecard
 * @param {boolean} isScorecardLoading - Indicates if the scorecard is loading
 * @returns {React.ReactElement} A React element representing the rule card
 */
export function RuleCard(props: Readonly<Props>): React.ReactElement {
  const { ruleIndex, createMode, editMode, isScorecardLoading, isDuplicateMode = false } = props

  const { values, setFieldValue, handleChange, handleBlur, isSubmitting, errors, touched } =
    useFormikContext<ScoreCardFormikValues>()

  const theme = getTheme()

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null)
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null)

  const openMenu1 = Boolean(anchorEl1)
  const openMenu2 = Boolean(anchorEl2)

  const handleRemoveCondition = (
    levelRemove: (idx: number) => void,
    conditionIdx: number,
    conditionLevel: "levelTwoConditions" | "levelOneConditions",
  ): void => {
    // current level the condition being removed from
    const currentLevelLength = values?.rules[ruleIndex]?.[conditionLevel]?.length

    // the migrated level/group -> ex: if removing condition from levelTwo then the migrated level is levelOneConditions
    const levelToBeMigratedTo = "levelOneConditions"

    // the condition index that will be migrated to the prev level
    const conditionIndexToBeMigrated = conditionIdx === 0 ? 1 : 0

    if (
      currentLevelLength <= 2 &&
      conditionLevel === "levelOneConditions" &&
      values?.rules[ruleIndex]?.levelTwoConditions?.length === 0
    ) {
      const cachedCondition = values?.rules[ruleIndex]?.levelOneConditions[conditionIdx === 0 ? 1 : 0]

      const filteredScorecards = values?.rules?.filter((_: ScorecardRule, idx: number) => idx !== ruleIndex)

      const newRule = {
        type: "simple",
        id: values?.rules[ruleIndex]?.id,
        ruleName: `new-${uuidv4()}`,
        levelOneConditions: [cachedCondition],
        levelTwoConditions: [],
        return_value: values?.rules[ruleIndex]?.return_value,
        condition_list_files: [],
      }

      setFieldValue(`rules`, [...filteredScorecards, newRule])
    } else if (currentLevelLength <= 2 && conditionLevel === "levelTwoConditions") {
      // cache the migrated condition in a temp variable
      const tempCondition = values?.rules[ruleIndex]?.[conditionLevel][conditionIndexToBeMigrated]

      // remove the whole level
      setFieldValue(`rules[${ruleIndex}].${conditionLevel}`, [])

      // push the migrated condition to the prev/migrated level
      setFieldValue(`rules[${ruleIndex}].${levelToBeMigratedTo}`, [
        ...values?.rules[ruleIndex]?.[levelToBeMigratedTo],
        tempCondition,
      ])
    } else {
      levelRemove(conditionIdx)
    }
  }

  return (
    <Fragment>
      {values.rules[ruleIndex]?.type === "simple" ? (
        <Grid container style={{ height: "fit-content !important" }} item px={1.8} py={1}>
          <Condition
            isScorecardLoading={isScorecardLoading}
            conditionLevel={"levelOneConditions"}
            id={`rules[${ruleIndex}].levelOneConditions[${0}]`}
            index={0}
            isEditMode={Boolean(editMode)}
            isCreateMode={createMode}
            isDuplicateMode={isDuplicateMode}
            ruleIndex={ruleIndex}
          />
        </Grid>
      ) : (
        <Card className={createMode || editMode ? styles.card : styles.viewCard}>
          <CardContent style={{ borderRadius: "8px" }} className={styles.cardContent}>
            <Grid display="flex" justifyContent="space-between" container gap={1}>
              <Grid item xs={10}>
                {/* //  Level 1 group */}
                <FieldArray name={`rules[${ruleIndex}].levelOneConditions`}>
                  {({ push: level1push, remove: level1remove }) => (
                    <Grid container justifyContent="flex-end" alignItems="center" className={styles.groupBackground}>
                      <Grid container item spacing={1}>
                        {values?.rules[ruleIndex]?.levelOneConditions?.map((item: Rule, idx: number) => (
                          <Grid item xs={12}>
                            <Condition
                              isScorecardLoading={isScorecardLoading}
                              key={item?.id}
                              conditionLevel={"levelOneConditions"}
                              id={`rules[${ruleIndex}].levelOneConditions[${idx}]`}
                              isDuplicateMode={isDuplicateMode}
                              index={idx}
                              isEditMode={Boolean(editMode)}
                              isCreateMode={createMode}
                              ruleIndex={ruleIndex}
                              removeCondition={() => handleRemoveCondition(level1remove, idx, "levelOneConditions")}
                            />
                          </Grid>
                        ))}

                        {/* and/or */}
                        {values?.rules[ruleIndex]?.levelTwoConditions &&
                          values?.rules[ruleIndex]?.levelTwoConditions.length > 0 && (
                            <Grid item xs={3} md={2} lg={1.5}>
                              {(editMode || createMode) && values?.rules[ruleIndex]?.levelOneConditions.length === 1 ? (
                                <Select
                                  hideDescription
                                  type="text"
                                  value={values?.rules[ruleIndex]?.levelOneConditions[0]?.andOr ?? "and"}
                                  handleChange={(e) => {
                                    const { value } = e.target
                                    setFieldValue(`rules[${ruleIndex}].levelOneConditions[0].andOr`, value)
                                  }}
                                  fullWidth
                                  disabled={isSubmitting}
                                  menuProps={{ menuMaxContent: true }}
                                  options={["and", "or"]}
                                />
                              ) : (
                                <DataBlock value={values?.rules[ruleIndex]?.levelOneConditions[0]?.andOr} />
                              )}
                            </Grid>
                          )}

                        {/* Level 2 group */}
                        {values?.rules[ruleIndex]?.levelTwoConditions &&
                          values?.rules[ruleIndex]?.levelTwoConditions.length > 0 && (
                            <Grid item xs>
                              <FieldArray name={`rules[${ruleIndex}].levelTwoConditions`}>
                                {({ push: level2push, remove: level2remove }) => (
                                  <Grid
                                    container
                                    item
                                    justifyContent="flex-end"
                                    alignItems="flex-start"
                                    className={styles.groupBackground}
                                  >
                                    <Grid container item spacing={1}>
                                      {values?.rules[ruleIndex]?.levelTwoConditions?.map((item: Rule, idx: number) => (
                                        <Grid item xs={12} key={item?.id}>
                                          <Condition
                                            isScorecardLoading={isScorecardLoading}
                                            key={item?.id}
                                            ruleIndex={ruleIndex}
                                            conditionLevel={"levelTwoConditions"}
                                            id={`rules[${ruleIndex}].levelTwoConditions[${idx}]`}
                                            index={idx}
                                            isEditMode={Boolean(editMode)}
                                            isCreateMode={createMode}
                                            isDuplicateMode={isDuplicateMode}
                                            removeCondition={() =>
                                              handleRemoveCondition(level2remove, idx, "levelTwoConditions")
                                            }
                                          />
                                        </Grid>
                                      ))}

                                      {/* level 2 menu button  */}
                                      {(editMode || createMode) &&
                                        values?.rules[ruleIndex]?.levelTwoConditions &&
                                        values?.rules[ruleIndex]?.levelTwoConditions.length > 0 && (
                                          <Grid container>
                                            <Grid item lg={12} mt={1} ml={1}>
                                              <Button
                                                size="regular"
                                                variant="ghost"
                                                onClick={(event) => setAnchorEl2(event?.currentTarget)}
                                                disabled={isSubmitting}
                                              >
                                                + Add Rule
                                              </Button>
                                              <Menu
                                                key="basic-menu2"
                                                anchorEl={anchorEl2}
                                                open={openMenu2}
                                                onClose={() => setAnchorEl2(null)}
                                                menuMaxContent
                                              >
                                                <MenuItem
                                                  onClick={() => {
                                                    level2push({
                                                      ...EMPTY_SCORECARD_CONDITION,

                                                      andOr: values?.rules[ruleIndex]?.levelTwoConditions[0]?.andOr,
                                                    })
                                                    setAnchorEl2(null)
                                                  }}
                                                >
                                                  <Grid container display={"flex"} gap={1} height={"20px"}>
                                                    <Grid item>
                                                      <AddOutlinedIcon
                                                        fontSize="small"
                                                        htmlColor={theme.palette.grayscale.text[1]}
                                                      />
                                                    </Grid>
                                                    <Grid item>
                                                      <Typography variant="a">Add Rule</Typography>
                                                    </Grid>
                                                  </Grid>
                                                </MenuItem>
                                              </Menu>
                                            </Grid>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </Grid>
                                )}
                              </FieldArray>
                            </Grid>
                          )}

                        {/* level 1 menu button  */}
                        {(editMode || createMode) && (
                          <Grid container justifyContent="flex-start" alignItems="center">
                            <Grid item lg={12} mt={1} ml={1}>
                              <Button
                                size="regular"
                                variant="ghost"
                                onClick={(event) => setAnchorEl1(event?.currentTarget)}
                                disabled={isSubmitting}
                              >
                                + Add Rule
                              </Button>
                              <Menu
                                key="basic-menu1"
                                anchorEl={anchorEl1}
                                open={openMenu1}
                                onClose={() => setAnchorEl1(null)}
                                menuMaxContent
                              >
                                <MenuItem
                                  onClick={() => {
                                    level1push({
                                      ...EMPTY_SCORECARD_CONDITION,
                                      andOr: values?.rules[ruleIndex]?.levelOneConditions[0]?.andOr,
                                    })
                                    setAnchorEl1(null)
                                  }}
                                >
                                  <Grid container display={"flex"} gap={1} height={"20px"}>
                                    <Grid item>
                                      <AddOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="a">Add Rule</Typography>
                                    </Grid>
                                  </Grid>
                                </MenuItem>
                                {values?.rules[ruleIndex]?.levelTwoConditions &&
                                  values?.rules[ruleIndex]?.levelTwoConditions.length === 0 && (
                                    <MenuItem
                                      onClick={() => {
                                        values?.rules[ruleIndex]?.levelTwoConditions.push(
                                          {
                                            ...EMPTY_SCORECARD_CONDITION,
                                          } as never,
                                          {
                                            ...EMPTY_SCORECARD_CONDITION,
                                          } as never,
                                        )
                                        setAnchorEl1(null)
                                      }}
                                    >
                                      <Grid container display={"flex"} gap={1} height={"20px"}>
                                        <Grid item>
                                          <AddBoxOutlinedIcon
                                            fontSize="small"
                                            htmlColor={theme.palette.grayscale.text[1]}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="a">Add Group</Typography>
                                        </Grid>
                                      </Grid>
                                    </MenuItem>
                                  )}
                              </Menu>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </FieldArray>
              </Grid>

              <Grid
                container
                xs
                gap={0.5}
                item
                justifyContent="flex-start"
                alignItems="center"
                alignSelf="center"
                mt={1}
              >
                <Grid alignSelf="flex-end" item xs={"auto"} mr={0.5} marginTop={editMode || createMode ? 3 : 1}>
                  <Typography variant="a">{"="}</Typography>
                </Grid>

                {/* Rule Weight */}
                <Grid item xs>
                  {editMode || createMode ? (
                    <InputText
                      id={`rules[${ruleIndex}].return_value`}
                      placeholder="Rule Weight"
                      value={getRuleWeightValue(values.rules[ruleIndex].return_value)}
                      handleChange={handleChange}
                      hideDescription
                      error={getFieldErrorIfTouched(errors, touched, `rules.${ruleIndex}.return_value`)}
                      handleBlur={handleBlur}
                      disabled={isSubmitting}
                      type="number"
                      fullWidth
                    />
                  ) : (
                    <DataBlock value={`${parseFloat(values.rules[ruleIndex].return_value)}`} />
                  )}
                </Grid>
              </Grid>

              {/* Remove button */}
              {(editMode || createMode) && values?.rules?.length > 1 && (
                <Grid display="flex" item alignSelf="center" mt={"2px"}>
                  <Button
                    variant="dangerous"
                    size="small"
                    disabled={isSubmitting}
                    onClick={() => {
                      const newRules = values?.rules?.filter(
                        (rule: ScorecardRule) => rule?.id !== values?.rules[ruleIndex]?.id,
                      )

                      setFieldValue(`rules`, newRules)
                    }}
                  >
                    <RemoveCircleOutlineIcon fontSize="inherit" />
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Fragment>
  )
}
