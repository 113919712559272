import React from "react"

import { CircularProgress, Grid } from "@mui/material"

export const LoadingContainer = (): React.ReactElement => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: "100%" }}>
      <CircularProgress />
    </Grid>
  )
}
