import React, { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import {
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  SvgIcon,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import {
  Button,
  Menu,
  MenuItem,
  NotificationUtils,
  Skeleton,
  Tag,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import moment from "moment"

import { queryClient } from "../../../../../.."
import { KonanAvatar } from "../../../../../../components/Avatar"
import { ModelType } from "../../../../../../components/ModelType"
import { BaseSimpleDialog } from "../../../../../../components/dialogs/BaseSimpleDialog"
import { getTheme } from "../../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../../store/CurrentProjectAndModelContext"
import { Model } from "../../../../../../types/generated/api/Model"
import { AssignTrainingDataToModelDialog } from "./AssignTrainingDataToModelDialog"

import styles from "../../TrainingData.module.scss"

type ParamType = {
  id: string
}
type Props = {
  createdAt: string
  createdBy: string
  name: string
  models: string[]
  trainingDataUUID: string
  uuid: string
}
type trainingProps = {
  modelId: string
  setShadowModel: Dispatch<SetStateAction<string | undefined>>
}
type DialogProps = {
  open: boolean
  onDialogClose: () => void
  model_uuid: string
  model_name: string | undefined
}
function UnlinkTrainingDataFromModelDialog(props: Readonly<DialogProps>): React.ReactElement {
  const { open, onDialogClose, model_uuid, model_name } = props
  const muiTheme = useMuiTheme()

  const unlinkModelMutation = useMutation<AxiosResponse, AxiosError>(() =>
    KonanAPI.deleteTrainingDataFromModel(model_uuid),
  )

  async function unlinkModel(): Promise<void> {
    try {
      await unlinkModelMutation.mutateAsync()
      await queryClient.invalidateQueries(["model", model_uuid])
      setTimeout(() => unlinkModelMutation.reset(), 300)

      onDialogClose()
      NotificationUtils.toast(`Successfully unlinked <${model_name}> from training data`, {
        snackBarVariant: "positive",
      })
    } catch (e) {
      NotificationUtils.toast(`an error occurred while unlinking <${model_name}> from training data`, {
        snackBarVariant: "negative",
      })

      onDialogClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && unlinkModelMutation.isLoading) {
          return undefined
        } else {
          onDialogClose()
        }
      }}
      fullScreen={useMediaQuery(muiTheme.breakpoints.down("md"))}
    >
      <DialogTitle className="dialog-header-base">
        <Typography variant="h2-bold">Unlink This Model?</Typography>
      </DialogTitle>
      <DialogContent className="dialog-content-base">
        <Typography variant="h3-regular" gutterBottom>
          Are you sure you want to unlink{" "}
          <strong>
            {" "}
            {model_name} ({model_uuid})
          </strong>{" "}
          from this training dataset?
        </Typography>
      </DialogContent>
      <DialogActions className="dialog-actions-base">
        <Button variant="secondary" onClick={onDialogClose}>
          CANCEL
        </Button>
        <Button variant="dangerous" onClick={() => unlinkModel()} disabled={unlinkModelMutation?.isLoading}>
          {unlinkModelMutation?.isLoading ? <CircularProgress size={20} color="inherit" /> : "UNLINK"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function MiniTrainingDataCard(props: Readonly<trainingProps>): React.ReactElement {
  const { modelId, setShadowModel } = props
  const { id: projectId } = useParams<ParamType>()

  const theme = getTheme()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { setCurrentModel } = useContext(CurrentProjectAndModelContext)

  const [unlinkModelDialogOpen, setUnlinkModelDialogOpen] = useState<boolean>(false)

  const {
    isLoading: isModelDataLoading,
    isError,
    data: modelData,
  } = useQuery<AxiosResponse<Model>, AxiosError>(["model", modelId], () => KonanAPI.fetchModel(modelId), {
    enabled: !!modelId,
  })

  const handleUnlinkModelDialogClose = (): void => {
    setUnlinkModelDialogOpen(false)
    queryClient.invalidateQueries(["training-data", projectId])
  }

  useEffect(() => {
    if (isError) {
      setShadowModel(modelId)
    } else {
      setShadowModel("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, modelData, modelId])

  return (
    <Fragment>
      <UnlinkTrainingDataFromModelDialog
        model_uuid={modelId}
        open={unlinkModelDialogOpen}
        onDialogClose={handleUnlinkModelDialogClose}
        model_name={modelData?.data?.name}
      />
      {isModelDataLoading ? (
        <Card className="card-box-shadow">
          <Grid container direction="column" className={"MiniModelCard"}>
            <Grid item sx={{ marginTop: "8px" }}>
              <Skeleton variant="rectangular" width={"40%"} height={10} />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" width={"70%"} height={10} />
            </Grid>
            <Grid item style={{ marginBottom: "5px" }}>
              <Skeleton variant="rectangular" width={"40%"} height={10} />
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card className="card-box-shadow" style={{ borderRadius: "4px" }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={styles.dataCard}
          >
            <Grid item xs={12} direction="row" justifyContent="space-between" container>
              <Grid item xs={10}>
                <ModelType modelState={modelData?.data?.state} size="small" />
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end" alignItems="flex-start">
                <IconButton
                  style={{ color: theme.palette.gray.background[1], marginRight: "-5px" }}
                  size="small"
                  onClick={() => setUnlinkModelDialogOpen(true)}
                >
                  <CloseIcon fontSize="small" className={styles.remove} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }} marginTop={0.5}>
              <Typography
                variant="p"
                noWrap
                className={styles.trainingText}
                color="important"
                variantColor={2}
                onClick={() => {
                  navigate(`/projects/${projectId}/models/requests`)
                  setCurrentModel(modelId)
                }}
              >
                {modelData?.data?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} className={"typography-and-tooltip-container"}>
              {modelData?.data?.created_at && (
                <Typography variant="label" noWrap style={{ width: "100%" }}>
                  <Tooltip
                    title={format(new Date(modelData?.data?.created_at), "dd/MM/yyyy, p")}
                    placement="right"
                    width="100%"
                  >
                    <Typography variant="span" noWrap className={styles.modelDate}>
                      {moment(new Date(modelData?.data?.created_at)).fromNow()}
                    </Typography>
                  </Tooltip>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
    </Fragment>
  )
}

export function TrainingDataCard(props: Readonly<Props>): React.ReactElement {
  const { createdAt, createdBy, name, models, trainingDataUUID } = props
  const { id: projectId } = useParams<ParamType>()

  const [shadowModel, setShadowModel] = useState<string | undefined>(undefined)
  const [deleteTrainingDataDialogOpen, setDeleteTrainingDataDialogOpen] = useState<boolean>(false)
  const [assignModelDialogOpen, setAssignModelDialogOpen] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [allModels, setAllModels] = useState([...models])

  const muiTheme = useMuiTheme()
  const theme = getTheme()

  const smallerScreens = useMediaQuery(muiTheme.breakpoints.down("sm"))

  const DEFAULT_TRAINING_DATA_NAME = "data.csv"

  const deleteTrainingDataMutation = useMutation<AxiosResponse, AxiosError>(
    () => KonanAPI.deleteTrainingDataFromProject(projectId as string, trainingDataUUID),
    {
      onSuccess: async () => {
        // Invalidate react-query queries
        await queryClient.invalidateQueries(["training-data", projectId])

        NotificationUtils.toast(`Successfully deleted ${name ? `<${name}>` : `training data`}`, {
          snackBarVariant: "positive",
        })
      },
      onError: () => {
        NotificationUtils.toast(`An error occurred when attempting to delete ${name ? `<${name}>` : `training data`}`, {
          snackBarVariant: "negative",
        })
      },
    },
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  /**TEMPORARY FIX
   *
   * this problem of displaying TRAINING JOB in the training data is currently an issue returning
   * when fetching /training-data endpoint from the backend, it shouldn't return the id of the shadow model
   * so this effect hides the shadow model if it exists
   */
  useEffect(() => {
    if (shadowModel?.length) {
      const assignedModels = [...models]
      assignedModels?.splice(models?.indexOf(shadowModel), models?.indexOf(shadowModel) + 1)
      setAllModels([...assignedModels])
    } else {
      setAllModels([...models])
    }
  }, [shadowModel, models])

  return (
    <Fragment>
      {assignModelDialogOpen && (
        <AssignTrainingDataToModelDialog
          projectUUID={projectId as string}
          open={assignModelDialogOpen}
          onClose={() => setAssignModelDialogOpen(false)}
          trainingDataUUID={trainingDataUUID}
          trainingName={name}
        />
      )}
      {deleteTrainingDataDialogOpen && (
        <BaseSimpleDialog
          open={deleteTrainingDataDialogOpen}
          isLoading={deleteTrainingDataMutation.isLoading}
          name={name}
          onAccept={() => deleteTrainingDataMutation.mutateAsync()}
          onClose={() => setDeleteTrainingDataDialogOpen(false)}
          mode={"training-data-deletion"}
        />
      )}
      <Card className="card-box-shadow">
        <Grid container direction="column" className={styles.trainingCardDataHeaderRoot}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={8} alignItems="center" justifyContent="flex-start" className={styles.tag}>
              <Tag variant="positive" size="small">
                Success
              </Tag>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end" alignItems="flex-start">
              <IconButton aria-label="settings" onClick={handleClick} size="small" className={styles.headerButton}>
                <MoreHorizIcon />
              </IconButton>
              <Menu id="simple-menu" anchorEl={anchorEl} menuMaxContent open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    setAssignModelDialogOpen(true)
                    handleClose()
                  }}
                >
                  <Typography className={styles.menuItem} variant="label">
                    Assign to a model
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleteTrainingDataDialogOpen(true)
                    handleClose()
                  }}
                >
                  <Typography className={styles.menuItemDelete} variant="label">
                    Delete Training data
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3-bold" noWrap className={styles.headerName}>
              {name ?? DEFAULT_TRAINING_DATA_NAME}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between">
            <Grid
              container
              item
              spacing={1}
              xs={allModels?.length !== 0 ? 6 : 12}
              xl={8}
              alignItems="flex-start"
              className={styles.trainingDataAvatarContainer}
            >
              <Grid item sx={{ marginTop: "2px" }}>
                {/* SvgIcon is used to fix square avatars on safari */}
                <SvgIcon className={styles.avatar}>
                  <KonanAvatar size={24} name={createdBy} />
                </SvgIcon>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="label" noWrap>
                  {createdBy}
                </Typography>

                <Typography variant="label" noWrap>
                  <Tooltip title={format(new Date(createdAt), "dd/MM/yyyy, p")} placement="right">
                    <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                      {moment(new Date(createdAt)).fromNow()}
                    </Typography>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
            {shadowModel !== undefined && allModels?.length !== 0 && (
              <Grid item container xs={6} xl={4} className={styles.assignContainer}>
                <Button onClick={() => setAssignModelDialogOpen(true)} size={"small"} variant="secondary">
                  {smallerScreens ? "Assign" : "Assign to a model"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container direction="column" className={styles.bodyRoot}>
          <Grid container item direction="row" alignItems="flex-end">
            <Grid container item direction="row">
              <Grid item xs={12} sm={9}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                  <Typography variant="label" className={styles.label}>
                    Assigned Models
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {allModels?.length > 0 ? (
            <Grid container direction="row" spacing={1} className={styles.horizontalScroller}>
              {allModels?.map((modelID) => (
                <Grid item xs={4} className={styles.containerCard} key={modelID}>
                  <MiniTrainingDataCard modelId={modelID} setShadowModel={setShadowModel} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item xs={12} className={styles.addTrainingSection} onClick={() => setAssignModelDialogOpen(true)}>
              <AddIcon style={{ color: theme.palette.gray.background[1] }} />
              <Typography variant="h3-bold">Assign to a Model</Typography>
            </Grid>
          )}
        </Grid>
      </Card>
    </Fragment>
  )
}
