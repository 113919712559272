import React from "react"

import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { IconButton } from "@mui/material"
import { NotificationUtils } from "@synapse-analytics/synapse-ui"

import styles from "./KonanCopyToClipboard.module.scss"

type Props = {
  text: string
  disabled?: boolean
  rounded?: boolean
}

/* Text box with copy to clipboard option */
export function KonanCopyToClipboard(props: Props): React.ReactElement {
  const { text, disabled, rounded } = props

  return (
    <IconButton
      size="small"
      color="default"
      className={`${styles.button} ${rounded && styles.rounded}`}
      onClick={(e) => {
        e.stopPropagation()
        navigator.clipboard.writeText(text)
        NotificationUtils.toast("Copied to Clipboard!", { snackBarVariant: "positive" })
      }}
      disabled={disabled}
    >
      <ContentCopyIcon className={styles.icon} />
    </IconButton>
  )
}
