import React, { useContext, useState } from "react"

import { useQuery } from "react-query"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Grid, Pagination } from "@mui/material"
import { Button, Tab, Tabs } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState } from "../components/KonanEmptyState"
import { Scorecardset } from "../features/ScoreCardSets/Scorecardset"
import { ScoreTable } from "../features/ScoreTables"
import { ScoreCardLoader } from "../features/Scorecards/ScoreCardLoader"
import { Scorecard } from "../features/Scorecards/Scorecard"
import { KonanAPI } from "../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../store/CurrentProjectAndModelContext"
import { PaginatedScoreCardListList } from "../types/generated/api/PaginatedScoreCardListList"
import { PaginatedScoreCardSetListList } from "../types/generated/api/PaginatedScoreCardSetListList"
import { ScoreCardList } from "../types/generated/api/ScoreCardList"
import { ScoreCardSetList } from "../types/generated/api/ScoreCardSetList"
import { isEnvVariableTruthy } from "../utils/genericHelpers"

interface ScorecardsTabProps {
  shouldShowNewScorecard: boolean
  onNewScoreCardCancel: () => void
  shouldShowNewScoreTable: boolean
  onNewScoreTableCancel: () => void
  duplicatedScoreCard?: ScoreCardList | null
  setDuplicatedScoreCard?: React.Dispatch<React.SetStateAction<ScoreCardList | null>>
}

function ScorecardsTab(props: Readonly<ScorecardsTabProps>): React.ReactElement {
  const {
    onNewScoreCardCancel,
    onNewScoreTableCancel,
    shouldShowNewScoreTable,
    shouldShowNewScorecard,
    duplicatedScoreCard,
    setDuplicatedScoreCard,
  } = props

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  // Pagination
  const pageSize = 6
  const [page, setPage] = useState<number>(0)

  const duplicateScoreCard = (scorecard: ScoreCardList): void => {
    const newScoreCard = { ...scorecard, name: `${scorecard.name} - Copy` }
    setDuplicatedScoreCard?.(newScoreCard)
    // scroll to top
    window.scrollTo(0, 0)
  }

  /**
   * Fetches scorecards data for the current project.
   *
   * @returns An object containing the scorecards data and loading status.
   */
  const {
    data: scorecards,
    isLoading: isScorecardsLoading,
    isFetching: isScorecardsFetching,
  } = useQuery<AxiosResponse<PaginatedScoreCardListList>, AxiosError>(
    ["scorecards", currentProject?.uuid, page, pageSize],
    () => KonanAPI.fetchScorecards(currentProject?.uuid as string, page + 1, pageSize),
    {
      enabled: !!currentProject?.uuid,
    },
  )

  return (
    <Grid container spacing={2}>
      {(shouldShowNewScoreTable ||
        (duplicatedScoreCard && duplicatedScoreCard.format === ScoreCardList.format.TABLE)) && (
        <Grid item xs={12}>
          <ScoreTable
            scorecard={duplicatedScoreCard as ScoreCardList}
            isCreateMode
            handleCancel={duplicatedScoreCard ? () => setDuplicatedScoreCard?.(null) : onNewScoreTableCancel}
            goToFirstPage={() => setPage(0)}
            isDuplicateMode={!!duplicatedScoreCard}
          />
        </Grid>
      )}

      {(shouldShowNewScorecard ||
        (duplicatedScoreCard && duplicatedScoreCard.format === ScoreCardList.format.LIST)) && (
        <Grid item xs={12}>
          <Scorecard
            scorecard={duplicatedScoreCard as ScoreCardList}
            isCreateMode
            handleCancel={duplicatedScoreCard ? () => setDuplicatedScoreCard?.(null) : onNewScoreCardCancel}
            goToFirstPage={() => setPage(0)}
            isDuplicateMode={!!duplicatedScoreCard}
          />
        </Grid>
      )}

      {isScorecardsLoading || isScorecardsFetching
        ? [1, 2].map((item: number) => (
            <Grid item xs={12} key={item}>
              <ScoreCardLoader />
            </Grid>
          ))
        : scorecards?.data?.results?.length && scorecards?.data?.results?.length > 0
          ? scorecards?.data.results.map((scorecard: ScoreCardList) => (
              <Grid item xs={12} key={scorecard.uuid}>
                {scorecard.format === ScoreCardList.format.LIST ? (
                  <Scorecard
                    scorecard={scorecard}
                    disableDuplicateButton={!!duplicatedScoreCard || shouldShowNewScorecard || shouldShowNewScoreTable}
                    duplicateScoreCard={duplicateScoreCard}
                  />
                ) : (
                  <ScoreTable
                    scorecard={scorecard}
                    disableDuplicateButton={!!duplicatedScoreCard || shouldShowNewScorecard || shouldShowNewScoreTable}
                    duplicateScoreTable={duplicateScoreCard}
                  />
                )}
              </Grid>
            ))
          : !shouldShowNewScoreTable &&
            !shouldShowNewScorecard && (
              <Grid item xs={12} className={"empty-container"}>
                <KonanEmptyState
                  title="No Scorecards"
                  subTitle="Assign weighted numerical scores to applicants based on their features. To be effective, you integrate it into your workflow"
                />
              </Grid>
            )}

      {/* Pagination */}
      {!isScorecardsLoading && scorecards?.data.results && scorecards?.data.results.length > 0 && (
        <Grid container item justifyContent="flex-start">
          <Pagination
            count={Math.ceil((scorecards?.data.count as number) / pageSize)}
            page={page + 1}
            onChange={(_, value: number) => {
              setPage(value - 1)
              window.scrollTo(0, 0)
            }}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  )
}

interface ScoreSetsTabProps {
  shouldShowNewScoreSet: boolean
  onNewScoreSetCancel: () => void
  duplicatedScoreCard?: ScoreCardSetList | null
  setDuplicatedScoreCard?: React.Dispatch<React.SetStateAction<ScoreCardSetList | null>>
}

function ScoreSetsTab(props: Readonly<ScoreSetsTabProps>): React.ReactElement {
  const { onNewScoreSetCancel, shouldShowNewScoreSet, duplicatedScoreCard, setDuplicatedScoreCard } = props

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  // Pagination
  const pageSize = 6
  const [page, setPage] = useState<number>(0)

  const duplicateScoreSet = (scoreSet: ScoreCardSetList): void => {
    const newScoreSet = { ...scoreSet, name: `${scoreSet.name} - Copy` }
    setDuplicatedScoreCard?.(newScoreSet)
    // scroll to top
    window.scrollTo(0, 0)
  }

  /**
   * Fetches the scorecard sets for the current project.
   *
   * @returns An object containing the loading state and data of the scorecard sets.
   */
  const {
    data: scorecardsets,
    isLoading: isSetsLoading,
    isFetching: isSetsFetching,
  } = useQuery<AxiosResponse<PaginatedScoreCardSetListList>, AxiosError>(
    ["scorecardsets", currentProject?.uuid, page, pageSize],
    () => KonanAPI.fetchScorecardsets(currentProject?.uuid as string, page + 1, pageSize),
    {
      enabled: !!currentProject?.uuid,
    },
  )

  // TODO:: remove and refactor the menu to fetch using an infinite query with backend search
  /**
   * Fetches scorecards data for the current project.
   *
   * @returns An object containing the scorecards data and loading status.
   */
  const { data: scoreCards, isLoading: isScoreCardsLoading } = useQuery<
    AxiosResponse<PaginatedScoreCardListList>,
    AxiosError
  >(["sets", currentProject?.uuid], () => KonanAPI.fetchScorecards(currentProject?.uuid as string, page + 1, 200), {
    enabled: !!currentProject?.uuid,
  })

  return (
    <Grid container spacing={2}>
      {(shouldShowNewScoreSet || duplicatedScoreCard) && (
        <Grid item xs={12}>
          <Scorecardset
            isDuplicate={!!duplicatedScoreCard}
            isLoading={isSetsLoading}
            isCreate={true}
            handleCancel={!!duplicatedScoreCard ? () => setDuplicatedScoreCard?.(null) : () => onNewScoreSetCancel()}
            allScorecards={scoreCards?.data.results ?? []}
            goToFirstPage={() => setPage(0)}
            scorecardset={duplicatedScoreCard ?? undefined}
          />
        </Grid>
      )}

      {isSetsLoading || isScoreCardsLoading || isSetsFetching
        ? [1, 2].map((item: number) => (
            <Grid item xs={12} key={item}>
              <ScoreCardLoader />
            </Grid>
          ))
        : scorecardsets?.data?.results?.length && scorecardsets?.data?.results?.length > 0
          ? scorecardsets?.data.results.map((set: ScoreCardSetList) => (
              <Grid item xs={12} key={set.uuid}>
                <Scorecardset
                  scorecardset={set}
                  isLoading={isSetsLoading}
                  allScorecards={scoreCards?.data.results ?? []}
                  goToFirstPage={() => setPage(0)}
                  disableDuplicateButton={!!duplicatedScoreCard || shouldShowNewScoreSet}
                  duplicateScoreSet={duplicateScoreSet}
                />
              </Grid>
            ))
          : !shouldShowNewScoreSet && (
              <Grid item xs={12} className={"empty-container"}>
                <KonanEmptyState
                  title="No ScorecardSets"
                  subTitle="Assign weighted numerical scores to applicants based on their features. To be effective, you integrate it into your workflow"
                />
              </Grid>
            )}

      {/* Pagination */}
      {!isSetsLoading && scorecardsets?.data.results && scorecardsets?.data.results.length > 0 && (
        <Grid container item justifyContent="flex-start">
          <Pagination
            count={Math.ceil((scorecardsets?.data.count as number) / pageSize)}
            page={page + 1}
            onChange={(_, value: number) => {
              setPage(value - 1)
              window.scrollTo(0, 0)
            }}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  )
}

/**
 * Renders the Scorecards page.
 *
 * @returns The rendered Scorecards page.
 */
export function Scorecards(): React.ReactElement {
  const [tab, setTab] = useState<"scorecards" | "sets">("scorecards")

  const [showNewSet, setShowNewSet] = useState<boolean>(false)
  const [showScorecard, setShowScorecard] = useState<boolean>(false)
  const [showScoretable, setShowScoretable] = useState<boolean>(false)

  const [duplicatedScoreCard, setDuplicatedScoreCard] = useState<ScoreCardList | ScoreCardSetList | null>(null)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
        <Grid item>
          <Tabs value={tab}>
            <Tab label="Scorecard Sets" value="sets" selected={tab === "sets"} onClick={() => setTab("sets")} />
            <Tab
              label="Scorecards"
              value="scorecards"
              selected={tab === "scorecards"}
              onClick={() => setTab("scorecards")}
            />
          </Tabs>
        </Grid>

        <Grid item display={"flex"} alignItems={"flex-end"}>
          {tab === "sets" ? (
            <Button
              variant="primary"
              onClick={() => setShowNewSet(true)}
              startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
              disabled={!!duplicatedScoreCard}
            >
              Create set
            </Button>
          ) : (
            <Grid container item gap={1}>
              <Button
                variant="primary"
                onClick={() => setShowScorecard(true)}
                startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
                disabled={!!duplicatedScoreCard}
              >
                Create Scorecard
              </Button>

              <Button
                variant="primary"
                onClick={() => setShowScoretable(true)}
                startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
                disabled={!isEnvVariableTruthy(window.__RUNTIME_CONFIG__.RULES_TABLES_ENABLED) || !!duplicatedScoreCard}
                tooltip={
                  !isEnvVariableTruthy(window.__RUNTIME_CONFIG__.RULES_TABLES_ENABLED)
                    ? "Score tables are currently unavailable"
                    : undefined
                }
              >
                Create Scoretable
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {tab === "sets" ? (
          <ScoreSetsTab
            shouldShowNewScoreSet={showNewSet}
            onNewScoreSetCancel={() => setShowNewSet(false)}
            duplicatedScoreCard={duplicatedScoreCard as ScoreCardSetList | null}
            setDuplicatedScoreCard={
              setDuplicatedScoreCard as React.Dispatch<React.SetStateAction<ScoreCardSetList | null>>
            }
          />
        ) : (
          <ScorecardsTab
            duplicatedScoreCard={duplicatedScoreCard}
            setDuplicatedScoreCard={setDuplicatedScoreCard}
            shouldShowNewScorecard={showScorecard}
            onNewScoreCardCancel={() => setShowScorecard(false)}
            shouldShowNewScoreTable={showScoretable}
            onNewScoreTableCancel={() => setShowScoretable(false)}
          />
        )}
      </Grid>
    </Grid>
  )
}
