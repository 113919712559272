import React from "react"

import { Tooltip } from "@synapse-analytics/synapse-ui"
import Avatar from "boring-avatars"

import styles from "./Avatar.module.scss"

type Props = {
  size: number
  name?: string
}

export function KonanAvatar(props: Readonly<Props>): React.ReactElement {
  const { size, name } = props

  // Customized boring avatar component
  return (
    <Avatar
      size={size}
      name={name}
      variant="bauhaus"
      colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
    />
  )
}

export function BorderedKonanAvatar(props: Readonly<Props>): React.ReactElement {
  const { size, name } = props

  // Customized boring avatar component
  return (
    <div className={styles.avatarBorder}>
      <Tooltip title={name} placement="top">
        <KonanAvatar size={size} name={name} />
      </Tooltip>
    </div>
  )
}
