import React from "react"

import { Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

interface Props {
  title: string
  actions?: (React.ReactElement | undefined)[]
}

/**
 * Simple page sub-header to carry title and actions
 * @param {string} title
 * @param {React.ReactElement[]} actions (optional)
 * @returns {React.ReactElement}
 */
export function SubHeader(props: Readonly<Props>): React.ReactElement {
  const { title, actions } = props

  return (
    <Grid container item xs={12} justifyContent={"space-between"}>
      <Grid item xs>
        <Typography variant="h2-bold">{title}</Typography>
      </Grid>

      <Grid container item xs justifyContent={"flex-end"} gap={1}>
        {actions?.map((action, index) => {
          return (
            <Grid item key={`${title}-${index}`}>
              {action}
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}
