import React, { useCallback, useEffect, useState } from "react"

import { useQuery } from "react-query"

import CheckIcon from "@mui/icons-material/Check"
import RemoveIcon from "@mui/icons-material/Remove"
import { Box, Skeleton } from "@mui/material"
import Grid from "@mui/material/Grid"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table"
import moment from "moment"

import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { ModelComparison } from "../../types/custom/projects"
import { Model } from "../../types/generated/api/Model"
import { PaginatedListModelOutputsList } from "../../types/generated/api/PaginatedListModelOutputsList"
import { compareModels, getModelByUUID } from "../../utils/modelDetailsHelpers"
import { KonanJsonView } from "../KonanJsonView"
import { ModelType } from "../ModelType"
import { BaseTableContainer } from "./BaseTableContainer"

type Props = {
  startDate: string
  endDate: string
  projectUUID: string
  modelAUUID?: string
  modelBUUID?: string
  models?: Array<Model>
}

export function ModelComparisonTable(props: Readonly<Props>): React.ReactElement {
  const { startDate, endDate, projectUUID, models, modelAUUID, modelBUUID } = props

  const theme = getTheme()

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const [modelATotalCount, setModelATotalCount] = useState(0)

  const [modelBTotalCount, setModelBTotalCount] = useState(0)

  const [modelAData, setModelAData] = useState<Model | null>()
  const [modelBData, setModelBData] = useState<Model | null>()

  const [comparedData, setComparedData] = useState<ModelComparison[]>([])

  // Load request logs for modelA
  const { isFetching, data: response } = useQuery<AxiosResponse<PaginatedListModelOutputsList>, AxiosError>(
    ["request-log", startDate, endDate, projectUUID, modelAUUID, pagination],
    () =>
      KonanAPI.fetchModelRequestLogData(
        startDate,
        endDate,
        modelAUUID ?? "",
        pagination.pageIndex + 1,
        pagination.pageSize,
      ),
    {
      keepPreviousData: true,
      onSuccess: (response) => {
        if (response?.data.results && response.data.results.length > 0) {
          setModelATotalCount(response.data.count ?? 0)
        }
      },
    },
  )

  // Load request logs for modelB
  const { isFetching: isFetchingModelToCompareWith, data: modelToCompareWithResponse } = useQuery<
    AxiosResponse<PaginatedListModelOutputsList>,
    AxiosError
  >(
    ["request-log", startDate, endDate, projectUUID, modelBUUID, pagination.pageIndex, pagination.pageSize],
    () =>
      KonanAPI.fetchModelRequestLogData(
        startDate,
        endDate,
        modelBUUID ?? "",
        pagination.pageIndex + 1,
        pagination.pageSize,
      ),
    {
      keepPreviousData: true,
      onSuccess: (response) => {
        if (response?.data.results && response.data.results.length > 0) {
          setModelBTotalCount(response.data.count ?? 0)
        }
      },
    },
  )

  const memoizedGetModelByUUID = useCallback((uuid: string) => getModelByUUID(uuid, models), [models])

  //reset model A and B pages whenever the user changes the models
  useEffect(() => {
    setPagination({ ...pagination, pageIndex: 0 })
    setModelAData(memoizedGetModelByUUID(modelAUUID as string))
    setModelBData(memoizedGetModelByUUID(modelBUUID as string))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelAUUID, modelBUUID])

  // Use useEffect instead of useMemo to prevent scrolling to the top when returning []
  useEffect(() => {
    if (
      ((response?.data.results && response.data.results.length > 0) ||
        (modelToCompareWithResponse?.data.results && modelToCompareWithResponse.data.results.length > 0)) &&
      models &&
      models.length > 0 &&
      !isFetchingModelToCompareWith &&
      !isFetching
    ) {
      const modelAData = Array.from(response?.data.results ?? [])
      const modelBData = Array.from(modelToCompareWithResponse?.data.results ?? [])

      setComparedData(compareModels(modelAData, modelBData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, modelToCompareWithResponse, models, modelAUUID, modelBUUID])

  // reset pagination when date range changes
  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }, [startDate, endDate])

  const columns: MRT_ColumnDef<ModelComparison>[] = [
    {
      header: "Request Date",
      size: 20,
      accessorKey: "created_at",
      accessorFn: (row) => moment(row.created_at).utc().format("DD/MM/YYYY"),
    },
    {
      header: "Request Time (UTC)",
      accessorKey: "created_at_utc",
      maxSize: 20,
      size: 20,
      enableResizing: true,
      accessorFn: (row) => moment(row.created_at).utc().format("hh:mm:ss A"),
    },
    {
      header: "Feedback",
      accessorKey: "feedback",
      size: 20,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return row.original.feedback ? <CheckIcon /> : <RemoveIcon />
      },
    },
    {
      header: `Model A Status Code`,
      accessorKey: "model_a_status_code",
      accessorFn: (row) => row.model_a_status_code?.toString(),
      size: 150,
      // Make error's color darker than success to emphasize the success rows
      Cell: ({ row }) => {
        if (row.original.model_a_status_code !== undefined && row.original.model_a_status_code !== null) {
          return (
            <span
              style={{
                color:
                  row.original.model_a_status_code >= 400 ? theme.palette.red.text[2] : theme.palette.green.text[2],
              }}
            >
              {row.original.model_a_status_code}
            </span>
          )
        } else {
          return <span style={{ color: theme.palette.yellow.text[2] }}>NA</span>
        }
      },
    },
    {
      header: `Model B Status Code`,
      accessorKey: "model_b_status_code",
      accessorFn: (row) => row.model_b_status_code?.toString(),
      size: 150,
      // Make error's color darker than success to emphasize the success rows
      Cell: ({ row }) => {
        if (row.original.model_b_status_code !== undefined && row.original.model_b_status_code !== null) {
          return (
            <span
              style={{
                color:
                  row.original.model_b_status_code >= 400 ? theme.palette.red.text[2] : theme.palette.green.text[2],
              }}
            >
              {row.original.model_b_status_code}
            </span>
          )
        } else {
          return <span style={{ color: theme.palette.yellow.text[2] }}>NA</span>
        }
      },
    },
    {
      header: `Model A Response Time`,
      accessorKey: "model_a_response_time",
      accessorFn: (row) => {
        if (row.model_a_response_time) return row.model_a_response_time + " ms"
        else {
          return <span style={{ color: theme.palette.yellow.text[2] }}>NA</span>
        }
      },
      size: 150,
    },
    {
      header: `Model B Response Time`,
      accessorKey: "model_b_response_time",
      accessorFn: (row) => {
        if (row.model_b_response_time) return row.model_b_response_time + " ms"
        else {
          return <span style={{ color: theme.palette.yellow.text[2] }}>NA</span>
        }
      },
      size: 150,
    },
  ]

  return (
    <BaseTableContainer
      title={
        isFetching || isFetchingModelToCompareWith ? (
          <Skeleton animation="wave" height={30} width={300} />
        ) : (
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="h3-bold">{modelAData?.name + " (A)"}</Typography>
            </Grid>
            <Grid item>
              <ModelType modelState={modelAData?.state} />
            </Grid>
            <Grid item>
              <Typography variant="h3-bold">{"VS. " + modelBData?.name + " (B)"}</Typography>
            </Grid>
            <Grid item>
              <ModelType modelState={modelBData?.state} />
            </Grid>
          </Grid>
        )
      }
      columns={columns}
      source="model-comparison"
      isLoading={isFetching || isFetchingModelToCompareWith}
      data={comparedData}
      pagination={pagination}
      setPagination={setPagination}
      rowCount={Math.min(modelATotalCount, modelBTotalCount)}
      detailPanel={(row) => {
        return (
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={row.original.feedback ? 3 : 4}>
                <Typography variant="p" gutterBottom>
                  Request Body
                </Typography>
                <KonanJsonView src={row.original.features} />
              </Grid>
              {row.original.feedback && (
                <Grid item xs={3}>
                  <Typography variant="p" gutterBottom>
                    Feedback
                  </Typography>
                  <KonanJsonView src={row.original.feedback} />
                </Grid>
              )}
              <Grid item xs={row.original.feedback ? 3 : 4}>
                <Typography variant="p" gutterBottom>
                  {modelAData?.name} Response
                </Typography>
                <KonanJsonView src={row.original.model_a_mls_output} />
              </Grid>
              <Grid item xs={row.original.feedback ? 3 : 4}>
                <Typography variant="p" gutterBottom>
                  {modelBData?.name} Response
                </Typography>
                <KonanJsonView src={row.original.model_b_mls_output} />
              </Grid>
            </Grid>
          </Box>
        )
      }}
    />
  )
}
