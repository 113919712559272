import React from "react"

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import { Drawer, Grid } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import styles from "./SideBar.module.scss"

interface Props {
  isOpen?: boolean
  onClose?: () => void
  title?: string
  children?: React.ReactElement
  actions?: React.ReactElement[]
}
/** */
export function SideBar(props: Readonly<Props>): React.ReactElement {
  const { children, title, actions, onClose, isOpen = false } = props

  return (
    // Drawer should be anchored to the container (settings screen for example) not the body tag
    // TODO:: should use a portal with dynamic anchor to attach it to
    // Meaning we will have to develop our own side bar soon
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor={"right"}
      // using inline to lessen the level of specificity i will have to write in a css class
      PaperProps={{ style: { width: "25%", minWidth: "350px" } }}
      slotProps={{ backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.2)" } } }}
    >
      <Grid container item className={styles.container}>
        {/* Header */}
        <Grid item xs={12} className={styles.header} display={"flex"} gap={1.5}>
          {onClose && (
            <Grid item>
              <Button onClick={() => onClose?.()} size="small">
                <ArrowRightAltIcon sx={{ fontSize: "16px" }} />
              </Button>
            </Grid>
          )}

          <Grid item>
            <Typography variant="h2-bold" textTransform="capitalize">
              {title}
            </Typography>
          </Grid>
        </Grid>

        {/* Body */}
        <Grid item xs={12} className={styles.body} p={3}>
          {children}
        </Grid>

        {/* Actions */}
        {actions && actions.length > 0 && (
          <Grid container item xs={12} justifyContent={"flex-end"} gap={1} className={styles.actions}>
            {actions?.map((action, index) => {
              return (
                <Grid item key={`${index}`}>
                  {action}
                </Grid>
              )
            })}
          </Grid>
        )}
      </Grid>
    </Drawer>
  )
}
