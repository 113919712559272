import React, { Fragment, useEffect, useMemo } from "react"

import { FileRejection, useDropzone } from "react-dropzone"

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import { CardActionArea, Grid, Link } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { LinearProgressWithLabel } from "../../../components/LinearProgressWithLabel"
import { getTheme } from "../../../hooks/UseTheme"
import { dropZoneCSVExtensionValidator } from "../../../utils/genericHelpers"

type Props = {
  uploadingCSV: boolean
  uploadProgress: number
  currentSelectedFiles: File[]
  setSelectedFiles: (file: File[]) => void
  setInvalidFiles: (file: FileRejection[]) => void
  bottomText?: string
  handleCancelUpload: () => void
  onDropZoneOpen?: (open: () => void) => void
  height?: number
}

/**
 * dropZone component
 * @param  {boolean} uploadingCSV
 * @param  {number} uploadProgress
 * @param  {File[]} currentSelectedFiles
 * @param  {Function} setSelectedFiles
 * @param  {Function} setInvalidFiles
 * @param  {string} bottomText text to display at the bottom of the dropzone
 * @param  {Function} handleCancelUpload
 * @return  {React.ReactElement}
 */
export function Dropzone(props: Props): React.ReactElement {
  const {
    uploadingCSV,
    uploadProgress,
    currentSelectedFiles,
    setSelectedFiles,
    setInvalidFiles,
    bottomText,
    handleCancelUpload,
    onDropZoneOpen,
    height,
  } = props

  const theme = getTheme()

  const baseStyle: React.CSSProperties = useMemo(() => {
    return {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 4,
      borderColor: theme.palette.neutral.border.default,
      borderStyle: "dashed",
      backgroundColor: theme.palette.neutral.background.default,
      color: theme.palette.grayscale.text[2],
      outline: "none",
      height: height ? `${height}px` : "135px",
      transition: "border .24s ease-in-out",
      cursor: "pointer",
    }
  }, [
    height,
    theme.palette.grayscale.text,
    theme.palette.neutral.background.default,
    theme.palette.neutral.border.default,
  ])

  const activeStyle = useMemo(() => {
    return {
      borderColor: theme.palette.blue.border,
    }
  }, [theme.palette.blue.border])

  const acceptStyle = useMemo(() => {
    return {
      borderColor: theme.palette.green.border,
    }
  }, [theme.palette.green.border])

  const rejectStyle = useMemo(() => {
    return {
      borderColor: theme.palette.red.border,
    }
  }, [theme.palette.red.border])

  const onDrop = (acceptedFiles: File[]): void => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()
      reader.readAsText(file)
    })
  }

  // Dropzone stuff
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } =
    useDropzone({
      disabled: uploadingCSV,
      multiple: false,
      onDrop,
      accept: {
        "text/*": [".csv"],
      },
      validator: dropZoneCSVExtensionValidator,
    })

  // Need to style dropzone, https://react-dropzone.js.org/#!/Styling%20Dropzone
  const dropzoneStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [baseStyle, isDragActive, activeStyle, isDragAccept, acceptStyle, isDragReject, rejectStyle],
  )

  // rejects file if invalid
  useEffect(() => {
    setInvalidFiles(fileRejections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections])

  // accepts file if valid
  useEffect(() => {
    setSelectedFiles(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  // this effect open files if the passed `onDropZoneOpen` is passed and called
  useEffect(() => {
    if (onDropZoneOpen) {
      onDropZoneOpen(open)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      {/* add && uploadingCSV */}
      {currentSelectedFiles && currentSelectedFiles.length > 0 && !(fileRejections && fileRejections.length > 0) ? (
        <CardActionArea
          className={currentSelectedFiles && currentSelectedFiles.length > 0 ? "selected-dropzone" : "dropzone"}
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="p">{currentSelectedFiles[0].name}</Typography>
            </Grid>
            <Grid item xs={12} mt={0.5} mb={0.5}>
              <Typography variant="span">{uploadingCSV ? "Uploading file..." : "Uploaded!"}</Typography>
            </Grid>
            <Grid item xs={12}>
              <LinearProgressWithLabel value={uploadProgress} isUploading={uploadingCSV} />
            </Grid>
          </Grid>
        </CardActionArea>
      ) : (
        <div {...getRootProps({ style: dropzoneStyle })}>
          <input {...getInputProps()} />
          <Typography variant="p">
            Drop or upload CSV file{" "}
            <FileUploadOutlinedIcon style={{ verticalAlign: "bottom", color: "var(--grayscale-text-2)" }} />
          </Typography>
        </div>
      )}

      {bottomText && (
        <Grid container direction="row" justifyContent="space-between" spacing={2} pt={0.5}>
          <Grid item>
            <Typography variant="span">{bottomText}</Typography>
          </Grid>

          {currentSelectedFiles && currentSelectedFiles.length > 0 && (
            <Grid item>
              <Typography variant="span">
                <Link
                  component="button"
                  onClick={uploadingCSV ? handleCancelUpload : () => setSelectedFiles([])}
                  underline="hover"
                  // --blue-text-2 (DARK)
                  style={{ color: "var(--blue-09)" }}
                >
                  {uploadingCSV ? "Cancel upload" : "Re-upload"}
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  )
}
