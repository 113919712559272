import React, { useCallback, useEffect, useRef, useState } from "react"

import { FileRejection } from "react-dropzone"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import { Grid } from "@mui/material"
import { NotificationUtils, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { getTheme } from "../../../hooks/UseTheme"
import { KonanAPI } from "../../../services/KonanAPI"
import { DataFile } from "../../../types/generated/api/DataFile"
import { Dropzone } from "../../projects/components/Dropzone"
import { NewDatasetViewProps } from "../Interfaces"

import styles from "../Simulations.module.scss"

/**
 * Simulation new data upload screen
 * @param {WorkflowVersionsRetrieve} workflow
 * @param {(uuid: string, columns: string[]) => void} setDataFile
 * @param {FormikProps<WorkflowSimulationFormik>} formik
 * @return {React.ReactElement}
 */
export function NewDatasetView(props: Readonly<NewDatasetViewProps>): React.ReactElement {
  const { workflow, setDataFile, formik } = props

  const { id: projectID } = useParams<{ id: string }>()

  const abortControllerRef = useRef<AbortController | null>(null)

  const queryClient = useQueryClient()
  const theme = getTheme()

  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [invalidFiles, setInvalidFiles] = useState<FileRejection[]>([])
  const [uploadedColumns, setUploadedColumns] = useState<Array<string>>([])

  // upload file mutation
  const {
    isLoading: uploadingCSV,
    isError,
    mutateAsync: uploadCSVAsyncMutation,
    reset,
  } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      abortControllerRef.current = new AbortController()
      return KonanAPI.uploadDataFile({
        project_uuid: projectID as string,
        file: file,
        setProgress: setUploadProgress,
        signal: abortControllerRef.current.signal,
        type: DataFile.type.PREDICTIONS,
      })
    },
    {
      mutationKey: "uploadCSV",
      onSuccess: (res) => {
        setUploadedColumns([...(res.columns ?? [])])
        setDataFile(res.uuid, [...(res.columns ?? [])])

        // disabling the next btn if required feats arent met
        const shouldProceed = !workflow?.schema?.features
          ?.filter((feat) => feat?.is_required)
          .some((feat) => !res.columns?.find((col) => col === feat?.name))

        // setting everytime to invalidate cached data in formik
        formik.setFieldValue("shouldProceed", shouldProceed)

        setTimeout(() => {
          formik.validateForm()
        }, 100)

        NotificationUtils.toast("Dataset upload successfully.", {
          snackBarVariant: "positive",
        })
      },
      onError: () => {
        NotificationUtils.toast("An error occurred, please try again", {
          snackBarVariant: "negative",
        })
      },
    },
  )

  // handle close dropzone
  const handleCloseAndAbort = useCallback(() => {
    abortControllerRef.current?.abort()
    reset()
    setUploadProgress(0)
    setAcceptedFiles([])
  }, [reset])

  // handle upload once files are accepted
  const handleUpload = async (): Promise<void> => {
    try {
      await uploadCSVAsyncMutation(acceptedFiles[0])
      // Invalidate training data to trigger a refresh
      await queryClient.invalidateQueries(["data-files", projectID])
    } catch (err) {
      // reset dropzone on error
      handleCloseAndAbort()
    }
  }

  // starts uploading once the file is read
  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  return (
    <Grid container spacing={2}>
      {/**
       * upload errors
       * 1. invalid file type
       * 2. request didn't succeed
       */}
      {invalidFiles && invalidFiles.length > 0 && (
        <Grid item xs={12}>
          <Snackbar
            variant="negative"
            fullWidth
            description={`${invalidFiles[0].file.name} is not a valid CSV file.`}
          />
        </Grid>
      )}

      {isError && (
        <Grid item xs={12}>
          <Snackbar variant="negative" fullWidth description={"An error occurred. Please try again."} />
        </Grid>
      )}

      {/* DropZone */}
      <Grid container item xs={12} spacing={0.5}>
        <Grid item xs={12}>
          <Typography
            variant="label"
            tooltip="This data will be used to train the model. Note that it has to match the required schema."
            tooltipIconSize={16}
            tooltipVerticalAlign="middle"
            variantColor={2}
          >
            New Dataset
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Dropzone
            uploadingCSV={uploadingCSV}
            uploadProgress={uploadProgress}
            currentSelectedFiles={acceptedFiles}
            setSelectedFiles={(file: File[]) => {
              file.length === 0 && formik.setFieldValue("shouldProceed", false)
              setAcceptedFiles(file)
            }}
            setInvalidFiles={(file: FileRejection[]) => setInvalidFiles(file)}
            bottomText="Max 2 GB"
            handleCancelUpload={handleCloseAndAbort}
          />
        </Grid>
      </Grid>

      {/* Features table, enabled only if schema exists */}
      {workflow?.schema?.features?.filter((feat) => feat?.is_required)?.length > 0 && (
        <Grid container item mt={1} ml={2} className={styles.existingDataSetTable}>
          <Grid item xs={12}>
            <Typography variant="a" className={styles.requiredFeaturesHeader}>
              Required Features
            </Typography>
          </Grid>

          {workflow?.schema?.features
            ?.filter((feat) => feat?.is_required)
            .sort((a, b) => Number(a) - Number(b))
            .map((item) => {
              return (
                <Grid container item xs={12} className={styles.existingDataSetRow} key={item?.name}>
                  <Grid item xs={acceptedFiles?.length > 0 ? 8 : 12}>
                    <Typography variant="label" noWrap>
                      {item?.name}
                    </Typography>
                  </Grid>

                  {acceptedFiles?.length > 0 && uploadedColumns?.length > 0 && (
                    <Grid item xs={4} container>
                      <Grid item mr={1} pt={0.5}>
                        {uploadedColumns?.find((feat) => feat === item?.name) ? (
                          <CheckIcon fontSize="small" style={{ color: theme.palette.green.text[2] }} />
                        ) : (
                          <ClearIcon fontSize="small" style={{ color: theme.palette.red.text[2] }} />
                        )}
                      </Grid>
                      <Grid item alignSelf={"center"}>
                        <Typography variant="p" variantColor={2} noWrap>
                          {uploadedColumns?.find((feat) => feat === item?.name) ? "Found" : "Not found"}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )
            })}
        </Grid>
      )}
    </Grid>
  )
}
