import React from "react"

import { Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { SelectWithSearch } from "./UI/SelectWithSearch"

import styles from "./GraphHeader.module.scss"

type Props = {
  title?: string
  tooltipText?: string | React.ReactElement
  range?: number
  selectProps?: {
    options: string[]
    value: string
    setValue: (val: string) => void
  }
  headerMode?: "full" | "select" | "none"
}

export function GraphHeader(props: Readonly<Props>): React.ReactElement {
  const { title, range, tooltipText, selectProps, headerMode = "full" } = props

  return (
    <Grid>
      {headerMode === "full" ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={`chart-header ${styles.header}`}
        >
          {/* Chart title */}
          <Grid item>
            <Typography variant="h3-bold" tooltip={tooltipText} tooltipVerticalAlign="middle" tooltipIconSize={19}>
              {title}
            </Typography>
          </Grid>

          {/* Date range of the data displayed */}
          {range ? (
            <Grid item>
              <Typography variant="span">{!!range && range + " days"}</Typography>
            </Grid>
          ) : (
            selectProps && (
              <SelectWithSearch
                initialValue={selectProps.value}
                options={[
                  { label: "Select Label", value: "", isDisabled: true },
                  ...selectProps.options.map((option) => {
                    return { label: option, value: option }
                  }),
                ]}
                searchInputPlaceHolder="Search features"
                fullWidth={false}
                onSelectMenuItem={(item: { label: string; value: string }) => {
                  selectProps.setValue(item.value)
                }}
              />
            )
          )}
        </Grid>
      ) : (
        headerMode === "select" &&
        selectProps && (
          <Grid container item xs={12} px={1.5} pt={1.5}>
            <SelectWithSearch
              onSelectMenuItem={(item: { label: string; value: string }) => {
                selectProps.setValue(item.value)
              }}
              initialValue={selectProps.value}
              options={[
                { label: "Select Label", value: "", isDisabled: true },
                ...selectProps.options.map((option) => {
                  return { label: option, value: option }
                }),
              ]}
            />
          </Grid>
        )
      )}
    </Grid>
  )
}
