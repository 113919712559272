import { Fragment, MouseEvent, ReactElement, useRef, useState } from "react"

import { Checkbox, InputText, Menu, MenuItem, Typography } from "@synapse-analytics/synapse-ui"

import { CustomArrowAdornment } from "../UI/CustomArrowAdornment"

interface CustomMenuItemProps {
  /** Visually and functionally disable the menu item. */
  disabled?: boolean
  /** If true the menu item will be selected. */
  isSelected?: boolean
  /** Unique Value of Menu items to identify which menu item is selected . */
  label?: string
  /** Handle callback function when click on the menu item */
  onClick?: (event: MouseEvent<HTMLElement>) => void
  /** The ref to the HTML DOM element */
}

/**
 * Custom Menu Item component to handle the custom menu items.
 * @param {boolean} [disabled] Visually and functionally disable the menu item.
 * @param {boolean} [isSelected] If true the menu item will be selected.
 * @param {string} [label] Unique Value of Menu items to identify which menu item is selected.
 * @param {function} [onClick] Handle callback function when click on the menu item.
 * @returns {ReactElement}
 */
const CustomMenuItem = (props: CustomMenuItemProps): JSX.Element => {
  const { disabled, isSelected, label, onClick } = props
  return (
    <MenuItem
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
      onClick={onClick}
      isSelected={isSelected}
      disabled={disabled}
    >
      <Checkbox
        label={label}
        checked={isSelected}
        name="item-checkbox"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onChange={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      />
    </MenuItem>
  )
}

export interface FilterOptions {
  [key: string]: Array<string>
}

interface MenuFilterProps {
  isGrouped?: boolean
  options: Array<string> | FilterOptions
  value: Array<string> | FilterOptions | null | undefined
  onChange?: React.Dispatch<React.SetStateAction<string[]>> | React.Dispatch<React.SetStateAction<FilterOptions>>
}

/**
 * Custom Filter component to handle the custom filter options.
 * @param {boolean} [isGrouped] Flag to indicate if the filter options are grouped.
 * @param {Array<string> | FilterOptions} options List of options to be displayed in the filter.
 * @param {Array<string> | FilterOptions} value List of selected options in the filter.
 * @param {function} [onChange] Handler for when a filter option is selected.
 * @returns {ReactElement}
 */
export const MenuFilter = (props: MenuFilterProps): ReactElement => {
  const { isGrouped, options, value, onChange } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)

  const handleAdornmentClick = (): void => {
    if (inputRef?.current) {
      if (isMenuOpen) {
        setAnchorEl(null)
      } else {
        setAnchorEl(inputRef.current.parentElement)
        inputRef?.current.focus()
      }
    }
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <InputText
        isSelect
        placeholder="Choose filter"
        isNotchedLabel
        endAdornment={<CustomArrowAdornment isMenuOpen={isMenuOpen} handleAdornmentClick={handleAdornmentClick} />}
        handleInputClick={handleAdornmentClick}
        anchoringRef={inputRef}
        hideDescription
        value={
          value
            ? isGrouped
              ? Object.values(value).flat(1).join(", ")
              : Array.isArray(value)
                ? value.join(", ")
                : ""
            : ""
        }
      />
      {/* Menu */}
      <Menu open={isMenuOpen} onClose={handleClose} anchorEl={anchorEl} menuMaxContent>
        {isGrouped ? (
          <Fragment>
            {Object.keys(options).map((group) => (
              <Fragment key={group}>
                <Typography variant="label" variantColor={2}>
                  {group}
                </Typography>
                {!Array.isArray(options) &&
                  value &&
                  !Array.isArray(value) &&
                  options[group].map((item) => {
                    return (
                      <CustomMenuItem
                        key={`${group}-${item}`}
                        label={item}
                        onClick={() => {
                          ;(onChange as React.Dispatch<React.SetStateAction<FilterOptions>>)((value: FilterOptions) => {
                            return {
                              ...value,
                              [group]: value[group]?.includes(item)
                                ? value[group].filter((ele) => item !== ele)
                                : [...(value[group] ?? []), item],
                            }
                          })
                        }}
                        isSelected={value[group]?.includes(item)}
                      />
                    )
                  })}
              </Fragment>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            {Array.isArray(options) &&
              Array.isArray(value) &&
              value &&
              options.map((item) => (
                <CustomMenuItem
                  label={item}
                  isSelected={value?.includes(item)}
                  onClick={() => {
                    ;(onChange as React.Dispatch<React.SetStateAction<string[]>>)?.(
                      value?.includes(item) ? value.filter((elem) => elem !== item) : value ? [...value, item] : [item],
                    )
                  }}
                />
              ))}
          </Fragment>
        )}
      </Menu>
    </Fragment>
  )
}
