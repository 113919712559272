import React, { Fragment } from "react"

import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { BarDatum, ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { ModelAvgResponseTime } from "../../types/custom/projects"
import { Model } from "../../types/generated/api/Model"
import { customRound } from "../../utils/genericHelpers"
import { getModelByUUID } from "../../utils/modelDetailsHelpers"
import { orderModelsByStatus } from "../../utils/searchSortFilterHelpers"
import { GraphHeader } from "../GraphHeader"

type Props = {
  title: string
  data: ModelAvgResponseTime[]
  models: Model[]
  isLoading: boolean
  graphHeight: number
  range?: number
}

export function ResponseTimeBarChart(props: Props): React.ReactElement {
  const { title, data, models, isLoading, graphHeight, range } = props

  const theme = getTheme()

  const maxLabelLength = 24

  const sortedData: ModelAvgResponseTime[] = React.useMemo(() => {
    if (data && data.length > 0 && models && models.length > 0) {
      const newData = Array.from(data)
      //map creation date to each model
      newData.forEach((item) => {
        item.created_at = getModelByUUID(item.uuid, models)?.created_at
      })
      return orderModelsByStatus(newData, "desc")
    }
    return []
  }, [data, models])

  return (
    <div className={"charts-paper"}>
      <GraphHeader
        title={title}
        tooltipText="Average response time the model takes to process requests"
        range={range}
      />

      <Box style={{ height: graphHeight, padding: "4px 16px 8px 16px" }}>
        {(!data || data.length < 1) && !isLoading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            <Typography variant="p" variantColor={2}>
              No data to display
            </Typography>
          </Grid>
        ) : isLoading || !data ? (
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className={"full-height"}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="75%" />
            <Skeleton animation="wave" width="60%" height={30} />
            <Skeleton animation="wave" width="30%" />
          </Grid>
        ) : (
          <ResponsiveBar
            data={sortedData as BarDatum[]}
            theme={NivoTheme(theme)}
            indexBy="name"
            keys={["avg_response_time"]}
            colors={[theme.palette.blue.background[1]]}
            colorBy="id"
            margin={{ top: 30, right: 0, bottom: -20, left: 0 }}
            borderRadius={4}
            padding={0.75}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            valueFormat={(v) => v.toFixed(0)}
            enableLabel={false}
            enableGridY={false}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            animate={true}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -22,
                itemsSpacing: 20,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 15,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            legendLabel={() => "AVERAGE RESPONSE TIME"}
            labelSkipWidth={2000} // labelSkipWidth = minimum bar size needed for a normal label
            layers={[
              "grid",
              "axes",
              "bars",
              "markers",
              "legends",
              "annotations",
              ({ bars }) => {
                return (
                  <g>
                    {bars.map(({ width, y, key, data }) => {
                      return width ? (
                        <Fragment key={key}>
                          {/* model name */}
                          <text
                            //Calculate position of model name
                            transform={`translate(${0}, ${y - 9})`}
                            textAnchor="left"
                            dominantBaseline="central"
                            className="chart-text"
                          >
                            {`${
                              key.slice(key.indexOf(".") + 1).length > maxLabelLength
                                ? key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength) + "..."
                                : key.slice(key.indexOf(".") + 1)
                            }`}
                          </text>

                          {!data.value ? (
                            ""
                          ) : //Right value on bar
                          key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength).length * 6.5 +
                              customRound(data.value * 100, 2).toString().length * 6.5 +
                              40 <
                            width ? (
                            <text
                              transform={`translate(${
                                //Calculate position of right value
                                width - customRound(data.value, 0).toString().length * 14 - 10
                              }, ${y - 9})`}
                              textAnchor="right"
                              dominantBaseline="central"
                              className="chart-text"
                            >
                              {data.value ? `${customRound(data.value, 0)}ms` : ""}
                            </text>
                          ) : (
                            //display the value next to the name
                            <text
                              transform={`translate(${
                                key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength).length * 7 + 15
                              }, ${y - 9})`}
                              textAnchor="right"
                              dominantBaseline="central"
                              className="chart-text"
                            >
                              {`${customRound(data.value, 0)}ms`}
                            </text>
                          )}
                        </Fragment>
                      ) : null
                    })}
                  </g>
                )
              },
            ]}
            tooltip={(slice) => {
              return (
                <div className={"chart-tooltip"}>
                  <Typography variant="h3-bold" variantColor={2}>
                    {slice.data.name}
                  </Typography>
                  <Typography variant="p" variantColor={2}>{`Average Response Time: ${
                    slice.data.avg_response_time ? customRound(Number(slice.data.avg_response_time), 0) + "ms" : 0
                  }`}</Typography>
                </div>
              )
            }}
          />
        )}
      </Box>
    </div>
  )
}
