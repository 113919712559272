import React, { Fragment, useCallback, useMemo, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from "../.."
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { SidAPI } from "../../services/SidAPI"
import { RootState } from "../../store/ReduxStore"
import { OrganizationInvitation } from "../../types/generated/authentication/OrganizationInvitation"
import { Role } from "../../types/generated/authentication/Role"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { ContainerProps, MenuOption } from "./Interfaces"
import { MemberCard } from "./MemberCard"
import { MemberInviteDialog } from "./MemberInviteDialog"

/**
 * @param {OrganizationUser} member
 * @param {boolean} isLastMember
 * @return {ReactElement}
 */
export function Container(props: Readonly<ContainerProps>): React.ReactElement {
  const { member, isLastMember = false } = props

  const navigate = useNavigate()

  const organizationName = Auth.getOrganizationName() as string
  const organizationUUID = Auth.getOrganizationUID() as string
  const currentUser = Auth.getEmail() as string
  const permissions = Auth.getPermissions()
  const currentUserRole = Auth.getUserRole()

  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)

  // states for controlling opening/closing dialogs
  const [openInviteDialog, setOpenInviteDialog] = useState<boolean>(false)
  const [isMemberRemovalDialogOpen, setIsMemberRemovalDialogOpen] = useState<boolean>(false)
  const [isOrganizationDeletionDialogOpen, setIsOrganizationDeletionDialogOpen] = useState<boolean>(false)

  const leaveOrganizationMutation = useMutation<AxiosResponse, AxiosError>(SidAPI.leaveOrganization, {
    onSuccess: () => Auth.logOut(true, navigate),
    onError: () =>
      NotificationUtils.toast("Couldn't log you out, please try again.", {
        snackBarVariant: "negative",
      }),
  })

  const deleteOrganizationMutation = useMutation<AxiosResponse, AxiosError, string>(SidAPI.deleteOrganization, {
    onSuccess: () => Auth.logOut(true, navigate),
    onError: () =>
      NotificationUtils.toast("Couldn't log you out, please try again.", {
        snackBarVariant: "negative",
      }),
  })

  const removeUserMutation = useMutation<AxiosResponse, AxiosError, string>(SidAPI.removeUser, {
    onSuccess: async () => {
      NotificationUtils.toast(`Member [${member.first_name + member.last_name}] removed`, {
        snackBarVariant: "positive",
      })

      await queryClient.invalidateQueries(["Organization", organizationUUID])
    },
    onError: () =>
      NotificationUtils.toast("Couldn't log you out, please try again.", {
        snackBarVariant: "negative",
      }),
  })

  const handleRemove = useCallback((): void => {
    isLastMember ? setIsOrganizationDeletionDialogOpen(true) : setIsMemberRemovalDialogOpen(true)
  }, [isLastMember])

  const menuOptions = useMemo((): MenuOption[] => {
    const options: MenuOption[] = []

    isPermitted("List roles", permissions.synapse_id, flattenedSidPermissions) &&
      isPermitted("List groups", permissions.synapse_id, flattenedSidPermissions) &&
      member.role_label !== "OWNER" &&
      options.push({
        title: "Edit",
        variant: "neutral",
        onClick: () => setOpenInviteDialog(true),
      })

    isPermitted("Kick users", permissions.synapse_id, flattenedSidPermissions) &&
      options.push({
        title: "Remove",
        variant: "negative",
        onClick: handleRemove,
      })

    return member.role_label === "OWNER" && currentUserRole !== "OWNER" ? [] : options
  }, [permissions.synapse_id, flattenedSidPermissions, handleRemove, member.role_label, currentUserRole])

  return (
    <Fragment>
      {/* Member removal/Leaving dialog */}
      <BaseSimpleDialog
        open={isMemberRemovalDialogOpen}
        name={currentUser === member.email ? organizationName : (`${member.first_name} ${member.last_name}` ?? "")}
        mode={currentUser === member.email ? "member-leaving" : "member-removal"}
        onAccept={async () => {
          if (currentUser === member.email) {
            await leaveOrganizationMutation.mutateAsync()
          } else {
            await removeUserMutation.mutateAsync(member.uid)
          }
        }}
        isLoading={currentUser === member.email ? leaveOrganizationMutation.isLoading : removeUserMutation.isLoading}
        onClose={() => {
          setIsMemberRemovalDialogOpen(false)
        }}
      />

      {/* Organization Deletion dialog */}
      <BaseSimpleDialog
        open={isOrganizationDeletionDialogOpen}
        name={organizationName}
        mode={"organization-deletion"}
        onAccept={async () => await deleteOrganizationMutation.mutateAsync(organizationUUID)}
        isLoading={deleteOrganizationMutation.isLoading}
        onClose={() => {
          setIsOrganizationDeletionDialogOpen(false)
        }}
      />

      {openInviteDialog && (
        <MemberInviteDialog isOpen={openInviteDialog} onClose={() => setOpenInviteDialog(false)} member={member} />
      )}

      <MemberCard
        name={member.first_name + " " + member.last_name}
        email={member.email}
        role={member.role_label}
        date={member.date_joined}
        MenuOptions={menuOptions}
      />
    </Fragment>
  )
}

interface PendingMemberContainerProps {
  member: OrganizationInvitation
}
export function PendingMemberContainer(props: Readonly<PendingMemberContainerProps>): React.ReactElement {
  const { member } = props

  const organizationUUID = Auth.getOrganizationUID() as string
  const permissions = Auth.getPermissions()

  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)

  const [isInvitationDeletionDialogOpen, setIsInvitationDeletionDialogOpen] = useState<boolean>(false)

  // Fetch single role
  const { data: role } = useQuery<AxiosResponse<Role>, AxiosError>(
    ["role", member.role],
    () => SidAPI.fetchRole(member.role.uid),
    {
      enabled: !!organizationUUID && !!member.role,
      refetchOnMount: false,
    },
  )

  const invitationDeletionMutation = useMutation<AxiosResponse, AxiosError, string>(SidAPI.removeInvitation, {
    onSuccess: async () => {
      NotificationUtils.toast(`Invitation [${member.email}] Canceled`, {
        snackBarVariant: "positive",
      })

      await queryClient.invalidateQueries(["OrganizationInvitation", organizationUUID])
    },
    onError: () =>
      NotificationUtils.toast("Couldn't log you out, please try again.", {
        snackBarVariant: "negative",
      }),
  })

  return (
    <Fragment>
      {/* Invitation Deletion dialog */}
      <BaseSimpleDialog
        open={isInvitationDeletionDialogOpen}
        name={member.email ?? ""}
        mode={"invitation-deletion"}
        onAccept={async () => {
          await invitationDeletionMutation.mutateAsync(member.token)
          setIsInvitationDeletionDialogOpen(false)
        }}
        isLoading={invitationDeletionMutation.isLoading}
        onClose={() => {
          setIsInvitationDeletionDialogOpen(false)
        }}
      />

      <MemberCard
        name={member.email}
        role={role?.data.name ?? "USER"}
        date={member.created_at}
        pending
        MenuOptions={
          isPermitted("Delete user invitations", permissions.synapse_id, flattenedSidPermissions)
            ? [
                {
                  title: "Remove",
                  variant: "negative",
                  onClick: () => {
                    setIsInvitationDeletionDialogOpen(true)
                  },
                },
              ]
            : undefined
        }
      />
    </Fragment>
  )
}
