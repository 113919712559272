import React, { useEffect, useState } from "react"

import { useQuery } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"

import Hidden from "@mui/material/Hidden"
import { AxiosError, AxiosResponse } from "axios"

import { Registry } from "../../features/Registry/Registry"
import { setKonanPermissions, setSidPermissions } from "../../features/Roles/PermissionsSlice"
import { setComputedFeatures, setSchemaFeatures } from "../../features/projects"
import { ProjectCreationFlow } from "../../features/projects/ProjectCreationFlow"
import { PageFooter } from "../../layouts/PageFooter"
import { PageHeader } from "../../layouts/PageHeader"
import { ModelDetails } from "../../pages/ModelDetails"
import { ProjectDetails } from "../../pages/ProjectDetails"
import { Projects } from "../../pages/Projects"
import { Settings } from "../../pages/Settings"
import { KonanAPI } from "../../services/KonanAPI"
import { SidAPI } from "../../services/SidAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { RootState } from "../../store/ReduxStore"
import { Deployment } from "../../types/generated/api/Deployment"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { DashboardAppBar } from "./components/DashboardAppBar"
import { DashboardDrawer } from "./components/DashboardDrawer"

import styles from "./Dashboard.module.scss"

export function Dashboard(): React.ReactElement {
  const location = useLocation()
  const dispatch = useDispatch()

  const permissions = Auth.getPermissions()

  const flattenedSidPermissions = useSelector((state: RootState) => state.permissions.flattenedSidPermissions)

  const [mobileOpen, setMobileOpen] = useState(false)

  const [currentProject, setCurrentProject] = useState<Deployment | undefined>(undefined)
  const [currentModel, setCurrentModel] = useState<string>("")

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen)
  }

  // Fetching organization roles
  useQuery<AxiosResponse, AxiosError>(["sid-permissions"], SidAPI.fetchSIDPermissions, {
    refetchOnMount: true,
    onSuccess: (response) => {
      dispatch(setSidPermissions(response))
    },
  })

  // Fetching organization roles
  useQuery<AxiosResponse, AxiosError>(["konan-permissions"], () => KonanAPI.fetchKonanPermissions(), {
    refetchOnMount: false,
    onSuccess: (response) => {
      dispatch(setKonanPermissions(response))
    },
  })

  // display extended variant of contact support FAB then switch to rounded variant
  useEffect(() => {
    if (!location.pathname.includes("/projects/")) {
      //reset current project's global state in projects page
      setCurrentProject(undefined)
      //reset current model's global state in projects page
      setCurrentModel("")
    }
  }, [location.pathname])

  // empty project features (schema and computed) if there's no currentProject
  // or it's not a workflow project
  useEffect(() => {
    if (!currentProject || currentProject?.category !== "workflow") {
      dispatch(setSchemaFeatures([]))
      dispatch(setComputedFeatures([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject])

  return (
    <div className={styles.root}>
      <CurrentProjectAndModelContext.Provider
        value={{ currentProject, setCurrentProject, currentModel, setCurrentModel }}
      >
        {/* Show AppBar on small screen sizes only, so that the user can open/close the drawer */}
        <Hidden smUp>
          <DashboardAppBar handleDrawerToggle={handleDrawerToggle} />
        </Hidden>
        <DashboardDrawer
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          orgName={Auth.getOrganizationName() ?? ""}
        />

        <main className={styles.contentContainer} id="main">
          {location?.pathname && location?.pathname !== "/projects/new-model" && <PageHeader />}
          <Routes>
            <Route path={"*"} element={<Navigate to={"projects"} replace />} />
            {(isPermitted("List roles", permissions.synapse_id, flattenedSidPermissions) ||
              isPermitted("List users", permissions.synapse_id, flattenedSidPermissions) ||
              isPermitted("List groups", permissions.synapse_id, flattenedSidPermissions)) && (
              <Route key="Settings" path={`settings`} element={<Settings />} />
            )}
            <Route key="registry" path="kcr" element={<Registry />} />
            <Route key="projects" path={`projects`} element={<Projects />} />
            <Route key="new-project" path={`projects/new-model`} element={<ProjectCreationFlow />} />
            <Route key="project-details" path={`projects/:id/*`} element={<ProjectDetails />} />
            <Route key="model-details" path={`projects/:id/models/*`} element={<ModelDetails />} />
          </Routes>
          <PageFooter />
        </main>
      </CurrentProjectAndModelContext.Provider>
    </div>
  )
}
