/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExportJobCreateRequest = {
  /**
   * This name will be taken as the exported csv file name. It allows all characters except [
   * , .]
   */
  name: string
  /**
   * * `DEPLOYMENT_PREDICTIONS` - Deployment Predictions
   * * `MODEL_PREDICTIONS` - Model Predictions
   * * `SIMULATION_PREDICTIONS` - Simulation Predictions
   * * `WORKFLOW_PREDICTIONS` - Workflow Predictions
   */
  type: ExportJobCreateRequest.type
  model?: string
  workflow?: string
  simulation_job?: string
  predictions_start_time?: string | null
  predictions_end_time?: string | null
  /**
   * * `CSV` - CSV
   * * `JSON` - JSON
   */
  data_type?: ExportJobCreateRequest.data_type
}
export namespace ExportJobCreateRequest {
  /**
   * * `DEPLOYMENT_PREDICTIONS` - Deployment Predictions
   * * `MODEL_PREDICTIONS` - Model Predictions
   * * `SIMULATION_PREDICTIONS` - Simulation Predictions
   * * `WORKFLOW_PREDICTIONS` - Workflow Predictions
   */
  export enum type {
    DEPLOYMENT_PREDICTIONS = "DEPLOYMENT_PREDICTIONS",
    MODEL_PREDICTIONS = "MODEL_PREDICTIONS",
    SIMULATION_PREDICTIONS = "SIMULATION_PREDICTIONS",
    WORKFLOW_PREDICTIONS = "WORKFLOW_PREDICTIONS",
  }
  /**
   * * `CSV` - CSV
   * * `JSON` - JSON
   */
  export enum data_type {
    CSV = "CSV",
    JSON = "JSON",
  }
}
