import React, { Fragment, useState } from "react"

import { useQuery } from "react-query"

import { Grid } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import packageJson from "../../package.json"
import { SupportDialog } from "../components/dialogs/SupportDialog"
import { KonanAPI } from "../services/KonanAPI"
// import { KonanAPI } from "../services/KonanAPI"
import { Version } from "../types/generated/api/Version"

import styles from "./PageFooter.module.scss"

/**
 * Konan Page footer
 * @return  {React.ReactElement}
 */
export function PageFooter(): React.ReactElement {
  const [openSupportDialog, setOpenSupportDialog] = useState<boolean>(false)

  // Fetch Backend version on mount
  const { data: response } = useQuery<AxiosResponse<Version>, AxiosError>(
    ["version"],
    () => KonanAPI.fetchBackendVersion(),
    {
      refetchOnMount: false,
    },
  )

  return (
    <Fragment>
      {openSupportDialog && <SupportDialog open={openSupportDialog} onClose={() => setOpenSupportDialog(false)} />}

      <Grid container justifyContent="space-between" alignItems="center" className={styles.footer}>
        <Grid item>
          {/* TODO:: enable after merging SUI latest */}
          {/* <Button onClick={() => setOpenSupportDialog(true)} size="small" variant="ghost">
            Report a Bug
          </Button> */}

          <Button size="regular" onClick={() => setOpenSupportDialog(true)}>
            Report a Bug
          </Button>
        </Grid>

        <Grid item>
          <Typography variant="p" color="neutral" gutterBottom={false} variantColor={2}>
            © Synapse Analytics &ensp; V{packageJson.version} &ensp;{" "}
            {response?.data.version && `V${response?.data.version}`}
          </Typography>
        </Grid>

        <Grid item>
          {/* TODO:: enable after merging SUI latest */}
          {/* <Button
            onClick={() => window.open(`https://www.synapse-analytics.io/konan-terms-and-conditions`, "_blank")}
            size="small"
            variant="ghost"
          >
            Terms & Privacy
          </Button> */}
          <Button
            size="regular"
            onClick={() => window.open(`https://www.synapse-analytics.io/konan-terms-and-conditions`, "_blank")}
          >
            Terms & Privacy
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  )
}
