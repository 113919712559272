import React, { ChangeEventHandler, Fragment } from "react"

import { Button } from "@synapse-analytics/synapse-ui"

import { UploadIcon } from "../../assets/SvgIcons"
import { getTheme } from "../../hooks/UseTheme"

interface Props {
  id: string
  accept: string
  onChange: ChangeEventHandler<HTMLInputElement>
  children: React.ReactNode
}

/**
 * Custom Upload component to handle file uploads.
 *
 * @param {string} id The id of the input element.
 * @param {string} accept The file types that the input should accept.
 * @param {ChangeEventHandler<HTMLInputElement>} onChange The event handler for the input element.
 * @param {React.ReactNode} children The children to render.
 * @returns {ReactElement}
 */
export const CustomUpload: React.FC<Props> = (props) => {
  const { children, id, accept, onChange } = props

  return (
    <Fragment>
      <input type="file" id={id} accept={accept} onChange={onChange} hidden />
      <label htmlFor={id} style={{ cursor: "pointer" }}>
        {children}
      </label>
    </Fragment>
  )
}

interface ButtonProps {
  id: string
  accept: string
  onChange: ChangeEventHandler<HTMLInputElement>
}

/**
 * Upload button component to handle file uploads.
 *
 * @param {string} id The id of the input element.
 * @param {string} accept The file types that the input should accept.
 * @param {ChangeEventHandler<HTMLInputElement>} onChange The event handler for the input element.
 * @returns {ReactElement}
 */
export const UploadButton: React.FC<ButtonProps> = (props) => {
  const theme = getTheme()

  return (
    <Button variant="ghost" size="regular">
      <CustomUpload {...props}>
        <UploadIcon width="16" height="16" fill={theme.palette.neutral.text.active} /> Upload File
      </CustomUpload>
    </Button>
  )
}
