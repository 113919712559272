// declaring our custom edge type/style to react-flow
import { EdgeMarker, MarkerType } from "reactflow"

import { v4 as uuidv4 } from "uuid"

import { getTheme } from "../../hooks/UseTheme"
import { Operators } from "../../types/custom/projects"
import { FilterCondition } from "../../types/custom/rules"
import { AddBlockNode } from "./components/AddBlockNode"
import { CalculatorNode } from "./components/CalculatorNode"
import { CustomEdgeLabel } from "./components/CustomEdgeLabel"
import { FilterNode } from "./components/FilterNode"
import { LabeledNode } from "./components/LabeledNode"
import { LoopEndNode } from "./components/LoopEndNode"
import { LoopStartNode } from "./components/LoopStartNode"
import { SelectionNode } from "./components/SelectionNode"

export const edgeTypes = {
  custom: CustomEdgeLabel,
}

const theme = getTheme()

// Generic CUSTOM EDGE STYLES
export const markerEnd: EdgeMarker = {
  type: MarkerType.ArrowClosed,
  width: 40,
  height: 20,
  color: theme.palette.neutral.border.default,
  orient: "auto",
}
export const edgeStyles = {
  strokeWidth: 2,
  stroke: theme.palette.neutral.border.default,
  color: theme.palette.neutral.border.default,
}

// declaring our custom nodes types/styles to react-flow
export const nodeTypes = {
  SelectionNode,
  AddBlockNode,
  LabeledNode,
  FilterNode,
  CalculatorNode,
  LoopStartNode,
  LoopEndNode,
}

export const EMPTY_FILTER_CONDITION: FilterCondition = {
  feature: "",
  valueOrFeature: "Value",
  value: "",
  type: "string",
  secondValue: "",
  secondFeature: "",
  id: uuidv4(),
  andOr: "and",
  operator: Operators["="],
}

export const NODES_WITHOUT_LABEL = [
  "ProjectNode",
  "StartNode",
  "EndResultNode",
  "TaglistNode",
  "ScorecardsetNode",
  "CalculatorNode",
  "ScriptNode",
  "ProgramNode",
  "LoopStartNode",
  "LoopEndNode",
]

export const StaticAnalysisScript = `# importing request from flask is for input data retrieval
from flask import request


# Main function is the method that will be run and its name must be main
def main():
	# This is how to get input data sent from workflow
	data = request.get_json()
	score = data.get("score") # for example here i am getting the score from the input and returning it in the returned dict

	return {"score": score, "i_score":1234} # returned dict`
