import { useLayoutEffect, useState } from "react"

// This custom hook listens to any resizing event triggered in the DOM, then re-calculates the new window width and height.
// this can be very useful when we are dealing with a scrollable view, for example: Model logs component.
// and since we use this type of measurement we use useLayoutEffect instead of useEffect.
// info about useLayoutEffect: https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize(): void {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}
