import React, { Fragment, memo, useContext, useMemo, useState } from "react"

import { useQuery } from "react-query"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router"

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, Grid, IconButton, SvgIcon } from "@mui/material"
import { Menu, MenuItem, Skeleton, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import { KonanAvatar } from "../../components/Avatar"
import { ProjectType } from "../../components/ProjectType"
import { RequestsDailyBarChart } from "../../components/graphs/RequestsDailyBarChart"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { RootState } from "../../store/ReduxStore"
import { Deployment } from "../../types/generated/api/Deployment"
import { RequestSummaryDailyOutput } from "../../types/generated/api/RequestSummaryDailyOutput"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { KonanTimeHelper, commify } from "../../utils/genericHelpers"

import Styles from "./Projects.module.scss"

interface PredictionsContainerProps {
  totalPredictionsCount: number
  dailyPredictionDates: RequestSummaryDailyOutput | undefined
  isPredictionDatesLoading: boolean
}

/**
 * Predictions container, which holds
 * 1. Predictions graph
 * 2. loading state
 * @param  {number} totalPredictionsCount
 * @param  {RequestSummaryDailyOutput} dailyPredictionDates
 * @param  {boolean} isPredictionDatesLoading
 * @return  {React.ReactElement}
 */
function PredictionsContainer(props: Readonly<PredictionsContainerProps>): React.ReactElement {
  const { totalPredictionsCount, dailyPredictionDates, isPredictionDatesLoading } = props

  return (
    <Fragment>
      {totalPredictionsCount ? (
        <Grid container item xs={12} className={Styles.projectPredictionContainer}>
          <Grid item container className={Styles.projectPredictionTypography}>
            <Grid item>
              <Typography className={Styles.projectTotalPredictions} variant="label">
                {commify(totalPredictionsCount)}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="span" className={Styles.projectLast28}>
                Last 28 days
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <RequestsDailyBarChart
              data={dailyPredictionDates ? dailyPredictionDates?.data : []}
              graphHeight={40}
              isLoading={isPredictionDatesLoading}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid className={Styles.projectEmptyState} container item xs={12}>
          <Typography variant="a" className={Styles.projectEmptyStateTitle}>
            No predictions
          </Typography>
        </Grid>
      )}
    </Fragment>
  )
}

interface ProjectCardProps {
  project: Deployment
  onProjectDeletionClicked(name: string, id: string): void
}

/**
 * Project overview card
 * @param  {Project} project
 * @param  {function} onProjectDeletionClicked
 * @return  {React.ReactElement}
 */
export const ProjectCard = memo(function ProjectCard(props: Readonly<ProjectCardProps>): React.ReactElement {
  const { project, onProjectDeletionClicked } = props
  const permissions = Auth.getPermissions()

  const { setCurrentProject } = useContext(CurrentProjectAndModelContext)

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const konanTime = new KonanTimeHelper()
  const predictionsStartDate = konanTime.adjustDate(moment().subtract(27, "days"), "start")?.toISOString() as string
  const now = konanTime.adjustDate(moment(), "end")?.toISOString() as string

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // fetching model request summary in the prediction graph
  const { isLoading: isPredictionDatesLoading, data: dailyPredictionDates } = useQuery<RequestSummaryDailyOutput>(
    ["project-daily-request-summary", project.uuid],
    () =>
      KonanAPI.fetchDailyProjectRequestSummary({
        project_uuid: project.uuid,
        start_date: predictionsStartDate,
        end_date: now,
      }),
    {
      enabled: !!project.uuid,
    },
  )

  // memoizing total live model predictions to avoid heavy recalculations
  const totalPredictionsCount = useMemo(() => {
    let liveModelTotalPredctions = 0
    dailyPredictionDates?.data.forEach((item) => {
      liveModelTotalPredctions += item.successful + item.failed
    })

    return liveModelTotalPredctions
  }, [dailyPredictionDates])

  // handler for selecting a project to navigate to, setting the currentProject context with
  // the initial project info we have untill we fetch the whole project,
  // this will eliminate extra unnecessary loading state
  const handleProjectClick = (): void => {
    // set the currentProject with the initial data we have

    /*TODO: refactor this when we change from context to redux */
    setCurrentProject({
      name: project?.name,
      category: project?.category,
      description: project?.description,
      is_demo: project?.is_demo,
      uuid: project?.uuid,
      type: project?.type,
    })

    // navigate the route
    navigate(`${pathname}/${project.uuid}/${project.category === "workflow" ? "workflows" : "overview"}`)
  }

  return (
    <Card className={"card-box-shadow"}>
      <Grid container direction="column" className={Styles.projectCardHeaderRoot}>
        {project.category === "model" && (
          <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start">
            <Grid item container xs>
              <ProjectType type={project.type} size="small" />
            </Grid>

            {isPermitted("Delete project", permissions.konan, flattenedKonanPermissions) && (
              <CardMenu
                anchorEl={anchorEl}
                setAnchorEl={(anchor: HTMLElement | null) => {
                  setAnchorEl(anchor)
                }}
                onProjectDeletionClicked={() => onProjectDeletionClicked(project.name, project.uuid)}
              />
            )}
          </Grid>
        )}

        <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
          <Grid item xs={10} md={11}>
            <Typography
              variant="h2-bold"
              noWrap
              onClick={handleProjectClick}
              className={Styles.projectHeader}
              color="important"
              variantColor={2}
              style={{ width: "100% - 50px" }}
            >
              {project.name}
            </Typography>
          </Grid>

          {project.category === "workflow" &&
            isPermitted("Delete project", permissions.konan, flattenedKonanPermissions) && (
              <Grid item>
                <CardMenu
                  anchorEl={anchorEl}
                  setAnchorEl={(anchor: HTMLElement | null) => {
                    setAnchorEl(anchor)
                  }}
                  onProjectDeletionClicked={() => onProjectDeletionClicked(project.name, project.uuid)}
                />
              </Grid>
            )}
        </Grid>

        <Grid container item xs={12} spacing={1} alignItems="flex-start" className={Styles.projectCardAvatarContainer}>
          <Grid item sx={{ marginTop: "2px" }}>
            {/* SvgIcon is used to fix square avatars on safari */}
            <SvgIcon className={Styles.projectAvatar}>
              <KonanAvatar size={24} name={project.created_by} />
            </SvgIcon>
          </Grid>

          <Grid item xs={10} sx={{ width: "100%" }}>
            <Typography variant="label" noWrap>
              {project.created_by}
            </Typography>
            <Typography variant="label" noWrap>
              <Tooltip title={format(new Date(project.created_at), "dd/MM/yyyy, p")} placement="right">
                <Typography variant="span" noWrap className={Styles.projectDate}>
                  {moment(new Date(project.created_at)).fromNow()}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* predictions chart section */}
      <Grid container item direction="row" sx={{ height: "fit-content" }} className={Styles.projectBodyRoot}>
        <Grid item container direction="row" alignItems="flex-end">
          <Typography
            variant="label"
            tooltip="Data points sent for prediction to the project"
            className={Styles.projectSubtitles}
            tooltipIconSize={16}
            tooltipVerticalAlign="middle"
          >
            Predictions
          </Typography>
        </Grid>

        <Grid container className={Styles.projectSectionBox}>
          {isPredictionDatesLoading ? (
            <Grid container className={Styles.projectSectionBoxLoader}>
              <Typography variant="span">
                <Skeleton variant="rectangular" height={80} />
              </Typography>
            </Grid>
          ) : (
            <PredictionsContainer
              totalPredictionsCount={totalPredictionsCount}
              dailyPredictionDates={dailyPredictionDates}
              isPredictionDatesLoading={isPredictionDatesLoading}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  )
})

interface CardMenuInterface {
  anchorEl: HTMLElement | null
  setAnchorEl: (anchor: HTMLElement | null) => void
  onProjectDeletionClicked: () => void
}

const CardMenu = (props: Readonly<CardMenuInterface>): React.ReactElement => {
  const { anchorEl, setAnchorEl, onProjectDeletionClicked } = props
  return (
    <Grid item>
      <IconButton
        size="small"
        aria-label="settings"
        id="ProjectCard_settings"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
        className={Styles.projectHeaderIconBtn}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        menuMaxContent
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            onProjectDeletionClicked()
          }}
          id="delete_project"
        >
          <Typography className={Styles.projectDeleteButton} variant="label">
            Delete Project
          </Typography>
        </MenuItem>
      </Menu>
    </Grid>
  )
}
