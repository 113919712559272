import React from "react"

import { useParams } from "react-router-dom"

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import CloseIcon from "@mui/icons-material/Close"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import { Card, Grid, IconButton, Skeleton } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { SelectWithSearch } from "../../components/UI/SelectWithSearch"
import { getTheme } from "../../hooks/UseTheme"

import styles from "./ScorecardSet.module.scss"

// type for URL params
type ParamsType = {
  id: string
}

interface ScoreCardItem {
  uuid: string
  name: string
  //   hidden: boolean
}

interface ScoreCardNodeType {
  scorecard?: { name: string; uuid: string | undefined }
  isEdit?: boolean
  menuView?: boolean
  isLoading?: boolean
  ScoreCards?: Array<ScoreCardItem>
  handleRemove?: () => void
  onValueChange?: (scorecard: { name: string; uuid: string }) => void
}

/**
 * ScoreCardCard component
 * @param {string} uuid
 * @param {boolean} isEdit
 * @param {boolean} menuView
 * @param {boolean} isLoading
 * @param {Array<ScoreCardItem>} ScoreCards
 * @param {function} handleRemove
 * @param {function} onValueChange select value change
 * @returns {React.ReactElement}
 */
export function ScoreCardBlock(props: Readonly<ScoreCardNodeType>): React.ReactElement {
  const { isEdit, handleRemove, scorecard, menuView, onValueChange, isLoading, ScoreCards } = props
  const { id: projectId } = useParams<ParamsType>()

  const readMode = isEdit ?? null

  const theme = getTheme()

  return (
    <Card className={menuView ? styles.ScoreCardBlock_menuView : styles.ScoreCardBlock}>
      <Grid container wrap="nowrap">
        <Grid item>
          <Grid item className={styles.ScoreCardBlockType}>
            <AssignmentOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          flexGrow={1}
          flexDirection={"column"}
          paddingLeft={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="label" variantColor={2}>
                SCORECARD
              </Typography>
            </Grid>
            {readMode && (
              <Grid item justifySelf={"end"}>
                <IconButton style={{ marginTop: "-15px" }} size="small" onClick={() => handleRemove?.()}>
                  <CloseIcon fontSize="small" className={styles.remove} />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="flex-start"
            justifyContent={"space-between"}
            marginTop={0.5}
            wrap="nowrap"
          >
            <Grid item>
              {isLoading ? (
                <Skeleton height={"25px"} width="100px" />
              ) : isEdit || menuView ? (
                <SelectWithSearch
                  isDropDownDisabled={menuView || ScoreCards?.length === 0}
                  options={
                    ScoreCards?.map((ScoreCard) => {
                      return { label: ScoreCard.name, value: ScoreCard.uuid, disabled: ScoreCard.hidden }
                    }) || []
                  }
                  placeHolder={"Choose scorecard"}
                  initialValue={scorecard?.uuid?.startsWith("default-") ? "Choose scorecard" : scorecard?.name}
                  searchInputPlaceHolder="Search"
                  hideDescription
                  isOptionsLoading={false}
                  fullWidth
                  onSelectMenuItem={(item: { label: string; value: string }) =>
                    onValueChange?.({ uuid: item.value, name: item.label })
                  }
                />
              ) : (
                <Typography variant="h3-bold" noWrap>
                  {scorecard?.name}
                </Typography>
              )}
            </Grid>

            <Grid item display={"flex"} alignSelf={"center"} justifySelf={"end"} ml={1}>
              <Button
                variant="secondary"
                /*Workaround to use window.open in safari */
                onClick={() =>
                  setTimeout(() => {
                    window.open(`/projects/${projectId}/Scorecards`, "_blank")
                  })
                }
                size="small"
                disabled={menuView}
              >
                <LaunchOutlinedIcon
                  fontSize="medium"
                  sx={{ color: menuView ? theme.palette.gray.background[1] : theme.palette.grayscale.text[1] }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
