import React, { useEffect } from "react"

import { useLocation, useNavigate, useParams } from "react-router-dom"

import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined"
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined"
import { Grid } from "@mui/material"
import { Button, Tab, Tabs } from "@synapse-analytics/synapse-ui"

import { PythonIcon } from "../../assets/SvgIcons"
import { APIIntegration } from "./components/APIIntegration"
import { IntegrationLanguage } from "./components/IntegrationLanguage"
import { swaggerTemplate } from "./swaggerTemplate"

import styles from "./Integration.module.scss"

type ParamsType = {
  id: string
  tab: string
}

type TabInfo = {
  label: string
  value: string
  icon: React.ReactElement
}

/**
 * Integration component contains python SDK as well as multiple tabs with multiple programming languages
 * snippets to make it easier to integrate with Konan API
 * @returns {React.ReactElement}
 */
export function Integration(): React.ReactElement {
  const { id: projectId, tab } = useParams<ParamsType>()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [activeTab, setActiveTab] = React.useState<string>(tab?.toLowerCase() || "rest-api")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [apiSpec, setApiSpec] = React.useState<any>()

  // All valid tab types
  const tabsTypes = ["rest-api", "python"]

  // Array contains each tab info (label, value, icon)
  const tabsInfo: Array<TabInfo> = [
    {
      label: "REST API",
      value: "rest-api",
      icon: <ClassOutlinedIcon className="integration-icon" fontSize="small" />,
    },
    {
      label: "Python (SDK)",
      value: "python",
      icon: <PythonIcon fill={activeTab === "python" ? "#4989E2" : "#B8B9B9"} width="22" height="22" />,
    },
  ]

  const handleTabClick = (name: string): void => {
    if (name) {
      setActiveTab(name)
    }
    navigate(`${pathname.substring(0, pathname.lastIndexOf("/"))}/${name}`, {
      replace: true,
    })
  }

  const handleDownloadDocs = (): void => {
    if (apiSpec) {
      // Extract project title from response
      const title = `Konan - ${apiSpec?.info.title} REST API Documentation`

      // Turn API spec into string
      const stringifiedResponse = JSON.stringify(apiSpec)

      // Pass stringified API Spec to the swagger template
      const html = swaggerTemplate(title, stringifiedResponse)

      // Creating Anchor element to be downloaded
      const element = document.createElement("a")
      element.setAttribute("href", "data:html/text;charset=utf-8, " + encodeURIComponent(html))
      element.setAttribute("download", title + ".html")

      // Download the element / api doc
      document.body.appendChild(element)
      element.click()

      // removing element after execution from the dom
      document.body.removeChild(element)
    }
  }

  useEffect(() => {
    // push `/python` when no tab selected on the first render
    if (!tab) {
      navigate(`${pathname}/rest-api`, {
        replace: true,
      })
    }
    // replace param value with `/python` when invalid tab value inserted
    // and set the tab value to `python`
    else if (!tabsTypes.includes(tab)) {
      navigate(`${pathname.substring(0, pathname.lastIndexOf("/"))}/rest-api`, {
        replace: true,
      })
      setActiveTab("rest-api")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, pathname, tab])

  return (
    <Grid container direction="column" spacing={2}>
      {/* implementing tabs for different programming languages*/}
      <Grid item className={styles.tabsContainer}>
        <Tabs value={activeTab}>
          {/*Grouping Tabs */}
          {tabsInfo.map((tab) => (
            <Tab
              icon={() => tab.icon}
              key={tab.label}
              label={tab.label}
              onClick={() => handleTabClick(tab.value)}
              value={tab.value}
              selected={activeTab === tab.value}
              id={tab.value}
            />
          ))}
        </Tabs>

        {/* Download API docs button is only visible if activeTab = "rest-api" + docs are loaded */}
        {activeTab === "rest-api" && apiSpec && (
          <Button
            startIcon={<CloudDownloadOutlinedIcon fontSize="small" />}
            variant="primary"
            onClick={handleDownloadDocs}
            className={styles.downloadButton}
            id="download-api-button"
            size="regular"
          >
            DOWNLOAD API DOCS
          </Button>
        )}
      </Grid>

      {/* Navigating to REST-api tab when it's selected, otherwise,
        navigate to SDKintegration with the selected language sent as a prop*/}
      <Grid item container xs={12} className={styles.integrationContainer}>
        {activeTab !== "rest-api" ? (
          <IntegrationLanguage UUID={projectId as string} lang={activeTab} />
        ) : (
          <APIIntegration setApiSpec={setApiSpec} />
        )}
      </Grid>
    </Grid>
  )
}
