import React from "react"

import { useTheme } from "../../hooks/UseTheme"
import KBlackLogo from "./KBlackLogo.svg"
import KWhiteLogo from "./KWhiteLogo.svg"
import Logo from "./KonanLogo.svg"
import BlackLogo from "./KonanLogoBlack.svg"

type Props = {
  logoWidth?: number
  isDrawerCollapsed?: boolean
  isDrawerView?: boolean
}

export function KonanLogo(props: Readonly<Props>): React.ReactElement {
  const { logoWidth = 300, isDrawerCollapsed, isDrawerView = false } = props

  const [theme, _] = useTheme()

  const getLogo = (): string => {
    if ((localStorage.getItem("is_drawer_collapsed") === "true" || isDrawerCollapsed) && isDrawerView) {
      return theme === "dark" ? KWhiteLogo : KBlackLogo
    } else {
      return theme === "dark" ? Logo : BlackLogo
    }
  }

  return (
    <img
      src={getLogo()}
      alt="Logo"
      style={{
        width: logoWidth ?? 300,
        height: isDrawerView ? (isDrawerCollapsed ? 34 : 38) : "unset",
        transition: "opacity 225ms cubic-bezier(0.4, 0, 0.6, 1)",
      }}
    />
  )
}
