import React, { Fragment, useState } from "react"

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, Grid, IconButton, Skeleton } from "@mui/material"
import { Avatar, Menu, MenuItem, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { BorderedKonanAvatar } from "../../components/Avatar"
import { InfoBlock } from "../../components/containers/InfoBlock"
import { getTheme } from "../../hooks/UseTheme"
import { RoleCardProps } from "./Interfaces"

import styles from "./Roles.module.scss"

export function RoleCard(props: Readonly<RoleCardProps>): React.ReactElement {
  const { name, date, immutable, MenuOptions, users, onTitleClick } = props

  const theme = getTheme()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Card
      className="card-box-shadow"
      style={{ padding: "12px", backgroundColor: theme.palette.grayscale.background[3] }}
    >
      <Grid container direction="column" gap={0.75}>
        <Grid container item xs={12} justifyContent={"space-between"} wrap="nowrap" minHeight={"22px"}>
          <Grid item xs>
            <Typography variant="span" variantColor={2} noWrap>
              Added {moment(new Date(date)).format("MMM DD YYYY")}
            </Typography>
          </Grid>

          {!immutable && MenuOptions && MenuOptions.length > 0 && (
            <Grid item>
              <IconButton
                size="small"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                className={styles.headerButton}
                id={`${name}-role-menu-btn`}
              >
                <MoreHorizIcon fontSize="small" />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} menuMaxContent>
                {MenuOptions.map((option) => (
                  <MenuItem
                    onClick={() => {
                      option.onClick()
                      setAnchorEl(null)
                    }}
                    key={option.title}
                  >
                    <Typography variant="label" color={option.variant ?? "neutral"} variantColor={2}>
                      {option.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} alignItems={MenuOptions && MenuOptions.length > 0 ? "center" : "flex-end"}>
          <Typography
            variant="h3-bold"
            variantColor={onTitleClick ? 2 : 1}
            noWrap
            color={onTitleClick ? "important" : "neutral"}
            onClick={() => onTitleClick?.()}
            className={`${onTitleClick && styles.titleHover}`}
            textTransform="capitalize"
          >
            {name.toLocaleLowerCase()}
          </Typography>
        </Grid>

        <Grid item xs={12} display={"flex"} mt={0.5}>
          {users && users?.length > 0 ? (
            <Fragment>
              {users?.slice(0, 6).map((name, index) => (
                <Grid item display={"flex"} key={name} ml={index !== 0 ? -1 : 0}>
                  <BorderedKonanAvatar name={name} size={33} key={name} />
                </Grid>
              ))}
            </Fragment>
          ) : (
            <Grid item mt={0.5} mb={0.5} xs={12}>
              <InfoBlock alignText="center" text={"No users assigned"} bordered={false} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export const RoleCardLoader = (): React.ReactElement => {
  const theme = getTheme()

  return (
    <Card
      className="card-box-shadow"
      style={{ padding: "12px", backgroundColor: theme.palette.grayscale.background[3] }}
    >
      <Grid container direction="column" spacing={0.75}>
        <Grid container item xs={12}>
          <Skeleton animation="wave" width="100px" height="20%" />
        </Grid>

        <Grid item xs={12} display={"flex"} mt={1}>
          {["1", "2", "3"].map((item, index) => (
            <Grid item display={"flex"} key={item} ml={index !== 0 ? -1.5 : 0}>
              <Avatar src={"1"} isLoading isTextVisible={false} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}
