import { initReactI18next } from "react-i18next"

import i18n, { use } from "i18next"
import Backend from "i18next-http-backend"

use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export { i18n }
