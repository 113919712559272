import React, { Fragment } from "react"

import { Button, CircularProgress } from "@mui/material"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"

import styles from "./UploadFileBlock.module.scss"

type UploadFileBlockProps = {
  isUploading?: boolean
  isDeleting?: boolean
  fileName?: string
  handleCancel: () => void
  onDelete: () => void
  type?: "FilterNode" | "ruleset"
  editMode?: boolean
  createMode?: boolean
  isDisabled?: boolean
}

export function UploadFileBlock(props: Readonly<UploadFileBlockProps>): React.ReactElement {
  const {
    isDisabled,
    type = "ruleset",
    editMode,
    createMode,
    handleCancel,
    onDelete,
    isUploading,
    isDeleting,
    fileName,
  } = props
  return (
    <div className={styles.cardFieldUpload} style={{ marginTop: type === "FilterNode" ? "0px" : "2px" }}>
      {isUploading ? (
        <Fragment>
          <div className={styles.uploadFileProgress}>
            <CircularProgress size={15} style={{ marginRight: "6px" }} color="info" />{" "}
            <Typography variant="a">Uploading..</Typography>
          </div>
          <Button onClick={handleCancel} className={styles.ghostButton} size="small">
            Cancel
          </Button>
        </Fragment>
      ) : (
        <div className={styles.fileName}>
          <Tooltip width="100%" title={fileName} placement="top">
            <div style={{ display: "flex", justifySelf: "flex-start", alignItems: "center" }}>
              <Typography variant="a" noWrap>
                {props.fileName}
              </Typography>
            </div>
          </Tooltip>

          {(editMode || createMode || type === "FilterNode") && (
            <Button disabled={isDisabled} onClick={onDelete} className={styles.ghostButton} size="small">
              {isDeleting ? <CircularProgress size={15} style={{ marginRight: "6px" }} color="info" /> : "Delete"}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
