import React from "react"

import { Box, Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

type Props = {
  icon?: React.ReactElement
  title?: string
  subtitle?: string
  action?: React.ReactElement
}

export function InfoContainer(props: Props): React.ReactElement {
  const { icon, title, subtitle, action } = props

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" textAlign="center" marginTop={2}>
      {icon && <Grid item>{icon}</Grid>}
      <Grid item>
        <Typography align="center" variant="a">
          {subtitle ? <strong>{title}</strong> : title}
        </Typography>
      </Grid>

      {subtitle && (
        <Grid item>
          <Typography align="center" variant="span">
            {subtitle}
          </Typography>
        </Grid>
      )}

      {action && (
        <React.Fragment>
          <Box mt={2} />
          <Grid item>{action}</Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}
