import React, { useState } from "react"

import { useMutation } from "react-query"

import * as Yup from "yup"
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Button, InputText, NotificationUtils, Select, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"

import { KonanAPI } from "../../services/KonanAPI"
import { CreateIssueRequest } from "../../types/custom/core"

type Props = {
  open: boolean
  onClose: () => void
}

export function SupportDialog(props: Readonly<Props>): React.ReactElement {
  const { open, onClose } = props
  const theme = useTheme()

  const [issueType, setIssueType] = useState<string>("Issue")

  const validationSchema = Yup.object({
    summary: Yup.string()
      .required("Summary is required")
      .min(1, "Summary can not be empty")
      .max(500, "Summary is too long - Must be 500 characters or less"),
    description: Yup.string()
      .max(500, "Description is too long - Must be 500 characters or less")
      .required("Description is required"),
  })

  const createIssueMutation = useMutation<AxiosResponse<string>, AxiosError, CreateIssueRequest>(KonanAPI.createIssue, {
    onSuccess: () =>
      NotificationUtils.toast("Ticket successfully created.", {
        snackBarVariant: "positive",
      }),
    onError: () =>
      NotificationUtils.toast("We're having trouble submitting your feedback. Please try again at another time.", {
        snackBarVariant: "negative",
      }),
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIssueType(event.target.value)
  }

  const formik = useFormik({
    initialValues: {
      summary: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await createIssueMutation
        .mutateAsync({
          summary: values.summary,
          issueType: issueType === "Issue" ? "Bug" : "Task",
          description: values.description,
        })
        .then(() => {
          onClose()
          resetForm({})
        })
    },
  })

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && createIssueMutation.isLoading) {
          return undefined
        } else {
          onClose()
        }
      }}
      fullWidth
      fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
    >
      <DialogTitle id="form-dialog-title" className="dialog-header-base">
        <Typography variant="h2-bold">Contact Support</Typography>
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        <Select
          label=" Open ticket"
          value={issueType}
          handleChange={handleChange}
          options={["Issue", "Suggest An Idea"]}
        />

        <Box mt={1} />

        <InputText
          id="summary"
          label="Summary"
          required
          fullWidth
          value={formik.values.summary}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          error={formik.touched.summary && Boolean(formik.errors.summary) && formik.errors.summary}
          placeholder="KONAN is awesome..."
        />
        {/* TODO:: refactor when adding multiline option to InputText */}
        <Grid item marginTop={2}>
          <Typography variant="span" gutterBottom>
            Description
          </Typography>
          <TextField
            id="description"
            type="text"
            multiline
            fullWidth
            required
            placeholder={
              issueType === "suggest"
                ? "Please provide a description of the feature or idea you'd like to suggest."
                : "Please provide a detailed description of the issue you're facing and how to reproduce it."
            }
            value={formik.values.description}
            size="medium"
            rows={5}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            className={"multiline"}
          />
        </Grid>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Button onClick={() => onClose()} variant={"secondary"} disabled={createIssueMutation.isLoading}>
          Cancel
        </Button>
        <Button
          onClick={formik.submitForm}
          variant={"primary"}
          disabled={formik.isSubmitting || !(formik.dirty && formik.isValid)}
        >
          {createIssueMutation.isLoading ? <CircularProgress size={20} color="inherit" /> : "Send"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
