import { useCallback, useContext, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined"
import BatchPredictionOutlinedIcon from "@mui/icons-material/BatchPredictionOutlined"
import BoltIcon from "@mui/icons-material/Bolt"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined"
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined"
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined"
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined"
import MediationOutlinedIcon from "@mui/icons-material/MediationOutlined"
import ModelTrainingIcon from "@mui/icons-material/ModelTraining"
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { FormControl, Grid, MenuItem, Select as MuiSelect, SelectChangeEvent } from "@mui/material"
import { Button, DateRangePicker, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { formatDistance } from "date-fns"

import { CopyUUIDButton } from "../components/CopyUUIDButton"
import { ModelType } from "../components/ModelType"
import { ModelSwitchDialog } from "../components/dialogs/BaseSimpleDialog"
import { WorkflowConfigurationDialog } from "../components/dialogs/WorkflowConfigurationDialog"
import { ConfigurationDialog } from "../features/Models"
import { ProjectWorkflowCreationDialog } from "../features/projects/ProjectWorkflowCreationFlow"
import { KonanAPI } from "../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../store/CurrentProjectAndModelContext"
import { RootState } from "../store/ReduxStore"
import { Route } from "../types/custom/projects"
import { Model } from "../types/generated/api/Model"
import { isPermitted } from "../utils/PermissionsHelpers"
import { Auth } from "../utils/auth"
import { getModelByUUID } from "../utils/modelDetailsHelpers"
import { useDateQuery } from "./useDateQuery"

/**
 * Custom hook, which contains all routes data, used with useHeaderData.tsx to obtain
 * the current route data needed for the page header
 */
export function useRoutesHeaderData(): Record<string, Route> {
  const DATE_ROUTES = ["/ekyc", "/requests", "/overview", "decisions"]

  const permissions = Auth.getPermissions()
  const queryClient = useQueryClient()
  const location = useLocation()
  const navigate = useNavigate()

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery(29, DATE_ROUTES)
  const { currentProject, currentModel, setCurrentModel } = useContext(CurrentProjectAndModelContext)

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions?.flattenedKonanPermissions)

  const [configurationDialogOpen, setConfigurationDialogOpen] = useState<boolean>(false)
  const [isWorkflowProjectDialogOpen, setIsWorkflowProjectDialogOpen] = useState<boolean>(false)
  const [isSwitchDialogOpen, setIsSwitchDialogOpen] = useState<boolean>(false)

  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError>(
    ["models", currentProject?.uuid],
    () => KonanAPI.fetchModels(currentProject?.uuid as string),
    {
      enabled: !!currentModel && !!currentProject?.uuid && window.location.pathname.includes("/models"),
    },
  )

  const promoteModelMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    {
      projectUUID: string | undefined
      modelUUID: string
      newState: string
    }
  >(KonanAPI.switchModelState, {
    onSuccess: (res) => {
      NotificationUtils.toast(res?.data?.detail ?? "Model Switched Successfully", {
        snackBarVariant: "positive",
      })

      setIsSwitchDialogOpen(false)
    },
  })

  async function promoteModel(newModelState: string): Promise<void> {
    try {
      await promoteModelMutation.mutateAsync({
        projectUUID: currentProject?.uuid,
        modelUUID: memoizedGetModelByUUID(currentModel)?.uuid as string,
        newState: newModelState,
      })

      // Invalidate react-query queries
      await queryClient.invalidateQueries("models")
      await queryClient.invalidateQueries(["model", memoizedGetModelByUUID(currentModel)?.uuid])
      await queryClient.invalidateQueries(["overviewModels", currentProject?.uuid])
      await queryClient.invalidateQueries(["modelHistory", currentProject?.uuid])
    } catch (e) {
      NotificationUtils.toast(
        "Failed to switch Model " + memoizedGetModelByUUID(currentModel)?.name + " to " + newModelState,
        { snackBarVariant: "negative" },
      )
    }
  }

  const memoizedGetModelByUUID = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (currentModel: any) => getModelByUUID(currentModel, models?.data),
    [models],
  )

  const routes = {
    kcr: {
      title: "Models Registry",
      description: "Host your model images on Konan Container Registry.",
      icon: <DnsOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
    },

    models: {
      title: "Models",
      description: "Train pre-built models or create your own. Monitor, retrain or challenge your models.",
      icon: <BatchPredictionOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        isPermitted("Add project", permissions.konan, flattenedKonanPermissions) && (
          <Button
            onClick={() => navigate(`${location?.pathname}/new-model`)}
            variant="primary"
            startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
            key={currentProject?.uuid}
            size="regular"
          >
            New Model
          </Button>
        ),
      ],
    },

    workflows: {
      title: "Workflows",
      description: "Create, simulate and monitor a decision engine based on your business logic.",
      icon: <MediationOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        isPermitted("Add project", permissions.konan, flattenedKonanPermissions) && (
          <Button
            onClick={() => setIsWorkflowProjectDialogOpen(true)}
            variant="primary"
            startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
            id="sub_header_btn_1"
            key={currentProject?.uuid}
            size="regular"
          >
            New Workflow
          </Button>
        ),
      ],
      dialog: (
        <ProjectWorkflowCreationDialog
          isOpen={isWorkflowProjectDialogOpen}
          onClose={function (): void {
            setIsWorkflowProjectDialogOpen(false)
          }}
        />
      ),
    },

    settings: {
      title: "Settings",
      description: "Manage members, roles, groups and their permissions.",
      icon: <SettingsOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
    },

    decisions: {
      title: "Live Decisions",
      description: currentProject ? `Decisions being made by your live ${currentProject?.category}.` : "",
      icon: <BoltIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
          key={currentProject?.uuid}
        />,
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
    },

    dataFiles: {
      title: "Data Files",
      icon: <FolderOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
    },

    workflow: {
      title: currentProject?.name,
      icon: <MediationOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      description: currentProject?.description,
      actionButtons: [
        <Button
          key={currentProject?.name}
          variant="secondary"
          onClick={() => setConfigurationDialogOpen(true)}
          startIcon={<SettingsOutlinedIcon fontSize="small" />}
          size="regular"
        >
          Project Configuration
        </Button>,
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
      dialog: (
        <WorkflowConfigurationDialog
          isOpen={configurationDialogOpen}
          onClose={() => setConfigurationDialogOpen(false)}
        />
      ),
    },

    ekyc: {
      title: "Electronic Know Your Customer",
      description: "View insights on customers.",
      icon: <AssignmentIndOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
          key={currentProject?.uuid}
        />,
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
    },

    integration: {
      title: "Integration",
      icon: <IntegrationInstructionsOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
      description: "Send requests to the Konan API through our python SDK or your framework of choice.",
    },

    overview: {
      title: "Overview",
      description: "Compare your live, challenger, and idle models.",
      icon: <DashboardOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
          key={currentProject?.uuid}
        />,
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
    },

    requests: {
      title: "Live Monitoring",
      description: "API requests and statistics sent to your live model.",
      icon: <OnlinePredictionIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
          key={currentProject?.uuid}
        />,
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
    },

    modelsList: {
      title: "Models",
      description: "View, configure and switch between live, challenger, and idle.",
      icon: <BatchPredictionOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        <Button
          key={currentProject?.name}
          variant="secondary"
          onClick={() => setConfigurationDialogOpen(true)}
          startIcon={<SettingsOutlinedIcon fontSize="small" />}
          size="regular"
        >
          Configuration
        </Button>,
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
      dialog: (
        <ConfigurationDialog isOpen={configurationDialogOpen} onClose={() => setConfigurationDialogOpen(false)} />
      ),
    },

    modelTraining: {
      title: "Model Training",
      description: "Upload new training data. Retrain your models and view training reports.",
      icon: <ModelTrainingIcon style={{ color: "var(--blue-text-2)" }} />,
      actionButtons: [
        <CopyUUIDButton
          key={currentProject?.uuid}
          disabled={!currentProject?.uuid}
          textToCopy={currentProject?.uuid as string}
        />,
      ],
    },

    classification: {
      title: (
        <FormControl variant="standard">
          <MuiSelect
            variant="standard"
            labelId="modelUUID"
            id="selectModelUUID"
            className="select-model-header"
            value={currentModel}
            displayEmpty
            disableUnderline
            onChange={(e: SelectChangeEvent) => setCurrentModel(e.target.value)}
            renderValue={() => <div>{memoizedGetModelByUUID(currentModel)?.name}</div>}
          >
            {models?.data?.map((model) => {
              return (
                <MenuItem key={model.uuid} value={model.uuid}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="h3-regular" variantColor={1}>
                        {model.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ModelType modelState={model.state} size="small" />
                    </Grid>
                  </Grid>
                </MenuItem>
              )
            })}
          </MuiSelect>
        </FormControl>
      ),
      icon: <BatchPredictionOutlinedIcon style={{ color: "var(--blue-text-2)" }} />,
      dialog: isSwitchDialogOpen && isPermitted("Switch model state", permissions.konan, flattenedKonanPermissions) && (
        <ModelSwitchDialog
          open
          modelState={memoizedGetModelByUUID(currentModel)?.state}
          name="Switch Model Type"
          mode={"model-switch"}
          onAccept={(newModelState) => promoteModel(newModelState)}
          isLoading={promoteModelMutation.isLoading}
          onClose={() => {
            setIsSwitchDialogOpen(false)
            //  To prevent flickering of alerts when closing the dialog
            setTimeout(() => promoteModelMutation.reset(), 300)
          }}
        />
      ),
      tag: (
        <ModelType
          modelState={currentModel ? memoizedGetModelByUUID(currentModel)?.state : models?.data[0]?.state}
          margin={"0px 0px 4px 0px"}
          size="small"
        />
      ),
      createdAt: memoizedGetModelByUUID(currentModel)?.created_at && (
        <Typography variant="span">
          {formatDistance(new Date(memoizedGetModelByUUID(currentModel)?.created_at as string), new Date(), {
            addSuffix: true,
          })}
        </Typography>
      ),
      actionButtons: [
        isPermitted("Switch model state", permissions.konan, flattenedKonanPermissions) && (
          <Grid item key={currentProject?.uuid}>
            <Button
              tooltip={
                memoizedGetModelByUUID(currentModel)?.state === "live" || !currentModel
                  ? "Can't switch a live model"
                  : undefined
              }
              onClick={() => setIsSwitchDialogOpen(true)}
              disabled={memoizedGetModelByUUID(currentModel)?.state === "live" || !currentModel}
              variant="secondary"
              startIcon={<CachedOutlinedIcon fontSize="small" />}
              size="regular"
            >
              Switch
            </Button>
          </Grid>
        ),
        <CopyUUIDButton
          isModel
          key={currentProject?.uuid}
          disabled={!currentModel}
          textToCopy={currentModel as string}
        />,
      ],
    },
  }

  return routes
}
