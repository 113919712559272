/* generated using openapi-typescript-codegen -- do not edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { RulesetRuleCreateRequest } from "./RulesetRuleCreateRequest"

/**
 * Serializer for ruleset model
 */
export type RuleSetCreateRequest = {
  name: string
  /**
   * * `GENERIC` - GENERIC
   * * `DECISION` - DECISION
   */
  type?: RuleSetCreateRequest.type
  rules?: Array<RulesetRuleCreateRequest>
}
export namespace RuleSetCreateRequest {
  /**
   * * `GENERIC` - GENERIC
   * * `DECISION` - DECISION
   */
  export enum type {
    GENERIC = "GENERIC",
    DECISION = "DECISION",
  }
}
