import React, { Fragment, useContext, useState } from "react"

import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { Card, Grid, SvgIcon, useMediaQuery, useTheme } from "@mui/material"
import { Button, Skeleton, Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import moment from "moment"

import { KonanAvatar } from "../../../../../components/Avatar"
import { ModelType } from "../../../../../components/ModelType"
import { KonanLogo } from "../../../../../components/icons/KonanLogo"
import { KonanAPI } from "../../../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../../../store/CurrentProjectAndModelContext"
import { Model } from "../../../../../types/generated/api/Model"
import { RetrainingJob } from "../../../../../types/generated/api/RetrainingJob"
import { TrainingDataUpload } from "../../../../../types/generated/api/TrainingDataUpload"
import { uniqueJobName } from "../../../../../utils/deploymentDetailsHelpers"
import { KonanTimeHelper } from "../../../../../utils/genericHelpers"
import { RetrainingLogsView } from "./RetrainingLogs"
import { TrainingReportDialog } from "./TrainingReportDialog"

import styles from "../TrainingJobs.module.scss"

type ParamsType = {
  id: string
}

type MetricType = {
  metric_name: string
  metric_value: number
}

type Props = {
  parentModel: string
  startedAt: string
  firstPredictionDate: string
  lastPredictionDate: string
  createdModelName: string
  endedAt: string
  trainingDataUUID: string
  resultingModel: string
  isLoading?: boolean
  status?: RetrainingJob.status
  createdAt: string
  createdModelStatus?: string
  evalMetrics: {
    test: MetricType[]
    train: MetricType[]
  }
  uuid: string
  points: number | undefined
  projectType?: string
  /** Used to guarantee that avatars are unique per user. */
  userName?: string
  duration?: string
  dataPoints?: string
  trainingOrRetraining: string
}

function EvaluationDataCardLoader(): React.ReactElement {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="flex-start"
      className={styles.dataCard}
    >
      <Skeleton height={10} width={"100%"} />
      <Skeleton height={10} width={"100%"} />
    </Grid>
  )
}
type EvalProps = {
  title: string
  trainValue: number
  testValue: number
}
function EvaluationDataCard(props: Readonly<EvalProps>): React.ReactElement {
  const { title, trainValue, testValue } = props

  const metricValue = `${trainValue?.toFixed(2)} / ${testValue?.toFixed(2)}`
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className={styles.dataCard}
    >
      {/* Title */}
      <Typography variant="p" noWrap className={styles.trainingText}>
        {title}
      </Typography>

      {/* Value */}
      <Typography variant="p" noWrap className={styles.trainingText}>
        {typeof trainValue === "number" && typeof testValue === "number" ? metricValue : trainValue / testValue}
      </Typography>
    </Grid>
  )
}

export function SuccessfulTrainingJobCard(props: Readonly<Props>): React.ReactElement {
  const {
    isLoading,
    status,
    startedAt,
    createdAt,
    createdModelName,
    createdModelStatus,
    parentModel,
    userName,
    firstPredictionDate,
    lastPredictionDate,
    dataPoints,
    trainingDataUUID,
    endedAt,
    projectType,
    uuid,
    duration,
    trainingOrRetraining,
    points,
    evalMetrics,
    resultingModel,
  } = props
  const { id: projectId } = useParams<ParamsType>()
  const theme = useTheme()
  const navigate = useNavigate()

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const { setCurrentModel } = useContext(CurrentProjectAndModelContext)

  const [openRetrainingLogs, setOpenRetrainingLogs] = useState(false)
  const [trainingReportDialogOpen, setTrainingReportDialogOpen] = useState(false)

  const konanTime = new KonanTimeHelper()

  const { isLoading: isParentModelDataLoading, data: parentModelData } = useQuery<AxiosResponse<Model>, AxiosError>(
    ["model", parentModel],
    () => KonanAPI.fetchModel(parentModel),
    { enabled: !!parentModel },
  )

  const { data: trainingData, isLoading: isTrainingDataLoading } = useQuery<TrainingDataUpload, AxiosError>(
    ["training-data", projectId, trainingDataUUID],
    () => KonanAPI.fetchSingleTrainingData(projectId as string, trainingDataUUID),
    { enabled: projectId != null && trainingDataUUID != null },
  )

  // fetch threshold metrics
  const { data: thresholdMetricsData, isLoading: isThresholdMetricsLoading } = useQuery<AxiosResponse>(
    ["threshold-metrics", resultingModel],
    () => KonanAPI.fetchThresholdWithMetrics({ projectUUID: projectId as string, modelUUID: resultingModel }),
    {
      enabled: !!projectId && !!resultingModel && trainingOrRetraining === "training-automl",
    },
  )

  const handleLogsClose = (): void => {
    setOpenRetrainingLogs(false)
  }

  const isTrainingType = trainingOrRetraining === "training-automl" || trainingOrRetraining === "training-prebuilt"

  return (
    <Fragment>
      {openRetrainingLogs && (
        <RetrainingLogsView
          open={openRetrainingLogs}
          onClose={handleLogsClose}
          projectUUID={projectId as string}
          retrainingUUID={uuid}
          jobStatus={status}
          trainingJobType={trainingOrRetraining}
          trainingName={uniqueJobName(uuid, createdAt, trainingOrRetraining)}
          isAutoMl={trainingOrRetraining === "training-automl"}
        />
      )}

      {trainingReportDialogOpen && (
        <TrainingReportDialog
          TrainingDataName={trainingData?.name ? trainingData?.name : ""}
          projectUUID={projectId as string}
          onClose={() => setTrainingReportDialogOpen(false)}
          open
          uuid={uuid}
          createdModelStatus={createdModelStatus}
          createdModelName={createdModelName}
          resultingModel={resultingModel}
          dataSplit={dataPoints}
          points={points}
          evalMetrics={evalMetrics}
          isAutoMl={trainingOrRetraining === "training-automl"}
        />
      )}

      <Card className="card-box-shadow">
        <Grid container direction="column" className={styles.headerContainer}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} container direction="row">
              <Grid item>
                <Tag variant="positive" size="small">
                  Success
                </Tag>
              </Grid>
              <Grid item direction="column" justifyContent="center">
                <Typography variant="span" className={styles.durationText} noWrap>
                  {!isLoading && duration ? `Lasted ${konanTime.timeParser(duration)}` : <br />}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3-bold" noWrap className={styles.headerName}>
              {uniqueJobName(uuid, createdAt, trainingOrRetraining)}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between" className={styles.avatarSpacing}>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              item
              sm={isTrainingType ? 5 : 7}
              xs={isTrainingType ? 6 : 7}
              spacing={1}
              alignItems="flex-start"
              className={styles.avatarContainer}
            >
              <Grid item sx={{ marginTop: "2px" }}>
                <SvgIcon className={styles.avatar}>
                  <KonanAvatar size={24} name={userName} />
                </SvgIcon>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="label" noWrap>
                  {userName}
                </Typography>

                <Typography variant="label" noWrap>
                  <Tooltip
                    title={<Typography variant="span">{format(new Date(createdAt), "dd/MM/yyyy, p")} </Typography>}
                    placement="right"
                  >
                    <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                      {moment(new Date(createdAt)).fromNow()}
                    </Typography>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              sm={isTrainingType ? 6 : 4}
              xs={isTrainingType ? 6 : 5}
              container
              className={styles.buttonsContainer}
            >
              {isTrainingType ? (
                <Button onClick={() => setTrainingReportDialogOpen(true)} size="small" variant="secondary">
                  {fullScreen ? "Report" : "Training Report"}
                </Button>
              ) : (
                <Grid item direction="row">
                  <Button onClick={() => setOpenRetrainingLogs(true)} size="small" variant="secondary">
                    {fullScreen ? "Logs" : "View Logs"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={trainingOrRetraining !== "training-automl" ? styles.bodyContainer : styles.bodyContainerAutoml}
        >
          <Grid container item direction="row" alignItems="flex-end">
            <Grid container item direction="row">
              <Grid item xs={12} sm={9}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                  <Typography
                    variant="label"
                    className={styles.labelColor}
                    tooltip={
                      isTrainingType ? "Trained Model is developed by Konan" : "Original version of the retrained model"
                    }
                    tooltipVerticalAlign="middle"
                    tooltipIconSize={16}
                  >
                    Source Model
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container className={styles.sourceModelSection}>
              {isTrainingType ? (
                <Fragment>
                  <Grid item marginTop={2} marginBottom={1}>
                    <Typography variant="span">{projectType}</Typography>
                  </Grid>
                  <Grid item>
                    <KonanLogo logoWidth={115} />
                  </Grid>
                </Fragment>
              ) : (
                <Grid item container direction="column" className={styles.sourceModel} sx={{ width: "100%" }}>
                  {!isParentModelDataLoading && parentModelData?.data && (
                    <Fragment>
                      <Grid item>
                        <ModelType modelState={parentModelData?.data?.state} size="small" />
                      </Grid>
                      <Grid
                        item
                        onClick={() => {
                          navigate(`/projects/${projectId}/models/requests`)
                          setCurrentModel(parentModelData?.data?.uuid)
                        }}
                        sx={{ width: "100%" }}
                      >
                        <Typography variant="p" noWrap className={styles.linkText} color="important" variantColor={2}>
                          {parentModelData?.data?.name}
                        </Typography>
                      </Grid>

                      <Grid className={styles.dateContainer} item>
                        <Typography variant="label" noWrap>
                          <Tooltip
                            title={
                              <Typography variant="span">
                                {format(
                                  new Date(parentModelData?.data?.created_at ? parentModelData?.data?.created_at : ""),
                                  "dd/MM/yyyy, p",
                                )}
                              </Typography>
                            }
                            placement="right"
                          >
                            <Typography variant="span" className={styles.date}>
                              {moment(
                                new Date(parentModelData?.data?.created_at ? parentModelData?.data?.created_at : ""),
                              ).fromNow()}
                            </Typography>
                          </Tooltip>
                        </Typography>
                      </Grid>
                    </Fragment>
                  )}
                  {isParentModelDataLoading && (
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexWrap="nowrap"
                    >
                      <Grid container direction="column" className={"generatedModelCard"}>
                        <Grid item>
                          <Skeleton variant="rectangular" width={"40%"} height={10} />
                        </Grid>
                        <Grid item>
                          <Skeleton variant="rectangular" width={"70%"} height={10} />
                        </Grid>
                        <Grid item style={{ marginBottom: "5px" }}>
                          <Skeleton variant="rectangular" width={"40%"} height={10} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item direction="row" className={styles.twoBoxesContainer}>
            <div className={styles.testWidthLeft}>
              <Typography
                variant="label"
                tooltip={`Start datetime of the ${isTrainingType ? "training" : "retraining"} job`}
                tooltipVerticalAlign="middle"
                tooltipIconSize={16}
                className={styles.labelColor}
              >
                Started
              </Typography>
              <Grid item className={styles.idbox} sx={{ width: "100px" }}>
                <Typography variant="p" noWrap className={styles.textIdBox}>
                  {format(new Date(startedAt), "dd/MM/yyyy h:mm:ss")}
                </Typography>
              </Grid>
            </div>
            <div className={styles.testWidthRight}>
              <Typography
                variant="label"
                tooltip={`End datetime of the ${isTrainingType ? "training" : "retraining"} job`}
                tooltipVerticalAlign="middle"
                tooltipIconSize={16}
                className={styles.labelColor}
              >
                Ended
              </Typography>
              <Grid container className={styles.idbox}>
                <Typography noWrap variant="p" className={styles.textIdBox}>
                  {format(new Date(endedAt), "dd/MM/yyyy h:mm:ss")}
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Grid
            container
            item
            direction="row"
            className={trainingOrRetraining !== "training-automl" ? styles.twoBoxesContainer : ""}
          >
            <div className={styles.testWidthLeft}>
              {trainingOrRetraining === "retraining" ? (
                <Fragment>
                  <Typography
                    variant="label"
                    tooltip="Data date range that the job used to retrain the model"
                    tooltipVerticalAlign="middle"
                    tooltipIconSize={16}
                    className={styles.labelColor}
                  >
                    Serving Data
                  </Typography>
                  <Grid container className={styles.idbox}>
                    <Typography variant="p" noWrap className={styles.textIdBox}>
                      {format(new Date(firstPredictionDate), "dd/MM/yy")} -{" "}
                      {format(new Date(lastPredictionDate), "dd/MM/yy")}
                    </Typography>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography
                    variant="label"
                    tooltip="Training data that the job used to train the model"
                    tooltipVerticalAlign="middle"
                    tooltipIconSize={16}
                    className={styles.labelColor}
                  >
                    Training Data
                  </Typography>
                  <Grid container className={styles.idbox}>
                    {isTrainingDataLoading ? (
                      <Grid item xs={12}>
                        <Typography variant="p">
                          <Skeleton variant="rectangular" width={"100%"} height={10} />
                        </Typography>
                      </Grid>
                    ) : (
                      <Typography variant="p" noWrap className={styles.textIdBox}>
                        {!isTrainingDataLoading && trainingData?.name
                          ? trainingData?.name
                          : !isTrainingDataLoading && trainingData?.uuid && !trainingData?.name
                            ? "data.csv"
                            : !isTrainingDataLoading && "N/A"}
                      </Typography>
                    )}
                  </Grid>
                </Fragment>
              )}
            </div>
            <div className={styles.testWidthRight}>
              <Typography
                variant="label"
                tooltip="Training and testing data split percentage"
                tooltipVerticalAlign="middle"
                tooltipIconSize={16}
                className={styles.labelColor}
              >
                Data Split
              </Typography>
              <Grid container className={styles.idbox}>
                {isLoading || isThresholdMetricsLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={20} />
                ) : (status === RetrainingJob.status.SUCCEEDED && dataPoints) ||
                  !!thresholdMetricsData?.data?.metrics?.split?.train ? (
                  <Typography variant="p" noWrap className={styles.textIdBox}>
                    {trainingOrRetraining === "training-automl"
                      ? `${thresholdMetricsData?.data?.metrics?.split?.train * 100}% / ${thresholdMetricsData?.data?.metrics?.split?.test * 100}%`
                      : dataPoints}
                  </Typography>
                ) : (
                  <Typography variant="p" noWrap>
                    N/A
                  </Typography>
                )}
              </Grid>
            </div>
          </Grid>
          {trainingOrRetraining !== "training-automl" && (
            <Fragment>
              <Grid container item direction="row" style={{ paddingLeft: 0 }}>
                <Grid container item direction="row">
                  <Grid item xs={12} sm={9}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Typography
                        variant="label"
                        display="block"
                        tooltip={
                          isTrainingType ? "Performance of the trained model" : "Performance of the retrained model"
                        }
                        tooltipVerticalAlign="text-bottom"
                        tooltipIconSize={16}
                        className={styles.labelColor}
                        noWrap
                      >
                        Evaluation Metrics ( Train / Test )
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!evalMetrics && !isLoading ? (
                <Grid>
                  <Grid
                    className={styles.addTrainingSection}
                    container
                    direction="row"
                    justifyContent="space-between"
                    item
                    xs={12}
                    marginBottom={4}
                  >
                    <Typography variant="span" align="center" className={styles.emptyPredictions}>
                      There are no evaluation tests in this model
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="row" spacing={1} className={styles.horizontalScrolling}>
                  {isLoading &&
                    [1, 2, 3].map((_, index: number) => (
                      <Grid item xs={4} key={index}>
                        <EvaluationDataCardLoader />
                      </Grid>
                    ))}

                  {!isLoading &&
                    evalMetrics &&
                    Boolean(evalMetrics?.train?.length) &&
                    evalMetrics?.train?.map((ev: { metric_name: string; metric_value: number }, idx) => (
                      <Grid
                        item
                        xs={evalMetrics?.train?.length === 1 ? 12 : evalMetrics?.train?.length === 2 ? 6 : 4}
                        className={styles.evalCard}
                        key={`${ev.metric_name}-${ev.metric_value}`}
                      >
                        <EvaluationDataCard
                          title={ev?.metric_name}
                          trainValue={ev?.metric_value}
                          testValue={evalMetrics?.test[idx]?.metric_value}
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Fragment>
          )}

          <Grid
            container
            item
            direction="row"
            alignItems="flex-end"
            className={evalMetrics?.train?.length || isLoading ? styles.spacingTopMetrics : styles.spacingTop}
          >
            <Grid container item direction="row">
              <Grid item xs={12} sm={9}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                  <Typography
                    variant="label"
                    tooltip={
                      isTrainingType
                        ? " New model version generated from the training job"
                        : " New model version generated from the retraining job"
                    }
                    tooltipVerticalAlign="middle"
                    tooltipIconSize={16}
                    className={styles.labelColor}
                  >
                    Generated Model
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={styles.sourceModelSectionGenerated} marginBottom={1}>
            <Grid item container direction="column" className={styles.sourceModel}>
              {createdModelStatus && (
                <Fragment>
                  <Grid item>
                    <ModelType modelState={createdModelStatus} size="small" />
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      navigate(`/projects/${projectId}/models/requests`)
                      setCurrentModel(resultingModel)
                    }}
                    sx={{ width: "100%" }}
                  >
                    <Typography variant="p" noWrap className={styles.linkText} color="important" variantColor={2}>
                      {createdModelName}
                    </Typography>
                  </Grid>

                  <Grid item className={styles.dateContainer}>
                    <Typography variant="label" noWrap>
                      <Tooltip
                        title={<Typography variant="span">{format(new Date(endedAt), "dd/MM/yyyy, p")}</Typography>}
                        placement="right"
                      >
                        <Typography variant="span" className={styles.date} gutterBottom>
                          {moment(new Date(endedAt)).fromNow()}
                        </Typography>
                      </Tooltip>
                    </Typography>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
