import React, { Fragment, memo, useCallback, useContext, useMemo } from "react"

import { useQuery } from "react-query"
import { Handle, Position, useReactFlow, useStoreApi } from "reactflow"

import { Box, FormControl, Grid, MenuItem, Select } from "@mui/material"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { getTheme } from "../../../hooks/UseTheme"
import { KonanAPI } from "../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../store/CurrentProjectAndModelContext"
import { UpdateGraphParams } from "../../../types/custom/workflows"
import { Deployment } from "../../../types/generated/api/Deployment"
import { PaginatedProgramList } from "../../../types/generated/api/PaginatedProgramList"
import { PaginatedRuleSetListList } from "../../../types/generated/api/PaginatedRuleSetListList"
import { PaginatedScoreCardSetListList } from "../../../types/generated/api/PaginatedScoreCardSetListList"
import { PaginatedScriptGroupListList } from "../../../types/generated/api/PaginatedScriptGroupListList"
import { PaginatedTagListListList } from "../../../types/generated/api/PaginatedTagListListList"
import { RuleSetCreateRequest } from "../../../types/generated/api/RuleSetCreateRequest"
import { isEnvVariableTruthy } from "../../../utils/genericHelpers"
import { isNodeInFilter, isNodeInLoop, preDefinedRoutesForNodes } from "../../../utils/workflowHelpers"
import { CalculatorNode } from "./CalculatorNode"
import { FilterNode } from "./FilterNode"
import { LabeledNode } from "./LabeledNode"
import { LoopStartNode } from "./LoopStartNode"
import { SelectionNode } from "./SelectionNode"

import styles from "./CustomCards.module.scss"

type AddBlockType = {
  data: {
    parentId: string
    updateGraph: (data: UpdateGraphParams) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema?: any
    disabled?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: any
  }
  id: string
}

type MenuItemsWithTypesProps = {
  Item: React.ReactElement
  tooltipText: string
  tooltipCondition: boolean
  onSelectingBlock: () => void
  isNodeDisabled?: boolean
}

export function MenuItemWithTooltip(props: Readonly<MenuItemsWithTypesProps>): React.ReactElement {
  const { Item, tooltipCondition, tooltipText, onSelectingBlock, isNodeDisabled = false } = props
  return (
    <div>
      {tooltipCondition ? (
        <Tooltip title={tooltipText} placement="top">
          <span style={{ pointerEvents: "none" }}>
            <MenuItem onClick={() => onSelectingBlock()} disabled={true}>
              {Item}
            </MenuItem>
          </span>
        </Tooltip>
      ) : (
        <MenuItem onClick={() => onSelectingBlock()} disabled={isNodeDisabled}>
          {Item}
        </MenuItem>
      )}
    </div>
  )
}

/**
 * Add block node, responsible for adding nodes when creating/editing a workflow.
 * @param {void} updateGraph updateGraph function
 * @param {string} id add block node id
 * @param {string} parentId parent id for add block node
 * @returns {React.ReactElement}
 */

export const AddBlockNode = memo(function AddBlockNode(props: Readonly<AddBlockType>): React.ReactElement {
  // updateGraph function that handles new node/s addition extracted from the props
  const updateGraph = props.data?.updateGraph

  const { currentProject } = useContext(CurrentProjectAndModelContext)
  // node id
  const { id } = props

  // instance of our state management that share our nodes and edges across all component
  const store = useStoreApi()
  // extracting getNodes getter from our store instance
  const { getNodes } = store.getState()

  // this is react-flow helper function to delete passed nodes as well as all the connected edges from the passed nodes
  const { getEdges } = useReactFlow()

  const nodes = getNodes()

  const theme = getTheme()

  const currentIndex = nodes?.findIndex((node: AddBlockType) => node?.id === id)

  // fetch rulesets
  const { data: rulesets, isLoading: isRulesetsLoading } = useQuery<
    AxiosResponse<PaginatedRuleSetListList>,
    AxiosError
  >(["rulesets", currentProject], () => KonanAPI.fetchRulesets(currentProject.uuid as string), {
    enabled: !!currentProject,
  })

  // fetch programs
  const { isLoading: isProgramsLoading, data: programs } = useQuery<AxiosResponse<PaginatedProgramList>, AxiosError>(
    ["programs", currentProject],
    () => KonanAPI.fetchPrograms(currentProject.uuid as string),
    {
      enabled: !!currentProject,
    },
  )

  // Fetching projects,
  const { isLoading: isProjectsLoading, data: projects } = useQuery<AxiosResponse<Array<Deployment>>, AxiosError>(
    ["projects"],
    () => KonanAPI.fetchProjects(),
  )

  // fetch scorecardsets
  const { isLoading: isScorecardsetsLoading, data: scorecardsets } = useQuery<
    AxiosResponse<PaginatedScoreCardSetListList>,
    AxiosError
  >(["scorecardsets", currentProject], () => KonanAPI.fetchScorecardsets(currentProject?.uuid as string), {
    enabled: !!currentProject,
  })

  // fetch scripts
  const { data: scripts, isLoading: isScriptsLoading } = useQuery<
    AxiosResponse<PaginatedScriptGroupListList>,
    AxiosError
  >(["scripts", currentProject], () => KonanAPI.fetchScripts(currentProject?.uuid as string), {
    enabled: !!currentProject,
  })

  // fetch taglists
  const { isLoading: isTaglistsLoading, data: taglists } = useQuery<
    AxiosResponse<PaginatedTagListListList>,
    AxiosError
  >(["taglists", currentProject], () => KonanAPI.fetchTagLists(currentProject?.uuid as string), {
    enabled: !!currentProject,
  })

  // extracting and memoizing the generic typed rulesets
  const genericRulesetsMemo = useMemo(() => {
    const generics = rulesets?.data?.results?.filter((ruleset) => ruleset?.type === RuleSetCreateRequest.type.GENERIC)
    return generics
  }, [rulesets?.data?.results])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getNodeData = (nodeType: string): any => {
    switch (nodeType) {
      case "RulesetNode":
      case "DecisionNode":
        return rulesets?.data
      case "ProgramNode":
        return programs?.data
      case "ScorecardsetNode":
        return scorecardsets?.data
      case "ProjectNode":
        return projects?.data
      case "ScriptNode":
        return scripts?.data
      default:
        return {}
    }
  }

  const currentPid = useMemo(() => {
    const currentNode = nodes?.find((node) => node?.id === id)
    return currentNode?.data?.parentId
  }, [id, nodes])

  /**
   * upon selecting a block from add block menu, we send the selected node to
   * the preDefinedRoutesForNodes to return a list of nodes that need to be added to our nodes state
   * for example: selecting ruleset node --> 2 nodes (ruleset node, add block node for the uncovered route)
   * selecting filter node --> 3 nodes (filter node, 2 add block nodes for each true and false routes)
   */
  const onSelectingBlock = useCallback(
    (type: string): void => {
      const parentId = currentIndex === 1 ? "1" : (currentPid ?? "")
      const isSelectedNodeInFilter = isNodeInFilter(parentId, nodes)
      const isSelectedNodeInLoop = isNodeInLoop(parentId, nodes)

      const currentEdge = getEdges()?.find((edge) => edge.target === id)

      const newNodes = preDefinedRoutesForNodes(
        {
          nodeType: type,
          newNodeId: id,
          parentId,
          updateGraph,
          formik: props?.data?.formik,
          schema: props?.data?.schema,
          filterBranch: ["true", "false"].includes(currentEdge?.data?.text)
            ? currentEdge?.data?.text
            : (isSelectedNodeInFilter.filterBranch as string),
          isNodeInFilter: isSelectedNodeInFilter.isNodeInFilter,
          filterId: isSelectedNodeInFilter.filterId,
          ...isSelectedNodeInLoop,
        },
        getNodeData(type),
      )
      if (props?.data?.updateGraph) {
        props?.data?.updateGraph({ arrayOfNewNodes: newNodes, isNodeBetween: false })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPid],
  )

  return (
    <Grid container item xs={12}>
      <Handle
        style={{ opacity: 0 }}
        className="custom-handle"
        type="target"
        position={Position.Top}
        id="b"
        isConnectable={true}
      />
      <FormControl variant="standard" size="small">
        <Select
          variant="standard"
          labelId="modelUUID"
          id="selectModelUUID"
          value={"default" || "none"}
          disabled={props?.data?.disabled}
          disableUnderline
          displayEmpty
          className={`sort nodrag`}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: theme.palette.grayscale.background[1],
                border: theme.palette.neutral.background.enabled,
              },
            },
            classes: {
              paper: styles.menu,
            },
            variant: "selectedMenu",
          }}
        >
          <MenuItem key="none" value="default" disabled style={{ display: "none" }}>
            <Typography variant="label" style={{ paddingTop: "6px" }}>
              Add Block
            </Typography>
          </MenuItem>

          {/* Ruleset node */}
          <MenuItemWithTooltip
            Item={<SelectionNode menuView={true} data={{ nodeType: "RulesetNode" }} />}
            onSelectingBlock={() => onSelectingBlock("RulesetNode")}
            tooltipCondition={!isRulesetsLoading && genericRulesetsMemo?.length === 0}
            tooltipText={"This project doesn't have any rulesets, therefore you can't add a ruleset node in a workflow"}
            isNodeDisabled={isRulesetsLoading}
          />

          <Box mt={1} />
          <MenuItem onClick={() => onSelectingBlock("DecisionNode")}>
            <SelectionNode menuView={true} data={{ nodeType: "DecisionNode" }} />
          </MenuItem>

          <Box mt={1} />
          {/* Taglist node */}
          <MenuItemWithTooltip
            Item={<SelectionNode menuView={true} data={{ nodeType: "TaglistNode" }} />}
            onSelectingBlock={() => onSelectingBlock("TaglistNode")}
            tooltipCondition={!isTaglistsLoading && taglists?.data?.results?.length === 0}
            tooltipText={"This project doesn't have any taglists, therefore you can't add a taglist node in a workflow"}
            isNodeDisabled={isTaglistsLoading}
          />

          <Box mt={1} />
          {/* Scorecardset node */}
          <MenuItemWithTooltip
            Item={<SelectionNode menuView={true} data={{ nodeType: "ScorecardsetNode" }} />}
            onSelectingBlock={() => onSelectingBlock("ScorecardsetNode")}
            tooltipCondition={!isScorecardsetsLoading && scorecardsets?.data?.results?.length === 0}
            tooltipText={
              "This project doesn't have any scorecardsets, therefore you can't add a scorecardset node in a workflow"
            }
            isNodeDisabled={isScorecardsetsLoading}
          />
          <Box mt={1} />

          {/* Program node */}
          <MenuItemWithTooltip
            Item={<SelectionNode menuView={true} data={{ nodeType: "ProgramNode" }} />}
            onSelectingBlock={() => onSelectingBlock("ProgramNode")}
            tooltipCondition={!isProgramsLoading && programs?.data?.results?.length === 0}
            tooltipText={"This project doesn't have any programs, therefore you can't add a program node in a workflow"}
            isNodeDisabled={isProgramsLoading}
          />

          <Box mt={1} />
          <MenuItem onClick={() => onSelectingBlock("FilterNode")}>
            <FilterNode menuView={true} />
          </MenuItem>

          <Box mt={1} />
          <MenuItem onClick={() => onSelectingBlock("CalculatorNode")}>
            <CalculatorNode menuView={true} />
          </MenuItem>

          {isEnvVariableTruthy(window.__RUNTIME_CONFIG__.WORKFLOW_LOOPS_ENABLED) && (
            <Fragment>
              <Box mt={1} />
              <MenuItem onClick={() => onSelectingBlock("LoopStartNode")}>
                <LoopStartNode menuView={true} />
              </MenuItem>
            </Fragment>
          )}

          <Box mt={1} />
          {/* Script node */}
          <MenuItemWithTooltip
            Item={<SelectionNode menuView={true} data={{ nodeType: "ScriptNode" }} />}
            onSelectingBlock={() => onSelectingBlock("ScriptNode")}
            tooltipCondition={!isScriptsLoading && scripts?.data?.results?.length === 0}
            tooltipText={"This project doesn't have any scripts, therefore you can't add a script node in a workflow"}
            isNodeDisabled={isScriptsLoading}
          />

          <Box mt={1} />
          {/* Project node */}
          <MenuItemWithTooltip
            Item={<SelectionNode menuView={true} data={{ nodeType: "ProjectNode" }} />}
            onSelectingBlock={() => onSelectingBlock("ProjectNode")}
            tooltipCondition={!isProjectsLoading && projects?.data?.length === 0}
            tooltipText={
              "This organization doesn't have any other projects, therefore you can't add a project node in a workflow"
            }
            isNodeDisabled={isProjectsLoading}
          />

          <Box mt={1} />
          <MenuItem onClick={() => onSelectingBlock("EndResultNode")}>
            <LabeledNode data={{ nodeType: "EndResultNode" }} menuView={true} />
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )
})
