import React from "react"

import { useQuery } from "react-query"

import { Warning } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress, Dialog, DialogContent, Grid, useMediaQuery, useTheme as useMuiTheme } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { Tag, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { InfoContainer } from "../../../../../components/InfoContainer"
import { KonanLogViewer } from "../../../../../components/KonanLogViewer"
import { getTheme } from "../../../../../hooks/UseTheme"
import { KonanAPI } from "../../../../../services/KonanAPI"
import { AutoMLJobLogs } from "../../../../../types/generated/api/AutoMLJobLogs"
import { RetrainingJobLogs } from "../../../../../types/generated/api/RetrainingJobLogs"

type Props = {
  open: boolean
  projectUUID: string
  retrainingUUID: string
  isAutoMl?: boolean
  trainingName?: string
  trainingJobType?: string
  onClose: () => void
  jobStatus?: string
}

export function RetrainingLogsView(props: Readonly<Props>): React.ReactElement {
  const { projectUUID, retrainingUUID, open, onClose, jobStatus, trainingName, trainingJobType, isAutoMl } = props
  const muiTheme = useMuiTheme()
  const theme = getTheme()

  const fullScreen = useMediaQuery(muiTheme.breakpoints.down("md"))

  const { isLoading: isRetrainingLogsLoading, data = { logs: [] } } = useQuery<RetrainingJobLogs, AxiosError>(
    ["retraining-logs", projectUUID, retrainingUUID],
    () => KonanAPI.fetchRetrainingModelLogs(projectUUID, retrainingUUID),
    {
      enabled: !!projectUUID && open && trainingJobType === "retraining" && !isAutoMl,
      refetchInterval: 10000,
    },
  )

  const { isLoading: isTrainingLogsLoading, data: trainingLogsData = { logs: [] } } = useQuery<
    RetrainingJobLogs,
    AxiosError
  >(
    ["retraining-logs", projectUUID, retrainingUUID],
    () => KonanAPI.fetchTrainingModelLogs(projectUUID, retrainingUUID),
    {
      enabled: !!projectUUID && open && trainingJobType === "training" && !isAutoMl,
      refetchInterval: 10000,
    },
  )

  const { isLoading: isTrainingAutomlLogsLoading, data: trainingAutomlLogs } = useQuery<AutoMLJobLogs, AxiosError>(
    ["training-automl-logs", projectUUID, retrainingUUID],
    () => KonanAPI.fetchAutoMLTrainingJobLogs(projectUUID, retrainingUUID),
    {
      enabled: !!projectUUID && open && isAutoMl,
      refetchInterval: !isAutoMl ? false : 10000,
    },
  )

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={fullScreen}>
      <DialogContent className="dialog-content-base">
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item>
            <Tag
              size="small"
              variant={
                jobStatus === "CANCELLED"
                  ? "warning"
                  : jobStatus === "PENDING" || jobStatus === "RUNNING"
                    ? "default"
                    : jobStatus === "FAILED" || jobStatus === "TIMEOUT"
                      ? "negative"
                      : "positive"
              }
            >
              {jobStatus?.toLowerCase()}
            </Tag>
          </Grid>

          <Grid item>
            <IconButton size="small" onClick={() => onClose()} className={"close-icon-button"}>
              <CloseIcon style={{ color: theme.palette.gray.background[1] }} />
            </IconButton>
          </Grid>
        </Grid>

        <Typography variant="h1-bold">{trainingName}</Typography>

        <Grid style={{ margin: "20px 0px" }}>
          <Typography variant="h2-bold">Training Logs</Typography>
        </Grid>

        {trainingJobType === "training" ? (
          isTrainingLogsLoading ? (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              className={"logs-grid-height"}
              marginBottom={2}
            >
              <InfoContainer
                icon={<CircularProgress className={"logs-circular-progress"} />}
                title="Loading trainings Logs..."
              />
            </Grid>
          ) : data.logs !== null && data.logs?.length ? (
            <Grid container item direction="row" marginBottom={2}>
              <KonanLogViewer
                data={trainingLogsData.logs as string[]}
                isLoading={isTrainingLogsLoading}
                projectUUID={projectUUID}
              />
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              className={"logs-grid-height"}
              marginBottom={2}
            >
              <InfoContainer
                icon={<Warning fontSize="large" className={"warning-icon-base"} />}
                title="No training/retraining logs to display."
              />
            </Grid>
          )
        ) : isAutoMl ? (
          isTrainingAutomlLogsLoading ? (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              className={"logs-grid-height"}
              marginBottom={2}
            >
              <InfoContainer
                icon={<CircularProgress className={"logs-circular-progress"} />}
                title="Loading retraining Logs..."
              />
            </Grid>
          ) : trainingAutomlLogs && trainingAutomlLogs.logs !== null && trainingAutomlLogs.logs?.length ? (
            <Grid container item direction="row" marginBottom={2}>
              <KonanLogViewer
                data={trainingAutomlLogs && (trainingAutomlLogs.logs as string[])}
                isLoading={isTrainingAutomlLogsLoading}
                projectUUID={projectUUID}
              />
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              className={"logs-grid-height"}
              marginBottom={2}
            >
              <InfoContainer
                icon={<Warning fontSize="large" className={"warning-icon-base"} />}
                title="No training/retraining logs to display."
              />
            </Grid>
          )
        ) : isRetrainingLogsLoading ? (
          <Grid container justifyContent="center" alignContent="center" className={"logs-grid-height"} marginBottom={2}>
            <InfoContainer
              icon={<CircularProgress className={"logs-circular-progress"} />}
              title="Loading retraining Logs..."
            />
          </Grid>
        ) : data.logs !== null && data.logs?.length ? (
          <Grid container item direction="row" marginBottom={2}>
            <KonanLogViewer
              data={data.logs as string[]}
              isLoading={isRetrainingLogsLoading}
              projectUUID={projectUUID}
            />
          </Grid>
        ) : (
          <Grid container justifyContent="center" alignContent="center" className={"logs-grid-height"} marginBottom={2}>
            <InfoContainer
              icon={<Warning fontSize="large" className={"warning-icon-base"} />}
              title="No training/retraining logs to display."
            />
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}
