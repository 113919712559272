import React from "react"

import { Card, Grid } from "@mui/material"
import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import styles from "./Projects.module.scss"

/**
 * Loader card for the project card
 * @return  {<ProjectCardLoader />}
 */
export function ProjectCardLoader(): React.ReactElement {
  return (
    <Card className={"card-box-shadow"}>
      <Grid container direction="column" className={styles.projectCardHeaderRoot}>
        {/* Tag and title loaders  */}
        <Grid container item xs={12}>
          <Grid item xs={3} sx={{ marginTop: "4px" }}>
            <Typography variant="p" noWrap>
              <Skeleton variant="rectangular" height={15} width={"80%"} />
            </Typography>
          </Grid>

          <Grid container direction="column" spacing={1}>
            <Grid item xs={6} className={styles.name}>
              <Typography variant="p" noWrap>
                <Skeleton variant="rectangular" height={15} width={"60%"} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Avatar loaders */}
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center" className={styles.projectCardAvatarContainer}>
            <Grid item sx={{ marginTop: "10px" }}>
              <Skeleton variant="circular" width={32} height={32} />
            </Grid>

            <Grid direction="column" container item style={{ marginTop: "15px" }}>
              <Grid item xs={6} sx={{ marginBottom: "10px" }}>
                <Typography variant="p" noWrap>
                  <Skeleton variant="rectangular" height={10} width={"80%"} />
                </Typography>
              </Grid>

              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <Typography variant="span" noWrap>
                  <Skeleton variant="rectangular" height={10} width={"30%"} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={styles.projectBodyRoot} container>
        <Grid container item direction="row">
          <Grid item xs={12}>
            {/* Prediction section loader */}
            <Grid item container direction="row" justifyContent="flex-start">
              <Typography variant="label" className={styles.projectSubtitles}>
                Predictions
              </Typography>
            </Grid>

            <Grid container className={styles.projectSectionBox} sx={{ padding: "4px" }}>
              <Typography variant="h2-bold" noWrap className={styles.projectPredictionsLoader}>
                <Skeleton variant="rectangular" height={80} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
