/* generated using openapi-typescript-codegen -- do not edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { schema } from "./schema"

export type Deployment = {
  readonly created_by: string
  readonly organization: string
  description?: string
  readonly schema: schema[]
  readonly computed_feature_names: Array<Record<string, any>> | null
  readonly uuid: string
  name: string
  /**
   * default deployment type is generic
   *
   * * `generic` - Generic
   * * `classification` - Classification
   * * `credit_scoring` - Credit Scoring
   */
  type?: Deployment.type
  /**
   * default deployment category is model
   *
   * * `model` - Model
   * * `workflow` - Workflow
   */
  category?: Deployment.category
  readonly created_at: string
  is_demo?: boolean
}
export namespace Deployment {
  /**
   * default deployment type is generic
   *
   * * `generic` - Generic
   * * `classification` - Classification
   * * `credit_scoring` - Credit Scoring
   */
  export enum type {
    GENERIC = "generic",
    CLASSIFICATION = "classification",
    CREDIT_SCORING = "credit_scoring",
  }
  /**
   * default deployment category is model
   *
   * * `model` - Model
   * * `workflow` - Workflow
   */
  export enum category {
    MODEL = "model",
    WORKFLOW = "workflow",
  }
}
