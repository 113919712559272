import React, { useEffect, useState } from "react"

import { AddCircleOutline } from "@mui/icons-material"
import Grid from "@mui/material/Grid"
import { Button, InputText, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"

import { ClassificationConfiguration } from "../../../types/custom/projects"
import { convertResponseToTargets, deepJsonify } from "../../../utils/modelDetailsHelpers"
import { ConfigurationSlider } from "./ConfigurationSlider"

import styles from "../ModelConfigurations.module.scss"

interface Props {
  formik: FormikProps<{
    dataType: string
    target: string
    lowerLimit: number
    upperLimit: number
    step: number
    reDataType: string
  }>
  responseJSON: Record<string, string>
  modelUUID: string
  errorMessage?: string
}

// Disabling rule since this will component will be refactored soon
// eslint-disable-next-line react-refresh/only-export-components
export let handleSubmit: () => ClassificationConfiguration

/**
 * Configuration Form
 * @return {React.ReactElement}
 */
export function ConfigurationForm(props: Readonly<Props>): React.ReactElement {
  const { responseJSON, formik, modelUUID, errorMessage } = props

  const [sliders, setSliders] = useState([{ label: "", range: [0, 50] }])
  const [uncovered, setUncovered] = useState([[51, 100]])

  const requestValues = deepJsonify(responseJSON || {})

  const convertedTargets = convertResponseToTargets(responseJSON)
  const targets = convertedTargets.map((target) => target.join("."))

  const sliderIncrement = (value: number[], index: number): void => {
    const tempSliders = [...sliders]

    // checks if:
    // 1. not the last in list
    // 2. value of the last tip of the slider is higher than the upper-limit
    // 3. value of the upper-limit slider is higher than lower-limit of the next slider
    if (
      tempSliders[index + 1] &&
      value[1] >= tempSliders[index + 1].range[0] &&
      tempSliders[index].range[1] >= tempSliders[index + 1].range[0]
    ) {
      // Calculating new slider values
      for (let i = index + 1; i < sliders.length; i++) {
        // slider difference between its limits
        const offset = tempSliders[i].range[1] - tempSliders[i].range[0]
        const upper = tempSliders[i - 1].range[1] + formik.values.step
        const lower = tempSliders[i - 1].range[1] + offset + formik.values.step

        // ensuring values are always within boundary range
        tempSliders[i].range = [
          upper >= formik.values.lowerLimit ? upper : formik.values.lowerLimit,
          lower <= formik.values.upperLimit ? lower : formik.values.upperLimit,
        ]
      }
    }

    setSliders(tempSliders)
  }

  const sliderDecrement = (value: number[], index: number): void => {
    const tempSliders = [...sliders]

    // checks if:
    // 1. not the first in list
    // 2. value of the first tip of the slider is lower than the lower limit
    // 3. value of the lower-limit slider is lower than upper-limit of the prev slider
    if (
      tempSliders[index - 1] &&
      value[0] <= tempSliders[index - 1].range[1] &&
      tempSliders[index].range[0] <= tempSliders[index - 1].range[1]
    ) {
      // Calculating new slider values
      for (let i = index - 1; i >= 0; i--) {
        // slider difference between its limits
        const offset = tempSliders[i].range[1] - tempSliders[i].range[0]
        const upper = tempSliders[i + 1].range[0] - (offset + 1)
        const lower = tempSliders[i + 1].range[0] - formik.values.step

        tempSliders[i].range = [
          upper >= formik.values.lowerLimit ? upper : formik.values.lowerLimit,
          lower <= formik.values.upperLimit ? lower : formik.values.upperLimit,
        ]
      }
    }

    setSliders(tempSliders)
  }

  const handleRemoveLabel = (index: number): void => {
    const tempSliders = [...sliders]
    tempSliders.splice(index, 1)
    setSliders(tempSliders)
  }

  handleSubmit = (): ClassificationConfiguration => {
    // maps to the format needed by the backend
    const mappedLabels = sliders.map((slider) => {
      return {
        type: "label",
        label: slider.label,
        lower_bound: slider.range[0],
        upper_bound: slider.range[1],
      }
    })

    const targetIndex = targets.indexOf(formik.values.target)

    return {
      model: modelUUID,
      type: formik.values.dataType,
      target_key_path: convertedTargets[targetIndex] ?? [formik.values.target],
      settings:
        formik.values.dataType === "range"
          ? [
              {
                type: "boundary",
                lower_bound: formik.values.lowerLimit,
                upper_bound: formik.values.upperLimit,
              },
              ...mappedLabels,
            ]
          : undefined,
    }
  }

  // updating uncovered sliders on any slider's change
  // TODO:: refactor to fire on change committed
  useEffect(() => {
    // Setting uncovered sliders
    const uncovered = []
    const tempSliders = [
      { range: [formik.values.lowerLimit, formik.values.lowerLimit] },
      ...sliders,
      { range: [formik.values.upperLimit, formik.values.upperLimit] },
    ]

    // Count of how many decimal places in the step
    // eg: 1.15 => 2 => 2 decimal places (.15)
    // eg: 1.156 => 3 => 3 decimal places (.156)
    const decimalPlacesCount = formik.values.step.toString().split(".")[1]?.length || 0

    for (let i = 1; i < tempSliders.length; i++) {
      if (tempSliders[i].range[0] - tempSliders[i - 1].range[1] > formik.values.step)
        uncovered.push([
          i === 1
            ? formik.values.lowerLimit.toFixed(decimalPlacesCount)
            : (tempSliders[i - 1].range[1] + formik.values.step).toFixed(decimalPlacesCount),
          i === tempSliders.length - 1
            ? formik.values.upperLimit.toFixed(decimalPlacesCount)
            : (tempSliders[i].range[0] - formik.values.step).toFixed(decimalPlacesCount),
        ])
    }

    setUncovered(uncovered)
  }, [formik.values.lowerLimit, formik.values.step, formik.values.upperLimit, sliders])

  return (
    <Grid container direction="column" spacing={2}>
      {errorMessage && (
        <Grid item>
          <Snackbar variant="negative" fullWidth description={errorMessage} />
        </Grid>
      )}

      {formik.values.dataType !== formik.values.reDataType && (
        <Grid item>
          <Snackbar
            variant="negative"
            fullWidth
            description="Data type chosen doesn't match the recommended type detected based on the value type. Please change the data type."
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="h3-bold">Configure Target</Typography>
      </Grid>

      <Grid item>
        <InputText
          id="target"
          label="Choose Target"
          placeholder="Key"
          value={formik.values.target}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          handleChange={(e: any) => {
            const targetKey = targets.indexOf(e.target.value)
            let dataType: string
            if (typeof requestValues[targetKey] === "number") {
              dataType = "range"
            } else {
              dataType = "label"
            }
            formik.setFieldValue("dataType", dataType)
            formik.setFieldValue("reDataType", dataType)
            formik.setFieldValue("target", e.target.value)
          }}
          handleBlur={formik.handleBlur}
          error={formik.touched.target && Boolean(formik.errors.target) && formik.errors.target}
          fullWidth
          required
          options={responseJSON ? targets : undefined}
        />
      </Grid>

      <Grid container item spacing={1} justifyContent="space-between">
        {formik.values.target && (
          <Grid container item xs={6} lg={4} direction="column">
            <Grid item>
              <Typography variant="label" gutterBottom>
                Target Type
              </Typography>
            </Grid>

            <Grid container item spacing={1}>
              <Grid item xs={6}>
                <Button
                  variant="secondary"
                  size="regular"
                  onClick={() => formik.setFieldValue("dataType", "label")}
                  style={{
                    backgroundColor: formik.values.dataType === "label" ? `var(--neutral-background-active)` : "",
                  }}
                  fullWidth
                >
                  Label
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="secondary"
                  onClick={() => formik.setFieldValue("dataType", "range")}
                  style={{
                    backgroundColor: formik.values.dataType === "range" ? `var(--neutral-background-active)` : "",
                  }}
                  fullWidth
                  size="regular"
                >
                  Range
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {formik.values.dataType === "range" && (
          <Grid container item xs={6} lg={8} spacing={1} alignItems="flex-end" alignContent="flex-end">
            <Grid item xs={4}>
              <InputText
                id="lowerLimit"
                label="Lower Limit"
                placeholder="Lower Limit"
                value={formik.values.lowerLimit as unknown as string}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                handleChange={(e: any) =>
                  e.target.value
                    ? formik.setFieldValue("lowerLimit", parseFloat(e.target.value))
                    : formik.setFieldValue("lowerLimit", 0)
                }
                handleBlur={formik.handleBlur}
                error={formik.touched.lowerLimit && Boolean(formik.errors.lowerLimit) && formik.errors.lowerLimit}
                required
                fullWidth
                type="number"
              />
            </Grid>

            <Grid item xs={4}>
              <InputText
                id="upperLimit"
                label="Upper Limit"
                placeholder="upper Limit"
                value={formik.values.upperLimit as unknown as string}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                handleChange={(e: any) =>
                  e.target.value
                    ? formik.setFieldValue("upperLimit", parseFloat(e.target.value))
                    : formik.setFieldValue("upperLimit", formik.values.lowerLimit + 1)
                }
                handleBlur={formik.handleBlur}
                error={formik.touched.upperLimit && Boolean(formik.errors.upperLimit) && formik.touched.upperLimit}
                required
                fullWidth
                type="number"
              />
            </Grid>

            <Grid item xs={4}>
              <InputText
                id="step"
                label="Step"
                placeholder="1"
                value={formik.values.step as unknown as string}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                handleChange={(e: any) =>
                  e.target.value < 0
                    ? formik.setFieldValue("step", 1)
                    : e.target.value
                      ? formik.setFieldValue("step", parseFloat(e.target.value))
                      : formik.setFieldValue("step", 1)
                }
                handleBlur={formik.handleBlur}
                error={formik.touched.upperLimit && Boolean(formik.errors.upperLimit) && formik.touched.upperLimit}
                required
                fullWidth
                type="number"
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item>
        {formik.values.dataType === "range" && (
          <Grid container item spacing={2} direction="column">
            {sliders.map((item, index) => {
              return (
                <Grid item key={index}>
                  <ConfigurationSlider
                    label={item.label}
                    min={formik.values.lowerLimit}
                    max={formik.values.upperLimit}
                    range={[item.range]}
                    onDelete={() => {
                      handleRemoveLabel(index)
                    }}
                    isDeletable={index <= 0}
                    onSliderChange={(value) => {
                      const tempSliders = [...sliders]
                      tempSliders[index].range = value
                      setSliders(tempSliders)
                    }}
                    onLabelChange={(value) => {
                      const tempSliders = [...sliders]
                      tempSliders[index].label = value
                      setSliders(tempSliders)
                    }}
                    onChangeCommitted={(value: number[]) => {
                      sliderIncrement(value, index)
                      sliderDecrement(value, index)
                    }}
                    step={formik.values.step ?? 1}
                  />
                </Grid>
              )
            })}

            <Grid item>
              <Button
                fullWidth
                onClick={() => {
                  setSliders([
                    ...sliders,
                    {
                      label: ``,
                      range: [sliders[sliders.length - 1].range[1] + 1, sliders[sliders.length - 1].range[1] + 11],
                    },
                  ])
                }}
              >
                <AddCircleOutline style={{ color: "var(--gray-background-1)", marginRight: "8px" }} />
                <Typography variant="h3-bold">Add label</Typography>
              </Button>
            </Grid>

            <Grid item>
              {uncovered.length ? (
                <ConfigurationSlider
                  label="Uncovered"
                  min={formik.values.lowerLimit}
                  max={formik.values.upperLimit}
                  range={uncovered}
                  uncovered
                />
              ) : (
                <Grid container item justifyContent="center" alignItems="center" className={styles.sliderBox}>
                  <Typography variant="p">There arent any uncovered ranges</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
