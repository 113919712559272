import React from "react"

import { Link } from "react-router-dom"

import MenuIcon from "@mui/icons-material/Menu"
import AppBar from "@mui/material/AppBar"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"

import { KonanLogo } from "../../../components/icons/KonanLogo"
import { getTheme } from "../../../hooks/UseTheme"

import styles from "../Dashboard.module.scss"

type Props = {
  handleDrawerToggle: () => void
}

export function DashboardAppBar(props: Readonly<Props>): React.ReactElement {
  const { handleDrawerToggle } = props
  const theme = getTheme()

  return (
    <AppBar position="fixed" className={styles.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={styles.menuButton}
          size="large"
        >
          <MenuIcon style={{ color: theme.palette.grayscale.text[2] }} />
        </IconButton>
        <div>
          <Button to="/projects" component={Link}>
            <KonanLogo logoWidth={120} />
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  )
}
