import { ColumnAction, ColumnState } from "../types/custom/tables"

export const tableConfigReducer = (state: ColumnState, action: ColumnAction): ColumnState => {
  switch (action.type) {
    case "SET_INITIAL_STATE":
      return {
        ...action.payload,
      }

    case "UPDATE_COLUMN_ORDER":
      const existingColumns = action.payload.newOrder.filter((col) => state.columnsOrder.includes(col))
      const newColumns = action.payload.newOrder.filter((col) => !state.columnsOrder.includes(col))
      return {
        ...state,
        columnsOrder: [...existingColumns, ...newColumns],
      }

    case "UPDATE_VISIBILITY":
      return {
        ...state,
        columnsVisibility: {
          ...state.columnsVisibility,
          ...action.payload.newVisibility,
        },
      }

    default:
      return state
  }
}
