import React, { Fragment } from "react"

import { CircularProgress, Grid } from "@mui/material"
import { Snackbar, Typography } from "@synapse-analytics/synapse-ui"

import { KonanLogViewer } from "../../../components/KonanLogViewer"

import Styles from "../Projects.module.scss"

type Props = {
  creationLogs?: string | null
  creationErrors?: Record<string, string>[]
  isLoading: boolean
}

/**
 * Project creation overview, first screen in the creation flow
 * @param  {string} creationLogs model creation logs
 * @param  {Record<string, string>} creationErrors errors if creation fails
 * @param  {boolean} isLoading loading mode
 * @return  {React.ReactElement}
 */
export function PostCreationDialog(props: Props): React.ReactElement {
  const { creationLogs, creationErrors, isLoading } = props

  return (
    <Fragment>
      {isLoading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
          spacing={2}
          className={Styles.creationDialogRoot}
        >
          {/* isLoading mode */}
          <Grid container item justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>

          <Grid container item justifyContent="center" alignContent="center">
            <Typography variant="h3-bold">Creating model...</Typography>
          </Grid>

          <Grid container item justifyContent="center" alignContent="center">
            <Typography variant="h3-regular" textTransform="initial">
              Please leave this window open
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            {/* creation status */}
            {creationErrors && creationErrors.length > 0 ? (
              <Snackbar variant="negative" fullWidth description="Model Failed" />
            ) : (
              <Snackbar variant="positive" fullWidth description="Model Successful" />
            )}
          </Grid>

          <Grid item>
            <Typography variant="h3-bold" gutterBottom>
              Model Logs
            </Typography>

            {creationLogs ? (
              <KonanLogViewer data={creationLogs.trim().split("\n")} isLoading={isLoading} />
            ) : (
              <Snackbar variant="important" fullWidth description="No logs to display!" />
            )}
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}
