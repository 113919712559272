import React, { useContext, useEffect } from "react"

import { useInfiniteQuery, useQuery } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useLocation, useParams } from "react-router-dom"

import { CircularProgress, Grid } from "@mui/material"
import { AxiosError, AxiosResponse } from "axios"
import { Dispatch } from "redux"

import { InfoContainer } from "../components/InfoContainer"
import { KonanClassificationAlert } from "../components/KonanClassificationAlert"
import { LiveMonitoring } from "../features/ApiMonitor/LiveMonitoring"
import { Decisions } from "../features/Decisions/Decisions"
import { EKYC } from "../features/Doxter/EKYC"
import { Integration } from "../features/Integration/Integration"
import { TrainingJobs } from "../features/ModelTraining/components/Training/TrainingJobs"
import { Programs } from "../features/Programs/Programs"
import { Ruleset } from "../features/Ruleset/Ruleset"
import { setWorkflowList } from "../features/Workflows"
import { FlowWithProvider as Workflows } from "../features/Workflows/Workflows"
import { setComputedFeatures, setSchemaFeatures } from "../features/projects"
import { KonanAPI } from "../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../store/CurrentProjectAndModelContext"
import { RootState } from "../store/ReduxStore"
import { ComputedFeatures } from "../types/custom/workflows"
import { Deployment } from "../types/generated/api/Deployment"
import { ListClassificationModelOutputs } from "../types/generated/api/ListClassificationModelOutputs"
import { Model } from "../types/generated/api/Model"
import { PaginatedWorkflowListList } from "../types/generated/api/PaginatedWorkflowListList"
import { WorkflowDeploymentSchemaFeature } from "../types/generated/api/WorkflowDeploymentSchemaFeature"
import { isPermitted } from "../utils/PermissionsHelpers"
import { Auth } from "../utils/auth"
import { extractPageFromBackEndPaginationLink } from "../utils/genericHelpers"
import { mappedComputedFeaturesNames, mappedSchemaFeatures } from "../utils/workflowHelpers"
import { DataFiles } from "./DataFiles"
import { DecisionEngines } from "./DecisionEngines"
import { Overview } from "./Overview"
import { Scorecards } from "./Scorecards"
import { Scripts } from "./Scripts"
import { Simulations } from "./Simulations"
import { Taglist } from "./Taglist"

type ParamsType = {
  id: string
}

/**
 * Project details component
 * houses project detail routes
 * @return {React.ReactElement}
 */
export function ProjectDetails(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()

  const permissions = Auth.getPermissions()

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const location = useLocation()
  const { currentProject, setCurrentProject, currentModel, setCurrentModel } = useContext(CurrentProjectAndModelContext)

  const dispatch: Dispatch = useDispatch()

  // Fetching project
  const { isLoading: isProjectsLoading, isFetching: isProjectsFetching } = useQuery<
    AxiosResponse<Deployment>,
    AxiosError
  >(["project", projectId], () => KonanAPI.fetchProject(projectId as string), {
    onSuccess: (response) => {
      if (currentProject?.uuid !== response?.data?.uuid) {
        setCurrentProject(response?.data)
      }

      if (response?.data?.category === "workflow") {
        dispatch(
          setSchemaFeatures(
            mappedSchemaFeatures(response?.data?.schema as unknown as Array<WorkflowDeploymentSchemaFeature>),
          ),
        )

        dispatch(
          setComputedFeatures(
            mappedComputedFeaturesNames(response?.data?.computed_feature_names as unknown as Array<ComputedFeatures>),
          ),
        )
      }
    },
    enabled: !!projectId,
  })

  // Fetch active configuration for the current model, disable refetching in integration screen
  const { data: activeConfiguration, isLoading: isActiveLoading } = useQuery<
    AxiosResponse<ListClassificationModelOutputs>,
    AxiosError
  >(["activeConfig", currentModel], () => KonanAPI.fetchActiveModelConfiguration(currentModel), {
    enabled: currentModel !== "" && !!currentProject && currentProject.type !== "generic",
    retry: false,
    refetchOnWindowFocus: !location.pathname.includes("/integration"),
  })

  // Fetch models on mount, disable refetching in integration screen
  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError>(
    ["models", projectId],
    () => KonanAPI.fetchModels(projectId as string),
    {
      refetchOnWindowFocus: !location.pathname.includes("/integration"),
    },
  )

  // TODO: remove this query later when we remove add new feature from workflow components
  // Fetch all workflows
  useInfiniteQuery<AxiosResponse<PaginatedWorkflowListList>, AxiosError>(
    ["workflows", currentProject?.uuid],
    ({ pageParam = 1 }) =>
      KonanAPI.fetchWorkflows({
        projectUUID: currentProject?.uuid as string,
        page_size: 200,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },
      enabled: !!currentProject?.uuid && currentProject?.category === "workflow",
      onSuccess: (response) => {
        dispatch(setWorkflowList(response?.pages[0]?.data.results))
      },
    },
  )

  // Get live model UUID on mount to add to context, if there is no live model -> Get first model
  useEffect(() => {
    if (currentModel === "") {
      if (models?.data && models?.data.length > 0) {
        setCurrentModel(models?.data?.find((model) => model.state === "live")?.uuid ?? models?.data[0].uuid)
      }
    }
  }, [currentModel, models, setCurrentModel])

  return (
    <div className={!location.pathname.includes("/SimulationReports") ? "layoutPadding" : ""}>
      {/* checks if a non generic project is not configured to display a config alert */}
      {currentProject &&
        currentProject?.type !== "generic" &&
        currentProject?.category !== "workflow" &&
        !location.pathname.endsWith("/classification") &&
        !isActiveLoading &&
        !activeConfiguration &&
        currentModel && (
          <Grid marginBottom={2}>
            <KonanClassificationAlert id={projectId as string} />
          </Grid>
        )}

      {(isProjectsLoading || isProjectsFetching) && !currentProject?.uuid && (
        <Grid container item minHeight="70dvh">
          <InfoContainer icon={<CircularProgress />} title="Loading project info.." />
        </Grid>
      )}

      {/*TODO: refactor this when we change from context to redux */}
      {currentProject?.uuid && (
        <Routes>
          {currentProject?.category !== "workflow" ? (
            <>
              <Route key="project-overview" path={`overview`} element={<Overview />} />
              <Route key="project-requests" path={`requests`} element={<LiveMonitoring />} />
              <Route key="model-training" path={`model-training`} element={<TrainingJobs />} />
              <Route key="decision-engines" path={`decision-engines`} element={<DecisionEngines />} />
            </>
          ) : (
            <>
              <Route key="workflows" path={`workflows`} element={<Workflows />} />
              <Route key="scripts" path="scripts" element={<Scripts />} />
              <Route key="Rulesets" path={`Rulesets`} element={<Ruleset />} />
              {isPermitted("List taglists", permissions.konan, flattenedKonanPermissions) && (
                <Route key="Taglists" path={`Taglists`} element={<Taglist />} />
              )}
              <Route key="Scorecards" path={`Scorecards`} element={<Scorecards />} />
              <Route key="Programs" path={`Programs`} element={<Programs />} />
              <Route key="SimulationReports" path={`SimulationReports`} element={<Simulations />} />
            </>
          )}
          <Route key="decisions" path={`decisions`} element={<Decisions />} />
          <Route key="Data-Files" path={`data-files`} element={<DataFiles />} />
          <Route path="ekyc" element={<EKYC />} />
          <Route key="model-integration" path={`integration`}>
            <Route key="model-integration" index element={<Integration />} />
            <Route key="model-integration" path=":tab" element={<Integration />} />
          </Route>
        </Routes>
      )}
    </div>
  )
}
