/**
 * Read file content as text
 * @param file File to read
 * @returns Promise that resolves with the file content as a string
 * @throws Error if the file content is not a string
 */
export const readFileContentAsync = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      const result = event?.target?.result
      if (typeof result === "string") {
        resolve(result)
      } else {
        reject(new Error("File content is not a string"))
      }
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsText(file)
  })
}
