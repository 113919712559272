import React, { Fragment, useEffect, useMemo, useState } from "react"

import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"

import Grid from "@mui/material/Grid"
import { AxiosResponse } from "axios"
import { MRT_PaginationState } from "material-react-table"

import { KonanEmptyState } from "../../components/KonanEmptyState"
import { KonanTextualCard } from "../../components/KonanTextualCard"
import { EkycBarGraph } from "../../components/graphs/EKYCBarChart"
import { EkycSessionsTable } from "../../components/tables/EKYCSessionsTable"
import { DoxterAPI } from "../../services/DoxterAPI"
import { OrganizationVerification } from "../../types/generated/doxter/OrganizationVerification"
import { PaginatedListSessions } from "../../types/generated/doxter/PaginatedListSessions"
import { SessionAggregates } from "../../types/generated/doxter/SessionAggregates"
import { TimeSeries } from "../../types/generated/doxter/TimeSeries"
import { Auth } from "../../utils/auth"
import { convertEKYCDataToBarGraph } from "../../utils/deploymentDetailsHelpers"
import { SessionDetailsDialog } from "./components/SessionDetailsDialog"

/**
 * Doxter's EKYC screen
 * @return {React.ReactElement}
 */
export function EKYC(): React.ReactElement {
  const orgUID = Auth.getOrganizationUID()

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  // session detail dialog open state
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [userId, setUserId] = useState<string>("")

  const [searchParams] = useSearchParams()

  const startDate = searchParams.get("startDate")
  const endDate = searchParams.get("endDate")

  // fetch all user stats list endpoint
  const { data: verificationData, isLoading: isVerificationLoading } = useQuery<
    AxiosResponse<OrganizationVerification>
  >(["ekyc-verification", orgUID], () => DoxterAPI.verifyOrganization(orgUID as string), {
    enabled: !!orgUID,
    retry: false,
  })

  // fetch all user stats list endpoint
  const { data: aggregates, isLoading: isUserStatsLoading } = useQuery<AxiosResponse<SessionAggregates>>(
    ["ekyc-aggregates", orgUID, startDate, endDate],
    () =>
      DoxterAPI.fetchEkycUserStatsAggregates({
        date_from: startDate as string,
        date_to: endDate as string,
      }),
    { enabled: startDate != null && endDate != null && verificationData?.data.valid === "true" },
  )

  // fetch all user stats by time (granularity)
  const { data: timeSeriesData, isLoading: isUserStatsByTimeLoading } = useQuery<AxiosResponse<TimeSeries>>(
    ["ekyc-time-series", startDate, endDate],
    () =>
      DoxterAPI.fetchEkycUserStatsWithTime({
        date_from: startDate as string,
        date_to: endDate as string,
        granularity: "day",
      }),
    { enabled: startDate != null && endDate != null && verificationData?.data.valid === "true" },
  )

  // fetch all user stats by time (granularity)
  const { data: sessionsData, isLoading: isSessionsLoading } = useQuery<AxiosResponse<PaginatedListSessions>>(
    ["ekyc-sessions", pagination, startDate, endDate],
    () =>
      DoxterAPI.EkycSessions({
        date_from: startDate as string,
        date_to: endDate as string,
        page: pagination.pageIndex + 1,
        page_size: pagination.pageSize,
      }),
    { enabled: startDate != null && endDate != null && verificationData?.data.valid === "true" },
  )

  const adjustedBarGraphData = useMemo(() => {
    if (startDate && endDate) {
      // adding ignore to accommodate types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return convertEKYCDataToBarGraph(timeSeriesData?.data)
    }
  }, [startDate, endDate, timeSeriesData])

  // reset pagination when date range changes
  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }, [startDate, endDate])

  return (
    <Grid container spacing={2}>
      <SessionDetailsDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} uuid={userId} />

      {(verificationData?.data.valid === "false" || verificationData?.status !== 200) && !isVerificationLoading ? (
        <Grid container xs={12} className={"empty-container"}>
          <KonanEmptyState
            title="This organization isn't subscribed to EKYC, yet."
            subTitle="Please contact your administrator to subscribe"
          />
        </Grid>
      ) : (
        <Fragment>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} lg={2.4}>
              <KonanTextualCard
                header="Users"
                value={aggregates?.data.total_users}
                cardHeight={100}
                isLoading={(startDate && !endDate) || isUserStatsLoading || isVerificationLoading}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <KonanTextualCard
                header="Sessions"
                tooltipText={
                  <Fragment>
                    Total number of session opened by your users. A user can create multiple sessions
                    <br />
                    (for example by opening and closing the app and going through the EKYC process again)
                  </Fragment>
                }
                value={aggregates?.data.total_sessions}
                cardHeight={100}
                isLoading={(startDate && !endDate) || isUserStatsLoading || isVerificationLoading}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <KonanTextualCard
                header="Verified"
                tooltipText="Total number of sessions where the user passed the EKYC process successfully."
                value={aggregates?.data.verified}
                cardHeight={100}
                isLoading={(startDate && !endDate) || isUserStatsLoading || isVerificationLoading}
                variant="positive"
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <KonanTextualCard
                header="Unverified"
                tooltipText="Total number of sessions where the user failed the EKYC process."
                value={aggregates?.data.unverified}
                cardHeight={100}
                isLoading={(startDate && !endDate) || isUserStatsLoading || isVerificationLoading}
                variant="negative"
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <KonanTextualCard
                header="Incomplete"
                tooltipText="Total number of sessions where the user did not complete the EKYC process."
                value={aggregates?.data.incomplete}
                cardHeight={100}
                isLoading={(startDate && !endDate) || isUserStatsLoading || isVerificationLoading}
                variant="warning"
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <EkycBarGraph
              isLoading={(startDate && !endDate) || isUserStatsByTimeLoading || isVerificationLoading}
              barGraphData={adjustedBarGraphData}
            />
          </Grid>

          <Grid item xs={12} className={"table"}>
            <EkycSessionsTable
              rowCount={sessionsData?.data.count ?? 0}
              data={sessionsData?.data.results ?? []}
              isLoading={isSessionsLoading || isVerificationLoading}
              openDialog={(uid) => {
                setUserId(uid)
                setIsDialogOpen(true)
              }}
              pagination={pagination}
              setPagination={setPagination}
            />
          </Grid>
        </Fragment>
      )}
    </Grid>
  )
}
