import React from "react"

import DateRangeIcon from "@mui/icons-material/DateRange"
import { Box, Grid, Paper } from "@mui/material"
import { BarDatum, ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { EkycStatsByTimeBarData } from "../../types/custom/ekyc"
import { GraphHeader } from "../GraphHeader"
import { LoadingContainer } from "../LoadingContainer"

import styles from "./EkycBarGraph.module.scss"

type EKYCBarGraphProps = {
  isLoading: boolean
  barGraphData: EkycStatsByTimeBarData[] | undefined
}

export function EkycBarGraph(props: EKYCBarGraphProps): React.ReactElement {
  const { barGraphData, isLoading } = props

  const theme = getTheme()

  return (
    <Paper className={styles.paper}>
      <GraphHeader title="Sessions per Day" />
      <Grid padding={1}>
        <Box style={{ height: 400, padding: 0, margin: 0 }}>
          {(!barGraphData || barGraphData.length < 1) && !isLoading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
              <Typography variant="label" variantColor={2}>
                No data to display
              </Typography>
            </Grid>
          ) : isLoading || !barGraphData ? (
            <LoadingContainer />
          ) : (
            <ResponsiveBar
              data={barGraphData as BarDatum[]}
              theme={NivoTheme(theme)}
              indexBy="timestamp"
              keys={["verified", "unverified", "incomplete"]}
              colors={[
                theme.palette.green.background[1],
                theme.palette.red.background[1],
                theme.palette.yellow.text[2],
              ]}
              colorBy="id"
              margin={{ top: 10, right: 10, bottom: 60, left: 40 }}
              borderWidth={0}
              labelSkipWidth={20}
              labelSkipHeight={20}
              borderRadius={2}
              padding={0.5}
              indexScale={{ type: "band", round: true }}
              layout="vertical"
              valueScale={{ type: "linear" }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: 38,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: -40,
              }}
              enableLabel={false}
              enableGridY={true}
              animate={true}
              enableGridX={true}
              groupMode="stacked"
              tooltip={(slice) => {
                return (
                  <div className={"chart-tooltip"}>
                    <div>
                      <div style={{ display: "flex" }}>
                        <DateRangeIcon
                          fontSize="small"
                          style={{
                            verticalAlign: "text-bottom",
                            marginRight: "4px",
                            color: theme.palette.grayscale.text[2],
                          }}
                        />
                        <Typography variant="h3-bold" variantColor={2}>
                          {slice.data.timestamp}
                        </Typography>
                      </div>

                      <Box mt={1} />

                      <Typography variant="h3-bold" variantColor={2}>
                        <strong>Summary</strong>
                      </Typography>
                      <Grid
                        item
                        container
                        xs={12}
                        marginBottom={0.5}
                        marginTop={0.5}
                        direction={"row"}
                        alignItems={"flex-start"}
                        wrap="nowrap"
                      >
                        <Typography
                          style={{ textAlign: "start", paddingTop: "2px" }}
                          noWrap
                          variant="label"
                          color="neutral"
                          variantColor={2}
                        >
                          Total users: {slice.data.total_users ?? 0}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        marginBottom={0.5}
                        marginTop={0.5}
                        direction={"row"}
                        alignItems={"flex-start"}
                        wrap="nowrap"
                      >
                        <span key="chip" className={styles.total} />
                        <Typography
                          style={{ textAlign: "start", paddingTop: "2px" }}
                          noWrap
                          variant="label"
                          color="neutral"
                          variantColor={2}
                        >
                          Total sessions: {slice.data.total_sessions ?? 0}
                        </Typography>
                      </Grid>
                      <Grid item marginBottom={0.5} container xs={12} direction={"row"} alignItems={"flex-start"}>
                        <Grid item>
                          <span
                            style={{ display: "block" }}
                            key="chip"
                            className={`${styles.block} ${styles.incomplete}`}
                          />
                        </Grid>
                        <Typography
                          style={{ textAlign: "start", paddingTop: "2px" }}
                          noWrap
                          variant="label"
                          color="neutral"
                          variantColor={2}
                        >
                          Incomplete: {slice.data.incomplete ?? 0}
                        </Typography>
                      </Grid>

                      <Grid item marginBottom={0.5} container xs={12} direction={"row"} alignItems={"flex-start"}>
                        <Grid item>
                          <span
                            style={{ display: "block" }}
                            key="chip"
                            className={`${styles.block} ${styles.unverified}`}
                          />
                        </Grid>
                        <Typography
                          style={{ textAlign: "start", paddingTop: "2px" }}
                          noWrap
                          variant="label"
                          color="neutral"
                          variantColor={2}
                        >
                          Unverified: {slice.data.unverified ?? 0}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        marginBottom={0.5}
                        xs={12}
                        direction={"row"}
                        alignItems={"flex-start"}
                        wrap="nowrap"
                      >
                        <span key="chip" className={`${styles.block} ${styles.verified}`} />
                        <Typography
                          style={{ textAlign: "start", paddingTop: "2px" }}
                          noWrap
                          variant="label"
                          color="neutral"
                          variantColor={2}
                        >
                          Verified: {slice.data.verified ?? 0}
                        </Typography>
                      </Grid>
                    </div>
                  </div>
                )
              }}
            />
          )}
        </Box>
      </Grid>
    </Paper>
  )
}
