import { createSlice } from "@reduxjs/toolkit"

/**
 * this slice used to store and update workflow listing
 */
export const workflowSlice = createSlice({
  name: "workflow",
  initialState: {
    workflows: [],
  },
  reducers: {
    setWorkflowList(state, action) {
      state.workflows = action.payload
    },
  },
})

export const { setWorkflowList } = workflowSlice.actions
