import { Grid } from "@mui/material"
import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import { CustomTooltip } from "./UI/CustomTooltip"

export function ValueBlock(props: {
  value: string | null
  size: number
  isLoading?: boolean
  showTooltip?: boolean
}): React.ReactElement {
  const { isLoading, value, size, showTooltip = true } = props
  return (
    <Grid item xs={size} className="card-field" alignItems={"center"} display={"flex"} justifyContent={"center"}>
      <CustomTooltip title={!isLoading && showTooltip ? value : ""} placement="top">
        <Typography style={{ textAlign: "center" }} variant="label">
          {isLoading ? <Skeleton variant="rectangular" width={150} height={20} /> : value}
        </Typography>
      </CustomTooltip>
    </Grid>
  )
}
