import ReactGA from "react-ga4"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import * as Yup from "yup"
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Button, InputText, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"

import { KonanAPI } from "../../services/KonanAPI"
import { CreateProjectRequest, baseErrorType } from "../../types/custom/projects"
import { Deployment } from "../../types/generated/api/Deployment"

// Project form validation schema object
const projectValidationSchema = Yup.object({
  name: Yup.string().max(30, "Must be 30 characters or less").required("Required"),
  description: Yup.string().max(200, "Must be 200 characters or less"),
  type: Yup.string().required("Required"),
})

interface Props {
  isOpen: boolean
  onClose: () => void
}

/**
 * Project Workflow Creation Dialog
 * @param  {string} isOpen
 * @param  {function} onClose
 * @return  {React.ReactElement}
 */
export function ProjectWorkflowCreationDialog(props: Readonly<Props>): React.ReactElement {
  const { isOpen, onClose } = props

  const MuiTheme = useTheme()
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const fullScreen = useMediaQuery(MuiTheme.breakpoints.down("md"))

  // Request mutations
  const createProjectMutation = useMutation<AxiosResponse<Deployment>, AxiosError<baseErrorType>, CreateProjectRequest>(
    KonanAPI.createProject,
    {
      onSuccess: () => {
        NotificationUtils.toast("Workflow project created successfully", { snackBarVariant: "positive" })
      },
      onError: ({ response }) => {
        NotificationUtils.toast(response?.data?.non_field_errors?.[0] ?? "Workflow project creation failed", {
          snackBarVariant: "negative",
        })
      },
    },
  )
  // Project creation form control
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      type: "generic",
      category: "workflow",
    },
    validationSchema: projectValidationSchema,
    onSubmit: async (values) => {
      try {
        ReactGA.event({
          category: "Deployments",
          action: "Creating new deployment",
        })

        await createProjectMutation.mutateAsync({
          name: values.name,
          type: values.type,
          category: values.category,
          description: values.description || undefined,
        })

        onClose()

        // navigate to workflow tab after closing dialog
        navigate("?page=workflow")
        queryClient.invalidateQueries("projects")
      } catch (e) {
        ReactGA.event({
          category: "Deployments",
          action: "Deployment contains errors",
        })
      }
    },
  })

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          // To enable the date-picker to overflow
          overflow: "unset",
        },
      }}
    >
      <DialogTitle className="dialog-header-base">
        <Typography variant="h2-bold">New Workflow Project</Typography>
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputText
              id="name"
              label="Name"
              placeholder="Enter Project Name"
              value={formik.values.name}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              id="description"
              label="Description"
              placeholder="Enter Description"
              value={formik.values.description}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description) && formik.errors.description}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Button onClick={onClose} variant={"secondary"}>
          Cancel
        </Button>
        <Button
          onClick={formik.submitForm}
          variant="primary"
          disabled={!formik.isValid || formik.isSubmitting || !formik.values.name}
        >
          {formik.isSubmitting ? <CircularProgress color="inherit" size={20} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
