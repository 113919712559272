// https://www.freecodecamp.org/news/build-a-react-hooks-front-end-app-with-routing-and-authentication/
import { jwtDecode } from "jwt-decode"

import { queryClient } from "../index"

// This class contains both Auth and API calls, should be seperated moving on
class Auth {
  async login(access, refresh) {
    try {
      // Save access and refresh token
      localStorage.setItem("access_token", access)
      localStorage.setItem("refresh_token", refresh)

      const access_payload = jwtDecode(access)
      localStorage.setItem("email", access_payload.email)
      localStorage.setItem("organization_id", access_payload.organization_id)
      localStorage.setItem("organization_name", access_payload.organization_name)
      localStorage.setItem("access_expiry_date", access_payload.exp)
      localStorage.setItem("first_name", access_payload.first_name)
      localStorage.setItem("last_name", access_payload.last_name)
      localStorage.setItem("role_name", access_payload.role_name)
      localStorage.setItem("role_id", access_payload.role_id)
      localStorage.setItem("resources", JSON.stringify(access_payload.resources))
      localStorage.setItem("permissions", JSON.stringify(access_payload.permissions))
      localStorage.setItem("role_is_owner", JSON.stringify(access_payload.role_is_owner))

      const refresh_payload = jwtDecode(refresh)
      localStorage.setItem("refresh_expiry_date", refresh_payload.exp)
    } catch (e) {
      throw e
    }
  }

  getUsername = () => {
    if (localStorage.getItem("username")) {
      return localStorage.getItem("username")
    } else {
      return null
    }
  }

  getFirstName = () => {
    if (localStorage.getItem("first_name")) {
      return localStorage.getItem("first_name")
    } else {
      return null
    }
  }

  getLastName = () => {
    if (localStorage.getItem("last_name")) {
      return localStorage.getItem("last_name")
    } else {
      return null
    }
  }

  getEmail = () => {
    if (localStorage.getItem("email")) {
      return localStorage.getItem("email")
    } else {
      return null
    }
  }

  getPermissions = () => {
    if (localStorage.getItem("permissions")) {
      return JSON.parse(localStorage.getItem("permissions"))
    } else {
      // returning empty permissions to prevent screen blackouts
      return { synapse_id: [], konan: [] }
    }
  }

  setOrganizationUID = (organization_id) => {
    localStorage.setItem("organization_id", organization_id)
  }

  setOrganizationName = (organization_name) => {
    localStorage.setItem("organization_name", organization_name)
  }

  getOrganizationUID = () => {
    if (localStorage.getItem("organization_id") !== "null") {
      return localStorage.getItem("organization_id")
    } else {
      return null
    }
  }

  getOrganizationName = () => {
    if (localStorage.getItem("organization_name") !== "null") {
      return localStorage.getItem("organization_name")
    } else {
      return null
    }
  }

  getUserRole = () => {
    if (localStorage.getItem("role_name") !== "null") {
      return localStorage.getItem("role_name")
    } else {
      return null
    }
  }

  getUserRoleID = () => {
    if (localStorage.getItem("role_id") !== "null") {
      return localStorage.getItem("role_id")
    } else {
      return null
    }
  }

  getUserGroups = () => {
    if (localStorage.getItem("resources") !== "null") {
      return localStorage.getItem("resources")
    } else {
      return null
    }
  }

  setAccessToken = (access_token) => {
    localStorage.setItem("access_token", access_token)
  }

  getAccessToken = () => {
    if (localStorage.getItem("access_token")) {
      return localStorage.getItem("access_token")
    } else {
      return null
    }
  }

  getRefreshToken = () => {
    if (localStorage.getItem("refresh_token")) {
      return localStorage.getItem("refresh_token")
    } else {
      return null
    }
  }

  checkIfRefreshTokenIsValid = () => {
    let currentTime = Date.now() / 1000
    let expiryDate = localStorage.getItem("refresh_expiry_date")
    return currentTime < expiryDate
  }

  checkIfAccessTokenIsValid = () => {
    let currentTime = Date.now() / 1000
    let expiryDate = localStorage.getItem("access_expiry_date")
    return currentTime < expiryDate
  }

  isAuthenticated = () => {
    return this.checkIfRefreshTokenIsValid()
  }

  getAuthHeaderValue() {
    return "Bearer " + this.getAccessToken()
  }

  getIsOwnerRole = () => {
    return localStorage.getItem("role_is_owner") === "true"
  }

  logOut(redirect = false, navigate) {
    // clear all elements of local storage except theme
    const theme = localStorage.getItem("theme")
    localStorage.clear()
    localStorage.setItem("theme", theme)

    // Clear cache
    queryClient.clear()

    if (redirect && navigate) {
      // has to be imported for navigation to work as expected
      navigate("/login", { replace: true })
    }
  }
}

const auth = new Auth()
export { auth as Auth }
