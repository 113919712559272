import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from "react"

import { FileRejection } from "react-dropzone"
import { useMutation, useQuery } from "react-query"

import { Box, Divider, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material"
import { Button, InputText, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { FormikProps } from "formik"
import { MRT_ColumnDef } from "material-react-table"

import { queryClient } from "../../.."
import { LoadingContainer } from "../../../components/LoadingContainer"
import { BaseTable } from "../../../components/tables/BaseTable"
import { KonanAPI } from "../../../services/KonanAPI"
import { RequiredSchema } from "../../../types/custom/projects"
import { TrainingDataResponse } from "../../../types/generated/api/TrainingDataResponse"
import { TrainingDataUpload } from "../../../types/generated/api/TrainingDataUpload"
import { Dropzone } from "./Dropzone"

import styles from "../Projects.module.scss"

const columns: MRT_ColumnDef<RequiredSchema>[] = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Required",
    accessorKey: "required",
    enableColumnFilter: false,
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
]

type Props = {
  currentProjectUUID: string
  onDataChange: (datasetUUID: string) => void
  creditScoringType?: string

  type?: string
  formik?: FormikProps<{ name: string; target_column: string; targetColumnType: string }>
}

/**
 * Project creation overview, first screen in the creation flow
 * @param  {string} currentProjectUUID
 * @param  {React.FormikProps} formik formik object to control the form
 * @param  {function} onDataChange
 * @param  {string} type
 * @return  {React.ReactElement}
 */
export function UploadTrainingDataFlow(props: Props): React.ReactElement {
  const { currentProjectUUID, onDataChange, type, creditScoringType, formik } = props

  const [selectedDataset, setSelectedDataset] = useState("sample")

  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [invalidFiles, setInvalidFiles] = useState<FileRejection[]>([])

  const abortControllerRef = useRef<AbortController | null>(null)

  const [openDropzone, setOpenDropzone] = React.useState<null | (() => void)>(null)

  const handleOpenDropzone = (open: () => void): void => {
    setOpenDropzone(() => open)
  }

  const handleButtonClick = (): void => {
    openDropzone && openDropzone()
  }

  // https://github.com/TanStack/query/discussions/1551
  // Followed the instructions here by F0rro on jan 23 and cleaned up a bit to match our architecture
  const {
    isLoading: uploadingCSV,
    isError,
    mutateAsync: uploadCSVAsyncMutation,
    reset,
  } = useMutation<TrainingDataResponse, AxiosError, File>((file: File) => {
    abortControllerRef.current = new AbortController()
    return KonanAPI.uploadTrainingData(currentProjectUUID, file, setUploadProgress, abortControllerRef.current.signal)
  })

  //fetch all training data in current project
  const { data: trainingData, isLoading: isAllTrainingDataLoading } = useQuery<TrainingDataUpload[], AxiosError>(
    ["training-data", currentProjectUUID],
    () => KonanAPI.fetchTrainingData(currentProjectUUID),
    { enabled: currentProjectUUID != null },
  )

  //fetch schema
  const { isLoading: isSchemaLoading, data: schema } = useQuery<RequiredSchema[], AxiosError>(
    ["required-schema", currentProjectUUID],
    () => KonanAPI.fetchRequiredSchema(currentProjectUUID),
    { keepPreviousData: true },
  )

  // Select change handler
  const handleSelect = (trainingData: ChangeEvent<HTMLInputElement>): void => {
    setSelectedDataset((trainingData?.target).value)
    onDataChange((trainingData?.target).value)
  }

  // handle close dropzone
  const handleCloseAndAbort = useCallback(() => {
    abortControllerRef.current?.abort()
    reset()
    setUploadProgress(0)
    setAcceptedFiles([])
    if (type === "pre-built") {
      setSelectedDataset("sample")
    }
  }, [reset, type])

  // handle upload once files are accepted
  const handleUpload = async (): Promise<void> => {
    setSelectedDataset("")
    onDataChange("")
    try {
      setUploadProgress(0)
      const res = await uploadCSVAsyncMutation(acceptedFiles[0])
      onDataChange(res.uuid)

      // Invalidate training data to trigger a refresh
      await queryClient.invalidateQueries(["training-data", currentProjectUUID])
      setSelectedDataset(res.uuid)
    } catch (err) {
      // reset dropzone on error
      handleCloseAndAbort()
    }
  }

  // starts uploading once the file is read
  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  return (
    <Grid
      container
      item
      spacing={2}
      padding={0.5}
      className={creditScoringType === "pre-built" ? styles.prebuiltContainer : styles.automlContainer}
    >
      {/**
       * upload errors
       * 1. invalid file type
       * 2. request didn't succeed
       */}
      {invalidFiles && invalidFiles.length > 0 && (
        <Grid item xs={12}>
          <Snackbar
            variant="negative"
            fullWidth
            description={`${invalidFiles[0].file.name} is not a valid CSV file.`}
          />
        </Grid>
      )}

      {isError && (
        <Grid item xs={12}>
          <Snackbar variant="negative" fullWidth description="An error occurred. Please try again." />
        </Grid>
      )}

      <Grid item xs={12}>
        <Snackbar
          variant="important"
          fullWidth
          description="The uploaded file requires input and target columns to train the model. You have the flexibility to name the columns as per your preference, and during the process, you will be prompted to select the target column for model training."
        />
      </Grid>

      <Grid item>
        <Typography variant="h3-bold">Model Overview</Typography>
      </Grid>

      <Grid item xs={12} mt={1}>
        <InputText
          label="Name"
          id="name"
          placeholder="Initial Model"
          required
          value={formik && formik.values.name}
          handleChange={(e: React.ChangeEvent<Element>) => {
            formik && formik.handleChange(e)
          }}
          handleBlur={formik && formik.handleBlur}
          error={formik && formik.touched.name && Boolean(formik && formik.errors.name) && formik.errors.name}
          fullWidth
        />
      </Grid>

      <div className={styles.verticalMargin} />

      {/* Schema table section */}
      {creditScoringType !== "auto-ml" && (
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="h3-bold" gutterBottom>
              Match required scheme
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12}>
              <BaseTable
                enableOrdering={false}
                columns={columns}
                data={schema ?? []}
                isLoading={isSchemaLoading}
                title="Data Schema"
              />

              <Typography variant="span" style={{ marginTop: "8px" }}>
                Make sure your dataset matches the required schema before uploading
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      )}

      {/* data upload section */}
      <Grid item container direction="row" mb={2} justifyContent="space-between" alignItems="flex-start" xs={12}>
        <Grid item container direction="column" xs={12} lg={5.8}>
          <Grid item>
            <Typography variant="h3-bold" gutterBottom>
              Upload a training data
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Dropzone
              uploadingCSV={uploadingCSV}
              uploadProgress={uploadProgress}
              currentSelectedFiles={acceptedFiles}
              setSelectedFiles={(file: File[]) => setAcceptedFiles(file)}
              setInvalidFiles={(file: FileRejection[]) => setInvalidFiles(file)}
              bottomText="Max 2 GB"
              handleCancelUpload={handleCloseAndAbort}
              onDropZoneOpen={handleOpenDropzone}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={5.8}>
          <Grid item>
            <Typography variant="h3-bold" gutterBottom>
              {creditScoringType === "pre-built" ? "Use sample/existing data" : "Or choose existing data"}
            </Typography>
          </Grid>
          <Grid container item xs={12} direction="column" className={styles.existingDataContainer}>
            {creditScoringType === "pre-built" && (
              <Grid item xs={12}>
                <Grid
                  item
                  className={selectedDataset === "sample" ? styles.selectedTrainingDataCard : styles.trainingDataCard}
                  //onClick={() => handleSelect("sample")}
                >
                  <Typography variant="a" style={{ width: "100%" }} noWrap>
                    Sample dataset
                  </Typography>
                </Grid>
              </Grid>
            )}

            {isAllTrainingDataLoading && type === "dialog" ? (
              <Grid item container xs={12} direction="column" alignItems="center" justifyContent="center">
                <LoadingContainer />
              </Grid>
            ) : (trainingData && trainingData?.length === 0) || (!trainingData && isAllTrainingDataLoading) ? (
              <Grid item container xs={12} direction="column" alignItems="center" justifyContent="center">
                <Typography variant="a">No data uploaded, yet</Typography>
                <Box mt={0.5} />
                <Typography variant="span">Upload data to get started</Typography>
                <Box mt={1} />
                <Button variant="secondary" disabled={uploadingCSV} size="small" onClick={handleButtonClick}>
                  Upload data
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} container className={styles.datasetOptionContainer}>
                <RadioGroup
                  aria-label="datasets"
                  name="controlled-radio-buttons-group"
                  value={selectedDataset}
                  onChange={handleSelect}
                >
                  {trainingData?.map((dataset) => (
                    <Fragment key={dataset.uuid}>
                      <Grid item container padding={1} ml={0.5}>
                        <FormControlLabel
                          value={dataset.uuid}
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                                  color: "var(--neutral-border-active)",
                                },
                                "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                  color: "var(--important-background-active)",
                                },
                              }}
                            />
                          }
                          label={<Typography variant="p">{dataset.name}</Typography>}
                          sx={{ color: "var(--grayscale-text-1)" }}
                        />
                      </Grid>

                      <Divider
                        variant="fullWidth"
                        style={{
                          backgroundColor: "var(--grayscale-border)",
                        }}
                        sx={{
                          "&::before, &::after": {
                            backgroundColor: "var(--grayscale-border)",
                          },
                        }}
                      />
                    </Fragment>
                  ))}
                </RadioGroup>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
