import { useCallback, useEffect, useRef } from "react"

import { throttle } from "lodash"

/**
 * Represents a function returned by lodash's throttle method, which includes a cancel method to
 * cancel delayed invocations.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ThrottledFunction<T extends (...args: any[]) => void> {
  (...args: Parameters<T>): void
  cancel: () => void
}

/**
 * A React hook that creates a throttled version of a callback function.
 * The throttled function invokes the original function at most once per specified delay.
 *
 * @template T The type of the original callback function.
 * @param callback The function to throttle.
 * @param delay The time in milliseconds to delay between the function calls.
 * @returns A throttled version of the input function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useThrottle<T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
): (...args: Parameters<T>) => void {
  // Use a ref to store the callback
  const callbackRef = useRef(callback)

  // Update the ref with the new callback every time it changes
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // Create the throttled function and store it in a ref
  const throttledCallbackRef = useRef<ThrottledFunction<T>>(
    throttle((...args: Parameters<T>) => {
      callbackRef.current(...args)
    }, delay),
  )

  // If the delay changes, re-apply the throttle function
  useEffect(() => {
    throttledCallbackRef.current = throttle((...args: Parameters<T>) => {
      callbackRef.current(...args)
    }, delay)
  }, [delay])

  // Cleanup the throttled function on unmount
  useEffect(() => {
    return () => {
      throttledCallbackRef.current.cancel()
    }
  }, [])

  // Return the current throttled function
  return useCallback((...args: Parameters<T>) => {
    throttledCallbackRef.current(...args)
  }, [])
}
