/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type WorkflowSchemaFeature = {
  name: string
  /**
   * * `TEXT` - Text
   * * `NUMBER` - Number
   * * `BOOLEAN` - Boolean
   * * `DATE` - Date
   * * `LIST` - List
   * * `UNDEFINED` - Undefined
   */
  type?: WorkflowSchemaFeature.type
  is_required?: boolean
  feature_nesting_separator?: string
  /**
   * * `workflow` - workflow
   * * `project_node` - project_node
   * * `workflow_and_project_node` - workflow_and_project_node
   */
  readonly source: WorkflowSchemaFeature.source
}
export namespace WorkflowSchemaFeature {
  /**
   * * `TEXT` - Text
   * * `NUMBER` - Number
   * * `BOOLEAN` - Boolean
   * * `DATE` - Date
   * * `LIST` - List
   * * `UNDEFINED` - Undefined
   */
  export enum type {
    TEXT = "TEXT",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    LIST = "LIST",
    UNDEFINED = "UNDEFINED",
  }
  /**
   * * `workflow` - workflow
   * * `project_node` - project_node
   * * `workflow_and_project_node` - workflow_and_project_node
   */
  export enum source {
    WORKFLOW = "workflow",
    PROJECT_NODE = "project_node",
    WORKFLOW_AND_PROJECT_NODE = "workflow_and_project_node",
  }
}
