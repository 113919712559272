import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { GroupRetrieve } from "../../types/generated/authentication/GroupRetrieve"
import { UserManagementLog } from "../../types/generated/authentication/UserManagementLog"

export const groupsSlice = createSlice({
  name: "groups",
  initialState: {
    groups: [] as GroupRetrieve[],
    history: [] as UserManagementLog[],
  },
  reducers: {
    setgroups: (state, action: PayloadAction<GroupRetrieve[]>) => {
      state.groups = action.payload
    },
    setHistory: (state, action: PayloadAction<UserManagementLog[]>) => {
      state.history = action.payload
    },
  },
})

export const { setgroups, setHistory } = groupsSlice.actions
