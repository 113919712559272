import { TableConfigurationRequest } from "../../types/generated/api/TableConfigurationRequest"

export const TAG_VARIANTS = [
  "cyan",
  "teal",
  "amber",
  "crimson",
  "violet",
  "sky",
  "grass",
  "plum",
  "tomato",
  "indigo",
  "purple",
  "orange",
  "pink",
  "brown",
  "mint",
  "lime",
]

// Currently supported tables to have ordering functionality e2e
export const ORDERED_TABLES = Object.values(TableConfigurationRequest.table)
