import React, { Fragment, useEffect, useMemo, useState } from "react"

import { useInView } from "react-intersection-observer"
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import CodeIcon from "@mui/icons-material/Code"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import {
  Button,
  InputText,
  NotificationUtils,
  Skeleton,
  Tab,
  Tabs,
  Tag,
  Typography,
} from "@synapse-analytics/synapse-ui"
import axios, { AxiosError, AxiosResponse } from "axios"
import sha256 from "crypto-js/sha256"
import { useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"

import { VersionHistory } from "../../components/UI/VersionHistory"
import { KonanAPI } from "../../services/KonanAPI"
import { VersionChangeRequest } from "../../types/custom/rules"
import { CreateScriptRequest, UpdateScriptRequest } from "../../types/custom/workflows"
import { DataFile } from "../../types/generated/api/DataFile"
import { PaginatedScriptVersionList } from "../../types/generated/api/PaginatedScriptVersionList"
import { ScriptGroupRetrieve } from "../../types/generated/api/ScriptGroupRetrieve"
import { ScriptVersion } from "../../types/generated/api/ScriptVersion"
import { ScriptVersionRetrieve } from "../../types/generated/api/ScriptVersionRetrieve"
import { createFileFromString, extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import { isStaticAnalysisValid } from "../../utils/workflowHelpers"
import { StaticAnalysisScript } from "../Workflows/workflow-fixtures"
import {
  RequirementsDataFile,
  ScriptDialogProps,
  ScriptTab,
  SingleScriptProps,
  StatusColorMap,
  StatusTextMap,
} from "./Interfaces"
import {
  CustomRequirementsPackages,
  CustomScriptEditor,
  CustomScriptLogs,
  CustomScriptTestFeatures,
} from "./components/CustomScriptComponents"

import styles from "./Scripts.module.scss"

/**
 * Dialog for creating, reading and updating custom script
 *
 * @param {function} onClose handler for closing dialog
 * @param {boolean} isWorkFlowInReadMode
 * @param {object} scriptId
 * @param {string} nodeId
 * @param {"workflow" | "scripts"} [renderView] Specifies the context in which the dialog is used.
 * @param {boolean} isNewScriptTriggered indicator for this dialog should be new with clean slate or reference to existing script
 * @returns {React.ReactElement}
 */
function SingleScript(props: Readonly<SingleScriptProps>): React.ReactElement {
  const {
    isWorkFlowInReadMode,
    onClose,
    isNewScriptTriggered = false,
    isDuplicate = false,
    isScriptLoading,
    scriptData,
    renderView = "workflow",
    openInEditMode = false,
    tab,
    isVersionDialog = false,
    setIsVersionDialog,
  } = props

  const { id: projectId } = useParams<{ id: string }>()
  const queryClient = useQueryClient()

  // states
  const [currentScriptUUID, setCurrentScriptUUID] = useState<string | undefined>(scriptData?.uuid)
  const [selectedState, setSelectedState] = useState<ScriptTab>(tab ?? ScriptTab.ScriptCode)

  const [isConsoleExpanded, setIsConsoleExpanded] = useState<boolean>(false)
  const [isLogsExpanded, setIsLogsExpanded] = useState<boolean>(false)

  const [toggleOpenInEditMode, setToggleOpenInEditMode] = useState<{ isOpen: boolean; snippet?: string }>({
    isOpen: openInEditMode,
    snippet: "",
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [testResponse, setTestResponse] = useState<any>("")

  // state to handle the current mode
  const [mode, setMode] = useState<{ create: boolean; edit: boolean }>({
    create: isNewScriptTriggered,
    edit: openInEditMode,
  })

  const [scriptHash, setScriptHash] = useState<string>("")
  const [requirementsHash, setRequirementsHash] = useState<string>(sha256("").toString())
  const [requirementsDataFiles, setRequirementsDataFiles] = useState<RequirementsDataFile[]>([])
  const [requirementsFile, setRequirementsFile] = useState<RequirementsDataFile>({
    label: "Choose requirements file",
    value: scriptData?.active_version?.requirements_file,
    uuid: undefined,
  })

  const createScriptMutation = useMutation<
    AxiosResponse<ScriptGroupRetrieve>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    CreateScriptRequest
  >(KonanAPI.createScript, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: async (res: any) => {
      setCurrentScriptUUID(res?.data?.uuid)

      await queryClient.invalidateQueries(["scripts", projectId])
      await queryClient.invalidateQueries(["script", projectId, scriptData?.uuid])
      await queryClient.invalidateQueries("getFileContent")
      NotificationUtils.toast(`Script (${res?.data?.name}) created successfully!`, {
        snackBarVariant: "positive",
      })
      onClose()
      setMode({ create: false, edit: false })
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (response: any) => {
      if (response?.response?.data?.details) {
        NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
          snackBarVariant: "negative",
        })
      } else if (response?.response?.data?.name) {
        NotificationUtils.toast(response?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while creating script, please try again!", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // change script version
  const scriptVersionChangeMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("selectedScriptVersion")
        await queryClient.invalidateQueries(["scriptVersionsList", projectId])
        await queryClient.invalidateQueries(["script", projectId, scriptData?.uuid])
        await queryClient.invalidateQueries(["scripts", projectId])
      },
      onError: async () => {
        NotificationUtils.toast("An error occurred while restoring script version", {
          snackBarVariant: "negative",
        })
      },
    },
  )

  const updateScriptMutation = useMutation<
    AxiosResponse<ScriptGroupRetrieve>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    UpdateScriptRequest
  >(KonanAPI.updateScript, {
    onSuccess: async (res) => {
      setCurrentScriptUUID(res?.data?.uuid)

      await queryClient.invalidateQueries(["scripts", projectId])
      await queryClient.invalidateQueries(["script", projectId, scriptData?.uuid])

      NotificationUtils.toast(`Script (${res?.data?.name}) updated successfully!`, {
        snackBarVariant: "positive",
      })
      onClose()
      setTimeout(() => {
        setMode({ edit: false, create: false })
      }, 300)
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (response: any) => {
      if (response?.response?.data?.details) {
        NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
          snackBarVariant: "negative",
        })
      } else if (response?.response?.data?.name) {
        NotificationUtils.toast(response?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while updating script, please try again!", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  const testScriptMutation = useMutation<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosResponse<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >(KonanAPI.testScript, {
    mutationKey: ["Test-script-mutation", scriptData?.uuid],
    onSuccess: async (res) => {
      NotificationUtils.toast(
        res?.data?.succeeded
          ? `Script tested successfully!`
          : `It seems there's an error in the script, please check logs for info!`,
        {
          snackBarVariant: res?.data?.succeeded ? "positive" : "negative",
        },
      )

      setTestResponse(res?.data)
      // this is the last valid date user can revert to, since testing saves and updates the script
      if (mode.edit) {
        setLastSaved(formik.values)
        setToggleOpenInEditMode({ isOpen: false })
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (response: any) => {
      if (response?.response?.data?.details) {
        NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while testing script, please try again!", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cancelBuildingScriptMutation = useMutation<AxiosResponse, AxiosError, any>(KonanAPI.cancelBuildingScript, {
    mutationKey: ["cancelBuildingScript", scriptData?.uuid, scriptData?.active_version?.version],
    onSuccess: async () => {
      NotificationUtils.toast("Script building aborted successfully!", {
        snackBarVariant: "positive",
      })
      await queryClient.invalidateQueries(["scripts", projectId])
      await queryClient.invalidateQueries(["scriptVersionsList", projectId, scriptData?.uuid, isVersionDialog])
      await queryClient.invalidateQueries(["script", projectId, scriptData?.uuid])
    },
    onError: async () => {
      NotificationUtils.toast("An error occurred while aborting script building", {
        snackBarVariant: "negative",
      })
    },
  })

  const { mutateAsync: uploadScriptAsyncMutation } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      return KonanAPI.uploadDataFile({
        project_uuid: projectId as string,
        file: file,
        type: DataFile.type.SCRIPT,
      })
    },
    {
      mutationKey: "uploadScriptFile",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (response: any) => {
        if (response?.response?.data?.details) {
          NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  const { mutateAsync: uploadRequirementsAsyncMutation } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      return KonanAPI.uploadDataFile({
        project_uuid: projectId as string,
        file: file,
        type: DataFile.type.REQUIREMENTS,
      })
    },
    {
      mutationKey: "uploadRequirementsFile",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (response: any) => {
        if (response?.response?.data?.details) {
          NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  const formik = useFormik({
    validationSchema: Yup.object({ scriptName: Yup.string().required("Script name is required") }),
    initialValues: {
      snippet: StaticAnalysisScript,
      scriptName: scriptData?.name ?? "",
      isDraft: !isWorkFlowInReadMode,
      showDraftBtn: !isWorkFlowInReadMode,
      testFeatures: { "": "" },
      id: uuidv4(),
      requirements: "",
      requirementsFileName: undefined,
    },
    onSubmit: async ({ snippet, scriptName, isDraft, requirements, requirementsFileName }) => {
      const staticAnalysis = isStaticAnalysisValid(snippet)
      if (!staticAnalysis.valid && staticAnalysis.reason) {
        NotificationUtils.toast(`${staticAnalysis.reason}!`, {
          snackBarVariant: "negative",
        })
      } else {
        let scripFiletUUID = undefined
        let requirementsUUID = requirementsFile.uuid

        // check if the file changed before creating new one
        if ((isDuplicate || scriptHash !== sha256(snippet).toString()) && !!snippet) {
          const fileName = `main-${uuidv4()}.py`
          const fileContentType = "text/x-python"
          const finalFile = createFileFromString(snippet, fileName, fileContentType)
          // upload script file first
          const dataFileResponse = await uploadScriptAsyncMutation(finalFile)
          scripFiletUUID = dataFileResponse?.uuid
        }
        if ((isDuplicate || requirementsHash !== sha256(requirements).toString()) && !!requirements) {
          const requirementsContentType = "text/plain"
          // converting string to file object
          const RequirementsFile = createFileFromString(
            requirements,
            requirementsFileName ??
              `requirements_${new Date()
                .toISOString()
                .replace(/[-:T.]/g, "")
                .slice(0, 15)}.txt`,
            requirementsContentType,
          )
          // upload requirements file
          const dataRequirementsResponse = await uploadRequirementsAsyncMutation(RequirementsFile)
          requirementsUUID = dataRequirementsResponse?.uuid
        }
        // check if script exists or not first
        if ((currentScriptUUID || scriptData?.uuid) && !isDuplicate) {
          // update script first, then testing it
          await updateScriptMutation.mutateAsync({
            file: scripFiletUUID,
            name: scriptName,
            project_uuid: projectId as string,
            is_draft: isDraft,
            script_uuid: (scriptData?.uuid ?? currentScriptUUID) as string,
            requirements_file: requirementsUUID,
          })
        } else {
          // create script and then testing it
          await createScriptMutation.mutateAsync({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            file: scripFiletUUID!,
            name: scriptName,
            project_uuid: projectId as string,
            is_draft: isDraft,
            requirements_file: requirementsUUID,
          })
        }
      }
    },
  })

  const shouldEnableScriptContentFetch = Boolean(
    isDuplicate ||
      (!mode.create &&
        !isNewScriptTriggered &&
        !isScriptLoading &&
        isStaticAnalysisValid(formik.values?.snippet).valid &&
        !testScriptMutation.isLoading),
  )

  const shouldUpdateScript = Boolean(
    !isWorkFlowInReadMode && !isNewScriptTriggered && !isScriptLoading && !testScriptMutation.isLoading,
  )

  const {
    isLoading: isFileLoading,
    isRefetching: isFileRefetching,
    isError: isFetchingFileError,
  } = useQuery(
    ["getFileContent", scriptData?.uuid, scriptData?.active_version?.version],
    async () => (await axios(scriptData?.active_version.file as string)).data,
    {
      refetchOnMount: true,
      enabled: shouldEnableScriptContentFetch,
      onSuccess(data: string) {
        if (shouldUpdateScript) {
          formik.setFieldValue("scriptName", scriptData?.name)
          formik.setFieldValue("showDraftBtn", scriptData?.active_version.is_draft)
          formik.setFieldValue("isDraft", scriptData?.active_version.is_draft)
        }

        if (scriptData) {
          formik.setFieldValue("scriptName", `${scriptData?.name}${isDuplicate ? " - Copy" : ""}`)
        }

        if (toggleOpenInEditMode.isOpen) {
          setToggleOpenInEditMode({ isOpen: true, snippet: data })
        }

        formik.setFieldValue("snippet", data)
        setScriptHash(sha256(data).toString())
      },
      onError() {
        console.warn("An error occurred while fetching script!")
      },
    },
  )

  // fetch requirements file
  const { isLoading: isRequirementsFileLoading } = useQuery(
    ["getFileContent", requirementsFile.value],
    async () => (await axios(requirementsFile.value as string)).data,
    {
      refetchOnMount: true,
      onSuccess(data: string) {
        formik.setFieldValue("requirements", data)
        setRequirementsHash(sha256(data).toString())
      },
      onError() {
        setRequirementsHash(sha256("").toString())
        console.warn("An error occurred while fetching requirements file!")
      },
      enabled: !!requirementsFile.value,
    },
  )

  // fetch requirements files
  const { isLoading: isRequirementsDataFilesLoading } = useQuery(
    ["requirementsDataFiles", projectId],
    () => KonanAPI.fetchDataFiles(projectId as string, DataFile.type.REQUIREMENTS),
    {
      enabled: !!projectId,
      onSuccess(data: AxiosResponse<Array<DataFile>>) {
        if (data?.data?.length > 0) {
          setRequirementsDataFiles(
            data.data.map((item, index) => ({
              label: item.name,
              value: item.data_file,
              uuid: item.uuid,
              createdAt: item.created_at,
              index,
            })),
          )
        }
      },
    },
  )

  // saving snapshot of formik before editing
  const [lastSaved, setLastSaved] = React.useState(formik.values)

  // handler for Run test button
  const handleRunTestClicked = async (): Promise<void> => {
    testScriptMutation.mutateAsync({
      project_uuid: projectId as string,
      script_uuid: scriptData?.uuid,
      features: formik.values.testFeatures,
      version: isVersionDialog ? scriptData?.active_version.version : undefined,
    })
  }

  const handleCancelBuildingScript = async (): Promise<void> => {
    cancelBuildingScriptMutation.mutateAsync({
      project_uuid: projectId as string,
      script_uuid: scriptData?.uuid,
      version: scriptData?.active_version?.version,
    })
  }

  const isScriptInViewMode = !mode.create && !mode.edit

  const isBuildingState =
    scriptData?.active_version?.status === ScriptVersionRetrieve.status.RUNNING ||
    scriptData?.active_version?.status === ScriptVersionRetrieve.status.PENDING

  // should disable buttons booleans

  const shouldRunTestButtonDisabled =
    scriptHash !== sha256(formik.values.snippet).toString() ||
    requirementsHash !== sha256(formik.values.requirements).toString() ||
    !!requirementsFile.uuid ||
    isRequirementsFileLoading ||
    isFileLoading ||
    isScriptLoading ||
    testScriptMutation.isLoading ||
    createScriptMutation.isLoading ||
    updateScriptMutation.isLoading

  const shouldEditButtonDisabled =
    formik.isSubmitting ||
    testScriptMutation.isLoading ||
    isScriptLoading ||
    isFileLoading ||
    isRequirementsFileLoading ||
    isBuildingState

  // [TODO]: disable restore when status is pending or cancelled
  const shouldRestoreButtonDisabled =
    testScriptMutation.isLoading || isScriptLoading || scriptVersionChangeMutation.isLoading

  const shouldVersionHistoryButtonDisabled =
    formik.isSubmitting || testScriptMutation.isLoading || isScriptLoading || isFileLoading || isRequirementsFileLoading

  const shouldDisableCancelButton =
    createScriptMutation.isLoading ||
    updateScriptMutation.isLoading ||
    formik.isSubmitting ||
    testScriptMutation.isLoading

  const shouldDisableSaveAsDraftButton =
    createScriptMutation.isLoading ||
    updateScriptMutation.isLoading ||
    formik.isSubmitting ||
    testScriptMutation.isLoading

  const shouldDisableSaveButton =
    !isDuplicate &&
    ((scriptHash === sha256(formik.values.snippet).toString() &&
      requirementsHash === sha256(formik.values.requirements).toString() &&
      scriptData?.name === formik.values.scriptName &&
      !requirementsFile.uuid) ||
      createScriptMutation.isLoading ||
      updateScriptMutation.isLoading ||
      formik.isSubmitting ||
      testScriptMutation.isLoading)

  const handleCancelClicked = async (): Promise<void> => {
    setMode({ edit: false, create: false })

    // to clear requirement file selected
    setRequirementsFile({
      label: "Choose requirements file",
      value: scriptData?.active_version?.requirements_file,
      uuid: undefined,
    })

    if (toggleOpenInEditMode.isOpen) {
      formik.setFieldValue("scriptName", scriptData?.name)
      formik.setFieldValue("snippet", toggleOpenInEditMode?.snippet)
      setToggleOpenInEditMode({ isOpen: false })
    } else {
      setTimeout(() => {
        formik.setValues(lastSaved)
        formik.setFieldValue("scriptName", scriptData?.name ?? lastSaved.scriptName)
      }, 10)
    }
  }

  const extractedConsoleData = useMemo((): string[] => {
    if (testScriptMutation.isError) return ["/* Failed to execute script */"]
    else if (testResponse && !testResponse?.succeeded)
      return ["/* It seems there's an error in the script, please check logs for info */"]
    else if (testResponse?.succeeded && testResponse?.output) return [JSON.stringify(testResponse?.output)]
    else return ["// run test to see results"]
  }, [testResponse, testScriptMutation.isError])

  // used to update formik script name once script request finishes
  useEffect(() => {
    scriptData?.name &&
      scriptData?.name !== formik.values.scriptName &&
      formik.setFieldValue("scriptName", scriptData?.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptData?.name])

  // Handle if a requirements file already exists for this script.
  useEffect(() => {
    setRequirementsFile({
      label: "Choose requirements file",
      value: scriptData?.active_version?.requirements_file,
      uuid: undefined,
    })
  }, [scriptData?.active_version?.requirements_file])

  const getComponentForTab = (tab: ScriptTab): React.ReactElement => {
    switch (tab) {
      case ScriptTab.ScriptCode:
        return (
          <CustomScriptEditor
            isLoading={isBuildingState ? cancelBuildingScriptMutation.isLoading : isFileLoading || isScriptLoading}
            isRefetchingFile={isFileRefetching}
            script={scriptData}
            isReadMode={isScriptInViewMode}
            formik={formik}
            consoleLogs={extractedConsoleData}
            isFetchingFileError={isFetchingFileError}
            logs={testResponse?.logs}
            isConsoleExpanded={isConsoleExpanded}
            setIsConsoleExpanded={() => setIsConsoleExpanded(!isConsoleExpanded)}
            setIsLogsExpanded={() => setIsLogsExpanded(!isLogsExpanded)}
            isLogsExpanded={isLogsExpanded}
            onActionButtonClick={isBuildingState ? handleCancelBuildingScript : handleRunTestClicked}
            isActionButtonDisabled={
              isBuildingState ? cancelBuildingScriptMutation.isLoading : shouldRunTestButtonDisabled
            }
            isBuildingState={isBuildingState}
          />
        )
      case ScriptTab.RequirementsPackages:
        return (
          <CustomRequirementsPackages
            isLoading={isRequirementsFileLoading}
            formik={formik}
            isReadMode={isScriptInViewMode}
            options={requirementsDataFiles}
            isLoadingOptions={isRequirementsDataFilesLoading}
            setRequirementsFile={setRequirementsFile}
            requirementsFile={requirementsFile}
          />
        )
      case ScriptTab.TestFeatures:
        return <CustomScriptTestFeatures formik={formik} />
      case ScriptTab.Logs:
        return <CustomScriptLogs uuid={scriptData?.uuid as string} />
      default:
        return <CustomScriptLogs uuid={scriptData?.uuid as string} />
    }
  }

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} p={2} pb={0.5} style={{ backgroundColor: "var(--grayscale-background-1)" }}>
        <Grid container item xs={12} flexDirection="row" alignItems="center" justifyContent="space-between">
          <Grid item className={styles.scriptSideBar}>
            <CodeIcon sx={{ color: "var(--grayscale-text-1)" }} />
          </Grid>

          <Grid item xs container direction="column" gap={1}>
            {/* Dialog title */}
            <Grid item>
              <Typography variant="label" variantColor={2}>
                Script
              </Typography>
            </Grid>

            <Grid container item xs mt={mode.create || mode.edit ? "-7px" : "4px"}>
              {isScriptInViewMode && !scriptData?.name && isScriptLoading ? (
                <Grid item mt={1}>
                  <Skeleton width={"60%"} height={18} />
                </Grid>
              ) : isScriptInViewMode && (scriptData?.name || formik.values.scriptName) ? (
                <Typography variant="h3-bold">{scriptData?.name ?? formik.values.scriptName}</Typography>
              ) : (
                <InputText
                  hideDescription
                  key={formik.values.id}
                  disabled={formik.isSubmitting || testScriptMutation.isLoading}
                  type="text"
                  handleChange={formik.handleChange}
                  value={formik.values.scriptName}
                  id="scriptName"
                  widthCalculator
                  placeholder="Script Name"
                  error={formik.touched.scriptName && Boolean(formik.errors.scriptName) && formik.errors.scriptName}
                />
              )}
            </Grid>
          </Grid>

          {renderView !== "workflow" && (
            <Grid container item xs mt={1} height="fit-content" display={"flex"} justifyContent="flex-end">
              {isScriptInViewMode ? (
                <Fragment>
                  {!isVersionDialog ? (
                    <Fragment>
                      <Button
                        style={{ marginRight: "8px" }}
                        onClick={setIsVersionDialog}
                        disabled={shouldVersionHistoryButtonDisabled}
                        variant="secondary"
                        size="regular"
                      >
                        History
                      </Button>

                      <Button
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          setMode({ create: false, edit: true })
                          setTimeout(() => {
                            setLastSaved(formik.values)
                          }, 10)
                        }}
                        disabled={shouldEditButtonDisabled}
                        variant="secondary"
                        size="regular"
                      >
                        Edit
                      </Button>
                    </Fragment>
                  ) : (
                    !scriptData?.active_version?.is_active_version && (
                      <Button
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          scriptVersionChangeMutation.mutateAsync({
                            projectUUID: projectId as string,
                            resource: "scripts",
                            version: scriptData?.active_version.version as string,
                            resourceUUID: scriptData?.uuid as string,
                          })
                        }}
                        disabled={shouldRestoreButtonDisabled}
                        variant="secondary"
                        size="regular"
                      >
                        Restore
                      </Button>
                    )
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {mode.edit && !isNewScriptTriggered && (
                    <Button
                      style={{ marginRight: "8px" }}
                      disabled={shouldDisableCancelButton}
                      onClick={handleCancelClicked}
                      variant="secondary"
                      size="regular"
                    >
                      cancel
                    </Button>
                  )}

                  {formik.values.showDraftBtn && (
                    <Button
                      style={{ marginRight: "8px" }}
                      onClick={() => {
                        formik.setFieldValue("isDraft", true)
                        formik.submitForm()
                      }}
                      disabled={shouldDisableSaveAsDraftButton}
                      variant="secondary"
                      size="regular"
                      isLoading={
                        (createScriptMutation.isLoading || updateScriptMutation.isLoading) && formik.values.isDraft
                      }
                    >
                      {"Save as draft"}
                    </Button>
                  )}
                </Fragment>
              )}

              {(mode.create || mode.edit) && (
                <Button
                  disabled={shouldDisableSaveButton}
                  onClick={() => {
                    formik.setFieldValue("isDraft", false)
                    formik.submitForm()
                  }}
                  variant="primary"
                  size="regular"
                  isLoading={createScriptMutation.isLoading || updateScriptMutation.isLoading}
                >
                  {"Save"}
                </Button>
              )}
            </Grid>
          )}
        </Grid>

        <Grid container item xs={12} mt={1}>
          <Tabs value={selectedState}>
            {[
              ScriptTab.ScriptCode,
              ScriptTab.RequirementsPackages,
              ScriptTab.TestFeatures,
              ...(isNewScriptTriggered ? [] : [ScriptTab.Logs]),
            ].map((label) => (
              <Tab
                key={label}
                label={label}
                value={label}
                selected={label === selectedState}
                onClick={() => setSelectedState(label as ScriptTab)}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      {getComponentForTab(selectedState)}
    </Grid>
  )
}

export function ScriptDialog(props: Readonly<ScriptDialogProps>): React.ReactElement {
  const { isOpen, onClose, scriptId, isDuplicate } = props
  const { id: projectId } = useParams<{ id: string }>()

  const MuiTheme = useMuiTheme()

  const [isVersionDialog, setIsVersionDialog] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>()

  // fetch Script
  const { data: scriptData, isLoading: isScriptLoading } = useQuery<AxiosResponse<ScriptGroupRetrieve>, AxiosError>(
    ["script", projectId, scriptId],
    () => KonanAPI.fetchScript({ projectUUID: projectId as string, scriptUUID: scriptId as string }),
    {
      onSuccess: ({ data }) => {
        setSelectedVersion(data.active_version.version)
      },
      enabled: !!projectId && !!scriptId && !isVersionDialog,
    },
  )

  // list Versions
  const {
    isLoading: isVersionsListLoading,
    isFetchingNextPage,
    data: versionsList,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AxiosResponse<PaginatedScriptVersionList>, AxiosError>(
    ["scriptVersionsList", projectId, scriptId, isVersionDialog],
    ({ pageParam = 1 }) =>
      KonanAPI.listScriptVersions({
        projectUUID: projectId as string,
        scriptUUID: scriptId as string,
        page_size: 10,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },
      enabled: !!projectId && !!scriptId && isVersionDialog,
      refetchOnMount: true,
    },
  )

  const { ref: lastVersionRef, inView } = useInView()

  // extracting the results after infinite-Query
  const adjustedScriptVersions = useMemo(() => {
    return (
      versionsList?.pages.reduce(
        (accumulator, page) => accumulator.concat(page.data.results as ScriptVersion[]),
        [] as ScriptVersion[],
      ) ?? []
    )
  }, [versionsList?.pages])

  // fetch Version
  const { data: scriptVersionData, isLoading: isScriptVersionDataLoading } = useQuery<
    AxiosResponse<ScriptVersionRetrieve>,
    AxiosError
  >(
    ["selectedScriptVersion", projectId, scriptId, selectedVersion, isVersionDialog],
    () =>
      KonanAPI.retrieveScriptVersion({
        projectUUID: projectId as string,
        scriptUUID: scriptId as string,
        version: selectedVersion as string,
      }),
    {
      enabled: !!projectId && !!scriptId && isVersionDialog && !!selectedVersion,
    },
  )

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  return (
    <Dialog
      open={isOpen}
      sx={{
        "& .MuiPaper-root": {
          height: "628px",
        },
      }}
      fullWidth
      maxWidth={isVersionDialog ? "lg" : "md"}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      onClose={onClose}
    >
      <DialogTitle className="dialog-header-base" style={{ display: "flex", justifyContent: "space-between" }}>
        <Box display="flex" gap={1.5}>
          {!!scriptData?.data.name && !isDuplicate ? (
            <Typography variant="h2-bold">{scriptData?.data.name}</Typography>
          ) : isScriptLoading ? (
            <Skeleton variant="rectangular" height={30} width={120} />
          ) : (
            <Typography variant="h2-bold">Script</Typography>
          )}
          {scriptData?.data.active_version.version && !isDuplicate && (
            <Tag size="regular">{scriptData?.data.active_version.version}</Tag>
          )}
          {scriptData?.data.active_version.status && !isDuplicate && (
            <Tag
              size="regular"
              variant={StatusColorMap[scriptData?.data.active_version.status as keyof typeof StatusColorMap]}
            >
              {StatusTextMap[scriptData?.data.active_version.status as keyof typeof StatusTextMap]}
            </Tag>
          )}
        </Box>

        <IconButton onClick={onClose} size="small" className={"close-icon-button"}>
          <CloseIcon style={{ color: "var(--grayscale-text-2)" }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.scriptDialogContent}>
        {!isVersionDialog ? (
          <SingleScript
            {...props}
            isVersionDialog={isVersionDialog}
            setIsVersionDialog={() => setIsVersionDialog(true)}
            scriptData={scriptData?.data as ScriptGroupRetrieve}
            isScriptLoading={isScriptLoading}
          />
        ) : (
          <Grid container height="100%">
            <Grid item width={"calc(100% - 300px)"} id="single-script" className={styles.mainScriptContainer}>
              <SingleScript
                {...props}
                isVersionDialog={isVersionDialog}
                setIsVersionDialog={() => setIsVersionDialog(true)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                scriptData={{ ...scriptData?.data, active_version: scriptVersionData?.data as ScriptVersionRetrieve }}
                isScriptLoading={isScriptVersionDataLoading}
              />
            </Grid>

            <Grid item xs={3} minWidth={"300px"}>
              <VersionHistory
                height={520}
                totalCount={versionsList?.pages[0]?.data?.count}
                lastVersionRef={lastVersionRef}
                onClose={() => setIsVersionDialog(false)}
                versions={adjustedScriptVersions ?? []}
                selectedVersion={selectedVersion ?? ""}
                setSelectedVersion={setSelectedVersion}
                isLoading={isVersionsListLoading}
                isScriptView
                activeVersion={scriptData?.data.active_version as ScriptVersionRetrieve}
                infiniteScrollProps={{
                  hasNextPage,
                  fetchNextPage,
                  isFetchingNextPage,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}
