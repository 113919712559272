import React, { useEffect, useMemo, useState } from "react"

import InfiniteScroll from "react-infinite-scroller"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Box, CircularProgress } from "@mui/material"
import Grid from "@mui/material/Grid"
import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { KonanEmptyState, KonanEmptyStateSearch } from "../../../../components/KonanEmptyState"
import { KonanTabsHeader } from "../../../../components/KonanTabsHeader"
import { KonanAPI } from "../../../../services/KonanAPI"
import { TrainingDataUpload } from "../../../../types/generated/api/TrainingDataUpload"
import { mapResponseTypesToTabValues } from "../../../../utils/deploymentDetailsHelpers"
import { searchListByName, sortList } from "../../../../utils/searchSortFilterHelpers"
import { UploadCSVDialog } from "./components/UploadCSVDialog"
import { ProcessingFailedCard } from "./components/components/ProcessingFailedCard"
import { ProcessingFailedLoader } from "./components/components/ProcessingFailedLoader"
import { TrainingDataCard } from "./components/components/TrainingDataCard"
import { TrainingDataLoader } from "./components/components/TrainingDataLoader"

import styles from "./TrainingData.module.scss"

type ParamsType = {
  id: string
}

export function TrainingData(): React.ReactElement {
  const { id: projectUUID } = useParams<ParamsType>()
  const [searchValue, setSearchValue] = useState<string>("")
  const [sortValue, setSortValue] = useState<string>("Most Recent")
  const [sortValueFailed, setSortValueFailed] = useState<string>("Most Recent")
  const [sortValueInProgress, setSortValueInProgress] = useState<string>("Most Recent")
  const [searchValueFailed, setSearchValueFailed] = useState<string>("")
  const [searchValueInProgress, setSearchValueInProgress] = useState<string>("")

  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 6

  //fetch all training data on a project level
  const { isLoading: isTrainingDataLoading, data: trainingData } = useQuery<TrainingDataUpload[], AxiosError>(
    ["training-data", projectUUID],
    () => KonanAPI.fetchTrainingData(projectUUID as string),
    { enabled: !!projectUUID },
  )

  const [uploadCSVDialogOpen, setUploadCSVDialogOpen] = React.useState(false)
  const [dialogKey, setDialogKey] = React.useState(0)
  const [uploadedDataUUID, setUploadedDataUUID] = React.useState("")
  const [tabValue, setTabValue] = useState<string>("success")

  const succeededTranininData = trainingData?.filter((item) => item?.status === "success")
  const failedTrainingData = trainingData?.filter((item) => item?.status === "error")
  const processingTrainingData = trainingData?.filter(
    (item) => item?.status === "processing" || item?.status === "pending",
  )
  const handleUploadCSVDialogOpen = (): void => {
    setUploadCSVDialogOpen(true)
    setDialogKey((dialogKey + 1) % 10)
  }
  const handleUploadCSVDialogClose = (): void => {
    setUploadCSVDialogOpen(false)
  }

  const adjustedTrainings = useMemo((): TrainingDataUpload[] => {
    let adjustedTrainings = succeededTranininData || []
    if (searchValue && searchValue.length > 0)
      adjustedTrainings = adjustedTrainings ? searchListByName(adjustedTrainings, searchValue) : []

    if (sortValue && sortValue.length > 0)
      adjustedTrainings = adjustedTrainings ? sortList(adjustedTrainings, sortValue, "training-data") : []

    return adjustedTrainings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [succeededTranininData, searchValue, sortValue, trainingData?.length])

  const adjustedFailedTrainings = useMemo((): TrainingDataUpload[] => {
    let adjustedTrainings = failedTrainingData || []
    if (searchValueFailed && searchValueFailed.length > 0)
      adjustedTrainings = adjustedTrainings ? searchListByName(adjustedTrainings, searchValueFailed) : []
    if (sortValueFailed && sortValueFailed.length > 0)
      adjustedTrainings = adjustedTrainings ? sortList(adjustedTrainings, sortValueFailed, "training-data") : []

    return adjustedTrainings
  }, [failedTrainingData, searchValueFailed, sortValueFailed])

  const adjustedInProgressTrainings = useMemo((): TrainingDataUpload[] => {
    let adjustedTrainings = processingTrainingData || []
    if (searchValueInProgress && searchValueInProgress.length > 0)
      adjustedTrainings = adjustedTrainings ? searchListByName(adjustedTrainings, searchValueInProgress) : []
    if (sortValueInProgress && sortValueInProgress.length > 0)
      adjustedTrainings = adjustedTrainings ? sortList(adjustedTrainings, sortValueInProgress, "training-data") : []

    return adjustedTrainings
  }, [processingTrainingData, searchValueInProgress, sortValueInProgress])

  useEffect(() => {
    if (uploadedDataUUID) {
      const filtered = trainingData?.filter((train) => train?.uuid === uploadedDataUUID)
      if (filtered?.length) {
        setTabValue(mapResponseTypesToTabValues(filtered[0]?.status))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedDataUUID])

  const loadMoreTrainingData = (): void => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [tabValue])

  return (
    <React.Fragment>
      <UploadCSVDialog
        projectUUID={projectUUID as string}
        key={dialogKey}
        open={uploadCSVDialogOpen}
        onDialogClose={handleUploadCSVDialogClose}
        setOpen={setUploadCSVDialogOpen}
        onUploadSuccess={(trainingName: string, trainingUUID: string) => {
          setUploadedDataUUID(trainingUUID)
          NotificationUtils.toast(
            `Successfully uploaded ${trainingName ? `<${trainingName}>` : "training data"} and is now being processed`,
            {
              snackBarVariant: "positive",
            },
          )
        }}
        onUploadError={(trainingName: string) =>
          NotificationUtils.toast(
            `Error occurred while uploading ${trainingName ? `<${trainingName}>` : "training data"}`,
            {
              snackBarVariant: "negative",
            },
          )
        }
        onUploadAbort={(trainingName: string) =>
          NotificationUtils.toast(`${trainingName ? `<${trainingName}>` : "training data"} has been aborted`, {
            snackBarVariant: "negative",
          })
        }
      />

      <Grid container item mt={3}>
        <KonanTabsHeader
          title="Upload Dataset"
          setAction={setUploadCSVDialogOpen}
          containerType={"Training Data"}
          setTabValue={setTabValue}
          value={tabValue}
          searchValue={
            tabValue === "success" ? searchValue : tabValue === "failed" ? searchValueFailed : searchValueInProgress
          }
          setSearchValue={
            tabValue === "success"
              ? setSearchValue
              : tabValue === "failed"
                ? setSearchValueFailed
                : setSearchValueInProgress
          }
          sortValue={tabValue === "success" ? sortValue : tabValue === "failed" ? sortValueFailed : sortValueInProgress}
          setSortValue={
            tabValue === "success" ? setSortValue : tabValue === "failed" ? setSortValueFailed : setSortValueInProgress
          }
          sortOptions={["Most Recent", "Name"]}
        />
      </Grid>
      <Grid container spacing={2}>
        {isTrainingDataLoading &&
          tabValue === "success" &&
          [1, 2].map((item: number) => (
            <Grid item xs={12} md={6} lg={4} key={item}>
              <TrainingDataLoader />
            </Grid>
          ))}
        {isTrainingDataLoading &&
          (tabValue === "inProgress" || tabValue === "failed") &&
          [1, 2].map((item: number) => (
            <Grid item xs={12} md={6} lg={4} key={item}>
              <ProcessingFailedLoader />
            </Grid>
          ))}
      </Grid>
      <Box mt={2} />
      <InfiniteScroll
        pageStart={currentPage}
        loadMore={loadMoreTrainingData}
        hasMore={
          (tabValue === "success" && adjustedTrainings && adjustedTrainings.length) >= currentPage * pageSize ||
          (tabValue === "failed" && adjustedFailedTrainings && adjustedFailedTrainings.length) >=
            currentPage * pageSize ||
          (tabValue === "inProgress" && adjustedInProgressTrainings && adjustedInProgressTrainings.length) >=
            currentPage * pageSize
        }
      >
        <Grid container spacing={2}>
          {tabValue === "inProgress" &&
            !isTrainingDataLoading &&
            adjustedInProgressTrainings &&
            adjustedInProgressTrainings?.length > 0 &&
            adjustedInProgressTrainings?.slice(0, currentPage * pageSize).map((item: TrainingDataUpload) => (
              <Grid item key={item.uuid} xs={12} md={4} lg={4}>
                <ProcessingFailedCard
                  trainingDataUUID={item?.uuid}
                  createdAt={item?.created_at}
                  createdBy={item?.created_by}
                  name={item?.name}
                  type="Processing"
                />
              </Grid>
            ))}
          {!isTrainingDataLoading &&
            tabValue === "inProgress" &&
            adjustedInProgressTrainings?.length === 0 &&
            processingTrainingData &&
            processingTrainingData?.length > 0 && (
              <Grid container item xs={12} className={styles.emptySearch}>
                <KonanEmptyStateSearch title={searchValueInProgress} />
              </Grid>
            )}
          {!isTrainingDataLoading && tabValue === "inProgress" && processingTrainingData?.length === 0 && (
            <Grid container xs={12} item className={"empty-container"}>
              <KonanEmptyState
                title="No training data in progress"
                buttonText="Upload Dataset"
                subTitle="Can't find what you're looking for? Check the success or failed tabs"
                setAction={handleUploadCSVDialogOpen}
              />
            </Grid>
          )}
          {tabValue === "success" &&
            !isTrainingDataLoading &&
            trainingData &&
            adjustedTrainings &&
            adjustedTrainings?.length > 0 &&
            adjustedTrainings?.slice(0, currentPage * pageSize).map((item: TrainingDataUpload) => (
              <Grid item key={item.uuid} xs={12} md={6} lg={4}>
                <TrainingDataCard
                  trainingDataUUID={item?.uuid}
                  createdAt={item?.created_at}
                  createdBy={item?.created_by}
                  name={item?.name}
                  models={item?.models}
                  status={item?.status}
                  isValid={item?.is_valid}
                  isLoading={isTrainingDataLoading}
                  uuid={item?.uuid}
                />
              </Grid>
            ))}
          {!isTrainingDataLoading &&
            tabValue === "success" &&
            adjustedTrainings?.length === 0 &&
            succeededTranininData &&
            succeededTranininData?.length > 0 && (
              <Grid className={styles.emptySearch} direction="column" container xs={12} item>
                <KonanEmptyStateSearch title={searchValue} />
              </Grid>
            )}
          {!isTrainingDataLoading && tabValue === "success" && trainingData && succeededTranininData?.length === 0 && (
            <Grid container item xs={12} className={"empty-container"}>
              <KonanEmptyState
                title="No successful training data to show"
                buttonText="Upload Dataset"
                subTitle="Match the required schema and upload your dataset. Must be .csv and under 2GB"
                setAction={handleUploadCSVDialogOpen}
              />
            </Grid>
          )}
          {tabValue === "failed" &&
            !isTrainingDataLoading &&
            adjustedFailedTrainings &&
            adjustedFailedTrainings?.length > 0 &&
            adjustedFailedTrainings?.slice(0, currentPage * pageSize).map((item: TrainingDataUpload) => (
              <Grid item key={item.uuid} xs={12} md={4} lg={4}>
                <ProcessingFailedCard
                  trainingUuid={item?.uuid}
                  createdAt={item?.created_at}
                  createdBy={item?.created_by}
                  name={item?.name}
                  type="Failed"
                />
              </Grid>
            ))}
          {!isTrainingDataLoading &&
            tabValue === "failed" &&
            adjustedFailedTrainings?.length === 0 &&
            failedTrainingData &&
            failedTrainingData?.length > 0 && (
              <Grid container item xs={12} className={styles.emptySearch}>
                <KonanEmptyStateSearch title={searchValueFailed} />
              </Grid>
            )}
          {!isTrainingDataLoading && tabValue === "failed" && failedTrainingData?.length === 0 && (
            <Grid container xs={12} item className={"empty-container"}>
              <KonanEmptyState
                title="No successful training data to show"
                buttonText="Upload Dataset"
                subTitle="Can't find what you're looking for? Check the success or in progress tabs"
                setAction={handleUploadCSVDialogOpen}
              />
            </Grid>
          )}
        </Grid>
        {(tabValue === "success" && adjustedTrainings && adjustedTrainings.length) >= currentPage * pageSize ||
        (tabValue === "failed" && adjustedFailedTrainings && adjustedFailedTrainings.length) >=
          currentPage * pageSize ||
        (tabValue === "inProgress" && adjustedInProgressTrainings && adjustedInProgressTrainings.length) >=
          currentPage * pageSize ? (
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Box mt={3} />
            <CircularProgress size={36} />
            <Box mt={1} />
          </Grid>
        ) : (
          ""
        )}
      </InfiniteScroll>
    </React.Fragment>
  )
}
