import React, { Fragment, useState } from "react"

import { useMutation } from "react-query"
import { useParams } from "react-router-dom"

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, Grid, IconButton, SvgIcon } from "@mui/material"
import { Menu, MenuItem, NotificationUtils, Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import moment from "moment"

import { queryClient } from "../../../../../.."
import { KonanAvatar } from "../../../../../../components/Avatar"
import { BaseSimpleDialog } from "../../../../../../components/dialogs/BaseSimpleDialog"
import { KonanAPI } from "../../../../../../services/KonanAPI"

import styles from "../../TrainingData.module.scss"

type ProcessingProps = {
  name: string
  createdAt: string
  createdBy: string
  trainingDataUUID: string
  type: "Failed" | "Processing"
}
type ParamType = {
  id: string
}

export function ProcessingFailedCard(props: Readonly<ProcessingProps>): React.ReactElement {
  const { name, createdAt, createdBy, trainingDataUUID, type } = props
  const { id: projectId } = useParams<ParamType>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [deleteTrainingDataDialogOpen, setDeleteTrainingDataDialogOpen] = useState<boolean>(false)

  const deleteTrainingDataMutation = useMutation<AxiosResponse, AxiosError>(
    () => KonanAPI.deleteTrainingDataFromProject(projectId as string, trainingDataUUID),
    {
      onSuccess: async () => {
        // Invalidate react-query queries
        await queryClient.invalidateQueries(["training-data", projectId])

        NotificationUtils.toast(`Successfully deleted ${name ? `<${name}>` : `training data`}`, {
          snackBarVariant: "positive",
        })
      },
      onError: () => {
        NotificationUtils.toast(`An error occurred when attempting to delete ${name ? `<${name}>` : `training data`}`, {
          snackBarVariant: "negative",
        })
      },
    },
  )

  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Fragment>
      {deleteTrainingDataDialogOpen && (
        <BaseSimpleDialog
          open={deleteTrainingDataDialogOpen}
          isLoading={deleteTrainingDataMutation.isLoading}
          name={name}
          onAccept={() => deleteTrainingDataMutation.mutateAsync()}
          onClose={() => setDeleteTrainingDataDialogOpen(false)}
          mode={"training-data-deletion"}
        />
      )}

      <Card className="card-box-shadow">
        <Grid container direction="column" className={styles.failedProcessingCard}>
          <Grid item xs={12} direction="row" justifyContent="space-between" container alignItems="flex-start">
            <Grid item xs={10}>
              <Tag
                size="small"
                variant={type === "Processing" ? "warning" : "negative"}
                onResize={undefined}
                onResizeCapture={undefined}
              >
                {type}
              </Tag>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end" alignItems="flex-start">
              <IconButton aria-label="settings" onClick={handleClick} size="small" className={styles.headerButton}>
                <MoreHorizIcon />
              </IconButton>
              <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} menuMaxContent>
                <MenuItem
                  onClick={() => {
                    setDeleteTrainingDataDialogOpen(true)
                    handleClose()
                  }}
                >
                  <Typography className={styles.menuItemDelete} variant="label">
                    Delete Training data
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3-bold" noWrap className={styles.failedProcessingText}>
              {name}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid
              container
              item
              spacing={1}
              xs={12}
              alignItems="flex-start"
              className={styles.trainingDataAvatarContainer}
            >
              <Grid item sx={{ marginTop: "2px" }}>
                {/* SvgIcon is used to fix square avatars on safari */}
                <SvgIcon className={styles.avatar}>
                  <KonanAvatar size={24} name={createdBy} />
                </SvgIcon>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="label" noWrap>
                  {createdBy}
                </Typography>

                <Typography variant="label" noWrap>
                  <Tooltip title={format(new Date(createdAt), "dd/MM/yyyy, p")} placement="right">
                    <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                      {moment(new Date(createdAt)).fromNow()}
                    </Typography>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
