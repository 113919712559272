import React from "react"

import { Box } from "@mui/material"
import { ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../../../hooks/UseTheme"
import { NivoTheme } from "../../../../../themes/nivoTheme"

type NumericalType = {
  low_value: number
  high_value: number
  sample_count: number
}

type Props = {
  data: NumericalType[]
  graphHeight: number
}

// Variable to hold the rightmost edge of the previous bar. Used in the CustomTick component
let prevX = 0

export function NumericalHistogram(props: Readonly<Props>): React.ReactElement {
  const { data, graphHeight } = props

  const theme = getTheme()

  // Adapted from https://nivo.rocks/storybook/?path=/story/bar--custom-axis-ticks
  const CustomTick = (tick: { x: number; y: number; tickIndex: number; value: number }): React.ReactElement => {
    // Reset prevX if this is the first tick in the graph. Set to a value greater than 0 because bar graph begins at
    // a value greater than 0
    if (tick.tickIndex === 0) {
      prevX = 2
    }
    // Calculate the new offset, this is the distance between the middle tick (tick.x) and the edge of the previous bar (prevX)
    const offset = tick.x - prevX

    // Calculate the new position of the tick. This is the distance between the middle tick (tick.x) and its offset from the previous bar
    const newX = tick.x - offset

    // Calculate the right edge of the current bar.
    prevX = tick.x + offset

    return (
      <g transform={`translate(${newX},${tick.y + 22})`}>
        {/*<line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} />*/}
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          fill={theme.palette.grayscale.text[2]}
          style={{
            fontFamily: '"Inter", sans-serif',
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "16px",
          }}
        >
          {(Math.round(tick.value * 100) / 100).toFixed(1)}
        </text>
      </g>
    )
  }

  // Add an additional dummy value to the data array.
  // Needed to be able to add the final x axis tick to the graph
  const adjustedData: NumericalType[] = React.useMemo(() => {
    const newData = Array.from(data)
    newData.push({ low_value: data[data.length - 1].high_value, high_value: 0, sample_count: 0 })
    return newData
  }, [data])

  return (
    <Box style={{ height: graphHeight }}>
      <ResponsiveBar
        data={adjustedData}
        theme={NivoTheme(theme)}
        indexBy="low_value"
        keys={["sample_count"]}
        colors={["#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b"]}
        colorBy="indexValue"
        margin={{ top: 20, right: -30, bottom: 25, left: 70 }}
        borderWidth={1}
        padding={adjustedData.length < 3 ? 0.9 : 0}
        valueScale={{ type: "linear" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          renderTick: CustomTick,
          format: (v) => (v % 1 === 0 ? v : (Math.round(v * 100) / 100).toFixed(1)),
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "Count",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        valueFormat={(v) => v.toFixed(0)}
        enableLabel={true}
        enableGridY={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        tooltip={(slice) => {
          return (
            <div className={"chart-tooltip"}>
              <Typography variant="h3-bold" variantColor={2}>
                {(Math.round(slice.data.low_value !== undefined ? slice.data.low_value * 100 : 0) / 100).toFixed(1) +
                  " to " +
                  (Math.round(slice.data.high_value !== undefined ? slice.data.high_value * 100 : 0) / 100).toFixed(1)}
              </Typography>
              <Typography variant="p" variantColor={2}>
                {`Count: ${slice.data.sample_count.toFixed(0)}`}
              </Typography>
            </div>
          )
        }}
      />
    </Box>
  )
}
