import React, { forwardRef } from "react"

import AddBox from "@mui/icons-material/AddBox"
import ArrowDownward from "@mui/icons-material/ArrowDownward"
import Check from "@mui/icons-material/Check"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import Clear from "@mui/icons-material/Clear"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import Edit from "@mui/icons-material/Edit"
import FilterList from "@mui/icons-material/FilterList"
import FirstPage from "@mui/icons-material/FirstPage"
import LastPage from "@mui/icons-material/LastPage"
import Remove from "@mui/icons-material/Remove"
import SaveAlt from "@mui/icons-material/SaveAlt"
import Search from "@mui/icons-material/Search"
import ViewColumn from "@mui/icons-material/ViewColumn"

// Adapted from https://github.com/mbrn/material-table/issues/2391 to fix the issue
// where importing ResponsiveBar from @nivo/bar would cause the forwardRef
// to throws a lot of typescript errors
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeTableIcon = (Icon: any): any =>
  forwardRef((props, ref) => <Icon {...props} style={{ color: "white" }} ref={ref} />)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tableIcons: any = {
  Add: makeTableIcon(AddBox),
  Check: makeTableIcon(Check),
  Clear: makeTableIcon(Clear),
  Delete: makeTableIcon(DeleteOutline),
  DetailPanel: makeTableIcon(ChevronRight),
  Edit: makeTableIcon(Edit),
  Export: makeTableIcon(SaveAlt),
  Filter: makeTableIcon(FilterList),
  FirstPage: makeTableIcon(FirstPage),
  LastPage: makeTableIcon(LastPage),
  NextPage: makeTableIcon(ChevronRight),
  PreviousPage: makeTableIcon(ChevronLeft),
  ResetSearch: makeTableIcon(Clear),
  Search: makeTableIcon(Search),
  SortArrow: makeTableIcon(ArrowDownward),
  ThirdStateCheck: makeTableIcon(Remove),
  ViewColumn: makeTableIcon(ViewColumn),
}
