import React from "react"

import { Box, Grid, Skeleton } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "./KonanPageHeader.module.scss"

type Props = {
  title?: string | React.ReactElement
  subtitle?: string | React.ReactElement
  actions?: React.ReactElement[]
  loading?: boolean
  mainHeader?: boolean
}

/**
 * Page header component
 * @param  {string} title page title
 * @param  {string} subtitle page subtitle
 * @param  {React.ReactElement} actions
 * @param  {boolean} loading is page still loading
 * @return {<KonanPageHeader />}
 */
export function KonanPageHeader(props: Props): React.ReactElement {
  const { title, subtitle, actions, loading } = props

  const animation: "wave" | "pulse" = "pulse"

  return (
    <Grid container item direction="row" justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={6}>
        <Typography variant="h1-bold" gutterBottom={false}>
          {loading ? <Skeleton width={300} animation={animation} className={styles.skeleton} /> : title}
        </Typography>

        {/* Check if undefined to handle case where "" is passed as placeholder */}
        {subtitle !== undefined && (
          <Typography variant="span" gutterBottom={false}>
            {loading ? (
              <Box>
                <Skeleton animation={animation} />
                <Skeleton animation={animation} width="40%" />
              </Box>
            ) : (
              subtitle
            )}
          </Typography>
        )}
      </Grid>

      {/* Create actions cell if actions exist*/}
      <Grid item xs={12} md={6}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={actions && actions.length > 0 ? (actions.length === 1 ? 0 : 2) : 0}
        >
          {actions &&
            actions.map((action, index) => {
              return (
                <Grid item key={index}>
                  {action}
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}
