import React, { Fragment } from "react"

import { useLocation, useNavigate } from "react-router-dom"

import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { BarDatum, ResponsiveBar } from "@nivo/bar"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { MetricsState } from "../../types/custom/projects"
import { ClassificationLabelEvaluation } from "../../types/generated/api/ClassificationLabelEvaluation"
import { customColorScheme, customRound } from "../../utils/genericHelpers"
import { GraphHeader } from "../GraphHeader"
import { InfoContainer } from "../InfoContainer"

type Props = {
  title: string
  data: ClassificationLabelEvaluation[]
  graphKey: string
  isLoading: boolean
  graphHeight: number
  emptyState: MetricsState | undefined
  range?: number
}

export function MetricsBarChart(props: Props): React.ReactElement {
  const { title, data, graphKey, isLoading, graphHeight, emptyState, range } = props

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const theme = getTheme()

  const maxLabelLength = 30

  //Needed to display the empty bars
  const adjustedData = React.useMemo(() => {
    if (data && data.length > 0) {
      const newData = Array.from(data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newData.forEach((item: any) => {
        if (item[graphKey] === 0) {
          item[graphKey + "_bar"] = 0.01
        } else if (item[graphKey] === null) {
          item[graphKey + "_null"] = 0.01
        } else {
          item[graphKey + "_bar"] = item[graphKey]
        }
      })

      return newData
    }
    return []
  }, [data, graphKey])

  return (
    <div className={"charts-paper"}>
      <GraphHeader
        title={title}
        tooltipText={
          graphKey === "f1_score" ? (
            <React.Fragment>
              The average of both precision and recall.
              <br />
              <br />
              Often used as an <strong>alternative</strong> to accuracy when measuring the model's performance.
              <br />
              <br />
              Usually used when the <strong>predicted labels are not evenly distributed</strong>.
              <br />
              <br />
              <em>Only works for predictions with feedback.</em>
            </React.Fragment>
          ) : graphKey === "precision" ? (
            <React.Fragment>
              What proportion of <strong>model predictions</strong> were <strong>actually correct</strong>? (for each
              label)
              <br />
              <br />
              <em>Only works for predictions with feedback.</em>
            </React.Fragment>
          ) : graphKey === "recall" ? (
            <React.Fragment>
              What proportion of the <strong>actual labels</strong> were <strong>predicted correctly</strong>? (for each
              label)
              <br />
              <br />
              <em>Only works for predictions with feedback.</em>
            </React.Fragment>
          ) : (
            ""
          )
        }
        range={range}
      />

      <Box style={{ height: graphHeight, padding: "16px 16px 0px 16px" }}>
        {(!adjustedData || adjustedData.length < 1) && !isLoading && emptyState ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            <InfoContainer
              title={emptyState?.title}
              subtitle={emptyState?.description}
              action={
                emptyState?.action ? (
                  <Button
                    variant="primary"
                    onClick={() => navigate(`${pathname.substring(0, pathname.indexOf("/models"))}/integration`)}
                  >
                    {emptyState?.action}
                  </Button>
                ) : undefined
              }
            />
          </Grid>
        ) : isLoading || !adjustedData ? (
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className={"full-height"}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="75%" />
            <Skeleton animation="wave" width="60%" height={30} />
            <Skeleton animation="wave" width="30%" />
          </Grid>
        ) : (
          <ResponsiveBar
            data={adjustedData as BarDatum[]}
            theme={NivoTheme(theme)}
            indexBy={"name"}
            keys={[graphKey + "_bar", graphKey + "_null"]}
            colors={customColorScheme()}
            colorBy="indexValue"
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            borderRadius={4}
            padding={0.75}
            maxValue={1}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            enableGridY={false}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            animate={true}
            layers={[
              "grid",
              "axes",
              "bars",
              "markers",
              "legends",
              "annotations",
              ({ bars }) => {
                return (
                  <g>
                    {bars.map(({ width, height, y, key, data }) => {
                      return width ? (
                        <Fragment key={key}>
                          {/* label name */}
                          <text
                            //Calculate position of label name
                            transform={`translate(${0}, ${y - height / width - 10})`}
                            textAnchor="left"
                            dominantBaseline="central"
                            className="chart-text"
                          >
                            {`${
                              key.slice(key.indexOf(".") + 1).length > maxLabelLength
                                ? key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength) + "..."
                                : key.slice(key.indexOf(".") + 1)
                            }`}
                          </text>

                          {!data.value ? (
                            ""
                          ) : //Right value on bar
                          key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength).length * 6.5 +
                              customRound(data.value * 100, 2).toString().length * 6.5 +
                              40 <
                            width ? (
                            <text
                              transform={`translate(${
                                //Calculate position of right value
                                width - customRound(data.value * 100, 1).toString().length * 10 - 10
                              }, ${y - height / width - 10})`}
                              textAnchor="right"
                              dominantBaseline="central"
                              className="chart-text"
                            >
                              {data.value ? `${customRound(data.value * 100, 2)}%` : ""}
                            </text>
                          ) : (
                            //display the value next to the name
                            <text
                              transform={`translate(${
                                key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength).length * 7 + 15
                              }, ${y - height / width - 10})`}
                              textAnchor="right"
                              dominantBaseline="central"
                              className="chart-text"
                            >
                              {key.includes("_null")
                                ? `No predictions or feedback`
                                : data.data[graphKey]
                                  ? `${customRound(Number(data.data[graphKey]) * 100, 2)}%`
                                  : "0%"}
                            </text>
                          )}
                        </Fragment>
                      ) : null
                    })}
                  </g>
                )
              },
            ]}
            tooltip={(slice) => {
              return (
                <div className={"chart-tooltip"}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                      <span
                        key="chip"
                        style={{ display: "block", width: "12px", height: "12px", background: slice.color }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h3-bold" display="inline" variantColor={2}>
                        {slice.data.name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography variant="p" display="inline" variantColor={2}>
                    {graphKey === "f1_score"
                      ? !slice.label.includes(graphKey + "_null")
                        ? "F1-score: " +
                          (slice.data.f1_score ? customRound(Number(slice.data.f1_score) * 100, 2) : 0) +
                          "%"
                        : "No predictions or feedback"
                      : graphKey === "precision"
                        ? !slice.label.includes(graphKey + "_null")
                          ? "Precision: " +
                            (slice.data.precision ? customRound(Number(slice.data.precision) * 100, 2) : 0) +
                            "%"
                          : "No predictions or feedback"
                        : graphKey === "recall"
                          ? !slice.label.includes(graphKey + "_null")
                            ? "Recall: " +
                              (slice.data.recall ? customRound(Number(slice.data.recall) * 100, 2) : 0) +
                              "%"
                            : "No predictions or feedback"
                          : ""}
                  </Typography>
                </div>
              )
            }}
          />
        )}
      </Box>
    </div>
  )
}
