import { Grid, SvgIcon } from "@mui/material"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import Avatar from "boring-avatars"
import moment from "moment"

import styles from "./KonanAvatar.module.scss"

interface Props {
  createdBy: string
  title?: string | React.ReactElement
  createdAt?: string
  description?: string
  avatarName?: string
  noWrap?: boolean
  avatarSize?: number
}

// TODO:: remove and use SUI's avatar when it's finished
export function KonanAvatarComponent(props: Props): React.ReactElement {
  const { createdBy, createdAt, title, description, avatarName, noWrap = true, avatarSize = 24 } = props

  return (
    <Grid container gap={1} alignItems="flex-start" className={styles.avatarContainer}>
      <Grid item display={"flex"} alignSelf={"center"}>
        {/* SvgIcon is used to fix square avatars on safari */}
        <SvgIcon className={styles.avatar}>
          <Avatar
            size={avatarSize}
            name={avatarName ?? createdBy ?? title}
            variant="bauhaus"
            colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
          />
        </SvgIcon>
      </Grid>

      <Grid container item xs={10} display={"flex"} sx={{ width: "100%", height: "100%" }}>
        <Grid item xs={12} alignSelf={"center"}>
          <Typography variant="label" noWrap={noWrap}>
            {title}
          </Typography>
        </Grid>

        {description && (
          <Grid item xs={12}>
            <Typography variant="span" noWrap>
              {description}
            </Typography>
          </Grid>
        )}

        {createdAt && (
          <Grid item xs={12}>
            <Typography variant="label" noWrap>
              <Tooltip title={moment(new Date(createdAt)).format("DD/MM/yyyy, hh:mm a")} placement="right">
                <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                  {moment(new Date(createdAt)).fromNow()}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
