import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { PermissionsFlattener } from "../../utils/PermissionsHelpers"

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState: {
    sidPermissions: {},
    flattenedSidPermissions: [] as string[],
    konanPermissions: {},
    flattenedKonanPermissions: [] as string[],
  },
  reducers: {
    setSidPermissions: (state, action: PayloadAction<object>) => {
      state.sidPermissions = action.payload
      state.flattenedSidPermissions = PermissionsFlattener(action.payload)
    },
    setKonanPermissions: (state, action: PayloadAction<object>) => {
      state.konanPermissions = action.payload
      state.flattenedKonanPermissions = PermissionsFlattener(action.payload)
    },
  },
})

export const { setSidPermissions, setKonanPermissions } = permissionsSlice.actions
