import React, { Fragment } from "react"

import InfoIcon from "@mui/icons-material/Info"
import WarningIcon from "@mui/icons-material/Warning"
import { Box, Grid, Paper } from "@mui/material"
import { InputText, Typography } from "@synapse-analytics/synapse-ui"

import { Metric } from "../types/generated/api/Metric"
import { PredefinedMetric } from "../types/generated/api/PredefinedMetric"
import { customRound } from "../utils/genericHelpers"
import { InfoContainer } from "./InfoContainer"
import { LoadingContainer } from "./LoadingContainer"

import styles from "./EvaluationMetricsCard.module.scss"

type Props = {
  modelName?: string
  metrics: Metric | undefined
  isLoading?: boolean
  isError?: boolean
  error?: string
  cardHeight: number
}

// predefined metrics keys mapping
const predefinedMetricsMap: Record<string, string> = {
  mae: "Mean Absolute Error",
  rmse: "Root Mean Squared Error",
  f1_score: "F1 Score",
  precision: "Precision",
  recall: "Recall",
}

/**
 * Card component contains all evaluation metrics for a model
 * @param {string} modelName
 * @param {Metric | undefined} metrics given metrics that card will render
 * @param {boolean} isLoading indicator if eval metrics endpoint is loading or not
 * @param {boolean} isError indicator if eval metrics endpoint has an error or not
 * @param {string} error returned error from the response
 * @param {number} cardHeight
 * @returns {React.ReactElement}
 */
export function EvaluationMetricsCard(props: Props): React.ReactElement {
  const { modelName, metrics, isLoading, isError, error, cardHeight } = props

  return (
    <Paper className={styles.paper}>
      <Typography variant="h3-bold" gutterBottom>
        Evaluation Metrics
      </Typography>

      <div style={{ height: cardHeight }} className={styles.metricsContainer}>
        {isLoading ? (
          <LoadingContainer />
        ) : !isLoading && isError ? (
          // Requests returns an error
          <Grid container direction="column" justifyContent="center" alignItems="center" className={"full-height"}>
            <InfoContainer
              icon={<WarningIcon className={"warning-icon-base"} fontSize="large" />}
              subtitle={error ?? ""}
            />
          </Grid>
        ) : // If the request is not loading and both the predefined and custom keys do not contain any metrics
        !isLoading &&
          metrics &&
          metrics.custom &&
          metrics.custom.length === 0 &&
          Object.values(metrics.predefined as PredefinedMetric).every((item) => item === null) ? (
          <Grid container direction="column" justifyContent="center" alignItems="center" className={"full-height"}>
            <InfoContainer icon={<InfoIcon color="primary" fontSize="large" />} title="No metrics to display!" />
          </Grid>
        ) : (
          ""
        )}

        {!isLoading &&
          metrics &&
          ((metrics.custom && metrics.custom.length > 0) ||
            !Object.values(metrics.predefined as PredefinedMetric).every((item) => item === null)) && (
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="h3-bold" gutterBottom>
                  {modelName}
                </Typography>
              </Grid>

              {/* Mapping returned entries to input text components */}
              {/* Predefined Metrics */}
              {Object.entries(metrics.predefined as PredefinedMetric).map(
                ([key, value]) =>
                  value !== null && (
                    <Fragment key={key}>
                      <InputText
                        fullWidth
                        hideDescription
                        label={key in predefinedMetricsMap ? predefinedMetricsMap[key] : key}
                        value={typeof value == "number" ? customRound(value, 2) : (value as unknown as string)}
                      />
                      <Box mb={1} />
                    </Fragment>
                  ),
              )}

              {/* Custom Metrics */}
              {metrics.custom.map((elem) => (
                <Fragment key={elem.metric_name}>
                  <InputText
                    fullWidth
                    label={elem.metric_name}
                    hideDescription
                    value={
                      parseFloat(elem.metric_value as unknown as string)
                        ? customRound(parseFloat(elem.metric_value as unknown as string), 2)
                        : (elem.metric_value as unknown as string)
                    }
                  />
                  <Box mb={1} />
                </Fragment>
              ))}
            </Grid>
          )}
      </div>
    </Paper>
  )
}
