import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useDropzone } from "react-dropzone"
import { useMutation, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { Box, Grid } from "@mui/material"
import { Button, InputText, NotificationUtils, Select, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormikContext } from "formik"
import { v4 as uuidv4 } from "uuid"

import { FeaturesMenu } from "../../../components/FeaturesMenu"
import { RadioButtonsCondition } from "../../../components/RadioButtonsCondition"
import { UploadFileBlock } from "../../../components/UploadFileBlock"
import { useDebounce } from "../../../hooks/useDebounce"
import { KonanAPI } from "../../../services/KonanAPI"
import { RootState } from "../../../store/ReduxStore"
import { Operators, UpdateWorkflowRequest } from "../../../types/custom/projects"
import { MapValueTypeToFeatureType, NewFeatureData } from "../../../types/custom/rules"
import { FeatureData } from "../../../types/custom/workflows"
import { DataFile } from "../../../types/generated/api/DataFile"
import { WorkflowSchemaFeature } from "../../../types/generated/api/WorkflowSchemaFeature"
import { handleFeatureChange, multiValuesOperators, operatorsBasedOnFeatureType } from "../../../utils/conditionHelpers"
import { dropZoneCSVExtensionValidator } from "../../../utils/genericHelpers"
import { isTypeIncluded, matchFile } from "../../../utils/rulesetHelpers"
import { ArrowAdornment } from "../../Scorecards/Condition"
import { RuleCardFormikValues } from "../interface"
import { DataBlock } from "./RuleCard"

import styles from "./RuleCard.module.scss"

type Props = {
  id: string
  index: number
  editMode: boolean
  createMode: boolean
  conditionLevel: string
  removeCondition?: (removeFile: boolean) => void
  groupSwitch?: string
  lastSwitchSetter?: (data: string) => void
  ruleIndex: number
  duplicateMode?: boolean
}

export function Condition(props: Readonly<Props>): React.ReactElement {
  const {
    editMode = false,
    createMode,
    id,
    conditionLevel,
    index,
    removeCondition,
    groupSwitch,
    lastSwitchSetter,
    ruleIndex,
    duplicateMode,
  } = props

  const { values, setFieldValue, handleChange, handleBlur, isSubmitting, errors, touched } =
    useFormikContext<RuleCardFormikValues>()

  // variable contains all features (computed, schema)
  const schema = useSelector((state: RootState) => state?.project?.schemaFeatures)
  const computedSchemaFeatures = useSelector((state: RootState) => state?.project?.computedFeatures)

  const workflowsList = useSelector((state: RootState) => state?.workflow?.workflows)

  // filtered features for LHS feature input
  const [filteredFeatures, setFilteredFeatures] = useState<Array<FeatureData>>(schema ?? [])
  // filtered features for RHS feature input
  const [secondFilteredFeatures, setSecondFilteredFeatures] = useState<Array<FeatureData>>(schema ?? [])
  // anchor for LHS feature
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // anchor for RHS feature
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null)
  const [selectedFeat, setSelectedFeat] = useState<string>("")
  const [isFilteredFeaturesSet, setIsFilteredFeaturesSet] = useState(false)

  const [operators, setOperators] = useState<Operators[] | string[]>(
    createMode ? operatorsBasedOnFeatureType.TEXT : operatorsBasedOnFeatureType.UNDEFINED,
  )
  // ref for LHS feature
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>()
  // ref for RHS feature
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef2 = useRef<any>()

  const { id: projectID } = useParams<{ id: string }>()

  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])

  const abortControllerRef = useRef<AbortController | null>(null)

  const queryClient = useQueryClient()

  const allFeatures = [...schema, ...computedSchemaFeatures]

  const { open: openDropZone, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted: (files) => {
      setAcceptedFiles([...acceptedFiles, ...files])
    },
    accept: {
      "text/*": [".csv"],
    },
    validator: dropZoneCSVExtensionValidator,
  })

  const [types, setTypes] = useState<Array<string>>(["string", "number", "true", "false"])
  const andOr = ["and", "or"]

  const condition = values?.cards?.[ruleIndex][conditionLevel][index]

  const touchedRules = touched?.cards
  const errorRules = errors?.cards
  const touchedAndErrorsCheck =
    touchedRules &&
    errorRules &&
    touched.cards?.[ruleIndex]?.[conditionLevel]?.[index] &&
    errors.cards?.[ruleIndex]?.[conditionLevel]?.[index]

  // special handler for changing type field and all side effects in specific cases
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (["is null", "not null"].includes(event.target.value)) {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].operator`, event.target.value)

      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].value`, "null")
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].type`, "null")
      return
    }

    if (
      !["is null", "not null"].includes(event.target.value) &&
      ["is null", "not null"].includes(condition?.operator)
    ) {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].value`, "")
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].type`, "string")
    }

    setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].type`, event.target.value)

    //When selecting true, false, or null from the menu -> set the value field to the selection
    if (isTypeIncluded(event?.target?.value)) {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].value`, event.target.value)
      setOperators([Operators["!="], Operators["="]])
    }

    // whenever number type is selected, change the operators to include all numerical operators
    if (event.target.value === "number") {
      setOperators(operatorsBasedOnFeatureType.NUMBER)
    }

    if (event.target.value === "string") {
      setOperators(operatorsBasedOnFeatureType.TEXT)
    }

    //When selecting number or string from the menu -> reset the value field
    else if (event.target.value === "string" || event.target.value === "number") {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].value`, "")
    }
  }

  // special handler for changing operator field and all side effects in specific cases
  const handleOperatorChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].operator`, event.target.value)

    // reset the value field when selecting in/not in operators from the menu
    if (![Operators["!="], Operators["="]].includes(Operators[event.target.value as keyof typeof Operators])) {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].value`, "")
    }

    if (
      [Operators.between, ...multiValuesOperators].includes(Operators[event.target.value as keyof typeof Operators])
    ) {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].valueOrFeature`, "Value")
    }

    // reset the value field when selecting not equal/equal operators and there is an uploaded file
    if (
      matchFile(condition.value).isMatching &&
      acceptedFiles?.length &&
      (event.target.value === Operators["!="] || condition?.operator === Operators["="])
    ) {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].value`, "")
    }

    // automatically switch value's type to number when these operators are active
    // -> "greater than", "greater than/equal", "lower than", "lower than/equal", "between"
    if (
      ["greater than", "greater than/equal", "lower than", "lower than/equal", "between"].includes(event.target.value)
    ) {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].type`, "number")
    }

    // automatically switch value's type to string when these operators are active
    // -> "in", "not in", "contains", "not contains"
    if (multiValuesOperators.includes(event.target.value)) {
      setFieldValue(`cards[${ruleIndex}][${conditionLevel}][${index}].type`, "string")
    }
  }

  // update a workflow
  const updateWorkflowMutation = useMutation<AxiosResponse, AxiosError, UpdateWorkflowRequest>(
    KonanAPI.updateWorkflow,
    {
      onSuccess: async () => {
        NotificationUtils.toast("Feature added to schema successfully.", {
          snackBarVariant: "positive",
        })
        await queryClient.invalidateQueries("workflows")
        await queryClient.invalidateQueries("workflow")
        await queryClient.invalidateQueries(["project", projectID as string])

        setFilteredFeatures([...allFeatures].filter((item: FeatureData) => item?.name.includes(condition?.feature)))
        setSecondFilteredFeatures(
          [...allFeatures].filter((item: FeatureData) => item?.name.includes(condition?.secondFeature)),
        )

        setIsFilteredFeaturesSet(false)
      },

      onError: () => {
        NotificationUtils.toast("An error occurred while adding this feature to schema!", {
          snackBarVariant: "negative",
        })
      },
    },
  )

  const {
    isLoading: isCsvUploading,
    mutateAsync: uploadCSVAsyncMutation,
    reset,
  } = useMutation<DataFile, AxiosError, File>(
    (file: File) => {
      abortControllerRef.current = new AbortController()
      return KonanAPI.uploadDataFile({
        project_uuid: projectID as string,
        file: file,
        signal: abortControllerRef.current.signal,
        type: DataFile.type.CONDITION_LIST,
      })
    },
    {
      mutationKey: "uploadCSV",
      onSuccess: async (response) => {
        values?.cards?.[ruleIndex].condition_list_files.push({ uuid: response.uuid, name: response.name })

        condition.value = `$file<${response.uuid}>`
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (response: any) => {
        condition.value = ""

        if (response?.response?.data?.details) {
          NotificationUtils.toast(response?.response?.data?.details?.slice(1, -1), {
            snackBarVariant: "negative",
          })
        } else if (response?.response?.data?.data_file) {
          NotificationUtils.toast(response?.response?.data?.data_file[0], {
            snackBarVariant: "negative",
          })
        }
      },
    },
  )

  const deleteDataFileMutation = useMutation<AxiosResponse, AxiosError, string>(
    (fileId: string) => KonanAPI.deleteDataFile(projectID as string, fileId),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Data File deleted successfully!", {
          snackBarVariant: "positive",
        })

        condition.value = ""

        reset()
        setAcceptedFiles([])
      },
      onError: () => {
        NotificationUtils.toast("An error occurred while deleting data file!", {
          snackBarVariant: "negative",
        })
      },
    },
  )

  // handle close dropzone
  const handleCloseAndAbort = useCallback(() => {
    abortControllerRef.current?.abort()
    reset()
    setAcceptedFiles([])
  }, [reset])

  // handle upload once files are accepted
  const handleUpload = async (): Promise<void> => {
    try {
      await uploadCSVAsyncMutation(acceptedFiles[0])

      // Invalidate training data to trigger a refresh
      await queryClient.invalidateQueries(["data-files", projectID])
    } catch (err) {
      // reset dropzone on error
      handleCloseAndAbort()
    }
  }

  // handle delete data file
  const handleDeleteFile = async (): Promise<void> => {
    const value = condition.value

    if (editMode || duplicateMode) {
      condition.value = ""

      values?.cards?.[ruleIndex].condition_list_files.filter(
        (file: { uuid: string; name: string }) => file.uuid === value.slice(6, -1),
      )

      setAcceptedFiles([])
    } else {
      try {
        await deleteDataFileMutation.mutateAsync(value.slice(6, -1))
        await queryClient.invalidateQueries(["data-files", projectID])
      } catch (err) {
        // reset dropzone on error
        handleCloseAndAbort()
      }
    }
  }

  // starts uploading once the file is read
  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  // setting initial value for groupSwitch according to parsed andOr value
  useEffect(() => {
    if (
      values?.cards?.[ruleIndex] &&
      conditionLevel &&
      values?.cards?.[ruleIndex][conditionLevel][1]?.andOr &&
      lastSwitchSetter &&
      values?.cards?.[ruleIndex][conditionLevel][1]?.andOr &&
      lastSwitchSetter
    ) {
      lastSwitchSetter(values?.cards?.[ruleIndex][conditionLevel][1]?.andOr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isFilteredFeaturesSet) {
      setFilteredFeatures([...(schema ?? []), ...(computedSchemaFeatures ?? [])])
      setSecondFilteredFeatures([...(schema ?? []), ...(computedSchemaFeatures ?? [])])
      if ([...schema, ...computedSchemaFeatures]?.length > 0) {
        setIsFilteredFeaturesSet(true)
      }
    }
  }, [schema, isFilteredFeaturesSet, computedSchemaFeatures])

  const currentFeature = useMemo(() => {
    const feature = condition?.feature
    if (filteredFeatures?.length > 0 && feature) {
      const current = filteredFeatures?.find((feat) => feat?.name === feature)
      return current
    }
  }, [condition?.feature, filteredFeatures])

  const handleUpdateWorkflow = async (featureData: NewFeatureData, featureName: string): Promise<void> => {
    // Fetch single workflow
    const { data: workflowData } = await queryClient.fetchQuery(["workflow", projectID, featureData.workflow], () =>
      KonanAPI.fetchWorkflow(projectID as string, featureData.workflow),
    )

    const newFeat = {
      name: featureName?.replace(/\s/g, ""),
      type: featureData.type,
      is_required: featureData.isRequired,
      id: uuidv4(),
      source: "workflow",
      new: true,
    }

    if (workflowData?.active_version) {
      await updateWorkflowMutation.mutateAsync({
        name: workflowData?.name,
        projectUUID: projectID as string,
        workflowUUID: workflowData?.uuid,
        schema: [...(workflowData?.active_version?.schema?.features ?? []), newFeat],
      })
    }
  }

  const debouncedOnChangeLHS = useDebounce((): void => {
    const updatedConditionParts = handleFeatureChange(allFeatures, condition?.feature, condition)
    setFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
    setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
    setOperators(updatedConditionParts?.operators)
    setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    setFieldValue(id + ".type", updatedConditionParts?.type)

    setFieldValue(id + ".value", updatedConditionParts?.value)

    setAnchorEl(inputRef?.current)
    setTimeout(() => {
      inputRef?.current?.focus()
    }, 100)
  }, 400)

  const debouncedOnChangeRHS = useDebounce((): void => {
    const updatedConditionParts = handleFeatureChange(allFeatures, condition?.secondFeature, condition)
    setFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
    setSecondFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
    setOperators(updatedConditionParts?.operators)
    setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    setFieldValue(id + ".type", updatedConditionParts?.type)

    setFieldValue(id + ".value", updatedConditionParts?.value)

    setAnchorEl2(inputRef2?.current)

    setTimeout(() => {
      inputRef2?.current?.focus()
    }, 100)
  }, 400)

  const shouldDisplayFileBlock = Boolean(
    (isCsvUploading || matchFile(condition?.value).isMatching) &&
      multiValuesOperators.includes(condition?.operator) &&
      (editMode || createMode),
  )

  const handleFeatureSelectionFromMenu = (item: FeatureData, featureIndicator: "LHS" | "RHS"): void => {
    setSelectedFeat(item?.name)
    const updatedConditionParts = handleFeatureChange(allFeatures, item?.name, condition)
    setFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
    setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
    setOperators(updatedConditionParts?.operators)
    setFieldValue(id + ".operator", updatedConditionParts?.operator)

    setTypes(updatedConditionParts?.types)

    setFieldValue(id + ".type", updatedConditionParts?.type)

    setFieldValue(id + ".value", updatedConditionParts?.value)

    if (featureIndicator === "LHS") {
      setFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
      setSecondFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
    } else {
      setFilteredFeatures(updatedConditionParts?.otherFilteredFeatures as FeatureData[])
      setSecondFilteredFeatures(updatedConditionParts?.filteredFeatures as FeatureData[])
    }
  }

  /**
   * indicator flag for displaying value/feature options (radio buttons options)
   */
  const shouldDisplayValueOrFeature =
    // 1. when ruleset in edit/create mode
    (createMode || editMode) &&
    // 2. if operator is not in [in, not in, contains, not contains]
    !["is null", "not null", "between"].includes(condition?.operator) &&
    // 3. if value type isn't in [true, false, null] OR equal to any of these values
    // but feature is empty or currentFeature not equal to boolean or undefined
    (!isTypeIncluded(condition?.type) ||
      (isTypeIncluded(condition?.type) &&
        currentFeature &&
        currentFeature?.type !== WorkflowSchemaFeature.type.BOOLEAN &&
        currentFeature?.type !== WorkflowSchemaFeature.type.UNDEFINED))

  const getInitialNewFeatureType = (): WorkflowSchemaFeature.type => {
    if ([Operators.between, ...multiValuesOperators].includes(condition?.operator)) {
      return WorkflowSchemaFeature.type.LIST
    } else if (condition?.type === "true" || condition?.type === "false") {
      return WorkflowSchemaFeature.type.BOOLEAN
    } else if (condition?.type === "number") {
      return WorkflowSchemaFeature.type.NUMBER
    } else {
      return WorkflowSchemaFeature.type.TEXT
    }
  }

  const handleFeatureTypeChange = (value: string): void => {
    switch (value) {
      case WorkflowSchemaFeature.type.TEXT:
      case WorkflowSchemaFeature.type.UNDEFINED:
      case WorkflowSchemaFeature.type.DATE:
        if (
          (condition?.type === "true" && condition?.value === "true") ||
          (condition?.type === "false" && condition?.value === "false")
        ) {
          setFieldValue(id + ".value", "")
        }

        setFieldValue(id + ".type", "string")
        setOperators(operatorsBasedOnFeatureType.TEXT)
        setFieldValue(id + ".operator", "equal")
        break

      case WorkflowSchemaFeature.type.BOOLEAN:
        setFieldValue(id + ".type", "true")
        setFieldValue(id + ".value", "true")
        setOperators([Operators["!="], Operators["="]])
        setFieldValue(id + ".operator", "equal")
        break

      case WorkflowSchemaFeature.type.NUMBER:
        if (
          (condition?.type === "true" && condition?.value === "true") ||
          (condition?.type === "false" && condition?.value === "false")
        ) {
          setFieldValue(id + ".value", "")
        }

        setFieldValue(id + ".type", "number")
        setOperators(Object.values(Operators).filter((v) => isNaN(Number(v))))
        setFieldValue(id + ".operator", "equal")
        break

      case WorkflowSchemaFeature.type.LIST:
        setFieldValue(id + ".operator", "in")

        // reset the value field when selecting in/not in operators from the menu
        setFieldValue(id + ".value", "")
        setFieldValue(id + ".valueOrFeature", "Value")
        break

      default:
        break
    }
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
      {!!values?.cards?.[ruleIndex]?.levelOneConditions && (
        <Fragment>
          {/* And/Or */}
          {index !== 0 ? (
            <Grid item lg={1.5}>
              {(editMode || createMode) && index === 1 ? (
                <Select
                  id={id + ".andOr"}
                  type="text"
                  value={groupSwitch ?? values?.cards?.[ruleIndex][conditionLevel][1]?.andOr}
                  disabled={index > 1}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    handleChange(e)
                    if (lastSwitchSetter) {
                      lastSwitchSetter(e.target.value as string)
                    }
                  }}
                  fullWidth
                  options={andOr}
                  hideDescription
                  width={80}
                  menuProps={{ menuMaxContent: true }}
                />
              ) : (
                <DataBlock value={values?.cards?.[ruleIndex][conditionLevel][1]?.andOr} />
              )}
            </Grid>
          ) : (
            /* If */
            <Grid item lg={1}>
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="p">If</Typography>
              </Box>
            </Grid>
          )}

          {/* Feature */}
          <Grid item xs={6} md={editMode || createMode ? 3 : true}>
            {editMode || createMode ? (
              <div>
                <InputText
                  key={`${selectedFeat}_${values?.cards?.length}`}
                  hideDescription
                  id={id + ".feature"}
                  placeholder="Feature"
                  value={condition?.feature}
                  anchoringRef={inputRef}
                  endAdornment={
                    allFeatures.length > 0 ? (
                      <ArrowAdornment
                        anchorEl={anchorEl}
                        onClick={() => setAnchorEl(!!anchorEl ? null : inputRef?.current)}
                      />
                    ) : undefined
                  }
                  handleChange={(e) => {
                    handleChange(e)
                    setAnchorEl(null)
                    debouncedOnChangeLHS()
                  }}
                  error={
                    touchedAndErrorsCheck &&
                    touched.cards[ruleIndex][conditionLevel][index]?.feature &&
                    Boolean(errors.cards[ruleIndex][conditionLevel][index]?.feature) &&
                    errors.cards[ruleIndex][conditionLevel][index]?.feature
                  }
                  handleBlur={handleBlur}
                  disabled={isSubmitting}
                  fullWidth
                />
                <FeaturesMenu
                  handleFeatureTypeChange={(value: string) => handleFeatureTypeChange(value)}
                  initialFeatureType={getInitialNewFeatureType}
                  filteredFeatures={filteredFeatures}
                  workflowsList={workflowsList}
                  anchorEl={anchorEl}
                  queryKey={condition?.feature}
                  onAddingNewFeature={(featureData) => {
                    setAnchorEl(null)
                    handleUpdateWorkflow(featureData, condition?.feature)
                  }}
                  onSelectingFeature={(item) => {
                    setFieldValue(id + ".feature", item?.name)
                    handleFeatureSelectionFromMenu(item, "LHS")
                    setAnchorEl(null)
                  }}
                  onMenuClose={() => setAnchorEl(null)}
                />
              </div>
            ) : (
              <DataBlock value={condition?.feature} />
            )}
          </Grid>

          {/* Operator */}
          <Grid item xs md={["is null", "not null"].includes(condition?.operator) ? true : 2.4}>
            {editMode || createMode ? (
              <Select
                id={id + ".operator"}
                key={condition?.id}
                type="text"
                handleBlur={handleBlur}
                value={condition?.operator}
                handleChange={(e) => handleOperatorChange(e)}
                fullWidth
                options={operators}
                hideDescription
              />
            ) : (
              <DataBlock value={condition?.operator} />
            )}
          </Grid>

          {/* Type */}
          {((!currentFeature && !isSubmitting) ||
            currentFeature?.type === "UNDEFINED" ||
            isTypeIncluded(condition?.type) ||
            currentFeature?.type === "BOOLEAN") &&
            condition?.valueOrFeature !== "Feature" &&
            !["is null", "not null"].includes(condition?.operator) &&
            (editMode || createMode) && (
              <Grid
                item
                xs
                lg={isTypeIncluded(condition?.type) ? true : 1.5}
                style={
                  ((editMode || createMode) &&
                    condition?.operator !== Operators["!="] &&
                    condition?.operator !== Operators["="]) ||
                  (!editMode &&
                    !createMode &&
                    (multiValuesOperators.includes(Operators[condition?.operator as keyof typeof Operators]) ||
                      isTypeIncluded(condition?.type)))
                    ? { display: "none" }
                    : undefined
                }
              >
                {editMode || createMode ? (
                  <Select
                    id={id + ".type"}
                    key={condition?.id}
                    type="text"
                    handleBlur={handleBlur}
                    value={condition?.type}
                    handleChange={(e) => handleTypeChange(e)}
                    fullWidth
                    options={types}
                    hideDescription
                  />
                ) : (
                  <DataBlock value={condition?.type} />
                )}
              </Grid>
            )}

          {/* Feature/value radio buttons*/}
          {shouldDisplayValueOrFeature && (
            <RadioButtonsCondition
              handleOnChange={(e) => {
                setFieldValue(id + ".valueOrFeature", e.target.value)

                setOperators(
                  operatorsBasedOnFeatureType[
                    MapValueTypeToFeatureType[condition?.type as keyof typeof MapValueTypeToFeatureType]
                  ],
                )
              }}
              checkedFeat={condition?.valueOrFeature === "Feature"}
              checkedValue={condition?.valueOrFeature === "Value"}
              shouldDisableFeature={matchFile(condition?.value).isMatching}
              margin={"0.5rem 0.12rem 0px 0.4rem"}
            />
          )}

          {/* Value */}
          {condition?.operator?.toLowerCase() === Operators.between.toLowerCase() ? (
            <Grid flexWrap={"nowrap"} xs item container key={condition?.id}>
              <Grid item xs md key={condition?.id}>
                {editMode || createMode ? (
                  <InputText
                    id={id + ".value"}
                    hideDescription
                    key={condition?.id}
                    placeholder="Value"
                    value={condition?.value}
                    handleChange={handleChange}
                    type="number"
                    error={
                      touchedAndErrorsCheck &&
                      touched.cards[ruleIndex][conditionLevel][index]?.value &&
                      Boolean(errors.cards[ruleIndex][conditionLevel][index]?.value) &&
                      errors.cards[ruleIndex][conditionLevel][index]?.value
                    }
                    handleBlur={handleBlur}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <DataBlock value={condition?.value} />
                )}
              </Grid>
              <Grid item xs={0.2} mt={!editMode && !createMode ? 0 : 0.8} ml={1} mr={1} className="and-block">
                <Typography variant="h3-bold" variantColor={1} color="neutral">
                  And
                </Typography>
              </Grid>
              <Grid item xs md>
                {editMode || createMode ? (
                  <InputText
                    id={id + ".secondValue"}
                    hideDescription
                    placeholder="Value"
                    value={condition?.secondValue}
                    key={condition?.id}
                    handleChange={handleChange}
                    type="number"
                    error={
                      touchedAndErrorsCheck &&
                      touched.cards[ruleIndex][conditionLevel][index]?.secondValue &&
                      Boolean(errors.cards[ruleIndex][conditionLevel][index]?.secondValue) &&
                      errors.cards[ruleIndex][conditionLevel][index]?.secondValue
                    }
                    handleBlur={handleBlur}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <DataBlock value={condition?.secondValue} />
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs
              key={condition?.id}
              // Important: hide this field using display: "none" in order NOT to lose access to this element by id,
              // as the id of this field is used by the formik object
              style={
                ((editMode || createMode) && isTypeIncluded(condition?.type)) ||
                ["is null", "not null"].includes(condition?.operator)
                  ? { display: "none" }
                  : undefined
              }
            >
              {shouldDisplayFileBlock ? (
                <UploadFileBlock
                  onDelete={handleDeleteFile}
                  fileName={
                    values?.cards?.[ruleIndex]?.condition_list_files?.find(
                      (file: { uuid: string; name: string }) => file.uuid === matchFile(condition?.value).fileId,
                    )?.name ?? ""
                  }
                  isUploading={isCsvUploading}
                  handleCancel={handleCloseAndAbort}
                  isDeleting={deleteDataFileMutation.isLoading}
                  editMode={editMode}
                  createMode={createMode}
                />
              ) : (editMode || createMode) && condition?.valueOrFeature === "Feature" ? (
                <div>
                  <InputText
                    key={`${selectedFeat}_${values?.cards?.length}`}
                    id={id + ".secondFeature"}
                    hideDescription
                    placeholder="Feature"
                    value={condition?.secondFeature}
                    anchoringRef={inputRef2}
                    endAdornment={
                      allFeatures.length > 0 ? (
                        <ArrowAdornment
                          anchorEl={anchorEl2}
                          onClick={() => setAnchorEl2(!!anchorEl2 ? null : inputRef2?.current)}
                        />
                      ) : undefined
                    }
                    handleChange={(e) => {
                      handleChange(e)
                      setAnchorEl2(null)
                      debouncedOnChangeRHS()
                    }}
                    error={
                      touchedAndErrorsCheck &&
                      touched.cards[ruleIndex][conditionLevel][index]?.secondFeature &&
                      Boolean(errors.cards[ruleIndex][conditionLevel][index]?.secondFeature) &&
                      errors.cards[ruleIndex][conditionLevel][index]?.secondFeature
                    }
                    handleBlur={handleBlur}
                    disabled={isSubmitting}
                    fullWidth
                  />
                  <FeaturesMenu
                    filteredFeatures={secondFilteredFeatures}
                    workflowsList={workflowsList}
                    anchorEl={anchorEl2}
                    queryKey={condition?.secondFeature}
                    onAddingNewFeature={(featureData) => {
                      setAnchorEl2(null)
                      handleUpdateWorkflow(featureData, condition?.secondFeature)
                    }}
                    onSelectingFeature={(item) => {
                      setFieldValue(id + ".secondFeature", item?.name)
                      handleFeatureSelectionFromMenu(item, "RHS")
                      setAnchorEl2(null)
                    }}
                    onMenuClose={() => setAnchorEl2(null)}
                  />
                </div>
              ) : editMode || createMode ? (
                <InputText
                  id={id + ".value"}
                  placeholder="Value"
                  value={condition?.value}
                  hideDescription
                  key={condition?.id}
                  endAdornment={
                    multiValuesOperators.includes(Operators[condition?.operator as keyof typeof Operators]) ? (
                      <Fragment>
                        {/* must add this input to make it work in safari*/}
                        <input {...getInputProps()} />
                        <FileUploadOutlinedIcon
                          onClick={openDropZone}
                          style={{ color: "var(--grayscale-text-2)" }}
                          fontSize="small"
                        />
                      </Fragment>
                    ) : undefined
                  }
                  handleChange={handleChange}
                  error={
                    touchedAndErrorsCheck &&
                    touched.cards[ruleIndex][conditionLevel][index]?.value &&
                    Boolean(errors.cards[ruleIndex][conditionLevel][index]?.value) &&
                    errors.cards[ruleIndex][conditionLevel][index]?.value
                  }
                  type={
                    condition?.type !== "string" &&
                    !multiValuesOperators.includes(Operators[condition?.operator as keyof typeof Operators])
                      ? "number"
                      : "text"
                  }
                  handleBlur={handleBlur}
                  disabled={isSubmitting}
                  fullWidth
                  description={
                    [Operators["in"], Operators["not in"]].includes(
                      Operators[condition?.operator as keyof typeof Operators],
                    )
                      ? "Use single quotes for strings. Example: 'john', 'alex'"
                      : [Operators["contains"], Operators["not contains"]].includes(
                            Operators[condition?.operator as keyof typeof Operators],
                          )
                        ? "Use single quotes for strings. Example: 'john', 'alex', or enter one string"
                        : ""
                  }
                />
              ) : (
                <div className={styles.valueFieldMargin}>
                  <DataBlock
                    value={
                      matchFile(condition?.value).isMatching
                        ? (values?.cards?.[ruleIndex]?.condition_list_files?.find(
                            (file: { uuid: string; name: string }) => file.uuid === matchFile(condition?.value).fileId,
                          )?.name ?? "")
                        : condition?.value
                    }
                    isLoading={false}
                  />
                </div>
              )}
            </Grid>
          )}

          {/* Remove button */}
          {(editMode || createMode) &&
            ((conditionLevel !== "levelOneConditions" && values?.cards?.[ruleIndex]?.levelOneConditions.length === 1) ||
              index !== 0 ||
              conditionLevel === "levelTwoConditions" ||
              conditionLevel === "levelThreeConditions") && (
              <Grid item>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="dangerous"
                    size="small"
                    onClick={() => {
                      removeCondition?.(
                        !!(
                          multiValuesOperators.includes(Operators[condition?.operator as keyof typeof Operators]) &&
                          matchFile(condition?.value).isMatching
                        ),
                      )
                    }}
                  >
                    <RemoveCircleOutlineIcon fontSize="inherit" />
                  </Button>
                </Box>
              </Grid>
            )}
        </Fragment>
      )}
    </Grid>
  )
}
