/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExportJobList = {
  readonly uuid: string
  /**
   * This name will be taken as the exported csv file name. It allows all characters except [
   * .]
   */
  name: string
  /**
   * * `DEPLOYMENT_PREDICTIONS` - Deployment Predictions
   * * `MODEL_PREDICTIONS` - Model Predictions
   * * `SIMULATION_PREDICTIONS` - Simulation Predictions
   * * `WORKFLOW_PREDICTIONS` - Workflow Predictions
   */
  type: ExportJobList.type
  readonly created_at: string
  created_by: string
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   */
  status?: ExportJobList.status
  predictions_count?: number | null
  requested_predictions_count?: number | null
  /**
   * The data type of the exported job
   *
   * * `CSV` - CSV
   * * `JSON` - JSON
   */
  data_type?: ExportJobList.data_type
  /**
   * Version of the Export Job's data
   *
   * * `V1` - v1
   */
  version?: ExportJobList.version
}
export namespace ExportJobList {
  /**
   * * `DEPLOYMENT_PREDICTIONS` - Deployment Predictions
   * * `MODEL_PREDICTIONS` - Model Predictions
   * * `SIMULATION_PREDICTIONS` - Simulation Predictions
   * * `WORKFLOW_PREDICTIONS` - Workflow Predictions
   */
  export enum type {
    DEPLOYMENT_PREDICTIONS = "DEPLOYMENT_PREDICTIONS",
    MODEL_PREDICTIONS = "MODEL_PREDICTIONS",
    SIMULATION_PREDICTIONS = "SIMULATION_PREDICTIONS",
    WORKFLOW_PREDICTIONS = "WORKFLOW_PREDICTIONS",
  }
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   */
  export enum status {
    PENDING = "PENDING",
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED",
  }
  /**
   * The data type of the exported job
   *
   * * `CSV` - CSV
   * * `JSON` - JSON
   */
  export enum data_type {
    CSV = "CSV",
    JSON = "JSON",
  }
  /**
   * Version of the Export Job's data
   *
   * * `V1` - v1
   */
  export enum version {
    V1 = "V1",
  }
}
