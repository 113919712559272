import React, { Dispatch, SetStateAction } from "react"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined"
import { Grid } from "@mui/material"
import { Button, Tab, Tabs } from "@synapse-analytics/synapse-ui"

import { SearchSortFilter } from "./SearchSortFilter"

import styles from "./KonanTabsHeader.module.scss"

type Props = {
  setAction?: Dispatch<SetStateAction<boolean>>
  title: string
  containerType: string
  setTabValue: Dispatch<SetStateAction<string>>
  value: string
  sortValue: string
  setSortValue: Dispatch<SetStateAction<string>>
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  sortOptions: string[]
  actionDisabled?: boolean
}

export function KonanTabsHeader(props: Readonly<Props>): React.ReactElement {
  const {
    setAction,
    title,
    value,
    setTabValue,
    searchValue,
    setSearchValue,
    containerType,
    setSortValue,
    sortOptions,
    sortValue,
    actionDisabled,
  } = props

  const handleTabClick = (name: string): void => {
    if (name) {
      setTabValue(name)
    }
  }
  const handleChange = (newValue: string): void => {
    if (newValue) {
      setTabValue(newValue)
    }
  }
  return (
    <Grid xs={12} direction="row" container justifyContent="space-between" alignItems="flex-start">
      <Grid item container xs={setAction ? 9 : 12} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item className={styles.searchSortContainer}>
          <SearchSortFilter
            containerType={containerType}
            onSearchValueChange={setSearchValue}
            searchValue={searchValue}
            sortValue={sortValue}
            sortOptions={sortOptions}
            onSortValueChange={setSortValue}
          />
        </Grid>
        <Grid item className={styles.lgDownMb} mt="-5px">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <Tabs value={value} onClick={(e: any) => handleChange(e.target.value)}>
            <Tab
              icon={() => <CheckCircleOutlineOutlinedIcon fontSize="small" />}
              label="Success"
              value={"success"}
              selected={value === "success"}
              onClick={() => handleTabClick("success")}
            />
            <Tab
              icon={() => <ErrorOutlineOutlinedIcon fontSize="small" />}
              label="Failed"
              value={"failed"}
              selected={value === "failed"}
              onClick={() => handleTabClick("failed")}
            />
            <Tab
              icon={() => <TrackChangesOutlinedIcon fontSize="small" />}
              label="In Progress"
              value="inProgress"
              selected={value === "inProgress"}
              onClick={() => handleTabClick("inProgress")}
            />
          </Tabs>
        </Grid>
      </Grid>
      {setAction && (
        <Grid item style={{ alignSelf: "flex-start" }}>
          <Button
            onClick={() => setAction(true)}
            disabled={actionDisabled}
            variant="primary"
            className={styles.lgDownMb}
            size="regular"
            startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
          >
            {title}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
