import React, { useEffect, useMemo, useState } from "react"

import { Edge, Handle, Node, Position, useReactFlow, useStoreApi, useUpdateNodeInternals } from "reactflow"

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import { Card, Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../hooks/UseTheme"
import { getLayoutedElements, getNodeHandleXPosition } from "../../../utils/workflowHelpers"

import styles from "./CustomCards.module.scss"

// props for loop end nodes
interface LoopEndNodeType {
  id?: string
  menuView?: boolean
  data?: {
    readMode: boolean
    parentLoopName: string
    parentLoopId: string
    parentId: string
  }
}

type SortEdgePosition = {
  edge: Edge
  node: Node
}
/**
 * LoopEndNode component renders a card representing the end of a loop in a workflow.
 * It can be displayed in different views depending on the `menuView` prop.
 *
 * @param {string} [id] - The optional ID of the node.
 * @param {boolean} menuView - A boolean indicating if the node is being viewed in a menu.
 * @param {record} data - Optional data object containing the `readMode` and `parentLoopName`.
 *
 * @returns {React.ReactElement} The rendered LoopEndNode component.
 */
export function LoopEndNode(props: Readonly<LoopEndNodeType>): React.ReactElement {
  const { menuView } = props

  const theme = getTheme()

  const updateNodeInternals = useUpdateNodeInternals()

  // this is reactflow helper function to delete passed nodes as well as all the connected edges from the passed nodes
  const { getEdges, getNode } = useReactFlow()
  const edges = getEdges()

  // instance of our state management that share our nodes and edges across all component
  const store = useStoreApi()
  const { getNodes } = store.getState()

  const [shouldReCalculateLayout, setShouldReCalculateLayout] = useState<boolean>(false)

  const sourceEdges = edges?.filter((edge) => edge?.target === props?.id)

  const sortedSourceEdges: Array<SortEdgePosition> = sourceEdges.map((edge) => ({
    edge,
    node: getNode(edge.source) as Node,
  }))

  // Sorting function
  const sortNodes = (a: SortEdgePosition, b: SortEdgePosition): number => {
    const aXEnd = a.node?.position.x + (a.node?.width ?? 318)
    const bXEnd = b.node?.position.x + (b.node?.width ?? 318)

    if (aXEnd <= b.node?.position.x) {
      return a.node?.position.x - b.node?.position.x
    } else if (bXEnd <= a.node?.position.x) {
      return a.node?.position.x - b.node?.position.x
    } else {
      const aEdgeText = a.edge.data?.text
      const bEdgeText = b.edge.data?.text

      if (a.node?.position.y < b.node?.position.y) {
        if (aEdgeText === "true") {
          return -1
        } else {
          return 1
        }
      } else if (a.node?.position.y > b.node?.position.y) {
        if (bEdgeText === "true") {
          return 1
        } else {
          return -1
        }
      } else {
        return 0
      }
    }
  }

  // Apply the sorting function to the edges
  sortedSourceEdges.sort(sortNodes)

  const getNodeWidth = useMemo(() => {
    setShouldReCalculateLayout(true)

    return !menuView && sortedSourceEdges?.length > 1
      ? `${sortedSourceEdges?.length * 400 - 50}px !important`
      : "350px !important"
  }, [menuView, sortedSourceEdges?.length])

  // this memo aims to sort the edges pointing to this loop end node based on their position (order)
  // and adjust the space needed for all the handles to get positioned correctly
  const getHandlePositions = useMemo(() => {
    // Check if the menu view is not active and there are edges to process
    if (!menuView && sortedSourceEdges?.length > 0) {
      // Map over each edge and its associated node
      return sortedSourceEdges?.map((edgeWithNode, index) => {
        // Find the width of the current node using the node ID from props
        // The width is necessary to correctly position the handle on the node
        const currentNodeWidth = getNodes()?.find((node) => node?.id === props?.id)?.width as number

        // Return a Handle component for each edge
        return (
          <Handle
            // Unique key for each Handle to help React efficiently update the list
            key={`handle-${index}`}
            // Define this Handle as a target, meaning other nodes will connect to it
            type="target"
            // Position the Handle at the top of the node
            position={Position.Top}
            // Set the id of the Handle, using the targetHandle from the edge or defaulting to "a"
            id={edgeWithNode.edge?.targetHandle ?? "a"}
            isConnectable={true}
            // Style the Handle by setting its left position
            style={{
              opacity: 0,
              // Use a function to calculate the left position
              // This ensures the Handle is evenly spaced on the node
              left: `${getNodeHandleXPosition(index, currentNodeWidth, sortedSourceEdges?.length)}px`,
            }}
          />
        )
      })
    }
    // Return an empty array if the menu view is active or there are no edges to process
    return []
  }, [sortedSourceEdges, menuView, getNodes, props?.id])

  const updateGraphAfterHandleChange = (): void => {
    if (shouldReCalculateLayout) {
      getLayoutedElements(getNodes(), edges)

      setShouldReCalculateLayout(false)
    }
  }

  /**
   * When you programmatically add or remove handles to a node or update a node's handle position,
   * you need to let React Flow know about it using this hook.
   * This will update the internal dimensions of the node and properly reposition handles on the canvas if necessary.
   * learn more here: https://reactflow.dev/api-reference/hooks/use-update-node-internals
   */
  useEffect(() => {
    updateNodeInternals(props?.id as string)

    if (!props?.data?.readMode) {
      setTimeout(() => {
        updateGraphAfterHandleChange()
      }, 10)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id, sortedSourceEdges.length, updateNodeInternals, getNodes, edges, props?.data?.readMode])

  return (
    <Card className={`${styles.card} disableKeyboardA11y nodrag`} sx={{ width: getNodeWidth }}>
      {getHandlePositions}

      <Grid container flexWrap="nowrap" display="flex" gap={1}>
        {/* Node icon */}
        <Grid item xs={1.4} flexShrink={0} className={styles.loopEndCardType}>
          <CheckOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />
        </Grid>

        {/* Node title */}
        <Grid item xs display="flex" justifyContent="space-between" alignItems="center">
          <Grid item xs={5}>
            <Typography variant="label" variantColor={2}>
              LOOP END
            </Typography>
          </Grid>

          {/* Node parent loop name */}
          <Grid item display="flex" width="175px" overflow={"hidden"} justifyContent={"flex-end"}>
            <Typography noWrap style={{ maxWidth: "100%" }} variant="label" variantColor={2}>
              {props?.data?.parentLoopName ?? ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Node handle */}
      {!menuView && (
        <Handle style={{ opacity: 0 }} type="source" position={Position.Bottom} id="a" isConnectable={true} />
      )}
    </Card>
  )
}
