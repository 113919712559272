import React, { Fragment, useContext } from "react"

import { useQuery } from "react-query"

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import { Grid } from "@mui/material"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { MRT_ColumnDef } from "material-react-table"

import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { Deployment } from "../../types/generated/api/Deployment"
import { DeploymentSchemaFeature } from "../../types/generated/api/DeploymentSchemaFeature"
import { BaseTable } from "./BaseTable"

import styles from "../dialogs/WorkflowConfigurationDialog.module.scss"

export function ProjectSchemaTable(): React.ReactElement {
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  // Fetching project
  const { isLoading: isProjectLoading, data: project } = useQuery<AxiosResponse<Deployment>, AxiosError>(
    ["project", currentProject?.uuid],
    () => KonanAPI.fetchProject(currentProject?.uuid as string),
    {
      enabled: !!currentProject,
    },
  )

  // columns for project schema table
  const columns: MRT_ColumnDef<DeploymentSchemaFeature>[] = [
    {
      header: "Feature Name",
      accessorKey: "name",
      Cell: ({ row }) => {
        if (project?.data && project?.data?.schema?.length > 0) {
          return <Typography variant="p">{project?.data?.schema[row.index]?.name}</Typography>
        }
      },
    },
    {
      header: "Type",
      accessorKey: "type",
      Cell: ({ row }) => {
        if (project?.data && project?.data?.schema?.length > 0) {
          return (
            <Typography style={{ textTransform: "capitalize" }} variant="p">
              {project?.data?.schema[row.index]?.type?.toLowerCase()}
            </Typography>
          )
        }
      },
    },
    {
      header: "Required",
      accessorKey: "required",
      Cell: ({ row }) => {
        if (project?.data && project?.data?.schema?.length > 0) {
          return project?.data?.schema[row.index]?.is_required ? (
            <CheckOutlinedIcon className={styles.iconColor} fontSize="inherit" />
          ) : (
            <Typography variant="p">__</Typography>
          )
        }
      },
    },
    {
      header: `Source`,
      accessorKey: "source",
      Cell: ({ row }) => {
        if (project?.data && project?.data?.schema?.length > 0) {
          const sources =
            project?.data.category === Deployment.category.WORKFLOW
              ? project?.data?.schema[row.index].source_workflows
              : project?.data?.schema[row.index]?.source_models

          // Extracting the first item
          const firstItem = sources.slice(0, 1)

          // Checking if there are more items
          const moreItemsCount = sources.length - 1
          const hasMoreItems = moreItemsCount > 0

          // Rendering logic
          let renderedItems = firstItem
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ?.map((it: any) => {
              return `${it?.name} ${project?.data.category === Deployment.category.WORKFLOW ? `v${parseFloat(it?.version).toFixed(1)}` : ""}`
            })
            .join(", ")
          if (hasMoreItems) {
            renderedItems = (
              <Typography variant="p" noWrap>
                {`${renderedItems}`} <span style={{ textDecoration: "underline" }}>and {moreItemsCount} more</span>
              </Typography>
            )
          }

          return (
            <Tooltip
              placement="top"
              title={
                <Grid item padding={1}>
                  All workflows using this feature:
                  <Grid item container marginTop={0.5}>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {sources?.map((wf: any, idx: number) => {
                      return (
                        <Fragment key={`${wf?.name}`}>
                          {idx !== sources?.length - 1 || sources?.length === 1 ? "" : "and "}
                          {wf?.name}{" "}
                          {project?.data.category === Deployment.category.WORKFLOW
                            ? `v${parseFloat(wf?.version)?.toFixed(1)}`
                            : ""}
                          {idx !== sources?.length - 1 ? ", " : " "}
                        </Fragment>
                      )
                    })}
                  </Grid>
                </Grid>
              }
            >
              <Typography variant="p" noWrap>
                {sources.length > 0 ? renderedItems : "_"}
              </Typography>
            </Tooltip>
          )
        }
      },
    },
  ]
  return (
    <BaseTable
      data={project?.data && project?.data?.schema?.length > 0 ? project?.data?.schema : []}
      columns={columns as MRT_ColumnDef[]}
      disableToolbar
      title="Project Schema"
      pageSize={10}
      isLoading={!project?.data || isProjectLoading}
      stripped
      enableOrdering={false}
    />
  )
}
