import React from "react"

import { CardActions, Grid, Skeleton } from "@mui/material"
import { Tag, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import styles from "./SelectionCard.module.scss"

interface SelectionCardProps {
  item: {
    title: string
    createdAt: string
    state: string
  }
  tagVariant?: "positive" | "negative" | "warning" | "default"
  onClick?: () => void
  state?: "default" | "selected" | "pending"
}

/**
 * @param {object} item card item meta data
 * @param {"positive" | "negative" | "warning" | "default"} tagVariant (optional) variant for the tag to be displayed, defaults to "default"
 * @param {object} onClick (optional) on click action
 * @param {"default" | "selected" | "pending"} state (optional) card state, defaults to "default"
 * @return {React.ReactElement}
 */
export function SelectionCard(props: Readonly<SelectionCardProps>): React.ReactElement {
  const { item, onClick, tagVariant = "default", state = "default" } = props

  return (
    <CardActions
      onClick={() => {
        onClick?.()
      }}
      className={`${styles.card} ${state === "selected" && styles.selected} ${state === "pending" && styles.pending} ${!onClick && styles.noPointer}`}
    >
      <Grid container item xs={12} key={item.createdAt} gap={0.5}>
        <Grid item xs={12}>
          <Typography variant="span" variantColor={2}>
            {moment(new Date(item.createdAt)).format("MMM DD h:mma")}
          </Typography>
        </Grid>

        <Grid container item xs={12} gap={1} wrap="nowrap">
          <Grid item xs={7}>
            <Typography variant="a" variantColor={2} noWrap>
              {item.title}
            </Typography>
          </Grid>

          <Grid item xs display={"flex"} alignItems={"end"}>
            <Tag size="small" outline variant={tagVariant}>
              {item.state}
            </Tag>
          </Grid>
        </Grid>
      </Grid>
    </CardActions>
  )
}

/**
 * Loader component for the Selection card
 * @returns {React.ReactElement}
 */
export function SelectionLoaderCard(): React.ReactElement {
  return (
    <CardActions className={`${styles.card} ${styles.noPointer}`}>
      <Grid container item xs={12} gap={0.5}>
        <Grid item xs={12}>
          <Skeleton animation="wave" width="50%" />
        </Grid>

        <Grid container item xs={12} gap={1} wrap="nowrap">
          <Skeleton animation="wave" width="75%" />
        </Grid>
      </Grid>
    </CardActions>
  )
}
