import React, { Dispatch, SetStateAction } from "react"

import InfiniteScroll from "react-infinite-scroll-component"
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query"
import { useSearchParams } from "react-router-dom"

import { Grid, List, ListItemButton, ListItemText } from "@mui/material"
import { Skeleton, Tag, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { v4 as uuidv4 } from "uuid"

import { ConfirmCloseDialogState } from "../../../types/custom/workflows"
import { WorkflowList } from "../../../types/generated/api/WorkflowList"

import styles from "../Workflows.module.scss"

type Props = {
  activeWorkflowId: string
  activeWorkflowName: string
  setConfirmDialog: Dispatch<SetStateAction<ConfirmCloseDialogState>>
  setActiveWorkflow: Dispatch<SetStateAction<{ workflowName: string; workflowId: string }>>
  setIsEditMode: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderedWorkflows: any
  isWorkflowsLoading: boolean
  hasMore: boolean
  shouldFetchNext: boolean
  workflowsLength: number
  fetchNext: (
    options?: FetchNextPageOptions | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<InfiniteQueryObserverResult<AxiosResponse<any, any>, AxiosError<unknown, any>>>
}

export function WorkflowSidebar(props: Readonly<Props>): React.ReactElement {
  const {
    activeWorkflowId,
    activeWorkflowName,
    setConfirmDialog,
    setActiveWorkflow,
    setIsEditMode,
    formik,
    orderedWorkflows,
    workflowsLength,
    isWorkflowsLoading,
    hasMore,
    fetchNext,
    shouldFetchNext,
  } = props

  const [searchParams] = useSearchParams()

  // handler for selecting the active workflow based on the selection made from the side bar
  const handleSelectWorkflow = (name: string, uuid: string): void => {
    // only navigate if it's a different one than the already selected
    if (uuid !== activeWorkflowId) {
      if (activeWorkflowId === "new" || searchParams.get("EnableClose") === "false") {
        setConfirmDialog({ isOpen: true, action: { name: "switch", workflowName: name, workflowId: uuid } })
      } else {
        setActiveWorkflow({ workflowName: name, workflowId: uuid })
        setIsEditMode(false)
        formik.values.workflowName = ""
      }
    }
  }

  return (
    <Grid item container className={styles.sideBar} id="workflows-scrollable-target">
      <InfiniteScroll
        hasMore={hasMore}
        next={() => shouldFetchNext && fetchNext()}
        loader={
          <Grid container item xs={12} gap={1.5} pl={2} pr={1.5} pb={2}>
            {[1, 2, 3].map((item) => {
              return (
                <Grid item xs={12} key={item}>
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </Grid>
              )
            })}
          </Grid>
        }
        dataLength={orderedWorkflows?.length ?? 0}
        scrollableTarget="workflows-scrollable-target"
      >
        <Grid item>
          <List sx={{ paddingTop: "0px" }}>
            {activeWorkflowId === "new" && (
              <ListItemButton
                sx={{ marginBottom: "8px" }}
                key={`new-${workflowsLength ? workflowsLength + 1 : 1}-${uuidv4()}`}
                dense
                selected={
                  `Untitled-${workflowsLength ? workflowsLength + 1 : 1}` === activeWorkflowName ||
                  formik.values.workflowName === activeWorkflowName
                }
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="label"
                      style={{ alignSelf: "center" }}
                      color={
                        `Untitled-${workflowsLength ? workflowsLength + 1 : 1}` === activeWorkflowName ||
                        formik.values.workflowName === activeWorkflowName
                          ? "important"
                          : "neutral"
                      }
                      variantColor={2}
                      noWrap
                    >
                      {formik.values.workflowName?.length > 0 ? formik.values.workflowName : "Untitled"}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            {!isWorkflowsLoading &&
              orderedWorkflows?.length > 0 &&
              orderedWorkflows?.map((wf: WorkflowList) => {
                return (
                  <ListItemButton
                    sx={{ marginBottom: "8px" }}
                    key={`${wf?.uuid}-${orderedWorkflows?.length + 1}-${uuidv4()}`}
                    dense
                    alignItems="center"
                    selected={wf?.name === activeWorkflowName && activeWorkflowId !== "new"}
                    onClick={() => handleSelectWorkflow(wf?.name, wf?.uuid)}
                  >
                    <ListItemText
                      primary={
                        <Grid flexWrap={"wrap"} container>
                          <Grid item xs={wf?.is_deprecated ? 6 : 8} alignItems="center" alignSelf={"center"}>
                            <Typography
                              variant="label"
                              noWrap
                              color={
                                wf?.name === activeWorkflowName && activeWorkflowId !== "new" ? "important" : "neutral"
                              }
                              variantColor={2}
                            >
                              {wf?.name}
                            </Typography>
                          </Grid>

                          <Grid item xs={wf?.is_deprecated ? 6 : 4}>
                            {wf?.uuid !== "new" && (
                              <Tag
                                variant={
                                  wf?.is_deprecated ? "negative" : wf?.state === "DISABLED" ? "default" : "positive"
                                }
                                size="small"
                                style={{ marginLeft: "5px", justifySelf: "flex-end" }}
                              >
                                {wf?.name
                                  ? `${wf?.is_deprecated ? "Deprecated" : wf?.state === "DISABLED" ? "Idle" : "Active"}`
                                  : orderedWorkflows?.length > 0
                                    ? "Idle"
                                    : "Active"}
                              </Tag>
                            )}
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                )
              })}
          </List>
        </Grid>
      </InfiniteScroll>
    </Grid>
  )
}
