import { Button, NotificationUtils } from "@synapse-analytics/synapse-ui"

interface CopyUUIDButtonType {
  textToCopy: string
  disabled: boolean
  size?: "small" | "large" | "regular"
  isModel?: boolean
}

export const CopyUUIDButton = (props: CopyUUIDButtonType): React.ReactElement => {
  const { disabled, textToCopy, isModel } = props
  return (
    <Button
      onClick={(e) => {
        e?.stopPropagation()
        navigator.clipboard.writeText(textToCopy)
        NotificationUtils.toast("Copied to Clipboard!", { snackBarVariant: "positive" })
      }}
      disabled={disabled}
      variant="secondary"
      size={"regular"}
    >
      {`Copy ${isModel ? "Model" : "Project"} ID`}
    </Button>
  )
}
