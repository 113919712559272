import { useState } from "react"

import { Grid } from "@mui/material"
import { RadioButton, Typography } from "@synapse-analytics/synapse-ui"

import { BaseSimpleDialog, BaseSimpleDialogProps } from "../../../components/dialogs/BaseSimpleDialog"
import { FilterBranchDeletionOptions } from "./FilterNode"

/**
 * Props for the FilterDeletionDialog component.
 */
interface FilterDeletionDialogProps extends BaseSimpleDialogProps {
  /** Array of options to display in the dialog. */
  options: Array<FilterBranchDeletionOptions>

  /** Function to handle the acceptance of the selected option. */
  handleAccept: (selectedOption: FilterBranchDeletionOptions) => FilterBranchDeletionOptions
}

/**
 * A dialog component to select which node to delete, handle different cases when removing a filter node.
 *
 * @param {FilterDeletionDialogProps} props - The props for the FilterDeletionDialog component.
 * @returns A React element (Deletion dialog with options).
 */
export function FilterDeletionDialog(props: Readonly<FilterDeletionDialogProps>): React.ReactElement {
  const { options, handleAccept } = props

  const [selectedOption, setSelectedOption] = useState<FilterBranchDeletionOptions>(options[0])

  return (
    <BaseSimpleDialog disableMainBtn={!selectedOption} {...props} onAccept={() => handleAccept(selectedOption)}>
      <Grid item xs={12} display="flex" flexDirection="column">
        <Typography variant="h3-bold">Select which node to delete</Typography>

        <Grid item display="flex" xs={12} mt={1} gap={2}>
          {options?.map((option) => (
            <RadioButton
              key={option}
              label={option}
              name={option}
              value={option}
              checked={selectedOption === option}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target?.value !== selectedOption) {
                  setSelectedOption(e.target.value as FilterBranchDeletionOptions)
                }
              }}
            />
          ))}
        </Grid>
      </Grid>
    </BaseSimpleDialog>
  )
}
