import React, { Fragment, useCallback, useEffect, useState } from "react"

import { useQuery } from "react-query"

import CheckIcon from "@mui/icons-material/Check"
import RemoveIcon from "@mui/icons-material/Remove"
import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table"
import moment from "moment"

import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { ExportJobCreateRequest } from "../../types/generated/api/ExportJobCreateRequest"
import { ListPredictionsOutput } from "../../types/generated/api/ListPredictionsOutput"
import { Model } from "../../types/generated/api/Model"
import { PaginatedListModelOutputsList } from "../../types/generated/api/PaginatedListModelOutputsList"
import { PaginatedListPredictionsList } from "../../types/generated/api/PaginatedListPredictionsList"
import { getModelByUUID } from "../../utils/modelDetailsHelpers"
import { KonanJsonView } from "../KonanJsonView"
import { ExportDataDialog } from "../dialogs/ExportDataDialog"
import { BaseTableContainer } from "./BaseTableContainer"

type Props = {
  startDate: string
  endDate: string
  projectUUID: string
  modelUUID?: string
}

export function RequestLogTable(props: Readonly<Props>): React.ReactElement {
  const { startDate, endDate, projectUUID, modelUUID } = props
  const theme = getTheme()

  const [isExportDialogOpen, setIsExportDialogOpen] = useState<boolean>(false)

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  // Load request logs
  // Will load request logs from a model if modelUUID is supplied.
  // Otherwise, will load request logs from the entire project
  const { isFetching, data: response } = useQuery<
    AxiosResponse<PaginatedListModelOutputsList | PaginatedListPredictionsList>,
    AxiosError
  >(
    ["request-log", startDate, endDate, projectUUID, modelUUID, pagination],
    () =>
      modelUUID
        ? KonanAPI.fetchModelRequestLogData(
            startDate,
            endDate,
            modelUUID,
            pagination.pageIndex + 1,
            pagination.pageSize,
          )
        : KonanAPI.fetchProjectRequestLogData(
            startDate,
            endDate,
            projectUUID,
            pagination.pageIndex + 1,
            pagination.pageSize,
          ),
    { keepPreviousData: true },
  )

  // Loads the project's models
  // Used to figure out the names of each model in the request log
  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError>(["models", projectUUID], () =>
    KonanAPI.fetchModels(projectUUID),
  )

  const memoizedGetModelByUUID = useCallback((uuid: string) => getModelByUUID(uuid, models?.data), [models])

  // reset pagination when date range changes
  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }, [startDate, endDate])

  const columns: MRT_ColumnDef<ListPredictionsOutput>[] = [
    {
      header: "Request Date",
      accessorKey: "request_date",
      accessorFn: (row) => moment(row.created_at).utc().format("DD/MM/YYYY"),
    },
    {
      header: "Request Time (UTC)",
      accessorKey: "created_at_utc",
      accessorFn: (row) => moment(row.created_at).utc().format("hh:mm:ss A"),
    },
    {
      header: "Model Used",
      accessorKey: "model_name",
      accessorFn: (row) => {
        return row.model_name ?? memoizedGetModelByUUID(row.model)?.name
      },
    },
    {
      header: "Konan Status Code",
      accessorKey: "be_status_code",
      // casting to enable search
      accessorFn: (row) => row.be_status_code?.toString(),
      Cell: ({ row }) => {
        if (row.original.be_status_code) {
          return (
            <span
              style={{
                color: row.original.be_status_code >= 400 ? theme.palette.red.text[2] : theme.palette.green.text[2],
              }}
            >
              {row.original.be_status_code}
            </span>
          )
        } else {
          return <span>{row.original.be_status_code}</span>
        }
      },
    },
    {
      header: "Model Status Code",
      accessorKey: "mls_status_code",
      // casting to enable search
      accessorFn: (row) => row.mls_status_code?.toString(),
      Cell: ({ row }) => {
        if (row.original.mls_status_code) {
          return (
            <span
              style={{
                color: row.original.mls_status_code >= 400 ? theme.palette.red.text[2] : theme.palette.green.text[2],
              }}
            >
              {row.original.mls_status_code}
            </span>
          )
        } else {
          return <span style={{ color: theme.palette.yellow.text[2] }}>NA</span>
        }
      },
    },
    {
      header: "Requested By",
      accessorKey: "user",
    },
    {
      header: "Konan Response Time",
      accessorKey: "be_response_time",
      accessorFn: (row) => {
        if (row.be_response_time) return row.be_response_time + " ms"
        else {
          return <span style={{ color: theme.palette.yellow.text[2] }}>NA</span>
        }
      },
    },
    {
      header: "Model Response Time",
      accessorKey: "mls_response_time",
      accessorFn: (row) => {
        if (row.mls_response_time) return row.mls_response_time + " ms"
        else {
          return <span style={{ color: theme.palette.yellow.text[2] }}>NA</span>
        }
      },
    },
    {
      header: "Feedback",
      accessorKey: "feedback",
      Cell: ({ row }) => {
        return row.original.feedback ? <CheckIcon /> : <RemoveIcon />
      },
      enableColumnFilter: false,
    },
  ]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseJsonBody = (rowData: Record<string, any> | null, uuid: string): Record<string, any> => {
    const response = {
      uuid: uuid,
      ...rowData,
    }
    return response
  }

  return (
    <Fragment>
      {isExportDialogOpen && (
        <ExportDataDialog
          onClose={() => setIsExportDialogOpen(false)}
          jobType={
            modelUUID
              ? ExportJobCreateRequest.type.MODEL_PREDICTIONS
              : ExportJobCreateRequest.type.DEPLOYMENT_PREDICTIONS
          }
          model={modelUUID}
        />
      )}

      <BaseTableContainer
        title="Request Log"
        columns={columns}
        data={(modelUUID ? response?.data?.results : response?.data?.results?.outputs) ?? []}
        isLoading={isFetching}
        manualPagination
        pagination={pagination}
        source="api-requests"
        setPagination={setPagination}
        rowCount={response?.data.count}
        onExportClick={() => setIsExportDialogOpen(true)}
        detailPanel={(row) => {
          const rowData = row.original as ListPredictionsOutput
          return (
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={
                    rowData.feedback && rowData.workflow_output
                      ? 3
                      : rowData.feedback || rowData.workflow_output
                        ? 4
                        : 6
                  }
                >
                  <Typography variant="p" gutterBottom variantColor={2}>
                    Request Body
                  </Typography>
                  <KonanJsonView src={rowData.features} />
                </Grid>

                <Grid
                  item
                  xs={
                    rowData.feedback && rowData.workflow_output
                      ? 3
                      : rowData.feedback || rowData.workflow_output
                        ? 4
                        : 6
                  }
                >
                  <Typography variant="p" gutterBottom variantColor={2}>
                    Response
                  </Typography>
                  <KonanJsonView src={responseJsonBody(rowData.mls_output_json, rowData.uuid) ?? rowData.mls_output} />
                </Grid>

                {rowData.feedback && (
                  <Grid item xs={rowData.workflow_output ? 3 : 4}>
                    <Typography variant="p" gutterBottom variantColor={2}>
                      Feedback
                    </Typography>
                    <KonanJsonView src={rowData.feedback} />
                  </Grid>
                )}

                {rowData.workflow_output && (
                  <Grid item xs={rowData.feedback ? 3 : 4}>
                    <Typography variant="p" gutterBottom variantColor={2}>
                      Workflow Output
                    </Typography>
                    <KonanJsonView src={rowData.workflow_output} />
                  </Grid>
                )}
              </Grid>
            </Box>
          )
        }}
      />
    </Fragment>
  )
}
