import React, { useMemo } from "react"

import { useQuery } from "react-query"

import { Grid } from "@mui/material"
import { AxiosError } from "axios"
import { MRT_ColumnDef } from "material-react-table"

import { KonanPageHeader } from "../../components/KonanPageHeader"
import { BaseTable } from "../../components/tables/BaseTable"
import { KonanAPI } from "../../services/KonanAPI"
import { KonanRegistryImagesSeriliazer } from "../../types/generated/api/KonanRegistryImagesSeriliazer"
import { Auth } from "../../utils/auth"
import { RegistryCredentials } from "./components/RegistryCredentials"

type ModifiedImagesList = {
  id: number
  image_url: string
}

export function Registry(): React.ReactElement {
  const orgId = Auth.getOrganizationUID() as string

  const { isLoading, data } = useQuery<Array<KonanRegistryImagesSeriliazer>, AxiosError>(
    ["registry-images", orgId],
    () => KonanAPI.fetchRegistryImages(),
  )

  const modifiedData = useMemo(() => {
    const images: ModifiedImagesList[] = []
    data?.forEach((item, index) =>
      images.push({
        id: index + 1,
        image_url: item.image,
      }),
    )
    return images
  }, [data])

  const columns: MRT_ColumnDef<ModifiedImagesList>[] = [
    {
      header: "#",
      accessorKey: "id",
      enableResizing: true,
      maxSize: 10,
      size: 5,
      minSize: 0,
      enableColumnFilter: false,
    },
    {
      header: "Container Image URL",
      accessorKey: "image_url",
      enableResizing: true,
    },
  ]

  return (
    <div className="layoutPadding">
      <KonanPageHeader
        title="Konan Container Registry (KCR)"
        subtitle="View your organization's container images that are ready for project."
        mainHeader
      />
      <Grid container spacing={2} paddingTop={2}>
        <Grid item xs={12}>
          <BaseTable
            enableOrdering={false}
            disableToolbarActions={true}
            columns={columns}
            data={modifiedData}
            isLoading={isLoading}
            enableHiding={false}
            title={"KCR Container Images"}
          />
        </Grid>

        <Grid item xs={12}>
          <RegistryCredentials />
        </Grid>
      </Grid>
    </div>
  )
}
