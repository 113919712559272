import React, { useMemo, useState } from "react"

import InfiniteScroll from "react-infinite-scroller"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"

import { CircularProgress, Grid } from "@mui/material"
import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { KonanEmptyState, KonanEmptyStateFilter, KonanEmptyStateSearch } from "../components/KonanEmptyState"
import { KonanSubHeader } from "../components/KonanSubHeader"
import { BaseSimpleDialog } from "../components/dialogs/BaseSimpleDialog"
import { ProjectCard } from "../features/projects/ProjectCard"
import { ProjectCardLoader } from "../features/projects/ProjectCardLoader"
import { useHandlePageQueryParam } from "../hooks/useHandlePageQueryParam"
import { KonanAPI } from "../services/KonanAPI"
import { RootState } from "../store/ReduxStore"
import { Deployment } from "../types/generated/api/Deployment"
import { isPermitted } from "../utils/PermissionsHelpers"
import { Auth } from "../utils/auth"
import { filterListByState, searchListByName, sortList } from "../utils/searchSortFilterHelpers"

/**
 * Projects screen component
 * @return  {React.ReactElement}
 */
export function Projects(): React.ReactElement {
  const queryClient = useQueryClient()

  const permissions = Auth.getPermissions()
  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions?.flattenedKonanPermissions)

  const [openProjectDeletionDialog, setOpenProjectDeletionDialog] = useState<boolean>(false)

  // Search, sort, filter functionality states
  const [searchValue, setSearchValue] = useState<string>("")
  const [sortValue, setSortValue] = useState<string>("Most Recent")
  const [filterValue, setFilterValue] = useState<string[]>([])

  // INFO:: keeping this here since it will be used in the revamp
  const [tabValue, _] = useHandlePageQueryParam(["model", "workflow"], "model")

  // saves data of project to be deleted to pass to deletion dialog
  const [projectToDelete, setProjectToDelete] = useState<{ name: string; id: string }>({ name: "", id: "" })

  // Infinite scroller paging
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 9

  // Fetching projects
  const { isLoading, data: response } = useQuery<AxiosResponse<Array<Deployment>>, AxiosError>(["projects"], () =>
    KonanAPI.fetchProjects(),
  )

  // project deletion mutation
  const deleteProjectMutation = useMutation<AxiosResponse, AxiosError>(
    () => KonanAPI.deleteProject(projectToDelete.id),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Project successfully deleted.", {
          snackBarVariant: "positive",
        })

        setOpenProjectDeletionDialog(false)

        // Invalidate react-query queries
        await queryClient.invalidateQueries("projects")
        await queryClient.invalidateQueries(["project", projectToDelete.id])
      },
      onError: () => {
        NotificationUtils.toast("Project deletion failed.", {
          snackBarVariant: "negative",
        })
        handleDeleteProject(projectToDelete.name, projectToDelete.id)
      },
    },
  )

  // Create an adjustedProjects array that allows for searching and sorting projects
  const adjustedProjects = useMemo((): Deployment[] | undefined => {
    let adjustedProjects = response?.data ? Array.from(response?.data).filter((item) => item.category === tabValue) : []
    if (searchValue && searchValue.length > 0) {
      adjustedProjects = adjustedProjects ? searchListByName(adjustedProjects, searchValue) : []
      setCurrentPage(1)
    }
    if (sortValue && sortValue.length > 0) {
      adjustedProjects = adjustedProjects ? sortList(adjustedProjects, sortValue, "projects") : []
      setCurrentPage(1)
    }
    if (filterValue && filterValue.length > 0 && tabValue === "model") {
      adjustedProjects = adjustedProjects ? filterListByState(adjustedProjects, filterValue, "projects") : []
      setCurrentPage(1)
    }

    return adjustedProjects
  }, [response?.data, searchValue, sortValue, filterValue, tabValue])

  const handleDeleteProject = (name: string, id: string): void => {
    setProjectToDelete({ name, id })
    setOpenProjectDeletionDialog(true)
  }

  async function deleteProject(): Promise<void> {
    await deleteProjectMutation.mutateAsync()

    //  To prevent flickering of alerts when closing the dialog
    setTimeout(() => deleteProjectMutation.reset(), 300)
  }

  return (
    <div className="layoutPadding">
      {/* Project deletion dialog */}
      {openProjectDeletionDialog && (
        <BaseSimpleDialog
          open={openProjectDeletionDialog}
          isLoading={deleteProjectMutation.isLoading}
          name={projectToDelete.name}
          mode={"project-deletion"}
          onAccept={deleteProject}
          onClose={() => setOpenProjectDeletionDialog(false)}
        />
      )}

      <KonanSubHeader
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        sortValue={sortValue}
        setSortValue={setSortValue}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
        containerType="Project"
        sortOptions={["Most Recent", "Name"]}
        showButton={isPermitted("Add project", permissions.konan, flattenedKonanPermissions)}
        filterOptions={tabValue === "model" ? ["generic", "classification", "credit scoring"] : undefined}
      />

      {/* Show loader when request is still loading */}
      {isLoading && (
        <Grid container spacing={2}>
          {[1, 2].map((item: number) => (
            <Grid item xs={12} md={6} lg={4} key={item}>
              <ProjectCardLoader />
            </Grid>
          ))}
        </Grid>
      )}

      {/* Render projects in an infinite scrollbar for better UI/UI */}
      {adjustedProjects && adjustedProjects?.length !== 0 && response?.data.length !== 0 && (
        <InfiniteScroll
          pageStart={currentPage}
          loadMore={() => setCurrentPage(currentPage + 1)}
          hasMore={adjustedProjects?.length >= currentPage * pageSize}
        >
          <Grid container spacing={2}>
            {adjustedProjects.slice(0, currentPage * pageSize).map((project: Deployment) => (
              <Grid item key={project.uuid} xs={12} md={6} lg={4}>
                <ProjectCard project={project} onProjectDeletionClicked={handleDeleteProject} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      )}

      {/* circular progress to show when new cards are showing */}
      {adjustedProjects && adjustedProjects.length >= currentPage * pageSize && (
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1} mt={3} mb={1}>
          <CircularProgress size={36} />
        </Grid>
      )}

      {/**
       * Project screen empty states
       * 1. no projects yet
       * 2. search value couldn't be found
       * 3. filter value couldn't be found
       */}
      {response?.data.length === 0 && !isLoading ? (
        <Grid container xs={12} className={"empty-container"}>
          <KonanEmptyState
            title="No projects, yet."
            subTitle=" Create a new project to choose a use-case, or deploy your own model"
          />
        </Grid>
      ) : !isLoading && adjustedProjects?.length === 0 && (searchValue || (searchValue && filterValue)) ? (
        <Grid container xs={12} className={"empty-container"}>
          <KonanEmptyStateSearch title={searchValue} />
        </Grid>
      ) : (
        adjustedProjects?.length === 0 &&
        !isLoading &&
        filterValue &&
        !searchValue && (
          <Grid container xs={12} className={"empty-container"}>
            <KonanEmptyStateFilter title={filterValue} />
          </Grid>
        )
      )}
    </div>
  )
}
