import { SetStateAction, useEffect, useState } from "react"

import { useQuery } from "react-query"

import { Grid } from "@mui/material"
import { InputText, Select, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikProps } from "formik"

import { DataMappingTable } from "../../../components/tables/DataMappingTable"
import { KonanAPI } from "../../../services/KonanAPI"
import { CustomFeatureRequest, baseErrorType } from "../../../types/custom/projects"
import { ExcludedFeatureRequest } from "../../../types/generated/api/ExcludedFeatureRequest"
import { TrainingDataUpload } from "../../../types/generated/api/TrainingDataUpload"

type Props = {
  onDataChange: (mapping: Array<CustomFeatureRequest>, excludedColumns: Array<ExcludedFeatureRequest>) => void
  createdProjectUUID: string
  fileHeaders: Array<string> | undefined
  formik: FormikProps<{ target_column: string; name: string; targetColumnType: string }>
  isSchemaLoading: boolean
  errors: AxiosError<baseErrorType> | null
  trainingDataUUID: string
}

/**
 * The component for data mapping view (Target column, file headers table)
 * @param onDataChange setter for the upper state (excluded columns and mapping arrays)
 * @param createdProjectUUID projectUUID
 * @param fileHeaders CSV file headers passed to the data mapping columns
 * @param formik Formik controller
 * @param errors Axios errors
 * @return {React.ReactElement}
 */
export function DataMappingFlow(props: Props): React.ReactElement {
  const {
    onDataChange,
    createdProjectUUID: projectUUID,
    fileHeaders,
    formik,
    errors,
    isSchemaLoading,
    trainingDataUUID,
  } = props

  const [mapping, setMapping] = useState<Array<CustomFeatureRequest>>([])

  const { data: fetchPredefinedTypesData } = useQuery<AxiosResponse>(
    ["predefined-types", projectUUID],
    () => KonanAPI.fetchAutomlPredefinedTypes({ projectUUID, type: "credit_scoring" }),
    {
      enabled: !!projectUUID,
    },
  )

  // fetch single training data
  const { data: trainingData } = useQuery<TrainingDataUpload, AxiosError<baseErrorType>>(
    ["training-data", projectUUID, trainingDataUUID],
    () => KonanAPI.fetchSingleTrainingData(projectUUID, trainingDataUUID),
    {
      enabled: !!projectUUID && !!trainingDataUUID,
      refetchInterval: 3000,
    },
  )

  // updating the mapping whenever pre-defined types or target column input change
  useEffect(() => {
    if (formik.values.targetColumnType === "none" || !formik.values.targetColumnType) {
      formik.setFieldValue("targetColumnType", "default")
    }
    if (formik.values.target_column && fileHeaders && fileHeaders.length > 0) {
      for (const col of fileHeaders) {
        if (col === formik.values.target_column) {
          // setTimeout to avoid autocomplete flickring
          setTimeout(() => {
            setMapping((prevMapping: SetStateAction<CustomFeatureRequest[]>) => {
              if ((prevMapping as CustomFeatureRequest[]).some((row: CustomFeatureRequest) => row.target === true)) {
                return (prevMapping as CustomFeatureRequest[]).map((row: CustomFeatureRequest) =>
                  row.target === true
                    ? {
                        name: formik.values.target_column,
                        type:
                          formik.values.targetColumnType === "none" || formik.values.targetColumnType === "default"
                            ? null
                            : formik.values.targetColumnType,
                        target: true,
                      }
                    : row,
                )
              } else {
                return [
                  ...(prevMapping as CustomFeatureRequest[]),
                  {
                    name: formik.values.target_column,
                    target: true,
                    type:
                      formik.values.targetColumnType === "none" || formik.values.targetColumnType === "default"
                        ? null
                        : formik.values.targetColumnType,
                  },
                ]
              }
            })
          }, 5)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileHeaders, formik.values.targetColumnType, formik.values.target_column])

  return (
    <Grid container item spacing={2} padding={0.5}>
      {/* TODO: refactor this error checking when we have error schema on endpoints from the BE */}
      {errors?.response?.data?.details && (
        <Grid item xs={12} mb={1}>
          <Snackbar variant="negative" fullWidth description={errors?.response?.data?.details} />
        </Grid>
      )}
      {errors?.response?.data?.name && errors?.response?.data?.name[0] && (
        <Grid item xs={12} mb={1}>
          <Snackbar variant="negative" fullWidth description={errors?.response?.data?.name[0]} />
        </Grid>
      )}
      {errors?.response?.data?.error && (
        <Grid item xs={12} mb={1}>
          <Snackbar variant="negative" fullWidth description={errors?.response?.data?.error} />
        </Grid>
      )}
      {errors?.response?.data?.mapping && (
        <Grid item xs={12} mb={1}>
          <Snackbar variant="negative" fullWidth description="Please make sure to select a type for target column" />
        </Grid>
      )}
      {errors?.response?.data?.target_column && (
        <Grid item xs={12} mb={1}>
          <Snackbar variant="negative" fullWidth description={errors?.response?.data?.target_column[0]} />
        </Grid>
      )}
      {errors?.response?.data?.excluded_columns && (
        <Grid item xs={12} mb={1}>
          <Snackbar variant="negative" fullWidth description={errors?.response?.data?.excluded_columns[0]} />
        </Grid>
      )}
      {(trainingData?.status === "pending" || trainingData?.status === "processing") && (
        <Grid item xs={12} mb={1}>
          <Snackbar
            variant="warning"
            fullWidth
            description={`The training data status is ${trainingData?.status}, please wait until it finishes and train the model`}
          />
        </Grid>
      )}
      {trainingData?.status === "error" && (
        <Grid item xs={12} mb={1}>
          <Snackbar
            variant="negative"
            fullWidth
            description="The processing of training data failed, please upload a valid training dataset then try again"
          />
        </Grid>
      )}

      <Grid item>
        <Typography variant="h3-bold">Choose Target</Typography>
      </Grid>

      <Grid item xs={12} justifyContent="space-between" container>
        <Grid item xs={5.9}>
          <InputText
            label="Target column"
            id="target_column"
            placeholder="Target Column"
            required
            value={formik.values.target_column}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.touched.target_column && Boolean(formik.errors.target_column) && formik.errors.target_column}
            fullWidth
            options={fileHeaders ?? []}
            key={fileHeaders ? fileHeaders[0] : ""}
          />
        </Grid>
        <Grid item xs={5.9}>
          <Grid item xs={12} mb={0.5}>
            <Typography variant="span">Type</Typography>
          </Grid>

          <Select
            fullWidth
            id="targetColumnType"
            hideDescription
            value={formik.values.targetColumnType || "default"}
            handleChange={formik.handleChange}
            optionsWithValues={[
              { label: "Choose Type", value: "default", disabled: true },
              { label: "Choose none", value: "none" },
              //  eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(fetchPredefinedTypesData?.data?.results?.map((item: any) => {
                return { label: item.name, value: item.name }
              }) ?? []),
            ]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataMappingTable
          data={fetchPredefinedTypesData?.data?.results ?? []}
          onDataChange={onDataChange}
          fileHeaders={fileHeaders}
          isSchemaLoading={isSchemaLoading}
          targetColumn={formik.values.target_column}
          targetColumnType={formik.values.targetColumnType}
          mapping={mapping}
          setMapping={setMapping}
        />
      </Grid>
    </Grid>
  )
}
