import React from "react"

import { Card, Grid } from "@mui/material"
import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import styles from "../TrainingJobs.module.scss"

function EvaluationDataCardLoader(): React.ReactElement {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="flex-start"
      className={styles.dataCard}
    >
      <Skeleton height={10} width={"100%"} />
      <Skeleton height={10} width={"100%"} />
    </Grid>
  )
}

export function SuccessfulTrainingCardLoader(): React.ReactElement {
  return (
    <Card className="card-box-shadow">
      <Grid container direction="column" className={styles.headerContainer}>
        <Grid container item xs={12}>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={3} sx={{ marginTop: "4px" }}>
              <Typography variant="p" noWrap>
                <Skeleton variant="rectangular" height={15} width={"80%"} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={6} className={styles.nameLoader}>
              <Typography variant="p" noWrap>
                <Skeleton variant="rectangular" height={15} width={"60%"} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center" className={styles.trainingDataAvatarContainer}>
            <Grid item sx={{ marginTop: "5px" }}>
              <Skeleton variant="circular" width={32} height={32} />
            </Grid>
            <Grid direction="column" container item style={{ marginTop: "6px" }}>
              <Grid item xs={6} sx={{ marginBottom: "6px", marginTop: "8px" }}>
                <Typography variant="p" noWrap>
                  <Skeleton variant="rectangular" height={10} width={"80%"} />
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <Typography variant="span" noWrap>
                  <Skeleton variant="rectangular" height={10} width={"30%"} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="column" className={styles.bodyContainer}>
        <Grid container item direction="row" alignItems="flex-end">
          <Grid container item direction="row">
            <Grid item xs={12} sm={9}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                <Typography variant="label" className={styles.labelColor}>
                  Source Model
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container className={styles.sourceModelSectionLoader}>
            <Skeleton height={80} width={"100%"} />
          </Grid>
        </Grid>
        <Grid container item direction="row" className={styles.twoBoxesContainer}>
          <div className={styles.testWidthLeft}>
            <Typography variant="label" className={styles.labelColor}>
              Started
            </Typography>
            <Grid container className={styles.idbox}>
              <Grid item xs={12}>
                <Typography variant="span" className={styles.dateText}>
                  <Skeleton height={10} width="100%" />
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={styles.testWidthRight}>
            <Typography variant="label" className={styles.labelColor}>
              Ended
            </Typography>
            <Grid container className={styles.idbox}>
              <Grid item xs={12}>
                <Typography variant="span" className={styles.dateText}>
                  <Skeleton height={10} width="100%" />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid container item direction="row" className={styles.twoBoxesContainer}>
          <div className={styles.testWidthLeft}>
            <Typography variant="label" className={styles.labelColor} noWrap>
              Serving/Training Data
            </Typography>
            <Grid container className={styles.idbox}>
              <Grid item xs={12}>
                <Typography variant="span" className={styles.dateText}>
                  <Skeleton height={10} width="100%" />
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={styles.testWidthRight}>
            <Typography variant="label" className={styles.labelColor}>
              Data Split
            </Typography>
            <Grid container className={styles.idbox}>
              <Grid item xs={12}>
                <Typography variant="span" className={styles.dateText}>
                  <Skeleton height={10} width="100%" />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid container item direction="row">
          <Grid container item direction="row">
            <Grid item xs={12} sm={9}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Typography variant="label" className={styles.labelColor}>
                  Evaluation Metrics
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className={styles.horizontalScrolling}>
          {[1, 2, 3].map((_, index: number) => (
            <Grid item xs={4} key={index}>
              <EvaluationDataCardLoader />
            </Grid>
          ))}
        </Grid>

        <Grid container item direction="row" alignItems="flex-end" style={{ paddingLeft: 0 }}>
          <Grid container item direction="row">
            <Grid item xs={12} sm={9}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                <Typography variant="label" className={styles.labelColor}>
                  Generated Model
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="flex-start"
          className={styles.sourceModelSectionLoader}
          item
        >
          <Skeleton width={"100%"} height={80} />
        </Grid>
      </Grid>
    </Card>
  )
}
