import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react"

import { useLocation, useNavigate } from "react-router-dom"

import queryString from "query-string"

/**
 * This custom hook gathers all the logic of redirecting to the current training job/training data
 * tab in both training jobs page and training data page.
 * so, status of the current job/training data = current tab value = current query param value
 */
export function useHandleTrainingQueryParam(
  tabValues: string[],
  defaultTabValue: string,
): [string, Dispatch<SetStateAction<string>>] {
  const location = useLocation()

  const navigate = useNavigate()

  const queryParams = useMemo(() => queryString?.parse(location.search), [location.search])

  const [tabValue, setTabValue] = React.useState<string>(queryParams?.tab ? `${queryParams?.tab}` : defaultTabValue)
  /**
   * Effect to make sure tab value will be the default tab value if we have
   * an invalid value in the query-param
   */
  useEffect(() => {
    if (queryParams?.tab && !tabValues?.includes(`${queryParams?.tab}`)) {
      setTabValue(defaultTabValue)
    } else if (queryParams?.tab && tabValues?.includes(`${queryParams?.tab}`)) {
      setTabValue(`${queryParams?.tab}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname, queryParams?.tab])

  // change the queryparam to match the current tab whenever the tabvalue changes
  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `tab=${tabValue}`,
      },
      {
        replace: true,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue])

  return [tabValue, setTabValue]
}
