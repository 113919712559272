import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"

import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Divider, Grid, Skeleton } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from ".."
import { KonanEmptyState } from "../components/KonanEmptyState"
import { SelectionCard, SelectionLoaderCard } from "../components/cards/SelectionCard"
import { DebouncedInput } from "../components/form/DebouncedInput"
import { SimulationReport, SimulationTagMap } from "../features/Simulations"
import { KonanAPI } from "../services/KonanAPI"
import { PaginatedWorkflowSimulationJobList } from "../types/generated/api/PaginatedWorkflowSimulationJobList"
import { WorkflowSimulationJob } from "../types/generated/api/WorkflowSimulationJob"
import { extractPageFromBackEndPaginationLink } from "../utils/genericHelpers"

import styles from "./Simulations.module.scss"

/**
 * Workflow Simulations screen
 * @returns {React.ReactElement}
 */
export function Simulations(): React.ReactElement {
  const { id: projectId } = useParams<{ id: string }>()

  const [selectedCard, setSelectedCard] = useState<{ uuid: string; name: string } | null>(null)
  const [searchValue, setSearchValue] = useState<string | null>(null)

  // caching simulations so that when searching
  // the content doesn't go blank/change till the user selects a report from the side menu
  const cachedSimulation = useRef<WorkflowSimulationJob | undefined>(undefined)

  const {
    isLoading: isReportsLoading,
    isFetchingNextPage,
    data: reportsData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AxiosResponse<PaginatedWorkflowSimulationJobList>, AxiosError>(
    ["SimulationReports", projectId, searchValue],
    ({ pageParam = 1 }) =>
      KonanAPI.fetchSimulations({
        projectUUID: projectId as string,
        pageSize: 20,
        page: pageParam,
        ...(!!searchValue && { workflow_name: searchValue }),
      }),
    {
      onSuccess: (response) => {
        if (!selectedCard) {
          const report = response.pages[0].data.results?.[0]
          if (report) {
            setSelectedCard({ uuid: report.uuid, name: report.workflow_name })
            cachedSimulation.current = { ...report, status: report.status === "Sent" ? "Success" : report.status }
          }
        }
      },
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },
      enabled: !!projectId,
      refetchOnMount: true,
    },
  )

  // extracting the results after infinite-Query
  const adjustedReports = useMemo(() => {
    return (
      reportsData?.pages
        .reduce((accumulator, page) => {
          return accumulator.concat(page.data.results)
        }, [])
        .map((item: WorkflowSimulationJob): WorkflowSimulationJob => {
          return {
            ...item,
            status: item.status === "Sent" ? "Success" : item.status,
          }
        }) ?? []
    )
  }, [reportsData?.pages])

  // to prevent table from using cached data while the report is loading
  useEffect(() => {
    return () => {
      queryClient.resetQueries({ queryKey: ["SimulationReports", projectId] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container item xs={12} display={"flex"} flex={1} wrap="nowrap" className={styles.root}>
      {isReportsLoading || (!isReportsLoading && (adjustedReports.length > 0 || !!searchValue)) ? (
        // TODO:: refactor into an HoC to make it easily reusable
        <Fragment>
          {/* Side-bar */}
          <Grid item className={styles.sideBar}>
            <Grid container item xs={12} p={2} pb={0}>
              <Grid item xs={12}>
                <Typography variant="label" textTransform="uppercase">
                  Simulation Reports
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <DebouncedInput
                  callback={(value: string | null) => {
                    setSearchValue(value)
                  }}
                  fullWidth
                  variant={"filled"}
                />
              </Grid>

              <Divider
                orientation="horizontal"
                style={{
                  backgroundColor: "var(--grayscale-border)",
                  width: "100%",
                  marginTop: "8px",
                  marginBottom: "12px",
                }}
              />
            </Grid>

            <Grid item xs={12} className={styles.sideBar_content} id="simulation-scrollable-target">
              {isReportsLoading ? (
                <Grid container item xs={12} gap={1.5} pl={2} pr={1.5} pb={2}>
                  {[1, 2, 3].map((item) => {
                    return (
                      <Grid item xs={12} key={item}>
                        <SelectionLoaderCard />
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <InfiniteScroll
                  dataLength={adjustedReports.length ?? 0}
                  next={() => !isReportsLoading && !isFetchingNextPage && fetchNextPage()}
                  hasMore={hasNextPage ?? false}
                  loader={
                    <Grid container item xs={12} gap={1.5} pl={2} pr={1.5} pb={2}>
                      {[1, 2, 3].map((item) => {
                        return (
                          <Grid item xs={12} key={item}>
                            <SelectionLoaderCard />
                          </Grid>
                        )
                      })}
                    </Grid>
                  }
                  scrollableTarget={"simulation-scrollable-target"}
                >
                  <Grid container item xs={12} gap={1.5} pl={2} pr={1.5} pb={2}>
                    {adjustedReports.length > 0 ? (
                      adjustedReports.map((report: WorkflowSimulationJob) => (
                        <Grid item xs={12} key={report.uuid}>
                          <SelectionCard
                            item={{ createdAt: report.created_at, title: report.workflow_name, state: report.status }}
                            state={
                              report.uuid === selectedCard?.uuid
                                ? "selected"
                                : ["Running", "Pending"].includes(report.status)
                                  ? "pending"
                                  : "default"
                            }
                            onClick={() => {
                              setSelectedCard({ uuid: report.uuid, name: report.workflow_name })
                              cachedSimulation.current = report
                              // to prevent table from using cached data while in fact it doesnt belong to the report being fetched
                              queryClient.resetQueries({ queryKey: ["simulation-outputs", projectId] })
                            }}
                            tagVariant={SimulationTagMap[report.status]}
                          />
                        </Grid>
                      ))
                    ) : (
                      <KonanEmptyState
                        title={`No results for '${searchValue}'`}
                        subTitle="Adjust the search value and try again"
                      />
                    )}
                  </Grid>
                </InfiniteScroll>
              )}
            </Grid>
          </Grid>

          <Grid item xs style={{ overflowX: "hidden", height: "100%" }}>
            {/* header */}
            <Grid item xs={12} className={styles.header}>
              {isReportsLoading && !searchValue ? (
                <Skeleton animation="wave" width="200px" />
              ) : (
                <Typography variant="h2-bold">{selectedCard?.name}</Typography>
              )}
            </Grid>

            {/* Body */}
            <Grid item xs={12} p={2} className={styles.content_container}>
              <SimulationReport
                report={
                  adjustedReports.find((report) => selectedCard?.uuid === report.uuid) ?? cachedSimulation.current
                }
                isLoading={isReportsLoading && !searchValue}
              />
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <KonanEmptyState title="No reports, yet." subTitle={"Create your first report from the workflow screen."} />
      )}
    </Grid>
  )
}
