import React, { useState } from "react"

import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Grid, Skeleton } from "@mui/material"
import { DateRangePicker, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import moment, { Moment } from "moment"

import { KonanAPI } from "../../../services/KonanAPI"
import { baseErrorType } from "../../../types/custom/projects"
import { DeploymentPredictionsSummary } from "../../../types/generated/api/DeploymentPredictionsSummary"
import { KonanTimeHelper } from "../../../utils/genericHelpers"
import { LiveDataViewProps } from "../Interfaces"

/**
 * Simulation live model's data screen
 * @param {(date: Moment | undefined) => void} setFormikStartDate
 * @param {(date: Moment | undefined) => void} setFormikEndDate
 * @param {(date: number) => void} setFormikRequestsNumber
 * @return {React.ReactElement}
 */
export function LiveDataView(props: Readonly<LiveDataViewProps>): React.ReactElement {
  const { setFormikStartDate, setFormikEndDate, setFormikRequestsNumber } = props

  const { id: projectId } = useParams<{ id: string }>()

  const [startDate, setStartDate] = useState<moment.Moment | null>(moment().subtract(29, "days"))
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment())

  const konanTime = new KonanTimeHelper()

  // fetching api requests to extract # feedback adn # requests
  const { isLoading: isApiRequestsLoading, data: apiRequests } = useQuery<
    AxiosResponse<DeploymentPredictionsSummary>,
    AxiosError<baseErrorType>
  >(
    ["apiRequests", projectId, startDate, endDate],
    () =>
      KonanAPI.fetchApiRequestsSummary({
        project_uuid: projectId as string,
        start_date: startDate?.toISOString() as string,
        end_date: endDate?.toISOString() as string,
      }),
    {
      onSuccess: (response) => {
        setFormikRequestsNumber(response.data.total)
      },
      enabled: !!projectId && !!startDate && !!endDate,
    },
  )

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <Typography variant="label" variantColor={2}>
          Live Data
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(startDate) => {
            setStartDate(startDate)
            setFormikStartDate(konanTime.adjustDate(startDate, "start") as Moment)
          }}
          onEndDateChange={(endDate) => {
            setEndDate(endDate)
            setFormikEndDate(konanTime.adjustDate(endDate, "end") as Moment)
          }}
          disableFuture
        />
      </Grid>

      <Grid item xs={12}>
        {isApiRequestsLoading ? (
          <Skeleton animation="wave" width="30%" height="60%" />
        ) : (
          <Typography variant="span">
            {apiRequests?.data.total} requests and {apiRequests?.data.feedback_count} feedback
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} mt={1}>
        {0 === apiRequests?.data.feedback_count && !isApiRequestsLoading && (
          <Snackbar
            variant="warning"
            fullWidth
            title="No Feedback Found"
            description="Without feedback, we can't show workflow performance in the report. Choose a different date range with feedback"
          />
        )}
      </Grid>
    </Grid>
  )
}
