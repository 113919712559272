import React from "react"

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"
import { Typography } from "@synapse-analytics/synapse-ui"

type Props = {
  value: number
  isUploading: boolean
}

export function LinearProgressWithLabel(props: Props): React.ReactElement {
  const { isUploading } = props
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          sx={{
            "& .MuiLinearProgress-bar": {
              backgroundColor: "var(--green-background-1)",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      {!isUploading ? (
        <CheckCircleOutlineIcon fontSize="small" style={{ color: "var(--green-background-1)", marginTop: "-5px" }} />
      ) : (
        <Typography variant="span">{Math.round(props.value)}%</Typography>
      )}
    </Box>
  )
}
