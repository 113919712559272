import React, { Fragment, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import { Card, CardContent, CardHeader, CircularProgress, Grid } from "@mui/material"
import { Button, InputText, NotificationUtils, Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikProvider, useFormik } from "formik"
import { isEqual } from "lodash"

import { VersionTag } from "../../components/VersionTag"
import { VersioningDialog } from "../../components/dialogs/VersioningDialog"
import { KonanAPI } from "../../services/KonanAPI"
import { CreateScoreCardRequest, UpdateScorecardRequest } from "../../types/custom/projects"
import { VersionChangeRequest } from "../../types/custom/rules"
import { ScoreCardCreateUpdate } from "../../types/generated/api/ScoreCardCreateUpdate"
import { ScoreCardCreateUpdateRequest } from "../../types/generated/api/ScoreCardCreateUpdateRequest"
import { ScoreCardGroupRetrieve } from "../../types/generated/api/ScoreCardGroupRetrieve"
import { ScoreCardList } from "../../types/generated/api/ScoreCardList"
import { ScoreCardRetrieve } from "../../types/generated/api/ScoreCardRetrieve"
import { parseConditionIntoString } from "../../utils/conditionHelpers"
import { extractScorecardErrorsMessages, getValidMaxMinorVersion } from "../../utils/deploymentDetailsHelpers"
import { isEnvVariableTruthy } from "../../utils/genericHelpers"
import { convertComplexConditionsToNestedForm } from "../../utils/rulesetHelpers"
import { ScorecardLoadingComponent } from "../ScoreCardSets/Scorecardset"
import { ScorecardMenu } from "../Scorecards/Scorecard"
import { ConfigCard } from "./ConfigCard"
import { WeightTable } from "./WeightTable"
import { ScoreTableProps } from "./interfaces"

import styles from "./ScoreTables.module.scss"

// Yup validation schema
const validationSchema = Yup.object({
  name: Yup.string().min(1).required("Name is required"),
  features: Yup.array()
    .of(Yup.string().required("Feature is required"))
    .test({
      test: (arr) => (arr as string[]).length > 0,
    }),
  criteria: Yup.array().of(
    Yup.array().of(
      Yup.object().shape({
        // Only validating the existence of the feature since the rest are being validated in the onSubmit
        feature: Yup.string().required("Feature is required"),
      }),
    ),
  ),
})

export function ScoreTable(props: Readonly<ScoreTableProps>): React.ReactElement {
  const {
    scorecard,
    isCreateMode = false,
    handleCancel,
    goToFirstPage,
    disableDuplicateButton,
    duplicateScoreTable,
    isDuplicateMode = false,
  } = props
  const { id: projectId } = useParams<{ id: string }>()

  const queryClient = useQueryClient()

  const RuleTablesEnabledFeatureFlag = isEnvVariableTruthy(window.__RUNTIME_CONFIG__.RULES_TABLES_ENABLED)
  const MaxFeaturesFeatureFlag = Number(window.__RUNTIME_CONFIG__.RULES_TABLES_MAXIMUM_FEATURES)

  const [tab, setTab] = useState<"Configuration" | "Weights">(isCreateMode ? "Configuration" : "Weights")

  const [mode, setMode] = useState<"read" | "edit" | "create">(isCreateMode ? "create" : "read")

  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)
  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false)

  /**
   * Fetches the scorecard data from the server.
   *
   * @returns The scorecard data and loading status.
   */
  const {
    data: scorecardData,
    isLoading: isScorecardDataLoading,
    isRefetching: isScorecardDataRefetching,
  } = useQuery<AxiosResponse<ScoreCardGroupRetrieve>, AxiosError>(
    ["scorecard", projectId, scorecard?.uuid],
    async () => {
      if (isDuplicateMode) {
        const data = queryClient.getQueryData<AxiosResponse<ScoreCardGroupRetrieve>>([
          "scorecard",
          projectId,
          scorecard?.uuid,
        ])
        if (data) {
          return data
        }
      }
      return await KonanAPI.fetchScorecard(projectId as string, scorecard?.uuid as string)
    },
    {
      onSuccess: async ({ data }) => {
        const activeVersion = data?.versions?.find((version: ScoreCardRetrieve) => version?.is_active_version === true)

        const adjustedCriteria = Object.values(activeVersion?.criteria)
          ?.reduce((acc, criteria) => (acc ?? []).concat([criteria]), [])
          ?.map((criteria) =>
            criteria.map((item) => {
              const parseCondition = convertComplexConditionsToNestedForm(item.condition)

              return { ...item, ...parseCondition[0][0] }
            }),
          )

        await formik.setValues({
          name: scorecard?.name ?? data?.name,
          uuid: data?.uuid,
          format: activeVersion?.format,
          features: activeVersion?.features,
          criteria: adjustedCriteria,
          criteria_files: activeVersion?.criteria_files,
          results: activeVersion?.results,
        })
      },
      // Only enable the query if the scorecard?.uuid is not undefined
      enabled: !!scorecard?.uuid,
    },
  )

  const createScoreCardMutation = useMutation<
    AxiosResponse,
    AxiosError<CreateScoreCardRequest>,
    CreateScoreCardRequest
  >(KonanAPI.createScorecard, {
    mutationKey: "createScorecard",
    onSuccess: async () => {
      await queryClient.invalidateQueries(["scorecards", projectId])

      // closing the scorecard view upon creation
      handleCancel?.()
      NotificationUtils.toast("Scorecard successfully created", {
        snackBarVariant: "positive",
      })

      goToFirstPage?.()
    },
    onError: async (response: AxiosError<CreateScoreCardRequest>) => {
      const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "create")

      NotificationUtils.toast(errorMessage, {
        snackBarVariant: "negative",
      })
    },
  })

  const updateScorecardMutation = useMutation<
    AxiosResponse<ScoreCardCreateUpdate>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    UpdateScorecardRequest
  >(KonanAPI.updateScorecard, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["scorecard", scorecard?.uuid])
      await queryClient.invalidateQueries(["scorecards", projectId])

      setMode("read")

      NotificationUtils.toast("Scorecard updated successfully!", {
        snackBarVariant: "positive",
      })
    },
    onError: async (response: AxiosError<UpdateScorecardRequest>) => {
      const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "update")

      NotificationUtils.toast(errorMessage, {
        snackBarVariant: "negative",
      })
    },
  })

  const formik = useFormik({
    initialValues: {
      uuid: "",
      name: "",
      format: "table",
      features: ["", ""],
      criteria: [
        [
          {
            feature: "",
            condition: "",
            parsedCondition: ["", "=", ""],
            type: "string",
            operator: "equal",
            value: "",
          },
        ],
        [
          {
            feature: "",
            condition: "",
            parsedCondition: ["", "=", ""],
            type: "string",
            operator: "equal",
            value: "",
          },
        ],
      ],
      criteria_files: [],
      results: [],
    },
    validationSchema: validationSchema,
    isInitialValid: false,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async ({ criteria, features, uuid, results, name }) => {
      const activeTableVersion = scorecardData?.data?.versions?.find(
        (version: ScoreCardRetrieve) => version?.is_active_version === true,
      )

      // TODO:: rename plural criteria to criterion in the score--tables module
      const criterion = criteria
      const adjustedCriteria = criterion.map((criteria) =>
        criteria.map((item) => {
          return {
            feature: item.feature,
            // TODO:: check later
            condition: `(${parseConditionIntoString(item)})`,
          }
        }),
      )

      const permutationsCount = criteria.reduce((acc, criteriaArray) => acc * criteriaArray.length, 1)

      let adjustedResults = results.map((result) => ([null, undefined, ""].includes(result) ? 0 : result))

      if (adjustedResults.length < permutationsCount)
        adjustedResults = adjustedResults.concat(Array(permutationsCount - adjustedResults.length).fill(0))
      else if (adjustedResults.length > permutationsCount) adjustedResults = adjustedResults.slice(0, permutationsCount)

      const shouldSendTableDataWhenUpdating = !(
        isEqual(adjustedCriteria, activeTableVersion?.criteria) &&
        isEqual(features, activeTableVersion?.features) &&
        isEqual(adjustedResults, activeTableVersion.results)
      )

      if (mode === "edit") {
        await updateScorecardMutation.mutateAsync({
          projectUUID: projectId as string,
          scorecardUUID: uuid,
          name: name !== scorecardData?.data?.name ? name : undefined,
          ...(shouldSendTableDataWhenUpdating
            ? {
                results: adjustedResults,
                features: features,
                criteria: adjustedCriteria,
              }
            : {}),
        })
      } else if (mode === "create") {
        await createScoreCardMutation.mutateAsync({
          projectUUID: projectId as string,
          name: name,
          results: adjustedResults,
          features: features,
          format: ScoreCardCreateUpdateRequest.format.TABLE,
          criteria: adjustedCriteria,
        })
      }
    },
  })

  const handleCancelEdit = (): void => {
    if (mode === "create") {
      handleCancel?.()

      return
    }

    setMode("read")

    const activeVersion = scorecardData?.data.versions?.find(
      (version: ScoreCardRetrieve) => version?.is_active_version === true,
    )

    const adjustedCriteria = Object.values(activeVersion?.criteria)
      ?.reduce((acc, criteria) => (acc ?? []).concat([criteria]), [])
      ?.map((criteria) =>
        criteria.map((item) => {
          const parseCondition = convertComplexConditionsToNestedForm(item.condition)
          return { ...item, ...parseCondition[0][0] }
        }),
      )

    formik.setValues({
      name: scorecardData?.data?.name as string,
      uuid: scorecardData?.data?.uuid as string,
      format: activeVersion?.format,
      features: activeVersion?.features,
      criteria: adjustedCriteria,
      criteria_files: activeVersion?.criteria_files,
      results: activeVersion?.results as number[],
    })
  }

  const handleAddingBucket = (): void => {
    formik.setFieldValue("results", [])

    formik.setFieldValue("criteria", [
      ...formik.values.criteria,
      [
        {
          feature: "",
          condition: "",
          parsedCondition: ["", "=", ""],
          type: "string",
          operator: "equal",
          value: "",
        },
      ],
    ])

    formik.setFieldValue("features", [...formik.values.features, ""])
  }

  // TODO:: refactor later
  // children component for the versioning dialog for scorecard
  const ScoreTableVersioningContainer = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})
    const [dialogTab, setDialogTab] = useState<"Configuration" | "Weights">("Weights")

    /**
     * Mutation hook for changing the version of a resource.
     *
     * @param {VersionChangeRequest} requestData - The request data for changing the version.
     * @returns {void}
     */
    const versionSwitchMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
      KonanAPI.changeResourceVersion,
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["scorecard", scorecard?.uuid])
          await queryClient.invalidateQueries(["scorecards", projectId])
          setIsVersionDialogOpen(false)
        },
      },
    )

    const activeVersion = scorecardData?.data.versions?.find(
      (version: ScoreCardRetrieve) => version?.is_active_version === true,
    )

    const Version = useMemo(() => {
      if (scorecardData && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(scorecardData.data?.versions, selectedVersion)
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of scorecardData.data?.versions) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, scorecardData])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeVersion?.version

    const tagVariant = activeVersion?.version === selectedVersion ? "positive" : "default"

    const adjustedCriteria = Object.values(Version?.criteria)
      ?.reduce((acc, criteria) => (acc ?? []).concat([criteria]), [])
      ?.map((criteria) =>
        criteria.map((item) => {
          const parseCondition = convertComplexConditionsToNestedForm(item.condition)

          return { ...item, ...parseCondition[0][0] }
        }),
      )

    // simpler formik specific for editing the current scorecard
    const versionFormik = useFormik({
      initialValues: {
        name: formik?.values.name,
        uuid: formik?.values.uuid,
        format: Version?.format,
        features: Version?.features,
        criteria: adjustedCriteria,
        criteria_files: Version?.criteria_files,
        results: Version?.results,
      },
      onSubmit: () => {
        return
      },
    })

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {scorecardData?.data?.name}
            </Typography>

            <VersionTag
              version={isNaN(Number(Version?.version)) ? undefined : (Number(Version?.version) ?? selectedVersion)}
              variant={Version ? tagVariant : "positive"}
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() =>
                versionSwitchMutation.mutateAsync({
                  projectUUID: projectId as string,
                  resourceUUID: scorecard?.uuid as string,
                  version: Version?.version as string,
                  resource: "scorecards",
                })
              }
              disabled={versionSwitchMutation.isLoading}
            >
              {versionSwitchMutation.isLoading ? <CircularProgress size={12} /> : "Restore"}
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0} mt={1}>
          <Grid container>
            <Grid container mt={1} gap={1} display={"flex"} justifyContent={"space-between"}>
              <Tabs value={dialogTab}>
                <Tab
                  label="Rule Weights"
                  value="Weights"
                  selected={dialogTab === "Weights"}
                  // To reset container image shifting from one mode to another
                  onClick={() => setDialogTab("Weights")}
                />
                <Tab
                  label="Table Configuration"
                  value="Configuration"
                  selected={dialogTab === "Configuration"}
                  // To reset container image shifting from one mode to another
                  onClick={() => setDialogTab("Configuration")}
                />
              </Tabs>
            </Grid>

            <Grid container item display={"flex"} xs={12} mt={3} gap={2}>
              <FormikProvider value={versionFormik}>
                {dialogTab === "Configuration" ? (
                  <Fragment>
                    {formik.values.criteria.map((_, index) => (
                      <Grid item xs={12} key={index}>
                        <ConfigCard index={index} mode={mode} />
                      </Grid>
                    ))}
                  </Fragment>
                ) : (
                  <WeightTable mode={mode} />
                )}
              </FormikProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      {/* Versioning Dialog */}
      {isVersionDialogOpen && (
        <VersioningDialog
          isOpen={isVersionDialogOpen}
          onClose={() => setIsVersionDialogOpen(false)}
          versions={scorecardData?.data?.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          maxWidth="lg"
          activeVersion={
            scorecardData?.data.versions?.find(
              (version: ScoreCardRetrieve) => version?.is_active_version === true,
            ) as ScoreCardRetrieve
          }
        >
          <ScoreTableVersioningContainer />
        </VersioningDialog>
      )}

      <Card className={styles.card}>
        <CardHeader
          className={styles.cardHeader}
          title={
            <Grid container justifyContent={"space-between"} alignItems={"center"} maxWidth="100%">
              {mode !== "read" ? (
                <Grid container item xs={6} md={3} width="fit-content">
                  <InputText
                    id={`name`}
                    hideDescription
                    placeholder="Name"
                    value={formik.values.name}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    disabled={formik.isSubmitting}
                    fullWidth
                  />
                </Grid>
              ) : (
                <Grid container item xs={6} width="fit-content">
                  <Typography style={{ width: "100%" }} className={styles.cardTitle} noWrap variant="h3-bold">
                    {formik.values.name}
                  </Typography>
                </Grid>
              )}

              <Grid container item xs={12} sm={6} justifyContent="flex-end">
                {mode === "read" ? (
                  <Grid item gap={1} display={"flex"} alignSelf={"flex-end"}>
                    <Grid item>
                      <Button
                        size="regular"
                        variant="secondary"
                        onClick={() => duplicateScoreTable?.(scorecard as ScoreCardList)}
                        disabled={Boolean(formik.isSubmitting || disableDuplicateButton)}
                      >
                        Duplicate
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="regular"
                        variant="secondary"
                        onClick={() => {
                          setSelectedVersion(
                            scorecardData?.data.versions?.find(
                              (version: ScoreCardRetrieve) => version?.is_active_version === true,
                            )?.version,
                          )
                          setIsVersionDialogOpen(true)
                        }}
                      >
                        History
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="regular"
                        variant="secondary"
                        onClick={() => setMode("edit")}
                        disabled={formik.isSubmitting || !RuleTablesEnabledFeatureFlag}
                        tooltip={!RuleTablesEnabledFeatureFlag ? "Score tables are currently unavailable" : undefined}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item gap={1} display={"flex"} alignSelf={"flex-end"}>
                    <Grid item>
                      <Button
                        disabled={formik.isSubmitting}
                        onClick={handleCancelEdit}
                        size="regular"
                        variant={"secondary"}
                      >
                        cancel
                      </Button>
                    </Grid>
                    <Grid item alignSelf="flex-start">
                      <Button
                        variant="primary"
                        size="regular"
                        onClick={() => formik.submitForm()}
                        disabled={formik.isSubmitting || !formik.isValid}
                        isLoading={formik.isSubmitting}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          }
          action={
            mode === "read" && <ScorecardMenu name={formik.values.name} uuid={formik.values.uuid} mode="ScoreTable" />
          }
        />

        <CardContent className={styles.cardContent}>
          <Grid container>
            <Grid container mt={1} gap={1} display={"flex"} justifyContent={"space-between"}>
              <Grid item>
                <Tabs value={tab}>
                  <Tab
                    label="Rule Weights"
                    value="Weights"
                    selected={tab === "Weights"}
                    // To reset container image shifting from one mode to another
                    onClick={() => setTab("Weights")}
                  />
                  <Tab
                    label="Table Configuration"
                    value="Configuration"
                    selected={tab === "Configuration"}
                    // To reset container image shifting from one mode to another
                    onClick={() => setTab("Configuration")}
                  />
                </Tabs>
              </Grid>

              {tab === "Configuration" && mode !== "read" && (
                <Grid item display={"flex"} direction={"column"} alignItems={"flex-end"} gap={1}>
                  <Grid item>
                    <Button
                      variant="secondary"
                      onClick={handleAddingBucket}
                      disabled={
                        formik?.values?.criteria?.length >= MaxFeaturesFeatureFlag && MaxFeaturesFeatureFlag !== -1
                      }
                      tooltip={
                        formik?.values?.criteria?.length >= MaxFeaturesFeatureFlag && MaxFeaturesFeatureFlag !== -1
                          ? `Maximum number of features (${MaxFeaturesFeatureFlag}) reached`
                          : undefined
                      }
                    >
                      Add Feature
                    </Button>
                  </Grid>

                  <Grid item>
                    <Typography variant="label">Adding a feature will reset all weights</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid container item display={"flex"} xs={12} mt={3} gap={2}>
              {isScorecardDataLoading || isScorecardDataRefetching ? (
                <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <ScorecardLoadingComponent />
                </Grid>
              ) : (
                <FormikProvider value={formik}>
                  {tab === "Configuration" ? (
                    <Fragment>
                      {formik.values.criteria.map((_, index) => (
                        <Grid item xs={12} key={index}>
                          <ConfigCard index={index} mode={mode} />
                        </Grid>
                      ))}
                    </Fragment>
                  ) : (
                    <WeightTable mode={mode} />
                  )}
                </FormikProvider>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  )
}
