import { styled } from "@mui/material"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"

import { getTheme } from "../../hooks/UseTheme"

/**
 * TODO: remove this and replace MUI tooltips with SUI tooltips, after refactoring and
 * solving the stacking issues that currently exisiting...
 */
export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => {
  const theme = getTheme()

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme?.shadow,
      padding: "4px 8px",
      borderRadius: "4px",
      color: theme?.palette?.grayscale?.text[2],
      backgroundColor: theme?.palette?.grayscale?.background[3],
      border: `1px solid ${theme?.palette?.grayscale?.border}`,
      fontFamily: '"Inter", sans-serif',
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
    },
  }
})
