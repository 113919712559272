import React, { useMemo, useState } from "react"

import { useMutation, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import * as Yup from "yup"
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material"
import { useTheme as useMuiTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Button, InputText, NotificationUtils, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import moment from "moment"

import { KonanAPI } from "../../services/KonanAPI"
import { WorkflowSimulationJobCreateRequest } from "../../types/custom/projects"
import { WorkflowSimulationJobCreate } from "../../types/generated/api/WorkflowSimulationJobCreate"
import { KonanTimeHelper } from "../../utils/genericHelpers"
import { SimulationCreationDialogProps } from "./Interfaces"
import { DataSource } from "./components/DataSource"

import styles from "./Simulations.module.scss"

const validationSchema = Yup.object({
  source: Yup.string().required("Data source is required!"),
  totalRequestsNumber: Yup.number(),
  datafile_columns: Yup.array().of(Yup.string()),
  predictions_datafile: Yup.string().when("source", {
    is: (source: string) => source !== "Live Data",
    then: (schema) => schema.required("Data file is required!").min(1),
  }),
  target: Yup.string(),
  predictions_start_time: Yup.string().when("source", {
    is: "Live Data",
    then: (schema) => schema.required("Start time is required!"),
  }),
  predictions_end_time: Yup.string().when("source", {
    is: "Live Data",
    then: (schema) => schema.required("End time is required!"),
  }),
})

/**
 * Workflow Simulation flow dialog
 * @param {boolean} open
 * @param {function} onClose
 * @param {string} workflowID
 * @param {string} workflowName
 * @return {React.ReactElement}
 */
export function SimulationCreationDialog(props: Readonly<SimulationCreationDialogProps>): React.ReactElement {
  const { open, onClose, workflowID, workflowName } = props

  const { id: projectID } = useParams<{ id: string }>()

  const konanTime = new KonanTimeHelper()

  const MuiTheme = useMuiTheme()
  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState<number>(0)

  const createSimulationMutation = useMutation<
    AxiosResponse<WorkflowSimulationJobCreate>,
    AxiosError,
    WorkflowSimulationJobCreateRequest
  >((props: WorkflowSimulationJobCreateRequest) => KonanAPI.createSimulation(props, workflowID), {
    mutationKey: "simulation-create",
    onSuccess: async () => {
      NotificationUtils.toast("Simulation Created Successfully", {
        snackBarVariant: "positive",
      })
      await queryClient.invalidateQueries(["SimulationReports", projectID])

      handleCLoseDialog()
    },
    onError: async () => {
      NotificationUtils.toast("An error occurred while creating Simulation", {
        snackBarVariant: "negative",
      })
    },
  })

  const formik = useFormik({
    initialValues: {
      workflowID: workflowID,
      source: "Live Data",
      totalRequestsNumber: 0,
      // check false in case there are no required features
      shouldProceed: true,
      predictions_datafile: "",
      datafile_columns: [""],
      predictions_start_time: konanTime.adjustDate(moment().subtract(29, "days"), "start"),
      predictions_end_time: konanTime.adjustDate(moment(), "end"),
      target_column: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await createSimulationMutation.mutateAsync({
        projectUUID: projectID as string,
        ...(values.source === "Live Data"
          ? {
              predictions_start_time: values.predictions_start_time?.toISOString(),
              predictions_end_time: values.predictions_end_time?.toISOString(),
            }
          : {
              predictions_datafile: values.predictions_datafile,
              target_column: ["", null, undefined].includes(values.target_column) ? null : values.target_column,
            }),
      })

      // TODO:: should navigate to the report just created using search params
      navigate(`/projects/${projectID}/SimulationReports`)
    },
  })

  const isWarningMode = useMemo((): boolean => {
    if (
      ["New Dataset", "Existing Dataset"].includes(formik.values.source) &&
      formik.values.predictions_datafile.length > 0 &&
      !formik.values.shouldProceed
    ) {
      return true
    }

    return false
  }, [formik.values.predictions_datafile.length, formik.values.shouldProceed, formik.values.source])

  // Credit scoring steps with action buttons, description, etc...
  const steps = [
    {
      name: "Choose Data Source",
      btnText: formik.values.source !== "Live Data" ? (isWarningMode ? "Proceed anyway" : "Next") : "Simulate",
      component: <DataSource formik={formik} />,
      visible: true,
    },
    {
      name: "Choose Target Column (Optional)",
      btnText: "Simulate",
      component: (
        <InputText
          id="target"
          label="Target Column (Optional)"
          placeholder="Key"
          value={formik.values.target_column ?? ""}
          handleChange={(e) => {
            formik.setFieldValue("target_column", e.target.value)
          }}
          handleBlur={formik.handleBlur}
          disabled={formik.isSubmitting}
          key={formik.values.datafile_columns ?? [""]}
          options={formik.values.datafile_columns ?? [""]}
          error={formik.touched.target_column && Boolean(formik.errors.target_column) && formik.errors.target_column}
          fullWidth
          required
        />
      ),
      visible: formik.values.source !== "Live Data",
    },
  ]

  /**
   * generic click actions function to be called when needed
   * to make it easier to than using switch cases and if conditions
   * @return  {void}
   */
  const clickActions = (): void => {
    switch (activeStep) {
      case 0:
        formik.values.source !== "Live Data" ? setActiveStep(activeStep + 1) : formik.submitForm()
        break
      case 1:
        formik.submitForm()
        break
      default:
        break
    }
  }

  // handles dialog closing
  // 1. closes dialog
  // 2. sets active step to 0 (UX)
  // 3. sets the data source to Live Data (UX)
  const handleCLoseDialog = (): void => {
    onClose()

    setTimeout(() => {
      setActiveStep(0)
      formik.resetForm()
    }, 300)
  }

  const shouldDisableSimulateButton =
    !formik.isValid ||
    createSimulationMutation.isLoading ||
    (formik.values.source === "Live Data" && formik.values.totalRequestsNumber === 0)

  return (
    <Dialog
      open={open}
      onClose={handleCLoseDialog}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { overflow: "hidden" } }}
    >
      <DialogTitle className="dialog-header-base" style={{ backgroundColor: "var(--grayscale-background-2)" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2-bold">Simulation</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="a" variantColor={2}>
              {workflowName}
            </Typography>
          </Grid>

          <Grid item xs={12} mt={1.5}>
            <Stepper activeStep={activeStep} connector={null}>
              {steps.map(
                (step, index) =>
                  step.visible && (
                    <Step key={step.name} style={{ paddingLeft: index === 0 ? "0px" : "8px" }}>
                      <StepLabel>
                        <Typography
                          variant={index === activeStep ? "h3-bold" : "h3-regular"}
                          variantColor={index === activeStep ? 1 : 2}
                        >
                          {step.name}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ),
              )}
            </Stepper>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={styles.flowContainer}>
        <Grid container item xs={12}>
          {steps[activeStep].component}
        </Grid>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
          <Box display={"flex"}>
            {activeStep === 1 && (
              <Button
                variant="secondary"
                onClick={() => {
                  setActiveStep(activeStep - 1)
                }}
                id="retest"
                disabled={createSimulationMutation.isLoading}
              >
                Back
              </Button>
            )}
          </Box>

          <Box display={"flex"}>
            <Box mr={1}>
              <Button onClick={handleCLoseDialog} disabled={createSimulationMutation.isLoading} variant="secondary">
                Cancel
              </Button>
            </Box>

            <Box>
              <Tooltip
                placement="top"
                title={
                  formik.values.source === "Live Data" && formik.values.totalRequestsNumber === 0
                    ? "No requests sent in the selected date range"
                    : ""
                }
              >
                <Button
                  onClick={clickActions}
                  disabled={shouldDisableSimulateButton}
                  variant="primary"
                  className={isWarningMode ? styles.nextBtn : ""}
                  tooltip={isWarningMode && "Dataset columns are missing some required features"}
                  tooltipPlacement="top"
                >
                  {formik.isSubmitting ? <CircularProgress size={20} color="inherit" /> : steps[activeStep].btnText}
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
