import React, { Fragment, useEffect, useMemo, useState } from "react"

import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import { Grid, useMediaQuery, useTheme as useMuiTheme } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { MRT_PaginationState } from "material-react-table"
import moment from "moment"

import { KonanEmptyState } from "../../components/KonanEmptyState"
import { InfoBlock } from "../../components/containers/InfoBlock"
import { BasePieChart } from "../../components/graphs/PieChart"
import { SankeyChart } from "../../components/graphs/SankeyChart"
import { TopFeaturesGraph } from "../../components/graphs/TopFeaturesGraph"
import { SimulationOutputTable } from "../../components/tables/SimulationOutputTable"
import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { NodePercentage } from "../../types/generated/api/NodePercentage"
import { PaginatedWorkflowSimulationOutputList } from "../../types/generated/api/PaginatedWorkflowSimulationOutputList"
import { WorkflowSimulationJobReport } from "../../types/generated/api/WorkflowSimulationJobReport"
import { PieChartContainerProps, SimulationReportProps, TopDataFeaturesProps } from "./Interfaces"

import styles from "./Simulations.module.scss"

/**
 * Pie chart container to hold the data and select state to enable selecting which label  to display
 * @param {boolean} isLoading
 * @param {number} index
 * @param data
 * @returns {React.ReactElement}
 */
function PieChartContainer(props: Readonly<PieChartContainerProps>): React.ReactElement {
  const { isLoading, data, index } = props
  const [selectedReason, setSelectedReason] = useState<string>("")

  // sets the selected reason using the index passed when rendering after loading
  // ensuring that on initial load no label is selected twice
  useEffect(() => {
    !isLoading && setSelectedReason(data[index].label.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <BasePieChart
      title={`${selectedReason} Reasons`}
      graphHeight={150}
      isLoading={isLoading}
      data={
        data.find((item) => item.label.name === selectedReason)?.reasons ?? [
          {
            name: "",
            value: 0,
            percent: 0,
          },
        ]
      }
      label={
        data.find((item) => item.label.name === selectedReason)?.label ?? {
          name: "",
          value: 0,
          percent: 0,
        }
      }
      selectProps={{
        options: data.map((item) => item.label.name),
        value: selectedReason,
        setValue: (value) => {
          setSelectedReason(value)
        },
      }}
      headerMode="select"
    />
  )
}

/**
 * Simulation Report container
 * @param {WorkflowSimulationJob} report simulation report metadata
 * @param {isLoading} isLoading (optional)
 * @returns {React.ReactElement}
 */
export function SimulationReport(props: Readonly<SimulationReportProps>): React.ReactElement {
  const { isLoading: isContainerLoading = false, report } = props
  const { id: projectId } = useParams<{ id: string }>()

  const theme = getTheme()
  const muiTheme = useMuiTheme()

  const mdQuery = useMediaQuery(muiTheme.breakpoints.down("md"))
  const smQuery = useMediaQuery(muiTheme.breakpoints.down("sm"))

  const [filterObject, setFilterObject] = useState<object>({})

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  // fetch simulations report
  const {
    isLoading: isSimulationReportDataLoading,
    data: simulationReportData,
    isFetched: isReportFetched,
  } = useQuery<AxiosResponse<WorkflowSimulationJobReport>, AxiosError>(
    ["SimulationReport", projectId, report?.uuid],
    () =>
      KonanAPI.fetchSimulationReportCharts({
        project_uuid: projectId as string,
        simulation_uuid: report?.uuid as string,
      }),
    {
      enabled: !!projectId && !!report?.uuid && report?.status === "Success",
    },
  )

  // fetch simulations outputs
  const {
    isLoading: isSimulationOutputLoading,
    data: simulationOutput,
    isFetching: isSimulationOutputFetching,
  } = useQuery<AxiosResponse<PaginatedWorkflowSimulationOutputList>, AxiosError>(
    ["simulation-outputs", projectId, report?.uuid, pagination, filterObject],
    () =>
      KonanAPI.fetchSimulationOutputs({
        projectUUID: projectId as string,
        simulation_uuid: report?.uuid as string,
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        filterObject: filterObject,
      }),
    {
      enabled: !!projectId && !!report?.uuid && report?.status === "Success" && !!isReportFetched,
      keepPreviousData: true,
    },
  )

  /**
   * Date range extractor in the following form {DD:MM:YYYY (start) -> DD:MM:YYYY (end)}
   * @return {React.ReactElement | string}
   */
  const reportCardDateRange = useMemo((): React.ReactElement | string => {
    return report?.predictions_start_time && report?.predictions_end_time ? (
      <Grid container wrap="nowrap">
        <Grid item>
          <Typography variant="p" noWrap>
            {moment(report?.predictions_start_time).format("MMM DD YYYY")}
          </Typography>
        </Grid>
        <Grid item>
          <ArrowRightAltIcon fontSize="small" className={styles.dateIcon} />
        </Grid>
        <Grid item>
          <Typography variant="p" noWrap>
            {moment(report?.predictions_end_time).format("MMM DD YYYY")}
          </Typography>
        </Grid>
      </Grid>
    ) : (
      "No date range"
    )
  }, [report?.predictions_end_time, report?.predictions_start_time])

  // extracting TopFeaturesGraph labels and their features data
  const TopFeaturesData = useMemo(() => {
    const features: TopDataFeaturesProps[] = []

    if (simulationReportData?.data.report.labels && simulationReportData?.data.report.labels.length > 0)
      for (const item of simulationReportData?.data.report.labels) {
        features.push({
          features: item.features,
          label: item.name,
        })
      }

    return features
  }, [simulationReportData?.data.report.labels])

  // decision funnel data extractor
  const DecisionFunnelData = useMemo(() => {
    const data: {
      nodes: { id: string; uuid: string; color: string; percentage: number; nodeType: string; name: string }[]
      links: {
        source: string
        target: string
        value: number
        percentage: number
        nodeType: string
        name: string
      }[]
    } = {
      nodes: [],
      links: [],
    }

    const GetNodeColor = (node: string): string => {
      enum Theme {
        "ruleset" = theme.palette.pink.background[2],
        "label" = theme.palette.red.background[2],
        "program" = theme.palette.orange.background[2],
        "scorecardset" = theme.palette.yellow.background[2],
        "filter" = theme.palette.purple.background[2],
        "calculator" = theme.palette.teal.background[2],
        "taglist" = theme.palette.green.background[2],
        "script" = theme.palette.neutral.background.disabled,
        "project" = theme.palette.blue.background[2],
      }

      return Theme[node]
    }

    const getNodeTitle = (item: NodePercentage): string => {
      switch (item.node_type) {
        case "calculator":
          return item.equation as string
        case "filter":
          return item.condition?.slice(2, -2) as string
        case "label":
          return item.return_label?.name as string
        case "project":
          return item.title.split(":")[1].split("#")[0].trim() ?? item.title
        default:
          return item.name as string
      }
    }

    simulationReportData?.data.report.node_percentages.forEach((item) => {
      data.nodes.push({
        id: item.uuid,
        uuid: item.uuid,
        nodeType: item.node_type,
        name: getNodeTitle(item),
        color: GetNodeColor(item.node_type),
        percentage: item.percentage,
      })
    })

    simulationReportData?.data.report.node_percentages.forEach((item) => {
      if (item.node_type === "filter") {
        const true_route = data.nodes.find((object) => object.uuid === item.true_route)
        true_route &&
          data.links.push({
            source: item.uuid,
            target: true_route.id,
            nodeType: true_route.nodeType,
            name: true_route.name,
            // TODO:: fix when backend return the number of requests per node
            value: Math.round((true_route.percentage * simulationReportData?.data.total_requests) / 100),
            percentage: true_route.percentage,
          })

        const false_route = data.nodes.find((object) => object.uuid === item.false_route)
        false_route &&
          data.links.push({
            source: item.uuid,
            target: false_route.id,
            nodeType: false_route.nodeType,
            name: false_route.name,
            // TODO:: fix when backend return the number of requests per node
            value: Math.round((false_route.percentage * simulationReportData?.data.total_requests) / 100),
            percentage: false_route.percentage,
          })
      } else if (item.resolution_route) {
        const resolution_route = data.nodes.find((object) => object.uuid === item.resolution_route)
        resolution_route &&
          data.links.push({
            source: item.uuid,
            target: resolution_route?.id ?? "",
            nodeType: resolution_route.nodeType,
            name: resolution_route.name,
            // TODO:: fix when backend return the number of requests per node
            value: Math.round(((resolution_route?.percentage ?? 0) * simulationReportData?.data.total_requests) / 100),
            percentage: resolution_route?.percentage ?? 0,
          })
      } else if (item.uncovered_route) {
        const uncovered_route = data.nodes.find((object) => object.uuid === item.uncovered_route)

        uncovered_route &&
          data.links.push({
            source: item.uuid,
            target: uncovered_route.id ?? "",
            nodeType: uncovered_route.nodeType,
            name: uncovered_route.name,
            // TODO:: fix when backend return the number of requests per node
            value: Math.round(((uncovered_route?.percentage ?? 0) * simulationReportData?.data.total_requests) / 100),
            percentage: uncovered_route?.percentage ?? 0,
          })
      }
    })

    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationReportData?.data])

  // preprocessing simulation report data to map it in data that can be rendered in the pie charts
  const reasons = useMemo(() => {
    const reasonList: {
      reasons: { name: string; value: number; percent: number }[]
      label: { name: string; percent: number; value: number }
    }[] = []

    const loadingList = new Array(3).fill({ reasons: [], label: { name: "Loading", percent: 1, value: 1 } })

    simulationReportData?.data.report.labels.forEach((item) => {
      const totalItems = item.total

      const subReasons: { name: string; value: number; percent: number }[] = []
      const subLabel = {
        name: item.name,
        percent: Math.round((item.total / simulationReportData?.data.total_requests) * 100),
        value: item.total,
      }

      item.breakdown.forEach((breakdownItem) => {
        subReasons.push({
          name: `${breakdownItem.name ?? breakdownItem.return_label?.name ?? ""} (${
            breakdownItem.node_type.charAt(0).toUpperCase() + breakdownItem.node_type.slice(1)
          })`,
          value: breakdownItem.count,
          percent: Math.round((breakdownItem.count / totalItems) * 100),
        })
      })

      reasonList.push({ reasons: subReasons, label: subLabel })
    })

    return { reasonList, loadingList }
  }, [simulationReportData?.data.report.labels, simulationReportData?.data.total_requests])

  const isReportDataLoading =
    isContainerLoading ||
    isSimulationReportDataLoading ||
    (simulationReportData?.data?.total_requests &&
      simulationReportData?.data?.total_requests > 0 &&
      simulationReportData?.data?.total_requests !== simulationReportData?.data?.total_failed)

  return (
    <Grid container spacing={2}>
      {!["Running", "Pending"].includes(report?.status ?? "") ? (
        // Simulation Report
        <Fragment>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} lg={4}>
              <InfoBlock
                title="# Requests"
                text={simulationReportData?.data?.total_requests ?? "N/A"}
                isLoading={isSimulationReportDataLoading || isContainerLoading}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InfoBlock
                title="Dataset"
                text={report?.predictions_datafile_name ?? "Live Predictions"}
                isLoading={isSimulationReportDataLoading || isContainerLoading}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InfoBlock
                title="Date Range"
                text={reportCardDateRange}
                isLoading={isSimulationReportDataLoading || isContainerLoading}
              />
            </Grid>
          </Grid>

          {isReportDataLoading ? (
            <Fragment>
              {(isSimulationReportDataLoading || isContainerLoading ? reasons.loadingList : reasons.reasonList)
                // only showing 3 cards (max)
                .slice(0, smQuery ? 1 : mdQuery ? 2 : 3)
                .map((_, index) => {
                  return (
                    <Grid item sm={12} md={6} lg={4} key={index}>
                      <PieChartContainer
                        isLoading={isSimulationReportDataLoading || isContainerLoading}
                        data={reasons.reasonList}
                        index={index}
                      />
                    </Grid>
                  )
                })}

              <Grid item xs={12}>
                <SankeyChart
                  title="Decision funnel"
                  graphHeight={340}
                  data={DecisionFunnelData}
                  isLoading={isSimulationReportDataLoading || isContainerLoading}
                />
              </Grid>

              <Grid item xs={12}>
                <TopFeaturesGraph
                  title="Top Features"
                  graphHeight={340}
                  showAxis={false}
                  visible
                  isLoading={isSimulationReportDataLoading || isContainerLoading}
                  data={TopFeaturesData ?? []}
                />
              </Grid>

              <Grid item xs>
                <SimulationOutputTable
                  data={simulationOutput?.data ?? undefined}
                  isLoading={isSimulationOutputLoading || isContainerLoading || isSimulationReportDataLoading}
                  isFetching={isSimulationOutputFetching}
                  pagination={pagination}
                  setPagination={setPagination}
                  filterObject={filterObject}
                  setFilterObject={setFilterObject}
                  uuid={report?.uuid}
                />
              </Grid>
            </Fragment>
          ) : (
            // Failed simulations empty state
            <Grid container item height={"100%"} xs={12} alignSelf={"center"}>
              <KonanEmptyState
                title={
                  report?.status === "Failed" ||
                  simulationReportData?.data?.total_requests === simulationReportData?.data?.total_failed
                    ? "Simulation Failed"
                    : "Simulation Reports unavailable"
                }
                subTitle={
                  report?.status === "Failed"
                    ? report.failure_reason
                    : simulationReportData?.data?.total_requests === simulationReportData?.data?.total_failed
                      ? "All requests failed. Rerun using different data"
                      : "Please check another report."
                }
              />
            </Grid>
          )}
        </Fragment>
      ) : (
        // Empty Simulation list empty state
        <Grid container item height={"100%"} xs={12} alignSelf={"center"}>
          <KonanEmptyState title={`Report is currently ${report?.status}`} subTitle={"Please wait"} />
        </Grid>
      )}
    </Grid>
  )
}
