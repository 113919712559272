import React from "react"

import { Grid, Skeleton } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "./InfoBlock.module.scss"

interface InfoContainerProps {
  title?: string
  text: string | number | React.ReactElement
  bordered?: boolean
  isLoading?: boolean
  alignText?: string
}

/** */
export function InfoBlock(props: Readonly<InfoContainerProps>): React.ReactElement {
  const { title, text, bordered = true, isLoading = false, alignText = "left" } = props

  return (
    <Grid container gap={0.75}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="label" textTransform="capitalize">
            {title}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} className={`${styles.root} ${!bordered && styles.nonBordered}`}>
        {!isLoading ? (
          <Typography
            variant="p"
            align={alignText ? alignText : bordered ? "left" : "center"}
            textTransform="capitalize"
            noWrap
          >
            {typeof text === "string" ? text?.toLowerCase() : text}
          </Typography>
        ) : (
          <Skeleton animation="wave" width="75%" />
        )}
      </Grid>
    </Grid>
  )
}
