import React from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Slider, SliderValueLabelProps, Tooltip } from "@mui/material"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import { InputText, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../hooks/UseTheme"

import styles from "../ModelConfigurations.module.scss"

/**
 * Configuration slider's tooltip component
 * @return {React.ReactElement}
 */
export function ValueLabelComponent(props: Readonly<SliderValueLabelProps>): React.ReactElement {
  const { children, value } = props

  return (
    <Tooltip open={true} enterTouchDelay={0} placement="bottom" title={value}>
      {children}
    </Tooltip>
  )
}

type Props = {
  label: string
  min: number
  max: number
  range: number[][]
  onDelete?: () => void
  onSliderChange?: (value: number[]) => void
  onLabelChange?: (value: string) => void
  onChangeCommitted?: (value: number[]) => void
  uncovered?: boolean
  isDeletable?: boolean
  step?: number
}

/**
 * Configuration slider
 * @return {React.ReactElement}
 */
export function ConfigurationSlider(props: Readonly<Props>): React.ReactElement {
  const {
    label,
    min,
    max,
    range,
    onDelete,
    onSliderChange,
    onLabelChange,
    onChangeCommitted,
    uncovered = false,
    isDeletable = false,
    step = 1,
  } = props

  const theme = getTheme()

  return (
    <Grid container item direction="column" className={uncovered ? styles.sliderBoxUncovered : styles.sliderBoxCovered}>
      <Grid container item justifyContent="space-between">
        <Grid item>
          {!uncovered ? (
            <InputText
              hideDescription
              placeholder="Please Add label"
              value={label}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              handleChange={(e: any) => {
                onLabelChange?.(e.target.value)
              }}
            />
          ) : (
            <Typography variant="p">{label}</Typography>
          )}
        </Grid>

        <Grid container item xs={1} justifyContent="flex-end">
          {!isDeletable && !uncovered && (
            <IconButton
              edge="end"
              color="inherit"
              onClick={onDelete}
              aria-label="delete"
              className={styles.closeBtn}
              size="large"
            >
              <CloseIcon color="action" style={{ color: theme.palette.gray.background[1] }} />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <Grid container item>
        {range.map((range) => (
          <Grid item xs={12} key={`slider-${uncovered ?? label}`}>
            <Slider
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(_e: any, value: any) => {
                onSliderChange?.(value as number[])
              }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChangeCommitted={(_e: any, value: any) => {
                onChangeCommitted?.(value as number[])
              }}
              slots={{
                valueLabel: ValueLabelComponent,
              }}
              value={range}
              min={min}
              max={max}
              disabled={uncovered}
              valueLabelDisplay="on"
              step={step}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
