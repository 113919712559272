import React, { useContext, useEffect, useMemo } from "react"

import { useMutation } from "react-query"

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material"
import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { MRT_ColumnDef } from "material-react-table"

import { queryClient } from "../../.."
import { BaseTable } from "../../../components/tables/BaseTable"
import { KonanAPI } from "../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../store/CurrentProjectAndModelContext"
import { WorkflowResponseFilter } from "../../../types/generated/api/WorkflowResponseFilter"
import { WorkflowVersionsRetrieve } from "../../../types/generated/api/WorkflowVersionsRetrieve"

interface DialogProps {
  isOpen: boolean
  onClose: () => void
  // Workflow uuid
  uuid: string
  isWorkflowDeprecated?: boolean
  workflow: WorkflowVersionsRetrieve
}
export function OutputFiltersDialog(props: Readonly<DialogProps>): React.ReactElement {
  const { isOpen, onClose, uuid, isWorkflowDeprecated, workflow } = props
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const MuiTheme = useMuiTheme()
  const [filters, setFilters] = React.useState<string[]>([])

  const createUpdateOutputFilters = useMutation<
    AxiosResponse<WorkflowResponseFilter>,
    AxiosError,
    {
      project_uuid: string
      workflow_uuid: string
      fields: string[]
    }
  >(KonanAPI.createUpdateOutputFilters, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["workflow", currentProject.uuid, uuid])
      await queryClient.resetQueries({ queryKey: ["project-predictions"] })

      NotificationUtils.toast("Filters created successfully.", {
        snackBarVariant: "positive",
      })
    },
    onError: () =>
      NotificationUtils.toast("We're having trouble submitting your Filters. Please try again at another time.", {
        snackBarVariant: "negative",
      }),
  })

  const computed_feature_names = useMemo((): { name: string }[] | undefined => {
    const unique: string[] = []

    return (
      workflow?.nodes
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((node: any) => {
          if (!unique.includes(node.computed_feature_name)) {
            unique.push(node.computed_feature_name)
            return {
              name: node.computed_feature_name,
            }
          }

          return {
            name: undefined,
          }
        })
        .filter((item) => item.name !== undefined)
    )
  }, [workflow?.nodes])

  const handleSubmit = async (): Promise<void> => {
    await createUpdateOutputFilters.mutateAsync({
      project_uuid: currentProject?.uuid,
      workflow_uuid: uuid ?? "",
      fields: filters,
    })

    onClose()
  }

  const columns: MRT_ColumnDef<{ name: string; include: string }>[] = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Include",
      accessorKey: "include",
      Cell: ({ row }) => {
        return (
          <Switch
            key={row.original.name}
            checked={!filters.includes(row.original.name)}
            disabled={isWorkflowDeprecated}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              !e.target.checked
                ? setFilters([...filters, row.original.name])
                : setFilters(filters.filter((item) => item !== row.original.name))
            }}
          />
        )
      },
    },
  ]

  // onMount: setting filters to workflow filters or empty array
  useEffect(() => {
    setFilters(workflow.response_filter ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={useMediaQuery(MuiTheme.breakpoints.down("md"))}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { overflow: "hidden" } }}
    >
      <DialogTitle className="dialog-header-base">
        <Typography variant="h2-bold">Output Filters</Typography>
      </DialogTitle>

      <DialogContent className={`dialog-content-base `} style={{ height: "440px" }}>
        <BaseTable
          columns={columns}
          stripped
          enableOrdering={false}
          data={[
            ...(workflow?.nodes?.find((node) => node.node_type === "taglist") ? [{ name: "KONAN_TAGS" }] : []),
            ...(computed_feature_names ?? []),
          ]}
          title={"Features"}
          rowCount={computed_feature_names?.length}
          pageSize={5}
        />
      </DialogContent>

      {!isWorkflowDeprecated && (
        <DialogActions className="dialog-actions-base">
          <Button variant={"secondary"} onClick={onClose} autoFocus>
            cancel
          </Button>

          <Button variant={"primary"} onClick={handleSubmit} id="main_btn">
            {createUpdateOutputFilters.isLoading ? <CircularProgress size={20} color="inherit" /> : "Save"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
