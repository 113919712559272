import React from "react"

import { Box, Grid, Skeleton } from "@mui/material"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format, formatDistance } from "date-fns"

import { ListModelStateSwitch } from "../types/generated/api/ListModelStateSwitch"
import { GraphHeader } from "./GraphHeader"
import { ModelType } from "./ModelType"

import Styles from "./KonanActivityCard.module.scss"

type Props = {
  data: ({ name: string } & ListModelStateSwitch)[] | undefined
  isLoading: boolean
  cardHeight: number
}

export function KonanActivityCard(props: Props): React.ReactElement {
  const { data, isLoading, cardHeight } = props

  return (
    <div className={"charts-paper"}>
      <GraphHeader title={"ACTIVITY"} tooltipText="Shows actions done by any user on this project" />

      <Box style={{ height: cardHeight, padding: "12px 12px 0px 16px" }}>
        {isLoading ? (
          <Grid item className={Styles.loadingContainer}>
            {/* Loading 10 place holders while the response is being fetched */}
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index: number) => (
              <Grid container direction="column" justifyContent="space-between" key={index}>
                <Grid item xs={4}>
                  <Skeleton animation="wave" />
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={3}>
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton animation="wave" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item className={Styles.activityGrid} style={{ height: cardHeight }} paddingBottom={2}>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <Grid container direction="row" key={index} marginBottom={1.5}>
                  {/* Activity item stating who switched which model to which state followed by date
                      ex: abc@synapse-analytics.io switched model xyz to Live
                          21 days ago
                  */}
                  <Grid item xs={12}>
                    <Typography variant="p">
                      <strong>{item.created_by}</strong> {" switched model "} <strong>{item.name}</strong>
                      {" to "}
                      <ModelType modelState={item.after_state} margin={"4px 0px 0px 0px"} />
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="label" noWrap>
                      <Tooltip title={format(new Date(item.created_at), "dd/MM/yyyy, p")} placement="right">
                        <Typography variant="p">
                          {formatDistance(new Date(item.created_at), new Date(), {
                            addSuffix: true,
                          })}
                        </Typography>
                      </Tooltip>
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              // activity card empty state
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={Styles.loadingContainer}
              >
                <Typography variant="p" gutterBottom variantColor={2}>
                  No data to display
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </div>
  )
}
