import React, { memo } from "react"

import CloseIcon from "@mui/icons-material/Close"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined"
import { Card, Grid, IconButton, Skeleton } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { SelectWithSearch } from "../../../components/UI/SelectWithSearch"
import { getTheme } from "../../../hooks/UseTheme"

import styles from "../Programs.module.scss"

interface ProjectItem {
  uuid: string
  name: string
  hidden: boolean
}

interface ProjectNodeType {
  project?: { name: string; uuid: string | undefined }
  isEdit?: boolean
  menuView?: boolean
  isProjectsLoading?: boolean
  projects?: Array<ProjectItem>
  handleRemove?: () => void
  onValueChange?: (project: { name: string; uuid: string }) => void
  // using this flag as a temp solution till we revamp this issue was fixed in revamp cleanly
  isVersioningDialog?: boolean
}

/**
 * LiveModelCard component
 * @param {boolean} isEdit
 * @param {function} handleRemove
 * @param {boolean} menuView Toggling program's "new block" mode on/off
 * @returns {React.ReactElement}
 */
export const LiveModelCard = memo(function LiveModelNode(props: ProjectNodeType): React.ReactElement {
  const { isEdit, handleRemove, menuView, isProjectsLoading, projects, onValueChange, project, isVersioningDialog } =
    props

  const readMode = isEdit ?? null

  const theme = getTheme()

  return (
    <Card className={menuView ? styles.LiveModelCard_menuView : styles.LiveModelCard}>
      <Grid container wrap="nowrap">
        <Grid item>
          <Grid item className={styles.LiveModelCardType}>
            <TokenOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />
          </Grid>
        </Grid>

        <Grid
          item
          display={"flex"}
          flexGrow={1}
          flexDirection={"column"}
          paddingLeft={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="label" variantColor={2}>
                LIVE MODEL
              </Typography>
            </Grid>
            {readMode && handleRemove && (
              <Grid item justifySelf={"end"}>
                <IconButton style={{ marginTop: "-15px" }} size="small" onClick={() => handleRemove?.()}>
                  <CloseIcon fontSize="small" className={styles.remove} />
                </IconButton>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            container
            alignItems="flex-start"
            justifyContent={"space-between"}
            marginTop={0.5}
            wrap="nowrap"
          >
            <Grid item>
              {isProjectsLoading ? (
                <Skeleton height={"25px"} width="100px" />
              ) : isEdit || menuView ? (
                <SelectWithSearch
                  isDropDownDisabled={menuView || projects?.length === 0}
                  options={
                    projects?.map((project) => {
                      return { label: project.name, value: project.uuid, disabled: project.hidden }
                    }) || []
                  }
                  placeHolder={"Choose Live Model"}
                  initialValue={project?.uuid?.startsWith("default-") ? "Choose Live Model" : project?.name}
                  searchInputPlaceHolder="Search"
                  isOptionsLoading={false}
                  fullWidth
                  onSelectMenuItem={(item: { label: string; value: string }) =>
                    onValueChange?.({ uuid: item.value, name: item.label })
                  }
                />
              ) : (
                // width limit is based on
                <Typography variant="h3-bold" style={{ width: isVersioningDialog ? "400px" : "auto" }} noWrap>
                  {project?.name}
                </Typography>
              )}
            </Grid>

            <Grid item display={"flex"} alignSelf={"center"} justifySelf={"end"} ml={1}>
              <Button
                variant="secondary"
                /*Workaround to use window.open in safari */
                onClick={() =>
                  setTimeout(() => {
                    window.open(`/projects/${project?.uuid}/decision-engines?page=Models`, "_blank")
                  })
                }
                size="small"
                disabled={menuView || project?.uuid?.startsWith("default-")}
              >
                <LaunchOutlinedIcon
                  fontSize="medium"
                  sx={{ color: menuView ? theme.palette.gray.background[1] : theme.palette.grayscale.text[1] }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
})
