import React from "react"

import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Grid } from "@mui/material"
import { Button, RadioButton } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { Moment } from "moment"

import { KonanAPI } from "../../../services/KonanAPI"
import { WorkflowGroupRetrieve } from "../../../types/generated/api/WorkflowGroupRetrieve"
import { WorkflowVersionsRetrieve } from "../../../types/generated/api/WorkflowVersionsRetrieve"
import { DataSourceProps } from "../Interfaces"
import { ExistingDatasetView } from "./ExistingDatasetView"
import { LiveDataView } from "./LiveDataView"
import { NewDatasetView } from "./NewDatasetView"

import styles from "../Simulations.module.scss"

/**
 * Simulation Data source selection screen
 * @param {FormikProps<WorkflowSimulationFormik>} formik
 * @return {React.ReactElement}
 */
export function DataSource(props: Readonly<DataSourceProps>): React.ReactElement {
  const { formik } = props

  const { id: projectID } = useParams<{ id: string }>()

  // fetch single workflow
  const { data: workflow } = useQuery<AxiosResponse<WorkflowGroupRetrieve>, AxiosError>(
    ["workflow", projectID, formik.values.workflowID],
    () => KonanAPI.fetchWorkflow(projectID as string, formik.values.workflowID),
    {
      enabled: !!formik.values.workflowID && !!projectID,
    },
  )

  const handleSettingDataFile = (uuid: string, columns: string[]): void => {
    formik.setFieldValue("predictions_datafile", uuid)
    formik.setFieldValue("datafile_columns", columns)

    setTimeout(() => {
      formik.validateForm()
    }, 300)
  }

  return (
    <Grid container item xs={12} spacing={3}>
      {/* Radio Buttons */}
      <Grid container item xs={12} spacing={2}>
        {["Live Data", "New Dataset", "Existing Dataset"].map((item: string) => (
          <Grid item xs={4} key={item}>
            <Button
              variant="secondary"
              className={styles.radioBox}
              onClick={() => {
                formik.setFieldValue("predictions_datafile", "")
                formik.setFieldValue("datafile_columns", [""])
                formik.setFieldValue("shouldProceed", true)
                formik.setFieldValue("source", item)

                setTimeout(() => {
                  formik.validateForm()
                }, 100)
              }}
              fullWidth
            >
              <RadioButton value={item} checked={item === formik.values.source} label={item} id={item} />
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Data source options */}
      <Grid item xs={12}>
        {formik.values.source === "Live Data" && (
          <LiveDataView
            setFormikStartDate={(date: Moment | undefined) => formik.setFieldValue("predictions_start_time", date)}
            setFormikEndDate={(date: Moment | undefined) => formik.setFieldValue("predictions_end_time", date)}
            setFormikRequestsNumber={(data: number) => formik.setFieldValue("totalRequestsNumber", data)}
          />
        )}
        {formik.values.source === "New Dataset" && (
          <NewDatasetView
            formik={formik}
            setDataFile={handleSettingDataFile}
            workflow={workflow?.data.active_version as WorkflowVersionsRetrieve}
          />
        )}
        {formik.values.source === "Existing Dataset" && (
          <ExistingDatasetView
            workflow={workflow?.data.active_version as WorkflowVersionsRetrieve}
            dataFile={formik.values.predictions_datafile}
            setDataFile={handleSettingDataFile}
            formik={formik}
          />
        )}
      </Grid>
    </Grid>
  )
}
