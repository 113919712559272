import React, { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"

import { useQuery } from "react-query"
import { useParams, useSearchParams } from "react-router-dom"
import { Handle, Node, Position, useReactFlow, useStoreApi } from "reactflow"

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import CloseIcon from "@mui/icons-material/Close"
import CodeIcon from "@mui/icons-material/Code"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import SubjectIcon from "@mui/icons-material/Subject"
import TagIcon from "@mui/icons-material/Tag"
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined"
import { Card, Grid, IconButton } from "@mui/material"
import { Button, NotificationUtils, Tag, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikProps, useFormik } from "formik"

import { MenuOptions, SelectWithSearch } from "../../../components/UI/SelectWithSearch"
import { ValueBlock } from "../../../components/ValueBlock"
import { DecisionNodeDialog } from "../../../components/dialogs/DecisionNodeDialog"
import { getTheme } from "../../../hooks/UseTheme"
import { KonanAPI } from "../../../services/KonanAPI"
import { FeatureMapping, SelectionDataType, UpdateGraphParams } from "../../../types/custom/workflows"
import { Deployment } from "../../../types/generated/api/Deployment"
import { ModelDeploymentSchemaFeature } from "../../../types/generated/api/ModelDeploymentSchemaFeature"
import { PaginatedProgramList } from "../../../types/generated/api/PaginatedProgramList"
import { PaginatedRuleSetListList } from "../../../types/generated/api/PaginatedRuleSetListList"
import { PaginatedScoreCardSetListList } from "../../../types/generated/api/PaginatedScoreCardSetListList"
import { PaginatedScriptGroupListList } from "../../../types/generated/api/PaginatedScriptGroupListList"
import { PaginatedTagListListList } from "../../../types/generated/api/PaginatedTagListListList"
import { Program } from "../../../types/generated/api/Program"
import { RuleList } from "../../../types/generated/api/RuleList"
import { RuleSet } from "../../../types/generated/api/RuleSet"
import { RuleSetCreateRequest } from "../../../types/generated/api/RuleSetCreateRequest"
import { RuleSetList } from "../../../types/generated/api/RuleSetList"
import { SchemaSourceModel } from "../../../types/generated/api/SchemaSourceModel"
import { ScoreCardSetList } from "../../../types/generated/api/ScoreCardSetList"
import { Script } from "../../../types/generated/api/Script"
import { ScriptGroupList } from "../../../types/generated/api/ScriptGroupList"
import { ScriptSchema } from "../../../types/generated/api/ScriptSchema"
import { TagListList } from "../../../types/generated/api/TagListList"
import { getTrimmedComputedFeatureName, handleDeletingNodeInBetween } from "../../../utils/workflowHelpers"
import { BuiltinScriptDialog } from "../../Scripts/BuiltinScriptDialog"
import { ScriptDialog } from "../../Scripts/ScriptDialog"
import { ProjectFeatureMappingDialog } from "../../projects"

import styles from "./CustomCards.module.scss"

type SelectionDropDownProps = {
  nodeId: string | null
  dataType: SelectionDataType
  dataItemId: string | TagListList | RuleSet | Deployment | Program | Script | ScoreCardSetList | undefined
  menuView: boolean | undefined
  itemData: Array<Deployment> | PaginatedListType | PaginatedScriptGroupListList | undefined
  selectedValue: string
  setSelectedValue: Dispatch<SetStateAction<string | undefined | dataItemType>>
  addComputedFeature: (feature: string) => void
  isDataLoading: boolean
}

enum DataKeyName {
  "RulesetNode" = "ruleset",
  "ProjectNode" = "project",
  "DecisionNode" = RulesetNode,
  "ProgramNode" = "program",
  "ScorecardsetNode" = "scorecard_set",
  "ScriptNode" = "script",
  "StartNode" = "start",
  "TaglistNode" = "taglist",
}

enum DropdownTitle {
  "RulesetNode" = "choose ruleset",
  "ProgramNode" = "choose program",
  "ProjectNode" = "choose project",
  "ScorecardsetNode" = "choose scorecardset",
  "DecisionNode" = RulesetNode,
  "ScriptNode" = "choose script",
  "TaglistNode" = "choose taglist",
}

type PaginatedListType =
  | PaginatedProgramList
  | PaginatedRuleSetListList
  | PaginatedScoreCardSetListList
  | PaginatedScriptGroupListList
  | PaginatedTagListListList

type CurrentNodeData =
  | PaginatedRuleSetListList
  | PaginatedProgramList
  | PaginatedScoreCardSetListList
  | PaginatedTagListListList
  | Deployment[]
  | undefined
  | PaginatedScriptGroupListList

type dataItemType = RuleSet | Program | ScoreCardSetList | Script | Deployment | TagListList

function SelectDataMenu(props: Readonly<SelectionDropDownProps>): React.ReactElement {
  const { menuView, nodeId, dataItemId, itemData, dataType, addComputedFeature, setSelectedValue, isDataLoading } =
    props
  const { id: projectId } = useParams<ParamsType>()

  const store = useStoreApi()

  // extracting and memoizing the GENERIC and DECISION typed rulesets
  const NodeDataMemo = useMemo(() => {
    const rulesetData = itemData as PaginatedRuleSetListList

    switch (dataType) {
      case "RulesetNode":
        return rulesetData?.results?.filter((data: RuleSetList) => data?.type === RuleSetCreateRequest.type.GENERIC)
      case "DecisionNode":
        return rulesetData?.results?.filter(
          (ruleset: RuleSetList) => ruleset?.type === RuleSetCreateRequest.type.DECISION,
        )
      case "ProjectNode":
        return (itemData as Deployment[])
          ?.filter((project) => project?.uuid !== projectId)
          .filter((project) => project.category === "model")

      default:
        return (itemData as PaginatedListType)?.results
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType, (itemData as PaginatedListType)?.results, itemData])

  const onChange = (item: string): void => {
    setSelectedValue(item)
    const { getNodes, setNodes } = store.getState()
    let trimmedName: string | undefined = ""

    if (["ProjectNode", "ProgramNode", "ScorecardsetNode"].includes(dataType)) {
      if (dataType === "ProjectNode") {
        trimmedName = getTrimmedComputedFeatureName(itemData as Deployment[], item as keyof typeof Deployment, "uuid")
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        trimmedName = getTrimmedComputedFeatureName((itemData as any)?.results, item, "uuid")
      }
      addComputedFeature(trimmedName)
    } else if (dataType === "ScriptNode" && itemData && (itemData as PaginatedScriptGroupListList)?.results) {
      trimmedName = getTrimmedComputedFeatureName(
        (itemData as PaginatedScriptGroupListList).results,
        item as keyof typeof Script,
        "uuid",
      )

      addComputedFeature(trimmedName)
    }

    const currentScript = (itemData as PaginatedScriptGroupListList)?.results?.find((script) => script.uuid === item)

    setNodes(
      getNodes().map((node) => {
        const newNode = { ...node }
        if (node.id === nodeId && dataType) {
          if (["ProjectNode", "ProgramNode", "ScorecardsetNode"].includes(dataType)) {
            newNode.data = {
              ...newNode.data,
              [DataKeyName[dataType]]: item,
              computed_feature_name: trimmedName,
            }
          } else if (dataType === "ScriptNode") {
            newNode.data = {
              ...newNode.data,
              [DataKeyName[dataType]]: item,
              computed_feature_name: trimmedName,
              is_draft: currentScript?.template ? false : currentScript?.is_draft,
              isBuiltin: Boolean(currentScript?.template),
            }
          } else {
            newNode.data = {
              ...newNode.data,
              [DataKeyName[dataType]]: item,
            }
          }
        }
        return newNode
      }),
    )
  }

  const getMenuOptions = (): Array<MenuOptions> => {
    return (
      NodeDataMemo?.map((node) => ({
        label: node?.name,
        value: node?.uuid,
        element:
          dataType === "ScriptNode" && (node as ScriptGroupList)?.is_draft ? (
            <Tag size="small" variant="negative" style={{ marginLeft: 10 }}>
              Draft
            </Tag>
          ) : undefined,
      })) ?? []
    )
  }

  const getOptionName = (resourceId: string): string => {
    return NodeDataMemo?.find((item) => item?.uuid === resourceId)?.name as string
  }

  return (
    <Grid item xs={12} className="disableKeyboardA11y">
      <SelectWithSearch
        isDropDownDisabled={
          menuView ??
          (dataType === "ProjectNode"
            ? !(itemData as Deployment[])?.length
            : dataType === "ScriptNode"
              ? false
              : !(itemData as PaginatedListType)?.results?.length || !NodeDataMemo?.length)
        }
        options={[...getMenuOptions()]}
        placeHolder={dataType === "StartNode" ? "" : DropdownTitle[dataType]}
        initialValue={
          typeof dataItemId !== "string" && dataItemId?.name ? dataItemId?.name : getOptionName(dataItemId as string)
        }
        searchInputPlaceHolder="Search"
        isOptionsLoading={isDataLoading}
        fullWidth
        onSelectMenuItem={(item: { label: string; value: string }) => onChange(item?.value)}
        key={nodeId ?? "0"}
      />
    </Grid>
  )
}

type ParamsType = {
  id: string
}

export interface NodeDataBasedOnType {
  nodeLogo: React.ReactElement
  nodeTitle: string
  nodeBody?: string
  logoClassName?: string
  onRightIconClick?: () => void
}

type SelectionNodeProps = {
  data: {
    updateGraph: (data: UpdateGraphParams) => void
    nodeType: SelectionDataType
    readMode?: true
    ruleset?: RuleSet
    project?: Deployment
    program?: Program
    taglist?: TagListList
    script?: Script
    scorecard_set?: ScoreCardSetList
    rulesets?: PaginatedRuleSetListList
    programs?: PaginatedProgramList
    taglists?: PaginatedTagListListList
    scorecardsets?: PaginatedScoreCardSetListList
    rulesetName?: string
    scriptName?: string
    rules?: RuleList[]
    file?: string
    isNodeInLoop?: boolean
    projects?: Deployment[]
    scripts?: PaginatedScriptGroupListList
    feature_mappings?: Array<FeatureMapping>
    editMode?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>
  }

  menuView?: boolean
  id?: string
  setAllNodes?: (nodes: Node[], isNodeBetween: boolean) => void
}

export interface FeatureMappingFormikValues {
  feature_mappings: FeatureMapping[]
  retrievedNode: { uuid: string; feature_mappings: FeatureMapping[] }
  cachedNodes: { [key: string]: FeatureMapping[] }
  liveModelSchema: ModelDeploymentSchemaFeature[]
}

/**
 * Grouping node for all types of nodes that handles a selection behavior (scorecardset + program + taglist + ruleset + decision ruleset) + start node
 * @param {object} data and object contains all passed data to this node
 * @param {boolean} menuView an indicator if the current view is menu or not
 * @param {string} id node id
 * @param {function} setAllNodes function that updates the workflow, must be passed down to all nodes
 * @return {React.ReactElement}
 */
export function SelectionNode(props: Readonly<SelectionNodeProps>): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()
  const { data, menuView, id } = props

  // ruleset data
  const rulesets = data?.rulesets
  const rules = data?.rules ?? []
  const ruleset = data?.ruleset ?? null
  const rulesetName = data?.rulesetName ?? ""

  const updateGraph = props.data?.updateGraph

  // programs and scorecards data
  const programs = data?.programs
  const scorecardsets = data?.scorecardsets

  // taglists data
  const taglists = data?.taglists

  // scripts data
  const scripts = data?.scripts

  const readMode: boolean | null = data?.readMode ?? null
  const editMode = data?.editMode ?? null

  const nodeId = id ?? null

  const theme = getTheme()

  // instance of our state management that share our nodes and edges across all component
  const store = useStoreApi()
  // this is reactflow helper function to delete passed nodes as well as all the connected edges from the passed nodes
  const { getEdges } = useReactFlow()

  const [isBuiltinScriptDialogOpen, setIsBuiltinScriptDialogOpen] = useState<boolean>(false)
  const [isProjectDialogOpen, setIsProjectDialogOpen] = useState<boolean>(false)

  const { setNodes, getNodes } = store.getState()

  const [decisionDialogOpen, setDecisionDialogOpen] = useState<boolean>(false)

  const [isScriptDialogOpen, setIsScriptDialogOpen] = useState<boolean>(false)
  const [isDataProcessing, setIsDataProcessing] = useState<boolean>(false)

  const [searchParams] = useSearchParams()

  // fetch rulesets, enabled if nodeType === "RulesetNode" and there are rulesets in this project
  const { data: rulesetsData, isLoading: isRulesetsLoading } = useQuery<
    AxiosResponse<PaginatedRuleSetListList>,
    AxiosError
  >(["rulesets", projectId], () => KonanAPI.fetchRulesets(projectId as string), {
    enabled: !!projectId && !readMode && (data?.nodeType === "RulesetNode" || data?.nodeType === "DecisionNode"),
  })

  // fetch programs, enabled if nodeType === "ProgramNode" and there are programs in this project
  const { isLoading: isProgramsLoading, data: programsData } = useQuery<
    AxiosResponse<PaginatedProgramList>,
    AxiosError
  >(["programs", projectId], () => KonanAPI.fetchPrograms(projectId as string), {
    enabled: !!projectId && !readMode && data?.nodeType === "ProgramNode",
  })

  // fetch scorecardsets, enabled if nodeType === "ScorecardsetNode" and there are scorecardsets in this project
  const { isLoading: isScorecardsetsLoading, data: scorecardsetsData } = useQuery<
    AxiosResponse<PaginatedScoreCardSetListList>,
    AxiosError
  >(["scorecardsets", projectId], () => KonanAPI.fetchScorecardsets(projectId as string), {
    enabled: !!projectId && !readMode && data?.nodeType === "ScorecardsetNode",
  })

  // Fetching projects, enabled if nodeType === "ProjectNode"
  const { isLoading: isProjectsLoading, data: projectsData } = useQuery<AxiosResponse<Array<Deployment>>, AxiosError>(
    ["projects"],
    () => KonanAPI.fetchProjects(),
    {
      enabled: !readMode && data?.nodeType === "ProjectNode",
    },
  )

  // fetch scripts, enabled if nodeType === "ScriptNode"
  const { data: scriptsData, isLoading: isScriptsLoading } = useQuery<
    AxiosResponse<PaginatedScriptGroupListList>,
    AxiosError
  >(["scripts", projectId], () => KonanAPI.fetchScripts(projectId as string), {
    enabled: !!projectId && data?.nodeType === "ScriptNode",
  })

  // fetch taglists
  const { isLoading: isTaglistsLoading, data: taglistsData } = useQuery<
    AxiosResponse<PaginatedTagListListList>,
    AxiosError
  >(["taglists", projectId], () => KonanAPI.fetchTagLists(projectId as string), {
    enabled: !!projectId && data?.nodeType === "TaglistNode",
  })

  /**
   * Our onNodesDelete callback is called with one argument - deleted -
   * that is an array of every node that was just deleted.
   * If you select an individual node and press the delete key, deleted will contain just that node,
   * but if you make a selection all the nodes in that selection will be in deleted.
   */
  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      const newNodesAndEdges = handleDeletingNodeInBetween(deleted, getEdges(), getNodes(), nodeId as string)

      updateGraph({ dataAfterDeletion: { nodes: newNodesAndEdges?.nodes, edges: newNodesAndEdges?.edges } })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getEdges, getNodes, nodeId],
  )

  // when removing a calculator node we call the deleteDescendants function first to get
  // a final list of updated nodes then updated our nodes state with the returned array
  const handleRemoveNode = (): void => {
    const deleted = getNodes().filter((node) => node.id === nodeId)

    onNodesDelete(deleted)

    NotificationUtils.toast(`${data?.nodeType?.replace("Node", " node")} removed!`, {
      snackBarVariant: "positive",
    })
  }

  // variables that changes to the current data based on the current node type
  const currentData = (): CurrentNodeData => {
    switch (data?.nodeType) {
      case "RulesetNode":
      case "DecisionNode":
        return rulesetsData?.data ?? rulesets

      case "ScorecardsetNode":
        return scorecardsetsData?.data ?? scorecardsets

      case "ProgramNode":
        return programsData?.data ?? programs

      case "ScriptNode":
        return scriptsData?.data ?? scripts

      case "TaglistNode":
        return taglistsData?.data ?? taglists

      default:
        return projectsData?.data
    }
  }

  const currentDataItemId = (): dataItemType | undefined | string => {
    switch (data?.nodeType) {
      case "RulesetNode":
      case "DecisionNode":
        return data?.ruleset

      case "ScorecardsetNode":
        return data?.scorecard_set

      case "ProgramNode":
        return data?.program

      case "ScriptNode":
        return data?.script

      case "TaglistNode":
        return data?.taglist

      default:
        return data?.project
    }
  }

  const [selectedValue, setSelectedValue] = useState<dataItemType | undefined | string>(
    currentDataItemId()
      ? typeof currentDataItemId() === "string"
        ? currentDataItemId()
        : (currentDataItemId() as dataItemType)?.uuid
      : "",
  )

  // fetch project
  const { data: projectData, isLoading: isProjectLoading } = useQuery<AxiosResponse<Deployment>, AxiosError>(
    ["project", selectedValue],
    () => KonanAPI.fetchProject(selectedValue as string),
    {
      onSuccess: ({ data }) => {
        setIsDataProcessing(true)
        const liveModelSchema = data?.schema?.filter((item) =>
          (item as ModelDeploymentSchemaFeature).source_models.some((item: SchemaSourceModel) => item.state === "live"),
        )

        formik?.setFieldValue("liveModelSchema", liveModelSchema)

        const updatedFeatureMappings =
          liveModelSchema.map((feature) => ({
            node_feature: feature?.name,
            workflow_feature:
              props?.data?.feature_mappings?.find((feat) => feat.node_feature === feature.name)?.workflow_feature ?? "",
            type: feature?.type ?? data?.schema?.find((input) => input?.name === feature.name)?.type,
          })) ?? []

        formik?.setFieldValue("feature_mappings", updatedFeatureMappings)
        formik?.setFieldValue("retrievedNode", {
          uuid: formik?.values?.retrievedNode?.uuid,
          feature_mappings: updatedFeatureMappings,
        })

        // first we check if workflow not in read/view mode so it's either in create or edit
        if (!readMode && selectedValue) {
          // then we check if our cached scripts, has the current script uuid as a property or not
          if (formik?.values?.cachedNodes?.hasOwnProperty(selectedValue as string)) {
            // if already this script is cached then, update feature_mappings state with the cached/saved value
            formik?.setFieldValue("feature_mappings", formik?.values?.cachedNodes[selectedValue as string])
          }
          // if not, and workflow in create mode or the retrieved script uuid not equal to the current one
          // then we add its values as a new cached script item
          else if (
            (searchParams.get("workflowId") === "new" || formik?.values?.retrievedNode?.uuid !== selectedValue) &&
            !formik?.values?.cachedNodes?.hasOwnProperty(selectedValue as string)
          ) {
            const newCachedNodes = {
              ...formik?.values?.cachedNodes,
              [selectedValue as string]: updatedFeatureMappings,
            }

            formik?.setFieldValue("liveModelSchema", liveModelSchema)
            formik?.setFieldValue("cachedNodes", newCachedNodes)
            formik?.setFieldValue("feature_mappings", updatedFeatureMappings)
          }
        }

        setIsDataProcessing(false)
      },
      enabled: !!selectedValue && data?.nodeType === "ProjectNode" && isProjectDialogOpen,
    },
  )

  // memoized current script name
  const currentScript = useMemo(() => {
    if (scriptsData && data?.nodeType === "ScriptNode" && currentDataItemId() && readMode && !isScriptsLoading) {
      const script = scriptsData?.data?.results?.find(
        (script) => script?.uuid === (currentDataItemId() as dataItemType)?.uuid,
      )
      if (script) {
        return script
      } else {
        return currentDataItemId() as dataItemType
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptsData, data?.nodeType, currentDataItemId(), readMode, isScriptsLoading])

  // TODO:: refactor this formik in revamp
  const formik = useFormik<FeatureMappingFormikValues>({
    initialValues: {
      feature_mappings: props?.data?.feature_mappings ?? [],
      retrievedNode: {
        uuid: data.nodeType === "ScriptNode" ? (data?.script?.uuid as string) : (data.project?.uuid as string),
        feature_mappings: props?.data?.feature_mappings as Array<FeatureMapping>,
      },
      liveModelSchema: [],
      cachedNodes: {},
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: async ({ feature_mappings }) => {
      setNodes(
        getNodes().map((node) => {
          const newNode = { ...node }
          if (node.id === nodeId) {
            // check if current node is script node
            if (scriptsData && data?.nodeType === "ScriptNode" && currentDataItemId()) {
              // fetch the script selected in the current script node
              const script = scriptsData?.data?.results?.find(
                (script) => script?.uuid === (currentDataItemId() as dataItemType)?.uuid,
              )
              // return all important data to react-flow state (feature_mappings, is_draft, isBuiltin)
              return {
                ...newNode.data,
                feature_mappings: feature_mappings,
                is_draft: script?.is_draft ?? false,
                isBuiltin: Boolean(script?.template),
              }
            } else if (data?.nodeType === "ProjectNode" && currentDataItemId()) {
              return {
                ...newNode.data,
                feature_mappings: feature_mappings,
              }
            } else {
              return newNode
            }
          } else {
            return newNode
          }
        }),
      )
    },
  })

  // Set formik data (feature_mappings, retrievedNode)
  // after configuration is set from built-in script dialog
  const setFormikScriptData = (scriptSchema: ScriptSchema): void => {
    const formatResponseToKeyValue =
      scriptSchema?.input_features?.map((feature) => ({
        node_feature: feature?.name,
        workflow_feature:
          props?.data?.feature_mappings?.find((feat) => feat.node_feature === feature.name)?.workflow_feature ?? "",
        type: feature?.type ?? scriptSchema?.input_features.find((input) => input?.name === feature.name)?.type,
      })) ?? []

    formik?.setFieldValue("feature_mappings", formatResponseToKeyValue)
    formik?.setFieldValue("retrievedNode", {
      uuid: formik?.values?.retrievedNode?.uuid,
      feature_mappings: formatResponseToKeyValue,
    })

    // first we check if workflow not in read/view mode so it's either in create or edit
    if (!readMode && selectedValue) {
      // then we check if our cached scripts, has the current script uuid as a property or not
      if (formik?.values?.cachedNodes?.hasOwnProperty(selectedValue as string)) {
        // if already this script is cached then, update feature_mappings state with the cached/saved value
        formik?.setFieldValue("feature_mappings", formik?.values?.cachedNodes[selectedValue as string])
      }
      // if not, and workflow in create mode or the retrieved script uuid not equal to the current one
      // then we add its values as a new cached script item
      else if (
        (searchParams.get("workflowId") === "new" || formik?.values?.retrievedNode?.uuid !== selectedValue) &&
        !formik?.values?.cachedNodes?.hasOwnProperty(selectedValue as string)
      ) {
        const newCachedNodes = {
          ...formik?.values?.cachedNodes,
          [selectedValue as string]: formatResponseToKeyValue,
        }
        formik?.setFieldValue("cachedNodes", newCachedNodes)
        formik?.setFieldValue("feature_mappings", formatResponseToKeyValue)
      }
    }
  }

  const isStatesLoading =
    isRulesetsLoading ||
    isScriptsLoading ||
    isProgramsLoading ||
    isScorecardsetsLoading ||
    isProjectsLoading ||
    isTaglistsLoading

  // get the required data for each node based on its type
  const getNodesDataBasedOnNodeType = (nodeType: SelectionDataType): NodeDataBasedOnType | void => {
    switch (nodeType) {
      case "StartNode":
        return {
          nodeLogo: <FlagOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "START",
          nodeBody: "This is the workflow's starting point",
          logoClassName: "card-type",
        }

      case "RulesetNode":
        return {
          nodeLogo: <RuleOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "RULESET",
          logoClassName: "RulesetCardType",
          onRightIconClick: () =>
            setTimeout(() => {
              window.open(`/projects/${projectId}/Rulesets`, "_blank")
            }),
        }

      case "DecisionNode":
        return {
          nodeLogo: <RuleOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "RULESET - DECISION",
          logoClassName: "RulesetCardType",
          onRightIconClick: () => setDecisionDialogOpen(true),
        }

      case "ScorecardsetNode":
        return {
          nodeLogo: <AssignmentOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "SCORECARDSET",
          logoClassName: "ScorecardsetCardType",
          onRightIconClick: () =>
            setTimeout(() => {
              window.open(`/projects/${projectId}/Scorecards`, "_blank")
            }),
        }

      case "ProgramNode":
        return {
          nodeLogo: <SubjectIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "PROGRAM",
          logoClassName: "ProgramCardType",
          onRightIconClick: () =>
            setTimeout(() => {
              window.open(`/projects/${projectId}/Programs`, "_blank")
            }),
        }

      case "TaglistNode":
        return {
          nodeLogo: <TagIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "TAGLIST",
          logoClassName: "TaglistCardType",
          onRightIconClick: () =>
            setTimeout(() => {
              window.open(`/projects/${projectId}/Taglists`, "_blank")
            }),
        }

      case "ProjectNode":
        return {
          nodeLogo: <TokenOutlinedIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "Live Model",
          logoClassName: "ProjectCardType",
          onRightIconClick: () =>
            setTimeout(() => {
              window.open(
                `/projects/${
                  typeof currentDataItemId() === "string"
                    ? currentDataItemId()
                    : (currentDataItemId() as dataItemType)?.uuid
                }/decision-engines?page=Models`,
                "_blank",
              )
            }),
        }

      case "ScriptNode":
        return {
          nodeLogo: <CodeIcon sx={{ color: theme.palette.grayscale.text[1] }} />,
          nodeTitle: "SCRIPT",
          logoClassName: "ScriptCardType",
          onRightIconClick: () =>
            setTimeout(() => {
              window.open(`/projects/${projectId}/Scripts`, "_blank")
            }),
        }
      default:
        break
    }
  }

  const handleUpdateComputedFeature = (newFeature: string): void => {
    // check if this node has already an assigned computed feat name
    const computedFeatureIndex = props?.data?.formik?.values?.computedFeatures?.findIndex(
      (feature: { uuid: string; value: string }) => feature?.value?.toLowerCase() === newFeature?.toLowerCase(),
    )

    // check if it's a new computed feat name
    if (computedFeatureIndex === -1 && newFeature) {
      props?.data?.formik.setFieldValue("computedFeatures", [
        ...(props?.data?.formik?.values?.computedFeatures ?? []),
        { uuid: nodeId, value: newFeature },
      ])
    } else if (computedFeatureIndex !== -1 && newFeature) {
      props?.data?.formik.setFieldValue(`computedFeatures[${computedFeatureIndex}].value`, newFeature)
    }
  }

  const enableConfigButton = ["ScriptNode", "ProjectNode"].includes(data?.nodeType)

  // This effect listens to any change happens script/project node
  // and update the node state with the changed values
  // if readMode or editMode is true (creating or updating workflow)
  useEffect(() => {
    if (formik?.values?.feature_mappings && nodeId && enableConfigButton) {
      const { feature_mappings } = formik.values

      setTimeout(() => {
        setNodes(
          getNodes().map((node) => {
            const newNode = { ...node }
            if (node.id === nodeId) {
              newNode.data = {
                ...newNode.data,
                feature_mappings: feature_mappings ?? [],
              }
            }
            return newNode
          }),
        )
      }, 100)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.nodeType, formik.values?.feature_mappings, getNodes, nodeId])

  // Convert currentData to an array if it's not already one
  // If currentData is already an array, use it as is
  // If currentData has a 'results' property, use that
  // Otherwise, use an empty array
  const dataArray = Array.isArray(currentData()) ? currentData() : ((currentData() as PaginatedListType)?.results ?? [])

  return (
    <Fragment>
      {decisionDialogOpen && (
        <DecisionNodeDialog
          rules={rules}
          readMode={readMode !== null}
          rulesetName={rulesetName}
          rulesetUUID={typeof selectedValue === "string" ? selectedValue : ((ruleset as RuleSet)?.uuid ?? "")}
          isOpen={decisionDialogOpen}
          onClose={() => setDecisionDialogOpen(false)}
          workflowFormik={props?.data?.formik}
        />
      )}

      {isBuiltinScriptDialogOpen && (
        <BuiltinScriptDialog
          isOpen
          onClose={() => {
            formik.setFieldValue("cachedNodes", {
              ...formik.values.cachedNodes,
              [selectedValue as string]: formik?.values?.feature_mappings,
            })
            setIsBuiltinScriptDialogOpen(false)
          }}
          workflowMode={readMode ? "read" : editMode ? "edit" : "create"}
          scriptInfo={{
            name: currentScript?.name,
            uuid: selectedValue as string,
            template: data?.script?.template as string,
          }}
          workflowSchema={props?.data?.formik?.values?.features}
          workflowFormik={formik}
          renderView="workflow"
          setFormikScriptData={setFormikScriptData}
        />
      )}

      {isScriptDialogOpen && (
        <ScriptDialog
          isOpen
          onClose={() => setIsScriptDialogOpen(false)}
          renderView="workflow"
          scriptId={selectedValue as string}
          isWorkFlowInReadMode={readMode}
        />
      )}

      {isProjectDialogOpen && (
        <ProjectFeatureMappingDialog
          isOpen
          onClose={() => {
            formik.setFieldValue("cachedNodes", {
              ...formik.values.cachedNodes,
              [selectedValue as string]: formik?.values?.feature_mappings,
            })
            setIsProjectDialogOpen(false)
          }}
          name={projectData?.data.name as string}
          isLoading={isProjectLoading || isDataProcessing}
          workflowSchema={props?.data?.formik?.values?.features}
          workflowFormik={formik}
          isWorkflowInReadMode={readMode}
        />
      )}

      {/* Node Card*/}
      <Card className={`${styles.card} nodrag`}>
        {!menuView && data?.nodeType !== "StartNode" && (
          <Handle style={{ opacity: 0 }} type="target" position={Position.Top} id="b" isConnectable={true} />
        )}

        <Grid container justifyContent={"space-between"}>
          {/* Node Logo */}
          <Grid item xs={1.5}>
            <Grid item className={styles[`${getNodesDataBasedOnNodeType(data?.nodeType)?.logoClassName}`]}>
              {getNodesDataBasedOnNodeType(data?.nodeType)?.nodeLogo}
            </Grid>
          </Grid>

          <Grid
            item
            xs={10.5}
            flexDirection={"column"}
            paddingLeft={1}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={11}>
                <Typography variant="label" variantColor={2}>
                  {getNodesDataBasedOnNodeType(data?.nodeType)?.nodeTitle}
                </Typography>
              </Grid>

              {/* close icon*/}
              {!menuView && !readMode && data?.nodeType !== "StartNode" && (
                <Grid item xs={1} justifySelf={"flex-end"}>
                  <IconButton style={{ marginTop: "-15px" }} size="small" onClick={() => handleRemoveNode()}>
                    <CloseIcon fontSize="small" className={styles.remove} />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            {/* Node Body*/}
            <Grid
              item
              xs={12}
              container
              flexDirection={"column"}
              gap={0.5}
              alignItems="space-between"
              justifyContent={"flex-end"}
              marginTop={!readMode && !menuView ? "5px" : menuView ? "7px" : "5px"}
            >
              <Grid item xs display="flex">
                {data?.nodeType !== "StartNode" ? (
                  readMode && currentDataItemId() ? (
                    <Grid container item xs={enableConfigButton ? 8 : 10}>
                      <ValueBlock
                        value={
                          currentScript?.name ?? (currentDataItemId() as dataItemType)?.name ?? props?.data?.scriptName
                        }
                        size={12}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={enableConfigButton ? 10 : 12}>
                      <SelectDataMenu
                        nodeId={nodeId}
                        dataType={data?.nodeType}
                        menuView={menuView}
                        dataItemId={currentDataItemId()}
                        itemData={currentData()}
                        key={nodeId}
                        selectedValue={selectedValue as string}
                        setSelectedValue={setSelectedValue}
                        addComputedFeature={(newFeat: string) => handleUpdateComputedFeature(newFeat)}
                        isDataLoading={isStatesLoading}
                      />
                    </Grid>
                  )
                ) : (
                  <Grid item xs={12} alignItems="flex-start">
                    <Typography variant="label">This is the workflow's starting point</Typography>
                  </Grid>
                )}

                {enableConfigButton &&
                  ((typeof selectedValue === "string" && selectedValue !== "") ||
                    typeof selectedValue !== "string") && (
                    <Grid item xs={2} ml={0.5}>
                      <Button
                        size={readMode ? "large" : "regular"}
                        /*Workaround to use window.open in safari */
                        onClick={() => {
                          if (data?.nodeType === "ScriptNode") {
                            // get the selected script, to see if it's custom or builtin
                            const selectedScript = (currentData() as PaginatedScriptGroupListList)?.results?.find(
                              (result) => result?.uuid === selectedValue,
                            )
                            if (selectedScript?.template || data?.script?.template) {
                              setIsBuiltinScriptDialogOpen(true)
                            } else {
                              setIsScriptDialogOpen(true)
                            }
                          } else if (data?.nodeType === "ProjectNode") {
                            setIsProjectDialogOpen(true)
                          }
                        }}
                        disabled={menuView || !selectedValue}
                        fullWidth
                        data-mode={readMode ? "read" : menuView ? "edit-view" : "edit"}
                        variant="secondary"
                      >
                        {/** if the current script node has a template script selected change the action button icon to settings
                         * else, use the `openInFullIcon` icon
                         */}
                        {(dataArray as Script[])?.find((result: Script) => result?.uuid === selectedValue)?.template ||
                        data?.script?.template ||
                        data?.project ? (
                          <SettingsOutlinedIcon fontSize="small" sx={{ color: theme.palette.grayscale.text[1] }} />
                        ) : (
                          <OpenInFullIcon fontSize="small" sx={{ color: theme.palette.grayscale.text[1] }} />
                        )}
                      </Button>
                    </Grid>
                  )}

                {/* Node Action button*/}
                {data?.nodeType !== "StartNode" && (
                  <Grid item xs={2} ml={0.5}>
                    <Button
                      /*Workaround to use window.open in safari */
                      onClick={getNodesDataBasedOnNodeType(data?.nodeType)?.onRightIconClick}
                      disabled={menuView}
                      size={readMode ? "large" : "regular"}
                      fullWidth
                      data-mode={readMode ? "read" : menuView ? "edit-view" : "edit"}
                      variant="secondary"
                    >
                      {["DecisionNode"].includes(data?.nodeType) ? (
                        <OpenInFullIcon fontSize="small" sx={{ color: theme.palette.grayscale.text[1] }} />
                      ) : (
                        <LaunchOutlinedIcon fontSize="small" sx={{ color: theme.palette.grayscale.text[1] }} />
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>

              {!readMode &&
                !menuView &&
                ["RulesetNode", "DecisionNode"].includes(data?.nodeType) &&
                data?.isNodeInLoop && (
                  <Grid item mt="2px" className={styles.loopWarning}>
                    <Typography gutterBottom variant="label" variantColor={2} color="amber">
                      Warning
                    </Typography>

                    <Typography variant="p">This may stop the entire workflow</Typography>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>

        {!menuView && (
          <Handle style={{ opacity: 0 }} type="source" position={Position.Bottom} id="a" isConnectable={true} />
        )}
      </Card>
    </Fragment>
  )
}
