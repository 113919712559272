import React, { Fragment, useState } from "react"

import { Grid, Switch } from "@mui/material"
import {
  Button,
  InputChangeEvent,
  Menu,
  MenuItem,
  NotificationUtils,
  RadioButton,
  Select,
  Tag,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { v4 as uuidv4 } from "uuid"

import { NewFeatureData } from "../types/custom/rules"
import { FeatureData } from "../types/custom/workflows"
import { WorkflowGroupRetrieve } from "../types/generated/api/WorkflowGroupRetrieve"
import { WorkflowSchemaFeature } from "../types/generated/api/WorkflowSchemaFeature"
import { hasExactMatch } from "../utils/genericHelpers"

import styles from "../features/Ruleset/components/RuleCard.module.scss"

type FeaturesMenuProps = {
  filteredFeatures: Array<FeatureData>
  workflowsList: Array<WorkflowGroupRetrieve>
  anchorEl: null | HTMLElement
  queryKey: string
  onSelectingFeature: (item: FeatureData) => void
  onAddingNewFeature: (featureData: NewFeatureData) => void
  onMenuClose: () => void
  handleFeatureTypeChange?: (value: string) => void
  initialFeatureType?: () => WorkflowSchemaFeature.type
}

/**
 *  Dropdown Menu component for all features in all active workflows schema
 */
export function FeaturesMenu(props: Readonly<FeaturesMenuProps>): React.ReactElement {
  const {
    handleFeatureTypeChange,
    filteredFeatures,
    workflowsList,
    anchorEl,
    queryKey,
    onSelectingFeature,
    onAddingNewFeature,
    onMenuClose,
    initialFeatureType,
  } = props

  const [isAddFeatureClicked, setIsAddFeatureClicked] = useState<boolean>(false)

  const [newFeatureData, setNewFeatureData] = useState<NewFeatureData>({
    isRequired: true,
    type: WorkflowSchemaFeature.type.TEXT,
    workflow: "",
  })

  return (
    <Fragment>
      {workflowsList?.length > 0 && (
        <Menu
          menuMaxContent
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setIsAddFeatureClicked(false)

            onMenuClose()
            setNewFeatureData({
              isRequired: true,
              type: WorkflowSchemaFeature.type.TEXT,
              workflow: "",
            })
          }}
        >
          {isAddFeatureClicked ? (
            <Grid
              spacing={2}
              padding="12px"
              container
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Grid item container xs={12} display="flex" justifyContent="space-between">
                <Grid item>
                  <Typography variant="p" color="neutral">
                    {queryKey}
                  </Typography>
                </Grid>

                <Grid item display="flex">
                  <Switch
                    sx={{
                      "& .MuiSwitch-thumb": {
                        width: "15px",
                        height: "15px",
                      },
                    }}
                    size="small"
                    onChange={() =>
                      setNewFeatureData({
                        ...newFeatureData,
                        isRequired: !newFeatureData.isRequired,
                      })
                    }
                    checked={newFeatureData.isRequired}
                  />
                  <Typography variant="p">Required</Typography>
                </Grid>
              </Grid>

              {/* Select workflow */}
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <Select
                  fullWidth
                  id={`${workflowsList?.length}`}
                  value={newFeatureData.workflow || "default"}
                  handleChange={(e: InputChangeEvent) => {
                    setNewFeatureData({
                      ...newFeatureData,
                      workflow: e.target.value as string,
                    })
                  }}
                  hideDescription
                  optionsWithValues={[
                    { label: "Choose Workflow", value: "default", disabled: true },
                    ...workflowsList.map((workflow) => {
                      return { label: workflow.name, value: workflow.uuid }
                    }),
                  ]}
                />
              </Grid>

              {/* Select feature type */}
              <Grid item container xs={12} display="flex" justifyContent="space-between">
                {Object.values(WorkflowSchemaFeature.type)?.map((featureType) => (
                  // flex here needed to give equal space to items
                  <Grid item flex="0 0 33.333%" key={`${uuidv4()}-${workflowsList?.length}`} mb={0.5}>
                    <RadioButton
                      value={featureType}
                      checked={featureType === newFeatureData.type}
                      label={featureType?.toLowerCase()}
                      id={featureType}
                      onChange={() => {
                        setNewFeatureData({
                          ...newFeatureData,
                          type: featureType,
                        })
                        handleFeatureTypeChange?.(featureType)
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              {/* Action buttons */}
              <Grid item container xs={12} display="flex" justifyContent="space-between" gap={1}>
                <Grid item xs>
                  <Button
                    fullWidth
                    variant="secondary"
                    size="regular"
                    onClick={() => {
                      setIsAddFeatureClicked(false)
                      setNewFeatureData({
                        isRequired: true,
                        type: WorkflowSchemaFeature.type.TEXT,
                        workflow: "",
                      })
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs>
                  <Button
                    disabled={!newFeatureData.workflow}
                    fullWidth
                    variant="primary"
                    size="regular"
                    onClick={() => {
                      onAddingNewFeature(newFeatureData)
                      setIsAddFeatureClicked(false)
                      NotificationUtils.toast("Feature is being added to schema..", {
                        snackBarVariant: "important",
                      })
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              {/* item for Adding new feature to schema */}
              {queryKey && !hasExactMatch(filteredFeatures, queryKey, "name") && (
                <MenuItem key={queryKey} value={queryKey} onClick={() => setIsAddFeatureClicked(true)}>
                  <Grid item container flexWrap="nowrap" display="flex" justifyContent="space-between" minWidth={200}>
                    <div className={styles.featureNameContainer}>
                      <Typography style={{ width: "100%" }} noWrap variant="a" className="menu-item-padding">
                        {queryKey}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        size="small"
                        onClick={(e) => {
                          e?.stopPropagation()
                          setIsAddFeatureClicked(true)
                          setNewFeatureData({
                            ...newFeatureData,
                            type: initialFeatureType?.() ?? WorkflowSchemaFeature.type.TEXT,
                          })
                        }}
                      >
                        + Add Feature
                      </Button>
                    </div>
                  </Grid>
                </MenuItem>
              )}

              {filteredFeatures?.length > 0 &&
                filteredFeatures?.map((item: FeatureData, index) => (
                  <MenuItem key={index} value={item?.name} onClick={() => onSelectingFeature(item)}>
                    <Grid item container xs={12} flexDirection={"column"} justifyContent={"space-between"}>
                      <Grid flexWrap="nowrap" container item xs={12} justifyContent={"flex-start"}>
                        <Tag variant="positive" size="small" style={{ marginRight: "4px" }}>
                          {item?.type ?? WorkflowSchemaFeature.type.UNDEFINED}
                        </Tag>
                      </Grid>
                      <Grid
                        item
                        flexWrap={"nowrap"}
                        display={"flex"}
                        container
                        justifyContent={"space-between"}
                        minWidth={200}
                      >
                        <div className={styles.featureNameContainer}>
                          <Typography style={{ width: "100%" }} noWrap variant="a" className="menu-item-padding">
                            {item?.name}
                          </Typography>
                        </div>
                        <Tag variant="default" size="small">
                          {item?.workflows[0]}
                        </Tag>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
            </Fragment>
          )}
        </Menu>
      )}
    </Fragment>
  )
}
