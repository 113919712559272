import { AxiosResponse } from "axios"

import { AxiosApi } from "../lib/axios"
import {
  CreateGroupRequestProps,
  CreateRoleRequestProps,
  EditUserRequest,
  PatchedGroupRequest,
} from "../types/custom/Permissions"
import { CustomRegistrationInput } from "../types/custom/authentication"
import { CustomRegistration } from "../types/generated/authentication/CustomRegistration"
import { CustomTokenObtainPair } from "../types/generated/authentication/CustomTokenObtainPair"
import { CustomTokenRefresh } from "../types/generated/authentication/CustomTokenRefresh"
import { GoogleOauth2RegisterResponse } from "../types/generated/authentication/GoogleOauth2RegisterResponse"
import { GroupRetrieve } from "../types/generated/authentication/GroupRetrieve"
import { LogoutRequest } from "../types/generated/authentication/LogoutRequest"
import { Organization } from "../types/generated/authentication/Organization"
import { OrganizationInvitation } from "../types/generated/authentication/OrganizationInvitation"
import { OrganizationInvitationBulkRequest } from "../types/generated/authentication/OrganizationInvitationBulkRequest"
import { OrganizationInvitationBulkResponse } from "../types/generated/authentication/OrganizationInvitationBulkResponse"
import { Role } from "../types/generated/authentication/Role"
import { SendEmailReset } from "../types/generated/authentication/SendEmailReset"
import { TokenObtainPairResponse } from "../types/generated/authentication/TokenObtainPairResponse"
import { UserManagementLog } from "../types/generated/authentication/UserManagementLog"
import { getHostnames } from "../utils/genericHelpers"

class SidAPI extends AxiosApi {
  constructor() {
    const { auth } = getHostnames()

    super(auth)
  }

  login = ({ email, password }: CustomTokenObtainPair): Promise<AxiosResponse<TokenObtainPairResponse>> => {
    return this.postResource("/api/auth/login/", { email, password })
  }

  googleLogin = (credential: string): Promise<AxiosResponse<TokenObtainPairResponse>> => {
    return this.postResource("/api/auth/oauth/google/token/", {
      id_token: credential,
      product: "konan",
    })
  }

  logOut = ({ all_devices, refresh }: LogoutRequest): Promise<AxiosResponse<void>> => {
    return this.postResource("/api/auth/logout/", { all_devices, refresh })
  }

  refreshToken = (refresh: string): Promise<AxiosResponse<CustomTokenRefresh>> => {
    return this.postResource("/api/auth/token/refresh/", { refresh })
  }

  signup = ({
    firstName,
    lastName,
    email,
    password,
    rePassword,
    position,
  }: CustomRegistrationInput): Promise<AxiosResponse<CustomRegistration>> => {
    return this.postResource("/api/auth/signup/", {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      re_password: rePassword,
      position,
    })
  }

  googleSignup = (idToken: string): Promise<AxiosResponse<GoogleOauth2RegisterResponse>> => {
    return this.postResource("/api/auth/oauth/google/signup/", {
      id_token: idToken,
      product: "konan",
      login_on_register: true,
    })
  }

  resendVerificationLink = async (email: string): Promise<AxiosResponse<void>> => {
    return this.postResource(`/api/auth/verify/resend/`, {
      email: email,
    })
  }

  resetPassword = async ({ email }: SendEmailReset): Promise<SendEmailReset> => {
    // this api call actually returns on content when successful, only status 204
    const { data } = await this.postResource(
      "/api/auth/password/reset/",
      { email },
      {
        headers: {
          Authorization: "",
        },
      },
    )
    return data
  }

  createOrganization = async (name: string): Promise<AxiosResponse<Organization>> => {
    return this.postResource("/api/organizations/", { name })
  }

  fetchOrganization = async (uid: string): Promise<AxiosResponse<Organization>> => {
    return this.fetchResource(`/api/organizations/${uid}/`)
  }

  leaveOrganization = async (): Promise<AxiosResponse> => {
    return this.deleteResource(`/api/user/organization/`)
  }

  deleteOrganization = async (uid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/api/user/organizations/${uid}/`)
  }

  removeUser = async (uid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/api/organizations/users/${uid}/`)
  }

  editUser = async (props: EditUserRequest): Promise<AxiosResponse> => {
    const { userId, role, groups } = props

    return this.patchResource(`/api/organizations/users/${userId}/`, {
      role,
      groups,
    })
  }

  inviteUsers = async ({
    organizationUUID,
    emails,
    expiry_date,
    role,
    groups,
  }: {
    organizationUUID: string
  } & OrganizationInvitationBulkRequest): Promise<AxiosResponse<OrganizationInvitationBulkResponse>> => {
    return this.postResource(`/api/organizations/${organizationUUID}/invitations/`, {
      emails: emails,
      role: role,
      groups: groups,
      expiry_date: expiry_date,
    })
  }

  fetchPendingInvites = async (uid: string): Promise<AxiosResponse<OrganizationInvitation[]>> => {
    return this.fetchResource(`/api/organizations/${uid}/invitations/`)
  }

  removeInvitation = async (uid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/api/organizations/invitations/${uid}/`)
  }

  fetchRoles = async (organization_uuid: string): Promise<AxiosResponse<Role[]>> => {
    return this.fetchResource(`/api/organizations/${organization_uuid}/roles/`)
  }

  createRole = async (props: CreateRoleRequestProps & { organization_uuid: string }): Promise<AxiosResponse<Role>> => {
    return this.postResource(`/api/organizations/${props.organization_uuid}/roles/`, {
      ...props,
    })
  }

  fetchRole = async (role_uuid: string): Promise<AxiosResponse<Role>> => {
    return this.fetchResource(`/api/organizations/roles/${role_uuid}/`)
  }

  deleteRole = async (role_uuid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/api/organizations/roles/${role_uuid}/`)
  }

  updateRole = async (props: CreateRoleRequestProps & { role_uuid: string }): Promise<AxiosResponse<Role>> => {
    return this.patchResource(`/api/organizations/roles/${props.role_uuid}/`, {
      ...props,
    })
  }

  fetchGroups = async (organization_uuid: string): Promise<AxiosResponse<GroupRetrieve[]>> => {
    return this.fetchResource(`/api/organizations/${organization_uuid}/groups/`)
  }

  createGroup = async (
    props: CreateGroupRequestProps & { organization_uuid: string },
  ): Promise<AxiosResponse<GroupRetrieve>> => {
    const { organization_uuid } = props
    return this.postResource(`/api/organizations/${organization_uuid}/groups/`, {
      ...props,
    })
  }

  deleteGroup = async (group_uuid: string): Promise<AxiosResponse> => {
    return this.deleteResource(`/api/organizations/groups/${group_uuid}/`)
  }

  updateGroup = async (props: PatchedGroupRequest): Promise<AxiosResponse> => {
    const { uid } = props
    return this.patchResource(`/api/organizations/groups/${uid}/`, {
      ...props,
    })
  }

  fetchSIDPermissions = async (): Promise<AxiosResponse> => {
    const { data } = await this.fetchResource(`/api/permissions/`)
    return data
  }

  fetchUserManagementChangeHistory = async (organization_uuid: string): Promise<AxiosResponse<UserManagementLog[]>> => {
    return this.fetchResource(`/api/organizations/${organization_uuid}/user-management-logs/`)
  }
}

const api = new SidAPI()
export { api as SidAPI }
