import React, { Fragment, useMemo, useState } from "react"

import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, useMediaQuery } from "@mui/material"
import { Button, InputText, Menu, MenuItem, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { FormikProvider, useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"

import { queryClient } from "../.."
import { VersionTag } from "../../components/VersionTag"
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { VersioningDialog } from "../../components/dialogs/VersioningDialog"
import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { CreateScoreCardRequest, Operators, ScoreRule, UpdateScorecardRequest } from "../../types/custom/projects"
import {
  CustomRuleRequest,
  MapValueTypeToFeatureType,
  Rule,
  ScorecardRule,
  VersionChangeRequest,
} from "../../types/custom/rules"
import { SchemaFeature } from "../../types/custom/workflows"
import { ScoreCardCreateUpdate } from "../../types/generated/api/ScoreCardCreateUpdate"
import { ScoreCardGroupRetrieve } from "../../types/generated/api/ScoreCardGroupRetrieve"
import { ScoreCardList } from "../../types/generated/api/ScoreCardList"
import { ScoreCardRetrieve } from "../../types/generated/api/ScoreCardRetrieve"
import { WorkflowSchemaFeature } from "../../types/generated/api/WorkflowSchemaFeature"
import { detectRuleType, getFieldErrorIfTouched, getNewRuleInfo, levelSchema } from "../../utils/conditionHelpers"
import { extractScorecardErrorsMessages, getValidMaxMinorVersion } from "../../utils/deploymentDetailsHelpers"
import {
  convertComplexConditionsToNestedForm,
  convertComplexFormToCondition,
  isTypeIncluded,
} from "../../utils/rulesetHelpers"
import { EMPTY_SCORECARD_CONDITION } from "../ScoreCardSets/CONSTANTS"
import { ScorecardLoadingComponent } from "../ScoreCardSets/Scorecardset"
import { RuleCard } from "./RuleCard"

import styles from "./Scorecard.module.scss"

/**
 * ScorecardMenu component renders a menu for scorecard actions including deletion.
 *
 * @param name - The name of the scorecard.
 * @param uuid - The unique identifier of the scorecard.
 * @param {"card" | "table"} mode - The mode of the scorecard, either "card" or "table".
 * @returns A React element representing the scorecard menu.
 *
 * The component uses the following hooks:
 * - `useParams` to get the project ID from the URL parameters.
 * - `useState` to manage the state of the anchor element for the menu and the deletion dialog.
 * - `useMutation` to handle the deletion of the scorecard using an API call.
 *
 * The component renders:
 * - A deletion dialog (`BaseSimpleDialog`) that appears when the user opts to delete the scorecard.
 * - An icon button (`IconButton`) that opens the menu when clicked.
 * - A menu (`Menu`) with a menu item (`MenuItem`) to trigger the deletion dialog.
 *
 * The deletion mutation:
 * - Invalidates relevant queries on success to refresh the data.
 * - Displays a success or error notification based on the outcome of the deletion.
 */
export const ScorecardMenu = (props: {
  name: string
  uuid: string
  mode: "Scorecard" | "ScoreTable"
}): React.ReactElement => {
  const { name, uuid, mode } = props
  const { id: projectId } = useParams<{ id: string }>()

  const theme = getTheme()

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchor)

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  // scorecard deletion Mutation
  const deleteScorecardMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    { project_uuid: string; resource_uuid: string }
  >((props) => KonanAPI.deleteWorkflowResource({ ...props, resource_type: "scorecards" }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["scorecard", uuid])
      await queryClient.invalidateQueries(["scorecards", projectId])

      NotificationUtils.toast(`${mode} deleted successfully`, {
        snackBarVariant: "positive",
      })
      setIsDeletionDialogOpen(false)
    },
    onError: ({ response }) => {
      NotificationUtils.toast(response?.data?.details ?? `${mode} deletion failed`, { snackBarVariant: "negative" })
    },
  })

  return (
    <Fragment>
      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          open
          name={name}
          isLoading={deleteScorecardMutation.isLoading}
          mode={"scorecard-deletion"}
          onAccept={() =>
            deleteScorecardMutation.mutateAsync({
              project_uuid: projectId as string,
              resource_uuid: uuid,
            })
          }
          onClose={() => setIsDeletionDialogOpen(false)}
        />
      )}

      <Grid container item xs display={"flex"} justifyContent={"center"} alignContent={"center"} mr={0.5} mt={0.25}>
        <IconButton
          aria-label="settings"
          size="small"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
        >
          <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
        </IconButton>

        <Menu
          key={`deletion-menu-${uuid}`}
          anchorEl={anchor}
          open={isMenuOpen}
          onClose={() => setAnchor(null)}
          menuMaxContent
        >
          <MenuItem
            onClick={() => {
              setIsDeletionDialogOpen(true)
              setAnchor(null)
            }}
          >
            <Typography variant="a" color="negative" variantColor={2}>
              Remove {mode}
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Fragment>
  )
}

interface ScorecardProps {
  scorecard?: ScoreCardList
  isCreateMode?: boolean
  handleCancel?: () => void
  goToFirstPage?: () => void
  disableDuplicateButton?: boolean
  duplicateScoreCard?: (scorecard: ScoreCardList) => void
  isDuplicateMode?: boolean
}

// Yup validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("Scorecard name is required"),
  weight: Yup.number().typeError("Numerical value is required"),
  rules: Yup.array().of(
    Yup.object().shape({
      levelOneConditions: Yup.array().of(levelSchema),
      levelTwoConditions: Yup.array().of(levelSchema),
      return_value: Yup.number().typeError("Numeric value is required").required("Rule weight is required"),
    }),
  ),
})

/**
 * Renders the Scorecard component.
 *
 * @param {Readonly<ScorecardProps>} props - The props for the Scorecard component.
 * @returns {React.ReactElement} The rendered Scorecard component.
 */
export function Scorecard(props: Readonly<ScorecardProps>): React.ReactElement {
  const {
    scorecard,
    isCreateMode = false,
    handleCancel,
    goToFirstPage,
    disableDuplicateButton,
    duplicateScoreCard,
    isDuplicateMode = false,
  } = props
  const { id: projectId } = useParams<{ id: string }>()

  const theme = getTheme()

  const [mode, setMode] = useState<"create" | "edit" | "read">(isCreateMode ? "create" : "read")

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // specific media query breakpoint
  const smallActionButtons = useMediaQuery("(min-width:925px)")
  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)

  /**
   * Fetches the scorecard data from the server.
   *
   * @returns The scorecard data and loading status.
   */
  const { data: scorecardData, isLoading: isScorecardDataLoading } = useQuery<
    AxiosResponse<ScoreCardGroupRetrieve>,
    AxiosError
  >(["scorecard", scorecard?.uuid], () => KonanAPI.fetchScorecard(projectId as string, scorecard?.uuid as string), {
    onSuccess: async ({ data }) => {
      const activeVersion = data?.versions?.find((version: ScoreCardRetrieve) => version?.is_active_version === true)

      await formik.setValues({
        uuid: data.uuid,
        name: scorecard?.name ?? data.name,
        rules: activeVersion?.rules
          ?.sort((a, b) => a?.order - b?.order)
          .map((rule) => {
            const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)

            return {
              type: detectRuleType(rule?.condition),
              condition: rule?.condition,
              id: uuidv4(),
              ruleName: rule?.name,
              levelOneConditions:
                convertedRule && convertedRule.length > 0 ? convertedRule[0] : [EMPTY_SCORECARD_CONDITION],
              levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
              levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
              return_value: rule?.return_value,
              condition_list_files: rule?.condition_list_files,
            }
          }),
      })
    },
    // Only enable the query if the scorecard?.uuid is not undefined
    enabled: !!scorecard?.uuid,
  })

  const createScoreCardMutation = useMutation<
    AxiosResponse,
    AxiosError<CreateScoreCardRequest>,
    CreateScoreCardRequest
  >(KonanAPI.createScorecard, {
    mutationKey: "createScorecard",
    onSuccess: async () => {
      await queryClient.invalidateQueries(["scorecards", projectId])

      // closing the scorecard view upon creation
      handleCancel?.()
      NotificationUtils.toast("Scorecard successfully created", {
        snackBarVariant: "positive",
      })

      goToFirstPage?.()
    },
    onError: async (response: AxiosError<CreateScoreCardRequest>) => {
      const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "create")

      NotificationUtils.toast(errorMessage, {
        snackBarVariant: "negative",
      })
    },
  })

  const updateScorecardMutation = useMutation<
    AxiosResponse<ScoreCardCreateUpdate>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    UpdateScorecardRequest
  >(KonanAPI.updateScorecard, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["scorecard", scorecard?.uuid])
      await queryClient.invalidateQueries(["scorecards", projectId])

      setMode("read")

      NotificationUtils.toast("Scorecard updated successfully!", {
        snackBarVariant: "positive",
      })
    },
    onError: async (response: AxiosError<UpdateScorecardRequest>) => {
      const errorMessage = extractScorecardErrorsMessages(response?.response?.data, "update")

      NotificationUtils.toast(errorMessage, {
        snackBarVariant: "negative",
      })
    },
  })

  /**
   * Mutation hook for changing the version of a resource.
   *
   * @param {VersionChangeRequest} requestData - The request data for changing the version.
   * @returns {void}
   */
  const versionSwitchMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["scorecard", scorecard?.uuid])
        await queryClient.invalidateQueries(["scorecards", projectId])
        // TODO: remove this line when backend order scorecards based on weight
        /**
         * currently, the backend returns the order of scorecards, according to the created_at field
         * which changes after each new version, so let's say this is the order of scorecards (a - b - c)
         * after updating b for example, it will be -> (b - a - c) .. so this shift in order will reflect
         * in the content of the version history because now the same index is pointing to a different scorecard
         * so as of right now, after restoring the newly version we automatically close the dialog
         */
        setIsVersionDialogOpen(false)
      },
    },
  )

  // Memoize active version to render it in the card
  const activeScorecardVersion = useMemo(() => {
    if (scorecardData?.data?.versions && scorecardData?.data?.versions?.length > 0) {
      for (const version of scorecardData?.data?.versions) {
        if (version.is_active_version) {
          return version
        }
      }
    }
  }, [scorecardData])

  // simpler formik specific for editing the current scorecard
  const formik = useFormik({
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      uuid: "",
      name: "",
      type: "string",
      format: "list",
      rules: [
        {
          id: `new-${uuidv4()}`,
          ruleName: "",
          type: "simple",
          levelOneConditions: [EMPTY_SCORECARD_CONDITION],
          levelTwoConditions: [],
          return_value: "",
          condition_list_files: [],
          schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
        },
      ],
    },
    onSubmit: async ({ name, rules }) => {
      const featuresArray: Array<SchemaFeature> = []
      const finalRulesFormat: CustomRuleRequest[] = []

      rules.forEach((ruleCard: ScorecardRule) => {
        const multiValuesOperators = [
          Operators["in"],
          Operators["not in"],
          Operators["contains"],
          Operators["not contains"],
        ]

        if (ruleCard.levelOneConditions && ruleCard.levelOneConditions.length > 0) {
          ruleCard.levelOneConditions.forEach((item: Rule) => {
            if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })
            }

            item.andOr = ruleCard.levelOneConditions[0]?.andOr ?? "and"
            if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
              item.type = item.operator
            }
          })
        }

        if (ruleCard.levelTwoConditions && ruleCard.levelTwoConditions.length > 0) {
          ruleCard.levelTwoConditions.forEach((item: Rule) => {
            if (!featuresArray.find((elm) => elm?.name === item?.feature)) {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })
            }
            item.andOr = ruleCard.levelTwoConditions[0]?.andOr ?? "and"
            if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
              item.type = item.operator
            }
          })
        }

        const newRule = {
          name: `rule-${uuidv4()}`,
          condition: convertComplexFormToCondition(ruleCard.levelOneConditions, ruleCard.levelTwoConditions, []),
          condition_list_files: ruleCard.condition_list_files,
          return_value: ruleCard?.return_value,
        }

        finalRulesFormat.push(newRule)
      })

      mode === "create"
        ? await createScoreCardMutation.mutateAsync({
            // Pass the data of the current Scorecard to the mutation
            name: name?.trim(),
            projectUUID: projectId as string,
            rules: [...finalRulesFormat],
          })
        : await updateScorecardMutation.mutateAsync({
            // Pass the data of the current Scorecard to the mutation
            name: name?.trim() === scorecard?.name ? undefined : name?.trim(),
            scorecardUUID: scorecard?.uuid as string,
            projectUUID: projectId as string,
            rules: [...finalRulesFormat],
          })
    },
  })

  // handler for activating scorecard version
  const changeActiveScorecardVersion = async (version: string): Promise<void> => {
    try {
      await versionSwitchMutation.mutateAsync({
        projectUUID: projectId as string,
        resourceUUID: scorecard?.uuid as string,
        version,
        resource: "scorecards",
      })

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  // children component for the versioning dialog for scorecard
  const ScorecardContainerInVersionHistory = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

    const Version = useMemo(() => {
      if (scorecardData && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(scorecardData.data?.versions, selectedVersion)
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of scorecardData.data?.versions) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, scorecardData])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeScorecardVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeScorecardVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeScorecardVersion?.version

    const tagVariant = activeScorecardVersion?.version === selectedVersion ? "positive" : "default"

    // simpler formik specific for editing the current scorecard
    const versionFormik = useFormik({
      initialValues: {
        uuid: uuidv4(),
        name: formik.values.name,
        rules: Version?.rules?.map((rule) => {
          const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)

          return {
            id: rule.uuid,
            ruleName: rule.name,
            type: detectRuleType(rule?.condition),
            condition: rule?.condition,
            levelOneConditions: convertedRule[0],
            levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
            levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
            condition_list_files: rule.condition_list_files,
            return_value: rule.return_value,
            schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
          }
        }),
      },
      onSubmit: () => {
        return
      },
    })

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {scorecardData?.data?.name}
            </Typography>

            <VersionTag
              version={isNaN(Number(Version?.version)) ? undefined : (Number(Version?.version) ?? selectedVersion)}
              variant={Version ? tagVariant : "positive"}
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => changeActiveScorecardVersion(selectedVersion as string)}
              disabled={versionSwitchMutation.isLoading}
            >
              {versionSwitchMutation.isLoading ? <CircularProgress size={12} /> : "Restore"}
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0} mt={1}>
          <FormikProvider value={versionFormik}>
            {versionFormik?.values.rules?.map((rule, index) => {
              return (
                <RuleCard
                  key={rule.id}
                  isScorecardLoading={Boolean(updateScorecardMutation.isLoading)}
                  id={`${index}`}
                  scorecardIndex={index}
                  ruleIndex={index}
                  updateMode={false}
                  createMode={false}
                  isDuplicateMode={isDuplicateMode}
                />
              )
            })}
          </FormikProvider>
        </Grid>
      </Grid>
    )
  }

  const handleAddingNewRule = (ruleType: "simple" | "complex"): void => {
    const condition = getNewRuleInfo(formik?.values)?.commonFeature

    formik?.setFieldValue(`rules`, [
      ...formik?.values?.rules,
      {
        type: ruleType,
        id: `new-${uuidv4()}`,
        ruleName: `new-${uuidv4()}`,
        levelOneConditions:
          ruleType === "complex"
            ? Array(2).fill({
                ...EMPTY_SCORECARD_CONDITION,
                feature: condition?.name,
                type: condition?.type,
                value: isTypeIncluded(condition?.type as string) ? condition?.type : "",
              })
            : [
                {
                  ...EMPTY_SCORECARD_CONDITION,
                  feature: condition?.name,
                  type: condition?.type,
                  value: isTypeIncluded(condition?.type as string) ? condition?.type : "",
                },
              ],
        levelTwoConditions: [],
        return_value: "",
        condition_list_files: [],

        schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
      },
    ])
  }

  const handleCancelScorecard = (): void => {
    if (isCreateMode) {
      handleCancel?.()

      return
    }

    setMode("read")

    formik.setValues({
      uuid: scorecardData?.data?.uuid,
      name: scorecardData?.data?.name,
      rules: activeScorecardVersion?.rules
        ?.sort((a, b) => a?.order - b?.order)
        .map((rule) => {
          const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)

          return {
            type: detectRuleType(rule?.condition),
            id: uuidv4(),
            ruleName: rule?.name,
            levelOneConditions:
              convertedRule && convertedRule.length > 0 ? convertedRule[0] : [EMPTY_SCORECARD_CONDITION],
            levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
            levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
            return_value: rule?.return_value,
            condition_list_files: rule?.condition_list_files,
            condition: rule?.condition,
          }
        }),
    })
  }

  return (
    <Fragment>
      {/* Versioning Dialog */}
      {isVersionDialogOpen && (
        <VersioningDialog
          isOpen={isVersionDialogOpen}
          onClose={() => setIsVersionDialogOpen(false)}
          versions={scorecardData?.data?.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          maxWidth="lg"
          activeVersion={activeScorecardVersion as ScoreCardRetrieve}
        >
          <ScorecardContainerInVersionHistory />
        </VersioningDialog>
      )}

      {isScorecardDataLoading ? (
        <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
          <ScorecardLoadingComponent />
        </Grid>
      ) : (
        <Card className={styles.card}>
          <CardHeader
            className={styles.cardHeader}
            title={
              <Grid
                direction={smallActionButtons ? "row" : "column"}
                container
                justifyContent={smallActionButtons ? "space-between" : "flex-start"}
                alignItems={smallActionButtons ? "center" : "flex-start"}
                maxWidth="100%"
              >
                {mode !== "read" ? (
                  <Grid item container width="fit-content" xs={6} md={3}>
                    <InputText
                      id={`name`}
                      hideDescription
                      placeholder="Name"
                      value={formik.values.name}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      error={getFieldErrorIfTouched(formik?.errors, formik?.touched, `name`)}
                      disabled={formik.isSubmitting || Boolean(updateScorecardMutation.isLoading)}
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <Grid item container width="fit-content" xs={6}>
                    <Typography style={{ width: "100%" }} className={styles.cardTitle} noWrap variant="h3-bold">
                      {formik.values.name}
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  justifyContent="flex-end"
                  style={{ justifyContent: smallActionButtons ? "flex-end" : "flex-start" }}
                >
                  {mode === "read" ? (
                    <Fragment>
                      {mode === "read" && (
                        <Box mr={1} alignSelf="flex-start" mt={-0.3}>
                          <Button
                            size="regular"
                            variant="secondary"
                            disabled={disableDuplicateButton}
                            onClick={() => {
                              duplicateScoreCard?.(scorecard as ScoreCardList)
                            }}
                          >
                            Duplicate
                          </Button>
                        </Box>
                      )}
                      <Box mr={1} alignSelf="flex-start" mt={-0.3}>
                        <Button
                          size="regular"
                          variant="secondary"
                          onClick={() => {
                            setSelectedVersion(activeScorecardVersion?.version)
                            setIsVersionDialogOpen(true)
                          }}
                        >
                          History
                        </Button>
                      </Box>
                      <Box alignSelf="flex-start" mt={-0.3}>
                        <Button
                          variant="secondary"
                          disabled={Boolean(updateScorecardMutation.isLoading)}
                          size="regular"
                          onClick={() => setMode("edit")}
                        >
                          Edit
                        </Button>
                      </Box>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Box mr={1} alignSelf="flex-start">
                        <Button
                          disabled={Boolean(updateScorecardMutation.isLoading)}
                          onClick={handleCancelScorecard}
                          size="regular"
                          variant={"secondary"}
                        >
                          cancel
                        </Button>
                      </Box>
                      <Box alignSelf="flex-start">
                        <Button
                          variant="primary"
                          size="regular"
                          onClick={() => formik.submitForm()}
                          disabled={Boolean(updateScorecardMutation.isLoading)}
                          isLoading={updateScorecardMutation.isLoading || createScoreCardMutation.isLoading}
                        >
                          Save
                        </Button>
                      </Box>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            }
            action={
              mode === "read" && (
                <ScorecardMenu name={formik.values.name} uuid={scorecard?.uuid as string} mode="Scorecard" />
              )
            }
          />

          {/* Card content */}
          <CardContent className={styles.cardContent}>
            <Grid py={0.5} item container xs={12} direction="column" justifyContent="center">
              <FormikProvider value={formik}>
                {formik?.values?.rules?.map((item: ScoreRule, index: number) => (
                  <RuleCard
                    key={item.id}
                    isScorecardLoading={Boolean(updateScorecardMutation.isLoading)}
                    ruleIndex={index}
                    editMode={mode === "edit"}
                    createMode={mode === "create"}
                  />
                ))}
              </FormikProvider>

              {mode !== "read" && (
                <Grid item mb={1} ml={2}>
                  <Button
                    size="regular"
                    variant="ghost"
                    disabled={
                      formik?.isSubmitting ||
                      Boolean(updateScorecardMutation.isLoading) ||
                      formik.values.rules?.length > Number(window.__RUNTIME_CONFIG__.KONAN_MAX_RULE_NUMBER)
                    }
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    tooltip={
                      formik.values.rules?.length > Number(window.__RUNTIME_CONFIG__.KONAN_MAX_RULE_NUMBER)
                        ? "You have reached the maximum number of rules"
                        : undefined
                    }
                  >
                    + Add Rule
                  </Button>
                  <Menu
                    key="basic-menu1"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    menuMaxContent
                  >
                    <MenuItem
                      onClick={() => {
                        handleAddingNewRule("simple")
                        setAnchorEl(null)
                      }}
                    >
                      <Grid container display={"flex"} gap={1} height={"20px"}>
                        <Grid item>
                          <AddOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                        </Grid>
                        <Grid item>
                          <Typography variant="a">Add Rule</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleAddingNewRule("complex")
                        setAnchorEl(null)
                      }}
                    >
                      <Grid container display={"flex"} gap={1} height={"20px"}>
                        <Grid item>
                          <AddBoxOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                        </Grid>
                        <Grid item>
                          <Typography variant="a">Add Group</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Menu>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Fragment>
  )
}
