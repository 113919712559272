import React from "react"

import { Card, Grid, Skeleton } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "../ModelConfigurations.module.scss"

/**
 * Evaluation card loader
 * @return {React.ReactElement}
 */
function EvaluationDataCardLoader(): React.ReactElement {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="flex-start"
      className={styles.dataCard}
    >
      <Skeleton height={20} width={"60%"} />
      <Skeleton height={20} width={"40%"} />
    </Grid>
  )
}

/**
 * Model Configuration card loader
 * @return {React.ReactElement}
 */
export function ConfigurationCardLoader(): React.ReactElement {
  return (
    <Card className="card-box-shadow">
      <Grid container direction="column" className={styles.headerContainer}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start">
          <Skeleton variant="rectangular" height={15} width={"15%"} />
        </Grid>

        <Grid item xs={12} mt={0.5}>
          <Skeleton variant="rectangular" height={15} width={"60%"} />
        </Grid>

        <Grid container justifyContent="space-between">
          <Grid
            container
            justifyContent="space-between"
            direction="row"
            item
            xs={12}
            spacing={1}
            alignItems="flex-start"
            className={styles.avatarContainer}
          >
            <Grid item mt={0.25}>
              <Skeleton variant="circular" width={32} height={32} />
            </Grid>

            <Grid item xs={12}>
              <Grid item mt={0.5}>
                <Skeleton variant="rectangular" height={10} width={"50%"} />
              </Grid>

              <Grid item mt={0.5}>
                <Skeleton variant="rectangular" height={10} width={"30%"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="column" className={styles.bodyContainer}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6}>
            <Typography
              variant="label"
              className={styles.labelColor}
              tooltipIconSize={16}
              tooltipVerticalAlign="middle"
              tooltip={``}
            >
              Date Started
            </Typography>

            <Grid item className={styles.idbox} sx={{ width: "100px" }}>
              <Skeleton variant="rectangular" height={15} width={"100%"} />
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="label"
              className={styles.labelColor}
              tooltipIconSize={16}
              tooltipVerticalAlign="middle"
              tooltip={``}
            >
              Date Ended
            </Typography>

            <Grid item className={styles.idbox} sx={{ width: "100px" }}>
              <Skeleton variant="rectangular" height={15} width={"100%"} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} mt={1.5}>
          <Grid item xs={6}>
            <Typography
              variant="label"
              className={styles.labelColor}
              tooltipIconSize={16}
              tooltipVerticalAlign="middle"
              tooltip={``}
            >
              Target
            </Typography>

            <Grid item className={styles.idbox} sx={{ width: "100px" }}>
              <Skeleton variant="rectangular" height={15} width={"100%"} />
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="label"
              className={styles.labelColor}
              tooltipIconSize={16}
              tooltipVerticalAlign="middle"
              tooltip={``}
            >
              Target Type
            </Typography>

            <Grid item className={styles.idbox} sx={{ width: "100px" }}>
              <Skeleton variant="rectangular" height={15} width={"100%"} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} mt={1.5}>
          <Grid item xs={12}>
            <Typography
              variant="label"
              className={styles.labelColor}
              tooltipIconSize={16}
              tooltipVerticalAlign="middle"
              tooltip={``}
            >
              Evaluation Metrics
            </Typography>
          </Grid>

          <Grid container item xs={12} spacing={1} className={styles.horizontalScroller}>
            {[1, 2].map((_, index: number) => (
              <Grid item xs={4} className={styles.evaluationCard} key={index}>
                <EvaluationDataCardLoader />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
