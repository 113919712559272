import React, { Fragment, useContext, useMemo, useState } from "react"

import { useQuery } from "react-query"

import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Card, Grid, IconButton, Skeleton, SvgIcon } from "@mui/material"
import { Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import moment from "moment"

import { KonanAvatar } from "../../../components/Avatar"
import { KonanAPI } from "../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../store/CurrentProjectAndModelContext"
import { baseErrorType } from "../../../types/custom/projects"
import { ClassificationEvaluation } from "../../../types/generated/api/ClassificationEvaluation"
import { EvaluateResult } from "../../../types/generated/api/EvaluateResult"
import { ModelSettingsSet } from "../../../types/generated/api/ModelSettingsSet"
import { PredefinedMetric } from "../../../types/generated/api/PredefinedMetric"
import { ConfigurationLabelsDialog } from "./ConfigurationLabelsDialog"

import styles from "../ModelConfigurations.module.scss"

// predefined metrics keys mapping
const predefinedMetricsMap: Record<string, string> = {
  mae: "Mean Absolute Error",
  rmse: "Root Mean Squared Error",
  f1_score: "F1 Score",
  precision: "Precision",
  recall: "Recall",
}

/**
 * Evaluation data card loader
 * @param {string} title
 * @param {number | null} Value
 * @return {React.ReactElement}
 */
function EvaluationDataCardLoader(): React.ReactElement {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="flex-start"
      className={styles.dataCard}
    >
      <Skeleton height={20} width={"60%"} />
      <Skeleton height={20} width={"40%"} />
    </Grid>
  )
}

type EvalProps = {
  title: string
  Value: number | null
}

/**
 * Evaluation data card
 * @param {string} title
 * @param {number | null} Value
 * @return {React.ReactElement}
 */
function EvaluationDataCard(props: Readonly<EvalProps>): React.ReactElement {
  const { title, Value } = props

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className={styles.dataCard}
    >
      {/* Title */}
      <Typography noWrap variant="label" style={{ width: "100%" }}>
        <Tooltip title={title} placement="top" width="100%">
          <Typography variant="p" noWrap className={styles.trainingText}>
            {title}
          </Typography>
        </Tooltip>
      </Typography>

      {/* Value */}
      <Grid item xs={12}>
        <Typography variant="span" noWrap className={styles.trainingText} variantColor={2}>
          {Value ?? "N/A"}
        </Typography>
      </Grid>
    </Grid>
  )
}

type Props = {
  Configuration: ModelSettingsSet
}

/**
 * Model Configuration card
 * @param {ModelSettingsSet} Configuration
 * @return {React.ReactElement}
 */
export function ConfigurationCard(props: Readonly<Props>): React.ReactElement {
  const { Configuration } = props
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const [labelsDialogOpen, setLabelsDialogOpen] = useState<boolean>(false)

  // fetch model predictions data
  // labels, confusion matrix, coverage, accuracy, ...
  const {
    data: autoMlEvalData,
    isLoading: isAutoMlEvalLoading,
    isError: isAutoMlEvalError,
    error: autMlEvalError,
  } = useQuery<AxiosResponse<ClassificationEvaluation>, AxiosError<baseErrorType>>(
    [["classification-evaluation-AutoMl", Configuration.created_at]],
    () =>
      KonanAPI.fetchModelClassificationEvaluationMetrics(
        Configuration.created_at,
        Configuration.deactivation_date ?? moment().toISOString(),
        Configuration.model as string,
      ),
    {
      retry: false,
      enabled: currentProject.type === "credit_scoring",
    },
  )

  // fetch model predictions data
  // labels, confusion matrix, coverage, accuracy, ...
  const {
    data: evaluationData,
    isLoading: isEvalMetricsLoading,
    isError: isEvalError,
    error: evalError,
  } = useQuery<EvaluateResult, AxiosError<baseErrorType>>(
    [["classification-evaluation", Configuration.created_at]],
    () =>
      KonanAPI.evaluateModel({
        start_date: Configuration.created_at,
        end_date: Configuration.deactivation_date ?? moment().toISOString(),
        model_uuid: Configuration.model as string,
      }),
    {
      retry: false,
      enabled: currentProject.type !== "credit_scoring",
    },
  )

  const evalData = useMemo(() => {
    const result = currentProject.type === "credit_scoring" ? autoMlEvalData?.data : evaluationData?.metrics.predefined
    delete result?.confusion_matrix
    delete result?.multi_label_confusion_matrix
    delete result?.labels

    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoMlEvalData?.data, evaluationData?.metrics.predefined])

  return (
    <Fragment>
      <ConfigurationLabelsDialog
        open={labelsDialogOpen}
        onClose={() => setLabelsDialogOpen(false)}
        Configuration={Configuration}
      />

      <Card className="card-box-shadow">
        <Grid container direction="column" className={styles.headerContainer}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start">
            <Tag variant={Configuration.is_active ? "positive" : "default"} size="small">
              {Configuration.is_active ? "Active" : "Deactivated"}
            </Tag>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3-bold" noWrap className={styles.headerName}>
              {Configuration.name}
            </Typography>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              item
              xs={8}
              spacing={1}
              alignItems="flex-start"
              className={styles.avatarContainer}
            >
              <Grid item sx={{ marginTop: "2px" }}>
                <SvgIcon className={styles.avatar}>
                  <KonanAvatar size={24} name={Configuration.created_by} />
                </SvgIcon>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="label" noWrap>
                  {Configuration.created_by}
                </Typography>

                <Typography variant="label" noWrap>
                  <Tooltip title={format(new Date(Configuration.created_at), "dd/MM/yyyy, p")} placement="right">
                    <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                      {moment(new Date(Configuration.created_at)).fromNow()}
                    </Typography>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="column" className={styles.bodyContainer}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <Typography
                variant="label"
                className={styles.labelColor}
                tooltipIconSize={16}
                tooltipVerticalAlign="middle"
              >
                Date Started
              </Typography>

              <Grid item className={styles.idbox} sx={{ width: "100px" }}>
                <Typography variant="p" noWrap className={styles.textIdBox}>
                  {format(new Date(Configuration.created_at), "dd/MM/yyyy, p")}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="label"
                className={styles.labelColor}
                tooltipIconSize={16}
                tooltipVerticalAlign="middle"
              >
                Date Ended
              </Typography>

              <Grid item className={styles.idbox} sx={{ width: "100px" }}>
                <Typography variant="p" noWrap className={styles.textIdBox}>
                  {Configuration.deactivation_date
                    ? format(new Date(Configuration.deactivation_date), "dd/MM/yyyy, p")
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={1} mt={0.5}>
            <Grid item xs={6}>
              <Typography
                variant="label"
                className={styles.labelColor}
                tooltipIconSize={16}
                tooltipVerticalAlign="middle"
                tooltip={`Feature that the model's output is saved in. It can be in one of two formats either range or label`}
              >
                Target
              </Typography>

              <Grid item className={styles.idbox} sx={{ width: "100px" }}>
                <Typography variant="p" noWrap className={styles.textIdBox}>
                  <Tooltip title={Configuration.target_key_path.join(".")} placement="top" width="100%">
                    <Typography variant="p" noWrap className={styles.trainingText}>
                      {Configuration.target_key_path[Configuration.target_key_path.length - 1]}
                    </Typography>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="label"
                className={styles.labelColor}
                tooltipIconSize={16}
                tooltipVerticalAlign="middle"
                tooltip={`Displays whether the output is returned as a range which is a number or label which is a string`}
              >
                Target Type
              </Typography>

              <Grid item className={styles.idbox} justifyContent={"space-between"} sx={{ width: "100px" }}>
                <Typography variant="p" noWrap className={styles.textIdBox}>
                  {Configuration.type.charAt(0).toUpperCase() + Configuration.type.slice(1)}
                </Typography>

                {Configuration.type === "range" && (
                  <IconButton
                    size="small"
                    color="default"
                    onClick={(e) => {
                      e.stopPropagation()
                      setLabelsDialogOpen(true)
                    }}
                    className={styles.icon}
                  >
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} mt={1.5}>
            <Grid item xs={12}>
              <Typography
                variant="label"
                className={styles.labelColor}
                tooltipIconSize={16}
                tooltipVerticalAlign="middle"
                tooltip={`Model's performance in the time this configuration is/was active`}
              >
                Evaluation Metrics
              </Typography>
            </Grid>

            {isEvalMetricsLoading || isAutoMlEvalLoading ? (
              <Grid container item xs={12} spacing={1} className={styles.horizontalScroller}>
                {[1, 2].map((_, index: number) => (
                  <Grid item xs={4} className={styles.evaluationCard} key={index}>
                    <EvaluationDataCardLoader />
                  </Grid>
                ))}
              </Grid>
            ) : (
              (isEvalError || isAutoMlEvalError) && (
                <Grid
                  className={styles.addTrainingSection}
                  container
                  direction="row"
                  justifyContent="space-between"
                  marginBottom={0.5}
                  item
                  xs={12}
                >
                  <Typography variant="span" align="center">
                    {evalError?.response?.data.detail ??
                      autMlEvalError?.response?.data.detail ??
                      "No Metrics to display"}
                  </Typography>
                </Grid>
              )
            )}

            {!isEvalMetricsLoading && !isAutoMlEvalLoading && !isEvalError && !isAutoMlEvalError && (
              <Grid container item xs={12} spacing={1} className={styles.horizontalScroller}>
                {Object.entries(evalData as PredefinedMetric)
                  .reverse()
                  .map(([key, value]) => (
                    <Grid item xs={true} className={styles.evaluationCard} key={`${key}-${value}`}>
                      <EvaluationDataCard
                        title={key in predefinedMetricsMap ? predefinedMetricsMap[key] : key}
                        Value={typeof value === "number" ? value.toFixed(2) : value}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
