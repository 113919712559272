/* generated using openapi-typescript-codegen -- do not edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { NodeCreateRequest } from "./NodeCreateRequest"
import type { WorkflowSchemaFeatureRequest } from "./WorkflowSchemaFeatureRequest"

/**
 * Represents raw workflow and its associated nodes where nodes point to each other through their list index.
 */
export type WorkflowCreateRequest = {
  name: string
  /**
   * * `ACTIVE` - ACTIVE
   * * `DISABLED` - DISABLED
   */
  state: WorkflowCreateRequest.state
  /**
   * The index in the nodes list of the workflow's root node
   */
  start_node?: number
  /**
   * A list of objects describing the workflow nodes and pointing to each other using their list index (0-based)
   */
  nodes?: Array<NodeCreateRequest>
  schema?: Array<WorkflowSchemaFeatureRequest>
}
export namespace WorkflowCreateRequest {
  /**
   * * `ACTIVE` - ACTIVE
   * * `DISABLED` - DISABLED
   */
  export enum state {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
  }
}
