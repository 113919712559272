import React, { PropsWithChildren, useEffect, useState } from "react"

import { Box } from "@mui/material"
import { Pagination } from "@synapse-analytics/synapse-ui"

import { useDebounce } from "../../hooks/useDebounce"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const KonanPagination = (props: PropsWithChildren<any>): React.ReactElement | null => {
  const {
    variant = "complex",
    size = "default",
    count,
    page,
    onPageChange: externalOnPageChange,
    rowsPerPage,
    setRowsPerPage,
    hideRowCount = false,
    stripped = false,
    debounceTime = 800,
  } = props

  const [internalPage, setInternalPage] = useState<number>(page + 1)

  const debouncedOnPageChange = useDebounce(externalOnPageChange, debounceTime)

  const onPageChange = (newPage: number): void => {
    setInternalPage(newPage)
    debouncedOnPageChange(newPage - 1)
  }

  // to handle if page is forced to change from outside without calling local onPageChange so internalPage must be updated
  useEffect(() => {
    setInternalPage(page + 1)
  }, [page])

  return (
    <Box className="pagination" width={"100%"} p={1.5} pl={stripped ? 0 : 1.5} pr={stripped ? 0 : 1.5}>
      <Pagination
        variant={variant}
        totalCount={count}
        page={internalPage}
        onPageChange={onPageChange}
        pageSize={rowsPerPage}
        onPageSizeChange={setRowsPerPage}
        hidePageSize={hideRowCount}
        size={size}
      />
    </Box>
  )
}
