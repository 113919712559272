import React from "react"

import { useNavigate } from "react-router-dom"

import { Snackbar } from "@synapse-analytics/synapse-ui"

type PropType = {
  id: string
}

/**
 * Alert to be used when the user creates a classification project / model but has not configured it yet.
 * @param props
 * @constructor
 */
export function KonanClassificationAlert(props: Readonly<PropType>): React.ReactElement {
  const { id } = props
  const navigate = useNavigate()

  // Alert shown at all project screens
  // when the live model settings hasnt been configured yet
  return (
    <Snackbar
      action="Configure"
      variant="negative"
      onAction={() => navigate(`/projects/${id}/decision-engines?page=Configuration`)}
      description="Classification settings have not been configured for the live model yet. This project will be treated as a generic
      project until the classification settings are configured."
    />
  )
}
