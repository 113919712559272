import React, { useContext } from "react"

import { useQuery } from "react-query"

import Grid from "@mui/material/Grid"
import { DateRangePicker } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"

import { KonanLogViewer } from "../../components/KonanLogViewer"
import { KonanPageHeader } from "../../components/KonanPageHeader"
import { useDateQuery } from "../../hooks/useDateQuery"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { DeploymentLogs } from "../../types/generated/api/DeploymentLogs"

export function ProjectLogsView(): React.ReactElement {
  const { currentModel } = useContext(CurrentProjectAndModelContext)

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery(6)

  const { isLoading, data = { logs: [] } } = useQuery<DeploymentLogs, AxiosError>(
    ["project-logs", currentModel, startDate, endDate],
    () =>
      KonanAPI.fetchModelLogs({
        start_date: startDate?.toISOString() as string,
        end_date: endDate?.toISOString() as string,
        model_uuid: currentModel,
      }),
    { enabled: startDate != null && endDate != null, refetchInterval: 3000 },
  )

  const lastWeekOnly = (date: moment.Moment): boolean => {
    return moment().subtract(7, "days") < date && date < moment()
  }

  return (
    <Grid container spacing={2}>
      <KonanPageHeader
        title="Model Logs"
        actions={[
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            // TODO:: fix in SUI to enable both options at the same time
            // disableFuture
            isOutsideRange={lastWeekOnly}
          />,
        ]}
      />

      <Grid item xs={12}>
        <KonanLogViewer type={"LogsPage"} data={data.logs} isLoading={isLoading} projectUUID={currentModel} />
      </Grid>
    </Grid>
  )
}
