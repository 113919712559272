import React, { Fragment } from "react"

import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { BarDatum, ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { DataDriftBarChartData } from "../../types/custom/projects"
import { orderModelsByStatus } from "../../utils/searchSortFilterHelpers"
import { GraphHeader } from "../GraphHeader"

type Props = {
  title: string
  data: DataDriftBarChartData[]
  isLoading: boolean
  graphHeight: number
}

export function DataDriftBarChart(props: Props): React.ReactElement {
  const { title, data, isLoading, graphHeight } = props

  const theme = getTheme()

  const maxLabelLength = 24

  //Needed to add data drift unavailable bars
  const adjustedData: DataDriftBarChartData[] = React.useMemo(() => {
    if (data && data.length > 0) {
      const newData = Array.from(data)
      //To find the maximum value of "total" in the array:
      const max = newData.reduce((prev, current) => (prev.total > current.total ? prev : current)).total
      newData.forEach((item) => {
        if (item.passed === 0 && item.drifted === 0) {
          item.unavailable = max === 0 ? 1 : max
          item.passed = 0
          item.drifted = 0
        } else {
          item.unavailable = 0
        }
      })

      return orderModelsByStatus(newData, "desc")
    }
    return []
  }, [data])

  return (
    <div className={"charts-paper"}>
      <GraphHeader title={title} tooltipText="Detects trend changes in features compared to the training data" />

      <Box style={{ height: graphHeight, padding: "4px 16px 8px 16px" }}>
        {(!data || data.length < 1) && !isLoading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            <Typography variant="p" variantColor={2}>
              No data to display
            </Typography>
          </Grid>
        ) : isLoading || !data ? (
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className={"full-height"}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="75%" />
            <Skeleton animation="wave" width="60%" height={30} />
            <Skeleton animation="wave" width="30%" />
          </Grid>
        ) : (
          <ResponsiveBar
            data={adjustedData as BarDatum[]}
            theme={NivoTheme(theme)}
            indexBy="name"
            keys={["passed", "drifted", "unavailable"]}
            colors={[theme.palette.blue.background[1], theme.palette.yellow.text[2], theme.palette.grayscale.border]}
            colorBy="id"
            margin={{ top: 30, right: 0, bottom: -20, left: 0 }}
            borderRadius={4}
            padding={0.75}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            // valueFormat={(v) => v.toFixed(0)}
            enableLabel={false}
            enableGridY={false}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["brighter", 3]] }}
            animate={true}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -22,
                itemsSpacing: 0,
                itemWidth: 85,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 15,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            legendLabel={(v) => v.id.toString().toUpperCase()}
            labelSkipWidth={2000} // labelSkipWidth = minimum bar size needed for a normal label
            layers={[
              "grid",
              "axes",
              "bars",
              "markers",
              "legends",
              "annotations",
              ({ bars }) => {
                return (
                  <g>
                    {bars.map(({ width, y, key, data }) => {
                      return width ? (
                        <Fragment key={key}>
                          {/* Model name on bar */}
                          <text
                            //Calculate position of model name
                            transform={`translate(${0}, ${y - 9})`}
                            textAnchor="left"
                            dominantBaseline="central"
                            className="chart-text"
                          >
                            {`${
                              key.slice(key.indexOf(".") + 1).length > maxLabelLength
                                ? key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength) + "..."
                                : key.slice(key.indexOf(".") + 1)
                            }`}
                          </text>
                          {!data.value ? (
                            ""
                          ) : data.id === "passed" ? (
                            //Left value on bar
                            key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength).length * 10 +
                              data?.value?.toString().length * 10 -
                              20 <
                            width ? (
                              <text
                                transform={`translate(${
                                  //Calculate position left value
                                  width - data?.value?.toString().length * 10 - 10
                                }, ${y - 9})`}
                                textAnchor="right"
                                dominantBaseline="central"
                                className="chart-text"
                              >
                                {data.value ? `${data.value}` : ""}
                              </text>
                            ) : (
                              ""
                            )
                          ) : //Right value on bar
                          data.id !== "unavailable" ? (
                            data?.value?.toString().length * 10 + 15 < width ? (
                              <text
                                transform={`translate(${
                                  //Calculate position of right
                                  bars[bars.findIndex((item) => item.key.includes(key.slice(key.indexOf(".") + 1)))]
                                    .width +
                                  width -
                                  data?.value?.toString().length * 10 -
                                  10
                                }, ${y - 9})`}
                                textAnchor="right"
                                dominantBaseline="central"
                                className="chart-text"
                              >
                                {data.value ? `${data.value}` : ""}
                              </text>
                            ) : (
                              ""
                            )
                          ) : key.slice(key.indexOf(".") + 1).slice(0, maxLabelLength).length * 10 + 150 < width ? (
                            <text
                              transform={`translate(${width - 170}, ${y - 9})`}
                              textLength={158}
                              textAnchor="right"
                              dominantBaseline="central"
                              className="chart-text"
                            >
                              {data.value ? `Data Drift Unavailable` : ""}
                            </text>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      ) : null
                    })}
                  </g>
                )
              },
            ]}
            tooltip={(slice) => {
              return (
                <div className={"chart-tooltip"}>
                  <Typography variant="h3-bold" variantColor={2}>
                    {slice.data.name}
                  </Typography>

                  {slice.data.total && (
                    <Fragment>
                      <Typography variant="p" variantColor={2}>{`Passed: ${slice.data.passed ?? 0}`}</Typography>
                      <Typography variant="p" variantColor={2}>{`Drifted: ${slice.data.drifted ?? 0}`}</Typography>
                      <Typography variant="p" variantColor={2}>{`Total: ${slice.data.total ?? 0}`}</Typography>
                    </Fragment>
                  )}

                  {!slice.data.total && (
                    <Typography variant="p" variantColor={2}>
                      Data Drift Unavailable
                    </Typography>
                  )}
                </div>
              )
            }}
          />
        )}
      </Box>
    </div>
  )
}
