import React, { useLayoutEffect, useRef, useState } from "react"

import { Box, Divider, Grid, Link, useMediaQuery, useTheme as useMuiTheme } from "@mui/material"
import { InputText, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"

import { UseCaseCard } from "../../../components/UseCaseCard"
import { getTheme } from "../../../hooks/UseTheme"
import { getHostnames } from "../../../utils/genericHelpers"

type Props = {
  formik: FormikProps<{ name: string; description: string; type: string }>
  errorMessage?: string
}

/**
 * Project creation overview, first screen in the creation flow
 * @param  {React.FormikProps} formik formik object to control the form
 * @param  {string} errorMessage error message to display
 * @return  {React.ReactElement}
 */
export function ProjectCreationOverview(props: Props): React.ReactElement {
  const { formik, errorMessage } = props
  const [height, setHeight] = useState<number>()
  // Credit scoring type can be (pre-built/auto-ml)
  const [creditScoringType, setCreditScoringType] = useState<string>("auto-ml")

  const theme = getTheme()
  const MuiTheme = useMuiTheme()

  const isLgDown = useMediaQuery(MuiTheme.breakpoints.down("lg"))

  const firstTextRef = useRef<HTMLElement>(null)
  const secondTextRef = useRef<HTMLElement>(null)

  // layout-specific effect to calculate the height of the larger text to avoid height inconsistency
  useLayoutEffect(() => {
    function handleResize(): void {
      if (secondTextRef.current && firstTextRef.current) {
        // Reset the height of both components to 'auto' to allow for natural height calculation
        secondTextRef.current.style.height = "auto"
        firstTextRef.current.style.height = "auto"

        // Get the natural height of both components
        const firstHeight = secondTextRef.current.offsetHeight
        const secondHeight = firstTextRef.current.offsetHeight

        const maxHeight = Math.max(firstHeight, secondHeight)
        secondTextRef.current.style.height = `${maxHeight}px`
        firstTextRef.current.style.height = `${maxHeight}px`
        setHeight(maxHeight)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    window.addEventListener("zoom", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("zoom", handleResize)
    }
  }, [])

  return (
    <Grid container item direction="column" spacing={2} padding={1}>
      {errorMessage && (
        <Grid item>
          <Snackbar variant="negative" fullWidth description={errorMessage} />
        </Grid>
      )}

      <Grid item>
        <Typography variant="h3-bold">Overview</Typography>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={12} md={6}>
          <InputText
            required
            fullWidth
            id="name"
            label="Name"
            placeholder="New project"
            value={formik.values.name}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
            description={"Required"}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputText
            fullWidth
            label="Description"
            id="description"
            placeholder="Enter description"
            value={formik.values.description}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.touched.description && Boolean(formik.errors.description) && formik.errors.description}
            description={"Optional"}
          />
        </Grid>
      </Grid>

      {/* Use-case section */}
      <Grid container item xs={12}>
        <Grid item xs md={6} lg={4}>
          <Typography variant="h3-bold" gutterBottom style={{ paddingRight: "4px" }}>
            AutoML
          </Typography>

          <Typography
            variant="span"
            gutterBottom
            style={{ paddingRight: "12px", position: "relative", height }}
            ref={firstTextRef}
          >
            Simple. Upload your data to train a customized model for you
          </Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <UseCaseCard
              name="Credit Scoring"
              description="By utilizing the data you upload, train pre-built models to effectively evaluate and make decisions on potential credit applicants."
              onClick={() => {
                formik.setFieldValue("type", "credit_scoring_auto-ml")
                setCreditScoringType("auto-ml")
              }}
              selected={
                formik.values.type === "credit_scoring_auto-ml" || formik.values.type === "credit_scoring_pre-built"
              }
              creditScoringType={creditScoringType}
              setCreditScoringType={setCreditScoringType}
            />
          </Box>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          light
          sx={{
            "&::before, &::after": {
              borderColor: theme.palette.gray.background[1],
            },
            margin: "15px 5px",
          }}
        >
          <Typography variant="span" disabled>
            Or
          </Typography>
        </Divider>

        {/* Deploy your own model section */}
        <Grid item xs>
          <Typography variant="h3-bold" gutterBottom>
            Deploy your own
          </Typography>

          <Typography variant="span" gutterBottom ref={secondTextRef} style={{ position: "relative", height }}>
            Advanced. Deploy your own model. Check our{" "}
            <Link
              href={`https://${getHostnames().docs}/`}
              target="_blank"
              style={{ color: theme.palette.grayscale.text[1], textDecoration: "underline" }}
            >
              documentation
            </Link>{" "}
            to make it production ready
          </Typography>

          <Grid item container xs={12}>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <Box marginBottom={isLgDown ? 2 : 0} flexBasis={{ sm: "100%", lg: "calc(50% - 8px)" }}>
                <UseCaseCard
                  name="Generic"
                  description="Leverage our MLOps capabilities to deploy your model into production seamlessly. Customize any metrics you require for  evaluating the model’s performance."
                  onClick={() => {
                    formik.setFieldValue("type", "generic")
                  }}
                  selected={formik.values.type === "generic"}
                />
              </Box>
              <Box flexBasis={{ sm: "100%", lg: "calc(50% - 8px)" }}>
                <UseCaseCard
                  name="Classification"
                  description="We offer all the features provided by Generic, along with additional configuration settings and ready classification metrics."
                  onClick={() => {
                    formik.setFieldValue("type", "classification")
                  }}
                  selected={formik.values.type === "classification"}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
