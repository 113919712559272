import React from "react"

import { Box, CircularProgress, Paper } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { MonacoTextEditor } from "../../../components/MonacoTextEditor"
import { Auth } from "../../../utils/auth"
import { getHostnames } from "../../../utils/genericHelpers"

import styles from "../Registry.module.scss"

export function RegistryCredentials(): React.ReactElement {
  const orgId = Auth.getOrganizationUID() as string

  const userEmail = Auth.getEmail()

  const registryPushingcodeSnippet =
    "# Login to KCR\n" +
    `docker login -u ${userEmail} -p <password> ${getHostnames().registry}\n` +
    "\n" +
    "# Tag your already built image\n" +
    `docker tag <image>:<tag> ${getHostnames().registry}/${orgId}/<image>:<tag>\n` +
    "\n" +
    "# Push container image to your organization's KCR\n" +
    `docker push ${getHostnames().registry}/${orgId}/<image>:<tag>`

  return (
    <Paper className={styles.creds}>
      <Typography variant="h2-bold">KCR Credentials</Typography>
      <Typography variant="span">
        Pushing container images to Konan's Container Registry (KCR) requires authentication using your KONAN
        credentials.
      </Typography>
      <Box m={3} />

      <Typography variant="h3-regular" gutterBottom>
        Pushing container images to KCR
      </Typography>

      <MonacoTextEditor
        value={registryPushingcodeSnippet}
        language={"shell"}
        readOnly
        automaticLayout
        lineNumbers="off"
        loading={<CircularProgress style={{ color: "rgb(25 118 210)" }} size={36} />}
      />
    </Paper>
  )
}
