/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { Node } from "reactflow"

import { AutoMLTrainingJob } from "../generated/api/AutoMLTrainingJob"
import { ConditionListFile } from "../generated/api/ConditionListFile"
import { DataFile } from "../generated/api/DataFile"
import { ExcludedFeatureRequest } from "../generated/api/ExcludedFeatureRequest"
import { ListModelStateSwitch } from "../generated/api/ListModelStateSwitch"
import { ModelListCreate } from "../generated/api/ModelListCreate"
import { NodeCreateRequest } from "../generated/api/NodeCreateRequest"
import { Program } from "../generated/api/Program"
import { ProgramItemRequest } from "../generated/api/ProgramItemRequest"
import { RetrainingJob } from "../generated/api/RetrainingJob"
import { RuleSetVersionRetrieve } from "../generated/api/RuleSetVersionRetrieve"
import { RulesetRuleCreate } from "../generated/api/RulesetRuleCreate"
import { ScoreCardCreateUpdate } from "../generated/api/ScoreCardCreateUpdate"
import { ScoreCardCreateUpdateRequest } from "../generated/api/ScoreCardCreateUpdateRequest"
import { ScoreCardRuleCreateRequest } from "../generated/api/ScoreCardRuleCreateRequest"
import { ScoreCardSetCreateUpdateRequest } from "../generated/api/ScoreCardSetCreateUpdateRequest"
import { ScoreCardSetRetrieve } from "../generated/api/ScoreCardSetRetrieve"
import { ScoreCardSetScoreCardCreateRequest } from "../generated/api/ScoreCardSetScoreCardCreateRequest"
import { ScriptVersionRetrieve } from "../generated/api/ScriptVersionRetrieve"
import { TagListVersionRetrieve } from "../generated/api/TagListVersionRetrieve"
import { TrainingJob } from "../generated/api/TrainingJob"
import { WorkflowCreateRequest } from "../generated/api/WorkflowCreateRequest"
import { WorkflowSchemaFeatureRequest } from "../generated/api/WorkflowSchemaFeatureRequest"
import { WorkflowVersionsRetrieve } from "../generated/api/WorkflowVersionsRetrieve"

export type CreateProjectRequest = {
  name: string
  type: string
  category: string
  description: string | undefined
}

export type CreateModelRequest = {
  docker_username?: string
  docker_password?: string
  project_uuid: string
  name: string
  image_url: string
  exposed_port?: number | null
  state: string
}

export type CreateRetrainingModelRequest = {
  predictions_start_time: string
  predictions_end_time: string
  append_training_data: boolean
  resulting_model_name: string
  resulting_model_state: string
  project_uuid: string
}

export type CreateModelResponse = {
  model: ModelListCreate
  errors: any
  container_logs: string | null
}

type TimeRange = {
  start_date: string
  end_date: string
}
export interface ProjectTimeRange extends TimeRange {
  project_uuid: string
}

export interface ScriptLogsRange extends TimeRange {
  project_uuid: string
  script_uuid: string
}

export interface ModelTimeRange extends TimeRange {
  model_uuid: string | undefined
}

//this type should serve both training and retraining jobs types
export type TrainingAndRetraining = {
  readonly created_by: string
  readonly training_data: string | null
  readonly metrics?: Record<string, any> | null
  readonly test_percentage?: number | null
  readonly train_percentage?: number | null
  readonly uuid: string
  readonly eval_percentage?: number | null
  readonly resulting_model: string | null
  readonly started_at: string | null
  readonly model?: string
  readonly predictions?: string | null
  readonly ended_at: string | null
  readonly created_at: string
  readonly threshold_metrics?: string | null
  readonly duration: string | null
  readonly cancelled_at: string | null
  readonly status: TrainingJob.status | RetrainingJob.status | AutoMLTrainingJob.status
  resulting_model_name?: string
  resulting_model_state?:
    | TrainingJob.resulting_model_state
    | RetrainingJob.resulting_model_state
    | AutoMLTrainingJob.resulting_model_state
  training: boolean
  training_job_name: string
  predictions_start_time?: string

  predictions_end_time?: string
}

export type TaskId = {
  task_id: string
}

export type UnknownType = {
  name: string
  type?: string
}

type NumericalHistogram = {
  low_value: number
  high_value: number
  sample_count: number
}

type CategoricalHistogram = {
  low_rank: number
  high_rank: number
  sample_count: number
  label: string
}

type DatetimeHistogram = {
  birth_date: string
  count: number
}

export type DiscreteOrContinuousType = {
  name: string
  type?: string
  parent?: string
  num_of_examples?: number
  min: number
  max: number
  mean: number
  median: number
  num_zeros: number
  num_missing: number
  num_non_missing: number
  std_dev: number
  histogram: NumericalHistogram[]
  quantiles?: NumericalHistogram[]
}

export type CategoricalType = {
  name: string
  type?: string
  parent?: string
  num_of_examples?: number
  unique: number
  avg_length: number
  tot_num_values: number
  num_non_missing: number
  rank_histogram: CategoricalHistogram[]
}

export type DatetimeType = {
  name: string
  type?: string
  parent?: string
  num_of_examples?: number
  aggregates: DatetimeHistogram[]
}

export type TaskResult = {
  num_of_examples: number
  statistics: any
  success: boolean
  error: { name: string; args: string[] } | {}
}

export type StatisticsResponse = {
  task_id: string
  task_ready: boolean
  task_result: TaskResult
  task_status: string
}

type AnomalyReason = {
  description: string
  shortDescription: string
  type: string
}

export type AnomalyInfo = {
  feature: string
  reason: AnomalyReason[]
  severity: string
}

type SkewMeasurements = {
  threshold: number
  type: string
  value: number
}

type DriftSkewInfo = {
  feature: string
  skewMeasurements: SkewMeasurements[]
}

type DriftInfo = {
  anomalyInfo: AnomalyInfo[]
  datasetAnomalyInfo: any
  driftSkewInfo: DriftSkewInfo[]
}

type DetectedDrift = {
  detected_drift: DriftInfo
}

export type DriftJobs = {
  uuid: string
  created_at: string | null
  completed_at: string
  model: string
  status: string
  reference_data: string
  result: DetectedDrift
}

export type DriftResponse = {
  count: number
  next: string
  previous: string
  results: DriftJobs[]
}

// Types used internally by Konan to render Drift Jobs
export type AdjustedDriftSkewInfo = {
  error: boolean
  errorReason: AnomalyReason | null
  featureName: string
  driftValue: number | null
  driftThreshold: number | null
}

export type AdjustedDriftJob = {
  uuid: string
  created_at: string | null
  completed_at: string
  model: string
  status: string
  reference_data: string
  result: AdjustedDriftSkewInfo[]
}

export type ModelAverageCpuUsage = {
  model: string
  name?: string
  average: number
  max: number
  realMax: number
  total: number
  created_at: string
  state: string
}

export type DataDriftBarChartData = {
  name: string
  created_at: string
  state: string
  drifted: number
  passed: number
  total: number
  unavailable?: number
}

export type ModelAvgResponseTime = {
  uuid: string
  name: string
  state: "live" | "challenger" | "disabled"
  avg_response_time: number
  created_at?: string
}

export type PredictionsSummary = {
  uuid: string
  name: string
  state: "live" | "challenger" | "disabled"
  total: number
  successful: number
  failed: number
  created_at?: string
}

export type ModelComparison = {
  model_a_uuid: string
  model_b_uuid: string | undefined
  model_a_response_time: number | null | undefined
  model_b_response_time: number | null | undefined
  model_a_status_code: number | null | undefined
  model_b_status_code: number | null | undefined
  created_at: string
  feedback: Record<string, any> | null
  features: string
  model_a_mls_output: Record<string, any> | string | null | undefined
  model_b_mls_output: Record<string, any> | string | null | undefined
}

export type CoverageGraphInput = {
  id: string
  label: string
  count: number
  value: number
}

export type ConfusionMatrixInput = {
  id: string
  data: {
    x: string
    y: number | null
  }[]
}

export type MetricsState = {
  showGraphs: boolean
  title?: string
  description?: string
  action?: string
}

export type TestPrediction = {
  model_uuid: string
  requestJson: any
  skip_config?: boolean
}

export type ClassificationConfiguration = {
  model: string
  type: string
  target_key_path: string[]
  settings?: {
    type: string
    label?: string
    lower_bound: number
    upper_bound: number
  }[]
}

export type SummaryByDayPredictions = {
  timestamp: string
  successful: number
  failed: number
  empty?: number
  alternateSuccessful?: number
  alternateFailed?: number
  modelsSwitched?: CustomSwitchedModels[]
}

export type RequiredSchema = {
  name: string
  type: string
  required: boolean
  description?: string
}

export type WorkflowInputSchema = {
  name: string
  type: string
  required: boolean
  remove: string
  source: string
}

export type TrainModelUsingPrebuiltModelRequest = {
  projectUUID: string
  modelName?: string
  trainingDataUUID?: string
}

export type CustomSwitchedModels = {
  model_name?: string
  after_state?: string
  before_state?: string
  model_uuid?: string
} & ListModelStateSwitch

export type ProjectLimits = {
  drift_threshold: number
  training_timeout_seconds: number
  retraining_timeout_seconds: number
}

export interface CreateScoreCardRequest extends ScoreCardCreateUpdateRequest {
  projectUUID: string
}

export type CreateScorecardSetRequest = {
  name: string
  calculation_method: string
  scorecards: Array<ScoreCardSetScoreCardCreateRequest>
  projectUUID: string
}

export type ScoreRule = {
  id?: number
  feature: string
  operator: string
  value: string
  condition_list_files?: ConditionListFile
  type?: "simple" | "complex"
}

export type baseErrorType = {
  details?: string
  detail?: string
  error?: string
  name?: string
  output?: string
  target_column?: Array<string>
  excluded_columns?: Array<string>
  mapping?: Array<{ type: string }>
}

export type CreateWorkflowRequest = {
  name: string
  state: WorkflowCreateRequest.state
  /**
   * The index in the nodes list of the workflow's root node
   */
  start_node?: number
  /**
   * A list of objects describing the workflow nodes and pointing to each other using their list index (0-based)
   */
  nodes?: Array<NodeCreateRequest>
  projectUUID?: string

  schema?: Array<WorkflowSchemaFeatureRequest>
}

export type UpdateWorkflowRequest = {
  projectUUID: string
  workflowUUID: string
  newState?: string
  name?: string
  start_node?: number
  nodes?: Array<Node>
  schema?: Array<WorkflowSchemaFeatureRequest>
  deprecate_workflows?: boolean
}

export type UpdateScorecardsetRequest = {
  projectUUID: string
  scorecardsetUUID: string
  name: string | undefined
  calculation_method: ScoreCardSetCreateUpdateRequest.calculation_method
  scorecards?: Array<ScoreCardSetScoreCardCreateRequest>
}

export interface UpdateScorecardRequest extends ScoreCardCreateUpdateRequest {
  projectUUID: string
  scorecardUUID: string
}

export type WorkflowResultResponse = {
  created_at: string
  created_by: string
  name: string
  state: "ACTIVE" | "DISABLED"
  uuid: string
  version: number
}

export type WorkflowGroupResponse = {
  count: number
  next: string
  previous: string
  results: WorkflowResultResponse[]
}

export type CreateProgramRequest = {
  projectUUID: string
  name: string
  items: Array<ProgramItemRequest>
}

export type UpdateProgramRequest = {
  projectUUID: string
  programUUID: string
  name?: string
  items: Array<ProgramItemRequest>
}

export type CreateAutoMlRequest = {
  projectUUID: string
  name: string
  mapping: Array<CustomFeatureRequest>
  excluded_columns: Array<ExcludedFeatureRequest>
  target_column: string
  training_data: string
}

export type DeleteModelRequest = {
  model_uuid: string
}

export type ConfigureAutoMlModelRequest = {
  modelUUID: string
  projectUUID: string
  threshold: number
  state?: string
  negative_label: string
  positive_label: string
}

export type WorkflowSimulationJobCreateRequest = {
  projectUUID: string
  predictions_datafile?: string
  predictions_start_time?: string | null
  predictions_end_time?: string | null
  target_column?: string | null
}

export type WorkflowSimulationJobFetchRequest = {
  projectUUID: string
  page?: number
  pageSize?: number
  workflow_name?: string
}

export type WorkflowSimulationOutputFetchRequest = {
  projectUUID: string
  simulation_uuid: string
  page?: number
  pageSize?: number
  filterObject?: object
}

export type InviteUserMutation = {
  uid: string
}

/**
 * Enum representing comparison operators.
 * @enum {string}
 */
export enum Operators {
  "=" = "equal",
  "!=" = "not equal",
  ">" = "greater than",
  ">=" = "greater than/equal",
  "<" = "lower than",
  "<=" = "lower than/equal",
  "between" = "between",
  "not in" = "not in",
  "in" = "in",
  "contains" = "contains",
  "not contains" = "not contains",
  "null" = "is null",
  "not null" = "not null"
}

export interface CustomRule {
  name: string
  readonly created_at: string
  uuid?: string
  return_label: string

  condition: string

  feature_nesting_separator?: string
  readonly condition_list_files?: ConditionListFile | null
}

export interface CustomRulesetRuleCreate extends Omit<RulesetRuleCreate, "condition_list_files"> {
  condition_list_files?: ConditionListFile | null
}

export type uploadDataRequest = {
  project_uuid: string
  file: File
  setProgress?: (progress: number) => void
  signal?: AbortSignal
  type: DataFile.type
}

export type CustomFeatureRequest = {
  name: string
  type: string | null
  target?: boolean
}

export interface HeaderMetaData {
  title: string | React.ReactElement
  description?: string
  icon?: React.ReactElement
  dialog?: React.ReactElement
  actionButtons?: Array<React.ReactElement>
  hasBorder?: boolean
  tag?: React.ReactElement
  createdAt?: JSX.Element | ""
}

export interface Route extends HeaderMetaData {
  [key: string]: any // Wildcard property for dynamic properties (optional)
}

export type VersioningComponents =
  | RuleSetVersionRetrieve
  | TagListVersionRetrieve
  | Program
  | ScoreCardCreateUpdate
  | WorkflowVersionsRetrieve
  | ScoreCardSetRetrieve
  | ScriptVersionRetrieve
