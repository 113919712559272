/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DataFile = {
  readonly uuid: string
  readonly name: string
  readonly created_at: string
  readonly created_by: string
  /**
   * The data file to be uploaded
   */
  data_file: string
  readonly project: string
  /**
   * The type defining the use-case this file will be used in.
   * 1. Generic: A file that won't be used in any special usecase.
   * 2. Condition List: A csv file carrying a list values to be used in a RuleSet rule or a FilterNode condition.
   * 3. Predictions: A csv file carrying prediction features entries. The file is expected to carry feature names in the first row where '.' char is used as a name separator, and then the actualvalues in the rest of the rows. The csv file should be exported with '"' as string delimiter, while in-list strings should be wrapped in two '"'.Also note that list values are expected to be in json format. Kindly check the following examples:
   * Number list: "[1, 2]".
   * Strings list: "[""a"",""b""]".
   * booleans and nulls: "[true, false, null]".
   * 4. Script: A file carrying executable code to be used in a workflow's ScriptNode.
   *
   * * `GENERIC` - Generic
   * * `CONDITION_LIST` - Condition List File
   * * `PREDICTIONS` - Predictions File
   * * `SCRIPT` - Script
   * * `REQUIREMENTS` - Requirements
   */
  type: DataFile.type
  /**
   * List of columns in the data file if the type supports it
   */
  readonly columns: Array<string>
}
export namespace DataFile {
  /**
   * The type defining the use-case this file will be used in.
   * 1. Generic: A file that won't be used in any special usecase.
   * 2. Condition List: A csv file carrying a list values to be used in a RuleSet rule or a FilterNode condition.
   * 3. Predictions: A csv file carrying prediction features entries. The file is expected to carry feature names in the first row where '.' char is used as a name separator, and then the actualvalues in the rest of the rows. The csv file should be exported with '"' as string delimiter, while in-list strings should be wrapped in two '"'.Also note that list values are expected to be in json format. Kindly check the following examples:
   * Number list: "[1, 2]".
   * Strings list: "[""a"",""b""]".
   * booleans and nulls: "[true, false, null]".
   * 4. Script: A file carrying executable code to be used in a workflow's ScriptNode.
   *
   * * `GENERIC` - Generic
   * * `CONDITION_LIST` - Condition List File
   * * `PREDICTIONS` - Predictions File
   * * `SCRIPT` - Script
   * * `REQUIREMENTS` - Requirements
   */
  export enum type {
    GENERIC = "GENERIC",
    CONDITION_LIST = "CONDITION_LIST",
    PREDICTIONS = "PREDICTIONS",
    SCRIPT = "SCRIPT",
    REQUIREMENTS = "REQUIREMENTS",
  }
}
