import React, { useState } from "react"

import * as Yup from "yup"
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Button, DateRangePicker, InputText, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { useFormik } from "formik"
import moment, { Moment } from "moment"

import { KonanTimeHelper } from "../../../../../utils/genericHelpers"

// Yup validation object
const validationSchema = Yup.object({
  name: Yup.string().max(30, "Must be 30 characters or less").required("Required"),
  appendTrainingData: Yup.boolean(),
})

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  onModelCreation: (
    start_time: string,
    end_time: string,
    append_training_data: boolean,
    resulting_model_name: string,
    resulting_model_state: string,
  ) => void
  retrainingError: string | undefined
}

/**
 * Mini evaluation metrics card
 * @param {boolean} open
 * @param {boolean} isLoading
 * @param {Function} onClose
 * @param {Function} onModelCreation
 * @return {React.ReactElement}
 */
export function RetrainingCreationDialog(props: Readonly<Props>): React.ReactElement {
  const { open, onClose, onModelCreation, retrainingError, isLoading } = props

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  const konanTime = new KonanTimeHelper()

  const [startDate, setStartDate] = useState(konanTime.adjustDate(moment().subtract(30, "days"), "start"))
  const [endDate, setEndDate] = useState(konanTime.adjustDate(moment(), "end"))

  // Form control
  const formik = useFormik({
    initialValues: {
      name: "",
      appendTrainingData: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Add Retraining Data
      if (startDate && endDate) {
        const Model: [string, string, boolean, string, string] = [
          startDate.toISOString(),
          endDate.toISOString(),
          values.appendTrainingData,
          values.name,
          "challenger",
        ]
        onModelCreation(...Model)
      }
    },
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          // To enable the date-picker to overflow
          overflow: "unset",
        },
      }}
    >
      <DialogTitle className="dialog-header-base">
        <Typography variant="h2-bold">Retrain Live Model</Typography>
      </DialogTitle>

      <DialogContent className="dialog-content-base">
        {retrainingError && <Snackbar variant="negative" fullWidth description={retrainingError} />}
        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <Typography variant="label">Serving Data</Typography>
          </Grid>

          <Grid item xs={12} className="data-popper-modifier">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={(startDate) => setStartDate(konanTime.adjustDate(startDate, "start") as Moment)}
              onEndDateChange={(endDate) => setEndDate(konanTime.adjustDate(endDate, "end") as Moment)}
              disableFuture
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="label">Append Training Data</Typography>
          </Grid>

          <Grid item xs={12}>
            <Switch
              checked={formik.values.appendTrainingData}
              onChange={formik.handleChange}
              name="appendTrainingData"
              color="primary"
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              id="name"
              label="Name"
              placeholder="Enter Model Name"
              value={formik.values.name}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
              fullWidth
              required
              description={"Retrained model will end up as a challenger model"}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="dialog-actions-base">
        <Button onClick={onClose} variant={"secondary"}>
          Cancel
        </Button>
        <Button
          onClick={formik.submitForm}
          variant="primary"
          disabled={!formik.isValid || !startDate || !endDate || isLoading || !formik.values.name}
        >
          {isLoading ? <CircularProgress color="inherit" size={20} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
