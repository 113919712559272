// TODO:: document all functs in this file with examples and refactor to be more readable and understandable

/**
 * Creates score table buckets of <n>D array from results array.
 *
 * @param formik - The formik object.
 * @returns An array of arrays containing numbers or null.
 */
export function createScoreTableBucketsFromResultsArray(
  results: number[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any[],
): Array<Array<number | null>> {
  let buckets = [...results]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bucketCreatorHelper = (buckets: any[], bucketSize: number): any[] => {
    const newBuckets = []

    while (buckets.length > 0) {
      const splicedBucket = buckets.splice(0, bucketSize)
      splicedBucket !== undefined && newBuckets.push(splicedBucket)
    }

    return newBuckets
  }

  const reversedCriteria = [...criteria].toReversed()

  for (const i of reversedCriteria) {
    buckets = bucketCreatorHelper(buckets, i.length)
  }

  return buckets
}

/**
 * Adds buckets to an array based on the given configuration index.
 *
 * @param buckets - The array of buckets to add to.
 * @param configIndex - The configuration index to determine which buckets to add.
 */

export function AddToBuckets(buckets: Array<Array<number | null>>, configIndex: number): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const BucketAdderHelper = (innerBuckets: any[], configIdx: number): void => {
    if (configIdx === configIndex) {
      for (const bucket of innerBuckets) {
        const stringifiedNewBucket = JSON.stringify(bucket[0])

        // TODO:: use Recursive funct to nullify all values
        const newBucket = stringifiedNewBucket.replace(/[-+]?\d*\.?\d+/g, "null")

        bucket.push(JSON.parse(newBucket))
      }
    } else {
      for (const bucket of innerBuckets) BucketAdderHelper(bucket, configIdx + 1)
    }
  }

  return BucketAdderHelper(buckets, 0)
}

/**
 * Removes a specific criteria from the buckets array based on the given configIndex and criteriaIndex.
 *
 * @param buckets - The array of buckets.
 * @param configIndex - The index of the configuration.
 * @param criteriaIndex - The index of the criteria to be removed.
 */
export function RemoveFromBuckets(
  buckets: Array<Array<number | null>>,
  configIndex: number,
  criteriaIndex: number,
): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const BucketRemoverHelper = (buckets: any[], configIdx: number): void => {
    if (configIdx === configIndex) {
      for (const bucket of buckets) {
        bucket.splice(criteriaIndex, 1)
      }
    } else {
      for (const bucket of buckets) BucketRemoverHelper(bucket, configIdx + 1)
    }
  }

  return BucketRemoverHelper(buckets, 0)
}

export function getIndexRepresentationBasedOnCriteria(
  i: number,
  Criteria: Array<Array<object>>,
  criterion_index: number,
): number {
  let remainder = 1
  let quotient = 1

  for (const C of Criteria.slice(criterion_index)) {
    remainder = remainder * C.length
  }

  for (const C of Criteria.slice(criterion_index + 1)) {
    quotient = quotient * C.length
  }

  return Math.floor((i % remainder) / quotient)
}

/**
 * Calculates the reverse index representation based on the given criteria.
 *
 * @param i - The index value.
 * @param Criteria - The array of arrays representing the criteria.
 * @param criterion_index - The index of the criterion.
 * @returns The reverse index representation.
 */
export function reverseIndexRepresentationBasedOnCriteria(
  i: number,
  Criteria: Array<Array<object>>,
  criterion_index: number,
): number {
  let factor = 1

  for (const C of Criteria.slice(criterion_index + 1)) {
    factor = factor * C.length
  }

  return i * factor
}
