import { AxiosResponse } from "axios"

import { AxiosApi } from "../lib/axios"
import { EkycSessions, EkycStats, EkycStatsByTime } from "../types/custom/ekyc"
import { OrganizationVerification } from "../types/generated/doxter/OrganizationVerification"
import { PaginatedListSessions } from "../types/generated/doxter/PaginatedListSessions"
import { SessionAggregates } from "../types/generated/doxter/SessionAggregates"
import { TimeSeries } from "../types/generated/doxter/TimeSeries"
import { getHostnames } from "../utils/genericHelpers"

class DoxterAPI extends AxiosApi {
  constructor() {
    const { doxter } = getHostnames()

    super(doxter)
  }

  // Verify if org has EKYC subscription
  verifyOrganization = async (orgUUID: string): Promise<AxiosResponse<OrganizationVerification>> => {
    return this.fetchResource(`/user_profile/organization/verify/${orgUUID}/`)
  }

  // fetch overall EKYC user stats (verified, unverified, incomplete, total)
  fetchEkycUserStatsAggregates = async ({
    date_from,
    date_to,
  }: EkycStats): Promise<AxiosResponse<SessionAggregates>> => {
    return this.fetchResource(`/ekyc/session/aggregates/`, {
      date_from,
      date_to,
    })
  }

  // fetch EKYC user stats over time with granularity (day/ week/ month)
  fetchEkycUserStatsWithTime = async ({
    date_from,
    date_to,
    granularity,
  }: EkycStatsByTime): Promise<AxiosResponse<TimeSeries>> => {
    return this.fetchResource(`/ekyc/session/timeseries/`, {
      date_from,
      date_to,
      granularity,
    })
  }

  // fetch all sessions
  EkycSessions = async ({
    date_from,
    date_to,
    page,
    page_size,
  }: EkycSessions): Promise<AxiosResponse<PaginatedListSessions>> => {
    return this.fetchResource("/ekyc/session/list/", {
      date_from,
      date_to,
      page: page,
      page_size: page_size,
    })
  }

  // fetch all sessions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  EkycSessionDetails = async (uid: string): Promise<AxiosResponse<any>> => {
    return this.fetchResource(`/ekyc/session/details/${uid}/`)
  }
}

const api = new DoxterAPI()
export { api as DoxterAPI }
