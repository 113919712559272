import React, { Fragment } from "react"

import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Button, Grid } from "@mui/material"
import { Tag, Typography } from "@synapse-analytics/synapse-ui"
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table"
import moment from "moment"

import { getTheme } from "../../hooks/UseTheme"
import { ListSessions } from "../../types/generated/doxter/ListSessions"
import { CustomTooltip } from "../UI/CustomTooltip"
import { BaseTableContainer } from "./BaseTableContainer"

import styles from "./EKYCSessionsTable.module.scss"

type EKYCSessionsTableProps = {
  data: Array<ListSessions>
  isLoading: boolean
  openDialog: (uid: string) => void
  rowCount: number
  pagination: MRT_PaginationState
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
}

export function EkycSessionsTable(props: EKYCSessionsTableProps): React.ReactElement {
  const { data, isLoading, openDialog, rowCount, pagination, setPagination } = props

  const theme = getTheme()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: MRT_ColumnDef<any>[] = [
    {
      header: "Status",
      accessorKey: "status",
      Cell: ({ row }) => {
        return (
          <Tag
            variant={
              row.original.status === "verified"
                ? "positive"
                : row.original.status === "unverified"
                  ? "negative"
                  : "warning"
            }
            style={{ whiteSpace: "nowrap" }}
          >
            {row.original.status}
          </Tag>
        )
      },
    },
    {
      header: "National ID",
      accessorKey: "id",
      Cell: ({ row }) => {
        return row.original.national_id
      },
    },
    {
      header: "Owner",
      accessorKey: "owner",
      Cell: ({ row }) => {
        return row.original.owner_username
      },
    },
    {
      header: "Document Classifier",
      accessorKey: "document_classifier",
      Cell: ({ row }) => {
        return (
          <Grid item className={styles.tooltip}>
            <CustomTooltip
              title={
                <Fragment>
                  Document classifier
                  <br />
                  <br />
                  Frontside:{" "}
                  {row.original.nid_fs_classifier_completed
                    ? row.original.nid_fs_classifier_verified?.toString()
                    : "incomplete"}
                  <br />
                  Backside:{" "}
                  {row.original.nid_bs_classifier_completed
                    ? row.original.nid_bs_classifier_verified?.toString()
                    : "incomplete"}
                </Fragment>
              }
              placement={"top"}
            >
              {row.original.nid_fs_classifier_verified && row.original.nid_bs_classifier_verified ? (
                <CheckCircleOutlineIcon fontSize="small" style={{ color: theme.palette.green.text[2] }} />
              ) : (row.original.nid_fs_classifier_completed && !row.original.nid_fs_classifier_verified) ||
                (row.original.nid_bs_classifier_completed && !row.original.nid_bs_classifier_verified) ? (
                <CancelIcon fontSize="small" style={{ color: theme.palette.red.text[2] }} />
              ) : (
                <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.grayscale.text[2] }} />
              )}
            </CustomTooltip>
          </Grid>
        )
      },
    },
    {
      header: "National ID Fraud",
      accessorKey: "nid_fraud",
      Cell: ({ row }) => {
        return (
          <Grid item className={styles.tooltip}>
            <CustomTooltip
              title={
                <Fragment>
                  National ID Fraud
                  <br />
                  <br />
                  Frontside:{" "}
                  {row.original.nid_fs_fraud_completed ? row.original.nid_fs_fraud_verified?.toString() : "incomplete"}
                  <br />
                  Backside:{" "}
                  {row.original.nid_bs_fraud_completed ? row.original.nid_bs_fraud_verified?.toString() : "incomplete"}
                </Fragment>
              }
              placement={"top"}
            >
              {row.original.nid_fs_fraud_verified && row.original.nid_bs_fraud_verified ? (
                <CheckCircleOutlineIcon fontSize="small" style={{ color: theme.palette.green.text[2] }} />
              ) : (row.original.nid_fs_fraud_completed && !row.original.nid_fs_fraud_verified) ||
                (row.original.nid_bs_fraud_completed && !row.original.nid_bs_fraud_verified) ? (
                <CancelIcon fontSize="small" style={{ color: theme.palette.red.text[2] }} />
              ) : (
                <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.grayscale.text[2] }} />
              )}
            </CustomTooltip>
          </Grid>
        )
      },
    },
    {
      header: "Face Validation",
      accessorKey: "face_validation",
      Cell: ({ row }) => {
        return (
          <Grid item className={styles.tooltip}>
            <CustomTooltip
              title={
                <Fragment>
                  Face Validation
                  <br />
                  <br />
                  Frontside:{" "}
                  {row.original.nid_fs_classifier_completed
                    ? row.original.nid_fs_classifier_verified?.toString()
                    : "Incomplete"}
                  <br />
                  Backside:{" "}
                  {row.original.nid_bs_classifier_completed
                    ? row.original.nid_bs_classifier_verified?.toString()
                    : "Incomplete"}
                  <br />
                  Liveness:{" "}
                  {row.original.liveness_completed ? row.original.liveness_verified?.toString() : "Incomplete"}
                </Fragment>
              }
              placement={"top"}
            >
              {!row.original.nid_fs_classifier_completed ||
              !row.original.nid_bs_classifier_completed ||
              !row.original.liveness_completed ? (
                <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.grayscale.text[2] }} />
              ) : row.original.nid_fs_classifier_verified &&
                row.original.nid_bs_classifier_verified &&
                row.original.liveness_verified ? (
                <CheckCircleOutlineIcon fontSize="small" style={{ color: theme.palette.green.text[2] }} />
              ) : (
                <CancelIcon fontSize="small" style={{ color: theme.palette.red.text[2] }} />
              )}
            </CustomTooltip>
          </Grid>
        )
      },
    },
    {
      header: "Liveness",
      accessorKey: "liveness",
      Cell: ({ row }) => {
        return (
          <Grid item className={styles.tooltip}>
            <CustomTooltip
              title={
                <Fragment>
                  Liveness
                  <br />
                  <br />
                  Liveness:{" "}
                  {row.original.liveness_completed ? row.original.liveness_verified?.toString() : "Incomplete"}
                </Fragment>
              }
              placement={"top"}
            >
              {!row.original.liveness_completed ? (
                <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.grayscale.text[2] }} />
              ) : row.original.liveness_verified ? (
                <CheckCircleOutlineIcon fontSize="small" style={{ color: theme.palette.green.text[2] }} />
              ) : (
                <CancelIcon fontSize="small" style={{ color: theme.palette.red.text[2] }} />
              )}
            </CustomTooltip>
          </Grid>
        )
      },
    },
    {
      header: "Created at",
      accessorKey: "created_at",
      Cell: ({ row }) => {
        return (
          <Grid container item xs={12} justifyContent="center" alignItems="center">
            <Typography variant={"h3-regular"} style={{ width: "max-content" }}>
              {moment(row.original.created_at).format("MMM DD YYYY, H:mm")}
            </Typography>
          </Grid>
        )
      },
    },
    {
      header: "Details",
      accessorKey: "details",
      Cell: ({ row }) => {
        return (
          <Button
            variant="text"
            onClick={() => {
              openDialog(row.original.uid)
            }}
          >
            <OpenInNewIcon fontSize="small" style={{ paddingRight: "8px", color: theme.palette.blue.text[2] }} />
            <Typography variant="a" color="important" variantColor={2}>
              View
            </Typography>
          </Button>
        )
      },
    },
  ]

  return (
    <BaseTableContainer
      title="Sessions Details"
      source="ekyc-session"
      isLoading={isLoading}
      data={data ?? []}
      columns={columns}
      pagination={pagination}
      setPagination={setPagination}
      rowCount={rowCount}
      enableGlobalFilter={false}
      enableHiding={false}
      enableFilters={false}
    />
  )
}
