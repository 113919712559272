/* generated using openapi-typescript-codegen -- do not edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { ScoreCardRuleCreateRequest } from "./ScoreCardRuleCreateRequest"
import type { TableFeatureCriterionRequest } from "./TableFeatureCriterionRequest"

export type ScoreCardCreateUpdateRequest = {
  name: string
  rules?: Array<ScoreCardRuleCreateRequest>
  /**
   * * `LIST` - LIST
   * * `TABLE` - TABLE
   */
  format?: ScoreCardCreateUpdateRequest.format
  results?: Array<number> | null
  criteria?: Array<TableFeatureCriterionRequest> | null
  features?: Array<string> | null
}
export namespace ScoreCardCreateUpdateRequest {
  /**
   * * `LIST` - LIST
   * * `TABLE` - TABLE
   */
  export enum format {
    LIST = "LIST",
    TABLE = "TABLE",
  }
}
