/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type WorkflowSchemaFeatureRequest = {
  name: string
  /**
   * * `TEXT` - Text
   * * `NUMBER` - Number
   * * `BOOLEAN` - Boolean
   * * `DATE` - Date
   * * `LIST` - List
   * * `UNDEFINED` - Undefined
   */
  type?: WorkflowSchemaFeatureRequest.type
  is_required?: boolean
  feature_nesting_separator?: string
}
export namespace WorkflowSchemaFeatureRequest {
  /**
   * * `TEXT` - Text
   * * `NUMBER` - Number
   * * `BOOLEAN` - Boolean
   * * `DATE` - Date
   * * `LIST` - List
   * * `UNDEFINED` - Undefined
   */
  export enum type {
    TEXT = "TEXT",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    LIST = "LIST",
    UNDEFINED = "UNDEFINED",
  }
}
