import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"

import { useLocation, useMatch, useNavigate } from "react-router-dom"

import moment from "moment"
import queryString from "query-string"

import { KonanTimeHelper } from "../utils/genericHelpers"

/**
 * calculates start and end dates based on query params
 * & updates the params according to changes in the page
 * NOTE: all expected date formats are ISO date formats
 * @returns [startDate, setStartDate, endDate, setEndDate]
 */
export function useDateQuery(
  defaultStartDateDays?: number,
  supportedRoutes?: Array<string>,
): [
  moment.Moment | null,
  Dispatch<SetStateAction<moment.Moment | null>>,
  moment.Moment | null,
  Dispatch<SetStateAction<moment.Moment | null>>,
] {
  const navigate = useNavigate()
  const location = useLocation()
  const match = useMatch("/projects/:id/*")

  // fetches query search params from URL
  const queryParams = useMemo(() => queryString.parse(location.search), [location.search])
  const konanTime = new KonanTimeHelper()

  // date takes the value of the query params,
  // if not found it defaults to:
  // start date: 30 days earlier
  // end date: today
  const [startDate, setStartDate] = useState<moment.Moment | null>(
    queryParams.startDate && typeof queryParams.startDate === "string"
      ? moment(queryParams.startDate)
      : moment().subtract(defaultStartDateDays ?? 29, "days"),
  )

  const [endDate, setEndDate] = useState<moment.Moment | null>(
    queryParams.endDate && typeof queryParams.endDate === "string" ? moment(queryParams.endDate) : moment(),
  )

  // Merge existing query parameters with the new ones
  const mergedQueryParams = useMemo(() => {
    return { ...queryParams, startDate: startDate?.toISOString(), endDate: endDate?.toISOString() }
  }, [endDate, queryParams, startDate])

  // reset date values if we opted-out from a project
  useEffect(() => {
    if (!match?.params.id && !!location.pathname) {
      setStartDate(moment().subtract(defaultStartDateDays ?? 29, "days"))
      setEndDate(moment())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  // Modify the URL when the dates or page value are changed
  useEffect(() => {
    const dateQuery = queryString.stringify(mergedQueryParams)
    if (!supportedRoutes || supportedRoutes.some((route) => location.pathname.includes(route))) {
      // navigate only if no supported routes given, or if current pathname included in the supported routes
      // for dates
      navigate(
        {
          pathname: location.pathname,
          search: dateQuery,
        },
        { replace: true },
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, mergedQueryParams])

  return [konanTime.adjustDate(startDate, "start"), setStartDate, konanTime.adjustDate(endDate, "end"), setEndDate]
}
