import React from "react"

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import { Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"
import { MRT_ColumnDef } from "material-react-table"

import { ScriptSchemaFeature } from "../../types/generated/api/ScriptSchemaFeature"
import { BaseTable } from "./BaseTable"

import styles from "../../features/Scripts/Scripts.module.scss"

type Props = {
  featuresList: Array<ScriptSchemaFeature>
}

/**
 * Table view to display all current template features (input and output/expected)
 * @param {Array<ScriptSchemaFeature>} featuresList list of retrieved features (input and output)
 * @returns {React.ReactElement}
 */
export function TemplateFeaturesTable(props: Props): React.ReactElement {
  const { featuresList } = props

  // columns for template features (input and output) table
  const columns: MRT_ColumnDef<ScriptSchemaFeature>[] = [
    {
      header: "Feature Name",
      accessorKey: "name",
      Cell: ({ row }) => <Typography variant="p">{featuresList[row.index]?.name}</Typography>,
    },
    {
      header: "Type",
      accessorKey: "type",
      Cell: ({ row }) => (
        <Typography style={{ textTransform: "capitalize" }} variant="p">
          {featuresList[row.index]?.type?.toLowerCase()}
        </Typography>
      ),
    },
    {
      header: "Required",
      accessorKey: "required",
      Cell: ({ row }) => {
        return (
          <Grid item display="flex" alignItems="center">
            {featuresList[row.index]?.is_required ? (
              <CheckOutlinedIcon className={styles.iconColor} fontSize="inherit" />
            ) : (
              <Typography variant="p">__</Typography>
            )}
          </Grid>
        )
      },
    },
  ]

  return (
    <BaseTable
      data={featuresList?.length > 0 ? featuresList : []}
      columns={columns as MRT_ColumnDef[]}
      disableToolbar
      title="Template Schema"
      pageSize={10}
      enableOrdering={false}
      isLoading={false}
      stripped
    />
  )
}
