import React, { useEffect } from "react"

import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Box, Grid } from "@mui/material"
import { Snackbar } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { OpenAPIV3 } from "openapi-types"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

import { LoadingContainer } from "../../../components/LoadingContainer"
import { KonanAPI } from "../../../services/KonanAPI"
import { SidAPI } from "../../../services/SidAPI"
import { CustomTokenRefresh } from "../../../types/generated/authentication/CustomTokenRefresh"
import { Auth } from "../../../utils/auth"

import styles from "../Integration.module.scss"

interface Request {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any
}

type ParamsType = {
  id: string
}

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setApiSpec: (spec: any) => void
}

/**
 * Component responsible for rendering Swagger UI
 * @param {void} setApiSpec state setter for the API spec
 * @returns {React.ReactElement}
 */
export function APIIntegration(props: Readonly<Props>): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()

  const { setApiSpec } = props

  // Fetch project docs using project ID in route
  const { isLoading: isLoadingOpenApiData, data: response } = useQuery<AxiosResponse<OpenAPIV3.Document>, AxiosError>(
    ["project-docs", projectId],
    () => KonanAPI.fetchProjectDocs(projectId as string),
    {
      retry: false,
    },
  )

  const refreshTokenMutation = useMutation<AxiosResponse<CustomTokenRefresh>, AxiosError, string>(SidAPI.refreshToken)

  // injecting request headers and to pass it later to swagger UI
  const injectRequestHeaders = async (req: Request): Promise<Request> => {
    if (!Auth.checkIfAccessTokenIsValid()) {
      const refreshResponse = await refreshTokenMutation.mutateAsync(Auth.getRefreshToken() as string)
      await Auth.login(refreshResponse.data.access, Auth.getRefreshToken())
    }
    req.headers.Authorization = Auth.getAuthHeaderValue()
    return req
  }

  // update the Api spec state if there's a returned data
  useEffect(() => {
    if (response?.data) {
      setApiSpec(response?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response?.data])

  return (
    <React.Fragment>
      {isLoadingOpenApiData ? (
        <LoadingContainer />
      ) : (
        <React.Fragment>
          <Grid item xs={12}>
            <Snackbar variant="important" fullWidth id="snackbar-auth-restApi">
              When trying out the Swagger document through <strong>Konan's portal</strong>, authentication is
              <strong> not required</strong>.
              <br />
              However, if you download the Swagger documentation and attempt to try it out on your machine, you'll
              <strong> have to</strong> authenticate yourself (by using the login endpoint) before sending any requests.
            </Snackbar>
          </Grid>
          <Box mt={2} />
          <Grid container className={styles.swaggerContainer}>
            <Grid item xs={12}>
              <SwaggerUI spec={response?.data} requestInterceptor={injectRequestHeaders} />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
