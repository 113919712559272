import { useEffect, useState } from "react"

import { useQuery } from "react-query"
import { useParams, useSearchParams } from "react-router-dom"

import { Grid } from "@mui/material"
import { AxiosError, AxiosResponse } from "axios"
import LZString from "lz-string"
import { MRT_PaginationState } from "material-react-table"

import { ProjectDecisionsTable } from "../../components/tables/ProjectDecisions"
import { KonanAPI } from "../../services/KonanAPI"
import { PaginatedListPredictionsList } from "../../types/generated/api/PaginatedListPredictionsList"

type ParamsType = {
  id: string
}

export function Decisions(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()
  const [searchParams, setSearchParams] = useSearchParams()

  const startDate = searchParams.get("startDate")
  const endDate = searchParams.get("endDate")

  // state to create a ComponentIsMounting effect in functional react
  const [isMounted, setIsMounted] = useState<boolean>(false)

  const [filterObject, setFilterObject] = useState<object>({})
  const [reviewLabel, setReviewLabel] = useState<"REVIEWED" | "REVOKED" | undefined>(undefined)

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  // fetch model features (model predictions table data)
  const {
    isLoading: isProjectPredictionsLoading,
    isFetching: isProjectPredictionsFetching,
    data: projectPredictions,
  } = useQuery<AxiosResponse<PaginatedListPredictionsList>, AxiosError>(
    ["project-predictions", startDate, endDate, pagination, filterObject, reviewLabel],
    () =>
      KonanAPI.fetchProjectPredictions(
        startDate as string,
        endDate as string,
        projectId as string,
        pagination.pageIndex + 1,
        pagination.pageSize,
        filterObject,
        reviewLabel,
      ),
    {
      keepPreviousData: true,
      enabled: !["", null, undefined].includes(projectId) && !!startDate && !!endDate && isMounted,
    },
  )

  // Reset pagination when date range changes
  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }, [startDate, endDate])

  // onFiltersChange: set filters to query-params (encoded)
  useEffect(() => {
    if (isMounted) {
      const params: { [key: string]: string } = {}

      for (const [key, value] of searchParams.entries()) {
        params[key] = value
      }

      const stringifiedParams = JSON.stringify(filterObject)
      const compressed_object = LZString.compressToEncodedURIComponent(stringifiedParams)

      if (stringifiedParams !== "{}") {
        params["decision_filters"] = compressed_object
      } else {
        delete params["decision_filters"]
      }

      if (reviewLabel) params["reviewed"] = reviewLabel
      else delete params["reviewed"]

      params["page"] = pagination.pageIndex.toString()
      params["pageSize"] = pagination.pageSize.toString()

      setSearchParams(params)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject, reviewLabel, pagination])

  // onMount: set filters present in the url to filter based on query params
  useEffect(() => {
    const decisionFilters = searchParams.get("decision_filters") as string
    const reviewed = searchParams.get("reviewed") as "REVIEWED" | "REVOKED" | undefined
    const page = parseInt(searchParams.get("page") ?? "0") ?? 0
    const size = parseInt(searchParams.get("pageSize") ?? "10") ?? 10

    decisionFilters && setFilterObject(JSON.parse(LZString.decompressFromEncodedURIComponent(decisionFilters)))
    reviewed && setReviewLabel(reviewed)
    setPagination({ pageIndex: page, pageSize: size })

    !isMounted && setIsMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid item xs={12}>
      <ProjectDecisionsTable
        data={projectPredictions?.data ?? undefined}
        isFetching={isProjectPredictionsFetching}
        isLoading={isProjectPredictionsLoading}
        pagination={pagination}
        setPagination={setPagination}
        filterObject={filterObject}
        setFilterObject={setFilterObject}
        reviewLabel={reviewLabel}
        setReviewLabel={setReviewLabel}
      />
    </Grid>
  )
}
