/* eslint-disable no-multi-str */
export const loginDocStrings = "Use this snippet to login to Konan to be able to use the other operations."

export const loginCodeSnippet =
  '\
from konan_sdk.sdk import KonanSDK\n\n\
\
# Initialize the SDK. Set verbose to True if you want verbose logging.\n\
konan_sdk = KonanSDK(verbose=False)\n\n\
\
# Login using your valid konan credentials\n\
konan_sdk.login(email="<email>", password="<password>")\
'
export const nodePrequiste = "Node v14.0"
export const csharp = "c# 8.0"
export const javaPrequiste = "JVM 17.0"
export const installtionNpm = "npm i konan-sdk"
export const loginCodeSnippetJava =
  '\
from konan_sdk.sdk import KonanSDK\n\n\
\
# Initialize the SDK. Set verbose to True if you want verbose logging.\n\
konan_sdk = KonanSDK(verbose=False)\n\n\
\
# Login using your valid konan credentials\n\
konan_sdk.login(email="<email>", password="<password>")\
'

export const refreshDocsStrings =
  "The access token acquried from the login endpoint has an expire date. To request another access token, you'll need to use the refresh endpoint."

export const predictDocStrings =
  "Use this snippet to use the ML logic in your model to make a new prediction.\n\
The output for each prediction includes the Prediction UUID and ML Output from your model."

export const predictCodeSnippet =
  "\
from konan_sdk.sdk import KonanSDK\n\n\
\
# Initialize the SDK. Set verbose to True if you want verbose logging.\n\
konan_sdk = KonanSDK(verbose=False)\n\n\
\
# Login using your valid konan credentials\n\
konan_sdk.login(email=\"<email>\", password=\"<password>\")\n\n\
\
PROJECT_UUID = \"<project-uuid>\"\n\n\
\
prediction_uuid, ml_output = konan_sdk.predict(\n\
    PROJECT_UUID,\n\
    input_data={\n\
        '<feature_1>': '<value_1>',\n\
        '<feature_2>': '<value_2>',\n\
        '<feature_3>': '<value_3>',\n\
        '<feature_4>': '<value_4>',\n\
    }\n\
)\n\n\
\
print(f\"Prediction with UUID {prediction_uuid} created and with output {ml_output}\")\n\n\
\
# Alternative printing with better formatting\n\
# print(f\"Prediction with UUID {prediction_uuid} created and with output: \")\n\
# print(f\"\t<output_1>: {ml_output['<output_1>']}\")\n\
# print(f\"\t<output_2>: {ml_output['<output_2>']}\")"

export const feedbackDocStrings =
  "\
Use this snippet to provide Feedback for any of your past Predictions.\n\
When providing Feedback for a specific Prediction.\
 Use the Prediction UUID and the correct, ground truth value that ideally your ML Model should have produced.\n\
"
export const feedbackCodeSnippet =
  "\
from konan_sdk.konan_types import KonanFeedbackSubmission\n\
from konan_sdk.sdk import KonanSDK\n\n\
\
# Initialize the SDK. Set verbose to True if you want verbose logging.\n\
konan_sdk = KonanSDK(verbose=False)\n\n\
\
# Login using your valid konan credentials\n\
konan_sdk.login(email=\"<email>\", password=\"<password>\")\n\n\
\
PROJECT_UUID = \"<project-uuid>\"\n\n\
\
feedbacks_result = konan_sdk.feedback(\n\
    PROJECT_UUID,\n\
    feedbacks=[\n\
        KonanFeedbackSubmission(\n\
            prediction_uuid=\"<prediction-1-uuid>\",\n\
            target={\n\
                '<output_1>': '<value_1>',\n\
                '<output_2>': '<value_2>',\n\
            },\n\
        ),\n\
        # KonanFeedbackSubmission(\n\
        #     prediction_uuid=\"<prediction-2-uuid>\",\n\
        #     target={\n\
        #         '<output_1>': '<value_1>',\n\
        #         '<output_2>': '<value_2>',\n\
        #     },\n\
        # ),\n\
    ],\n\
)\n\n\
\
print(f\"{feedback_result.total_count} feedbacks registered, of which {feedback_result.success_count} were successful and {feedback_result.failure_count} were failed\")\n\
for feedback in feedback_result.feedbacks_status:\n\
    print(f\"Feedback for prediction with UUID {feedback.prediction_uuid} has status {feedback.status}\")"

export const evaluateDocStrings =
  "\
Use this snippet to evaluate your Project given past Predictions and their Feedback.\n\
When evaluating your Project, Konan will use the valid Predictions between the specified start_time and end_time.\
 The endpoint will then return a list of Metrics as produced by your ML Model.\n\
"

export const evaluateCodeSnippet =
  '\
from datetime import datetime, timedelta\n\
import pytz\n\n\
\
from konan_sdk.sdk import KonanSDK\n\n\
\
# Initialize the SDK. Set verbose to True if you want verbose logging.\n\
konan_sdk = KonanSDK(verbose=False)\n\n\
\
# Login using your valid konan credentials\n\
konan_sdk.login(email="<email>", password="<password>")\n\n\
\
PROJECT_UUID = "<project-uuid>"\n\n\
\
metrics = konan_sdk.evaluate(\n\
    PROJECT_UUID,\n\
    start_time=datetime.now(tz=pytz.utc) - timedelta(days=7),\n\
    end_time=datetime.now(tz=pytz.utc),\n\
)\n\n\
\
print(f"Evaluating the project with UUID {PROJECT_UUID} gave the following metrics:")\n\
for metric in metrics:\n\
    print(f"{metric.name}: {metric.value}")'
