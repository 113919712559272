import React, { Dispatch, Fragment, SetStateAction, useContext, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import * as Yup from "yup"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Skeleton, SvgIcon } from "@mui/material"
import {
  Button,
  InputText,
  Menu,
  MenuItem,
  NotificationUtils,
  Tooltip,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import { FormikProvider, useFormik } from "formik"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { KonanAvatar } from "../../components/Avatar"
import { KonanEmptyState } from "../../components/KonanEmptyState"
import { VersionTag } from "../../components/VersionTag"
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { VersioningDialog } from "../../components/dialogs/VersioningDialog"
import { getTheme } from "../../hooks/UseTheme"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { RootState } from "../../store/ReduxStore"
import { CreateWorkflowRequest, Operators } from "../../types/custom/projects"
import {
  CreateTaglistRequest,
  CustomTag,
  CustomTagRequest,
  MapValueTypeToFeatureType,
  Rule,
  UpdateTaglistRequest,
  VersionChangeRequest,
} from "../../types/custom/rules"
import { SchemaFeature } from "../../types/custom/workflows"
import { TagListGroupRetrieve } from "../../types/generated/api/TagListGroupRetrieve"
import { TagListVersionRetrieve } from "../../types/generated/api/TagListVersionRetrieve"
import { WorkflowCreateRequest } from "../../types/generated/api/WorkflowCreateRequest"
import { WorkflowSchemaFeatureRequest } from "../../types/generated/api/WorkflowSchemaFeatureRequest"
import { isPermitted } from "../../utils/PermissionsHelpers"
import { Auth } from "../../utils/auth"
import { getValidMaxMinorVersion } from "../../utils/deploymentDetailsHelpers"
import {
  compareTwoRulesList,
  convertComplexConditionsToNestedForm,
  convertComplexFormToCondition,
} from "../../utils/rulesetHelpers"
import { TagCard } from "./TagCard"
import { TagGroupConditions } from "./inteface"

import styles from "../Ruleset/Ruleset.module.scss"

const levelSchema = Yup.object().shape({
  feature: Yup.string()
    .required("Feature is required")
    .matches(/^[a-zA-Z0-9._-]+$/, {
      message: "This field cannot contain white spaces or special character except: (.-_)",
    }),
  andOr: Yup.string().required(),
  operator: Yup.string().required("Operator is required"),
  type: Yup.string().required("Type is required"),
  value: Yup.mixed().when(["valueOrFeature", "type", "operator"], {
    is: (valueOrFeature: string, type: string, operator: string) => {
      return (
        valueOrFeature !== "Feature" &&
        ["true", "false", "null"].includes(type) &&
        ["greater than", "greater than/equal", "lower than", "lower than/equal", "between"].includes(operator)
      )
    },
    then: Yup.number().typeError("Value must be a number").required("Value is required"),
    otherwise: Yup.mixed(),
  }),
  secondValue: Yup.string().when(["operator"], (operator) => {
    return operator === "between"
      ? Yup.number().typeError("Value must be a number").required("Value is required")
      : Yup.string()
  }),
})

// Yup validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("Taglist name is required"),
  cards: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Tag name is required"),
      levelOneConditions: Yup.array().of(levelSchema),
      group1Switch: Yup.string().required(),
      group2Switch: Yup.string().required(),
      levelTwoConditions: Yup.array().of(levelSchema),
      levelThreeConditions: Yup.array().of(levelSchema),
    }),
  ),
})

type Props = {
  uuid: string
  createdBy: string
  createdAt?: string
  closeCreateCard?: () => void
  isDuplicate?: boolean
  duplicateTaglist?: (uuid: string) => void
  disableDuplicateButton?: boolean
}

/**
 * Taglist card component
 * @param {string} uuid
 * @param {string} createdBy email of creator
 * @param {string} createdAt  date of creation
 * @param {function} closeCreateCard
 * @param {boolean} isDuplicate is duplicate
 * @param {function} duplicateTaglist duplicate taglist
 * @param {boolean} disableDuplicateButton disable duplicate
 * @return {React.ReactElement}
 */
export function TaglistCard(props: Readonly<Props>): React.ReactElement {
  const { uuid, createdBy, createdAt, closeCreateCard, duplicateTaglist, isDuplicate, disableDuplicateButton } = props
  const { id: projectId } = useParams<{ id: string }>()

  const permissions = Auth.getPermissions()

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const workflowsList = useSelector((state: RootState) => state?.workflow?.workflows)
  const schemaFeatures = useSelector((state: RootState) => state?.project?.schemaFeatures)
  const computedSchemaFeatures = useSelector((state: RootState) => state?.project?.computedFeatures)

  const theme = getTheme()
  const queryClient = useQueryClient()

  const isCreateMode = uuid === "new" || !!isDuplicate

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchor)

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false)

  // saving a snap shot of the tags on edit click so if we cancel after some edit we get this snap shot back
  const [cardsSnapShot, setCardsSnapShot] = useState<Array<TagGroupConditions>>([])

  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)

  // fetch single taglist
  const { isLoading: isTaglistLoading, data: taglist } = useQuery<AxiosResponse<TagListGroupRetrieve>, AxiosError>(
    ["taglist", currentProject, uuid],
    () => KonanAPI.fetchTaglist(currentProject.uuid as string, uuid),
    {
      onSuccess: async ({ data }) => {
        const activeVersion = data?.versions?.find(
          (version: TagListVersionRetrieve) => version?.is_active_version === true,
        )

        await formik.setValues({
          uuid: data.uuid,
          name: `${data.name}${isDuplicate ? " - Copy" : ""}`,
          cards: activeVersion?.rules?.map((rule) => {
            const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)

            return {
              id: uuidv4(),
              name: rule?.name,
              group1Switch: "and",
              group2Switch: "and",
              levelOneConditions: convertedRule && convertedRule.length > 0 ? convertedRule[0] : [emptyCondition],
              levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
              levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
              condition_list_files: [...rule.condition_list_files],
            }
          }),
        })
      },
      enabled: !!currentProject && uuid !== "new",
    },
  )

  // create workflow mutation
  const createWorkflowMutation = useMutation<AxiosResponse, AxiosError, CreateWorkflowRequest>(
    KonanAPI.createWorkflow,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["workflows", currentProject?.uuid])
        await queryClient.invalidateQueries(["project", currentProject?.uuid])
      },
    },
  )

  // create taglist
  const createTaglistMutation = useMutation<AxiosResponse, AxiosError, CreateTaglistRequest>(KonanAPI.createTaglist, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("taglists")
      await queryClient.invalidateQueries("taglist")
      NotificationUtils.toast("Taglist successfully created!", {
        snackBarVariant: "positive",
      })
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(error: any) {
      if (error?.response?.data?.name) {
        NotificationUtils.toast(error?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.condition) {
        NotificationUtils.toast(error?.response?.data?.condition[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.details) {
        NotificationUtils.toast(error?.response?.data?.details, {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while creating your Taglist.", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // ruleset deletion Mutation
  const deleteTaglistMutation = useMutation<
    AxiosResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any>,
    { project_uuid: string; resource_uuid: string }
  >((props) => KonanAPI.deleteWorkflowResource({ ...props, resource_type: "taglists" }), {
    onSuccess: async () => {
      NotificationUtils.toast("Taglist deleted successfully", { snackBarVariant: "positive" })

      await queryClient.invalidateQueries("taglists")
      await queryClient.invalidateQueries(["taglist", currentProject, uuid])
      await queryClient.invalidateQueries("taglist")
    },
    onError: ({ response }) => {
      NotificationUtils.toast(response?.data?.details ?? "Taglist deletion failed", { snackBarVariant: "negative" })
    },
  })

  // change taglist version
  const taglistVersionChangeMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("taglists")
        await queryClient.invalidateQueries(["taglist", currentProject, uuid])
        await queryClient.invalidateQueries("taglist")
      },
    },
  )

  // update taglist
  const updateTaglistMutation = useMutation<AxiosResponse, AxiosError, UpdateTaglistRequest>(KonanAPI.updateTaglist, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("taglists")
      await queryClient.invalidateQueries("taglist")
      setIsEditMode(false)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(error: any) {
      if (error?.response?.data?.name) {
        NotificationUtils.toast(error?.response?.data?.name[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.condition) {
        NotificationUtils.toast(error?.response?.data?.condition[0], {
          snackBarVariant: "negative",
        })
      } else if (error?.response?.data?.details) {
        NotificationUtils.toast(error?.response?.data?.details, {
          snackBarVariant: "negative",
        })
      } else {
        NotificationUtils.toast("An error occurred while updating your taglist.", {
          snackBarVariant: "negative",
        })
      }
    },
  })

  // Memoize active version to render it in the card
  const activeVersion = useMemo(() => {
    if (taglist) {
      for (const version of taglist.data.versions) {
        if (version.is_active_version) {
          return version
        }
      }
    }
  }, [taglist])

  const emptyCondition: Rule = {
    id: uuidv4(),
    feature: "",
    andOr: "and",
    operator: Operators["="],
    type: "string",
    value: "",
    secondValue: "",
    valueOrFeature: "Value",
    schemaFeatureType: WorkflowSchemaFeatureRequest.type.TEXT,
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      cards: [
        {
          id: `new-${uuidv4()}`,
          name: "",
          group1Switch: "and",
          group2Switch: "and",
          levelOneConditions: [emptyCondition] as Rule[],
          levelTwoConditions: [] as Rule[],
          levelThreeConditions: [] as Rule[],
          condition_list_files: [],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async ({ name, cards }) => {
      const finalTaglistFormat: CustomTagRequest[] = []
      const featuresArray: Array<SchemaFeature> = []
      const trimmedName = name?.trim()

      for (const tagCard of cards) {
        const trimmedTagCardName = tagCard?.name?.trim()

        try {
          const multiValuesOperators = [
            Operators["in"],
            Operators["not in"],
            Operators["contains"],
            Operators["not contains"],
          ]

          if (tagCard.levelOneConditions && tagCard.levelOneConditions.length > 0) {
            tagCard.levelOneConditions.forEach((item: Rule) => {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })

              item.andOr = tagCard.group1Switch
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          if (tagCard.levelTwoConditions && tagCard.levelTwoConditions.length > 0) {
            tagCard.levelTwoConditions.forEach((item: Rule) => {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })
              item.andOr = tagCard.group2Switch
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          if (tagCard.levelThreeConditions && tagCard.levelThreeConditions.length > 1) {
            const ruleLevelThree = tagCard.levelThreeConditions[1]
            const switchValue = ruleLevelThree.andOr
            tagCard.levelThreeConditions.forEach((item: Rule) => {
              featuresArray.push({
                name: item?.feature,
                is_required: true,
                source: "workflow",
                type: MapValueTypeToFeatureType[item?.type as keyof typeof MapValueTypeToFeatureType],
              })
              item.andOr = switchValue
              if (multiValuesOperators?.includes(Operators[item.operator as keyof typeof Operators])) {
                item.type = item.operator
              }
            })
          }

          const newRule = {
            name: trimmedTagCardName,
            condition: convertComplexFormToCondition(
              tagCard.levelOneConditions,
              tagCard.levelTwoConditions,
              tagCard.levelThreeConditions,
            ),
          }

          finalTaglistFormat.push(newRule)
        } catch (e) {
          console.warn(e)
        }
      }
      if (workflowsList?.length === 0 && featuresArray?.length > 0) {
        await createWorkflowMutation.mutateAsync({
          name: "Untitled-1",
          state: WorkflowCreateRequest.state.DISABLED,
          projectUUID: currentProject?.uuid as string,
          schema: [...featuresArray],
        })
      }
      if (isCreateMode) {
        // Create a new taglist
        await createTaglistMutation.mutateAsync({
          projectUUID: currentProject?.uuid as string,
          name: trimmedName,
          rules: [...finalTaglistFormat],
        })
        closeCreateCard?.()
      } else {
        if (activeVersion?.rules && compareTwoRulesList(finalTaglistFormat, activeVersion?.rules)) {
          await updateTaglistMutation.mutateAsync({
            projectUUID: currentProject.uuid,
            taglistUUID: taglist?.data.uuid as string,
            name: trimmedName === taglist?.data?.name ? undefined : trimmedName,
          })
        } else {
          await updateTaglistMutation.mutateAsync({
            projectUUID: currentProject.uuid,
            taglistUUID: taglist?.data.uuid as string,
            name: trimmedName === taglist?.data?.name ? undefined : trimmedName,
            rules: [...(finalTaglistFormat ?? [])],
          })
        }

        setIsEditMode(false)
        NotificationUtils.toast("Taglist successfully updated!", {
          snackBarVariant: "positive",
        })

        await queryClient.invalidateQueries("taglists")
        await queryClient.invalidateQueries("taglist")
      }
    },
  })

  const deleteRule = async (name: string, closeDialogState: Dispatch<SetStateAction<boolean>>): Promise<void> => {
    const clonedArray = activeVersion?.rules

    try {
      const finalFormattedRules = clonedArray?.map((rule: CustomTag) => {
        return { ...rule }
      })
      await updateTaglistMutation.mutateAsync({
        projectUUID: currentProject.uuid,
        uuid: taglist?.data?.uuid ?? "",
        name: undefined,
        rules: [...(finalFormattedRules?.filter((rule: CustomTag) => rule.name !== name) ?? [])],
      })

      // Invalidate react-query queries
      await queryClient.invalidateQueries("taglist")
      await queryClient.invalidateQueries(["taglists", currentProject])
      await queryClient.invalidateQueries(["taglist", currentProject, uuid])
      closeDialogState(false)

      NotificationUtils.toast("Tag successfully deleted!", {
        snackBarVariant: "positive",
      })
    } catch (e) {
      console.warn(e)
    }
  }

  const handleCancelTaglist = (): void => {
    formik.setFieldValue("cards", cardsSnapShot)
    setIsEditMode(false)
    closeCreateCard?.()
    formik.setFieldValue("name", taglist?.data.name)
    formik.setErrors({})
  }

  const handleRemoveRule = (ruleIndex: number): boolean => {
    if (isCreateMode || isEditMode) {
      formik.setFieldValue(
        "cards",
        formik.values.cards.filter((_, index) => index !== ruleIndex),
      )
      formik.setFieldError(`cards[${ruleIndex}]`, "")
      return false
    } else {
      return true
    }
  }

  // helper function for the versioning dialog
  // returns data of the version selected
  const GetTaglistContainer = (): React.ReactElement => {
    const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

    const Version = useMemo((): TagListVersionRetrieve | undefined => {
      if (taglist && selectedVersion) {
        if (!cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(taglist.data.versions, selectedVersion)
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }

        for (const version of taglist.data.versions) {
          if (version.version === selectedVersion) {
            return version
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion, taglist])

    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeVersion?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion as string] === (selectedVersion ?? activeVersion?.version) &&
      cachedResults[majorSelectedVersion as string] !== activeVersion?.version

    const versionFormik = useFormik({
      initialValues: {
        name: formik.values.name,
        cards: Version?.rules?.map((rule) => {
          const convertedRule = convertComplexConditionsToNestedForm(rule?.condition)

          return {
            id: uuidv4(),
            name: rule?.name,
            group1Switch: "and",
            group2Switch: "and",
            levelOneConditions: convertedRule && convertedRule.length > 0 ? convertedRule[0] : [emptyCondition],
            levelTwoConditions: convertedRule && convertedRule.length > 1 ? convertedRule[1] : [],
            levelThreeConditions: convertedRule && convertedRule.length > 2 ? convertedRule[2] : [],
            condition_list_files: [...rule.condition_list_files],
          }
        }),
      },
      onSubmit: () => {
        return
      },
    })

    return (
      <Grid container>
        {/* Header */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item gap={1} xs={9} display="flex">
            <Typography variant="h2-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
              {taglist?.data.name}
            </Typography>

            <VersionTag
              version={Version?.version ?? selectedVersion}
              variant={Version ? (activeVersion?.version === selectedVersion ? "positive" : "default") : "positive"}
            />
          </Grid>

          {shouldShowRestoreButton && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => changeTaglistVersion(selectedVersion as string)}
              disabled={taglistVersionChangeMutation.isLoading}
            >
              {taglistVersionChangeMutation.isLoading ? <CircularProgress size={12} /> : "Restore"}
            </Button>
          )}
        </Grid>

        {/* Body */}
        <Grid item xs={12} p={1.5} pt={0}>
          <FormikProvider value={versionFormik}>
            {Version?.rules && 0 < Version?.rules?.length ? (
              Version?.rules?.map((rule, index: number) => (
                <Grid
                  container
                  item
                  key={rule?.name}
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  pt={1.5}
                >
                  <Grid item xs={12}>
                    <TagCard index={index} andOr={["and", "or"]} key={rule.name} />
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid
                container
                height={"100%"}
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                pt={1.5}
              >
                <KonanEmptyState title="No rules in this version" subTitle="Checkout another version" />
              </Grid>
            )}
          </FormikProvider>
        </Grid>
      </Grid>
    )
  }

  const changeTaglistVersion = async (version: string): Promise<void> => {
    try {
      await taglistVersionChangeMutation.mutateAsync({
        projectUUID: currentProject.uuid,
        resourceUUID: uuid,
        resource: "taglists",
        version,
      })

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
      // Invalidate react-query queries
      await queryClient.invalidateQueries("ruleset")
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  return (
    <Fragment>
      {isVersionDialogOpen && (
        <VersioningDialog
          isOpen={isVersionDialogOpen}
          onClose={() => setIsVersionDialogOpen(false)}
          versions={taglist?.data.versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          activeVersion={activeVersion as TagListVersionRetrieve}
        >
          <GetTaglistContainer />
        </VersioningDialog>
      )}

      {isDeletionDialogOpen && (
        <BaseSimpleDialog
          open={isDeletionDialogOpen}
          name={formik.values.name ?? taglist?.data.name}
          onClose={() => setIsDeletionDialogOpen(false)}
          onAccept={() => {
            deleteTaglistMutation.mutateAsync({
              project_uuid: projectId as string,
              resource_uuid: uuid,
            })
          }}
          mode={"taglist-deletion"}
          isLoading={deleteTaglistMutation.isLoading}
        />
      )}

      <Card className="card-box-shadow">
        <CardHeader
          className={styles.padding}
          title={
            <Grid container direction="column" item xs={12} spacing={1}>
              <Grid item container xs={12} justifyContent="space-between">
                <Grid item container xs={6} md={3}>
                  {isTaglistLoading ? (
                    <Skeleton width={200} />
                  ) : isEditMode || isCreateMode ? (
                    <InputText
                      hideDescription
                      id="name"
                      placeholder="Name"
                      value={formik.values.name}
                      handleChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
                      handleBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  ) : (
                    <Typography variant="h3-bold" style={{ textTransform: "inherit" }}>
                      {formik.values.name ?? taglist?.data.name ?? ""}
                    </Typography>
                  )}
                  {!isCreateMode && !isEditMode && (activeVersion?.version || selectedVersion) && (
                    <Grid item mt={isCreateMode || isEditMode ? 0 : -1} ml={1}>
                      <VersionTag
                        version={selectedVersion ?? activeVersion?.version}
                        variant={
                          selectedVersion
                            ? selectedVersion === activeVersion?.version
                              ? "positive"
                              : "default"
                            : "positive"
                        }
                      />
                    </Grid>
                  )}
                </Grid>

                {!isCreateMode && !isEditMode && (
                  <Grid container item xs justifyContent={"flex-end"}>
                    {isPermitted("Delete taglist", permissions.konan, flattenedKonanPermissions) && (
                      <IconButton
                        aria-label="settings"
                        size="small"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
                      >
                        <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
                      </IconButton>
                    )}

                    <Menu
                      key="basic-menu"
                      anchorEl={anchor}
                      open={isMenuOpen}
                      onClose={() => setAnchor(null)}
                      menuMaxContent
                    >
                      <MenuItem
                        onClick={() => {
                          setIsDeletionDialogOpen(true)

                          setAnchor(null)
                        }}
                      >
                        <Typography variant="a" color="negative" variantColor={2}>
                          Remove Taglist
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}
              </Grid>

              {/* Avatar section */}
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <Grid container item spacing={1} xs={6} alignItems="flex-start" className={styles.avatarContainer}>
                  <Grid item sx={{ marginTop: "2px" }}>
                    {/* SvgIcon is used to fix square avatars on safari */}
                    <SvgIcon className={styles.modelAvatar}>
                      <KonanAvatar size={24} name={createdBy} />
                    </SvgIcon>
                  </Grid>
                  <Grid container item xs={10} display={"flex"} direction={"column"}>
                    <Typography
                      variant="label"
                      noWrap
                      style={{ width: "fit-content", marginTop: isCreateMode ? "5px" : "0px" }}
                    >
                      {createdBy}
                    </Typography>

                    {createdAt && (
                      <Typography variant="label" noWrap style={{ width: "fit-content" }}>
                        <Tooltip title={format(new Date(createdAt), "dd/MM/yyyy, p")} placement="right">
                          <Typography variant="span" className={styles.date} style={{ width: "fit-content" }}>
                            {moment(new Date(createdAt)).fromNow()}
                          </Typography>
                        </Tooltip>
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  alignSelf={"flex-end"}
                  sx={{ justifyContent: { xs: "flex-start", md: "flex-end" } }}
                  gap={1}
                >
                  {!isCreateMode && !isEditMode && (
                    <Fragment>
                      <Grid item>
                        <Button
                          size="small"
                          variant="secondary"
                          disabled={isTaglistLoading || disableDuplicateButton}
                          onClick={() => {
                            duplicateTaglist?.(uuid)
                          }}
                        >
                          Duplicate
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          size="small"
                          variant="secondary"
                          disabled={isTaglistLoading}
                          onClick={() => {
                            setSelectedVersion(activeVersion?.version)
                            setIsVersionDialogOpen(true)
                          }}
                        >
                          History
                        </Button>
                      </Grid>
                    </Fragment>
                  )}

                  {!isTaglistLoading && !isEditMode && !isCreateMode
                    ? isPermitted("Update taglist", permissions.konan, flattenedKonanPermissions) && (
                        <Grid item>
                          <Button
                            variant="secondary"
                            size="small"
                            onClick={() => {
                              setIsEditMode((prev) => !prev)
                              setCardsSnapShot(JSON.parse(JSON.stringify(formik.values.cards)))
                            }}
                          >
                            Edit
                          </Button>
                        </Grid>
                      )
                    : !isTaglistLoading &&
                      (isEditMode || isCreateMode) && (
                        <Grid item display={"flex"} gap={1} alignSelf={"flex-end"}>
                          <Grid item>
                            <Button
                              disabled={isTaglistLoading}
                              size="small"
                              variant={"secondary"}
                              onClick={handleCancelTaglist}
                            >
                              Cancel
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              variant="primary"
                              size="small"
                              disabled={formik.isSubmitting || isTaglistLoading}
                              onClick={formik.submitForm}
                            >
                              {formik.isSubmitting ? <CircularProgress color="inherit" size={16} /> : "Save"}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                </Grid>
              </Grid>

              <Grid item container>
                <Typography variant="p" display="block" style={{ textTransform: "inherit" }}>
                  Define conditions with tags. To be effective, you integrate it into your workflow.
                </Typography>
              </Grid>
            </Grid>
          }
        />

        <CardContent className={styles.cardContent}>
          {isTaglistLoading ? (
            <Grid item xs={12}>
              <Card className={styles.cardHeader}>
                <CardHeader className={styles.padding} title={<Skeleton width={200} />} />
                <CardContent className={styles.cardContent}>
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Fragment>
              <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                <FormikProvider value={formik}>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    formik.values.cards.map((rule: any, index) => (
                      <Grid key={rule?.id} container item justifyContent="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                          <TagCard
                            createMode={isCreateMode || rule?.id?.includes("new")}
                            editMode={isEditMode}
                            duplicateMode={isDuplicate}
                            andOr={["and", "or"]}
                            // IMPORTANT: key constructed with these 3 dependencies to re-render whenever change occurs
                            key={`${rule.id}_${schemaFeatures.length}_${computedSchemaFeatures.length}`}
                            index={index}
                            handleRemoveRule={(index: number) => handleRemoveRule(index)}
                            handleDelete={(name: string, stateFn: Dispatch<SetStateAction<boolean>>) =>
                              deleteRule(name, stateFn)
                            }
                          />
                        </Grid>
                      </Grid>
                    ))
                  }
                </FormikProvider>
              </Grid>

              <Grid container justifyContent="flex-start" alignItems="center" mt={2}>
                {(isEditMode || isCreateMode) && (
                  <Grid item>
                    <Button
                      size="regular"
                      disabled={createTaglistMutation.isLoading || updateTaglistMutation.isLoading}
                      onClick={() => {
                        formik.setFieldValue("cards", [
                          ...formik.values.cards,
                          {
                            id: `new-${uuidv4()}`,
                            name: "",
                            group1Switch: "and",
                            group2Switch: "and",
                            levelOneConditions: [emptyCondition],
                            levelTwoConditions: [],
                            levelThreeConditions: [],
                            condition_list_files: [],
                          },
                        ])
                      }}
                    >
                      + Add Tag
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Fragment>
          )}
        </CardContent>
      </Card>
    </Fragment>
  )
}
