import { Fragment, useState } from "react"

import SearchIcon from "@mui/icons-material/Search"
import { InputChangeEvent, InputText } from "@synapse-analytics/synapse-ui"

import { useDebounce } from "../../hooks/useDebounce"

interface Props {
  callback: (value: string | null) => void
  fullWidth?: boolean
  variant?: "filled" | "outlined"
  placeHolder?: string
  isSearch?: boolean
}

/**
 * Input component with debounced callback
 * used for situations such as: searching in the backend
 * @param {function} callback
 * @returns {React.ReactElement}
 */
export const DebouncedInput = (props: Props): React.ReactElement => {
  const { callback, fullWidth = false, variant = "outlined", placeHolder = "Search", isSearch } = props
  const [value, setValue] = useState<string>("")

  const onChange = (): void => {
    callback(value !== "" ? value : null)
  }

  const debouncedOnChange = useDebounce(onChange, 400)

  return (
    <InputText
      handleChange={(e: InputChangeEvent) => {
        debouncedOnChange()

        const target = e.target
        setValue(target.value as string)
      }}
      value={value}
      placeholder={placeHolder}
      fullWidth={fullWidth}
      variant={variant}
      hideDescription
      startAdornment={
        isSearch ? (
          <SearchIcon
            fontSize="small"
            style={{
              paddingTop: "2px",
              fill: "var(--grayscale-text-2)",
            }}
          />
        ) : (
          <Fragment />
        )
      }
    />
  )
}
