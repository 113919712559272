import React, { Fragment, memo, useContext, useEffect, useMemo, useState } from "react"

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { Edge, Node, ReactFlowProvider, getOutgoers, useEdgesState, useReactFlow, useStoreApi } from "reactflow"
import "reactflow/dist/style.css"

import * as Yup from "yup"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Box, CircularProgress, Grid } from "@mui/material"
import { Button, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import queryString from "query-string"
import { v4 as uuidv4 } from "uuid"

import { InfoContainer } from "../../components/InfoContainer"
import { KonanEmptyState } from "../../components/KonanEmptyState"
import { KonanPageHeader } from "../../components/KonanPageHeader"
import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { KonanAPI } from "../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { CreateWorkflowRequest, UpdateWorkflowRequest } from "../../types/custom/projects"
import { ConfirmCloseDialogState, SchemaFeature, UpdateGraphParams } from "../../types/custom/workflows"
import { PaginatedWorkflowListList } from "../../types/generated/api/PaginatedWorkflowListList"
import { WorkflowCreateRequest } from "../../types/generated/api/WorkflowCreateRequest"
import { WorkflowGroupRetrieve } from "../../types/generated/api/WorkflowGroupRetrieve"
import { WorkflowRetrieve } from "../../types/generated/api/WorkflowRetrieve"
import { WorkflowSchemaFeature } from "../../types/generated/api/WorkflowSchemaFeature"
import { WorkflowVersionRetrieve } from "../../types/generated/api/WorkflowVersionRetrieve"
import { extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import {
  convertRetrievedNodesToFlow,
  getEdgeLabel,
  getLayoutedElements,
  handleCreateWorkflow,
  handleUpdateWorkflow,
  hasSchemaChanged,
} from "../../utils/workflowHelpers"
import { WorkflowCanvas } from "./components/WorkflowCanvas"
import { WorkflowCanvasHeader } from "./components/WorkflowCanvasHeader"
import { WorkflowSidebar } from "./components/WorkflowSidebar"
import { edgeStyles, markerEnd } from "./workflow-fixtures"

import styles from "./Workflows.module.scss"
import "./components/CustomCards.module.scss"

// validation schema for workflow name form
const validationSchema = Yup.object({
  workflowName: Yup.string().required("Workflow name is required"),
})

const Workflows = (): React.ReactElement => {
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const [canvasWorkflowOpen, setCanvasWorkflowOpen] = useState(false)

  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [collidingFeaturesList, setCollidingFeaturesList] = useState<Array<string>>([])

  const [acceptedFeaturesFile, setAcceptedFeaturesFile] = useState<File[]>([])

  const [isDuplicated, setIsDuplicated] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()

  // fetches query search params from URL
  const queryParams = useMemo(() => Object(queryString.parse(location.search)), [location.search])
  const queryClient = useQueryClient()

  // init react-flow tools
  const { getEdges, getNode } = useReactFlow()
  const store = useStoreApi()
  const { setNodes, getNodes } = store.getState()
  const nodes = getNodes()

  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState<ConfirmCloseDialogState>({
    isOpen: false,
  })

  const [currentWorkflow, setCurrentWorkflow] = useState<{ workflowName: string; workflowId: string }>({
    workflowId: queryParams?.workflowId ?? "",
    workflowName: queryParams?.workflowName ?? "",
  })

  // isCreateMode is the state that indicates if the canvas still in create workflow mode, or displaying
  // a fetched workflow from the backend
  const [isCreateMode, setIsCreateMode] = useState(currentWorkflow["workflowId"] === "new")
  const [isEditMode, setIsEditMode] = useState(false)

  // using this to get the current workflow state from the URL
  const [searchParams, setSearchParams] = useSearchParams()

  const [confirmDeprecateWorkflowsDialogOpen, setConfirmDeprecateWorkflowsDialogOpen] = useState<boolean>(false)

  const [lastWorkflowUpdatePayload, setLastWorkflowUpdatePayload] = useState<UpdateWorkflowRequest>()

  // saves query in a useMemo to avoid re-rendering
  const query = useMemo(() => {
    return {
      page: "Workflows",
      workflowId: currentWorkflow["workflowId"],
      workflowName: currentWorkflow["workflowName"],
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkflow])

  // create workflow mutation
  const createWorkflowMutation = useMutation<AxiosResponse, AxiosError, CreateWorkflowRequest>(
    KonanAPI.createWorkflow,
    {
      mutationKey: "createWorkflow",
      onSuccess: async (response) => {
        setCurrentWorkflow({ workflowName: response?.data?.name, workflowId: response?.data?.uuid })
        const workflowName = response?.data?.name
        const workflowId = response?.data?.uuid
        setSearchParams(`?${new URLSearchParams({ workflowName, workflowId, EnableClose: "true" })}`)
        NotificationUtils.toast(`Workflow successfully created!`, {
          snackBarVariant: "positive",
        })

        await queryClient.invalidateQueries("workflows")
        await queryClient.invalidateQueries("workflow")
        queryClient.invalidateQueries("workflow-version")
        queryClient.invalidateQueries("workflow-versions")
        queryClient.invalidateQueries(["project", currentProject?.uuid])
      },
      onError: () => {
        if (!isDuplicated) {
          const workflowName = query.workflowName
          const workflowId = "new"
          setSearchParams(
            `?${new URLSearchParams({ workflowName, workflowId, EnableClose: isCreateMode ? "false" : "true" })}`,
          )
          setCurrentWorkflow({
            workflowName: currentWorkflow["workflowName"],
            workflowId: "new",
          })
          setNodes([...nodes])
        }
      },
    },
  )

  // update a workflow
  const updateWorkflowMutation = useMutation<AxiosResponse, AxiosError, UpdateWorkflowRequest>(
    KonanAPI.updateWorkflow,
    {
      onSuccess: async (response) => {
        const workflowName = response?.data?.name
        const workflowId = response?.data?.uuid
        setSearchParams(`?${new URLSearchParams({ workflowName, workflowId, EnableClose: "true" })}`)
        setCurrentWorkflow({ workflowName: response?.data?.name, workflowId: response?.data?.uuid })
        NotificationUtils.toast(`${formik.values.isSchemaSubmit ? "Schema" : "Workflow"} successfully updated!`, {
          snackBarVariant: "positive",
        })
        setIsEditMode(false)
        setConfirmDeprecateWorkflowsDialogOpen(false)
        await queryClient.invalidateQueries("workflows")
        await queryClient.invalidateQueries("workflow")
        queryClient.invalidateQueries("workflow-version")
        queryClient.invalidateQueries("workflow-versions")
        queryClient.invalidateQueries(["project", currentProject?.uuid])
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: ({ response }: any) => {
        searchParams.set("EnableClose", "false")
        if (response?.data?.details && response?.data?.details?.includes("colliding with project schema")) {
          const regex = /\[([^\]]+)\]/
          const match = regex.exec(response?.data?.details)
          if (match) {
            const values = match[1].split(",").map((value) => value.trim())
            setCollidingFeaturesList(values)
          } else {
            setCollidingFeaturesList([])
          }
          setConfirmDeprecateWorkflowsDialogOpen(true)
        } else if (response?.data?.feature_mappings?.length > 0) {
          NotificationUtils.toast(`${response?.data?.feature_mappings[0]}`, {
            snackBarVariant: "negative",
          })
        } else if (response?.data?.non_field_errors?.length > 0) {
          NotificationUtils.toast(`${response?.data?.non_field_errors[0]}`, {
            snackBarVariant: "negative",
          })
        } else if (response?.data?.details) {
          setConfirmDeprecateWorkflowsDialogOpen(false)
          NotificationUtils.toast(`${response?.data?.details}`, {
            snackBarVariant: "negative",
          })
        } else {
          NotificationUtils.toast("An error occurred while updating schema, please try again!", {
            snackBarVariant: "negative",
          })
          setConfirmDeprecateWorkflowsDialogOpen(false)
        }
        setNodes([...nodes])
      },
    },
  )

  const {
    isLoading: isWorkflowsLoading,
    isFetchingNextPage,
    data: workflowsData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AxiosResponse<PaginatedWorkflowListList>, AxiosError>(
    ["workflows", currentProject?.uuid],
    ({ pageParam = 1 }) =>
      KonanAPI.fetchWorkflows({
        projectUUID: currentProject?.uuid as string,
        page_size: 20,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },
      enabled: !!currentProject?.uuid,
    },
  )

  //fetch single workflow
  const {
    isLoading: isWorkflowLoading,
    data: workflow,
    isFetching,
    isRefetching,
  } = useQuery<AxiosResponse<WorkflowGroupRetrieve>, AxiosError>(
    ["workflow", currentProject?.uuid, currentWorkflow["workflowId"]],
    () => KonanAPI.fetchWorkflow(currentProject?.uuid as string, currentWorkflow["workflowId"]),
    {
      enabled:
        !!currentWorkflow["workflowId"] &&
        currentWorkflow["workflowId"] !== "new" &&
        !isEditMode &&
        !!currentProject?.uuid,
      onSuccess: (res) => {
        formik.setFieldValue("workflowName", res?.data?.name)
      },
    },
  )

  const activeWorkflowVersion = workflow?.data?.active_version as WorkflowVersionRetrieve

  // extracting the results after infinite-Query
  const adjustedWorkflows = useMemo(() => {
    return workflowsData?.pages?.reduce((accumulator, page) => {
      return accumulator.concat(page.data.results)
    }, [])
  }, [workflowsData?.pages])

  // memoized order by state for workflows list
  const orderedWorkflows = useMemo(() => {
    if (adjustedWorkflows && adjustedWorkflows?.length > 0 && adjustedWorkflows?.length > 0) {
      return [...(adjustedWorkflows ?? [])].sort((a: WorkflowRetrieve, b: WorkflowRetrieve) =>
        a.state === "ACTIVE" ? -1 : b.state === "ACTIVE" ? 1 : 0,
      )
    }
    // Return an empty array if there are no workflowsData or no results in workflowsData
    return []
  }, [adjustedWorkflows])

  /**
   * This effect aims to update the workflow name/workflow id and the enableClose state whenever
   * any change happens to the location path name
   */
  useEffect(() => {
    const adjustedSearchParams = new URLSearchParams()

    if (!isWorkflowLoading && orderedWorkflows?.length === 0) {
      adjustedSearchParams.set("workflowName", "Untitled-1")
      adjustedSearchParams.set("workflowId", "new")
      adjustedSearchParams.set("EnableClose", "false")
    } else if (orderedWorkflows?.length > 0 && !query?.workflowId) {
      // reading queryParams and setting the active workflow based on it
      const workflowName = orderedWorkflows[0]?.name
      const workflowId = orderedWorkflows[0]?.uuid
      adjustedSearchParams.set("workflowName", workflowName)
      adjustedSearchParams.set("workflowId", workflowId)
      adjustedSearchParams.set("EnableClose", "true")
    } else {
      // In case you need to handle other scenarios, adjust or manipulate searchParams as needed
      // For example, you might want to append all the current query parameters
      Object.entries(query).forEach(([key, value]) => {
        searchParams.set(key, value)
      })
    }

    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    )
    // Dependencies array: React guarantees that location.pathname is stable and won’t change on re-renders,
    // but you should include all variables used inside useEffect to the dependencies array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, navigate, location.pathname, orderedWorkflows, isWorkflowLoading])

  /**
   * this function takes the pre-defined nodes we want to add
   * and assign edges for them, then update nodes, edges states
   * TODO: one of the biggest refactors will happen alongside revamp is data-flow for updating nodes
   * and edges, currently UpdateGraph handles the following
   * 1. adding any nodes (either from add block or in-between)
   * 2. updating states (nodes, edges) on add and on delete, because there's a technical limitation
   * that needs further debugging when using edges from the store rather than from useEdgesState
   * so updateGraph should be refactored later
   * 3. refactor updateGraph params
   */
  const updateGraph = ({
    dataAfterDeletion,
    arrayOfNewNodes,
    isNodeBetween,
    targetNode,
    sourceNode,
    edgeLabel,
    areBranchesSwapped,
  }: UpdateGraphParams): void => {
    // first condition, if we are passing the newly data after deletion to this update function
    // to update our state with the passed data (nodes, edges), as well as updating the layouting function
    // TODO: make this in its own seperate function
    if (dataAfterDeletion?.nodes?.length) {
      setEdges(dataAfterDeletion?.edges?.length ? dataAfterDeletion?.edges : getEdges())
      setNodes(dataAfterDeletion?.nodes)
      getLayoutedElements(
        dataAfterDeletion?.nodes,
        dataAfterDeletion?.edges?.length ? dataAfterDeletion?.edges : getEdges(),
      )
    }

    // then, we check if this update should happen because the filter branches swapped
    else if (areBranchesSwapped?.filterNodeId) {
      // first get the 2 children edges for the filter
      const filterChildren = getOutgoers(
        getNode(areBranchesSwapped.filterNodeId as string) as Node,
        getNodes(),
        getEdges(),
      )

      const filterChildrenEdges = getEdges()?.filter((edge) => edge.source === areBranchesSwapped.filterNodeId)

      if (filterChildrenEdges?.length === 2) {
        // swap the targets
        const modifiedTrueRoute = { ...filterChildrenEdges[0], target: filterChildrenEdges[1]?.target }
        const modifiedFalseRoute = { ...filterChildrenEdges[1], target: filterChildrenEdges[0]?.target }

        // remove old edges and add the newly ones
        setEdges([
          ...getEdges()?.filter((edge) => !filterChildrenEdges.some((edge2) => edge.id === edge2.id)),
          modifiedTrueRoute,
          modifiedFalseRoute,
        ])

        // updating the nodes
        setNodes(
          getNodes().map((node) => {
            if (
              node.id !== areBranchesSwapped.filterNodeId &&
              !filterChildren?.find((innerNode) => innerNode.id === node.id)
            ) {
              return node
            }

            const newNode = { ...node }

            // Adjust the `areBranchesSwapped` state
            if (newNode.id === areBranchesSwapped.filterNodeId) {
              newNode.data = {
                ...newNode.data,
                areBranchesSwapped: !newNode?.data?.areBranchesSwapped,
              }
            }

            // Update the `filterBranch` state if the node's filterId matches the swapped filterId
            if (filterChildren?.find((innerNode) => newNode.id === innerNode.id)) {
              const mappedBranches = {
                true: "false",
                false: "true",
              }

              newNode.data = {
                ...newNode.data,
                filterBranch: mappedBranches[newNode?.data?.filterBranch as keyof typeof mappedBranches] || "",
              }
            }

            return newNode
          }),
        )
      }
    }

    // then we check, if there're newly passed nodes or not
    else if (arrayOfNewNodes && arrayOfNewNodes?.length > 0) {
      const newEdges: Edge[] = []
      const sourceHandles = ["a", "b"]
      const firstNodeType = arrayOfNewNodes[0]?.data?.nodeType
      const isNewNodeFilterAndInLoop = Boolean(firstNodeType === "FilterNode" && arrayOfNewNodes[0]?.data?.isNodeInLoop)
      const loopEndOfFilterLoop = getNodes()?.find(
        (node) => node?.data?.parentLoopId === arrayOfNewNodes[0]?.data?.loopId,
      )

      // check if this new node(S) in between or at the end of the flow
      if (isNodeBetween) {
        const newInBetweenEdges: Edge[] = []

        for (let i = 0; i < arrayOfNewNodes?.length - 1; i++) {
          const newEdge = {
            id: `${uuidv4()}`,
            source: `${arrayOfNewNodes[i]?.id}`,
            target: `${arrayOfNewNodes[i + 1]?.id}`,
            sourceHandle: firstNodeType === "FilterNode" ? "b" : sourceHandles[i],
            targetHandle: arrayOfNewNodes[i + 1]?.data?.filterBranch === "false" ? "b" : "a",
            markerEnd,
            type: "custom",
            data: {
              text: firstNodeType === "FilterNode" ? "false" : getEdgeLabel(firstNodeType, i),
              updateGraph,
            },
            style: edgeStyles,
          }
          newInBetweenEdges.push(newEdge)
        }

        // OG node
        const previousNode = getNodes()?.filter((node) => node.id === sourceNode)

        // change the parentId of the previous connected node to the parentId of this new node
        setNodes([
          ...getNodes().map((node) => {
            const newNode = { ...node }

            if (node?.id === targetNode) {
              if (firstNodeType === "FilterNode") {
                newNode.data = {
                  ...newNode.data,
                  filterBranch: edgeLabel || "true",
                  filterId: previousNode[0]?.data?.filterId,
                  isNodeInFilter: true,
                  is_true_route: node?.data?.filterBranch === "true",
                  is_false_route: true,
                }
              }

              newNode.data = {
                ...newNode.data,
                parentId:
                  firstNodeType === "FilterNode"
                    ? arrayOfNewNodes[0]?.id
                    : arrayOfNewNodes[arrayOfNewNodes?.length - 1]?.id,
              }
            }

            return newNode
          }),
          ...arrayOfNewNodes,
        ])

        // creating new edge between the parentId and new node id (source: parentId, target: node id)
        const newEdge = {
          id: `${uuidv4()}`,
          source: `${arrayOfNewNodes[0]?.data?.parentId}`,
          target: `${arrayOfNewNodes[0]?.id}`,
          sourceHandle: edgeLabel === "true" ? "a" : "b",
          targetHandle: arrayOfNewNodes[0]?.data?.filterBranch === "false" ? "b" : "a",
          markerEnd,
          animated: arrayOfNewNodes[0]?.data?.parentId === "1",
          type: "custom",
          data: {
            updateGraph,
            text: edgeLabel ?? getEdgeLabel(previousNode[0]?.data?.nodeType, 0),
          },
          style: edgeStyles,
        }

        /**
         * creating new edge object to connect between new node id and the target node
         * which is the node that was previously connected to the parent of this new node
         * ex: new C node has been added, so: Source: C -> Target:targetNode
         */
        const modifiedEdge = {
          id: `${uuidv4()}`,
          source: `${arrayOfNewNodes[firstNodeType === "FilterNode" ? 0 : arrayOfNewNodes?.length - 1]?.id}`,
          target: `${targetNode}`,
          sourceHandle: "a",
          targetHandle:
            [...getEdges()].find(
              (edge) => edge.source === arrayOfNewNodes[0]?.data?.parentId && edge?.data?.text === edgeLabel,
            )?.targetHandle ?? `${uuidv4()}`,
          markerEnd,
          type: "custom",
          data: {
            text: getEdgeLabel(firstNodeType, 0),
            updateGraph,
          },
          style: edgeStyles,
        }

        // this edge is only valid, if we are adding a filter node inside a loop, in this case
        // we have 2 scenarios:
        // 1. both filter routes pointing to loop end node
        // 2. one route pointing to loop end node and the other pointing to another node, ex: program node
        // so we need this extra edge
        const secondModifiedEdge = {
          id: `${uuidv4()}`,
          source: `${arrayOfNewNodes[firstNodeType === "FilterNode" ? 0 : arrayOfNewNodes?.length - 1]?.id}`,
          target:
            isNewNodeFilterAndInLoop && getNode(targetNode as string)?.data?.nodeType !== "LoopEndNode"
              ? loopEndOfFilterLoop?.id
              : `${targetNode}`,
          sourceHandle: "b",
          targetHandle: `${uuidv4()}`,
          markerEnd,
          type: "custom",
          data: {
            text: "false",
            updateGraph,
          },
          style: edgeStyles,
        }

        /**
         * Find the index of the edge that we will to remove.
         * the edge that was connecting the parent node and the old node before adding the new node to be in between
         * ex: edge1 = Source:A -> Target:B, now new C node added so now we will remove this edge1
         * we have an exception which is FilterNode because the parent has 2 children (true and false)
         * so we need to check on the edge Label with the parentId
         */
        const indexToRemove = [...getEdges()].findIndex((edge) => {
          return edge.source === arrayOfNewNodes[0]?.data?.parentId && edge?.data?.text === edgeLabel
        })

        // creating new edges array will be used with filter node
        const adjustedEdges = [...getEdges()]

        // Remove the element from the edges array.
        adjustedEdges.splice(indexToRemove, 1)

        const validEdges = getEdges().filter((edge: Edge) => edge.source !== arrayOfNewNodes[0]?.data?.parentId)

        const updatedEdges: Edge[] = []
        // updating the edges with two different arrays based on the filter condition
        if (previousNode[0]?.type === "FilterNode") {
          /**
           * this check is IMPORTANT, because the edge with the sourceHandle = "a"
           * which is in this case the one with edgeLabel = "true" MUST come before
           * the edge with the sourceHandle = "b" (edgeLabel = "false") in the order of the array, that's why
           * the newEdge comes in first when the edgeLabel === "true" to ensure that SH -> a is before SH -> b
           */
          if (edgeLabel === "true") {
            updatedEdges.push(newEdge, ...adjustedEdges, modifiedEdge)
          } else {
            updatedEdges.push(...adjustedEdges, newEdge, modifiedEdge)
          }
        } else {
          updatedEdges.push(...validEdges, newEdge, modifiedEdge)
        }

        // Conditionally add secondModifiedEdge and newInBetweenEdges
        if (isNewNodeFilterAndInLoop) {
          updatedEdges.push(secondModifiedEdge as Edge, ...newInBetweenEdges)
        } else {
          updatedEdges.push(...newInBetweenEdges)
        }

        // Updating the updatedEdges with modifying the old edge
        setEdges(updatedEdges)

        getLayoutedElements([...getNodes()], updatedEdges)
      } else {
        for (let i = 0; i < arrayOfNewNodes?.length - 1; i++) {
          const newEdge = {
            id: `${uuidv4()}`,
            source:
              arrayOfNewNodes[0]?.type === "FilterNode" ? `${arrayOfNewNodes[0]?.id}` : `${arrayOfNewNodes[i]?.id}`,
            target: `${arrayOfNewNodes[i + 1]?.id}`,
            sourceHandle: sourceHandles[i],
            markerEnd,
            type: "custom",
            data: {
              text: getEdgeLabel(firstNodeType, i),
              updateGraph,
            },
            style: edgeStyles,
          }
          newEdges.push(newEdge)
        }
        setNodes([...getNodes(), ...arrayOfNewNodes])
        setEdges([...getEdges(), ...newEdges])
        getLayoutedElements([...getNodes(), ...arrayOfNewNodes], [...edges, ...newEdges])
      }
    }
  }

  // default nodes and edges for EVERY workflow
  const initNodesAndEdges = (): void => {
    const initNodes = [
      {
        id: "1",
        data: { nodeType: "StartNode" },
        position: { x: 100, y: 100 },
        type: "SelectionNode",
        draggable: true,
        height: 64,
        width: 320,
      },
      {
        id: "2",
        data: { updateGraph, parentId: "1" },
        position: { x: 100, y: 250 },
        type: "AddBlockNode",
      },
    ]
    const initEdges = [
      {
        id: "e1-2",
        source: "1",
        target: "2",
        type: "custom",
        data: { text: "", updateGraph },
        animated: true,
        markerEnd,
        style: edgeStyles,
      },
    ]
    setNodes([...initNodes])
    setEdges([...initEdges])
  }

  const nodelessWorkFlow = (isAddBlockDisabled: boolean): void => {
    const initNodes = [
      {
        id: "1",
        data: { nodeType: "StartNode" },
        position: { x: 100, y: 100 },
        type: "SelectionNode",
        draggable: true,
        height: 64,
        width: 320,
      },
      {
        id: "2",
        data: {
          updateGraph,
          schema: activeWorkflowVersion?.schema,
          disabled: isAddBlockDisabled,
          formik,
          parentId: "1",
        },
        position: { x: 100, y: 250 },
        type: "AddBlockNode",
      },
    ]
    const initEdges = [
      {
        id: "e1-2",
        source: "1",
        target: "2",
        type: "custom",
        data: { text: "", updateGraph },
        animated: true,
        markerEnd,
        style: edgeStyles,
      },
    ]

    setNodes([...initNodes])
    setEdges([...initEdges])
  }

  // auto create the default nodes and edges if the canvas is open and a new workflow initialized
  // TODO: remove this effect
  useEffect(() => {
    if ((currentWorkflow["workflowId"] === "new" || query.workflowId === "new") && isCreateMode) {
      if (!isDuplicated) {
        initNodesAndEdges()
      }
      setSearchParams(
        `?${new URLSearchParams({ workflowName: searchParams.get("workflowName") as string, workflowId: searchParams.get("workflowId") as string, EnableClose: "false" })}`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasWorkflowOpen, query.workflowId, isCreateMode, isDuplicated])

  const adjustCanvasNodes = (nodes: Node[], edges: Edge[]): void => {
    setTimeout(() => {
      // check if current nodes !== init nodes and nodes not being updated
      if (
        currentWorkflow["workflowId"] !== "new" &&
        getNodes()[1]?.type !== "AddBlockNode" &&
        !updateWorkflowMutation.isLoading
      ) {
        setNodes([...nodes])
        setEdges([...edges])
        getLayoutedElements([...nodes], [...edges])
      }
    }, 10)
  }

  // if there's a fetched workflow, then we update our nodes and edges states, to
  // accommodate these new changes and display them
  // TODO: remove this effect
  useEffect(() => {
    if (workflow && workflow?.data?.name && !isFetching && !isWorkflowLoading && !isWorkflowsLoading) {
      if (
        activeWorkflowVersion?.nodes?.length === 0 &&
        activeWorkflowVersion?.start_node === null &&
        !updateWorkflowMutation.isLoading &&
        !isCreateMode &&
        !isEditMode
      ) {
        nodelessWorkFlow(true)
      } else if (
        activeWorkflowVersion &&
        activeWorkflowVersion?.nodes?.length > 0 &&
        activeWorkflowVersion?.start_node !== null &&
        !isCreateMode &&
        !isEditMode
      ) {
        // parsing the fetched workflow
        const flowData = convertRetrievedNodesToFlow(
          activeWorkflowVersion?.nodes,
          activeWorkflowVersion?.start_node ?? "0",
          "retrieve",
          updateGraph,
          activeWorkflowVersion?.schema,
          formik,
        )
        // to ensure canvas is re-triggered
        adjustCanvasNodes([...flowData["nodes"]], [...flowData["edges"]])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeWorkflowVersion,
    createWorkflowMutation.isSuccess,
    updateWorkflowMutation.isSuccess,
    updateWorkflowMutation.isLoading,
    isWorkflowLoading,
    isWorkflowsLoading,
    isFetching,
    isCreateMode,
    isEditMode,
  ])

  // executing the function that handles workflow layout
  getLayoutedElements(getNodes(), edges)

  // if there's a fetched workflow, readMode is true
  useEffect(() => {
    if (!isWorkflowLoading && !isFetching && workflow?.data?.name && currentWorkflow["workflowId"] !== "new") {
      setIsCreateMode(false)
    }
  }, [workflow, isWorkflowLoading, currentWorkflow, isFetching])

  const defaultFeatures = [
    {
      name: "",
      type: WorkflowSchemaFeature.type.TEXT,
      is_required: true,
      remove: false,
      file: false,
      id: uuidv4(),
      new: true,
      source: "-",
    },
  ]

  // init formik
  const formik = useFormik({
    initialValues: {
      workflowName: query.workflowName,
      computedFeatures: [],
      renderControl: 1,
      features: [
        {
          name: "",
          type: WorkflowSchemaFeature.type.TEXT,
          is_required: true,
          remove: false,
          file: false,
          id: uuidv4(),
          new: true,
          source: "-",
        },
      ],
      isSchemaSubmit: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // extracting non-empty features then trim each single one
      const validFeatures = values.features?.filter((feat) => feat.name !== "")

      const trimmedWorkflowName = values.workflowName.trim()
      const trimmedFeatures = validFeatures?.map((feat) => {
        return { ...feat, name: feat.name.trim() }
      })

      // indicator for creating schema with empty workflow
      const isSchemaWithEmptyWorkflow =
        nodes?.length === 2 &&
        nodes[0]?.data?.nodeType === "StartNode" &&
        nodes[1]?.type === "AddBlockNode" &&
        isCreateMode &&
        trimmedFeatures?.length > 0

      if (
        values.isSchemaSubmit &&
        workflow?.data?.uuid &&
        hasSchemaChanged(trimmedFeatures, activeWorkflowVersion?.schema?.features as SchemaFeature[])
      ) {
        try {
          const updateWorkflowPayload = {
            name: trimmedWorkflowName,
            projectUUID: currentProject?.uuid as string,
            workflowUUID: workflow?.data?.uuid,
            schema: [...trimmedFeatures],
          }
          setLastWorkflowUpdatePayload(updateWorkflowPayload)
          await updateWorkflowMutation.mutateAsync(updateWorkflowPayload)
        } catch (e) {
          console.warn(e)
        }
      } else if (isSchemaWithEmptyWorkflow && !workflow?.data?.uuid) {
        try {
          await createWorkflowMutation.mutateAsync({
            name: trimmedWorkflowName,
            state: WorkflowCreateRequest.state.DISABLED,
            projectUUID: currentProject?.uuid as string,
            schema: [...trimmedFeatures],
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e?.response?.data?.details) {
            NotificationUtils.toast(`${e?.response?.data?.details}`, {
              snackBarVariant: "negative",
            })
          } else if (e?.response?.data?.nodes?.length > 0) {
            NotificationUtils.toast(`${e?.response?.data?.nodes[0]}`, {
              snackBarVariant: "negative",
            })
          } else {
            NotificationUtils.toast("An error occurred while creating schema, please try again!", {
              snackBarVariant: "negative",
            })
          }
        }
      }

      if (isCreateMode && !isSchemaWithEmptyWorkflow) {
        const returnedNodes = await handleCreateWorkflow(
          trimmedWorkflowName,
          [...nodes],
          orderedWorkflows,
          createWorkflowMutation,
          currentProject?.uuid,
          setIsCreateMode,
          edges,
          [...trimmedFeatures],
          setIsDuplicated,
        )
        if (returnedNodes) {
          setNodes([...returnedNodes])
        }
      } else if (isEditMode && !values.isSchemaSubmit) {
        const returnedNodes = await handleUpdateWorkflow(
          currentProject?.uuid,
          currentWorkflow["workflowId"],
          updateWorkflowMutation,
          setIsEditMode,
          trimmedWorkflowName,
          edges,
          orderedWorkflows,
          nodes,
          workflow?.data?.name,
          trimmedFeatures,
          setLastWorkflowUpdatePayload,
          activeWorkflowVersion,
        )
        if (returnedNodes) {
          setNodes([...returnedNodes])
        }
      }

      formik.setFieldValue("isSchemaSubmit", false)
    },
  })

  // reading queryParams and setting the active workflow based on it
  useEffect(() => {
    if (orderedWorkflows?.length > 0 && !query?.workflowId) {
      setCurrentWorkflow({
        workflowName: orderedWorkflows[0]?.name,
        workflowId: orderedWorkflows[0]?.uuid,
      })
    }
  }, [query?.workflowId, orderedWorkflows])

  const handleInitNewWorkflow = (): void => {
    const workflowName = `Untitled-${workflowsData?.pages[0]?.data?.count ? workflowsData?.pages[0]?.data?.count + 1 : 1}`
    const workflowId = "new"

    setSearchParams(
      `?${new URLSearchParams({ workflowName, workflowId, EnableClose: orderedWorkflows?.length ? "false" : "true" })}`,
    )

    setCurrentWorkflow({
      workflowName,
      workflowId,
    })

    formik.setFieldValue("workflowName", workflowName)
    formik.setFieldValue("features", defaultFeatures)
    formik.setFieldValue("computedFeatures", [])
    setIsCreateMode(true)
    setIsEditMode(false)
    initNodesAndEdges()
    setCanvasWorkflowOpen(true)
  }

  // handler for initializing a new workflow
  const onAddNewWorkflow = (): void => {
    if (searchParams.get("EnableClose") === "false") {
      setConfirmCloseDialogOpen({ isOpen: true, action: { name: "new-workflow" } })
    } else {
      handleInitNewWorkflow()
    }
  }

  // duplicate a workflow
  const handleDuplicateWorkflow = async (): Promise<void> => {
    const workflowName = `${workflow?.data.name} - Copy`
    const workflowId = "new"

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setSearchParams(
      `?${new URLSearchParams({ workflowName, workflowId, EnableClose: orderedWorkflows?.length ? "false" : "true" })}`,
    )

    setCurrentWorkflow({
      workflowName,
      workflowId,
    })

    formik.setFieldValue("workflowName", workflowName)
    setIsDuplicated(true)
    setIsCreateMode(true)
    setIsEditMode(false)
    setCanvasWorkflowOpen(true)
  }

  // when a user wants to cancel the playground for an unsaved workflow, there are 3 results
  // 1- cancel and switch to another workflow selected from the side bar
  // 2- cancel and go back to the default workflow, or go to empty state if there're no workflows
  // 3- cancel the edit mode, stays in the same workflow
  const handleConfirmAction = (): void => {
    switch (confirmCloseDialogOpen?.action?.name) {
      case "switch":
        setCurrentWorkflow({
          workflowName: confirmCloseDialogOpen?.action?.workflowName as string,
          workflowId: confirmCloseDialogOpen?.action?.workflowId as string,
        })
        formik.setFieldValue("workflowName", "")
        setSearchParams(
          `?${new URLSearchParams({ workflowName: confirmCloseDialogOpen?.action?.workflowName as string, workflowId: confirmCloseDialogOpen?.action?.workflowId as string, EnableClose: "true" })}`,
        )
        break

      case "close":
        setCanvasWorkflowOpen(false)
        setCurrentWorkflow({
          workflowName: orderedWorkflows[0]?.name ?? "",
          workflowId: orderedWorkflows[0]?.uuid ?? "",
        })
        formik.setFieldValue("workflowName", "")
        setSearchParams(
          `?${new URLSearchParams({ workflowName: orderedWorkflows[0]?.name as string, workflowId: orderedWorkflows[0]?.uuid as string, EnableClose: "true" })}`,
        )
        break

      case "new-workflow":
        handleInitNewWorkflow()
        break

      default:
        break
    }

    setConfirmCloseDialogOpen({ isOpen: false })
    setIsEditMode(false)
    setSearchParams(
      `?${new URLSearchParams({ workflowName: searchParams.get("workflowName") as string, workflowId: searchParams.get("workflowId") as string, EnableClose: "true" })}`,
    )

    if (currentWorkflow["workflowId"] !== "new" && confirmCloseDialogOpen?.action?.name !== "new-workflow") {
      formik.setFieldValue("workflowName", currentWorkflow["workflowName"])
    }
  }

  const handleEditClicked = (): void => {
    setIsEditMode(true)

    setSearchParams(
      `?${new URLSearchParams({ workflowName: searchParams.get("workflowName") as string, workflowId: searchParams.get("workflowId") as string, EnableClose: "false" })}`,
    )

    if (
      activeWorkflowVersion?.nodes?.length === 0 &&
      activeWorkflowVersion?.start_node === null &&
      !updateWorkflowMutation.isLoading
    ) {
      nodelessWorkFlow(false)
    } else if (
      activeWorkflowVersion &&
      activeWorkflowVersion?.nodes?.length > 0 &&
      activeWorkflowVersion?.start_node !== null &&
      !isCreateMode &&
      !isEditMode
    ) {
      // parsing the fetched workflow
      const flowData = convertRetrievedNodesToFlow(
        activeWorkflowVersion?.nodes,
        activeWorkflowVersion?.start_node ?? "0",
        "edit",
        updateGraph,
        activeWorkflowVersion?.schema,
        formik,
      )
      // setTimeout to ensure canvas and editMode is re-triggered
      // TODO: change later when workflow rendering logic changes in the workflow screen revamp
      setTimeout(() => {
        adjustCanvasNodes([...flowData["nodes"]], [...flowData["edges"]])
      }, 100)
    }
  }

  useEffect(() => {
    if (activeWorkflowVersion && searchParams.get("workflowId") !== "new") {
      const newArr: Array<WorkflowSchemaFeature & { id: string }> = []
      const computedFeaturesArr: Array<{ uuid: string; value: string }> = []
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      activeWorkflowVersion?.nodes?.forEach((node: any) => {
        if (node?.computed_feature_name) {
          computedFeaturesArr.push({ uuid: node?.uuid, value: node?.computed_feature_name })
        }
      })
      activeWorkflowVersion?.schema?.features?.forEach((feat: WorkflowSchemaFeature) => {
        newArr.push({
          name: feat?.name,
          type: feat?.type,
          is_required: feat?.is_required,
          id: uuidv4(),
          source: feat?.source,
        })
      })
      formik.setFieldValue("features", newArr)
      formik.setFieldValue("computedFeatures", computedFeaturesArr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkflowVersion?.schema, isWorkflowLoading, searchParams, isFetching, isCreateMode])

  useEffect(() => {
    if (isEditMode || isCreateMode) {
      setNodes(
        getNodes().map((node) => {
          const newNode = { ...node }
          newNode.data = {
            ...newNode.data,
            createMode: isCreateMode,
            editMode: isEditMode,
            readMode: !(isCreateMode || isEditMode),
            formik,
          }
          return newNode
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.features, isEditMode, getNodes(), formik.values.computedFeatures, isCreateMode])

  // this effects only run on mount, just to make sure if
  // on page mounting/reloading 'EnableClose' param was set to false and the workflow current mode is not create mode
  // so it only ensures that 'EnableClose' param/flag should persist to false if wf is being created
  useEffect(() => {
    if (searchParams.get("workflowId") !== "new") {
      setSearchParams(
        `?${new URLSearchParams({ workflowName: searchParams.get("workflowName") as string, workflowId: searchParams.get("workflowId") as string, EnableClose: "true" })}`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldDisableAddWorkflowButton =
    createWorkflowMutation.isLoading ||
    currentWorkflow["workflowId"] === "new" ||
    updateWorkflowMutation.isLoading ||
    isCreateMode ||
    isWorkflowLoading ||
    isWorkflowsLoading ||
    isFetching ||
    isRefetching

  return (
    <Grid container item spacing={2}>
      <BaseSimpleDialog
        open={confirmDeprecateWorkflowsDialogOpen}
        name=""
        onClose={() => setConfirmDeprecateWorkflowsDialogOpen(false)}
        onAccept={() => {
          updateWorkflowMutation.mutateAsync({
            ...(lastWorkflowUpdatePayload as UpdateWorkflowRequest),
            deprecate_workflows: true,
          })
        }}
        mode="deprecate-workflows"
        list={collidingFeaturesList}
        isLoading={updateWorkflowMutation.isLoading}
      />

      <BaseSimpleDialog
        onClose={() => setConfirmCloseDialogOpen({ isOpen: false })}
        open={confirmCloseDialogOpen?.isOpen}
        mode="close-while-edit"
        isLoading={false}
        name=""
        onAccept={handleConfirmAction}
      />

      {/* TODO:: use KonanSubheader */}
      <Grid item xs={12} textAlign={"right"}>
        <KonanPageHeader
          title="Workflows"
          actions={[
            <Button
              key={workflow?.data?.name}
              size="regular"
              variant="primary"
              onClick={onAddNewWorkflow}
              disabled={shouldDisableAddWorkflowButton}
              startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
            >
              Add Workflow
            </Button>,
          ]}
        />
      </Grid>

      <Box marginLeft={2} className={styles.workflowsContainer} marginTop={2}>
        {(isWorkflowsLoading || !currentProject) && !canvasWorkflowOpen ? (
          <Grid item xs={12} container minHeight={"100%"} className="dotted-background">
            <InfoContainer
              icon={<CircularProgress className={styles.circularProgress} />}
              title="Loading workflows..."
            />
          </Grid>
        ) : !isWorkflowsLoading && !isWorkflowLoading && !orderedWorkflows?.length && !canvasWorkflowOpen ? (
          <Grid item xs={12} container minHeight={"100%"} className="dotted-background">
            <KonanEmptyState
              title="No Workflows"
              subTitle="Build your own process using all your previously defined components"
              buttonText="Add workflow"
              setAction={handleInitNewWorkflow}
            />
          </Grid>
        ) : (
          <Fragment>
            <WorkflowSidebar
              activeWorkflowId={currentWorkflow["workflowId"]}
              setConfirmDialog={setConfirmCloseDialogOpen}
              setActiveWorkflow={setCurrentWorkflow}
              formik={formik}
              orderedWorkflows={orderedWorkflows}
              workflowsLength={workflowsData?.pages[0]?.data?.count}
              setIsEditMode={setIsEditMode}
              activeWorkflowName={currentWorkflow["workflowName"]}
              isWorkflowsLoading={isWorkflowsLoading}
              hasMore={hasNextPage ?? false}
              fetchNext={fetchNextPage}
              shouldFetchNext={!isWorkflowsLoading && !isFetchingNextPage}
            />
            <Grid item container xs={12} height="100%">
              <WorkflowCanvasHeader
                activeWorkflow={workflow?.data?.active_version as WorkflowVersionRetrieve}
                activeWorkflowId={currentWorkflow["workflowId"]}
                activeWorkflowName={currentWorkflow["workflowName"]}
                acceptedFile={acceptedFeaturesFile}
                setAcceptedFile={setAcceptedFeaturesFile}
                onEdgesChange={onEdgesChange}
                activeWorkflowVersion={activeWorkflowVersion}
                createWorkflowMutation={createWorkflowMutation}
                currentProjectId={currentProject?.uuid}
                formik={formik}
                isCreateMode={isCreateMode}
                isEditMode={isEditMode}
                isWorkflowLoading={isWorkflowLoading}
                isWorkflowsLoading={isWorkflowsLoading}
                isWorkflowFetching={isFetching}
                isWorkflowRefetching={isRefetching}
                setConfirmCloseDialogOpen={setConfirmCloseDialogOpen}
                handleEditClicked={handleEditClicked}
                workflowName={workflow?.data?.name}
                workflowState={workflow?.data?.state}
                workflowsCount={orderedWorkflows?.length}
                updateWorkflowMutation={updateWorkflowMutation}
                workflowUUID={workflow?.data?.uuid as string}
                handleDuplicateWorkflow={handleDuplicateWorkflow}
                setIsDuplicate={setIsDuplicated}
                versioningContainer={
                  <WorkflowCanvas
                    isFetching={isFetching}
                    isWorkflowLoading={isWorkflowLoading}
                    onEdgesChange={onEdgesChange}
                    nodes={nodes}
                    edges={edges}
                  />
                }
              />
              <Grid
                item
                container
                xs={12}
                className={`${
                  isCreateMode || isEditMode ? styles.editModeContainerHeight : styles.readModeContainerHeight
                } layoutflow`}
              >
                {isWorkflowLoading ||
                createWorkflowMutation.isLoading ||
                updateWorkflowMutation.isLoading ||
                isFetching ||
                nodes?.length === 0 ||
                isRefetching ? (
                  <Grid item xs={12} container minHeight={"80%"} className="dotted-background">
                    <InfoContainer icon={<CircularProgress />} title="Loading workflow..." />
                  </Grid>
                ) : (
                  <WorkflowCanvas
                    isCreateMode={isCreateMode}
                    isEditMode={isEditMode}
                    isWorkflowLoading={isWorkflowLoading}
                    nodes={getNodes()}
                    edges={edges}
                    isFetching={isFetching}
                    onEdgesChange={onEdgesChange}
                  />
                )}
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Box>
    </Grid>
  )
}

export const FlowWithProvider = memo((): React.ReactElement => {
  return (
    <div className="zoompanflow">
      <ReactFlowProvider>
        <Workflows />
      </ReactFlowProvider>
    </div>
  )
})
