import { ReactElement } from "react"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Grid } from "@mui/material"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import styles from "./KonanEmptyState.module.scss"

type Props = {
  title: string
  subTitle?: string
  buttonText?: string
  setAction?: (value: boolean) => void
  actionButtons?: Array<ReactElement>
}

/**
 * Basic empty state component
 * @param {string[]} title empty state title
 * @param {string} subTitle empty state subtitle
 * @param {string} buttonText text that appears on the button
 * @param {function} setAction fires up action needed by the page
 * @return {React.ReactElement}
 */
function KonanEmptyState(props: Readonly<Props>): React.ReactElement {
  const { title, subTitle, buttonText, setAction, actionButtons } = props

  return (
    <Grid className={styles.emptyState} container>
      <Grid className={styles.emptyStateContainer} container>
        <Grid>
          <Typography variant="h2-bold" className={styles.emptyStateTitle} align="center">
            {title}
          </Typography>
        </Grid>

        <Grid container direction="row" justifyContent="center">
          <Typography variant="p" className={styles.emptyStateSubtitle}>
            {subTitle}
          </Typography>
        </Grid>

        {/** TODO: change component structure later to only accept actions as an array of ReactElements
         * for now .. i'm keeping both options to keep backward compatibility
         * and don't break older implementation
         */}
        {actionButtons && (
          <Grid display="flex" mt={1}>
            {actionButtons?.map((button) => (
              <Grid item mr={1}>
                {button}
              </Grid>
            ))}
          </Grid>
        )}

        {buttonText && (
          <Grid container justifyContent={"center"} mt={1}>
            <Button
              onClick={() => setAction && setAction(true)}
              variant="primary"
              color="primary"
              size="regular"
              startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
            >
              {buttonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

// search sort filer empty states props
type SSFProps = { title?: string[]; text?: string }

/**
 * Filter empty state component
 * shown when the filter yields no data
 * @param {string[]} title empty state title
 * @param {string} text empty state title
 * @return {React.ReactElement}
 */
function KonanEmptyStateFilter(props: Readonly<SSFProps>): React.ReactElement {
  const { title, text } = props

  return (
    <Grid container className={styles.emptyState}>
      <Grid container className={styles.emptyStateContainer}>
        <Grid>
          {text ? (
            <Typography variant="h2-bold" className={styles.emptyStateTitle}>
              {text}
            </Typography>
          ) : (
            <Typography variant="h2-bold" className={styles.emptyStateTitle}>
              No results found {title && title?.length > 0 && `for ${title?.join(", ").toLowerCase()}`}
            </Typography>
          )}
        </Grid>

        <Grid container direction="row" justifyContent="center">
          <Typography variant="p" className={styles.emptyStateSubtitle}>
            Try adjusting your filter to find what you're looking for
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

/**
 * search empty state component
 * shown when the search yields no data
 * @param {string[]} title empty state title
 * @return {React.ReactElement}
 */
function KonanEmptyStateSearch(props: Readonly<SSFProps>): React.ReactElement {
  const { title } = props

  return (
    <Grid className={styles.emptyState} container>
      <Grid className={styles.emptyStateContainer} container>
        <Grid item xs={6}>
          <Typography variant="h2-bold" className={styles.emptyStateTitle}>
            No results found for "{title}".
          </Typography>
        </Grid>

        <Grid container direction="row" justifyContent="center">
          <Typography variant="p" className={styles.emptyStateSubtitle}>
            Try adjusting your search to find what you're looking for
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { KonanEmptyStateSearch, KonanEmptyStateFilter, KonanEmptyState }
