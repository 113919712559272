import React, { Fragment, useContext, useMemo, useState } from "react"

import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined"
import { CircularProgress, Grid } from "@mui/material"
import { Tab, Tabs } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"

import { queryClient } from ".."
import { KonanAvatarComponent } from "../components/KonanAvatar"
import { KonanEmptyState, KonanEmptyStateFilter } from "../components/KonanEmptyState"
import { KonanTabsHeader } from "../components/KonanTabsHeader"
import { SideBar } from "../components/UI/SideBar"
import { DataFileCard, DataFileLoadingCard } from "../features/DataFiles"
import { TrainingData } from "../features/ModelTraining/components/TrainingData/TrainingData"
import { useDebounce } from "../hooks/useDebounce"
import { useHandlePageQueryParam } from "../hooks/useHandlePageQueryParam"
import { KonanAPI } from "../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../store/CurrentProjectAndModelContext"
import { ExportJobList } from "../types/generated/api/ExportJobList"
import { PaginatedDownloadExportJobList } from "../types/generated/api/PaginatedDownloadExportJobList"
import { PaginatedExportJobListList } from "../types/generated/api/PaginatedExportJobListList"
import { extractPageFromBackEndPaginationLink } from "../utils/genericHelpers"

enum FilterMap {
  "success" = "SUCCEEDED",
  "failed" = '["FAILED", "CANCELLED"]',
  "inProgress" = '["RUNNING", "PENDING"]',
}

/**
 * Export Jobs page
 *
 * @returns {React.ReactElement}
 */
function ExportJobs(): React.ReactElement {
  const { id: projectId } = useParams<{ id: string }>()

  // SSF states
  const [searchValue, setSearchValue] = useState<string>("")
  const [sortValue, setSortValue] = useState<string>("Most Recent")
  const [filterValue, setFilterValue] = useState<string>("success")

  // Side bar states
  const [exportJobName, setExportJobName] = useState<string | null>(null)
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false)

  // fetch export jobs
  const {
    data: ExportJobs,
    isLoading: isExportJobsLoading,
    isFetching: isExportJobsFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<AxiosResponse<PaginatedExportJobListList>, AxiosError>(
    ["data-files", projectId, filterValue, sortValue],
    ({ pageParam = 1 }) =>
      KonanAPI.listExportJobs({
        project_uuid: projectId as string,
        search: searchValue,
        status:
          filterValue === "success"
            ? FilterMap[filterValue]
            : JSON.parse(FilterMap[filterValue as keyof typeof FilterMap]),
        page: pageParam,
        page_size: 20,
        ordering: sortValue === "Most Recent" ? "-created_at" : "name",
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next ? extractPageFromBackEndPaginationLink(lastPage.data?.next) : false
      },
      enabled: !!projectId,
    },
  )

  // list download logs for export job
  const {
    refetch: refetchDownloadLogs,
    isLoading: isDownloadLogsLoading,
    data: downloadLogsData,
  } = useQuery<AxiosResponse<PaginatedDownloadExportJobList>, AxiosError>(
    ["retrieve-export-downloads", exportJobName],
    () =>
      KonanAPI.retrieveExportJobsDownloadLogs({ project_uuid: projectId as string, search: exportJobName as string }),
    {
      enabled: false,
    },
  )

  const adjustedDataFiles = useMemo(() => {
    const reducedDataFiles = ExportJobs?.pages.reduce((accumulator, page) => {
      return accumulator.concat(page.data.results as ExportJobList[])
    }, [] as ExportJobList[])

    return reducedDataFiles?.length && reducedDataFiles?.length > 0 ? reducedDataFiles : []
  }, [ExportJobs?.pages])

  const debouncedOnChange = useDebounce((): void => {
    refetch()
  }, 400)

  return (
    <Grid container item>
      {isExportJobsLoading ||
      isExportJobsFetching ||
      (!isExportJobsLoading &&
        ((ExportJobs?.pages[0]?.data?.count ?? 0) > 0 || !!searchValue || !!filterValue.length)) ? (
        <Fragment>
          {/* SSF */}
          <Grid container item mt={3}>
            <KonanTabsHeader
              title={"Data file"}
              containerType={"Data file"}
              setTabValue={setFilterValue}
              value={filterValue}
              setSearchValue={(value) => {
                setSearchValue(value)
                debouncedOnChange()
              }}
              searchValue={searchValue}
              sortValue={sortValue}
              sortOptions={["Most Recent", "Name"]}
              setSortValue={setSortValue}
            />
          </Grid>

          {/* Data files */}
          <Grid item xs={12}>
            {isExportJobsLoading ? (
              <Grid container spacing={2}>
                {[1, 2, 3].map((item) => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={item}>
                    <DataFileLoadingCard />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <InfiniteScroll
                dataLength={adjustedDataFiles?.length ?? 0}
                next={() => !isExportJobsLoading && !isFetchingNextPage && fetchNextPage()}
                hasMore={hasNextPage ?? false}
                loader={
                  <Grid item xs={12} container spacing={2}>
                    {[1, 2, 3].map((item: number) => (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={item} mt={2}>
                        <DataFileLoadingCard />
                      </Grid>
                    ))}
                  </Grid>
                }
              >
                <Grid container item xs={12} spacing={2}>
                  {adjustedDataFiles.length > 0 ? (
                    adjustedDataFiles?.map((job: ExportJobList) => (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={job.uuid}>
                        <DataFileCard
                          exportJob={job}
                          openDataFileHistory={(name: string) => {
                            setIsSideBarOpen(true)
                            setExportJobName(name)

                            setTimeout(() => {
                              refetchDownloadLogs()
                            }, 15)
                          }}
                        />
                      </Grid>
                    ))
                  ) : searchValue ? (
                    <KonanEmptyState
                      title={`No Results for '${searchValue?.charAt(0)?.toUpperCase() + searchValue?.slice(1)?.toLowerCase()}'`}
                      subTitle="Adjust the search value and try again"
                    />
                  ) : (
                    filterValue?.length > 0 && (
                      <KonanEmptyStateFilter
                        title={[
                          filterValue === "inProgress"
                            ? "In Progress"
                            : filterValue?.charAt(0)?.toUpperCase() + filterValue?.slice(1)?.toLowerCase(),
                        ]}
                      />
                    )
                  )}
                </Grid>
              </InfiniteScroll>
            )}
          </Grid>
        </Fragment>
      ) : (
        <KonanEmptyState title={"No data files available"} subTitle="Export data files to be able to view them" />
      )}

      <SideBar
        isOpen={isSideBarOpen}
        title={"Download history"}
        onClose={() => {
          setIsSideBarOpen(false)
          setExportJobName(null)
          queryClient.removeQueries({ queryKey: "retrieve-export-downloads" })
        }}
      >
        {isDownloadLogsLoading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: "100%" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container item xs={12} spacing={1.5}>
            {(downloadLogsData?.data.results ?? []).length > 0 ? (
              downloadLogsData?.data.results?.map((log) => (
                <Grid item xs={12} key={log.export_job.uuid}>
                  <KonanAvatarComponent
                    createdAt={log.downloaded_at}
                    createdBy={log.downloaded_by}
                    title={log.downloaded_by}
                    noWrap={false}
                  />
                </Grid>
              ))
            ) : (
              <Grid container item xs={12} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                <KonanEmptyState title={`No download history available for ${exportJobName}.`} />
              </Grid>
            )}
          </Grid>
        )}
      </SideBar>
    </Grid>
  )
}

/**
 * Data files page
 * home to Konan Model Training
 * Export Jobs, Training Data
 *
 * @return {React.ReactElement}
 */
export function DataFiles(): React.ReactElement {
  const [value, setTabValue] = useHandlePageQueryParam(["Export-data-files", "Training-data"], "Export-data-files")
  const { currentProject } = useContext(CurrentProjectAndModelContext)

  return (
    <Grid container gap={1}>
      {currentProject?.category !== "workflow" && (
        <Grid item xs={12}>
          <Tabs value={value} className="base-tabs">
            <Tab
              icon={() => <FolderOutlinedIcon fontSize="small" />}
              label="Export Jobs Files"
              value={"Export-data-files"}
              selected={value === "Export-data-files"}
              onClick={() => setTabValue("Export-data-files")}
            />
            <Tab
              icon={() => <AssignmentOutlinedIcon fontSize="small" />}
              label="Training Data"
              value="Training-data"
              selected={value === "Training-data"}
              onClick={() => setTabValue("Training-data")}
            />
          </Tabs>
        </Grid>
      )}

      <Grid item xs={12}>
        {value === "Export-data-files" ? <ExportJobs /> : <TrainingData />}
      </Grid>
    </Grid>
  )
}
