import React from "react"

import { Divider, Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "../../DataMonitor.module.scss"

type Props = {
  name: string
  value?: number | string
  precision?: number
  tooltip?: string
}

export function StatisticsRow(props: Readonly<Props>): React.ReactElement {
  const { name, value, precision, tooltip } = props

  return (
    <React.Fragment>
      <Grid container item>
        <Grid item xs={6}>
          <Grid container item direction="row" justifyContent="flex-start">
            <Typography variant="label" tooltip={tooltip} tooltipVerticalAlign="middle" tooltipIconSize={16}>
              {name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="label">
            {typeof value === "number" && precision ? value.toFixed(precision) : value}
          </Typography>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" color="textPrimary" variant="fullWidth" className={styles.statDivider} />
    </React.Fragment>
  )
}
