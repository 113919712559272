import React, { Fragment } from "react"

import { Grid } from "@mui/material"
import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import { useHeaderData } from "../hooks/useHeaderData"

import styles from "./PageHeader.module.scss"

export function PageHeader(): React.ReactElement {
  const headerData = useHeaderData()

  return (
    <Fragment>
      {headerData?.dialog && headerData.dialog}

      {headerData && (
        <Grid
          container
          className={styles.header}
          borderTop={headerData?.hasBorder ? "4px solid var(--blue-text-2)" : "4px solid transparent"}
        >
          <Grid display="flex" item flexGrow={1} xs alignItems="center">
            {headerData?.icon ? (
              <div className={styles.headerIcon}>{headerData?.icon}</div>
            ) : (
              <Grid item mr={1}>
                <Skeleton variant="circular" width="48px" height="48px" />
              </Grid>
            )}

            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent={headerData?.description ? "space-between" : "center"}
              xs={12}
            >
              {headerData?.tag && (
                <Grid item container alignItems="center">
                  <Grid item mr={1}>
                    {headerData.tag}
                  </Grid>
                  {headerData?.createdAt && headerData?.createdAt}
                </Grid>
              )}

              <Grid item display="flex" xs={12}>
                {headerData?.title ? (
                  <Grid item alignSelf={"center"}>
                    <Typography variant="h2-bold">{headerData?.title}</Typography>
                  </Grid>
                ) : (
                  <Skeleton variant="rectangular" width="120px" height={"20px"} />
                )}
              </Grid>

              {!headerData?.title && (
                <Grid item xs={12} mt={1}>
                  <Skeleton variant="rectangular" width="120px" height={"20px"} />
                </Grid>
              )}

              {headerData?.description && (
                <Grid item xs={12} lg={6.5}>
                  <Typography variant="p">{headerData?.description}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {headerData?.actionButtons && headerData?.actionButtons?.length > 0 && (
            <Grid item display="flex" alignItems="center" marginTop={{ xs: 1.5, lg: 0 }}>
              {headerData?.actionButtons?.map((action, index: number) => {
                return (
                  <Grid item key={index} ml={1}>
                    {action}
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  )
}
