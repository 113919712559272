/* generated using openapi-typescript-codegen -- do not edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { TableColumnConfigurationRequest } from "./TableColumnConfigurationRequest"

export type TableConfigurationRequest = {
  column_configs: Array<TableColumnConfigurationRequest>
  /**
   * * `live-decisions` - Live Decisions
   * * `live-monitoring` - Live Monitoring
   * * `api-requests` - Api Requests
   * * `automl-config` - Automl Configuration
   * * `ekyc-session` - EKYC session
   * * `simulation-output` - Simulation Output
   * * `workflow-schema` - Workflow Schema
   * * `model-comparison` - Model Comparison
   */
  table: TableConfigurationRequest.table
}
export namespace TableConfigurationRequest {
  /**
   * * `live-decisions` - Live Decisions
   * * `live-monitoring` - Live Monitoring
   * * `api-requests` - Api Requests
   * * `automl-config` - Automl Configuration
   * * `ekyc-session` - EKYC session
   * * `simulation-output` - Simulation Output
   * * `workflow-schema` - Workflow Schema
   * * `model-comparison` - Model Comparison
   */
  export enum table {
    LIVE_DECISIONS = "live-decisions",
    LIVE_MONITORING = "live-monitoring",
    API_REQUESTS = "api-requests",
    AUTOML_CONFIG = "automl-config",
    EKYC_SESSION = "ekyc-session",
    SIMULATION_OUTPUT = "simulation-output",
    WORKFLOW_SCHEMA = "workflow-schema",
    MODEL_COMPARISON = "model-comparison",
  }
}
