// Adapted from https://github.com/plouc/nivo/blob/master/website/src/theming/nivo.js
// Should be added to theme prop of any nivo chart
// https://github.com/plouc/nivo/issues/308

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function NivoTheme(theme: any): any {
  return {
    crosshair: {
      line: {
        stroke: "#ccd7e2",
      },
    },
    axis: {
      domain: {
        line: {
          strokeWidth: 0,
          stroke: theme.palette.grayscale.border,
        },
      },
      ticks: {
        line: {
          strokeWidth: 1,
          stroke: theme.palette.grayscale.border,
        },
        text: {
          fill: theme.palette.grayscale.text[2],
          fontSize: 12,
          fontWeight: 400,
        },
      },
      legend: {
        text: {
          fill: theme.palette.grayscale.text[2],
          fontSize: 13,
          fontWeight: 500,
        },
      },
    },
    grid: {
      line: {
        stroke: theme.palette.grayscale.border,
      },
    },
    legends: {
      text: {
        fontSize: 12,
        fill: theme.palette.grayscale.text[2],
      },
    },
    labels: {
      text: {
        fill: "#ffffff",
        fontSize: 12,
        fontWeight: 500,
      },
    },
    dots: {
      text: {
        fill: theme.palette.grayscale.text[2],
        fontSize: 12,
      },
    },
  }
}
