import React from "react"

import { useQuery } from "react-query"

import * as OpenAPISnippet from "openapi-snippet"
import { Box, Card, Grid, Link } from "@mui/material"
import Hidden from "@mui/material/Hidden"
import { Skeleton, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { OpenAPIV3 } from "openapi-types"

import { MonacoTextEditor } from "../../../components/MonacoTextEditor"
import { KonanAPI } from "../../../services/KonanAPI"
import {
  evaluateCodeSnippet,
  evaluateDocStrings,
  feedbackCodeSnippet,
  feedbackDocStrings,
  loginCodeSnippet,
  loginDocStrings,
  predictCodeSnippet,
  predictDocStrings,
  refreshDocsStrings,
} from "./CodeSnippets"

import styles from "../Integration.module.scss"

type AnchorProps = {
  href: string
  text: string
  id?: string
  selected?: boolean
  small?: boolean
}

type Props = {
  UUID: string
  lang: string
}

type SectionInfo = {
  endpoint: string
  docString: string
  snippet: string
}

// simple component for rendering Anchor/titles for different sections
function Anchor(props: Readonly<AnchorProps>): React.ReactElement {
  const { href, text, selected = false, small = false, id } = props

  return (
    <a id={id ? id : ""} href={href} className={selected && small ? styles.anchorSmall : styles.anchorBase}>
      <Typography variant={small ? "p" : "h2-bold"} gutterBottom={!small}>
        {text}
      </Typography>
    </a>
  )
}

// dict to map between languages names and the name used in snippets package
const dict = {
  python: "python",
  nodejs: "javascript",
  csharp: "csharp",
  php: "php",
  java: "java",
  go: "go",
  ruby: "ruby",
  shell: "shell",
  r: "r",
}

// container component for each section with the codeBlock for it
const ExampleSection = (props: {
  lang: Props["lang"]
  endpointName: string
  docString: string
  snippet?: string
}): React.ReactElement => {
  const { lang, endpointName, docString, snippet } = props

  return (
    <React.Fragment>
      <Anchor
        id={endpointName}
        href={`#${endpointName}`}
        text={endpointName[0].toUpperCase() + endpointName.slice(1)}
      />

      <Typography variant="p" className={styles.docString} gutterBottom>
        {docString}
      </Typography>
      <Box className={styles.codeBox}>
        <MonacoTextEditor
          value={snippet}
          language={lang === "nodejs" ? "javascript" : lang}
          automaticLayout
          height="100px"
          readOnly
          loading={
            <Box width={"100%"}>
              <Box mb={1}>
                <Skeleton height={15} width={"90%"} />
              </Box>
              <Box mb={1}>
                <Skeleton height={15} width={"60%"} />
              </Box>
              <Box mb={1}>
                <Skeleton height={15} width={"80%"} />
              </Box>
            </Box>
          }
        />
      </Box>
    </React.Fragment>
  )
}

/**
 * Component to handle all the different integration snippets based on the selected language
 * @param {string} UUID project UUID
 * @param {string} lang selected programming language
 * @returns {React.ReactElement}
 */
export function IntegrationLanguage(props: Readonly<Props>): React.ReactElement {
  const { UUID, lang } = props

  const email = localStorage.getItem("email")

  const loginSnippet = email ? loginCodeSnippet.replace("<email>", email) : loginCodeSnippet
  const predictSnippet = (email ? predictCodeSnippet.replace("<email>", email) : predictCodeSnippet).replace(
    "<project-uuid>",
    UUID,
  )
  const feedbackSnippet = (email ? feedbackCodeSnippet.replace("<email>", email) : feedbackCodeSnippet).replace(
    "<project-uuid>",
    UUID,
  )
  const evaluateSnippet = (email ? evaluateCodeSnippet.replace("<email>", email) : evaluateCodeSnippet).replace(
    "<project-uuid>",
    UUID,
  )

  const { data: response } = useQuery<AxiosResponse<OpenAPIV3.Document>, AxiosError>(
    ["project-docs", UUID],
    () => KonanAPI.fetchProjectDocs(UUID),
    {
      retry: false,
    },
  )
  const openApi = response?.data
  type ObjectKey = keyof typeof dict
  const target = [dict[lang as ObjectKey]]

  // init snippets
  let otherLoginSnippet = ""
  let otherPredictSnippet = ""
  let otherRefreshSnippet = ""
  let otherFeedbackSnippet = ""
  let otherEvaluateSnippet = ""

  try {
    //login snippet
    otherLoginSnippet = OpenAPISnippet.getEndpointSnippets(openApi, "/api/auth/login/", "post", target).snippets[0]
      .content

    //refresh snippet
    otherRefreshSnippet = OpenAPISnippet.getEndpointSnippets(openApi, "/api/auth/token/refresh/", "post", target)
      .snippets[0].content

    // prediction snippet
    otherPredictSnippet = OpenAPISnippet.getEndpointSnippets(openApi, `/projects/${UUID}/predict/`, "post", target)
      .snippets[0].content

    // feedback snippet
    otherFeedbackSnippet = OpenAPISnippet.getEndpointSnippets(
      openApi,
      `/projects/${UUID}/predictions/feedback/`,
      "post",
      target,
    ).snippets[0].content

    // evaluate snippet
    otherEvaluateSnippet = OpenAPISnippet.getEndpointSnippets(openApi, `/projects/${UUID}/evaluate/`, "post", target)
      .snippets[0].content
  } catch (err) {
    JSON.stringify(err)
  }

  const sectionsInfo: Array<SectionInfo> = [
    {
      endpoint: "login",
      docString: loginDocStrings,
      snippet: lang === "python" ? loginSnippet : otherLoginSnippet,
    },
    {
      endpoint: "refresh",
      docString: refreshDocsStrings,
      snippet: otherRefreshSnippet,
    },
    {
      endpoint: "predict",
      docString: predictDocStrings,
      snippet: lang === "python" ? predictSnippet : otherPredictSnippet,
    },
    {
      endpoint: "feedback",
      docString: feedbackDocStrings,
      snippet: lang === "python" ? feedbackSnippet : otherFeedbackSnippet,
    },
    {
      endpoint: "evaluate",
      docString: evaluateDocStrings,
      snippet: lang === "python" ? evaluateSnippet : otherEvaluateSnippet,
    },
  ]

  return (
    <React.Fragment>
      {lang !== "python" && (
        <Grid container item xs={12} sx={{ marginBottom: "8px" }}>
          <Grid item md={9} xs={12}>
            <Snackbar variant="warning" fullWidth id="snackbar-auth">
              Authentication is done through{" "}
              <Link href="https://jwt.io/introduction" target="_blank" underline="hover">
                JSON Web Tokens
              </Link>
              . This requires an <strong>access token</strong> to be embedded in any requests being sent.
              <br />
              <br />
              To retrieve the <strong>access token</strong>, you'll need to use the login endpoint. The{" "}
              <strong>access token</strong> expires every <strong>5 minutes</strong>.
              <br />
              To refresh the <strong>access token</strong>, you'll need to use the refresh endpoint. The{" "}
              <strong>refresh token</strong> expires every <strong>24 hours</strong>. After that you'll have to login
              again.
              <br />
              <br />
              To be able to use Konan's endpoint without interruption, you'll have to implement the above logic. A{" "}
              <Link
                href="https://github.com/SynapseAnalytics/konan-sdk/blob/e7a20840b2d504900954fad4b3397fd657febece/konan_sdk/auth.py#L30"
                target="_blank"
                underline="hover"
              >
                sample implementation{" "}
              </Link>
              of this logic can be found in Konan's Python SDK.
            </Snackbar>
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item md={9} xs={12} className={styles.itemBox}>
          {lang === "python" && (
            <React.Fragment>
              <Grid item xs={12}>
                <Anchor id="installation" href="#installation" text="Installation" />

                <Box className={styles.codeBox}>
                  <MonacoTextEditor
                    automaticLayout
                    value="pip install konan-sdk"
                    language="python"
                    loading={
                      <Box width={"100%"}>
                        <Skeleton height={15} width={"90%"} />
                      </Box>
                    }
                  />
                </Box>
              </Grid>
              <Box mb={2} />
            </React.Fragment>
          )}

          {sectionsInfo.map((section) => {
            if (lang === "python" && section.endpoint === "refresh") {
              /* Refresh Section, only enabled when not viewing SDK*/
              return ""
            } else {
              return (
                <React.Fragment key={section.endpoint}>
                  <Grid item xs={12}>
                    <ExampleSection
                      lang={lang}
                      endpointName={section.endpoint}
                      docString={section.docString}
                      snippet={section.snippet}
                    />
                  </Grid>
                  <Box mb={2} />
                </React.Fragment>
              )
            }
          })}
        </Grid>

        {/* Hide sidebar when viewing on mobile */}
        <Hidden mdDown implementation="js">
          <Grid item md={3} display="sticky" top={0}>
            <Card className={styles.sideBar}>
              <Grid container direction="column">
                <Grid item>
                  {lang === "python" && (
                    <Anchor id="installation-anchor" href="#installation" text="Installation" small />
                  )}
                  <Anchor href="#login" text="Login" small />
                  {lang !== "python" && <Anchor id="refresh-anchor" href="#refresh" text="Refresh" small />}
                  <Anchor href="#predict" text="Predict" small />
                  <Anchor href="#feedback" text="Feedback" small />
                  <Anchor href="#evaluate" text="Evaluate" small />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Hidden>
      </Grid>
    </React.Fragment>
  )
}
