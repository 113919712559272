import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { Role } from "../../types/generated/authentication/Role"
import { UserManagementLog } from "../../types/generated/authentication/UserManagementLog"

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    roles: [] as Role[],
    history: [] as UserManagementLog[],
  },
  reducers: {
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload
    },
    setHistory: (state, action: PayloadAction<UserManagementLog[]>) => {
      state.history = action.payload
    },
  },
})

export const { setRoles, setHistory } = rolesSlice.actions
