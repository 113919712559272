/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Serializes RetrainingJobs made directly on a prebuilt model rather than active live model.
 */
export type TrainingJob = {
  readonly created_by: string
  readonly training_data: string
  readonly metrics: Record<string, any> | null
  readonly test_percentage: number | null
  readonly train_percentage: number | null
  readonly eval_percentage: number | null
  readonly resulting_model: string | null
  readonly uuid: string
  readonly created_at: string
  /**
   * Represents the time the retraining script was started
   */
  readonly started_at: string | null
  /**
   * Represents the time the retraining job finished
   */
  readonly ended_at: string | null
  /**
   * Represents the time the retraining job was cancelled
   */
  readonly cancelled_at: string | null
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   * * `TIMEOUT` - Timeout
   */
  readonly status: TrainingJob.status
  resulting_model_name?: string
  /**
   * * `live` - Live
   * * `challenger` - Challenger
   * * `disabled` - Disabled
   * * `pending-action` - Pending Action
   */
  resulting_model_state?: TrainingJob.resulting_model_state
  readonly duration: string | null
  /**
   * * `custom` - Custom
   * * `prebuilt` - Prebuilt
   * * `automl` - AutoML
   */
  resulting_model_type?: TrainingJob.resulting_model_type
}
export namespace TrainingJob {
  /**
   * * `PENDING` - Pending
   * * `RUNNING` - Running
   * * `SUCCEEDED` - Succeeded
   * * `FAILED` - Failed
   * * `CANCELLED` - Cancelled
   * * `TIMEOUT` - Timeout
   */
  export enum status {
    PENDING = "PENDING",
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED",
    TIMEOUT = "TIMEOUT",
  }
  /**
   * * `live` - Live
   * * `challenger` - Challenger
   * * `disabled` - Disabled
   * * `pending-action` - Pending Action
   */
  export enum resulting_model_state {
    LIVE = "live",
    CHALLENGER = "challenger",
    DISABLED = "disabled",
    PENDING_ACTION = "pending-action",
  }
  /**
   * * `custom` - Custom
   * * `prebuilt` - Prebuilt
   * * `automl` - AutoML
   */
  export enum resulting_model_type {
    CUSTOM = "custom",
    PREBUILT = "prebuilt",
    AUTOML = "automl",
  }
}
