import React from "react"

import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined"
import { Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import { DataBlock } from "../Ruleset/components/RuleCard"
import { ScorecardExampleProps } from "./Interfaces"

import styles from "./ScorecardExample.module.scss"

/**
 * Unit example of single scorecard, used in scorecard evaluation explanation dialog
 * @param {boolean} isNormalized indicator if this scorecard has normalized evaluation
 * @returns {React.ReactElement}
 */
function Example(props: Readonly<{ isNormalized: boolean }>): React.ReactElement {
  const { isNormalized } = props

  return (
    <Grid container className={styles.scorecard}>
      <Grid item xs={9.8} className={styles.conditionsContainer}>
        <Grid item className={styles.exampleHeader} padding={1.5}>
          <DataBlock value="Accident Risk" textVariant="p" bgVariant="neutral" />
        </Grid>
        <Grid
          item
          justifyContent="space-between"
          flexDirection="column"
          padding={1.5}
          display="flex"
          alignItems="space-between"
          height="137px"
        >
          <Grid item display="flex" xs={12} justifyContent="space-between" mb={1.4}>
            <Grid item>
              <DataBlock value="Age" textVariant="p" bgVariant="neutral" />
            </Grid>

            <Grid item>
              <DataBlock value="between" textVariant="p" bgVariant="gray" />
            </Grid>

            <Grid item>
              <DataBlock value="26" textVariant="p" bgVariant="neutral" />
            </Grid>

            <Grid item alignSelf={"center"}>
              <Typography variant="p">and</Typography>
            </Grid>

            <Grid item>
              <DataBlock value="35" textVariant="p" bgVariant="neutral" />
            </Grid>

            <Grid item alignSelf={"center"}>
              <Typography variant="p">=</Typography>
            </Grid>

            <Grid item minWidth={"45px"}>
              <DataBlock value="0" textVariant="p" bgVariant="neutral" />
            </Grid>
          </Grid>

          <Grid item display="flex" xs={12} justifyContent="space-between" mb={1.4}>
            <Grid item>
              <DataBlock value="Age" textVariant="p" bgVariant="neutral" />
            </Grid>

            <Grid item>
              <DataBlock value="between" textVariant="p" bgVariant="gray" />
            </Grid>

            <Grid item>
              <DataBlock value="19" textVariant="p" bgVariant="neutral" />
            </Grid>

            <Grid item alignSelf={"center"}>
              <Typography variant="p">and</Typography>
            </Grid>

            <Grid item>
              <DataBlock value="25" textVariant="p" bgVariant="neutral" />
            </Grid>

            <Grid item alignSelf={"center"}>
              <Typography variant="p">=</Typography>
            </Grid>

            <Grid item minWidth={"45px"}>
              <DataBlock
                value={isNormalized ? "1" : "0.01"}
                variantColor={2}
                textColor="negative"
                textVariant="p"
                bgVariant="neutral"
              />
            </Grid>
          </Grid>

          <Grid item display="flex" xs={12} justifyContent="space-between">
            <Grid item>
              <DataBlock value="Age" textVariant="p" bgVariant="neutral" />
            </Grid>

            <Grid item minWidth={"73.5px"}>
              <DataBlock value="equal" textVariant="p" bgVariant="gray" />
            </Grid>

            <Grid item minWidth={"101px"}>
              <DataBlock value="18" textVariant="p" bgVariant="neutral" />
            </Grid>

            <Grid item alignSelf={"center"}>
              <Typography variant="p">=</Typography>
            </Grid>

            <Grid item minWidth={"45px"}>
              <DataBlock
                value={isNormalized ? "2" : "0.02"}
                textColor={isNormalized ? "positive" : "neutral"}
                variantColor={isNormalized ? 2 : 1}
                textVariant="p"
                bgVariant="neutral"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2.2} className={styles.weightContainer}>
        <DataBlock value="100" textVariant="p" variantColor={2} textColor="important" />
      </Grid>
    </Grid>
  )
}

/**
 * Simulation Flow for a scorecard from receiving an input then processing w/different output
 * based on equation method selected (default, divide by max value)
 * @param {boolean} isEquationNormalized indicator for the normalization method selected or not
 * @returns {React.ReactElement}
 */
export function ScorecardExample(props: Readonly<ScorecardExampleProps>): React.ReactElement {
  const { isEquationNormalized = false } = props

  return (
    <Grid container>
      {/* Input*/}
      <Grid item xs className={styles.blocksContainer}>
        <Grid item width="fit-content" mr={1}>
          <DataBlock value="INPUT" textVariant="a" />
        </Grid>
        <Grid item width="fit-content" mr={1}>
          <DataBlock value="Age" textVariant="p" bgVariant="neutral" />
        </Grid>
        <Grid item mr={1}>
          <Typography variant="p">=</Typography>
        </Grid>
        <Grid item width="fit-content">
          <DataBlock value="24" textVariant="p" bgVariant="neutral" />
        </Grid>
      </Grid>

      <Grid item display="flex" xs={12} justifyContent="center">
        <ArrowDownwardOutlinedIcon sx={{ color: "var(--neutral-background-disabled)" }} />
      </Grid>

      {/* Scorecard Example */}
      <Example isNormalized={isEquationNormalized} />

      <Grid item display="flex" xs={12} justifyContent="center">
        <ArrowDownwardOutlinedIcon sx={{ color: "var(--neutral-background-disabled)" }} />
      </Grid>

      {/* Output*/}
      <Grid item xs className={styles.blocksContainer}>
        <Grid item width="fit-content" mr={1}>
          <DataBlock value="OUTPUT" textVariant="a" />
        </Grid>
        <Grid item width="fit-content" mr={1}>
          <DataBlock
            value={
              isEquationNormalized ? (
                <Typography variant="p">
                  (<span style={{ color: "var(--red-text-2)" }}>1</span>/
                  <span style={{ color: "var(--green-text-2)" }}>2</span>) *{" "}
                  <span style={{ color: "var(--blue-text-2)" }}>100</span>
                </Typography>
              ) : (
                <Typography variant="p">
                  <span style={{ color: "var(--red-text-2)" }}>0.01</span> *{" "}
                  <span style={{ color: "var(--blue-text-2)" }}>100</span>
                </Typography>
              )
            }
            bgVariant="neutral"
          />
        </Grid>
        <Grid item mr={1}>
          <Typography variant="p">=</Typography>
        </Grid>
        <Grid item width="fit-content">
          <DataBlock value={isEquationNormalized ? "50" : "1"} textVariant="p" bgVariant="neutral" />
        </Grid>
      </Grid>
    </Grid>
  )
}
