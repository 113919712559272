import React from "react"

import { Box, Grid, Skeleton } from "@mui/material"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format, formatDistance } from "date-fns"

import { GraphHeader } from "./GraphHeader"

type Props = {
  date?: Date | undefined
  cardHeight: number
  isLoading: boolean
}

export function KonanLastFeedbackCard(props: Props): React.ReactElement {
  const { date, cardHeight, isLoading } = props

  return (
    <div className={"charts-paper"}>
      <GraphHeader
        title={"LAST FEEDBACK"}
        tooltipText="Last data the model received the actual results to the historically sent predictions"
      />

      <Box style={{ height: cardHeight, padding: "4px 16px 8px 16px" }}>
        {!date && !isLoading ? (
          // empty state
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            className={"full-height"}
          >
            <Typography variant="p" variantColor={2}>
              No data to display
            </Typography>
          </Grid>
        ) : isLoading ? (
          // loading state
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className={"full-height"}
          >
            <Skeleton animation="wave" width="50%" height="10%" />
          </Grid>
        ) : (
          <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: cardHeight }}>
            <Grid container direction="row" justifyContent="center" alignItems="center" marginTop={1}>
              <Typography variant="label" style={{ width: "100%", textAlign: "center" }}>
                <Tooltip
                  title={
                    <Typography variant="h3-regular" variantColor={2}>
                      {date && format(date, "dd/MM/yyyy, p")}{" "}
                    </Typography>
                  }
                  placement="top"
                >
                  <Typography variant="h1-bold" style={{ height: "100%", textAlign: "center" }}>
                    {date &&
                      formatDistance(date, new Date(), {
                        addSuffix: true,
                      })}
                  </Typography>
                </Tooltip>
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  )
}
