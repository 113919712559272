import React from "react"

import { Card, Grid } from "@mui/material"
import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import styles from "../Models.module.scss"

/**
 * Model loading state component
 * @return {React.ReactElement}
 */
export function ModelLoadingComponent(): React.ReactElement {
  return (
    <Card className={"card-box-shadow"}>
      <Grid container direction="column" className={styles.headerContainer}>
        {/* Tag section */}
        <Grid container item xs={12}>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={3} sx={{ marginTop: "4px" }}>
              <Typography variant="p" noWrap>
                <Skeleton variant="rectangular" height={15} width={"80%"} />
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={1}>
            <Grid item xs={6} className={styles.name}>
              <Typography variant="p" noWrap>
                <Skeleton variant="rectangular" height={15} width={"60%"} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Avatar section */}
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center" className={styles.avatarContainer}>
            <Grid item sx={{ marginTop: "10px" }}>
              <Skeleton variant="circular" width={32} height={32} />
            </Grid>

            <Grid direction="column" container item style={{ marginTop: "8px" }}>
              <Grid item xs={6} sx={{ marginBottom: "6px", marginTop: "10px" }}>
                <Typography variant="p" noWrap>
                  <Skeleton variant="rectangular" height={10} width={"80%"} />
                </Typography>
              </Grid>

              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <Typography variant="span" noWrap>
                  <Skeleton variant="rectangular" height={10} width={"30%"} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Evaluation metrics section */}
      <Grid container direction="column" className={styles.bodyContainer}>
        <Grid container item direction="row">
          <Grid item xs={12}>
            <Grid item container direction="row">
              <Typography variant="label" className={styles.labelColor}>
                Evaluation Metrics
              </Typography>
            </Grid>

            <Grid container direction="row" spacing={1}>
              {[1, 2, 3].map((_, index: number) => (
                <Grid item xs={4} key={index}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="flex-start"
                    className={styles.dataCard}
                  >
                    <Skeleton height={10} width={"100%"} />
                    <Skeleton height={10} width={"100%"} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Training data section */}
          <Grid item xs={12}>
            <Grid item container direction="row" marginTop={1.5}>
              <Typography variant="label" className={styles.labelColor}>
                Training Data
              </Typography>
            </Grid>
            <Grid container>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                item
                xs={12}
                className={styles.trainingBox}
              >
                <Grid item xs={2}>
                  <Skeleton height={15} width={"100%"} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
