import React, { Dispatch, SetStateAction } from "react"

import { CardActionArea, Grid } from "@mui/material"
import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "./UseCaseCard.module.scss"

type Props = {
  name: string
  description: string
  selected?: boolean
  onClick: () => void
  disabled?: boolean
  creditScoringType?: string
  setCreditScoringType?: Dispatch<SetStateAction<string>>
}

/**
 * Text card
 * @param  {string} name use-case name
 * @param  {string} description use-case description
 * @param  {boolean} selected selected mode
 * @param  {function} onClick function to fire up on card click
 * @param  {boolean} disabled option to disable card
 * @param  {string} creditScoringType type of CS project (automl/pre-built)
 * @param  {function} setCreditScoringType setter for the CS project type state
 * @return  {React.ReactElement}
 */
export function UseCaseCard(props: Props): React.ReactElement {
  const { name, description, selected, onClick, disabled = false } = props

  return (
    <CardActionArea disabled={disabled} onClick={onClick} className={`${styles.card} ${selected && styles.activeCard}`}>
      <Grid container display="flex" flexDirection="row" width={"100%"}>
        <Grid p={1} container item xs={12}>
          {/* UseCase title */}
          <Typography variant="h3-bold">{name}</Typography>

          {/* UseCase description */}
          <Grid item xs={12} mt={1}>
            <Typography variant="p" disabled={disabled}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CardActionArea>
  )
}
