import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { OrganizationInvitation } from "../../types/generated/authentication/OrganizationInvitation"
import { OrganizationUser } from "../../types/generated/authentication/OrganizationUser"

export const membersSlice = createSlice({
  name: "members",
  initialState: {
    members: [] as OrganizationUser[],
    pendingMembers: [] as OrganizationInvitation[],
  },
  reducers: {
    setMembers: (state, action: PayloadAction<OrganizationUser[]>) => {
      state.members = action.payload
    },
    setPendingMembers: (state, action: PayloadAction<OrganizationInvitation[]>) => {
      state.pendingMembers = action.payload
    },
  },
})

export const { setMembers, setPendingMembers } = membersSlice.actions
