import { FormikProps } from "formik"

import { ScriptGroupList } from "../../types/generated/api/ScriptGroupList"
import { ScriptGroupRetrieve } from "../../types/generated/api/ScriptGroupRetrieve"

export enum ScriptTab {
  TestFeatures = "Test Features",
  ScriptCode = "Script Code",
  Logs = "Historical Logs",
  RequirementsPackages = "Requirements Packages",
  // Used in Built-in scripts
  ScriptSetup = "Script Setup",
}

export interface MenuOption {
  title: string
  variant?: "negative" | "neutral"
  onClick: () => void
}

export interface ScriptCardProps {
  script: ScriptGroupList
  MenuOptions?: MenuOption[]
  onLogsDialogClick: () => void
  onNameClick: () => void
}

export enum StatusColorMap {
  PENDING = "default",
  RUNNING = "warning",
  SUCCEEDED = "positive",
  FAILED = "negative",
  CANCELLED = "negative",
}

export enum StatusTextMap {
  PENDING = "Pending",
  RUNNING = "Building",
  SUCCEEDED = "Succeeded",
  FAILED = "Failed",
  CANCELLED = "Cancelled",
}

export interface BuiltinCreationStepProps {
  onTemplateSelect: (templateId: string) => void
  onClose: () => void
  onUseTemplateClick: () => void
  selectedTemplate: string
  scripts?: ScriptGroupList[]
}

export interface LogsDialog {
  isOpen: boolean
  onClose: () => void
  uuid: string
}

export interface TemplateCardProps {
  description?: string
  isInUse?: boolean
  name: string
  isSelected: boolean
  onSelect: () => void
}

export interface SingleScriptProps {
  onClose: () => void
  scriptData?: ScriptGroupRetrieve
  isScriptLoading: boolean
  isWorkFlowInReadMode?: boolean | null
  isNewScriptTriggered?: boolean
  isDuplicate?: boolean
  renderView?: "workflow" | "scripts"
  openInEditMode?: boolean
  tab?: ScriptTab
  isVersionDialog?: boolean
  setIsVersionDialog?: () => void
}

export interface ScriptDialogProps
  extends Omit<SingleScriptProps, "isVersionDialog" | "scriptData" | "setIsVersionDialog" | "isScriptLoading"> {
  isOpen: boolean
  scriptId?: string
}

export interface ScriptEditorProps {
  isLoading: boolean
  isRefetchingFile: boolean
  script?: ScriptGroupRetrieve
  isReadMode: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>
  consoleLogs: string[]
  isFetchingFileError: boolean
  logs: string[]
  isConsoleExpanded: boolean
  setIsConsoleExpanded: () => void
  setIsLogsExpanded: () => void
  isLogsExpanded: boolean
  isActionButtonDisabled: boolean
  onActionButtonClick: () => void
  isBuildingState?: boolean
}

export interface RequirementsDataFile {
  label?: string
  value?: string | null | undefined
  uuid?: string | undefined
  createdAt?: string | undefined
  // to handle selection of requirement file in grouped select with search
  index?: number | undefined | null
}

export interface RequirementsListProps {
  requirements: RequirementsDataFile[]
  setRequirementsFile: (file: RequirementsDataFile) => void
  requirementsFile: RequirementsDataFile
  isLoading: boolean
}
