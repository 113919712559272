import React, { useContext, useEffect } from "react"

import { useQuery } from "react-query"
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom"

import { Grid } from "@mui/material"
import { AxiosError, AxiosResponse } from "axios"

import { KonanClassificationAlert } from "../components/KonanClassificationAlert"
import { ClassificationMetrics } from "../features/ClassificationMetrics/ClassificationMetrics"
import { ModelApiMonitor } from "../features/ModelApiMonitor/ModelApiMonitor"
import { DataDriftView } from "../features/ModelDrift/DataDriftView"
import { ProjectLogsView } from "../features/ModelLogs/ModelLogs"
import { KonanAPI } from "../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../store/CurrentProjectAndModelContext"
import { Deployment } from "../types/generated/api/Deployment"
import { ListClassificationModelOutputs } from "../types/generated/api/ListClassificationModelOutputs"
import { Model } from "../types/generated/api/Model"

type ParamsType = {
  id: string
}

export function ModelDetails(): React.ReactElement {
  const { id: projectId } = useParams<ParamsType>()
  const location = useLocation()
  const navigate = useNavigate()
  const { currentProject, setCurrentProject, currentModel, setCurrentModel } = useContext(CurrentProjectAndModelContext)

  // Fetch projects on mount
  const { data: response } = useQuery<AxiosResponse<Array<Deployment>>, AxiosError>(["projects"], () =>
    KonanAPI.fetchProjects(),
  )

  const { data: models } = useQuery<AxiosResponse<Array<Model>>, AxiosError>(["models", projectId], () =>
    KonanAPI.fetchModels(projectId as string),
  )

  const { data: activeConfiguration, isLoading: isActiveLoading } = useQuery<
    AxiosResponse<ListClassificationModelOutputs>,
    AxiosError
  >(["activeConfig", currentModel], () => KonanAPI.fetchActiveModelConfiguration(currentModel), {
    enabled: currentModel !== "" && !!currentProject && currentProject.type !== "generic",
    retry: false,
  })

  useEffect(() => {
    // Add current project's data to our context provider
    if (response?.data && response?.data.length) {
      setCurrentProject(response?.data?.find((project) => project.uuid === projectId))
    }
    if (currentModel === "") {
      if (models?.data && models?.data.length > 0) {
        //Get live model UUID on mount, if there is no live model -> Get first model
        setCurrentModel(models?.data?.find((model) => model.state === "live")?.uuid ?? models?.data[0].uuid)
        //Get live model UUID on mount, if there is no live model -> Get first model
        setCurrentModel(models?.data?.find((model) => model.state === "live")?.uuid ?? models?.data[0].uuid)
      }
    }
    if (models?.data?.length === 0 && currentModel === "") {
      navigate(`/projects/${projectId}/overview`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModel, projectId, models, response, setCurrentProject, setCurrentModel])

  return (
    <div className="layoutPadding">
      {currentProject?.type !== "generic" &&
        !location.pathname.endsWith("/classification") &&
        !isActiveLoading &&
        !activeConfiguration &&
        currentModel && (
          <Grid marginBottom={2}>
            <KonanClassificationAlert id={projectId as string} />
          </Grid>
        )}

      <Routes>
        <Route key="model-api-requests" path={`requests`} element={<ModelApiMonitor />} />
        {currentProject?.type !== "generic" && (
          <Route key="model-api-requests" path={`classification`} element={<ClassificationMetrics />} />
        )}
        <Route key="data-drift" path={`drift`} element={<DataDriftView />} />
        <Route key="model-logs" path={`logs`} element={<ProjectLogsView />} />
      </Routes>
    </div>
  )
}
