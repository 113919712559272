import React, { useMemo } from "react"

import DateRangeIcon from "@mui/icons-material/DateRange"
import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { CartesianMarkerProps } from "@nivo/core"
import { DatumValue, ResponsiveLine, Serie } from "@nivo/line"
import { Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"

import { getTheme } from "../../hooks/UseTheme"
import { NivoTheme } from "../../themes/nivoTheme"
import { CustomSwitchedModels } from "../../types/custom/projects"
import { getTickInterval, truncateString } from "../../utils/genericHelpers"
import { LoadingContainer } from "../LoadingContainer"
import { ModelType } from "../ModelType"

import styles from "./RequestsLineGraph.module.scss"

type Props = {
  title?: string
  data?: Serie[]
  graphHeight: number
  isLoading: boolean
}

export function RequestsLineGraph(props: Props): React.ReactElement {
  const { title, data, graphHeight, isLoading } = props

  const theme = getTheme()

  const colors: Record<string, string> = {
    Successful: theme.palette.green.background[1],
    Failed: theme.palette.red.background[1],
  }

  //get markers for model state history
  const markers = useMemo(() => {
    if (data && data.length > 0) {
      const temp: { axis: string; value: DatumValue; lineStyle: Record<string, unknown> }[] = []
      data[1].data.forEach((item) => {
        if (item.modelsSwitched && item.modelsSwitched.length > 0) {
          temp.push({
            axis: "x",
            value: item.x as DatumValue,
            lineStyle: { stroke: theme.palette.gray.background[1], strokeWidth: 2, strokeDasharray: "20,10,5,5,5,10" },
          })
        }
      })
      return temp
    }
    return []
  }, [data, theme.palette.gray.background])

  const activityTooltip = (
    createdAt: string,
    ModelUUID: string,
    ModelName?: string,
    before_state?: string,
    after_state?: string,
  ): React.ReactElement => {
    const stateChangeString =
      before_state === "live" || (before_state === "challenger" && after_state === "disabled")
        ? "demoted"
        : before_state === "disabled" || (before_state === "challenger" && after_state === "live")
          ? "promoted"
          : ""
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Typography variant="p" variantColor={2}>
            <strong>{format(new Date(createdAt), "p")}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="p" variantColor={2}>
                <strong>{ModelName ?? truncateString(ModelUUID as string, 12) + " (DELETED)"}</strong>{" "}
                {stateChangeString} to <ModelType modelState={after_state} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Paper className={`${styles.paper}`}>
      <Box ml={1}>
        <Typography variant="h3-bold" gutterBottom>
          {title}
        </Typography>
      </Box>

      <Box style={{ height: graphHeight }}>
        {(!data || data.length < 1) && !isLoading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" className={"full-height"}>
            <Typography variant="label" variantColor={2}>
              No data to display
            </Typography>
          </Grid>
        ) : isLoading || !data ? (
          <LoadingContainer />
        ) : (
          <ResponsiveLine
            data={data}
            margin={{ top: 60, right: 30, bottom: 60, left: 60 }}
            xScale={{ type: "time", format: "native", precision: "day", useUTC: false }}
            theme={NivoTheme(theme)}
            xFormat="time:%a %d %b"
            yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
            curve="monotoneX"
            colors={(line) => colors[line.id]}
            markers={markers as CartesianMarkerProps<DatumValue>[]}
            sliceTooltip={({ slice }) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const switchStates = slice.points[0].data as any
              return (
                <div className={"chart-tooltip"}>
                  <div>
                    <div style={{ display: "flex" }}>
                      <DateRangeIcon
                        fontSize="small"
                        style={{
                          verticalAlign: "text-bottom",
                          marginRight: "4px",
                          color: theme.palette.grayscale.text[2],
                        }}
                      />
                      <Typography variant="h3-bold" variantColor={2}>
                        {slice.points[0].data.xFormatted}
                      </Typography>
                    </div>

                    <Box mt={1} />

                    <Typography variant="h3-bold" variantColor={2}>
                      Summary
                    </Typography>
                    <table style={{ borderCollapse: "collapse" }}>
                      <tbody>
                        {slice.points.map((point) => (
                          <tr key={point.id}>
                            <td style={{ padding: "3px 5px" }}>
                              <span
                                key="chip"
                                style={{
                                  display: "block",
                                  width: "12px",
                                  height: "12px",
                                  background: point.serieColor,
                                }}
                              />
                            </td>
                            <td style={{ padding: "3px 5px" }}>
                              <Typography variant="p" variantColor={2}>
                                {point.serieId}
                              </Typography>
                            </td>
                            <td style={{ padding: "3px 5px" }}>
                              <Typography variant="label" variantColor={2}>
                                {point.data[`yFormatted`]}
                              </Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {switchStates?.modelsSwitched && switchStates?.modelsSwitched.length > 0 && (
                      <React.Fragment>
                        <Box mt={1} />
                        <Typography variant="label" variantColor={2}>
                          <strong>Activity</strong>
                        </Typography>

                        {switchStates.modelsSwitched.map((item: CustomSwitchedModels) => (
                          <React.Fragment key={item.created_at}>
                            {activityTooltip(
                              item.created_at,
                              item.model,
                              item.model_name,
                              item.before_state,
                              item.after_state,
                            )}
                            <Box mb={1} />
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )
            }}
            axisTop={null}
            axisRight={null}
            enableArea={true}
            axisBottom={{
              // adjusting the tick values based on selected date range
              tickValues: getTickInterval(data[0]?.data?.length),
              format: "%a %d %b",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Number of Requests",
              legendOffset: -55,
              legendPosition: "middle",
              // Only display integer values
              format: (e) => Math.floor(e) === e && e,
            }}
            pointSize={0}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            enablePointLabel={true}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            enableSlices="x"
          />
        )}
      </Box>
    </Paper>
  )
}
