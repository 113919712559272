/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { ScoreCardSetScoreCardCreate } from "./ScoreCardSetScoreCardCreate"

/**
 * Serializer for ruleset model
 */
export type ScoreCardSetCreate = {
  readonly created_by: string
  name: string
  readonly uuid: string
  calculation_method: ScoreCardSetCreate.calculation_method
  scorecards?: Array<ScoreCardSetScoreCardCreate>
  readonly version: string
  readonly created_at: string
}

export namespace ScoreCardSetCreate {
  export enum calculation_method {
    VALUE_BY_WEIGHT = "VALUE_BY_WEIGHT",
    NORMALIZED_VALUE_BY_WEIGHT = "NORMALIZED_VALUE_BY_WEIGHT",
  }
}
