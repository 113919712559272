import React, { Dispatch, Fragment, SetStateAction, useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useTheme as MuiTheme,
  useMediaQuery,
} from "@mui/material"
import { Button, RadioButton, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../hooks/UseTheme"

import styles from "./OptionsWithExplanationDialog.module.scss"

export interface Option {
  value: string
  label: string
  description: string
  component: React.ReactElement
}

export interface OptionsWithExplanationDialogProps<T> {
  open: boolean
  onClose: () => void
  selectedOption: T
  setSelectedOption: Dispatch<SetStateAction<T>>
  shouldEnableSelectingMethods: boolean
  options: Array<Option>
  dialogTitle: string
}

/**
 * Dialog to compare between different methods of scorecard calculation, and choose between them.
 * @param {boolean} open
 * @param {function} onClose
 * @param {Array<Option>} options
 * @returns {React.ReactElement}
 */
export function OptionsWithExplanationDialog<T>(props: OptionsWithExplanationDialogProps<T>): React.ReactElement {
  const { open, onClose, dialogTitle, setSelectedOption, selectedOption, shouldEnableSelectingMethods, options } = props
  const theme = MuiTheme()

  const colorTheme = getTheme()
  const [innerSelectedOption, setInnerSelectedOption] = useState(selectedOption)

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSelectedOption(innerSelectedOption)
        setTimeout(() => {
          onClose()
        }, 10)
      }}
      fullWidth
      maxWidth="md"
      fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
    >
      {/* Dialog title */}
      <DialogTitle
        id="form-dialog-title"
        className="dialog-header-base"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h2-bold">{dialogTitle}</Typography>
        {!shouldEnableSelectingMethods && (
          <IconButton onClick={onClose} size="small" className={"close-icon-button"}>
            <CloseIcon style={{ color: colorTheme.palette.grayscale.text[2] }} />
          </IconButton>
        )}
      </DialogTitle>

      {/* Dialog content - radio buttons and options components */}
      <DialogContent className="dialog-content-base">
        <Grid
          className={styles.examplesContainer}
          container
          style={{ marginBottom: shouldEnableSelectingMethods ? 0 : "12px" }}
        >
          {options.map((option) => (
            <Grid key={option.value} item xs display="flex" flexDirection="column" alignItems="flex-start">
              <Grid xs item mb={1.5}>
                {shouldEnableSelectingMethods ? (
                  <Grid container item display="flex" flexWrap={"nowrap"}>
                    {/** TODO: return the label and description after fixing the radio button
                     * from SUI, this is because you cannot control description width from SUI
                     */}
                    <RadioButton
                      value={option.value}
                      onChange={() => setInnerSelectedOption(option.value as T)}
                      checked={[option.value, option.value?.toUpperCase()].includes(innerSelectedOption as string)}
                      id={option.value}
                    />
                    <Grid
                      item
                      xs={11}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setInnerSelectedOption(option.value as T)}
                    >
                      <Typography variant="p">{option.label}</Typography>
                      <Typography variant="span" variantColor={2}>
                        {option.description}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Fragment>
                    <Typography variant="p">{option.label}</Typography>
                    <Typography variant="span" variantColor={2}>
                      {option.description}
                    </Typography>
                  </Fragment>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                className={
                  [option.value, option.value?.toUpperCase()].includes(innerSelectedOption as string)
                    ? styles.selectedExample
                    : styles.example
                }
              >
                {option.component}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      {/** Action buttons */}
      {shouldEnableSelectingMethods && (
        <DialogActions className="dialog-actions-base">
          <Button onClick={onClose} variant="ghost">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setSelectedOption(innerSelectedOption)
              setTimeout(() => {
                onClose()
              }, 10)
            }}
            variant="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
