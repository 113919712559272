import React from "react"

import WarningIcon from "@mui/icons-material/Warning"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { Chip, Typography } from "@synapse-analytics/synapse-ui"

import { getTheme } from "../../../../../hooks/UseTheme"
import { CategoricalHistogram } from "./CategoricalHistogram"
import { ParentsBreadcrumbs } from "./components/ParentsBreadcrumbs"
import { StatisticsRow } from "./components/StatisticsRow"

import styles from "../DataMonitor.module.scss"

type RankHistogramType = {
  low_rank: number
  high_rank: number
  sample_count: number
  label: string
}

type Props = {
  name: string
  num_of_examples: number
  num_non_missing?: number
  unique?: number
  avg_length?: number
  rank_histogram?: RankHistogramType[]
  parents: string
}

export function CategoricalFeatureCard(props: Readonly<Props>): React.ReactElement {
  const { name, num_of_examples, num_non_missing, unique, avg_length, rank_histogram, parents } = props

  const theme = getTheme()

  const missingTolerance = 30

  //num_missing is always = zero -> should be fixed from the backend side in KONAN-1377
  const missingPercentage = React.useMemo(() => {
    if (num_non_missing) return ((num_of_examples - num_non_missing) / num_of_examples) * 100
    else return undefined
  }, [num_of_examples, num_non_missing])

  return (
    <Paper className={styles.valueContainer}>
      <Grid container>
        {/* Name and Type */}
        <Grid item xs={2}>
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" wrap="wrap">
            <Grid container item spacing={1} xs={12}>
              {/* Parents */}
              {parents && <ParentsBreadcrumbs parents={parents} />}
            </Grid>

            <Grid item container xs={11} spacing={1}>
              <Grid item xs={11} className={styles.featName}>
                <Typography variant="h2-bold" noWrap>
                  {name}
                </Typography>
              </Grid>

              <Grid item xs={12} className={styles.chipAlignment}>
                <Chip>Categorical</Chip>
              </Grid>

              {missingPercentage && missingPercentage > missingTolerance ? (
                <Grid item xs={12} className={styles.chipAlignment}>
                  <Chip>
                    <WarningIcon style={{ color: theme.palette.yellow.text[2], marginRight: "8px" }} fontSize="small" />
                    High % of Missing
                  </Chip>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Data */}
        <Grid container item xs={6} className={styles.dataSection}>
          <Grid container item xs={6} spacing={2} zeroMinWidth direction="column">
            <StatisticsRow name="Distinct" value={unique} precision={0} />
            <StatisticsRow
              name="Distinct (%)"
              value={unique !== undefined ? ((unique / num_of_examples) * 100).toFixed(1) + "%" : "0.0%"}
              precision={1}
            />
            <StatisticsRow name="Average Length" value={avg_length} precision={1} />
            <StatisticsRow
              name="Num Missing"
              value={num_non_missing ? num_of_examples - num_non_missing : ""}
              precision={0}
            />
            <StatisticsRow
              name="Num Missing (%)"
              value={missingPercentage !== undefined ? missingPercentage.toFixed(1) + "%" : "0.0%"}
              precision={1}
            />
          </Grid>
        </Grid>

        {/* Histogram  */}
        <Grid item xs={4} alignItems="flex-end">
          <CategoricalHistogram data={rank_histogram ? rank_histogram : []} graphHeight={200} />
        </Grid>
      </Grid>
    </Paper>
  )
}
